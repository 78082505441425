import React from 'react';
import { Filter, TextInput } from 'admin-on-rest';

const UserGroupsFilters = props => (
  <Filter {...props}>
    <TextInput
      label="customer.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput source="name" />
    <TextInput label="User Group Code" source="code" />
  </Filter>
);

export default UserGroupsFilters;
