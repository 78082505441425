import React, { Fragment, useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import LinearProgress from 'material-ui/LinearProgress';
import Seo from '../components/common/Seo/';
import { SectionBox } from './../components';
import LogItem from './components/LogItem';
import LogHeader from './components/LogHeader';
import General from './components/General';
// import StepWizard from './components/StepWizard';
import { loadingHistory as loadingHistoryAction } from './actions';
import StructureBadge from './../neonOrders/components/StructureBadge';
import { groupByLogs } from './helpers';


const style = {
  collapseBtn: {
    fontSize: '14px',
    fontWeight: '600',
    color: 'rgb(0, 188, 212)',
    cursor: 'pointer',
    margin: '10px 15px',
    display: 'block',
  },
  titleBadge: {
    display: 'inline-block',
    fontSize: '0.8rem',
    marginRight: '15px',
    verticalAlign: 'middle',
  },
  title: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  titleWrap: {
    borderTop: '1px solid rgb(0, 188, 212)',
    borderBottom: '1px solid rgb(0, 188, 212)',
  },
  loader: {},
  logGroupPdLeft: {
    paddingLeft: '30px',
    position: 'relative',
  },
};

const OrderHistory = (props) => {
  const { match: { params }, loading, loadingHistory, title, logs, general } = props;
  const { id } = params;
  const { structure, price, isCompo = '' } = general;

  const [open, toogleOpen] = useState(false);
  const hendleExpanded = () => toogleOpen(!open);

  useEffect(() => {
    loadingHistory(id);
  }, [id]);

  if (loading) {
    return (
      <Fragment>
        <Seo title={`Order #${id}`} />
        <SectionBox>
          <LinearProgress mode="indeterminate" color='#00bcd4' style={style.loader}/>
        </SectionBox>
      </Fragment>
    );
  }
  const groups = groupByLogs(logs);
  return (
    <Fragment>
      <Seo title={`Order #${id} | ${title}`} />
      <SectionBox>
        <div style={style.titleWrap}>
          <div style={style.titleBadge}>
            <StructureBadge structure={structure} price={price} is_compo={isCompo}/>
          </div>
          <div style={style.title}>
            <h3>{title}</h3>
          </div>
        </div>
        <General general={general} />
        {/* <StepWizard general={general} /> */}
        <div className='order-history-logs-wrapper'>
          <div className='oh-logs-action-wrapper'>
            <a onClick={hendleExpanded} style={style.collapseBtn}>{!open ? 'Expand' : 'Collapse'}</a>
          </div>
          <div>
            <div style={style.logGroupPdLeft}>
              <LogHeader />
            </div>
            {
              groups.map(([name, group], key) => (
                <div key={key} style={{ ...style.logGroupPdLeft }}>
                  <div className={`log-group lg-${key}`}></div>
                  <div className='log-label'><span className={`lg-span-${key}`}>{name}</span></div>
                  {group.map((log, logKey) => <LogItem key={logKey} {...log} open={open} />)}
                </div>
              ))
            }
          </div>
        </div>
      </SectionBox>
    </Fragment>
  );
};


const mapStateToProps = ({ orderHistoryReducer: { loading, title, general = {}, logs = [] } }) => ({
  loading,
  title,
  general,
  logs,
});

export default compose(
  connect(mapStateToProps, {
    loadingHistory: loadingHistoryAction,
  }),
)(OrderHistory);
