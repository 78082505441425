import React from 'react';
import { Filter, TextInput } from 'admin-on-rest';

const themeTemplateFilters = props => (
  <Filter {...props}>
    <TextInput
      label="theme_template.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="name"
    />
  </Filter>
);

export default themeTemplateFilters;
