import { BooleanField, BooleanInput, Datagrid, DateInput, Filter, FunctionField, List, NumberField, NumberInput, ReferenceField, ReferenceInput, refreshView, Responsive, SelectField, SelectInput, SimpleList, TextField, TextInput } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { DateField, UrlField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput, formatDateInput } from '../utils/dates';
import socket from 'ioClient';

const NotifyMeFilter = props => (
  <Filter {...props}>
    <TextInput
      label="notifications.list.search"
      source="_q"
      alwaysOn
    />
    <ReferenceInput
      label="User"
      source="user_id"
      reference="users"
    >
      <SelectInput
        optionText="email"
      />
    </ReferenceInput>
    <TextInput
      source="commodity_contract"
    />
    <TextInput
      source="structure"
    />
    <TextInput
      source="email"
    />
    <BooleanInput
      source="email_status"
    />
    <NumberInput
      source="value"
    />
    <SelectInput
      source="calculate_type"
      choices={[
        { id: 'price', name: 'Price' },
        { id: 'strikeLevel', name: 'Accumulation Level' },
        { id: 'barrierLevel', name: 'Barrier Level' },
      ]}
    />
    <TextInput
      source="quoted_currency"
    />
    <SelectInput
      source="operator"
      choices={[
        { id: '>', name: 'greater than' },
        { id: '>=', name: 'greater than or equals' },
        { id: '<', name: 'less than' },
        { id: '<=', name: 'less than or equals' },
      ]}
    />
    <SelectInput
      source="status"
      choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'done', name: 'Done' },
        { id: 'Read', name: 'Read' },
      ]}
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
    />
  </Filter>
);

const GetUrl = props => <UrlField href={`admin#/calculate_history/${props.record.pricing_history_id}-${props.record.history_structure}/show`} label={`${props.record.history_commodity_contract}`} />;

class NotifyMeList extends React.Component {
  constructor(props) {
    super(props);

    this.socket = socket;
  }

  componentDidMount() {
    this.socket.on('notify_me', () => {
      this.props.refreshView();
    });
  }

  render() {
    return (<List {...this.props}
      filters={<NotifyMeFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={50}
    >
      <Responsive
        small={
          <SimpleList
            primaryText={r => r.send_message}
            secondaryText={r => r.name}
            tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.create_at}</Moment>}
          />
        }
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
            <TextField
              source="id"
            />
            <ReferenceField
              label="user"
              source="user_id"
              reference="users"
            >
              <FunctionField
                render={r => `${r.firstName} ${r.lastName}`}
              />
            </ReferenceField>
            <GetUrl
              source="history_commodity_contract"
              label="commodity contract"
            />
            <TextField
              source="structure"
              label="structure"
            />
            <TextField
              source="email"
            />
            <BooleanField
              source="email_status"
            />
            <NumberField
              source="value"
            />
            <SelectField
              source="history_calculate_type"
              label="calculate_type"
              choices={[
                { id: 'price', name: 'Price' },
                { id: 'strikeLevel', name: 'Accumulation Level' },
                { id: 'barrierLevel', name: 'Barrier Level' },
                { id: '-', name: '-' },
              ]}
            />
            <TextField
              source="history_quoted_currency"
              label="quoted_currency"
            />
            <SelectField
              source="operator"
              choices={[
                { id: '>', name: 'greater than' },
                { id: '>=', name: 'greater than or equals' },
                { id: '<', name: 'less than' },
                { id: '<=', name: 'less than or equals' },
              ]}
            />
            <TextField
              source="status"
            />
            <DateField
              formatDate={formatDateTimeInput}
              source="updated_at"
              showTime
            />
            <DateField
              formatDate={formatDateTimeInput}
              source="created_at"
              showTime
            />
          </Datagrid>
        }
      />
    </List>);
  }
}

export default connect(null, {
  refreshView,
})(NotifyMeList);
