export const styleTitle = {
  title: {
    margin: '0px 0px -1px',
    padding: '24px 24px 20px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '22px',
    lineHeight: '32px',
    fontWeight: '400',
  },
  badge: {
    marginLeft: '10px',
    fontSize: '50%',
    verticalAlign: 'middle',
    paddingTop: '.5em',
  },
};

export default `
.tab-grid {
  display: grid;
  grid-template-areas:
    'username username email email lastEditBy lastEditBy'
    'structure structure status status quotedCurrency quotedCurrency'
    'orderType orderType orderType timeInForce timeInForce timeInForce'
    'stopPrice stopPrice stopPrice expiryDate expiryDate expiryDate'
    'contractExpiry contractExpiry contractExpiry createdAt createdAt createdAt'
    'canceledUserId canceledUserId canceledUserId canceledAt canceledAt canceledAt'
    'errors errors errors errors errors errors';
  grid-column-gap: .5em;
}

.quoted_currency {
  grid-area: quotedCurrency;
}
.structure {
  grid-area: structure;
}
.username {
  grid-area: username;
}
.email {
  grid-area: email;
}
.status {
  grid-area: status;
}
.last_edit_by {
  grid-area: lastEditBy;
}
.contract_expiry {
  grid-area: contractExpiry;
}
.created_at {
  grid-area: createdAt;
}
.canceled_user_id {
  grid-area: canceledUserId;
}
.canceled_at {
  grid-area: canceledAt;
}
.errors {
  grid-area: errors;
}

.commodity_contract {
  grid-area: commodityContract;
}
.contract_code {
  grid-area: contractCode;
}
.filled_quantity {
  grid-area: filledQuantity;
}
.quantity {
  grid-area: quantity;
}
.remaining_quantity {
  grid-area: remainingQuantity;
}
.direction {
  grid-area: direction;
}
.price {
  grid-area: price;
}
.avg_price {
  grid-area: avgPrice;
}
.structure_type {
  grid-area: structureType;
}
.strike_level {
  grid-area: strikeLevel;
}

.order_type {
  grid-area: orderType;
}
.time_in_force {
  grid-area: timeInForce;
}
.stop_price {
  grid-area: stopPrice;
}
.expiry_date {
  grid-area: expiryDate;
}


.order-tab-grid {
  display: grid;
  grid-template-areas:
    'commodityContract commodityContract commodityContract contractCode contractCode contractCode'
    'filledQuantity filledQuantity quantity quantity remainingQuantity remainingQuantity'
    'direction direction price price avgPrice avgPrice'
    'structureType structureType structureType strikeLevel strikeLevel strikeLevel';
  grid-column-gap: .5em;
}

.order-tab-grid input, .tab-grid input {
  color: rgba(0, 0, 0, 0.87) !important;
}

.popup-action-bar {
  margin-top: 25px;
  text-align: right;
  position: relative;
}
.popup-action-bar button {
  float: none !important;
  padding: 0px 10px !important;
}
.button-wrapper {
  width: 33%;
  display: inline-block;
  text-align: center;
}
`;
