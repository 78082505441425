import { UPDATE } from 'admin-on-rest';

import { PDF_SEND } from './PDFAction';

const PDFSendAction = (id, data, basePath) => ({
  type: PDF_SEND,
  payload: { id, data, basePath },
  meta: { resource: 'pdfs', fetch: UPDATE, cancelPrevious: false },
});

export default PDFSendAction;
