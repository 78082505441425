import React from 'react';
import {
  Edit,
  translate,
} from 'admin-on-rest';

import { UserGroupForm } from './components';

const UserGroupTitle = translate(({ record }) =>
  <span>Edit group { record.name }</span>);

const EditUserGroup = props =>
  <Edit {...props} title={<UserGroupTitle />}>
    <UserGroupForm />
  </Edit>;

export default EditUserGroup;
