export default `
.calc_title div span{
  font-size: 24px !important;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  line-height: 36px;
}

.simple-form > div {
  display: grid;
  grid-template-areas:
    'spreadsheet commodity . .'
    'fillall fillall fillall fillall'
    'result result result result';
  grid-column-gap: .5em;
}

.aor-input-Spreadsheet {
  grid-area: spreadsheet;
}
.aor-input-Commodity {
  grid-area: commodity;
}
.aor-input-result {
  grid-area: result;
}
.aor-input-fill-all {
  grid-area: fillall;
}

.aor-input-result .result {
  display: grid;
  grid-template-areas:
    'title title'
    'client neon';
  grid-column-gap: .5em;
}
.title {
  grid-area: title;
}
.client {
  grid-area: client;
}
.neon {
  grid-area: neon;
}
.table-title {
  background-color: rgb(0, 188, 212);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  color: #ffffff !important;
  font-weight: bold;
}
thead tr {
  background-color: rgb(224, 224, 224);
}

.custom-input {
  width: 100%;
  padding: 8.5px 10px;
  box-sizing: content-box;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  border-style: solid;
  transition: padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.custom-input:focus {
  outline: 0;
  border-color: rgb(0, 188, 212);
  border-radius: 4px;
  border-style: solid;
  transition: padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.save-btn svg {
  vertical-align: middle;
  margin-left: 12px;
  margin-right: 0px;
  display: inline-block;
  fill: rgb(255, 255, 255) !important;
  width: 24px;
}

.save-btn span {
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  color: rgb(255, 255, 255);
}
`;
