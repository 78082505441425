import React, { Component } from 'react';
import moment from 'moment';

class Message extends Component {
  shouldComponentUpdate({ id, isRead, subject, body }) {
    return id !== this.props.id ||
      isRead !== this.props.isRead ||
      subject !== this.props.subject ||
      body !== this.props.body;
  }

  onRead = () => {
    const { id, isRead, payload, onRead } = this.props;
    const type = payload && payload.type ? payload.type : null;
    const orderID = payload && payload.id ? payload.id : null;
    onRead(id, isRead, type, orderID);
  }

  onDelete = () => {
    const { id, onDelete } = this.props;
    onDelete(id);
  }

  render() {
    const { isRead, subject, body, createdAt } = this.props;
    if (!subject) return null;
    const classIsRead = isRead ? 'read unactive' : 'read active';
    return (
      <div className="card">
        <div className="card-content" onClick={this.onRead}>
          <div className="content-subject">
            <span>{subject}</span>
          </div>
          <div className="content-body">
            <span>{body}</span>
          </div>
          <span className="content-createdAt">{moment(createdAt).format('DD MMMM YYYY HH:mm')}</span>
        </div>
        <div className="card-actions">
          <button className="close" onClick={this.onDelete}>
            <span>
              <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation">
                <path d="M12 10.586L6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 1 0-1.414-1.414L12 10.586z" fill="currentColor"></path>
              </svg>
            </span>
          </button>
        </div>
        <div className="card-actions">
          <button className={classIsRead} onClick={this.onRead}>
            <span></span>
          </button>
        </div>
      </div>
    );
  }
}

export default Message;
