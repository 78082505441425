import React, { Component, Fragment } from 'react';
import EditButton from './EditButton';
import ApproveButton from './ApproveButton';
import RejectButton from './RejectButton';
import CancelButton from './CancelButton';
import HistoryButton from './HistoryButton';

export default class ButtonsWrapper extends Component {
  render() {
    const { props } = this;
    const { id, rowType } = props.record;
    if (rowType === 'legs') {
      return null;
    }
    return (
      <Fragment>
        <div className="buttons-wrapper">
          <HistoryButton id={id} style={{ float: 'left' }} />
          <EditButton {...props} />
          <ApproveButton {...props} />
          <RejectButton {...props} />
          <CancelButton {...props} />
        </div>
      </Fragment>
    );
  }
}
