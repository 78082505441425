
import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { showNotification } from 'admin-on-rest';

import { PRICING_DELETE_SUCCESS, PRICING_DELETE_FAILURE } from './PricingAction';

export default function* reviewSaga() {
  yield [
    takeEvery(PRICING_DELETE_SUCCESS, function* () {
      yield put(showNotification('pricings.notification.delete_success'));
      yield put(push('/'));
      yield put(push('/pricings'));
    }),
    takeEvery(PRICING_DELETE_FAILURE, function* ({ error }) {
      yield put(showNotification('pricings.notification.delete_error', 'warning'));
      console.error(error);
    }),
  ];
}
