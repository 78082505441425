import { BooleanField, BooleanInput, Datagrid, DateInput, Filter, List, RefreshButton, Responsive, SimpleList, TextField, TextInput } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import React from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import { formatDateInput, formatDateTimeInput } from '../utils/dates';
import FileUploadButton from './FileUploadButton';

const FileAction = ({ refresh, filters, resource, showFilter, displayedFilters, filterValues }) => (
  <CardActions>
    <FileUploadButton />
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    <RefreshButton refresh={refresh} />
  </CardActions>
);

const FileFilter = props => (
  <Filter {...props}>
    <TextInput
      source="name"
    />
    <TextInput
      source="upload_message"
    />
    <BooleanInput
      source="upload_status"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
    />
  </Filter>
);

const DatasetsList = props => (
  <List {...props}
    actions={<FileAction />}
    filters={<FileFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => r.upload_message}
          secondaryText={r => r.name}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
        />
      }
      medium={
        <Datagrid>
          <TextField
            source="id"
          />
          <TextField
            source="name"
          />
          <TextField
            source="upload_message"
          />
          <BooleanField
            source="upload_status"
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="created_at"
            showTime
          />
        </Datagrid>
      }
    />
  </List>
);

export default DatasetsList;
