import React from 'react';
import PropTypes from 'prop-types';
import RefreshDashboardConfirmIcon from 'material-ui/svg-icons/action/check-circle';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';

import { connect } from 'react-redux';
import { translate, UPDATE } from 'admin-on-rest';

import { REFRESH_DASHBOARD_CONFIRM } from './RefreshDashboardAction';

const refreshDashboardConfirmAction = (id, data, basePath) => ({
  type: REFRESH_DASHBOARD_CONFIRM,
  payload: { id, data: { ...data, status: true }, basePath },
  meta: { resource: 'refresh_dashboards', fetch: UPDATE, cancelPrevious: false },
});

class RefreshDashboardConfirmButton extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  onClick() {
    return () => {
      const { record } = this.props;
      this.props.confirmAction(record.id, record);
    };
  }

  render() {
    const { record } = this.props;
    return (
      <FlatButton
        primary
        onClick={this.onClick()}
        disabled={!record || record.status}
        label={this.translate('refreshDashboards.list.confirm')}
        icon={<RefreshDashboardConfirmIcon/>}
      />
    );
  }
}

RefreshDashboardConfirmButton.propTypes = {
  record: PropTypes.object,
  confirmAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    confirmAction: refreshDashboardConfirmAction,
  }),
);

export default enhance(RefreshDashboardConfirmButton);
