import React, { Component } from 'react';
import { translate, showNotification as showNotificationAction } from 'admin-on-rest';
import Icon from 'material-ui/svg-icons/navigation/cancel';
import compose from 'recompose/compose';
import FlatButton from 'material-ui/FlatButton';
import request from 'superagent';
import { connect } from 'react-redux';
import { updateOrder as updateOrderAction, PROCESSING, DONE } from './../../actions';
import path from '../../../path';
import { APPROVE_REJECT_STATUSES, FX_STRUCTURE, MINIFUTURE } from '../../constants';

class RejectButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: props.record.state === 'processing',
    };
    this.translate = this.props.translate;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { record: { status } } = this.props;
    return status !== nextProps.record.status || this.state.disabled !== nextState.disabled;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.record.state !== nextProps.record.state) {
      this.setState({
        disabled: nextProps.record.state === PROCESSING,
      });
    }
  }

  setDisabled = (status) => {
    const { record, updateOrder } = this.props;
    this.setState({
      disabled: status,
    });
    updateOrder({
      id: record.id,
      state: status ? PROCESSING : DONE,
    }, false);
  }

  onClick = () => {
    const { showNotification, record: { id, structure, status } } = this.props;
    this.setDisabled(true);

    const uri = structure === FX_STRUCTURE || status === 'requested'
      ? `${path}/mspx/req/reject/${id}`
      : `${path}/admin/neon_orders/update_status/${id}`;
    request
      .post(uri)
      .set('token', localStorage.getItem('session'))
      .send({ status: 'not approved' })
      .then(() => {
        showNotification('NeonOrders.update.status.success');
      })
      .catch(() => {
        this.setDisabled(false);
        showNotification('NeonOrders.update.status.fail', 'warning');
      });
  }

  render() {
    const { record: { status, structure, term_currency: termCurrency } } = this.props;
    const { disabled } = this.state;
    const title = structure === FX_STRUCTURE || status === 'requested' ? 'Reject price request' : 'Reject order';
    const isMfFx = structure === MINIFUTURE && !!termCurrency;
    if ((structure === FX_STRUCTURE || isMfFx) && status === 'pending') return null;
    return APPROVE_REJECT_STATUSES.includes(status) ? (
      <FlatButton
        primary
        onClick={this.onClick}
        disabled={disabled}
        icon={<Icon />}
        title={title}
        style={{ float: 'left', minWidth: '30px', color: '#de350b' }}
      />
    ) : '';
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    updateOrder: updateOrderAction,
  }),
);

export default enhance(RejectButton);

