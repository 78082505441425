import React, { Fragment, useEffect } from 'react';
import { required, number } from 'admin-on-rest';
import { formValueSelector, change, FieldArray, arrayPush } from 'redux-form';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Table, TableBody, TableRow, TableRowColumn, TableHeader, TableHeaderColumn } from 'material-ui/Table';
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar';
import DeleteIcon from 'material-ui/svg-icons/content/remove-circle';
import AddIcon from 'material-ui/svg-icons/content/add-circle';
import RaisedButton from 'material-ui/RaisedButton';
import {
  RECORD_FORM,
  DIRECTION,
  INPUT_PROPS,
  TableInput,
  businessDaysValidation,
  FIELD_BUSINESS_DAYS,
  SUBSTEPS_FIELD_ARRAY,
  SUBSTEPS_TYPES,
} from './helpers';
import { validateisIntegerToForty } from '../../utils/validations';

const renderSubSteps = (props) => {
  const { fields } = props;
  const removeHandler = i => fields.remove(i);
  return (
    <Fragment>
      {fields.map((item, index) => {
        const isLast = index === fields.length - 1;
        return (
          <TableRow key={index} style={{ backgroundColor: isLast ? '#f9f9f9' : 'none' }}>
            <TableInput
              source={`${item}.direction`}
              rowStyle={{ width: '50px' }}
              disabled={true}
              type="text"
              inputOptions={{
                ...INPUT_PROPS,
              }}
            />
            <TableInput
              placeholder="Business Days"
              source={`${item}.${FIELD_BUSINESS_DAYS}`}
              validate={[required, number, businessDaysValidation]}
              disabled={isLast}
              type="number"
              inputOptions={{ min: 0 }}
            />
            <TableInput
              placeholder="Substeps Per Day"
              source={`${item}.subStepsPerDay`}
              validate={[required, number, validateisIntegerToForty]}
              type="number"
            />
            <TableRowColumn>
              {!isLast ? (
                <RaisedButton
                  label="Remove Substeps"
                  secondary={true}
                  icon={<DeleteIcon />}
                  onClick={() => removeHandler(index)}
                />
              ) : null}
            </TableRowColumn>
          </TableRow>
        );
      })}
    </Fragment>
  );
};

const SubStepsControl = props => {
  const { arrayPushAction, record } = props;
  const addFields = (type, value) => arrayPushAction(RECORD_FORM, `${SUBSTEPS_FIELD_ARRAY}.${type}`, value);

  useEffect(() => {
    // eslint-disable-next-line
    for (const {key, val} of SUBSTEPS_TYPES) {
      if (record && record[SUBSTEPS_FIELD_ARRAY] && record[SUBSTEPS_FIELD_ARRAY][key] && record[SUBSTEPS_FIELD_ARRAY][key].length) return;
      addFields(key, {
        direction: DIRECTION.GREATER,
        businessDays: 0,
        subStepsPerDay: 0,
      });
    }
  }, []);

  const addElement = (type) => {
    const lastIdx = props[SUBSTEPS_FIELD_ARRAY][type].length - 1;
    const last = props[SUBSTEPS_FIELD_ARRAY][type][lastIdx];
    addFields(type, {
      subStepsPerDay: last && last.subStepsPerDay,
      businessDays: last && last.businessDays,
      direction: DIRECTION.GREATER,
    });
    props.change(RECORD_FORM, `${SUBSTEPS_FIELD_ARRAY}.${type}[${lastIdx}]`, { direction: DIRECTION.LESS_OR_EQUAL });
  };

  return (
    <Fragment>
      {SUBSTEPS_TYPES.map((item) => (
        <Fragment key={item.key}>
          <Toolbar>
            <ToolbarGroup>
              <ToolbarTitle text={item.value} />
            </ToolbarGroup>
            <ToolbarGroup>
              <ToolbarSeparator />
              <RaisedButton label="Add Substeps" secondary={true} icon={<AddIcon />} onClick={() => addElement(item.key)} />
            </ToolbarGroup>
          </Toolbar>
          <Table selectable={false}>
            <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
              <TableRow>
                <TableHeaderColumn style={{ width: '50px' }}>
                  <b>Direction</b>
                </TableHeaderColumn>
                <TableHeaderColumn>
                  <b>Business Days</b>
                </TableHeaderColumn>
                <TableHeaderColumn>
                  <b>Substeps Per Day</b>
                </TableHeaderColumn>
                <TableHeaderColumn></TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              <FieldArray name={`${SUBSTEPS_FIELD_ARRAY}.${item.key}`} component={renderSubSteps} />
            </TableBody>
          </Table>
        </Fragment>
      ))}
    </Fragment>
  );
};

const selector = formValueSelector(RECORD_FORM);
const enhance = compose(
  connect((state, { source }) => {
    const obj = {
      subStepsPerDay: selector(state, 'subStepsPerDay'),
      data: selector(state, source),
    };
    return obj;
  }, { change, arrayPushAction: arrayPush }),
);
export default enhance(SubStepsControl);
