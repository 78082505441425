import React from 'react';
import { DateInput, Filter, SelectInput, TextInput } from 'admin-on-rest';
import { formatDateInput, dateParser } from '../../utils/dates';

const FxCommodityFilters = props => (
  <Filter {...props}>
    <TextInput
      label="fxCommodities.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="fx_code"
    />
    <TextInput
      source="agile_name"
    />
    <TextInput
      source="base_currency"
    />
    <TextInput
      source="term_currency"
    />
    <SelectInput
      source="status"
      choices={[
        { id: true, name: 'Active' },
        { id: false, name: 'Inactive' },
      ]}
    />
    <SelectInput
      source="deliverable"
      choices={[
        { id: true, name: 'Yes' },
        { id: false, name: 'No' },
      ]}
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
      parse={dateParser}
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
      parse={dateParser}
    />
  </Filter>
);

export default FxCommodityFilters;
