import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CardActions } from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import Popover from 'material-ui/Popover';
import { RefreshButton } from 'admin-on-rest';
import {
  switchColumn as switchColumnAction,
  toogleAllColumns as toogleAllColumnsAction,
} from '../actions';
import DisplayCheckboxes from './DisplayCheckboxes';
import CreateButton from './Create';


const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

class ClientDataBaseActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleTouchTap = this.handleTouchTap.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  handleTouchTap(event) {
    event.preventDefault();
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  }

  handleRequestClose() {
    this.setState({
      open: false,
    });
  }

  render() {
    const { resource, filters, displayedFilters, filterValues, showFilter, columns, switchColumn, toogleAllColumns } = this.props;
    const { open, anchorEl } = this.state;
    return (
      <CardActions style={cardActionStyle}>
        {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
        <RefreshButton />
        <CreateButton />
        <div style={{ display: 'inline-block' }}>
          <FlatButton primary label="Display" onClick={this.handleTouchTap} />
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
            onRequestClose={this.handleRequestClose}
            autoFocus={true}
          >
            <DisplayCheckboxes columns={columns} switchColumn={switchColumn} toogleAllColumns={toogleAllColumns}/>
          </Popover>
        </div>
      </CardActions>
    );
  }
}

const mapStateToProps = ({ clientDataBaseReducer: { columns } }) => ({
  columns,
});
export default connect(mapStateToProps, { switchColumn: switchColumnAction, toogleAllColumns: toogleAllColumnsAction })(ClientDataBaseActions);
