import React, { Fragment, useState } from 'react';
import _ from 'lodash';
import TextField from 'material-ui/TextField';
import Divider from 'material-ui/Divider';
import { NavigationExpandMore } from 'material-ui/svg-icons';
import { Link } from 'react-router-dom';

const style = {
  root: {
    margin: '0 15px',
    cursor: 'unset',
    width: 'auto',
  },
  floatingLabelStyle: {
  },
  inputStyle: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  underlineStyle: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  divider: {
    marginTop: '25px',
    marginBottom: '25px',
    marginLeft: '72px',
    marginRight: '72px',
  },
  fieldsWrapper: {
    margin: 'auto 5%',
    display: 'grid',
    columnGap: '50px',
    gridTemplateColumns: 'auto auto auto auto',
  },
  hideBtn: {
    margin: '0 auto',
    width: '30px',
    cursor: 'pointer',
    position: 'relative',
    bottom: '-41px',
    backgroundColor: 'rgb(245, 245, 245)',
    textAlign: 'center',
  },
  link: {
    textDecoration: 'none',
    top: '39px',
    cursor: 'pointer',
    color: 'rgb(0, 188, 212)',
  },
};

const EXCLUDE_KEYS = [
  'id',
  'agileCodeId',
  'status',
  'price',
  'structure',
  'errors',
  'user',
  'spreadsheet',
  'slackMessageId',
  'externalId',
];

const GeneralField = ({ label, value, customStyle = {} }) => (
  <TextField
    id="text-field-default"
    defaultValue={value}
    floatingLabelText={label}
    disabled={true}
    style={{ ...style.root, ...customStyle }}
    floatingLabelStyle={style.floatingLabelStyle}
    inputStyle={style.inputStyle}
    underlineDisabledStyle={style.underlineStyle}
  />
);

const LinkField = ({ label, value: { id, value, resurce }, customStyle = {} }) => (
  <TextField
    id="text-field-default"
    floatingLabelText={label}
    disabled={true}
    style={{ ...style.root, ...customStyle }}
    floatingLabelStyle={style.floatingLabelStyle}
    floatingLabelFixed={true}
    inputStyle={style.inputStyle}
    underlineDisabledStyle={style.underlineStyle}
  >
    <Link to={`/${resurce}/${id}`} title={value} style={style.link}>{value}</Link>
  </TextField>
);

const General = ({ general }) => {
  const hidden = _.pickBy(general, (value, key) => !EXCLUDE_KEYS.includes(key));
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(!open);

  const display = open ? 'grid' : 'none';
  const rotateBtn = open ? 180 : 0;
  const titleBtn = open ? 'Hide' : 'Show more';
  return (
    <Fragment>
      <div>
        <div style={style.fieldsWrapper}>
          <GeneralField label='ID' value={general.id}/>
          { general.agileCodeId ? <GeneralField label='Agile Code' value={general.agileCodeId}/> : null}
          { general.slackMessageId ? <GeneralField label='slackMessageId' value={general.slackMessageId}/> : null}
          { general.externalId ? <GeneralField label='externalId' value={general.externalId}/> : null}
          { general.user ? <LinkField label='User' value={general.user}/> : null}
          { general.spreadsheet ? <LinkField label='Spreadsheet' value={general.spreadsheet}/> : null}
          { general.extId ? <GeneralField label='Exetnal ID' value={null}/> : null }
          <GeneralField label='Status' value={general.status}/>
          <div style={{ gridColumn: '1/-1' }}>
            <GeneralField label='Error' value={general.errors} customStyle={{ width: '97%' }}/>
          </div>
        </div>
        <div style={{ ...style.fieldsWrapper, display }}>
          {Object.keys(hidden).map((key) => {
            if (!hidden[key]) return null;
            if (typeof hidden[key] === 'object') return (<LinkField key={key} label={key} value={hidden[key]}/>);
            return (<GeneralField key={key} label={key} value={hidden[key]}/>);
          })}
        </div>
        <div style={style.hideBtn} onClick={handleClick} title={titleBtn}>
          <NavigationExpandMore style={{ transform: `rotate(${rotateBtn}deg)` }}/>
        </div>
      </div>
      <Divider style={style.divider} />
    </Fragment>
  );
};

export default General;
