
/* eslint-disable */
import React from 'react';
export const PRICING_TEMPLATES = 'PRICING_TEMPLATES';
export const PRICING_TEMPLATES_LOADING = 'PRICING_TEMPLATES_LOADING';
export const PRICING_TEMPLATES_FAILURE = 'PRICING_TEMPLATES_FAILURE';
export const PRICING_TEMPLATES_SUCCESS = 'PRICING_TEMPLATES_SUCCESS';
export const formatMarkups = (m) => {
  try {
    const keys = Object.keys(m);
    m = keys.map((key, i) => {
      const data = m[key];
      return (
        <p style={{ padding: '0', margin: '0', fontSize: '12px' }}
          key={i} dangerouslySetInnerHTML={{
            __html: `<b> ${key.toUpperCase()}: </b> ${JSON.stringify(data)} <br/>`,
          }}></p>
      );
    });
  } catch (err) {
    console.log(err, 'err');
    m = '';
  }
  return m;
};
