import React, { Component } from 'react';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import compose from 'recompose/compose';
import {
  Edit,
  SimpleForm,
} from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import { style } from './components/style';
import { SettingSection } from './components/SettingSection';
import { RECORD_FORM, GENERAL, match } from './components/constants';

const selector = formValueSelector(RECORD_FORM);

class Settings extends Component {
  render() {
    const { elements } = this.props;

    const grouped = _.groupBy(elements, 'group');
    const keys = Object.keys(grouped);
    const index = keys.indexOf(GENERAL);
    if (index > -1) {
      keys.splice(index, 1);
    }
    keys.unshift(GENERAL);

    return (
      <Edit
        actions={<CardActions></CardActions>}
        title="Settings"
        {...this.props}
        match={match}
      >
        <SimpleForm redirect={false}>
          <div style={style.wrapper}>
            {keys.map((el, index) => grouped[el] ? <SettingSection key={index} title={el} settings={grouped[el]} {...this.props} /> : null)}
          </div>
        </SimpleForm>
      </Edit>
    );
  }
}

Settings.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
};


Settings.defaultProps = {
  elements: [],
};

const enhance = compose(
  connect(state => ({
    elements: selector(state, 'elements'),
  }), { }),
);

export default enhance(Settings);
