import { Datagrid, DateInput, Filter, FunctionField, List, NumberField, NumberInput, ReferenceField, ReferenceInput, refreshView, Responsive, SelectInput, ShowButton, SimpleList, TextField, TextInput } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import TagButton from '../components/TagButton';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput, formatDateInput, dateParser } from '../utils/dates';
import socket from 'ioClient';

const PricingHistoryFilter = props => (
  <Filter {...props}>
    <TextInput
      source="currency"
    />
    <TextInput
      source="type"
    />
    <NumberInput
      source="reference_price"
    />
    <ReferenceInput
      label="Strucutre"
      source="structure_id"
      reference="structures"
    >
      <SelectInput
        optionValue="id"
        optionText="code"
      />
    </ReferenceInput>
    <ReferenceInput
      label="Underlying"
      source="underlying_id"
      reference="underlyings"
    >
      <SelectInput
        optionValue="id"
        optionText="reference_contract"
      />
    </ReferenceInput>
    <ReferenceInput
      label="User"
      source="user_id"
      reference="users"
    >
      <SelectInput
        optionValue="id"
        optionText="email"
      />
    </ReferenceInput>
    <NumberInput
      source="business_days"
    />
    <NumberInput
      source="notional"
    />
    <NumberInput
      label="Price"
      source="value"
    />
    <NumberInput
      label="Accumulation"
      source="strike_level"
    />
    <NumberInput
      label="Barrier"
      source="barrier_level"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      label="Time"
      source="created_at"
      showTime
      parse={dateParser}
    />
  </Filter>
);

class PricingHistoryList extends React.Component {
  constructor(props) {
    super(props);

    this.socket = socket;
  }

  componentDidMount() {
    this.socket.on('pricing-history', () => {
      this.props.refreshView();
    });
  }

  render() {
    return (<List {...this.props}
      filters={<PricingHistoryFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={50}
    >
      <Responsive
        small={
          <SimpleList
            primaryText={r => `${r.value} ${r.currency}`}
            secondaryText={r => `${r.strike_level} ${r.barrier_level}`}
            tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
          />
        }
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
            <DateField
              formatDate={formatDateTimeInput}
              source="created_at"
              label="Time"
              showTime
            />
            <ReferenceField
              label="User"
              source="user_id"
              reference="users"
              linkType="show"
            >
              <FunctionField
                render={r => `${r.firstName} ${r.lastName}`}
              />
            </ReferenceField>
            <ReferenceField
              label="Strucutre"
              source="structure_id"
              reference="structures"
              linkType="show"
            >
              <FunctionField
                render={r => r.code}
              />
            </ReferenceField>
            <ReferenceField
              label="Bloomberg Ticker"
              source="underlying_id"
              reference="underlyings"
              linkType="show"
            >
              <FunctionField
                render={r => r.bloomberg_ticker}
              />
            </ReferenceField>
            <ReferenceField
              label="Underlying"
              source="underlying_id"
              reference="underlyings"
              linkType="show"
            >
              <FunctionField
                render={r => r.reference_contract}
              />
            </ReferenceField>
            <NumberField
              source="notional"
            />
            <TextField
              source="currency"
            />
            <TextField
              source="type"
            />
            <NumberField
              source="reference_price"
            />
            <NumberField
              label="Price"
              source="value"
            />
            <NumberField
              label="Accumulation"
              source="strike_level"
            />
            <NumberField
              label="Barrier"
              source="barrier_level"
            />
            <TagButton label="Tag" getPrimaryText={({ record }) => record.tag}/>
            <ShowButton />
          </Datagrid>
        }
      />
    </List>);
  }
}

export default connect(null, {
  refreshView,
})(PricingHistoryList);
