import React, { Component } from 'react';

export default class Badge extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.record.badge !== this.props.record.badge;
  }

  render() {
    const { record: { badge, rowType, rowIndex } } = this.props;
    let className = '';
    if (badge) {
      className = `badge badge-${badge === 'NEW' ? 'blue' : 'yellow'}`;
    }

    if (rowType === 'legs') {
      return (
        <span className='badge badge-grey'> Leg {rowIndex + 1}</span>
      );
    }
    return (
      <span className={className}>{badge}</span>
    );
  }
}
