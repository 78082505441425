import React from 'react';
import TextField from 'material-ui/TextField';
import UploadButton from '../../components/UploadButton';
import ColorPicker from '../../components/ColorPicker';

const fileMapper = {
  'Favicon Image': 'favicon',
};

const acceptImgCriteria = {
  favicon: '.jpeg,.jpg,.png,.ico',
  DEFAULT: '.jpeg,.jpg,.png',
};

const imageRecommendedSizes = {
  favicon: '(64x64)',
  DEFAULT: '',
};

const RenderComponent = ({ input, onChange, value, errorObj }) => {
  const { name, title, type } = input;
  const accept = acceptImgCriteria[fileMapper[title]] || acceptImgCriteria.DEFAULT;
  const imgSize = imageRecommendedSizes[fileMapper[title]] || imageRecommendedSizes.DEFAULT;
  switch (type) {
  case 'color':
    return <ColorPicker
      floatingLabelText={title}
      name={name}
      onChange={onChange}
      value={value}
      TextFieldProps={
        { errorText: errorObj && errorObj.error && errorObj.message }
      }
    />;
  case 'text':
    return <div>
      <TextField
        name={name}
        type="text"
        floatingLabelText={title}
        multiLine={true}
        onChange={onChange}
        maxLength="200"
        value={value}
        errorText={errorObj ? errorObj.message : ''}
        required
      />
    </div>;
  case 'file':
    return <div
      style={{
        margin: '10px 0 0 0',
        padding: '10px',
        backgroundColor: 'rgb(245, 245, 245)',
        boxShadow: '1px 3px 10px rgba(0, 0, 0, 0.3)',
        borderTop: '1px solid rgb(0, 188, 212,1)',
        borderBottom: '1px solid rgb(0, 188, 212,1)',
      }}>
      <UploadButton
        label={`Select ${title} file ${imgSize}`}
        accept={accept}
        onDrop={onChange}
        src={value ? value.url : null}
        isName={false}
        error={errorObj && errorObj.error}
        messageError={errorObj && errorObj.error && errorObj.message}
      />
    </div>;
  default:
    return null;
  }
};

export default RenderComponent;
