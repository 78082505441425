import React, { useState } from 'react';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';

const HeaderActions = ({ onHeaderAction }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const onClick = (type) => () => {
    setTitle(type);
    setOpen(true);
  };
  const onClose = () => {
    setTitle('');
    setOpen(false);
  };
  const onConfirm = () => {
    onHeaderAction(title);
    setTitle('');
    setOpen(false);
  };
  return (<div>
    <div className="header-actions">
      <span onClick={onClick('Read All')}>Read All</span>/<span onClick={onClick('Delete All')}>Delete All</span>
    </div>
    <Dialog
      title={`${title} notifications!`}
      modal={false}
      open={open}
    >
      <span>Are you sure?</span>
      <div style={{ paddingTop: '15px', float: 'right' }}>
        <RaisedButton
          label='Cancel'
          onClick={onClose}
          icon={<CancelIcon />}
        />
        <RaisedButton
          style={{ marginLeft: '10px' }}
          label='Confirm'
          primary={true}
          onClick={onConfirm}
          icon={<SubmitIcon />}
        />
      </div>
    </Dialog>
  </div>
  );
};

export default HeaderActions;
