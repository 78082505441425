import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import AgileMenuItem from './AgileMenuItem';

const AgileMenuNested = ({ nested, onMenuTap, nestedLevel }) => (
  nested && nested.length ?
    nested
      .map((item) => {
        const key = item.title ? snakeCase(item.title) : item.path;
        const isNested = true;
        return AgileMenuItem({ key, ...item, onMenuTap, isNested, nestedLevel: nestedLevel + 1 });
      })
    : null
);

AgileMenuNested.propTypes = {
  nested: PropTypes.array.isRequired,
};

export default AgileMenuNested;
