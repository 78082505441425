import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { CardActions } from 'material-ui/Card';
import FileSaver from 'file-saver';
import FlatButton from 'material-ui/FlatButton';
import Popover from 'material-ui/Popover';
import { RefreshButton } from 'admin-on-rest';
import {
  switchColumn as switchColumnAction,
  toogleAllColumns as toogleAllColumnsAction,
} from './../actions';
import path from '../../path';
import DisplayCheckboxes from './DisplayCheckboxes';
import { Loader } from '../../helpers/Loader';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

class OrderActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      load: false,
    };
    this.handleTouchTap = this.handleTouchTap.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.generateFile = this.generateFile.bind(this);
  }

  handleTouchTap(event) {
    event.preventDefault();
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  }

  handleRequestClose() {
    this.setState({
      open: false,
    });
  }

  generateFile(e) {
    e.preventDefault();
    this.setState({ load: true });
    fetch(`${path}/admin/neon_orders/generate_list${this.props.location.search}`, { method: 'GET',
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      } })
      .then(response => response.json())
      .then(async (res) => {
        const blob = new Blob([new Uint8Array(res.data)]);
        await FileSaver.saveAs(blob, `orders-list_${moment().format('DD-MMMM-YYYY_HH-mm')}.xlsx`);
        this.setState({ load: false });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { resource, filters, displayedFilters, filterValues, showFilter, columns, switchColumn, toogleAllColumns } = this.props;
    const { open, anchorEl, load } = this.state;
    return (
      <CardActions style={cardActionStyle}>
        {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' })}
        <RefreshButton />
        <div style={{ display: 'inline-block' }}>
          <FlatButton primary label="Export" onClick={this.generateFile} />
          {load ? <Loader text='Please wait...' /> : ''}
          <FlatButton primary label="Display" onClick={this.handleTouchTap} />
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
            onRequestClose={this.handleRequestClose}
            autoFocus={true}
          >
            <DisplayCheckboxes columns={columns} switchColumn={switchColumn} toogleAllColumns={toogleAllColumns}/>
          </Popover>
        </div>
      </CardActions>
    );
  }
}

const mapStateToProps = ({ neonOrdersReducer: { columns } }) => ({
  columns,
});
export default connect(mapStateToProps, { switchColumn: switchColumnAction, toogleAllColumns: toogleAllColumnsAction })(OrderActions);
