import React from 'react';
import { LongTextInput, Edit, SimpleForm, TextInput, required, regex } from 'admin-on-rest';
import { RichTextInput } from '../helpers';

const validateMinLengthToTwo = regex(/\S{2,}/, 'Should contain at leat 2 characters');

const EmailEdit = props => (
  <Edit {...props} hasDelete={false}>
    <SimpleForm>
      <TextInput
        source="name"
        validate={[required, validateMinLengthToTwo]}
        disabled={true}
        options={{
          'disabled': true,
        }}
      />
      <LongTextInput
        source="subject"
        validate={[required, validateMinLengthToTwo]}
      />
      <LongTextInput
        source="text"
        validate={[required, validateMinLengthToTwo]}
      />
      <RichTextInput
        source="html"
        validate={[required, validateMinLengthToTwo]}
      />
    </SimpleForm>
  </Edit>
);

export default EmailEdit;
