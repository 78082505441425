import React, { Component } from 'react';
import { SimpleForm } from 'admin-on-rest';
import { JSONEditor } from 'react-json-editor-viewer';
import ReactJson from 'react-json-view';
import path from '../../path';
import './ratio.style.css';

const EditorStyle = {
  dualView: {
    display: 'flex',
  },
  jsonViewer: {
    borderLeft: '1px solid lightgrey',
    width: '50%',
    margin: 10,
  },
  jsonEditor: {
    width: '50%',
    fontSize: 14,
    fontFamily: 'monospace',
    margin: 10,
  },
  label: {
    color: '#fff',
    marginTop: 4,
  },
  value: {
    marginLeft: 10,
  },
  row: {
    display: 'flex',
  },
  root: {
    fontSize: 14,
    fontFamily: 'monospace',
  },
  withChildrenLabel: {
    color: '#a52a2a',
  },
  select: {
    borderRadius: 3,
    borderColor: '#d3d3d3',
  },
  input: {
    borderRadius: 3,
    border: '1px solid #d3d3d3',
    padding: 3,
  },
  addButton: {
    cursor: 'pointer',
    color: 'black',
    marginLeft: 15,
    fontSize: 14,
  },
  removeButton: {
    cursor: 'pointer',
    color: 'red',
    marginLeft: 15,
    fontSize: 14,
  },
  saveButton: {
    cursor: 'pointer',
    color: 'green',
    marginLeft: 15,
    fontSize: 14,
  },
  builtin: {
    color: '#00f',
  },
  text: {
    color: '#077',
  },
  number: {
    color: '#a0a',
  },
  property: {
    color: '#c00',
  },
  collapseIcon: {
    cursor: 'pointer',
    color: 'rgb(102, 217, 239)',
  },
};

class PricingMiniRatios extends Component {
  defaultState = {
    json: {},
  }
  constructor(props) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount() {
    fetch(`${path}/admin/settings/ratio`, {
      method: 'GET',
      headers: {
        Token: localStorage.getItem('session'),
      },
    }).then(res => res.json())
      .then((res) => {
        this.setState(state => ({
          ...state,
          json: res,
        }));
      });
  }

  handleSubmit = () => {
    console.log('*** handleSubmit');
    const { json } = this.state;
    fetch(`${path}/admin/settings/ratio`, {
      method: 'PUT',
      body: JSON.stringify(json),
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    }).then(res => res.json())
      .then((res) => {
        console.log('*** res', res);
      });
  }

  onJsonChange = (key, value, parent, data) => {
    this.setState(state => ({
      ...state,
      json: data,
    }));
  }

  render() {
    const { json } = this.state;
    return (
      <SimpleForm handleSubmit={this.handleSubmit}>
        <div className="ratio-wp">
          <JSONEditor
            data={ json }
            collapsible
            onChange={ this.onJsonChange }
            className="ratio-editor"
            styles={ EditorStyle }
          />
          <ReactJson src={ json }
            theme="monokai"
            displayDataTypes={true}
            collapseStringsAfterLength={80}
            collapsed={3}
            className="ratio-viewer"
          />
        </div>
      </SimpleForm>
    );
  }
}

export default PricingMiniRatios;
