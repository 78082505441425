import { NumberField, Show, SimpleShowLayout, TextField } from 'admin-on-rest';
import React from 'react';
import { DateField } from '../helpers';
import { formatDateTimeInput, formatDateInput } from '../utils/dates';
import MarketDataMatrix from './MarketDataMatrix';
import MarketDataShowTitle from './MarketDataShowTitle';

const MarketDataShow = props => (
  <Show {...props}
    title={<MarketDataShowTitle />}
  >
    <SimpleShowLayout style={{ display: 'flow-root', padding: '0px 1em 1em' }}>
      <MarketDataMatrix />
      <NumberField
        style={{ textAlign: 'left', display: 'flex' }}
        source="lot_size"
      />
      <TextField
        style={{ display: 'flex' }}
        source="fut_trading_units"
      />
      <NumberField
        style={{ textAlign: 'left', display: 'flex' }}
        source="fut_val_pt"
      />
      <TextField
        style={{ display: 'flex' }}
        source="quote_units"
      />
      <TextField
        style={{ display: 'flex' }}
        source="currency"
      />
      <DateField
        style={{ display: 'flex' }}
        formatDate={formatDateTimeInput}
        source="expiry_date"
        showTime
      />
      <TextField
        style={{ display: 'flex' }}
        source="bloomberg_ticker"
      />
      <DateField
        style={{ display: 'flex' }}
        formatDate={formatDateInput}
        source="contract_code"
        label="Contract Expiry"
        showTime
      />
      <TextField
        style={{ display: 'flex' }}
        source="commodity_contract"
      />
      <DateField
        style={{ display: 'flex' }}
        formatDate={formatDateTimeInput}
        source="updated_at"
        showTime
      />
    </SimpleShowLayout>
  </Show>
);

export default MarketDataShow;
