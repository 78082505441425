import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import RaisedButton from 'material-ui/RaisedButton';
import AutoComplete from 'material-ui/AutoComplete';

import { connect } from 'react-redux';
import { translate, GET_LIST, GET_ONE } from 'admin-on-rest';

import PDFSendIcon from './PDFSendIcon';
import PDFSendAction from './PDFSendAction';

import restClient from '../restClient';
import ValidateEmail from '../helpers/ValidateEmail';

class PDFSendButton extends React.Component {
  state = {
    pdf: false,
    open: false,
    error: false,
    emails: [],
    bcc: [],
    cc: [],
    chips: [],
    clients: [],
    searchText: '',
    clientId: '',
  }

  constructor(props) {
    super(props);
    this.dashboardId = props.record.id;
    this.translate = this.props.translate;
  }

  componentDidMount() {
    const { record } = this.props;

    restClient(GET_LIST, 'pdfs', {
      filter: {
        dashboard_id: record.id,
      },
      sort: {
        field: 'id',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 1,
      },
    }).then((res) => {
      if (res.data[0]) {
        const { id } = res.data[0];
        this.setState({ pdf: id });
      }
    });
  }

  handleOpen() {
    this.setState({ open: true });

    restClient(GET_LIST, 'users', {
      sort: {
        field: 'email',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((res) => {
      this.setState({ chips: res.data.map(i => i.email) });
    });

    restClient(GET_ONE, 'client_database/dashboard', {
      id: this.props.record.id,
    }).then((res) => {
      this.setState({ clients: res.data });
    });
  }

  clientSelected = (client) => {
    restClient(GET_ONE, 'client_database', {
      id: client.id,
    }).then((res) => {
      const {
        statement_signer: emails,
        statement_cc: cc,
        statement_bcc: bcc,
      } = res.data;

      this.setState({
        ...this.state,
        emails: emails || [],
        bcc: bcc || [],
        cc: cc || [],
        searchText: client.clientName,
        clientId: client.id,
      });
    });
  }

  handleClientInputChange = (value) => {
    if (value.length) return;
    this.setState({
      ...this.defaultState,
      chips: this.state.chips,
      clients: this.state.clients,
      open: true,
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleSubmit() {
    if (this.state.emails.length) {
      this.setState({ error: false, open: false });
    } else {
      this.setState({ error: true });
      return;
    }

    const { emails, bcc, cc, clientId, pdf } = this.state;
    this.props.sendAction(pdf, { emails, bcc, cc, clientId, dashboardId: this.dashboardId });
  }

  handleAddEmail(chip) {
    const chips = [...this.state.emails, chip.toLowerCase()];
    this.setState({ emails: chips, error: !chips.length });
  }

  handleAddBCC(chip) {
    const chips = [...this.state.bcc, chip.toLowerCase()];
    this.setState({ bcc: chips });
  }

  handleAddCC(chip) {
    const chips = [...this.state.cc, chip.toLowerCase()];
    this.setState({ cc: chips });
  }

  handleDeleteEmail(chip) {
    const chips = this.state.emails.filter(c => c !== chip);
    this.setState({ emails: chips, error: !chips.length });
  }

  handleDeleteBCC(chip) {
    const chips = this.state.bcc.filter(c => c !== chip);
    this.setState({ bcc: chips });
  }

  handleDeleteCC(chip) {
    const chips = this.state.cc.filter(c => c !== chip);
    this.setState({ cc: chips });
  }

  handleBeforeAddChip(chip) {
    const { emails, bcc, cc } = this.state;
    if (!ValidateEmail(chip)) return false;
    return !emails.includes(chip.toLowerCase()) && !bcc.includes(chip.toLowerCase()) && !cc.includes(chip.toLowerCase());
  }

  dataSource() {
    const { emails, bcc, cc } = this.state;
    return this.state.chips.filter(c => !emails.includes(c) && !bcc.includes(c) && !cc.includes(c));
  }

  render() {
    const { record } = this.props;
    const { pdf, open, emails, bcc, cc, clients, searchText } = this.state;

    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          disabled={!pdf}
          label={this.translate('pdfs.dialog.btnSend')}
          icon={<PDFSendIcon />}
          onClick={this.handleOpen.bind(this)}
        />
        <Dialog
          title={`${this.translate('pdfs.dialog.title')}${record && record.name}`}
          modal={false}
          open={open}
          autoScrollBodyContent={true}
        >
          <AutoComplete
            floatingLabelText="Select client"
            filter={AutoComplete.levenshteinDistance}
            dataSource={clients}
            maxSearchResults={5}
            dataSourceConfig={{ text: 'clientName', value: 'id' }}
            onNewRequest={this.clientSelected}
            onUpdateInput={this.handleClientInputChange}
            openOnFocus
            fullWidth
            searchText={searchText}
          />
          <ChipInput
            value={emails}
            dataSource={this.dataSource()}
            openOnFocus={true}
            onBeforeRequestAdd={this.handleBeforeAddChip.bind(this)}
            onRequestAdd={this.handleAddEmail.bind(this)}
            onRequestDelete={this.handleDeleteEmail.bind(this)}
            errorText={this.state.error && this.translate('aor.validation.required')}
            floatingLabelText={this.translate('pdfs.dialog.emailText')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={cc}
            dataSource={this.dataSource()}
            openOnFocus={true}
            onBeforeRequestAdd={this.handleBeforeAddChip.bind(this)}
            onRequestAdd={this.handleAddCC.bind(this)}
            onRequestDelete={this.handleDeleteCC.bind(this)}
            floatingLabelText={this.translate('pdfs.dialog.ccText')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={bcc}
            dataSource={this.dataSource()}
            openOnFocus={true}
            onBeforeRequestAdd={this.handleBeforeAddChip.bind(this)}
            onRequestAdd={this.handleAddBCC.bind(this)}
            onRequestDelete={this.handleDeleteBCC.bind(this)}
            floatingLabelText={this.translate('pdfs.dialog.bccText')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              label={this.translate('pdfs.dialog.btnCancel')}
              onClick={this.handleClose.bind(this)}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label={this.translate('pdfs.dialog.btnSubmit')}
              primary={true}
              onClick={this.handleSubmit.bind(this)}
              icon={<SubmitIcon />}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

PDFSendButton.propTypes = {
  record: PropTypes.object,
  sendAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    sendAction: PDFSendAction,
  }),
);

export default enhance(PDFSendButton);
