export default {
  agile_code_id: {
    status: true,
    label: 'Agile Code',
    disabled: false,
    type: 'TextField',
  },
  id: {
    status: false,
    label: 'ID',
    disabled: false,
    type: 'TextField',
  },
  neon_order_id: {
    status: false,
    label: 'Vendor ID',
    disabled: false,
    type: 'TextField',
  },
  neon_account_code: {
    status: false,
    label: 'Vendor Account',
    disabled: false,
    type: 'TextField',
  },
  username: {
    status: true,
    label: 'Username',
    disabled: true,
    type: 'DisplayText',
  },
  structure: {
    status: true,
    label: 'Structure',
    disabled: false,
    type: 'DisplayText',
  },
  email: {
    status: false,
    label: 'Email',
    disabled: false,
    type: 'TextField',
  },
  commodity_contract: {
    status: true,
    label: 'Contract',
    disabled: true,
    type: 'TextField',
  },
  contract_code: {
    status: false,
    label: 'Contract Code',
    disabled: false,
    type: 'TextField',
  },
  contract_expiry: {
    status: true,
    label: 'Contract Expiry',
    disabled: false,
    type: 'DateField',
  },
  contract_expiry_second: {
    status: true,
    label: 'Contract Expiry 2',
    disabled: false,
    type: 'DateField',
  },
  direction: {
    status: true,
    label: 'Direction',
    disabled: true,
    type: 'TextField',
  },
  direction_view_mf: {
    status: true,
    label: 'Direction MF',
    disabled: true,
    type: 'DisplayText',
  },
  quantity: {
    status: true,
    label: 'Client/Hedge QTY',
    disabled: true,
    type: 'DisplayText',
  },
  filled_quantity: {
    status: true,
    label: 'Filled',
    disabled: false,
    type: 'DisplayText',
  },
  unit: {
    status: true,
    label: 'Unit',
    disabled: true,
    type: 'TextField',
  },
  factor: {
    status: false,
    label: 'Factor',
    disabled: false,
    type: 'DisplayText',
  },
  remaining_quantity: {
    status: true,
    label: 'Client/Hedge Remaining',
    disabled: false,
    type: 'DisplayText',
  },
  price: {
    status: true,
    label: 'Requested Client/Price',
    disabled: true,
    type: 'DisplayText',
  },
  avg_price: {
    status: false,
    label: 'Avg/Client Price',
    disabled: false,
    type: 'DisplayText',
  },
  status: {
    status: true,
    label: 'Status',
    disabled: false,
    type: 'TextField',
  },
  fx_rate: {
    status: false,
    label: 'Requested Client FX / Rate',
    disabled: false,
    type: 'DisplayText',
  },
  last_edit_by: {
    status: false,
    label: 'Processed by',
    disabled: false,
    type: 'TextField',
  },
  quoted_currency: {
    status: false,
    label: 'Currency',
    disabled: false,
    type: 'DisplayText',
  },
  structure_type: {
    status: true,
    label: 'Type',
    disabled: false,
    type: 'TextField',
  },
  strike_level: {
    status: true,
    label: 'Strike/ AСС Level',
    disabled: false,
    type: 'NumberField',
  },
  canceled_user_id: {
    status: true,
    label: 'Cancelled by',
    disabled: false,
    type: 'DisplayText',
  },
  posted_to_slack: {
    status: true,
    label: 'Posted To Slack',
    disabled: false,
    type: 'TextField',
  },
  is_autobooked: {
    status: true,
    label: 'Autobooked',
    disabled: false,
    type: 'BooleanField',
  },
  canceled_at: {
    status: true,
    label: 'Canceled Date',
    disabled: false,
    type: 'DateField',
  },
  created_at: {
    status: true,
    label: 'Created At',
    disabled: false,
    type: 'DateField',
  },
  errors: {
    status: false,
    label: 'Error',
    disabled: false,
    type: 'TextField',
  },
  time_in_force: {
    status: true,
    label: 'Time in Force',
    disabled: false,
    type: 'DisplayText',
  },
  order_type: {
    status: true,
    label: 'Order Type',
    disabled: false,
    type: 'DisplayText',
  },
  stop_price: {
    status: true,
    label: 'Stop Price',
    disabled: false,
    type: 'DisplayText',
  },
  max_show: {
    status: true,
    label: 'Max Show',
    disabled: false,
    type: 'DisplayText',
  },
  expiry_date: {
    status: true,
    label: 'Expiry Date',
    disabled: false,
    type: 'DateField',
  },
  autoapprove_status: {
    status: true,
    label: 'Auto Approve Status',
    disabled: false,
    type: 'DisplayText',
  },
  skip_hedging: {
    status: false,
    label: 'Skip Hedging',
    disabled: false,
    type: 'BooleanField',
  },
};
