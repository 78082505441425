import React from 'react';
import Avatar from 'material-ui/Avatar';

const MarketDataLogo = ({ record }) => (
  <span>
    <Avatar
      src={record.logo || `https://ui-avatars.com/api/?name=${record.short_code}&background=808080&size=25x25&font-size=0.33&color=ffffff`}
      size={25}
      style={{ verticalAlign: 'middle' }}
    />
  </span>
);

export default MarketDataLogo;
