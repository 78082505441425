/* eslint-disable */
import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  translate,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
  GET_LIST,
  GET_ONE,
} from 'admin-on-rest';
import { isBoolean, sum } from 'lodash';
import TextField from 'material-ui/TextField';
import ChipInput from 'material-ui-chip-input';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import CreateButtonIcon from 'material-ui/svg-icons/content/reply';
import EditIcon from 'material-ui/svg-icons/image/edit';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import Toggle from 'material-ui/Toggle';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { AUTO_BOOK_OPTIONS } from './helpers';
import { removeSpecialChars } from '../helpers/utilities';
import path from '../path';
import restClient from '../restClient';
import { AddUnderlying, UnderlyingList } from './components';
import './components/style.css';

const markupAreas = ['sales', 'ib', 'client'];

const MarkUpItem = ({ area, markupKey, markupState }) => {
  const { markups, errors, onChange, translate } = markupState;
  return (
    <TextField
      errorText={errors && errors[area] && errors[area][markupKey] && translate('aor.validation.required')}
      floatingLabelText={markupKey.toUpperCase()}
      errorStyle={{ display: 'table-caption' }}
      fullWidth
      name={`${area}_markup_${markupKey}`}
      type="text"
      value={(markups && markups[area] && markups[area][markupKey]) || ''}
      multiLine={true}
      onChange={onChange(area, markupKey)}
      maxLength="5000"
      style={{ width: '180px', marginRight: '10px' }}
    />
  );
};
const MarkUpGroup = ({ area, label, markupState }) => {
  const { translate } = markupState;
  return (
    <div className="markup-group">
      <label style={{ color: 'rgba(0, 0, 0, 0.3)', display: 'block', marginTop: '30px' }}>
        {translate(`pricingTemplates.common.${label}`)}
      </label>
      {!markupState.useAbsMarkup ? (
        <Fragment>
          <MarkUpItem area={area} markupKey="x" markupState={markupState} />
          <MarkUpItem area={area} markupKey="y" markupState={markupState} />
          <MarkUpItem area={area} markupKey="z" markupState={markupState} />
        </Fragment>
      ) : (
        <MarkUpItem area={area} markupKey="abs" markupState={markupState} />
      )}
    </div>
  );
};

class CreateButton extends React.Component {
  emptyGroup = { groupName: '', pricingCards: [] };
  emptyCard = { underlying: '', pricingGroups: [], pricingCardsForChips: [], roundingRule: 1 };

  constructor(props) {
    super(props);
    this.translate = this.props.translate;
    this.translationsPrefix = (props.clone && 'pricingTemplates.clone') ||
      (props.edit && 'pricingTemplates.edit') ||
      'pricingTemplates.create';
    this.state = this.getDefaultState();
  }

  getDefaultState = () => ({
    open: false,
    id: null,
    name: '',
    useAbsMarkup: false,
    markups: null,
    users: [],
    user_groups: [],
    underlyingsWithCards: [],
    auto_book: false,
    ib_counterparty: null,
    sales_representative: null,
    errors: {
      markups: {},
      underlying: [],
      pricingCards: [],
      ib_counterparty: null,
      sales_representative: null
    },
    options: {
      ib_counterparty: [],
      sales_representative: []
    }
  });

  closeDialog = () => {
    this.setState({ ...this.getDefaultState() });
  };

  handleOpen = async () => {
    this.setState({ open: true });
    const record = this.props.record;
    const [template, users, user_groups, underlyings, pricingCards, salesRepresentatives, ibCounterparties] = await Promise.all([
      this.getTemplate(record && record.id ? record.id : null),
      this.getUsers(),
      this.getUserGroups(),
      this.getUnderlyings(),
      this.getPricingCards(),
      this.getSalesRepresentatives(),
      this.getIbCounterparties(),
    ]);

    if (this.props.clone) {
      const d = new Date();
      // add some uniqueness to name based on date & time
      template.data.name = 'Copy of ' + template.data.name + ' generated ' + d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
      // cloneTemplateId is used in MS-BE\src\admin\controllers\Accumulator\PricingTemplates.js
      // to clone any card settings which are not shown on this screen
      template.data.cloneTemplateId = template.data.id;
      template.data.id = 0;
    }

    const { underlyingsWithCards } = template.data;
    if (
      underlyingsWithCards.length === 1 &&
      underlyingsWithCards[0].pricingGroups &&
      !underlyingsWithCards[0].pricingGroups.length
    ) {
      template.data.underlyingsWithCards[0].pricingGroups = [{ ...this.emptyGroup }];
    }

    this.setState(state => ({
      ...state,
      ...(template && template.data),
      usersData: users && users.data.map(e => ({ email: e.email, id: e.id })),
      user_groupsData: user_groups && user_groups.data.map(e => ({ name: e.name, id: e.id })),
      underlyings: underlyings && [{}, ...underlyings.data], // empty obj is an empty select option, used to reset group
      pricingCards: pricingCards && pricingCards.data,
      options: {
        ib_counterparty: [{ id: null, name: '' }, ...(ibCounterparties && ibCounterparties.data || [])],
        sales_representative: [{ id: null, name: '' }, ...(salesRepresentatives && salesRepresentatives.data || [])]
      }
    }));
  };

  getTemplate = id =>
    !id
      ? { data: { underlyingsWithCards: [{ ...this.emptyCard }] } }
      : restClient(GET_ONE, 'pricing_templates', { id });

  getUsers = () =>
    restClient(GET_LIST, 'users', {
      sort: { field: 'email', order: 'ASC' },
      pagination: { page: 1, perPage: 100000 },
    });

  getUserGroups = () =>
    restClient(GET_LIST, 'user_groups', {
      sort: { field: 'name', order: 'ASC' },
      pagination: { page: 1, perPage: 100000 },
    });

  getUnderlyings = () => {
    // only for development - it's *much* easier to find the required contract alphabetically, no?
    const sortfield = process.env.NODE_ENV === 'development' ? 'commodity_contract' : 'created_at';
    return restClient(GET_LIST, 'accumulator_underlyings', {
      sort: { field: sortfield, order: 'ASC' },
      pagination: { page: 1, perPage: 1000 },
    });
  }

  getPricingCards = () =>
    restClient(GET_LIST, 'pricing_cards', {
      sort: { field: 'created_at', order: 'ASC' },
      pagination: { page: 1, perPage: 1000 },
    });

  getSalesRepresentatives = () =>
    fetch(`${path}/admin/pricing_templates/sales_representative`, {
      method: 'GET',
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    }).then(response => response.json());

  getIbCounterparties = () =>
    fetch(`${path}/admin/pricing_templates/ib_counterparty`, {
      method: 'GET',
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    }).then(response => response.json());

  mapGroups(underlyingsWithCards) {
    const mapped = underlyingsWithCards.reduce((acc, card) => {
      const { pricingGroups = [], underlying, roundingRule } = card;

      pricingGroups.forEach((card) => {
        if (!card.groupName) {
          card.groupName = 'Ungrouped';
        }

        acc.groupNames.add(card.groupName);

        acc.mapedUnderlyingsWithCards.push({
          group: { name: card.groupName },
          pricingCards: card.pricingCards,
          underlying,
          roundingRule,
        });
      })

      return acc;
    }, { groupNames: new Set(), mapedUnderlyingsWithCards: [] });

    return {
      ...mapped,
      groupNames: Array.from(mapped.groupNames)
    }
  }

  handleSubmit = async () => {
    const { open, errors, emails, usersData, user_groupsData,
      underlyings, pricingCards, users, user_groups, ...body } = this.state;
    if (!body.underlyingsWithCards || !body.underlyingsWithCards.length) {
      await this.handleAddBox();
    }
    const validationErrors = this.getValidationErrors();
    if (Object.keys(validationErrors).length) {
      return this.setState({ errors: validationErrors });
    }

    const { showNotification, refreshView } = this.props;
    body.underlyingsWithCards = this.mapGroups(body.underlyingsWithCards.filter(
      el => typeof el === 'object' && el.constructor === Object
    ));

    body.users = users && users.map(e => e.id);
    body.user_groups = user_groups && user_groups.map(e => e.id);
    // clear values based on useAbsMarkup
    for (const area of markupAreas) {
      const markup = this.state.markups[area];
      body.markups[area] = this.state.useAbsMarkup ? { abs: markup.abs } : { ...markup, abs: undefined };
    }

    this.setState({ open: false }, () => {
      const id = this.state.id || '';
      const method = (this.props.edit && 'PUT') || 'POST';
      fetch(`${path}/admin/pricing_templates/${id}`, {
        method,
        body: JSON.stringify(body),
        headers: {
          Token: localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
        .then(() => {
          showNotification(`${this.translationsPrefix}.successful`);
          refreshView();
          return this.setState({
            ...this.defaultState,
            underlyingsWithCards: [{ ...this.emptyCard }],
          });
        })
        .catch(e => {
          console.error(e);
          showNotification('Error', 'warning');
        });
    });
    return null;
  };

  getValidationErrors() {
    const { name, markups, users, user_groups, underlyingsWithCards, useAbsMarkup, sales_representative, ib_counterparty, auto_book } = this.state;
    const validationErrors = {
      markups: {},
      underlying: [],
      roundingRule: [],
      pricingCards: [],
      groups: [],
    };

    if (!name) validationErrors.name = true;
    if (auto_book && !sales_representative) validationErrors.sales_representative = this.translate('aor.validation.required');

    const keys = useAbsMarkup ? ['abs'] : ['x', 'y', 'z'];
    const ibMarkup = [];
    for (const area of markupAreas) {
      for (const key of keys) {
        const value = markups && markups[area] && markups[area][key];
        if (value) {
          if(area === 'ib') ibMarkup.push(parseFloat(value))
          continue;
        }
        if (!validationErrors.markups[area]) validationErrors.markups[area] = {};
        validationErrors.markups[area][key] = true; // required
      }
    }

    if (!ib_counterparty && sum(ibMarkup) > 0) validationErrors.ib_counterparty = this.translate('aor.validation.required');

    if ((!users || !users.length) && (!user_groups || !user_groups.length)) {
      validationErrors.users = true; // allow either users or user_groups to be empty but not both
    }

    const validateRoundingSimilarRules = new Set();
    underlyingsWithCards.forEach((card, index) => {
      const { underlying, pricingGroups = [], roundingRule } = card;
      pricingGroups.forEach((group, i) => {
        const { pricingCards = [], pricingCardsForChips = [] } = group;

        if (!(pricingCards.length && pricingCardsForChips.length)) {
          const { pricingCards: errorPricingCard } = validationErrors;
          if (!Array.isArray(errorPricingCard[index])) errorPricingCard[index] = [];
          validationErrors.pricingCards[index][i] = true;
        }

        if (pricingCards.length) {
          pricingCards.forEach(card => {
            const similarRuleIndex = `${underlying}_${card}`;

            if (validateRoundingSimilarRules.has(similarRuleIndex)) {
              if (!validationErrors.groups[index]) validationErrors.groups[index] = [];
              validationErrors.groups[index][i] = true;
            } else {
              validateRoundingSimilarRules.add(similarRuleIndex);
            }
          });
        }
      });
      if (!Number(roundingRule)) {
        validationErrors.roundingRule[index] = true;
      }
      if (!underlying) {
        validationErrors.underlying[index] = true;
      }
    });

    if (markupAreas.every(area => !validationErrors.markups[area])) delete validationErrors.markups;
    if (!validationErrors.pricingCards.length) delete validationErrors.pricingCards;
    if (!validationErrors.underlying.length) delete validationErrors.underlying;
    if (!validationErrors.roundingRule.length) delete validationErrors.roundingRule;
    if (!validationErrors.groups.length) delete validationErrors.groups;

    return validationErrors;
  }

  handleInputChange = inputName => (...event) =>
    this.setState({
      [inputName]: removeSpecialChars(event[1]),
      errors: {
        ...this.state.errors,
        [inputName]: false,
      },
    });

  handleToggleUseAbsMarkup = (_, isInputChecked) => {
    this.setState({ useAbsMarkup: isInputChecked });
  };

  handleMarkupsInputChange = (area, markupKey) => (...event) => {
    const value = event[1];
    const { errors } = this.state;
    errors.markups = errors.markups || {};
    errors.markups[area] = errors.markups[area] || {};
    errors.markups[area][markupKey] = !value;
    const markups = this.state.markups || {};
    markups[area] = markups[area] || {};
    markups[area][markupKey] = value;
    this.setState({
      markups,
      errors,
    });
  };

  handleSelectUnderlyingChange = selected => (...event) => {
    const [, , value] = event;
    const { underlyingsWithCards } = this.state;
    const [, type] = selected.split('_');
    const id = parseInt(type, 10);

    underlyingsWithCards[id] = {
      underlying: value,
      pricingGroups: underlyingsWithCards[id].pricingGroups || [],
      roundingRule: underlyingsWithCards[id].roundingRule || 1,
    };

    const underlying = this.state.errors.underlying || [];
    underlying[id] = false;

    this.setState({
      underlyingsWithCards: [...underlyingsWithCards],
      errors: {
        ...this.state.errors,
        underlying,
      },
    });
  };

  usersDataSource() {
    const { usersData = [], users } = this.state;
    return usersData.filter(email => !users.includes(email));
  }

  user_groupsDataSource() {
    const { user_groupsData = [], user_groups } = this.state;
    return user_groupsData.filter(name => !user_groups.includes(name));
  }

  pricingCardsDataSource = index => {
    const { pricingCards = [], underlyingsWithCards } = this.state;
    const { underlying } = underlyingsWithCards[index];

    const excluded = underlyingsWithCards.reduce((prev, cur) => {
      if (underlying === cur.underlying) {
        cur.pricingGroups.forEach(currGroup => {
          prev.push(...currGroup.pricingCards);
        });
      }
      return prev;
    }, []);
    return pricingCards.filter(({ id }) => !excluded.includes(id));
  };

  handleAddChips = type => chip => {
    const chips = [...this.state[type], typeof chip === 'object' ? chip : chip.toLowerCase()];
    this.setState({
      [type]: chips,
      errors: { ...this.state.errors, [type]: false },
    });
  };

  handleBeforeAddChip = type => chip => {
    const selected = this.state[type];
    return !selected.some(item => item.id === chip.id);
  };

  handleDeleteChips = type => chip => {
    const chips = this.state[type].filter(c => c.id !== chip);
    this.setState({ [type]: chips });
  };

  handleBeforeAddPricingCardChip = type => groupIndex => chip => {
    const id = parseInt(type, 10);
    const { underlyingsWithCards } = this.state;
    const cards = underlyingsWithCards[id].pricingGroups[groupIndex].pricingCards || [];
    return !cards.includes(chip.id);
  };

  handleAddPricingCardChips = type => groupIndex => chip => {
    const id = parseInt(type, 10);
    const { underlyingsWithCards } = this.state;
    const cards = [...underlyingsWithCards[id].pricingGroups[groupIndex].pricingCards] || [];
    const data = chip.id;
    cards.push(data);
    underlyingsWithCards[id].pricingGroups[groupIndex].pricingCards = [...cards];

    const { pricingCards = [] } = this.state.errors;
    pricingCards[id] = false;

    this.setState({
      underlyingsWithCards: [...underlyingsWithCards],
      errors: {
        ...this.state.errors,
        pricingCards,
      },
    });
  };

  handleDeletePricingCardChips = type => groupIndex => chip => {
    const id = parseInt(type, 10);
    const { underlyingsWithCards } = this.state;
    const cards = underlyingsWithCards[id].pricingGroups[groupIndex].pricingCards || [];
    underlyingsWithCards[id].pricingGroups[groupIndex].pricingCards = cards.filter(c => c !== chip);
    this.setState({ underlyingsWithCards });
  };

  handleAddBox = () => {
    const emptyCard = { ...this.emptyCard };
    emptyCard.pricingGroups = [{ ...this.emptyGroup }];
    this.setState(state => ({
      ...state,
      underlyingsWithCards: [...state.underlyingsWithCards, emptyCard],
    }));
  };

  handleAddGroup = i => j => () => {
    const { underlyingsWithCards } = this.state;

    if (underlyingsWithCards[i].pricingGroups[j].pricingCards.length >= 1) {
      underlyingsWithCards[i].pricingGroups = [...underlyingsWithCards[i].pricingGroups, { ...this.emptyGroup }];
      this.setState(state => ({
        ...state,
        underlyingsWithCards,
      }));
    }
  };

  handleCloseBox = index => {
    const underlyingsWithCards = [...this.state.underlyingsWithCards];
    underlyingsWithCards.splice(index, 1);
    this.setState(state => ({
      ...state,
      underlyingsWithCards,
    }));
  };

  handleChangeRule = index => (...event) => {
    const regValidation = /^(?:[1-9]\d*|0)?(?:(\.)\d*)?$/;
    const underlyingsWithCards = [...this.state.underlyingsWithCards];
    if (underlyingsWithCards[index] && event[1].match(regValidation)) {
      underlyingsWithCards[index].roundingRule = event[1];

      const roundingRule = this.state.errors.roundingRule || [];
      roundingRule[index] = false;

      this.setState(state => ({
        ...state,
        underlyingsWithCards,
        errors: {
          ...this.state.errors,
          roundingRule,
        },
      }));
    }
  };

  handleChangeGroup = index => i => (...event) => {
    const underlyingsWithCards = [...this.state.underlyingsWithCards];
    if (underlyingsWithCards[index]) {
      underlyingsWithCards[index].pricingGroups[i].groupName = event[1];
      this.setState(state => ({
        ...state,
        underlyingsWithCards,
      }));
    }
  };

  handleCloseGroup = underlyingIndex => groupIndex => {
    const underlyingsWithCards = [...this.state.underlyingsWithCards];
    if (underlyingsWithCards[underlyingIndex].pricingGroups.length > 1) {
      underlyingsWithCards[underlyingIndex].pricingGroups.splice(groupIndex, 1);
      this.setState(state => ({
        ...state,
        underlyingsWithCards,
      }));
    }
  };

  handleSelectChange = selected => (...event) => {
    const [, , value] = event;
    this.setState({ [selected]: isBoolean(value) ? value : value || null });
  };

  render() {
    const {
      name,
      users,
      user_groups,
      underlyingsWithCards,
      underlyings = [],
      pricingCards = [],
      errors: {
        markups: errMarkups = {},
        underlying: errUnderlying = [],
        pricingCards: errPricingCards = [],
        roundingRule: errRoundingRule = [],
        groups: errGroups = [],
        ib_counterparty: ibCounterpartyError = null,
        sales_representative: salesRepresentativeError = null,
      },
      options: {
        ib_counterparty: ibCounterpartyOptions = [],
        sales_representative: salesRepresentativeOptions = [],
      }
    } = this.state;

    const btnIcon = (this.props.edit && <EditIcon />) || <CreateButtonIcon />;
    const markupState = {
      markups: this.state.markups,
      errors: errMarkups,
      translate: this.translate,
      useAbsMarkup: this.state.useAbsMarkup,
      onChange: this.handleMarkupsInputChange,
    };

    const underlyingsWithCardsCopy = [...underlyingsWithCards].map(el => {
      el.pricingGroups &&
        el.pricingGroups.forEach(pricingGroup => {
          pricingGroup.pricingCardsForChips =
            pricingGroup.pricingCards &&
            pricingGroup.pricingCards.map(cardId => pricingCards.filter(card => card.id === cardId)[0]);
        });
      return el || [];
    });

    const actions = [
      <RaisedButton
        label={this.translate('pricingCards.dialog.btnCancel')}
        onClick={this.closeDialog}
        icon={<CancelIcon />}
      />,
      <RaisedButton
        type="submit"
        style={{ marginLeft: '10px' }}
        label={this.translate('pricingCards.dialog.btnSubmit')}
        primary={true}
        icon={<SubmitIcon />}
        onClick={this.handleSubmit}
      />,
    ];


    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label={this.translate(`${this.translationsPrefix}.buttonText`)}
          icon={btnIcon}
          onClick={this.handleOpen}
        />
        <Dialog
          title={this.translate(`${this.translationsPrefix}.title`)}
          modal={false}
          autoScrollBodyContent={true}
          open={this.state.open}
          actions={actions}
          style={{ marginTop: '-10vh' }}
        >
          <TextField
            errorText={this.state.errors.name && this.translate('aor.validation.required')}
            floatingLabelText={this.translate(`${this.translationsPrefix}.name`)}
            fullWidth
            name="name"
            type="text"
            value={name}
            multiLine={true}
            onChange={this.handleInputChange('name')}
            maxLength="200"
          />
          <ChipInput
            errorText={this.state.errors.users && this.translate('aor.validation.required')}
            value={users}
            openOnFocus={true}
            dataSourceConfig={{ text: 'email', value: 'id' }}
            floatingLabelText={this.translate(`${this.translationsPrefix}.users`)}
            dataSource={this.usersDataSource()}
            onRequestAdd={this.handleAddChips('users')}
            onRequestDelete={this.handleDeleteChips('users')}
            onBeforeRequestAdd={this.handleBeforeAddChip('users')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            errorText={this.state.errors.user_groups && this.translate('aor.validation.required')}
            value={user_groups}
            openOnFocus={true}
            dataSourceConfig={{ text: 'name', value: 'id' }}
            floatingLabelText={this.translate(`${this.translationsPrefix}.user_groups`)}
            dataSource={this.user_groupsDataSource()}
            onRequestAdd={this.handleAddChips('user_groups')}
            onRequestDelete={this.handleDeleteChips('user_groups')}
            onBeforeRequestAdd={this.handleBeforeAddChip('user_groups')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <h3>Markups:</h3>
          <Toggle
            label={this.translate('pricingTemplates.common.useAbsMarkup')}
            onToggle={this.handleToggleUseAbsMarkup}
            toggled={this.state.useAbsMarkup}
            style={{ width: '300px' }}
          />
          <MarkUpGroup area="sales" label="markupSales" markupState={markupState} />
          <MarkUpGroup area="ib" label="markupIb" markupState={markupState} />
          <MarkUpGroup area="client" label="markupClient" markupState={markupState} />

          <h3>Auto Book:</h3>
          <SelectField
            fullWidth
            name="auto_book"
            onChange={this.handleSelectChange('auto_book')}
            floatingLabelText="Auto Book"
            value={this.state.auto_book}
          >
            {AUTO_BOOK_OPTIONS.map(x => (
              <MenuItem key={x.name} value={x.id} primaryText={x.name} />
            ))}
          </SelectField>
          <SelectField
            fullWidth
            errorText={ibCounterpartyError}
            name="ib_counterparty"
            onChange={this.handleSelectChange('ib_counterparty')}
            floatingLabelText="IB Counterparty"
            value={this.state.ib_counterparty}
          >
            {ibCounterpartyOptions.map(x => (
              <MenuItem key={x.id} value={x.id} primaryText={x.name} />
            ))}
          </SelectField>
          <SelectField
            fullWidth
            errorText={salesRepresentativeError}
            name="sales_representative"
            onChange={this.handleSelectChange('sales_representative')}
            floatingLabelText="Sales Representative"
            value={this.state.sales_representative}
          >
            {salesRepresentativeOptions.map(x => (
              <MenuItem key={x.id} value={x.id} primaryText={x.name} />
            ))}
          </SelectField>

          <h3>Underlyings:</h3>
          <div className="markups-wrapper">
            {underlyingsWithCards.map((el, i) => (
              <UnderlyingList
                key={i}
                index={i}
                translate={this.translate}
                translationsPrefix={this.translationsPrefix}
                underlyings={underlyings}
                handleAddGroup={this.handleAddGroup(i)}
                underlyingsWithCardsCopy={underlyingsWithCardsCopy}
                pricingCards={this.pricingCardsDataSource(i)}
                handleCloseBox={this.handleCloseBox}
                handleSelectUnderlyingChange={this.handleSelectUnderlyingChange(`underlyingsWithCardsGroup_${i}`)}
                handleChangeRule={this.handleChangeRule(i)}
                handleChangeGroup={this.handleChangeGroup(i)}
                handleCloseGroup={this.handleCloseGroup(i)}
                errUnderlying={errUnderlying[i] || false}
                errPricingCards={errPricingCards[i] || []}
                errRoundingRule={errRoundingRule[i] || false}
                errGroups={errGroups[i] || false}
                chips={{
                  onBeforeRequestAdd: this.handleBeforeAddPricingCardChip(`${i}`),
                  onRequestAdd: this.handleAddPricingCardChips(`${i}`),
                  onRequestDelete: this.handleDeletePricingCardChips(`${i}`),
                }}
              />
            ))}
          </div>
          <AddUnderlying onClick={this.handleAddBox} text={'Add Underlying'} />
        </Dialog>
      </div>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  })
);

export default enhance(CreateButton);
