import React from 'react';
import {
  Edit,
  translate,
} from 'admin-on-rest';

import { LocationForm } from './components';

const LocationTitle = translate(({ record }) =>
  <span>Edit { record.name } location</span>);

const EditLocation = props =>
  <Edit {...props} title={<LocationTitle />}>
    <LocationForm />
  </Edit>;

export default EditLocation;
