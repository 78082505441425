import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CardActions } from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import Popover from 'material-ui/Popover';
import { RefreshButton } from 'admin-on-rest';
import DownloadIcon from 'material-ui/svg-icons/action/get-app';
import {
  switchColumn as switchColumnAction,
  toggleAllColumns as toggleAllColumnsAction,
} from '../actions';
import DisplayCheckboxes from './DisplayCheckboxes';
import path from '../../path';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

class StructuredProductsPricingActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleTouchTap = this.handleTouchTap.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  handleTouchTap(event) {
    event.preventDefault();
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  }

  handleRequestClose() {
    this.setState({
      open: false,
    });
  }

  getFileName() {
    const base = 'Structured_Products_Pricing';
    const date = moment().format('DD_MM_YYYY');
    return `${base}_${date}.csv`;
  }

  getUrl() {
    const url = new URL(`${path}/admin/structured_products_pricing/download`);
    const { columns, filterValues } = this.props;
    const keys = columns && Object.keys(columns);

    const activeColumns = keys && keys.length ?
      keys.reduce((acc, key) => {
        if (columns[key] && columns[key].status) {
          acc.push(key);
        }
        return acc;
      }, []) : null;
    const params = {
      columns: activeColumns,
      ...filterValues,
    };

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    return url;
  }

  download() {
    const url = this.getUrl();
    fetch(url, {
      method: 'GET',
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      .then((href) => {
        const div = document.getElementsByClassName('body')[0];
        const link = document.createElement('a');
        link.setAttribute('href', href);
        link.setAttribute('download', this.getFileName());
        div.appendChild(link);
        link.click();
        div.removeChild(link);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  render() {
    const { resource, filters, displayedFilters, filterValues, showFilter, columns, switchColumn, toggleAllColumns } = this.props;
    const { open, anchorEl } = this.state;
    return (
      <CardActions style={cardActionStyle}>
        {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' })}
        <RefreshButton />
        <div style={{ display: 'inline-block' }}>
          <FlatButton
            icon={<DownloadIcon />}
            primary
            label="Download"
            onClick={this.download.bind(this)}
          />
        </div>
        <div style={{ display: 'inline-block' }}>
          <FlatButton primary label="Display" onClick={this.handleTouchTap} />
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
            onRequestClose={this.handleRequestClose}
            autoFocus={true}
          >
            <DisplayCheckboxes columns={columns} switchColumn={switchColumn} toggleAllColumns={toggleAllColumns} />
          </Popover>
        </div>
      </CardActions>
    );
  }
}

const mapStateToProps = ({ structuredProductsPricingReducer: { columns } }) => ({
  columns,
});

export default connect(mapStateToProps, {
  switchColumn: switchColumnAction,
  toggleAllColumns: toggleAllColumnsAction,
})(StructuredProductsPricingActions);
