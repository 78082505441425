import React from 'react';
import Dropzone from 'react-dropzone';
import request from 'superagent';
import FlatButton from 'material-ui/FlatButton';
import FileUploadIcon from 'material-ui/svg-icons/file/file-upload';
import CircularProgress from 'material-ui/CircularProgress';
import compose from 'recompose/compose';
import ToggleDisplay from 'react-toggle-display';

import { connect } from 'react-redux';
import { translate, refreshView, showNotification } from 'admin-on-rest';

import path from '../path';

class FileUploadButton extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
    this.progres = 0;
    this.state = { value: 0 };
  }

  onDrop(files) {
    this.progres = files.length;

    const datasetFile = files
      .filter(i => /\.xlsx/.test(i.name));

    datasetFile.forEach((f) => {
      request
        .post(`${path}/admin/datasets`)
        .auth('FileEndpoint', 'QAZwsx741852')
        .field('file', f)
        .then(() => {
          this.progres -= 1;
          this.setState({
            value: this.progres,
          });
          if (!this.progres) {
            this.props.showNotification('files.notification.upload_success');
            this.props.refreshView();
          }
        })
        .catch(() => {
          this.progres -= 1;
          this.props.showNotification('files.notification.upload_error');
          this.props.refreshView();
          this.setState({
            value: this.progres,
          });
        });
    });
  }

  get show() {
    return !!this.progres;
  }

  get primary() {
    return !this.progres;
  }

  get disabled() {
    return !!this.progres;
  }

  get label() {
    return this.translate('files.list.upload');
  }

  render() {
    return (
      <Dropzone
        className="Dropzone"
        onDrop={this.onDrop.bind(this)}
        multiple={true}
        accept=".xls,.xlsx"
      >
        <ToggleDisplay
          show={this.show}
        >
          <CircularProgress
            className="CircularProgress"
            size={21}
            thickness={2}
          />
        </ToggleDisplay>
        <FlatButton
          primary={this.primary}
          disabled={this.disabled}
          label={this.label}
          icon={<FileUploadIcon/>}
        />
      </Dropzone>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    refreshView,
    showNotification,
  }),
);

export default enhance(FileUploadButton);
