import { ChipField, Datagrid, EditButton, FunctionField, ListButton, NumberField, ReferenceArrayField, ReferenceField, ReferenceManyField, RefreshButton, SelectField, Show, ShowButton, SingleFieldList, Tab, TabbedShowLayout, TextField, translate, BooleanField } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import React from 'react';
import { DateField } from '../helpers';
import { formatDateTimeInput } from '../utils/dates';
import UserApproveButton from './UserApproveButton';
import UserRejectButton from './UserRejectButton';
import { withPendingStatusesSource } from './helpers';

const UserTitle = translate(({ record }) =>
  <span>{translate('users.show.title', { smart_count: 1 })} {record.email}</span>);

const UserShowActions = ({ basePath, data, refresh }) => (
  <CardActions
    className="CardActions"
  >
    <UserApproveButton
      record={data}
    />
    <UserRejectButton
      record={data}
    />
    <EditButton
      basePath={basePath}
      record={data}
    />
    <ListButton
      basePath={basePath}
    />
    <RefreshButton
      refresh={refresh}
    />
  </CardActions>
);

const UserShow = props => (
  <Show {...props}
    title={<UserTitle />}
    actions={<UserShowActions />}
  >
    <TabbedShowLayout>
      <Tab label="users.show.info">
        <TextField
          source="firstName"
          label="First Name"
        />
        <TextField
          source="lastName"
          label="Last Name"
        />
        <TextField
          source="email"
        />
        <TextField
          label="Slack Id"
          source="slackId"
        />
        <TextField
          label="Volatility Rule"
          source="ruleName"
        />
        <SelectField
          source="status"
          choices={withPendingStatusesSource}
        />
        <ReferenceArrayField
          label="Dashboards"
          reference="dashboards"
          source="dashboardIds"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          label="Roles"
          reference="roles"
          source="roleIds"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField
          source="spreadsheetId"
          reference="spreadsheets"
          label="Spread sheet"
          linkType={false}
          allowEmpty
        >
          <TextField source="title" />
        </ReferenceField>
        <BooleanField
          source="greeks"
          label="Greeks Display"
        />
      </Tab>
      <Tab label="users.show.history">
        <ReferenceManyField addLabel={false} reference="pricing_histories" target="user_id">
          <Datagrid filter={{ status: 'approved' }}>
            <DateField
              formatDate={formatDateTimeInput}
              source="created_at"
              label="Time"
              showTime
            />
            <ReferenceField
              label="Strucutre"
              source="structure_id"
              reference="structures"
              linkType="show"
            >
              <FunctionField
                render={r => r.code}
              />
            </ReferenceField>
            <ReferenceField
              label="Bloomberg Ticker"
              source="underlying_id"
              reference="underlyings"
              linkType="show"
            >
              <FunctionField
                render={r => r.bloomberg_ticker}
              />
            </ReferenceField>
            <ReferenceField
              label="Underlying"
              source="underlying_id"
              reference="underlyings"
              linkType="show"
            >
              <FunctionField
                render={r => r.reference_contract}
              />
            </ReferenceField>
            <NumberField
              source="notional"
            />
            <TextField
              source="currency"
            />
            <TextField
              source="type"
            />
            <NumberField
              source="reference_price"
            />
            <NumberField
              label="Price"
              source="value"
            />
            <NumberField
              label="Accumulation"
              source="strike_level"
            />
            <NumberField
              label="Barrier"
              source="barrier_level"
            />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default UserShow;
