import React from 'react';
import Checkbox from 'material-ui/Checkbox';

const CheckboxCustom = (props) => {
  const { record, field, label } = props;
  const status = record[props.source];
  const handleClick = () => props.onClick(record.id, status, field, label);
  return <Checkbox onClick={handleClick} checked={status}/>;
};

export default CheckboxCustom;
