export const GET_HISTORY_DATA = 'GET_HISTORY_DATA';
export const loadingHistory = (orderId) => ({
  type: GET_HISTORY_DATA,
  payload: orderId,
});

export const RESPONSE_HISTORY_DATA = 'RESPONSE_HISTORY_DATA';
export const historyLoaded = (title = '', general = {}, logs = []) => ({
  type: RESPONSE_HISTORY_DATA,
  payload: {
    title,
    general,
    logs,
  },
});
