import React from 'react';
import Avatar from 'material-ui/Avatar';
import WelcomeIcon from 'material-ui/svg-icons/action/lightbulb-outline';
import PlaygroundlIcon from 'material-ui/svg-icons/action/code';
import GraphdocIcon from 'material-ui/svg-icons/action/settings-ethernet';
import VersionIcon from 'material-ui/svg-icons/action/info-outline';
import FlatButton from 'material-ui/FlatButton';

import { translate } from 'admin-on-rest';
import { Card, CardHeader, CardActions } from 'material-ui/Card';

import path from '../path';

class Welcome extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;

    this.state = {
      isEnv: new Set(['dev', 'local', 'test']),
      vanillaVersionDll: '',
      swapVersionDll: '',
    };
  }

  componentDidMount() {
    fetch(`${path}/neon/get_dll_version/`, { method: 'GET',
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      } })
      .then(response => response.json())
      .then((res) => {
        this.setState({
          vanillaVersionDll: res.vanilla,
          swapVersionDll: res.swap,
        });
      })
      .catch(() => {
        console.error('Something went wrong', 'warning');
      });
  }

  render() {
    const { isEnv, swapVersionDll, vanillaVersionDll } = this.state;
    return (
      <Card
        style={{ marginBottom: '2em' }}
      >
        <CardHeader
          title={this.translate('dashboard.welcome.title')}
          subtitle={this.translate('dashboard.welcome.subtitle')}
          avatar={<Avatar backgroundColor="#FFEB3B" icon={<WelcomeIcon />} />}
          actAsExpander={true}
          showExpandableButton={true}
        />
        <CardActions
          style={{ textAlign: 'right', padding: '10px 0' }}
          expandable={true}
        >
          <FlatButton
            disabled
            label={`Swap Dll version: ${swapVersionDll && swapVersionDll.length ? swapVersionDll : '-'}`}
            icon={<VersionIcon />}
            href="#"
          />
          <FlatButton
            disabled
            label={`Vanilla Dll version: ${vanillaVersionDll && vanillaVersionDll.length ? vanillaVersionDll : '-'}`}
            icon={<VersionIcon />}
            href="#"
          />
          <FlatButton
            disabled
            label={process.env.REACT_APP_VERSION}
            icon={<VersionIcon />}
            href="#"
          />
          {isEnv.has(process.env.REACT_APP_ENV) ? <FlatButton
            target="_blank"
            label={this.translate('dashboard.welcome.graphdoc')}
            icon={<GraphdocIcon />}
            href={`${path}/schema/index.html`}
          /> : ''}
          {isEnv.has(process.env.REACT_APP_ENV) ? <FlatButton
            target="_blank"
            label={this.translate('dashboard.welcome.playground')}
            icon={<PlaygroundlIcon />}
            href={`${path}/playground`}
          /> : ''}
        </CardActions>
      </Card>
    );
  }
}

export default translate(Welcome);
