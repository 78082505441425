import React from 'react';
import { BooleanInput, DateInput, Filter, SelectInput, TextInput } from 'admin-on-rest';
import { formatDateInput } from '../../utils/dates';

export const DashboardFilter = props => (
  <Filter {...props}>
    <TextInput
      label="Search (Title, SEO)"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="seo"
    />
    <TextInput
      source="title"
    />
    <TextInput
      source="description"
    />
    <SelectInput
      source="position"
      choices={[
        { id: 'footer', name: 'Footer' },
        { id: 'header', name: 'Header' },
      ]}
    />
    <SelectInput
      label="Default footer"
      source="defaultFooter"
      choices={[
        { id: true, name: 'Yes' },
        { id: false, name: 'No' },
      ]}
    />
    <BooleanInput
      source="status"
    />
    <TextInput
      source="type"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updatedAt"
      showTime
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="createdAt"
      showTime
    />
  </Filter>
);
