import { Datagrid, DateInput, Filter, List, Responsive, RichTextField, SimpleList, TextField, TextInput } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import { formatDateTimeInput, formatDateInput } from '../utils/dates';

const RoleFilter = props => (
  <Filter {...props}>
    <TextInput
      label="roles.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="name"
    />
    <TextInput
      source="description"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
    />
  </Filter>
);

const RoleList = props => (
  <List {...props}
    filters={<RoleFilter />}
    sort={{ field: 'created_at', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => r.name}
          secondaryText={r => r.description}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
        />
      }
      medium={
        <Datagrid>
          <TextField
            source="name"
          />
          <RichTextField
            source="description"
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="updated_at"
            showTime
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="created_at"
            showTime
          />
        </Datagrid>
      }
    />
  </List>
);

export default RoleList;
