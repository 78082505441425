import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import LinearProgress from 'material-ui/LinearProgress';
import { graphql } from '@apollo/client/react/hoc';
import MessageList from './MessageList';

import {
  GRAPHQL_NETWORK_ONLY,
  NOTIFICATIONS_QUERY,
  queryOptionsProvider,
  mapNodes,
  SUBSCRIBE_TO_MORE,
} from './../helpers';

const FILTER = [{ operator: 'eq', value: 'ADMIN', field: 'type' }];

class MessageScroll extends Component {
  componentDidMount() {
    this.props.subscribeToMore(SUBSCRIBE_TO_MORE);
  }

  componentWillReceiveProps({ reset }) {
    const { reset: oldReset, refetch, total } = this.props;
    if (reset && reset !== oldReset) {
      refetch({
        total,
        filter: FILTER,
      });
    }
  }

  render() {
    const { messagesList = {}, onRead, onDelete, loadMore, loading } = this.props;
    const items = mapNodes(messagesList);
    const hasNextPage = messagesList && messagesList.pageInfo && messagesList.pageInfo.hasNextPage;
    return (
      <div className="card-list">
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          threshold={10}
          useWindow={false}
          hasMore={!loading && hasNextPage}
          loader={<LinearProgress />}
        >
          {items && items.length ? <MessageList items={items} onRead={onRead} onDelete={onDelete} /> : <div className="empty-list">You do not have any notifications!</div>}
        </InfiniteScroll>
      </div>
    );
  }
}

MessageScroll.propTypes = {
  onRead: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

MessageScroll.defaultProps = {
  total: 5,
};

const options = ({ total }) => ({
  fetchPolicy: GRAPHQL_NETWORK_ONLY,
  variables: {
    total,
    filter: FILTER,
  },
});

export const NOTIFICATIONS_QUERY_OPTIONS = queryOptionsProvider(NOTIFICATIONS_QUERY, 'messagesList', options);

export default compose(
  graphql(NOTIFICATIONS_QUERY, NOTIFICATIONS_QUERY_OPTIONS),
)(MessageScroll);
