import React from 'react';

import { Show, SimpleShowLayout, translate, ChipField } from 'admin-on-rest';

const PDFHistoryTitle = translate(({ record }) =>
  <span>{translate('resources.pdf_histories.show.title', { smart_count: 1 })} { record.id }</span>);

const PDFHistoryShow = props => (
  <Show {...props}
    title={<PDFHistoryTitle />}
  >
    <SimpleShowLayout>
      <ChipField source='emails' />
      <ChipField source='cc' />
      <ChipField source='bcc' />
    </SimpleShowLayout>
  </Show>
);

export default PDFHistoryShow;
