import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import muiThemeable from 'material-ui/styles/muiThemeable';
import Checkbox from 'material-ui/Checkbox';
import { Field } from 'redux-form';
import {
  FieldTitle,
} from 'admin-on-rest';

const getStyles = (muiTheme) => {
  const {
    baseTheme,
    textField: { floatingLabelColor, backgroundColor },
  } = muiTheme;

  return {
    labelContainer: {
      fontSize: 16,
      lineHeight: '24px',
      display: 'inline-block',
      position: 'relative',
      backgroundColor,
      fontFamily: baseTheme.fontFamily,
      cursor: 'auto',
      marginTop: 14,
    },
    label: {
      color: floatingLabelColor,
      lineHeight: '22px',
      zIndex: 1,
      transform: 'scale(0.75)',
      transformOrigin: 'left top',
      pointerEvents: 'none',
      userSelect: 'none',
    },
  };
};

class MyCheckbox extends Component {
  handleCheck = (event, isChecked) => {
    const { input: { onChange } } = this.props;
    onChange(isChecked);
  };

  render() {
    const { input: { value }, ...rest } = this.props;
    return (<Checkbox
      onCheck={this.handleCheck}
      checked={value}
      {...rest}
    />);
  }
}

const SingleCheckbox = ({ labelGroup, source, resource, isRequired, muiTheme, ...rest }) => {
  const styles = getStyles(muiTheme);
  const { prepareStyles } = muiTheme;
  return (
    <div>
      <div style={prepareStyles(styles.labelContainer)}>
        <div style={prepareStyles(styles.label)}>
          <FieldTitle
            label={labelGroup}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </div>
      </div>
      <Field name={source} component={MyCheckbox} {...rest}/>
    </div>
  );
};

SingleCheckbox.propTypes = {
  label: PropTypes.string,
  labelGroup: PropTypes.string,
  muiTheme: PropTypes.object.isRequired,
};

export default compose(
  muiThemeable(),
)(SingleCheckbox);
