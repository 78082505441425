/* eslint-disable */

import React from 'react';
import { required, SimpleForm, TextInput, BooleanInput, NumberInput, minValue, maxValue } from 'admin-on-rest';

const textFieldStyle = { display: 'block', marginRight: '20px' };

const intValidation = (value, allValues) => {
  if (value && value !== Math.trunc(value)) {
      return 'Bandwidth must be zero (maximum) or a positive a whole number.';
  }
  return undefined;
}
const validateBandwidth = [minValue(0), maxValue(4294967295), intValidation];

export const CqgProxyForm = ({ toolbar, ...rest }) => (
  <SimpleForm toolbar={toolbar} {...rest}>

    <TextInput label="Name" source="name" style={textFieldStyle} validate={[required]} />
    <TextInput label="Host" source="host" style={textFieldStyle} validate={[required]} />
    <TextInput label="User" source="user" style={textFieldStyle} validate={[required]} />
    <TextInput label="Location" source="location" style={textFieldStyle} validate={[required]} />
    <NumberInput label="Bandwidth" source="bandwidth" validate={validateBandwidth} defaultValue={0} />
    <TextInput label="Password" source="password" type="password" style={textFieldStyle} validate={[required]} />
    <BooleanInput label="Active" source="active" />
    <BooleanInput label="Orders" source="orders" />
    <BooleanInput label="On-Demand Subscriptions" source="onDemandSubscriptions" />

  </SimpleForm>
);
