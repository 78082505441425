import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import request from 'superagent';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import CreateButtonIcon from 'material-ui/svg-icons/content/reply';
import EditIcon from 'material-ui/svg-icons/image/edit';
import {
  translate as adminTranslate,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
} from 'admin-on-rest';
import path from '../../path';
import UploadButton from '../../components/UploadButton';
import ShowFile from '../../components/ShowFile';
import { Divider } from 'material-ui';
import { ComponentEnhancer } from 'recompose';
import { StructureDataRecord, WrapperDataRecord } from '../types';
import { SpCommonDispatchProps } from './types';
import { isNil } from 'lodash';

const isJsonValid = (SpJson) => {
  try {
    if (SpJson && JSON.parse(SpJson)) {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};

interface Errors {
  name?: boolean;
  jsonFile?: boolean;
}

interface CreateEditButtonOwnProps {
  edit?: boolean;
  record?: StructureDataRecord | WrapperDataRecord;
  apiSource: string;
  defaultFilename: string;
  configurablePrefix?: string;
  fieldName: string;
}

type CreateEditButtonProps = CreateEditButtonOwnProps & SpCommonDispatchProps;

interface File {
  preview: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

const CreateEditButton: React.FC<CreateEditButtonProps> = ({
  edit = false,
  record = {},
  translate,
  showNotification,
  refreshView,
  apiSource,
  defaultFilename,
  configurablePrefix = 'configurableSpLayouts',
  fieldName,
}) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [name, setName] = useState('');
  const [jsonLayoutFile, setJsonLayoutFile] = useState<File[] | undefined>(undefined);
  const [jsonRulesFile, setJsonRulesFile] = useState<File[] | undefined>(undefined);
  const [jsonFieldValidationRulesFile, setJsonFieldValidationRulesFile] = useState<File[] | undefined>(undefined);
  const [jsonOnePagerFile, setJsonOnePagerFile] = useState<File[] | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const translationsPrefix = (edit && `${configurablePrefix}.edit`) || `${configurablePrefix}.create`;
  const showLayoutPreviewFile = record && !jsonLayoutFile;
  const showRulesPreviewFile = record && !jsonRulesFile;
  const showOnePagerPreviewFile = record && !jsonOnePagerFile;
  const showFieldValidationRulesPreviewFile = record && !jsonFieldValidationRulesFile;

  const handleOpen = async () => {
    setOpen(true);
    setErrorMessage('');

    if (record?.id) {
      setName(record[fieldName]);
    }
  };

  const getValidationErrors = () => {
    const validationErrors: Errors = {};
    if (!name.trim()) {
      validationErrors.name = true;
    }

    if (!record?.layout && (isJsonValid(jsonLayoutFile) || !jsonLayoutFile)) {
      validationErrors.jsonFile = true;
    }

    return validationErrors;
  };

  const handleNameChange = (_, value) => {
    setName(value);
    setErrors(getValidationErrors());
  };

  const closeDialog = () => {
    setJsonLayoutFile(undefined);
    setJsonRulesFile(undefined);
    setJsonFieldValidationRulesFile(undefined);
    setJsonOnePagerFile(undefined);
    setOpen(false);
    setName('');
    setErrors({});
  };

  const createInputConfig = () => {
    if (!jsonLayoutFile) {
      showNotification(`${translationsPrefix}.error.noJsonFile`, 'warning');
      setSubmitting(false);
      return;
    }

    const files: Array<File[]> = [];
    if (jsonLayoutFile) {
      files.push(jsonLayoutFile);
    }
    if (jsonRulesFile) {
      files.push(jsonRulesFile);
    }
    if (jsonFieldValidationRulesFile) {
      files.push(jsonFieldValidationRulesFile);
    }

    if (jsonOnePagerFile) {
      files.push(jsonOnePagerFile);
    }

    request
      .post(`${path}/admin/${apiSource}`)
      .set('Token', localStorage.getItem('session'))
      .field('files', files)
      .field('name', name)
      .then(() => {
        showNotification(`${translationsPrefix}.successful`);
        refreshView();
        closeDialog();
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage(translate(`${translationsPrefix}.error.${e.response.body.message || 'generic'}`, 'warning'));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const editInputConfig = (id) => {
    const fields: { name: string; files: Array<File[]> } = { name, files: [] };
    const files: Array<File[]> = [];

    if (jsonLayoutFile) {
      files.push(jsonLayoutFile);
    }

    if (jsonRulesFile) {
      files.push(jsonRulesFile);
    }

    if (jsonFieldValidationRulesFile) {
      files.push(jsonFieldValidationRulesFile);
    }

    if (jsonOnePagerFile) {
      files.push(jsonOnePagerFile);
    }
    fields.files = files;

    request
      .put(`${path}/admin/${apiSource}/${id}`)
      .set('Token', localStorage.getItem('session'))
      .field(fields)
      .then(() => {
        showNotification(`${translationsPrefix}.successful`);
        refreshView();
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage(translate(`${translationsPrefix}.error.${e.response.body.message || 'generic'}`, 'warning'));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSubmit = () => {
    const validationErrors = getValidationErrors();

    if (Object.keys(validationErrors).length) {
      setErrors({ ...validationErrors });
      return;
    }

    setSubmitting(true);
    setErrorMessage('');

    if (edit) {
      editInputConfig(record.id);
    } else {
      createInputConfig();
    }
  };

  const btnIcon = (edit && <EditIcon />) || <CreateButtonIcon />;

  return (
    <div style={{ float: 'left' }}>
      <FlatButton label={translate(`${translationsPrefix}.buttonText`)} icon={btnIcon} onClick={handleOpen} />
      <Dialog
        title={translate(`${translationsPrefix}.title`)}
        modal={false}
        open={open}
        repositionOnUpdate={false}
        style={{ paddingTop: 0 }}
        bodyStyle={{ overflowY: 'auto' }}
      >
        <TextField
          errorText={errors.name && translate('aor.validation.required')}
          floatingLabelText={translate(`${translationsPrefix}.nameField`)}
          fullWidth
          name="name"
          type="text"
          value={name}
          multiLine={true}
          onChange={handleNameChange}
          maxLength="200"
          style={{ marginBottom: '10px' }}
        />
        <UploadButton
          onDrop={setJsonLayoutFile}
          accept={'.json'}
          showPreview={false}
          style={{ marginLeft: '6px' }}
          label="Upload layout file"
        />
        {showLayoutPreviewFile && (
          <ShowFile
            id={record.id}
            cardJson={record.layout}
            fileStatus={record.layoutFileUploadStatus}
            fileName={record.layoutFilename || defaultFilename}
          />
        )}
        <Divider />
        <UploadButton
          onDrop={setJsonRulesFile}
          accept={'.json'}
          showPreview={false}
          style={{ marginLeft: '6px' }}
          label="Upload rules file"
        />
        {showRulesPreviewFile && (
          <ShowFile
            id={record.id}
            cardJson={record.rules}
            fileStatus={record.rulesFileUploadStatus}
            fileName={record.rulesFilename || 'sp_rules_config.json'}
          />
        )}
        <Divider />
        {record && 'onePager' in record && (
          <>
            <UploadButton
              onDrop={setJsonOnePagerFile}
              accept={'.json'}
              showPreview={false}
              style={{ marginLeft: '6px' }}
              label="Upload one pager file"
            />
            {showOnePagerPreviewFile && (
              <ShowFile
                id={record.id}
                cardJson={record.onePager}
                fileStatus={record.onePagerFileUploadStatus}
                fileName={record.onePagerFilename || 'sp_onePager_config.json'}
              />
            )}
          </>
        )}
        <Divider />
        <UploadButton
          onDrop={setJsonFieldValidationRulesFile}
          accept={'.json'}
          showPreview={false}
          style={{ marginLeft: '6px' }}
          label="Upload field validation rules file"
        />
        {showFieldValidationRulesPreviewFile && (
          <ShowFile
            id={record.id}
            cardJson={record.fieldValidationRules}
            fileStatus={record.fieldValidationRulesFileUploadStatus}
            fileName={record.fieldValidationRulesFilename || 'sp_field_validation_rules_config.json'}
          />
        )}
        <Divider />
        <div style={{ color: 'red', padding: '12px', fontWeight: 600 }}>{errorMessage}</div>
        <div style={{ paddingTop: '15px', float: 'right' }}>
          <RaisedButton
            label={translate('configurableSp.dialog.btnCancel')}
            onClick={closeDialog}
            icon={<CancelIcon />}
          />
          <RaisedButton
            type="submit"
            style={{ marginLeft: '10px' }}
            label={translate('configurableSp.dialog.btnSubmit')}
            primary={true}
            icon={<SubmitIcon />}
            disabled={submitting || (!jsonLayoutFile && !showLayoutPreviewFile) || !name}
            onClick={handleSubmit}
          />
        </div>
      </Dialog>
    </div>
  );
};

const enhance: ComponentEnhancer<CreateEditButtonProps, CreateEditButtonOwnProps> = compose(
  adminTranslate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(CreateEditButton);
