import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

export const UrlField = ({ href, label, elStyle }) => (
  <a href={`${href}`} style={elStyle}>
    {label}
  </a>
);

UrlField.propTypes = {
  addLabel: PropTypes.bool,
  elStyle: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

const PureUrlField = pure(UrlField);

PureUrlField.defaultProps = {
  addLabel: true,
};

export default PureUrlField;
