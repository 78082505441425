import React, { useState } from 'react';
import { compose } from 'redux';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import PreviewIcon from 'material-ui/svg-icons/image/view-compact';
import {
  translate as adminTranslate,
} from 'admin-on-rest';
import { reduxForm, Form, Field } from 'redux-form';
import { getSpInputProps } from '@agile/react-components';
import ErrorBoundary from '../../components/common/ErrorBoundary';
import './SpInputsConfig.css';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  const { spInputProps } = ownProps;
  const { name, options } = spInputProps;

  if (options) {
    return {
      initialValues: {
        [name]: options[0]
      }
    }
  }
}

const PreviewForm = connect(mapStateToProps)(reduxForm({form: 'preview'})(Form));

const PreviewButton = ({
  record,
  translate,
}) => {
  const [open, setOpen] = useState(false);
  const translationsPrefix = 'configurableSp.preview';

  const handleOpen = async () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const { FieldComponent, ...rest } = getSpInputProps(record.settings);
  const spInputProps = {
    ...rest,
    name: record.name
  }

  if (FieldComponent) {
    // weird bug in redux form + lerna whereby it does not like Field being imported from lerna
    spInputProps.FieldComponent = Field;
  }

  return (
    <div style={{ float: 'left' }}>
      <FlatButton
        label={translate(`${translationsPrefix}.buttonText`)}
        icon={ <PreviewIcon />}
        onClick={handleOpen}
      />
      <Dialog
        title={translate(`${translationsPrefix}.title`)}
        modal={false}
        open={open}
        repositionOnUpdate={false}
        style={{ paddingTop: '50px' }}
        bodyStyle={{overflowY: 'auto'}}
      >
        <PreviewForm className="sp-preview-dialog" onSubmit={() => null} spInputProps={spInputProps} >
          <div className="name">Component name: {record.name}</div>
          <ErrorBoundary Component={() => <p className="preview-error">Something went wrong. Please check JSON</p>}>
            <Field {...spInputProps} />
          </ErrorBoundary>
        </PreviewForm>
        <div style={{ paddingTop: '15px', float: 'right' }}>
          <RaisedButton
            label={translate('configurableSp.dialog.btnClose')}
            onClick={closeDialog}
            icon={<CancelIcon />}
          />
        </div>
      </Dialog>
    </div>
  );
};

const enhance = compose(
  adminTranslate
);

export default enhance(PreviewButton);
