/* eslint-disable */
import React from 'react';
import { Create, Edit, } from 'admin-on-rest';

import { CqgProxyForm } from './CqgProxyForm';

export const CqgProxyEdit = (props) =>
  <Edit title="Edit CQG Proxy" {...props}>
    <CqgProxyForm {...props} />
  </Edit>;

export const CqgProxyCreate = (props) =>
  <Create title="Create CQG Proxy" {...props}>
    <CqgProxyForm {...props} />
  </Create>;

export { default as CqgProxyList } from './CqgProxyList';
