import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Toggle from 'material-ui/Toggle';
import request from 'superagent';
import {
  showNotification as showNotificationAction,
} from 'admin-on-rest';
import path from '../../path';

class Structure extends Component {
  constructor(props) {
    super(props);
    const { source, record } = props;
    this.state = {
      source,
      id: record.id,
      value: record[source] || false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.value !== this.state.value;
  }

  onToggle = () => {
    const { id, source, value } = this.state;
    const { showNotification } = this.props;
    request
      .put(`${path}/admin/users/structure/${id}`)
      .set('token', localStorage.getItem('session'))
      .send({
        [source]: !value,
      })
      .then(({ body }) => {
        if (body.error) {
          return showNotification(body.error, 'warning');
        }
        return this.setState(state => ({
          ...state,
          value: !value,
        }));
      })
      .catch(() => showNotification('Something went wrong', 'warning'));
  }

  render() {
    const { value } = this.state;
    return (
      <Toggle
        toggled={value}
        onToggle={this.onToggle}
      />
    );
  }
}

const enhance = compose(
  connect(null, {
    showNotification: showNotificationAction,
  }),
);

export default enhance(Structure);
