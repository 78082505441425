
import React from 'react';
import EditIcon from 'material-ui/svg-icons/content/create';
import './DeleteButton.css';

const EditButton = props => (
  <div className='delete-button'>
    <a href={`#${props.basePath}/${props.record.id}`}>
      <EditIcon color="rgb(0, 188, 212)" style={{ margin: '7px 0 0' }} />
    </a>
  </div>
);

export default EditButton;
