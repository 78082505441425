import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import RaisedButton from 'material-ui/RaisedButton';
import AutoComplete from 'material-ui/AutoComplete';

import { connect } from 'react-redux';
import { translate, GET_LIST, GET_ONE, showNotification as showNotificationAction, refreshView as refreshViewAction } from 'admin-on-rest';

import PDFSendIcon from './PDFSendIcon';

import restClient from '../restClient';
import ValidateEmail from '../helpers/ValidateEmail';
import path from '../path';

class PDFSendButton extends React.Component {
  state = {
    open: false,
    error: false,
    emails: [],
    bcc: [],
    cc: [],
    chips: [],
    clients: [],
    searchText: '',
    clientId: '',
  }

  constructor(props) {
    super(props);
    this.dashboardId = props.record.id;
    this.translate = this.props.translate;
  }

  handleOpen() {
    this.setState({ open: true });

    restClient(GET_LIST, 'users', {
      sort: {
        field: 'email',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((res) => {
      this.setState({ chips: res.data.map(i => i.email) });
    });

    const session = localStorage.getItem('session');

    fetch(`${path}/admin/client_database/get_client_by_ref`, { method: 'POST',
      body: JSON.stringify({ reference_number: this.props.record.reference_number }),
      headers: {
        'Token': session,
        'Content-Type': 'application/json; charset=utf-8',
      } })
      .then(response => response.json())
      .then((res) => {
        this.setState({ clients: res });
      });
  }

  clientSelected = (client) => {
    restClient(GET_ONE, 'client_database', {
      id: client.id,
    }).then((res) => {
      const {
        statement_signer: emails,
        statement_cc: cc,
        statement_bcc: bcc,
      } = res.data;

      this.setState({
        ...this.state,
        emails: emails || [],
        bcc: bcc || [],
        cc: cc || [],
        searchText: client.clientName,
        clientId: client.id,
      });
    });
  }

  handleClientInputChange = (value) => {
    this.setState({
      ...this.defaultState,
      chips: this.state.chips,
      clients: this.state.clients,
      searchText: value,
      open: true,
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleSubmit() {
    if (this.state.emails.length) {
      this.setState({ error: false, open: false });
    } else {
      this.setState({ error: true });
      return;
    }

    const { showNotification, record } = this.props;
    const { emails, bcc, cc } = this.state;

    const session = localStorage.getItem('session');

    fetch(`${path}/admin/statements/send_statement`, { method: 'PUT',
      body: JSON.stringify({ statementId: record.id, emails, bcc, cc }),
      headers: {
        'Token': session,
        'Content-Type': 'application/json; charset=utf-8',
      } })
      .then(response => response.json())
      .then(() => {
        showNotification('Statement was sent');
        this.setState({ error: false, open: false, load: false });
      })
      .catch(() => {
        this.setState({ load: false });
        showNotification('Error', 'warning');
      });
  }

  handleAddEmail(chip) {
    const chips = [...this.state.emails, chip.toLowerCase()];
    this.setState({ emails: chips, error: !chips.length });
  }

  handleAddBCC(chip) {
    const chips = [...this.state.bcc, chip.toLowerCase()];
    this.setState({ bcc: chips });
  }

  handleAddCC(chip) {
    const chips = [...this.state.cc, chip.toLowerCase()];
    this.setState({ cc: chips });
  }

  handleDeleteEmail(chip) {
    const chips = this.state.emails.filter(c => c !== chip);
    this.setState({ emails: chips, error: !chips.length });
  }

  handleDeleteBCC(chip) {
    const chips = this.state.bcc.filter(c => c !== chip);
    this.setState({ bcc: chips });
  }

  handleDeleteCC(chip) {
    const chips = this.state.cc.filter(c => c !== chip);
    this.setState({ cc: chips });
  }

  handleBeforeAddChip(chip) {
    const { emails, bcc, cc } = this.state;
    if (!ValidateEmail(chip)) return false;
    return !emails.includes(chip.toLowerCase()) && !bcc.includes(chip.toLowerCase()) && !cc.includes(chip.toLowerCase());
  }

  dataSource() {
    const { emails, bcc, cc } = this.state;
    return this.state.chips.filter(c => !emails.includes(c) && !bcc.includes(c) && !cc.includes(c));
  }

  render() {
    const { record } = this.props;
    const { open, emails, bcc, cc, clients, searchText } = this.state;

    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          disabled={!record.file_exists}
          label={this.translate('pdfs.dialog.btnSend')}
          icon={<PDFSendIcon />}
          onClick={this.handleOpen.bind(this)}
        />
        <Dialog
          title={`${this.translate('pdfs.dialog.title')}${record && record.client_name}`}
          modal={false}
          open={open}
          autoScrollBodyContent={true}
        >
          <AutoComplete
            floatingLabelText="Select client"
            filter={AutoComplete.levenshteinDistance}
            dataSource={clients}
            maxSearchResults={5}
            dataSourceConfig={{ text: 'clientName', value: 'id' }}
            onNewRequest={this.clientSelected}
            onUpdateInput={this.handleClientInputChange}
            openOnFocus
            fullWidth
            searchText={searchText}
          />
          <ChipInput
            value={emails}
            dataSource={this.dataSource()}
            openOnFocus={true}
            onBeforeRequestAdd={this.handleBeforeAddChip.bind(this)}
            onRequestAdd={this.handleAddEmail.bind(this)}
            onRequestDelete={this.handleDeleteEmail.bind(this)}
            errorText={this.state.error && this.translate('aor.validation.required')}
            floatingLabelText={this.translate('pdfs.dialog.emailText')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={cc}
            dataSource={this.dataSource()}
            openOnFocus={true}
            onBeforeRequestAdd={this.handleBeforeAddChip.bind(this)}
            onRequestAdd={this.handleAddCC.bind(this)}
            onRequestDelete={this.handleDeleteCC.bind(this)}
            floatingLabelText={this.translate('pdfs.dialog.ccText')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={bcc}
            dataSource={this.dataSource()}
            openOnFocus={true}
            onBeforeRequestAdd={this.handleBeforeAddChip.bind(this)}
            onRequestAdd={this.handleAddBCC.bind(this)}
            onRequestDelete={this.handleDeleteBCC.bind(this)}
            floatingLabelText={this.translate('pdfs.dialog.bccText')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              label={this.translate('pdfs.dialog.btnCancel')}
              onClick={this.handleClose.bind(this)}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label={this.translate('pdfs.dialog.btnSubmit')}
              primary={true}
              onClick={this.handleSubmit.bind(this)}
              icon={<SubmitIcon />}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

PDFSendButton.propTypes = {
  record: PropTypes.object,
  sendAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(PDFSendButton);
