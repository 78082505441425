import { List } from 'admin-on-rest';
import React, { ComponentProps, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import path from '../path';
import { setColumns } from './actions';
import {
  Columns,
  ListFilters,
  StructuredProductsPricingActions,
} from './components';
import ConfigurableSPFilters from './components/ConfigurableSPFilters';

interface StructuredProductsPricingListProps
  extends ComponentProps<typeof List> {
  setColumns: typeof setColumns;
}

const StructuredProductsPricingList: React.FC<
  StructuredProductsPricingListProps
> = (props) => {
  const { setColumns } = props;
  const [isConfigurableSp, setIsConfigurableSp] = useState(false);

  useEffect(() => {
    setColumns(isConfigurableSp);
  }, [isConfigurableSp]);

  useEffect(() => {
    fetch(`${path}/admin/settings/list`, {
      method: 'GET',
      // @ts-ignore
      headers: {
        Token: localStorage.getItem('session') ?? undefined,
      },
    })
      .then((res) => res.json())
      .then((res) => setIsConfigurableSp(res['configurableSp:bo:enabled']))
      .catch((error) => console.error(error));
  }, []);

  const FilterComponent = isConfigurableSp
    ? ConfigurableSPFilters
    : ListFilters;

  return (
    <List
      {...props}
      filters={<FilterComponent />}
      sort={{ field: 'created_at', order: 'DESC' }}
      title='Structured Products Pricing'
      perPage={50}
      actions={<StructuredProductsPricingActions />}
    >
      <Columns />
    </List>
  );
};

export default connect(null, {
  setColumns,
})(StructuredProductsPricingList);
