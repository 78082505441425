/* eslint-disable */
import React, { Component } from 'react';
import compose from 'recompose/compose';
import { translate, showNotification as showNotificationAction } from 'admin-on-rest';
import Icon from 'material-ui/svg-icons/action/check-circle';
import FlatButton from 'material-ui/FlatButton';
import request from 'superagent';
import { connect } from 'react-redux';
import { updateOrder as updateOrderAction, PROCESSING, DONE } from './../../actions';
import path from '../../../path';
import { APPROVE_REJECT_STATUSES, FX_STRUCTURE, SWAP, MINIFUTURE } from '../../constants';
import { isCqgContract } from '../../../helpers/utilities';

class ApproveButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: props.record.state === PROCESSING,
    };
    this.translate = this.props.translate;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { record: { status, state } } = this.props;
    return (
      status !== nextProps.record.status ||
      state !== nextProps.record.state ||
      this.state.disabled !== nextState.disabled
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.record.state === nextProps.record.state) return;

    this.setState({ disabled: nextProps.record.state === PROCESSING });
  }

  setDisabled = status => {
    const { record, updateOrder } = this.props;
    this.setState({ disabled: status });
    updateOrder({ id: record.id, state: status ? PROCESSING : DONE }, false);
  };

  getUri = ({structure, status, commodity_code, id, cm_status}) => {
    if(structure === FX_STRUCTURE || status === 'requested') return `${path}/mspx/req/approve/${id}`
    if(structure === SWAP && cm_status) return `${path}/mspx/approve_compo_order/${id}`
    if(isCqgContract(commodity_code)) return `${path}/cqg/approve_order/${id}`
    return `${path}/neon/approve_order/${id}`
  }

  onClick = () => {
    try {
      const { showNotification, record } = this.props;
      const { structure } = record
      this.setDisabled(true);
      const uri = this.getUri(record);

      request
        .post(uri)
        .set('token', localStorage.getItem('session'))
        .end((error, response) => {
          if (error) {
            this.setDisabled(false);
            let message;
            if (response && response.body) {
              message = response.body.message;
              if (response.body.disabled) this.setDisabled(true);
            }
            showNotification(message || `NeonOrders.post.status.fail_${structure === FX_STRUCTURE ? 'fx' : 'neon'}`, 'warning');
          } else {
            showNotification(`NeonOrders.post.status.success_${structure === FX_STRUCTURE ? 'fx' : 'neon'}`);
          }
        });
    } catch (error) {
      this.setDisabled(false);
      console.error('ApproveButton', error);
    }
  };

  render() {
    const { record: { status, structure, term_currency: termCurrency } } = this.props;
    const title = status === 'requested' ? 'Approve price request' : 'Approve order';
    const { disabled } = this.state;
    const isMfFx = structure === MINIFUTURE && !!termCurrency;

    if ((structure === FX_STRUCTURE || isMfFx) && status === 'pending') return null;
    return APPROVE_REJECT_STATUSES.includes(status) ? (
      <FlatButton
        primary
        onClick={this.onClick}
        icon={<Icon />}
        disabled={disabled}
        title={title}
        className={'btn-approve'}
        style={{ float: 'left', minWidth: '30px', color: '#1976d2' }}
      />
    ) : (
      ''
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    updateOrder: updateOrderAction,
  })
);

export default enhance(ApproveButton);
