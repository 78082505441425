import gql from 'graphql-tag';
import { client as apolloClient } from './../../apollo';

export const GRAPHQL_NETWORK_ONLY = 'network-only';

const COUNT_QUERY = gql`
query {
  unreadNotifications
}
`;

export const ADMIN_MESSAGE_FRAGMENT = gql`
fragment MessageFragment on NotificationCenterType {
  id
  type
  to
  subject
  body
  isRead
  createdAt
  payload
}
`;

export const fetchNotificationsCount = () => apolloClient.query({
  query: COUNT_QUERY,
  fetchPolicy: GRAPHQL_NETWORK_ONLY,
})
  .then(({ data }) => data)
  .catch(error => console.error(error));

export const NOTIFICATIONS_QUERY = gql`
${ADMIN_MESSAGE_FRAGMENT}
query messagesListQuery(
  $cursor: String
  $total: Int
  $filter: [NotificationCenterFilterType]
  $sorter: [NotificationCenterSorterType]
){
  messagesList(
    after: $cursor
    first: $total
    filter: $filter
    sorter: $sorter
  ) {
    edges {
      node {
        ...MessageFragment
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      __typename
    }
  }
}
`;

export const EVENTS_SUBSCRIPTION = gql`
${ADMIN_MESSAGE_FRAGMENT}
subscription {
  notifications {
    ...MessageFragment
  }
}
`;

export const READ_NOTIFICATION_MUTATION = gql`
mutation readNotificationMutation(
  $id: String!
){
  readNotification(id: $id) {
    id
    isRead
  }
}
`;

export const readMessage = (variables) => apolloClient.mutate({
  mutation: READ_NOTIFICATION_MUTATION,
  variables,
})
  .then(({ data }) => data);

export const DELETE_NOTIFICATION_MUTATION = gql`
mutation deleteNotificationMutation(
  $id: String!
){
  deleteNotification(id: $id)
}
`;

export const deleteMessage = (variables) => apolloClient.mutate({
  mutation: DELETE_NOTIFICATION_MUTATION,
  variables,
})
  .then(({ data }) => data);

export const READ_ALL_NOTIFICATION_MUTATION = gql`
mutation readAllNotificationMutation{
  readAllNotifications
}
`;

export const readAllMessage = () => apolloClient.mutate({
  mutation: READ_ALL_NOTIFICATION_MUTATION,
})
  .then(({ data }) => data);

export const DELETE_ALL_NOTIFICATION_MUTATION = gql`
mutation deleteAllNotificationMutation{
  deleteAllNotifications
}
`;

export const deleteAllMessage = () => apolloClient.mutate({
  mutation: DELETE_ALL_NOTIFICATION_MUTATION,
})
  .then(({ data }) => data);
