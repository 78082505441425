import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  Datagrid,
  List,
  refreshView,
  Responsive,
  SimpleList,
  TextField,
  translate,
} from 'admin-on-rest';
import Seo from '../components/common/Seo';
import {
  DeleteButton,
  EditButton,
} from '../components';
import { UserGroupsFilters } from './components';
import { datagridDefaultStyles } from '../theme/styles';
import MultiplyChips from '../helpers/MultiplyChips';

const ButtonsWrapper = props => (
  <div style={{ width: '340px', overflow: 'hidden' }}>
    <DeleteButton {...props} />
    <EditButton {...props} />
  </div>
);

const UserGroupsList = props => (
  <Fragment>
    <Seo title={'User groups'} />
    <List {...props}
      sort={{ field: 'name', order: 'DESC' }}
      filters={<UserGroupsFilters />}
      perPage={25}
      refreshView={true}
      title='User groups'
    >
      <Responsive
        small={
          <SimpleList
            primaryText={r => r.name}
            secondaryText={r => r.code}
          />
        }
        medium={
          <Datagrid
            bodyOptions={{ showRowHover: true }}
            options={{ height: '70vh' }}
            styles={datagridDefaultStyles}
          >
            <TextField source="id" />
            <TextField source="name" />
            <TextField label="User Group Code" source="code" />
            <MultiplyChips label="clients" source="clientsNames" sortable={false} />
            <TextField label="Theme Name" source="themeName" />
            <TextField label="Spread Sheet" source="spreadsheetName" />
            <ButtonsWrapper />
          </Datagrid>
        }
      />
    </List>
  </Fragment>
);

const enhance = compose(
  translate,
  connect(null, { refreshView }),
);

export default enhance(UserGroupsList);
