import { List } from 'admin-on-rest';
import React, { Component, Fragment } from 'react';
import Seo from '../components/common/Seo';
import { Columns, Filters, OrderActions, Popup } from './components';
import style from './style';

class NeonOrdersList extends Component {
  render() {
    return (
      <Fragment>
        <Seo title={'Approval List'} />
        <List {...this.props}
          sort={{ field: 'created_at', order: 'DESC' }}
          filters={<Filters />}
          actions={<OrderActions {...this.props} />}
          perPage={15}
          title='Approval List'
        >
          <Columns />
        </List>
        <Popup />
        <style>{style}</style>
      </Fragment>
    );
  }
}

export default NeonOrdersList;
