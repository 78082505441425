import React from 'react';

import { SimpleForm, Create, TextInput, Toolbar, SaveButton, required, email, minLength, maxLength, ReferenceInput, SelectInput } from 'admin-on-rest';

const verify = (value, allValues, props) =>
  (allValues._password === value ? undefined : props.translate('admins.validate.verify'));

const password = (value, allValues, props) =>
  (/^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[A-Z])(?=.*[~<>,./?|!@#$%^&*`()\-_+={}[\];:]))|((?=.*[a-z])(?=.*[0-9])(?=.*[~<>,./?|!@#$%^&*`()\-_+={}[\];:]))|((?=.*[A-Z])(?=.*[0-9])(?=.*[~<>,./?|!@#$%^&*`()\-_+={}[\];:]))).{0,}$/.test(value) ? undefined : props.translate('admins.validate.password'));

const AdminToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      redirect="list"
      submitOnEnter={true}
    />
  </Toolbar>
);

const validateMinLengthToTwo = minLength(2);
const validateMinLengthToEight = minLength(8);

const validateMaxLengthToHundred = maxLength(100);
const validateMaxLengthToTwenty = maxLength(20);

const AdminCreate = props => (
  <Create {...props}>
    <SimpleForm
      toolbar={<AdminToolbar />}
    >
      <TextInput
        source="email"
        type="email"
        validate={[required, email]}
      />
      <TextInput
        source="first_name"
        validate={[required, validateMinLengthToTwo, validateMaxLengthToHundred]}
      />
      <TextInput
        source="last_name"
        validate={[required, validateMinLengthToTwo, validateMaxLengthToHundred]}
      />
      <TextInput
        source="_password"
        type="password"
        validate={[required, password, validateMinLengthToEight, validateMaxLengthToTwenty]}
      />
      <TextInput
        source="_verify"
        type="password"
        validate={[required, password, verify, validateMinLengthToEight, validateMaxLengthToTwenty]}
      />
      <TextInput
        label = "Slack ID"
        source="slack_id"
        type="text"
      />
      <ReferenceInput
        label="Admin Role"
        source="role_id"
        reference="admin_roles"
        perPage="1e9"
        validate={[required]}
      >
        <SelectInput
          optionValue="id"
          optionText="title"
          validate={[required]}
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default AdminCreate;
