import displayDefault from './default';
import {
  NO_TOGGLE_COLUMN_CDB,
  NO_TOGGLE_ALL_COLUMNS_CDB,
  COLUMN_VERSION_CDB,
} from './actions';

if (!localStorage.getItem('client_database_columns')) {
  localStorage.setItem('client_database_columns', JSON.stringify(displayDefault));
  localStorage.setItem('_vcol_cdb', COLUMN_VERSION_CDB);
}

if (!localStorage.getItem('_vcol_cdb') || localStorage.getItem('_vcol_cdb') !== `${COLUMN_VERSION_CDB}`) {
  localStorage.setItem('client_database_columns', JSON.stringify(displayDefault));
  localStorage.setItem('_vcol_cdb', COLUMN_VERSION_CDB);
}

const defaultState = {
  columns: JSON.parse(localStorage.getItem('client_database_columns')),
  version: 0,
};

export const clientDataBaseReducer = (previousState = defaultState, { type, payload }) => {
  switch (type) {
  case NO_TOGGLE_COLUMN_CDB: {
    const { columns } = previousState;
    const { col, status } = payload;
    const display = { ...columns, [col]: { ...columns[col], status } };
    localStorage.setItem('client_database_columns', JSON.stringify(display));
    return { ...previousState, columns: display };
  }
  case NO_TOGGLE_ALL_COLUMNS_CDB: {
    const { columns } = previousState;
    const display = Object.keys(columns).reduce((res, key) => {
      if (!columns[key].disabled) columns[key].status = payload;
      return Object.assign(res, { [key]: columns[key] });
    }, {});
    localStorage.setItem('client_database_columns', JSON.stringify(display));
    return { ...previousState, columns: display };
  }
  default:
    return previousState;
  }
};
