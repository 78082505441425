import { Datagrid, DateInput, Filter, List, NumberInput, refreshView, Responsive, SelectInput, SimpleList, TextField, TextInput, translate } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { DateField, DisplayText } from '../helpers';
import DeleteButton from '../helpers/DeleteButton';
import DownloadButton from '../helpers/DownloadButton';
import MultiplyChips from '../helpers/MultiplyChips';
import { PresignedURLDownload } from '../helpers/PresignedURLDownload';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput, formatDateInput, dateParser } from '../utils/dates';
import OTCEditButton from './OTCEditButton';
import OTCReleaseButton from './OTCReleaseButton';
import OTCRemindButton from './OTCRemindButton';
import OTCCancelButton from './OTCCancelButton';
import ListActions from './ListActions';
import socket from 'ioClient';

const OTCFilter = props => (
  <Filter {...props}>
    <TextInput
      label="Search (subject, status, trade number)"
      source="_q"
      alwaysOn
      options={{ style: { width: '300px' } }}
    />
    <NumberInput source="id" />
    <TextInput source="client_name" />
    <TextInput source="trade_number" />
    <TextInput source="trade_type" />
    <TextInput source="email_subject" />
    <TextInput source="user" />
    <SelectInput label="Status" source="status" choices={[
      { id: 'pending', name: 'pending' },
      { id: 'released', name: 'released' },
      { id: 'delivered', name: 'delivered' },
      { id: 'completed', name: 'completed' },
      { id: 'declined', name: 'declined' },
      { id: 'voided', name: 'voided' },
    ]} />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
      parse={dateParser}
    />
  </Filter>
);

const ButtonsWrapper = props => (
  <div
    style={{
      minWidth: '450px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    <div>
      <OTCRemindButton {...props} />
      <DownloadButton {...props} label="download" />
    </div>
    <OTCReleaseButton {...props} />
    <div>
      <OTCEditButton {...props} />
      <OTCCancelButton {...props} />
      <DeleteButton {...props} />
    </div>
  </div>
);

class OTCConfirmationList extends React.Component {
  constructor(props) {
    super(props);

    this.socket = socket;
  }

  componentDidMount() {
    this.socket.on('released_otc', () => {
      this.props.refreshView();
    });
    this.socket.on('unsigned-OTC-refresh', () => {
      this.props.refreshView();
    });
  }

  getLink = id => (e) => {
    e.preventDefault();
    PresignedURLDownload('confirmation', id);
  }

  getTPLLink = id => (e) => {
    e.preventDefault();
    PresignedURLDownload('otc_tpl', id);
  }

  render() {
    return (
      <List {...this.props}
        filters={<OTCFilter />}
        sort={{ field: 'created_at', order: 'DESC' }}
        title="OTC confirmations list"
        perPage={50}
        actions={<ListActions {...this.props} />}
      >
        <Responsive
          small={
            <SimpleList
              primaryText={r => r.trade_number}
              secondaryText={r => r.trade_type}
              tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.signed_at}</Moment>}
            />
          }
          medium={
            <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
              <TextField source="id" />
              <TextField source="client_name" />
              <TextField source="trade_number" />
              <TextField source="trade_type" />
              <TextField source="email_subject" />

              <MultiplyChips source="signers" />
              <MultiplyChips source="cc_emails" />
              <MultiplyChips source="bcc_emails" />
              <MultiplyChips source="viewers" />
              <DisplayText
                source="status"
                getText={({ record }) => record.status }
              />
              <DownloadButton
                label='TPL'
                activateField='tpl_exists'
                onClick={this.getTPLLink}
              />
              <DateField
                formatDate={formatDateTimeInput}
                source="signed_at"
                showTime
              />
              <DateField
                formatDate={formatDateTimeInput}
                source="created_at"
                showTime
              />
              <ButtonsWrapper onClick={this.getLink} />
            </Datagrid>
          }
        />
      </List>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    refreshView,
  }),
);

export default enhance(OTCConfirmationList);
