import React from 'react';

import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  translate,
} from 'admin-on-rest';

import { verify } from './helpers';

const TitleComponent = translate(({ record }) =>
  <span>{translate('admins.edit.title', { smart_count: 1 })} { record.email }</span>);

const SftpAccountsEdit = props => (
  <Edit {...props}
    title={<TitleComponent />}
  >
    <SimpleForm>
      <TextInput
        source="host"
        validate={[required]}
      />
      <TextInput
        source="username"
        validate={[required]}
      />
      <TextInput
        source="password"
        type="password"
        validate={[required]}
      />
      <TextInput
        source="_verify"
        type="password"
        validate={[required, verify]}
      />
    </SimpleForm>
  </Edit>
);

export default SftpAccountsEdit;
