import { LP_UPDATE } from "./actions";
import dotProp from 'dot-prop-immutable';


const defaultState = {
  livePosition: {},
}

const updateLivePosition = (state, data, payload) =>
  payload.id && data[payload.id]
    ? dotProp.set(state, 'resources.live_positions.data', list => ({
      ...list,
      [payload.id]: { ...data[payload.id], ...payload.data, },
    }))
    : state;

export const livePositionsReducer = (state, { type, payload }) => {
  switch(type) {
  case LP_UPDATE: {
    const { resources: { live_positions: { data }, }, } = state;
    return updateLivePosition(state, data, payload);
  }
  default:
    return state;
  }
}
