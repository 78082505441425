import { ChipField, Datagrid, DateInput, EditButton, Filter, List, ReferenceArrayField, Responsive, SimpleList, SingleFieldList, TextField, TextInput, ReferenceField, ReferenceInput, SelectInput } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import { formatDateTimeInput, formatDateInput } from '../utils/dates';
import ChangePasswordBtn from './../adminChangePassword/ChangePasswordBtn';

const AdminFilter = props => (
  <Filter {...props}>
    <TextInput
      label="admins.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="email"
    />
    <TextInput
      source="first_name"
    />
    <TextInput
      source="last_name"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
    />
    <ReferenceInput
      label="Admin Role"
      source="role_id"
      reference="admin_roles"
      perPage="1e9"
    >
      <SelectInput
        optionValue="id"
        optionText="title"
      />
    </ReferenceInput>
  </Filter>
);

const AdminList = props => (
  <List {...props}
    filters={<AdminFilter />}
    sort={{ field: 'created_at', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => `${r.last_name} ${r.first_name}`}
          secondaryText={r => r.email}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
        />
      }
      medium={
        <Datagrid options={{ height: '70vh', className: 'sticky-header' }}>
          <TextField
            source="id"
          />
          <TextField
            source="email"
          />
          <TextField
            source="first_name"
          />
          <TextField
            source="last_name"
          />
          <ReferenceArrayField
            label="Notification Setting"
            reference="admin_notification_settings"
            source="notification_setting_ids"
            sortable={false}
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <TextField
            label="Slack ID"
            source="slack_id"
          />
          <ReferenceField label="Admin Role" source="role_id" reference="admin_roles">
            <TextField source="title" />
          </ReferenceField>
          <DateField
            formatDate={formatDateTimeInput}
            source="updated_at"
            showTime
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="created_at"
            showTime
          />
          <ChangePasswordBtn />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default AdminList;
