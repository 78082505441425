/* eslint-disable */
import { takeEvery, select, put } from 'redux-saga/effects';
import { change } from 'redux-form';
import _ from 'lodash';
import {
  REDUX_FORM_CHANGE_ACTION,
  REDUX_FORM_UNREGISTER_ACTION,
  NOTIONAL_FIELD_ARRAY,
  FIELD_NOTIONAL_USD,
  DIRECTION,
  RECORD_FORM,
  selector,
} from './helpers';

export default function* spSaga() {
  yield [
    takeEvery(
      ({ type, meta = {}, payload = {} }) => {
        const path = type === REDUX_FORM_UNREGISTER_ACTION ? payload.name : meta.field;
        const [source, index, field] = _.toPath(path || '');
        return ([REDUX_FORM_CHANGE_ACTION, REDUX_FORM_UNREGISTER_ACTION].includes(type)
          && source === NOTIONAL_FIELD_ARRAY && field === FIELD_NOTIONAL_USD
        );
      },
      function* (foo) {
        const state = yield select();
        const notionals = selector(state, NOTIONAL_FIELD_ARRAY);
        const { max, arr } = notionals.reduce(
          (acc, { direction, notionalUsd }) => {
            if (direction === DIRECTION.GREATER_OR_EQUAL) {
              acc.max = parseFloat(notionalUsd);
            } else {
              acc.arr.push(notionalUsd ? parseFloat(notionalUsd) : 0);
            }
            return acc;
          },
          { max: 0, arr: [0] },
        );
        const newValue = Math.max(...arr);
        if (newValue !== max) {
          yield put(change(RECORD_FORM, `${NOTIONAL_FIELD_ARRAY}[${notionals.length-1}].${FIELD_NOTIONAL_USD}`, newValue));
        }
      },
    ),
  ];
}
