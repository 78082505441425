import React from 'react';
import { FormField, TextInput, NumberInput } from 'admin-on-rest';
import { TableRowColumn } from 'material-ui/Table';
import _ from 'lodash';

export const RECORD_FORM = 'record-form';
export const TRIALS_FIELD_ARRAY = 'trials';
export const SUBSTEPS_FIELD_ARRAY = 'subStepsPerDay';
export const TRIALS_TYPES = [{
  key: 'level',
  value: 'Level',
}, {
  key: 'pv',
  value: 'PV',
}];

export const SUBSTEPS_TYPES = [{
  key: 'europeanOrNone',
  value: 'European/None',
}, {
  key: 'american',
  value: 'American',
}];
export const FIELD_BUSINESS_DAYS = 'businessDays';

export const DIRECTION = {
  LESS_OR_EQUAL: '<=',
  GREATER: '>',
};

export const round = v => v && _.round(v, 4);

export const INPUT_PROPS = {
  inputStyle: { color: '#000' },
  underlineStyle: { display: 'none' },
};

export const businessDaysValidation = (value, props, item, el) => {
  const [firsLevel, secondLevelArr] = el.split('.');
  const secondLevel = secondLevelArr.replace(/(?<=\[)[^\][]*(?=])/g, '').replace('[]', '');
  const arrayFields = props[firsLevel][secondLevel];
  if (value < 0) {
    return 'Must be greater or equals 0';
  }

  const uniq = arrayFields.filter(({ businessDays, direction }) => (businessDays === value && direction === DIRECTION.LESS_OR_EQUAL));
  if (uniq && uniq.length > 1) {
    return 'Must be unique value';
  }
  return undefined;
};

export const inputFormatterNumber = (value) => {
  if (!value) {
    return '';
  }
  const num = Number(value);
  if (typeof num === 'number') {
    return new Intl.NumberFormat('en-GB').format(value);
  }

  return '';
};

export const parseInput = (number) => number ? number.replace(/[^0-9]/g, '') : '';

export const TableInput = ({ rowStyle = {}, placeholder, disabled = false, inputOptions = {}, type, ...rest }) => {
  const input = React.createElement(
    type === 'number' ? NumberInput : TextInput,
    {
      ...rest,
      addLabel: false,
      options: {
        disabled,
        placeholder,
        floatingLabelText: null,
        ...inputOptions,
      },
    },
  );
  return (
    <TableRowColumn style={{
      overflow: 'none',
      ...rowStyle,
    }}>
      <FormField input={input} />
    </TableRowColumn>
  );
};
