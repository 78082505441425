import React from 'react';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import SendIcon from 'material-ui/svg-icons/content/reply';
import TextField from 'material-ui/TextField';
import { connect } from 'react-redux';
import { GET_LIST
  , GET_ONE
  , translate
  , showNotification as showNotificationAction
  , refreshView as refreshViewAction } from 'admin-on-rest';
import restClient from '../restClient';
import path from '../path';

import ValidateEmail from '../helpers/ValidateEmail';

class Notify extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
    this.state = {
      open: false,
      recipients: [],
      cc: [],
      bcc: [],
      marginCall: '',
      sendValue: '',
      currency: '',
      emails: [],
      ssiExists: false,
      errors: {
        signers: false,
        sendValue: false,
        marginCall: false,
      },
    };
  }

  validate = () => {
    const { recipients, marginCall, sendValue } = this.state;
    const errors = {};

    if (!recipients.length) errors.recipients = true;
    if (!marginCall.length) errors.marginCall = true;
    if (!sendValue.length) errors.sendValue = true;

    this.setState({ errors });
    return errors;
  }

  submitForm = () => {
    const errors = this.validate();
    if (Object.keys(errors).length) return;

    const { showNotification, refreshView } = this.props;
    const { recipients, cc, bcc, marginCall, sendValue, currency } = this.state;

    this.setState({ open: false }, () => {
      fetch(`${path}/admin/margin_calls/notify`, { method: 'POST',
        body: JSON.stringify({ recipients, cc, bcc, marginCall, sendValue, currency }),
        headers: {
          'Token': localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        } })
        .then((response) => {
          if (response.status === 200) return response;
          throw new Error('Request failed');
        })
        .then(() => {
          showNotification('marginCall.notify.success');
          refreshView();
        })
        .catch((e) => {
          console.error(e);
          showNotification('Error', 'warning');
        });
    });
  }

  getEmails = () =>
    restClient(GET_LIST, 'users', {
      sort: { field: 'email', order: 'ASC' },
      pagination: { page: 1, perPage: 100000 },
    });

  getViewers = () =>
    restClient(GET_ONE, 'otc_confirmation/viewers', {
      id: this.props.record.id,
    });

  handleInputChange = inputName => (...event) => this.setState({
    [inputName]: event[1],
  });

  getSSI = () => fetch(`${path}/admin/SSI/exist`, { method: 'GET',
    headers: {
      'Token': localStorage.getItem('session'),
      'Content-Type': 'application/json; charset=utf-8',
    } })
    .then((response) => {
      if (response.status === 200) return response.json();
      throw new Error('Request failed');
    });

  async getFormData() {
    const {
      client_id: id
      , margin_call: marginCall = '0'
      , send_value: sendValue = '0'
      , currency } = this.props.record;

    const [response, emails, ssi] = await Promise.all([
      restClient(GET_ONE, 'client_database', { id }),
      this.getEmails(),
      this.getSSI(),
    ]);

    const { statement_signer: recipients, statement_cc: cc, statement_bcc: bcc } = response.data;
    this.setState({
      emails: emails.data.map(e => e.email),
      recipients,
      cc,
      bcc,
      marginCall,
      sendValue,
      currency,
      ssiExists: ssi.exist,
    });
  }

  changeDialogStatus = status => () => {
    this.setState({ open: status });
    if (status) this.getFormData();
  };

  handleDeleteChips = type => (chip) => {
    const chips = this.state[type].filter(c => c !== chip);
    this.setState({ [type]: chips });
  }

  handleAddChips = type => (chip) => {
    const chips = [...this.state[type], chip.toLowerCase()];
    this.setState({ [type]: chips });
  }

  handleBeforeAddChip = type => (chip) => {
    const chips = this.state[type];
    if (!ValidateEmail(chip)) return false;
    return !chips.includes(chip.toLowerCase());
  }

  emailsDataSource() {
    const { emails, recipients, cc, bcc } = this.state;
    return emails.filter(
      email => !recipients.includes(email)
        && !cc.includes(email)
        && !bcc.includes(email),
    );
  }

  render() {
    const { recipients, cc, bcc, currency, sendValue, marginCall, ssiExists } = this.state;
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          icon={<SendIcon color='#01579B' />}
          onClick={this.changeDialogStatus(true)}
          style= {{ minWidth: '30px', padding: '0 15px' }}
          label="Notify"
        />
        <Dialog
          title={this.translate('marginCall.notify.formTitle')}
          modal={false}
          open={this.state.open}
          autoScrollBodyContent={true}
        >
          {ssiExists ? <span>SSI will be automatically attached. You can review ssi <a href={`${path}/admin#/ssi`} target="_blank">here</a></span> : null}
          <ChipInput
            value={recipients}
            openOnFocus={true}
            dataSource={this.emailsDataSource()}
            fullWidth
            fullWidthInput
            floatingLabelText={this.translate('marginCall.notify.recipients')}
            onRequestAdd={this.handleAddChips('recipients')}
            onRequestDelete={this.handleDeleteChips('recipients')}
            onBeforeRequestAdd={this.handleBeforeAddChip('recipients')}
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
            errorText={this.state.errors.recipients && this.translate('marginCall.notify.required')}
          />
          <TextField
            value={marginCall}
            floatingLabelText={this.translate('marginCall.notify.marginCall')}
            fullWidth
            name="marginCall"
            type="number"
            onChange={this.handleInputChange('marginCall')}
            errorText={this.state.errors.marginCall && this.translate('marginCall.notify.required')}
          />
          <TextField
            value={sendValue}
            floatingLabelText={this.translate('marginCall.notify.sendValue')}
            fullWidth
            errorText={this.state.errors.sendValue && this.translate('marginCall.notify.required')}
            name="sendValue"
            type="number"
            onChange={this.handleInputChange('sendValue')}
          />
          <TextField
            value={currency}
            floatingLabelText={this.translate('marginCall.notify.currency')}
            fullWidth
            name="Currency"
            type="text"
            multiLine={true}
            onChange={this.handleInputChange('currency')}
          />
          <ChipInput
            value={cc}
            dataSource={this.emailsDataSource()}
            openOnFocus={true}
            fullWidth
            fullWidthInput
            floatingLabelText={this.translate('marginCall.notify.cc')}
            onRequestDelete={this.handleDeleteChips('cc')}
            onRequestAdd={this.handleAddChips('cc')}
            onBeforeRequestAdd={this.handleBeforeAddChip('cc')}
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={bcc}
            openOnFocus={true}
            dataSource={this.emailsDataSource()}
            fullWidth
            fullWidthInput
            floatingLabelText={this.translate('marginCall.notify.bcc')}
            onRequestAdd={this.handleAddChips('bcc')}
            onRequestDelete={this.handleDeleteChips('bcc')}
            onBeforeRequestAdd={this.handleBeforeAddChip('bcc')}
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              label={this.translate('pdfs.dialog.btnCancel')}
              icon={<CancelIcon />}
              onClick={this.changeDialogStatus(false)}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              primary={true}
              icon={<SubmitIcon />}
              onClick={this.submitForm}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(Notify);
