import React, { cloneElement } from 'react';
import { CardActions } from 'material-ui/Card';
import {
  RefreshButton,
  CreateButton,
} from 'admin-on-rest';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const LivePositionsActions = (props) => {
  const { basePath, resource, filters, displayedFilters, filterValues, showFilter } = props;

  return (
    <CardActions style={cardActionStyle}>
      {filters && cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' })}
      <CreateButton basePath={basePath}/>
      <RefreshButton />
    </CardActions>
  )
};

export default LivePositionsActions;
