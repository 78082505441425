export const datagridDefaultStyles = {
  table: {
    tableLayout: 'auto',
  },
  tbody: {
    height: 'inherit',
  },
  header: {
    'th': {
      padding: 0,
    },
    'th span': {
      padding: 0,
      background: 'red',
    },
    'th:first-child': {
      padding: '0 0 0 4px',
    },
  },
  cell: {
    'td': {
      padding: '0 4px',
      whiteSpace: 'normal',
    },
    'td:first-child': {
      padding: '0 4px 0 16px',
      whiteSpace: 'normal',
    },
  },
};
