export default {
  status: {
    status: true,
    label: 'Status',
    disabled: false,
    type: 'CheckboxCustom',
  },
  check_status: {
    status: true,
    label: 'Check Status',
    disabled: false,
    type: 'CheckboxCustom',
  },
  auto_sign: {
    status: true,
    label: 'Auto Sign',
    disabled: false,
    type: 'CheckboxCustom',
  },
  auto_release: {
    status: true,
    label: 'Auto Release',
    disabled: false,
    type: 'CheckboxCustom',
  },
  margin_call_status: {
    status: true,
    label: 'Margin Call Status',
    disabled: false,
    type: 'CheckboxCustom',
  },
  pdf_to_sftp: {
    status: true,
    label: 'PDF to SFTP',
    disabled: false,
    type: 'CheckboxCustom',
  },
  csv_to_sftp: {
    status: true,
    label: 'CSV to SFTP',
    disabled: false,
    type: 'CheckboxCustom',
  },
  sftpAcc: {
    status: false,
    label: 'SFTP Acc',
    disabled: false,
    type: 'RichTextField',
  },
  client: {
    status: true,
    label: 'Client Name',
    disabled: true,
    type: 'RichTextField',
  },
  dashboard: {
    status: true,
    label: 'Dashboard',
    disabled: true,
    type: 'MultiplyChips',
  },
  client_code: {
    status: true,
    label: 'Client Code',
    disabled: true,
    type: 'TextField',
  },
  contact_name: {
    status: true,
    label: 'Contact Name',
    disabled: true,
    type: 'TextField',
  },
  address: {
    status: false,
    label: 'Address',
    disabled: false,
    type: 'TextField',
  },
  phone_number: {
    status: false,
    label: 'Phone',
    disabled: false,
    type: 'TextField',
  },
  client_language: {
    status: false,
    label: 'Client Language',
    disabled: false,
    type: 'TextField',
  },
  agreement_type: {
    status: false,
    label: 'Agreement Type',
    disabled: false,
    type: 'TextField',
  },
  entity: {
    status: false,
    label: 'Entity',
    disabled: false,
    type: 'TextField',
  },
  to: {
    status: false,
    label: 'Recap To',
    disabled: false,
    type: 'MultiplyChips',
  },
  cc: {
    status: false,
    label: 'Recap Cc',
    disabled: false,
    type: 'MultiplyChips',
  },
  bcc: {
    status: false,
    label: 'Recap Bcc',
    disabled: false,
    type: 'MultiplyChips',
  },
  statement_signer: {
    status: false,
    label: 'Statement Signer',
    disabled: false,
    type: 'MultiplyChips',
  },
  statement_cc: {
    status: false,
    label: 'Statement CC',
    disabled: false,
    type: 'MultiplyChips',
  },
  statement_bcc: {
    status: false,
    label: 'Statement BCC',
    disabled: false,
    type: 'MultiplyChips',
  },
  otc_signer: {
    status: false,
    label: 'OTC Signer',
    disabled: false,
    type: 'ChipField',
  },
  signer_full_name: {
    status: false,
    label: 'Signer Full Name',
    disabled: false,
    type: 'TextField',
  },
  otc_cc: {
    status: false,
    label: 'OTC CC',
    disabled: false,
    type: 'MultiplyChips',
  },
  otc_bcc: {
    status: false,
    label: 'OTC BCC',
    disabled: false,
    type: 'MultiplyChips',
  },
  otc_viewers: {
    status: false,
    label: 'OTC Viewers',
    disabled: false,
    type: 'MultiplyChips',
  },
  sales_teams: {
    status: false,
    label: 'Sales Teams',
    disabled: false,
    type: 'MultiplyChips',
  },
  team: {
    status: false,
    label: 'Team',
    disabled: false,
    type: 'MultiplyChips',
  },
  sales: {
    status: false,
    label: 'Sales',
    disabled: false,
    type: 'TextField',
  },
  second_otc_signer: {
    status: false,
    label: 'Second OTC Signer',
    disabled: false,
    type: 'ChipField',
  },
  second_signer_full_name: {
    status: false,
    label: 'Second Signer Full Name',
    disabled: false,
    type: 'TextField',
  },
  record_type: {
    status: true,
    label: 'Record Type',
    disabled: false,
    type: 'TextField',
  },
  active: {
    status: true,
    label: 'Active',
    disabled: false,
    type: 'CheckboxCustom',
  },
};
