import React from 'react';
import { Field } from 'redux-form';
import { Edit, SimpleForm, TextInput, required, minLength, maxLength, translate } from 'admin-on-rest';
import 'jsoneditor-react/es/editor.min.css';
import JsonEditor from '../helpers/JsonEditor';

const ThemeConfigTitle = translate(({ record }) =>
  <span>{translate('structures.edit.title', { smart_count: 1 })} { record.name }</span>);

const validateMinLengthToTwo = minLength(2);
const validateMaxLengthToTwenty = maxLength(20);
const ThemeConfigsEdit = props => (
  <Edit {...props}
    title={<ThemeConfigTitle />}
  >
    <SimpleForm>
      <TextInput
        source="name"
        validate={[required, validateMinLengthToTwo, validateMaxLengthToTwenty]}
      />
      <Field
        name="definition"
        source="definition"
        component={JsonEditor}
      />
    </SimpleForm>
  </Edit>
);

export default ThemeConfigsEdit;
