import React from 'react';
import Moment from 'react-moment';

import { List, Responsive, SimpleList, Datagrid, EditButton, DeleteButton, ReferenceField, FunctionField, TextField } from 'admin-on-rest';

import VolatilityConfigFilter from './VolatilityConfigFilter';

const VolatilityConfigList = props => (
  <List {...props}
    title="Volatility Config"
    filters={<VolatilityConfigFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => r.id}
          econdaryText={r => r.structure}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.updated_at}</Moment>}
        />
      }
      medium={
        <Datagrid>
          <ReferenceField
            linkType="show"
            label="market data"
            source="market_data_id"
            reference="market_data"
          >
            <FunctionField
              render={record => `${record.commodity_contract} ${record.bloomberg_ticker} (${record.quoted_currency} ${record.short_code})`}
            />
          </ReferenceField>
          <TextField
            source="structure"
          />
          <ReferenceField
            label="volatility bucket"
            source="volatility_bucket_id"
            reference="volatility_bucket"
          >
            <FunctionField
              render={record => record.name}
            />
          </ReferenceField>
          <EditButton />
          <DeleteButton />
        </Datagrid>
      }
    />
  </List>
);

export default VolatilityConfigList;
