
import { CREATE } from 'admin-on-rest';

import { PDF_GENERATE } from './PDFAction';

const PDFGenerateAction = (data, basePath) => ({
  type: PDF_GENERATE,
  payload: { data, basePath },
  meta: { resource: 'pdfs', fetch: CREATE, cancelPrevious: false },
});

export default PDFGenerateAction;
