/* eslint-disable */
import React from 'react';
import Dropzone from 'react-dropzone';
import request from 'superagent';
import FlatButton from 'material-ui/FlatButton';
import FileUploadIcon from 'material-ui/svg-icons/file/file-upload';
import CircularProgress from 'material-ui/CircularProgress';
import compose from 'recompose/compose';
import ToggleDisplay from 'react-toggle-display';

import { connect } from 'react-redux';
import { translate, refreshView, showNotification } from 'admin-on-rest';

import path from '../path';

class FileUploadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isUploading: 0 };
  }

  async onDrop(files) {
    this.setState({ isUploading: true });

    this.progres = files.length;
    for (let i = 0; i < files.length; i += 1) {
      try {
        await request
          .post(`${path}/file`)
          .set('token', localStorage.getItem('session'))
          .field('file', files[i]);
          this.props.showNotification(`Uploaded file ${files[i].name}`);
      } catch (e) {
        console.error('file upload failed', i, e);
        this.props.showNotification(`Failed to uploaded file ${files[i].name}`, 'warning');
      }
      this.setState({ isUploading: false });
      this.props.refreshView();
    }
  }

  render() {
    const { isUploading } = this.state;
    const { translate } = this.props;

    return (
      <Dropzone className="Dropzone" onDrop={this.onDrop.bind(this)} multiple={true} >
        <FlatButton
          primary={!isUploading}
          disabled={isUploading}
          label={translate('files.list.upload')}
          icon={<FileUploadIcon/>}
        />
        <ToggleDisplay show={isUploading} >
          <CircularProgress className="CircularProgress" size={21} thickness={2} />
        </ToggleDisplay>
      </Dropzone>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    refreshView,
    showNotification,
  }),
);

export default enhance(FileUploadButton);
