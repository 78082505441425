import React from 'react';
import { SWAP, CALENDAR_SPREAD, ASIAN_SWAP, BULLET_STRIP } from './../constants';
import style from './StructureBadgeStyle';

const structureToClass = {
  'swap': 'badge badge-purple',
  'vanilla': 'badge badge-yellow',
  'minifuture': 'badge badge-blue',
  'forwards / ndfs': 'badge badge-green',
  [CALENDAR_SPREAD]: 'badge badge-purple-lite',
  [ASIAN_SWAP]: 'badge badge-asian',
  [BULLET_STRIP]: 'badge badge-bullet-strip',
};

const getStructure = (structure, isCompo, contractExpirySecond) => {
  if (structure === BULLET_STRIP && isCompo) return `${structure} / compo`;
  if (structure === ASIAN_SWAP && isCompo) return `${structure} / compo`;
  if (structure === SWAP && isCompo) return `${structure} / compo`;
  if (structure === SWAP && contractExpirySecond) return CALENDAR_SPREAD;
  return structure;
};
export default function StructureBadge({ structure, is_compo: isCompo, contract_expiry_second: contractExpirySecond, rowType, status = '' }) {
  if (rowType === 'legs') {
    return null;
  }

  const badgeText = status.includes('request') ? 'request' : 'order';
  const badgeClass = status.includes('request') ? 'badge badge-red' : 'badge badge-grey';

  const structureLabel = getStructure(structure, isCompo, contractExpirySecond);
  return (
    <div>
      <span className={structureToClass[structureLabel === CALENDAR_SPREAD ? structureLabel : structure]}>{structureLabel}</span>
      <span className={badgeClass}>{badgeText}</span>
      <style>{style}</style>
    </div>
  );
}
