export default {
  'Strucutre': 'Strucutre',
  'User Email': 'User Email',
  'bloomberg': 'bloomberg',
  'Download PDF': 'Download PDF',
  'Accumulation/Strike Level': 'Accumulation/Strike Level',
  'Expired at': 'Expired at',
  'Submit': 'Submit',
  'Status': 'Status',
  'price': 'price',
  'Search (Name, Status)': 'Search (Name, Status)',
  'underlying': 'underlying',
  'Details': 'Details',
  'download': 'download',
  'Percentage': 'Percentage',
  'Statement recipients': 'Statement recipients',
  'size': 'size',
  'Username': 'Username',
  'volatility bucket': 'volatility bucket',
  'Users': 'Users',
  'Barrier': 'Barrier',
  'Restart Neon': 'Restart Neon',
  'expired at': 'expired at',
  'Search (client name, ref number, dashboard)': 'Search (client name, ref number, dashboard)',
  'Market Data': 'Market Data',
  'Search (name, description)': 'Search (name, description)',
  'Sales Team Name': 'Sales Team Name',
  'Bloomberg Ticker': 'Bloomberg Ticker',
  'calculate_type': 'calculate_type',
  'contract expiry': 'contract expiry',
  'Personnel dashboards': 'Personnel dashboards',
  'Upload File': 'Upload File',
  'User': 'User',
  'units': 'units',
  'Clients Names': 'Clients Names',
  'Search (subject, status, trade number)': 'Search (subject, status, trade number)',
  'Pages': 'Pages',
  'Notification Setting': 'Notification Setting',
  'Remaining quantity': 'Remaining quantity',
  'Tag': 'Tag',
  'Strucutre (Name, Code)': 'Strucutre (Name, Code)',
  'Roles': 'Roles',
  'Search (email, contract code, order status)': 'Search (email, contract code, order status)',
  'File name': 'File name',
  'quoted_currency': 'quoted_currency',
  'Started at': 'Started at',
  'Refresh': 'Refresh',
  'Choose Structure': 'Choose Structure',
  'User groups': 'User groups',
  'commodity contract': 'commodity contract',
  'Dashboard Name': 'Dashboard Name',
  'Disabled For Structure': 'Disabled For Structure',
  'user': 'user',
  'groups': 'groups',
  'Receiver': 'Receiver',
  'PDF': 'PDF',
  'Contract Expiry': 'Contract Expiry',
  'Accumulation': 'Accumulation',
  'structure': 'structure',
  'strucutre': 'strucutre',
  'Search (Title, SEO)': 'Search (Title, SEO)',
  'Active/Deactive': 'Active/Deactive',
  'Search (name, subject)': 'Search (name, subject)',
  'Underlying (Reference Contract)': 'Underlying (Reference Contract)',
  'market data': 'market data',
  'started at': 'started at',
  'Dashboards': 'Dashboards',
  'Volatility Bucket': 'Volatility Bucket',
  'comodity contract': 'comodity contract',
  'Underlying': 'Underlying',
  'dashboard': 'dashboard',
  'Team Emails': 'Team Emails',
  'Cancel': 'Cancel',
  'Search (name)': 'Search (name)',
  'Price': 'Price',
  'Search (dashboard name)': 'Search (dashboard name)',
  'Back': 'Back',
  'history': 'History',
};
