import React, { Component } from 'react';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {
  Edit,
  TabbedForm,
  FormTab,
} from 'admin-on-rest';
import TrialsControl from './components/trialsControl';
import SubStepsControl from './components/substepsControl';

// hook for change uri path
const match = {
  params: {
    id: 'trials',
  },
};

const RECORD_FORM = 'record-form';
const selector = formValueSelector(RECORD_FORM);

class PricingMiniTrialsList extends Component {
  render() {
    return (
      <Edit
        {...this.props}
        title="Pricing Mini Trials"
        match={match}
      >
        <TabbedForm {...this.props} redirect={false}>
          <FormTab label="Trials">
            <TrialsControl {...this.props}/>
          </FormTab>
          <FormTab label="Substeps Per Day">
            <SubStepsControl {...this.props}/>
          </FormTab>
        </TabbedForm>
      </Edit>
    );
  }
}

PricingMiniTrialsList.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
};


PricingMiniTrialsList.defaultProps = {
  elements: [],
};

const enhance = compose(
  connect(state => ({
    elements: selector(state, 'elements'),
  }), { }),
);

export default enhance(PricingMiniTrialsList);
