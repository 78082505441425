import { Datagrid, DateInput, Filter, List, Responsive, TextField, TextInput } from 'admin-on-rest';
import React from 'react';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput, formatDateInput, dateParser } from '../utils/dates';

const EqConfigFilter = props => (
  <Filter {...props}>
    <TextInput
      source="table"
    />
    <TextInput
      source="input"
    />
    <TextInput
      source="output"
    />
    <TextInput
      source="comment_output"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
      parse={dateParser}
    />
  </Filter>
);

class EqConfigList extends React.Component {
  render() {
    return (<List {...this.props}
      filters={<EqConfigFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={50}
    >
      <Responsive
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
            <TextField
              source="table"
            />
            <TextField
              source="input"
            />
            <TextField
              source="output"
            />
            <TextField
              source="comment_output"
            />
            <DateField
              formatDate={formatDateTimeInput}
              source="created_at"
            />

          </Datagrid>
        }
      />
    </List>);
  }
}

export default EqConfigList;
