import React, { Component } from 'react';
import { NumberInput, Filter, TextInput, SelectInput, DateInput, BooleanInput } from 'admin-on-rest';
import { formatDateInput } from '../../utils/dates';
import { AUTOAPPROVE_STATUS_APPROVED, AUTOAPPROVE_STATUS_EXCEEDED } from '../../constants';

export const TIME_IN_FORCE_OPTIONS = [
  { id: 'day', name: 'Day' },
  { id: 'gtc', name: 'GTC' },
  { id: 'gtd', name: 'Good Till Date' },
];

export const TYPE_LIMIT = 'limit';
export const TYPE_MARKET = 'market';
export const TYPE_STOP = 'stop';
export const TYPE_ICEBERG = 'iceberg';
export const TYPE_STOP_LIMIT = 'stop-limit';
export const TYPE_OCO = 'oco';

export const ORDER_TYPE_OPTIONS = [
  { id: TYPE_LIMIT, name: 'Limit' },
  { id: TYPE_MARKET, name: 'Market' },
  { id: TYPE_STOP, name: 'Stop' },
  { id: TYPE_ICEBERG, name: 'Iceberg' },
  { id: TYPE_STOP_LIMIT, name: 'Stop Limit' },
  { id: TYPE_OCO, name: 'OCO' },
];

export const AUTO_APPROVE_STATUS_OPTIONS = [
  { id: null, name: 'None' },
  { id: AUTOAPPROVE_STATUS_APPROVED, name: 'Auto Approved' },
  { id: AUTOAPPROVE_STATUS_EXCEEDED, name: 'Exceeded Limit' },
];


export default class Filters extends Component {
  render() {
    return (
      <Filter {...this.props}>
        <TextInput source="email" />
        <TextInput source="contract_code" />
        <TextInput source="direction" />
        <TextInput source="status" />
        <SelectInput
          source="structure"
          choices={[
            { id: 'swap', name: 'swap' },
            { id: 'asian swap', name: 'asian swap' },
            { id: 'strip bullet swap', name: 'strip bullet swap' },
            { id: 'compo', name: 'asian/swap compo' },
            { id: 'vanilla', name: 'vanilla' },
            { id: 'minifuture', name: 'minifuture' },
            { id: 'forwards / ndfs', name: 'forwards / ndfs' },
          ]}
        />
        <SelectInput
          source="request_or_order"
          choices={[
            { id: 'request', name: 'request' },
            { id: 'order', name: 'order' },
          ]}
        />
        <SelectInput
          label="Type"
          source="structure_type"
          choices={[
            { id: 'call', name: 'call' },
            { id: 'put', name: 'put' },
          ]}
        />
        <SelectInput
          label="Time in Force"
          source="time_in_force"
          choices={TIME_IN_FORCE_OPTIONS}
        />
        <SelectInput
          label="Order Type"
          source="order_type"
          choices={ORDER_TYPE_OPTIONS}
        />
        <NumberInput source="stop_price" />
        <DateInput
          options={{
            formatDate: formatDateInput,
          }}
          source="expiry_date"
          showTime
        />
        <NumberInput source="quantity" />
        <NumberInput source="filled_quantity" />
        <NumberInput source="price" />
        <NumberInput source="strike_level" />
        <SelectInput
          label="Auto Approve"
          source="autoapprove_status"
          choices={AUTO_APPROVE_STATUS_OPTIONS}
        />
        <BooleanInput
          label="Skip Hedging"
          source="skip_hedging"
        />
      </Filter>
    );
  }
}
