import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import {
  refreshView as refreshViewAction,
  showNotification as showNotificationAction,
  translate as adminTranslate,
  SimpleForm,
} from 'admin-on-rest';
import request from 'superagent';
import path from '../../../path';
import { connect } from 'react-redux';
import { ActionSearch, ActionViewModule } from 'material-ui/svg-icons/index.es';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import './ProductRepoButton.css';
import { API_SOURCE_CONFIG } from '../utils';
import { StructureDataRecord, WrapperDataRecord } from '../../types';
import { ComponentEnhancer } from 'recompose';
import { SpCommonDispatchProps, spFieldListData } from '../types';
import { Categorisation, Layout, Validation } from '@agile/common-types/build/configurableSp/types';

const PREFIX = 'configurableSp.productRepo';

interface Mapping {
  field: string;
  section: string;
  compositeName?: string;
}
type SectionMapping = { [filed: string]: Mapping };
type Mappings = { [section: string]: SectionMapping };

interface SectionField {
  categorisations: Categorisation[];
  'composite-fields': SectionField[] | null;
  datatype: string;
  default: string | null;
  'display-name': string;
  lockable: boolean;
  mandatory: boolean;
  meta: string | null;
  name: string;
  tag: string | null;
  validation: Validation[] | null;
}

interface SectionData {
  'display-name'?: string;
  fields: SectionField[];
}

interface SectionProps {
  sectionData: SectionData & { section?: string };
  layoutInputs?: { id: string; name: string }[];
  setSelectedMappings: (section: string, name: string, value?: string, compositeName?: string) => void;
  selectedMappings: Mappings;
  sectionName: string;
  compositeName?: string;
  section: string;
}

const Section: React.FC<SectionProps> = ({
  sectionData,
  layoutInputs = [],
  setSelectedMappings,
  selectedMappings,
  sectionName,
  compositeName,
  section,
}) => {
  const { fields } = sectionData;

  return (
    <div className="sections">
      <div className="title">{sectionName.toUpperCase().replaceAll('-', ' ')}</div>
      <div>
        {fields.map((field) => {
          const { name, mandatory } = field;
          const subSection = field['composite-fields'];

          const onChange = (_, index: number) => {
            const value = index === 0 ? undefined : layoutInputs[index].name;
            setSelectedMappings(section, name, value, compositeName);
          };

          return (
            <div key={name}>
              {!subSection && (
                <SelectField
                  key={name}
                  id={name}
                  floatingLabelText={`${name}${mandatory && !subSection ? '*' : ''}`}
                  fullWidth
                  name={name}
                  value={selectedMappings?.[section]?.[name]?.field}
                  onChange={onChange}
                  required
                >
                  {layoutInputs.map((x) => (
                    <MenuItem key={x.id} value={x.name} primaryText={x.name} />
                  ))}
                </SelectField>
              )}
              {subSection && (
                <Section
                  key={`${name}-subsection`}
                  sectionName={`${name}-composite-fields`}
                  sectionData={{ fields: subSection }}
                  layoutInputs={layoutInputs}
                  selectedMappings={selectedMappings}
                  setSelectedMappings={setSelectedMappings}
                  compositeName={name}
                  section={section}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface ProductRepoButtonOwnProps {
  record?: StructureDataRecord | WrapperDataRecord;
  field?: 'payoff' | 'wrapper';
  floatingLabelText?: string;
}

type ProductRepoButtonProps = ProductRepoButtonOwnProps & SpCommonDispatchProps;
interface SpFieldDefinitionSection extends SectionData {
  section: string;
}
// eslint-disable-next-line no-undef
interface SpFieldDefinitionProps extends Pick<ProductRepoButtonProps, 'record' | 'showNotification' | 'refreshView'> {
  selectedSpField: string;
  apiSource: string;
  closeDialog: () => void;
  layout: Layout[] | null | undefined;
  field: string;
  spFieldDefinition?: {
    'display-name': string;
    version: number;
    sections: SpFieldDefinitionSection[];
  };
}

const SpFieldDefinition: React.FC<SpFieldDefinitionProps> = ({
  spFieldDefinition,
  selectedSpField,
  layout,
  showNotification,
  closeDialog,
  record,
  refreshView,
  apiSource,
  field,
}) => {
  const { fields } = API_SOURCE_CONFIG[field];
  const mappings = record?.[field]?.mappings;
  const [selectedMappings, setSelectedMappings] = useState<Mappings>(mappings || {});
  const selectedSpFieldDefinition = spFieldDefinition?.['display-name'];
  const selectedRecordName = record?.[fields.name];

  useEffect(() => {
    if (selectedRecordName !== selectedSpFieldDefinition) {
      setSelectedMappings({});
    } else {
      setSelectedMappings(mappings || {});
    }
  }, [selectedSpFieldDefinition, selectedRecordName, mappings]);

  const onMappingSelected = (section, name, value, compositeName) => {
    setSelectedMappings((prevState) => {
      const temp = { ...prevState };

      if (value === undefined) {
        delete temp[section][name];
        return temp;
      }

      if (temp[section]) {
        temp[section][name] = { field: value, compositeName, section };
      } else {
        temp[section] = {
          [name]: { field: value, compositeName, section },
        };
      }

      return temp;
    });
  };

  const inputSelectValues: SectionProps['layoutInputs'] = useMemo(() => {
    if (!layout) {
      return [];
    }
    return [
      { id: 'empty', name: '<--- delete --->' },
      ...layout.map(({ id }) => ({ id, name: id })).sort((a, b) => a.id.localeCompare(b.id)),
    ];
  }, [layout]);

  const onHandleSubmit = () => {
    request
      .post(`${path}/admin/${apiSource}/savePRMappings`)
      .set('Token', localStorage.getItem('session'))
      .field('selectedMappings', JSON.stringify(selectedMappings))
      .field('version', spFieldDefinition?.version)
      .field('id', record?.id)
      .field('name', spFieldDefinition?.['display-name'])
      .then(() => {
        showNotification(`${PREFIX}.successful`);
        refreshView();
        closeDialog();
      })
      .catch((e) => {
        console.warn(e);
        showNotification('Something went wrong. Please try again');
      });
  };

  if (!spFieldDefinition || selectedSpField?.toLowerCase() !== selectedSpFieldDefinition?.toLowerCase()) {
    return null;
  }

  const { sections } = spFieldDefinition;

  return (
    <SimpleForm handleSubmit={onHandleSubmit}>
      <>
        {sections.map((section) => {
          return (
            <div key={section.section} className="section">
              <Section
                section={section.section}
                sectionName={section.section}
                sectionData={section}
                layoutInputs={inputSelectValues}
                selectedMappings={selectedMappings}
                setSelectedMappings={onMappingSelected}
              />
            </div>
          );
        })}
      </>
    </SimpleForm>
  );
};

const ProductRepoButton: React.FC<ProductRepoButtonProps> = ({
  record,
  translate,
  showNotification,
  refreshView,
  field = 'payoff',
  floatingLabelText = 'Payoff',
}) => {
  const { apiSource, definition, allDefinition, fields } = API_SOURCE_CONFIG[field];
  const [open, setOpen] = useState(false);
  const [spField, setSpField] = useState(record?.[fields.name] || '');
  const [spFieldList, setSpFieldList] = useState<spFieldListData[]>([]);
  const [spFieldDefinition, setSpFieldDefinition] = useState();

  const searchSpField = (chosenSpField: string) => {
    request
      .put(`${path}/admin/${apiSource}/${definition}`)
      .set('Token', localStorage.getItem('session'))
      // product repo has hyphens for spaces in the GET request
      .send({ [field]: chosenSpField.replaceAll(' ', '-') })
      .then((res) => {
        setSpFieldDefinition(res.body);
      })
      .catch((e) => {
        showNotification(translate(`${PREFIX}.error.${e.response.body.message || 'generic'}`, 'warning'));
      });
  };

  const onSearchClicked = (selectedUri: string) => {
    searchSpField(selectedUri);
  };

  const handleOpen = async () => {
    setOpen(true);
    await request
      .get(`${path}/admin/${apiSource}/${allDefinition}`)
      .set('Token', localStorage.getItem('session'))
      .then((res) => {
        const list = res.body;
        setSpFieldList(list);
      })
      .catch((e) => {
        showNotification(translate(`${PREFIX}.error.${e.response.body.message || 'generic'}`, 'warning'));
      });

    const selectedSpField = record?.[fields.name] || '';
    const selectedUri = record?.[fields.uri] || '';
    setSpField(selectedSpField);

    if (selectedUri !== '') {
      onSearchClicked(selectedUri);
    }
  };

  const closeDialog = () => {
    setOpen(false);
    setSpFieldDefinition(undefined);
  };

  const handleSpFieldChange = (_, index: number) => {
    setSpField(spFieldList[index]?.id);
  };

  return (
    <div>
      <FlatButton label={translate(`${PREFIX}.buttonText`)} icon={<ActionViewModule />} onClick={handleOpen} />
      <Dialog
        title={translate(`${PREFIX}.title`)}
        modal={false}
        open={open}
        repositionOnUpdate={false}
        style={{ paddingTop: '50px' }}
        bodyStyle={{ overflowY: 'auto' }}
      >
        <div style={{ paddingTop: '15px' }}>
          <div style={{ paddingBottom: '15px' }}>
            <SelectField
              disabled={!spFieldList.length}
              floatingLabelText={floatingLabelText}
              fullWidth
              value={spField}
              onChange={handleSpFieldChange}
              required
            >
              {spFieldList.map((x) => (
                <MenuItem key={x.id} value={x.name} primaryText={x.name} />
              ))}
            </SelectField>
            <RaisedButton
              label={translate('configurableSp.dialog.btnSearch')}
              onClick={onSearchClicked}
              disabled={!spField}
              icon={<ActionSearch />}
            />
          </div>
          <SpFieldDefinition
            selectedSpField={spField}
            spFieldDefinition={spFieldDefinition}
            layout={record?.layout?.layout}
            showNotification={showNotification}
            refreshView={refreshView}
            closeDialog={closeDialog}
            record={record}
            apiSource={apiSource}
            field={field}
          />
          <div style={{ paddingTop: '15px', float: 'right' }}>
            <RaisedButton
              label={translate('configurableSp.dialog.btnClose')}
              onClick={closeDialog}
              icon={<CancelIcon />}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const enhance: ComponentEnhancer<ProductRepoButtonProps, ProductRepoButtonOwnProps> = compose(
  adminTranslate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(ProductRepoButton);
