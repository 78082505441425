import React from 'react';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

const DeleteButton = props =>
  <div className='delete-button'>
    <a href={`#${props.basePath}/${props.record.id}/delete`}>
      <DeleteIcon color="red" style={{ margin: '7px 0 0' }} />
    </a>
  </div>;

export default DeleteButton;
