export default `
  .badge {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
  }

  .badge-blue {
    background-color: #337ab7;
  }

  .badge-yellow {
    background-color: #f0ad4e;
  }

  .badge-purple {
    background-color: #4e3755;
  }

  .badge-purple-lite {
    background-color: #b451d2;
  }

  .badge-green {
    background-color: #69c83f;
  }

  .badge-red {
    background-color: #9D1D37;
    margin-left: 5px;
  }

  .badge-grey {
    margin-left: 5px;
    background-color: #918686
  }

  .badge-pink {
    margin-left: 5px;
    background-color: pink
  }

  .badge-asian {
    margin-left: 5px;
    background-color: #17a2b8
  }

  .badge-bullet-strip {
    margin-left: 5px;
    background-color: #4059AD
  }
`;
