import React from 'react';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import EditIcon from 'material-ui/svg-icons/image/edit';
import request from 'superagent';
import { connect } from 'react-redux';

import { GET_LIST
  , GET_ONE
  , translate
  , showNotification as showNotificationAction
  , refreshView as refreshViewAction } from 'admin-on-rest';

import restClient from '../restClient';
import path from '../path';

import ValidateEmail from '../helpers/ValidateEmail';

class OTCEditButton extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
    this.state = {
      open: false,
      emails: [],
      viewers: [],
    };
  }

  submitForm = () => {
    const { viewers } = this.state;
    const { showNotification, refreshView, record } = this.props;

    this.setState({ open: false }, () => {
      request
        .post(`${path}/admin/otc_confirmation/viewers/${record.id}`)
        .set('token', localStorage.getItem('session'))
        .field('viewers[]', viewers)
        .then(() => {
          showNotification('OTCConfirmations.edit.viewers_edit_success');
          refreshView();
        })
        .catch(() => showNotification('OTCConfirmations.edit.viewers_edit_error', 'warning'));
    });
  }

  getEmails = () =>
    restClient(GET_LIST, 'users', {
      sort: {
        field: 'email',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    });

  getViewers = () =>
    restClient(GET_ONE, 'otc_confirmation/viewers', {
      id: this.props.record.id,
    });

  async getEmailsViewers() {
    const [emails, viewers] = await Promise.all([this.getEmails(), this.getViewers()]);
    this.setState({
      ...this.state,
      emails: emails.data.map(e => e.email),
      viewers: viewers.data.map(v => v.email),
    });
  }

  handleAddViewer = (viewer) => {
    const viewers = [...this.state.viewers, viewer.toLowerCase()];
    this.setState({
      ...this.state,
      viewers,
    });
  }

  handleBeforeAddViewerChip = (chip) => {
    if (!ValidateEmail(chip)) return false;
    return chip.toLowerCase();
  }

  handleDeleteViewer = (viewer) => {
    const viewers = this.state.viewers.filter(c => c !== viewer);
    this.setState({
      ...this.state,
      viewers,
    });
  }

  changeDialogStatus = status => () => {
    this.setState({ open: status });
    this.getEmailsViewers();
  };

  render() {
    const { record } = this.props;
    const { emails, viewers } = this.state;

    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          icon={<EditIcon color='#01579B' />}
          onClick={this.changeDialogStatus(true)}
          style= {{ minWidth: '30px', padding: '0 15px' }}
        />
        <Dialog
          title={`${this.translate('OTCConfirmations.edit.title')} ${record && record.id}`}
          modal={false}
          open={this.state.open}
          autoScrollBodyContent={true}
        >
          <ChipInput
            value={viewers}
            openOnFocus={true}
            fullWidth
            fullWidthInput
            dataSource={emails}
            floatingLabelText={this.translate('OTCConfirmations.edit.emails')}
            onRequestAdd={this.handleAddViewer}
            onRequestDelete={this.handleDeleteViewer}
            onBeforeRequestAdd={this.handleBeforeAddViewerChip}
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              label={this.translate('pdfs.dialog.btnCancel')}
              icon={<CancelIcon />}
              onClick={this.changeDialogStatus(false)}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              primary={true}
              icon={<SubmitIcon />}
              onClick={this.submitForm}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(OTCEditButton);
