import { FunctionField, NumberField, ReferenceField, Show, SimpleShowLayout, TextField } from 'admin-on-rest';
import React from 'react';
import { DateField } from '../helpers';
import { formatDateTimeInput } from '../utils/dates';
import CalculateHistoryShowTitle from './CalculateHistoryShowTitle';

const CalculateHistoryShow = props => (
  <Show {...props}
    title={<CalculateHistoryShowTitle />}
  >
    <SimpleShowLayout>
      <TextField
        source="structure"
      />
      <TextField
        source="type"
      />
      <TextField
        source="bloomberg_ticker"
      />
      <TextField
        source="commodity_contract"
      />
      <ReferenceField
        label="User"
        source="user_id"
        reference="users"
      >
        <FunctionField
          render={r => `${r.lastName} ${r.firstName} (${r.email})`}
        />
      </ReferenceField>
      <NumberField
        style={{ textAlign: 'left' }}
        source="reference_price"
      />
      <NumberField
        style={{ textAlign: 'left' }}
        label="Accumulation/Strike Level"
        source="strike_level"
      />
      <NumberField
        style={{ textAlign: 'left' }}
        source="barrier_level"
      />
      <NumberField
        style={{ textAlign: 'left' }}
        source="notional"
      />
      <NumberField
        style={{ textAlign: 'left' }}
        source="price"
      />
      <NumberField
        style={{ textAlign: 'left' }}
        source="price_to_display"
      />
      <TextField
        source="quote_units"
      />
      <TextField
        source="quoted_currency"
      />
      <DateField
        formatDate={formatDateTimeInput}
        source="created_at"
        showTime
      />
    </SimpleShowLayout>
  </Show>
);

export default CalculateHistoryShow;
