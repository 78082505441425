import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import ContentSave from 'material-ui/svg-icons/content/save';
import CircularProgress from 'material-ui/CircularProgress';

export class SaveButton extends Component {
    handleClick = (e) => {
      e.preventDefault();
      const { onClick } = this.props;
      onClick();
    };

    render() {
      const {
        saving,
        label = 'Save',
        raised = true,
        submitOnEnter,
        redirect,
        disabled = false,
      } = this.props;
      const type = submitOnEnter ? 'submit' : 'button';
      const ButtonComponent = raised ? RaisedButton : FlatButton;

      return (
        <ButtonComponent
          type={type}
          label={label}
          disabled={disabled}
          icon={
            saving && saving.redirect === redirect ? (
              <CircularProgress size={25} thickness={2} />
            ) : (
              <ContentSave />
            )
          }
          onClick={this.handleClick}
          primary={!saving}
          style={{
            margin: '10px 24px',
            position: 'relative',
          }}
        />
      );
    }
}

SaveButton.propTypes = {
  label: PropTypes.string,
  raised: PropTypes.bool,
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  handleSubmitWithRedirect: PropTypes.func,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClick: PropTypes.func,
};

SaveButton.defaultProps = {
  handleSubmitWithRedirect: () => () => {},
};

export default SaveButton;
