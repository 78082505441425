import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { APP_TITLE } from '../../../constants';

const Seo = (props) => {
  const { title } = props;
  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${APP_TITLE}`}
    />
  );
};

Seo.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Seo;
