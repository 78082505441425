import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import { TableBody, TableRow } from 'material-ui/Table';
import DatagridCell from './DatagridCell';

const DatagridBody = ({
  resource,
  children,
  ids,
  isLoading,
  data,
  basePath,
  styles,
  rowStyle,
  options,
  rowOptions,
  fieldWithSubRows,
  subRowClassName,
  ...rest
}) => (
  <TableBody
    displayRowCheckbox={false}
    className="datagrid-body"
    {...rest}
    {...options}
  >
    {ids.map((id, rowIndex) => (
      <Fragment key={id}>
        <TableRow
          style={rowStyle ? rowStyle(data[id], rowIndex) : styles.tr}
          key={id}
          selectable={false}
          {...rowOptions}
        >
          {React.Children.map(
            children,
            (field, index) =>
              field ? (
                <DatagridCell
                  key={`${id}-${field.props.source || index}`}
                  className={`column-${field.props.source}`}
                  record={data[id]}
                  defaultStyle={
                    index === 0 ? (
                      styles.cell['td:first-child']
                    ) : (
                      styles.cell.td
                    )
                  }
                  {...{ field, basePath, resource }}
                />
              ) : null,
          )}
        </TableRow>
        {data[id] && data[id][fieldWithSubRows] && (data[id][fieldWithSubRows] || []).length ?
          data[id][fieldWithSubRows].map((subData, rowIndex) => (
            <TableRow
              style={styles.tr}
              key={`${id}-${rowIndex}`}
              selectable={false}
              className={`${subRowClassName} ${data[id][fieldWithSubRows].length - 1 === rowIndex ? `${subRowClassName}-last` : null}`}
              {...rowOptions}
            >
              {React.Children.map(
                children,
                (field, index) =>
                  field ? (
                    <DatagridCell
                      key={`${id}-${field.props.source || index}`}
                      className={`column-${field.props.source}`}
                      record={{ ...subData, rowIndex, rowType: 'legs' }}
                      defaultStyle={
                        index === 0 ? (
                          styles.cell['td:first-child']
                        ) : (
                          styles.cell.td
                        )
                      }
                      {...{ field, basePath, resource }}
                    />
                  ) : null,
              )}
            </TableRow>
          ))
          : null}
      </Fragment>
    ))}
  </TableBody>
);

DatagridBody.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool,
  resource: PropTypes.string,
  data: PropTypes.object.isRequired,
  basePath: PropTypes.string,
  options: PropTypes.object,
  rowOptions: PropTypes.object,
  styles: PropTypes.object,
  rowStyle: PropTypes.func,
  fieldWithSubRows: PropTypes.string,
  subRowClassName: PropTypes.string,
};

DatagridBody.defaultProps = {
  data: {},
  ids: [],
};

const PureDatagridBody = shouldUpdate(
  (props, nextProps) => nextProps.isLoading === false,
)(DatagridBody);

// trick material-ui Table into thinking this is one of the child type it supports
PureDatagridBody.muiName = 'TableBody';

export default PureDatagridBody;
