import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import RaisedButton from 'material-ui/RaisedButton';

import { connect } from 'react-redux';
import { translate, GET_LIST } from 'admin-on-rest';

import PDFHistorySendIcon from './PDFHistorySendIcon';
import PDFHistorySendAction from './PDFHistorySendAction';

import restClient from '../restClient';

class PDFHistorySendButton extends React.Component {
  state = {
    open: false,
    error: false,
    emails: [],
    bcc: [],
    cc: [],
    chips: [],
  }

  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  handleOpen() {
    this.setState({ open: true });
    restClient(GET_LIST, 'users', {
      sort: {
        field: 'email',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((res) => {
      const { cc, bcc, emails } = this.props.record;

      this.setState({
        chips: res.data.map(i => i.email),
        bcc: bcc || [],
        cc: cc || [],
        emails: emails || [],
      });
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleSubmit() {
    if (this.state.emails.length) {
      this.setState({ error: false, open: false });
    } else {
      this.setState({ error: true });
      return;
    }

    const { record } = this.props;
    const { emails, bcc, cc } = this.state;

    this.props.sendAction(record.id, { emails, bcc, cc });
  }

  handleAddEmail(chip) {
    const chips = [...this.state.emails, chip];
    this.setState({
      emails: chips,
      error: !chips.length,
    });
  }

  handleAddBCC(chip) {
    const chips = [...this.state.bcc, chip];
    this.setState({
      bcc: chips,
    });
  }

  handleAddCC(chip) {
    const chips = [...this.state.cc, chip];
    this.setState({
      cc: chips,
    });
  }

  handleDeleteEmail(chip) {
    const chips = this.state.emails.filter(c => c !== chip);
    this.setState({
      emails: chips,
      error: !chips.length,
    });
  }

  handleDeleteBCC(chip) {
    const chips = this.state.bcc.filter(c => c !== chip);
    this.setState({
      bcc: chips,
    });
  }

  handleDeleteCC(chip) {
    const chips = this.state.bcc.filter(c => c !== chip);
    this.setState({
      cc: chips,
    });
  }

  handleBeforeAddChip(chip) {
    const { emails, bcc, cc } = this.state;
    return !emails.includes(chip) && !bcc.includes(chip) && !cc.includes(chip);
  }

  dataSource() {
    const { emails, bcc, cc } = this.state;
    return this.state.chips.filter(c => !emails.includes(c) && !bcc.includes(c) && !cc.includes(c));
  }

  render() {
    const { record } = this.props;
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label={this.translate('pdfHistories.dialog.btnSend')}
          icon={<PDFHistorySendIcon />}
          onClick={this.handleOpen.bind(this)}
        />
        <Dialog
          title={`${this.translate('pdfHistories.dialog.title')}${record && record.pdf_name}`}
          modal={false}
          open={this.state.open}
          autoScrollBodyContent={true}
        >
          <ChipInput
            value={this.state.emails}
            dataSource={this.dataSource()}
            openOnFocus={true}
            onBeforeRequestAdd={this.handleBeforeAddChip.bind(this)}
            onRequestAdd={this.handleAddEmail.bind(this)}
            onRequestDelete={this.handleDeleteEmail.bind(this)}
            errorText={this.state.error && this.translate('aor.validation.required')}
            floatingLabelText={this.translate('pdfHistories.dialog.emailText')}
            fullWidth
            fullWidthInput
          />
          <ChipInput
            value={this.state.cc}
            dataSource={this.dataSource()}
            openOnFocus={true}
            onBeforeRequestAdd={this.handleBeforeAddChip.bind(this)}
            onRequestAdd={this.handleAddCC.bind(this)}
            onRequestDelete={this.handleDeleteCC.bind(this)}
            floatingLabelText={this.translate('pdfHistories.dialog.ccText')}
            fullWidth
            fullWidthInput
          />
          <ChipInput
            value={this.state.bcc}
            dataSource={this.dataSource()}
            openOnFocus={true}
            onBeforeRequestAdd={this.handleBeforeAddChip.bind(this)}
            onRequestAdd={this.handleAddBCC.bind(this)}
            onRequestDelete={this.handleDeleteBCC.bind(this)}
            floatingLabelText={this.translate('pdfHistories.dialog.bccText')}
            fullWidth
            fullWidthInput
          />
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              label={this.translate('pdfHistories.dialog.btnCancel')}
              onClick={this.handleClose.bind(this)}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label={this.translate('pdfHistories.dialog.btnSubmit')}
              primary={true}
              onClick={this.handleSubmit.bind(this)}
              icon={<SubmitIcon />}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

PDFHistorySendButton.propTypes = {
  record: PropTypes.object,
  sendAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    sendAction: PDFHistorySendAction,
  }),
);

export default enhance(PDFHistorySendButton);
