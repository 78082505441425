import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const DateRangePicker = ({
  from = null,
  to = null,
  onChange: onChangeHandler = null,
  theme,
  ...rest
}) => {
  const [startDate, setStartDate] = useState(from);
  const [endDate, setEndDate] = useState(to);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (onChangeHandler) onChangeHandler(dates);
  };

  let themeProps = {};

  if (theme === 'calendar') {
    themeProps = {
      inline: true,
      monthsShown: 2,
    };
  }

  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      selectsDisabledDaysInRange
      {...themeProps}
      {...rest}
    />
  );
};

DateRangePicker.defaultProps = {
  from: null,
  to: null,
  theme: 'calendar',
};

DateRangePicker.propTypes = {
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  theme: PropTypes.oneOf(['input', 'calendar']),
  onChange: PropTypes.func,
};

export default DateRangePicker;
