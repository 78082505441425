import React from 'react';

import {
  SimpleForm,
  AutocompleteInput,
  ReferenceInput,
  Create,
  TextInput,
  BooleanInput,
  SelectInput,
  Toolbar,
  SaveButton,
  required,
  email,
  minLength,
  maxLength,
} from 'admin-on-rest';

import { createUserStatusesSource } from './helpers';

const verify = (value, allValues, props) =>
  (allValues._password === value ? undefined : props.translate('users.validate.verify'));

const password = (value, allValues, props) =>
  (/^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[A-Z])(?=.*[~<>,./?|!@#$%^&*`()\-_+={}[\];:]))|((?=.*[a-z])(?=.*[0-9])(?=.*[~<>,./?|!@#$%^&*`()\-_+={}[\];:]))|((?=.*[A-Z])(?=.*[0-9])(?=.*[~<>,./?|!@#$%^&*`()\-_+={}[\];:]))).{0,}$/.test(value) ? undefined : props.translate('users.validate.password'));

const UserToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      redirect="list"
      submitOnEnter={true}
    />
  </Toolbar>
);

const validateMinLengthToTwo = minLength(2);
const validateMinLengthToEight = minLength(8);

const validateMaxLengthToTwenty = maxLength(20);
const validateMaxLengthToHundred = maxLength(100);
const UserCreate = props => (
  <Create {...props}>
    <SimpleForm
      toolbar={<UserToolbar />}
    >
      <TextInput
        source="email"
        type="email"
        validate={[required, email]}
      />
      <TextInput
        source="firstName"
        label="First Name"
        validate={[required, validateMinLengthToTwo, validateMaxLengthToHundred]}
      />
      <TextInput
        source="lastName"
        label="Last Name"
        validate={[required, validateMinLengthToTwo, validateMaxLengthToHundred]}
      />
      <TextInput
        label="Slack Id"
        source="slackId"
      />
      <ReferenceInput perPage={1000} key="spreadsheet_id" source="spreadsheetId" reference="spreadsheets" label="Spread sheet" allowEmpty>
        <AutocompleteInput
          optionText="title"
          optionValue="id"
          options={
            {
              'listStyle': { 'maxHeight': '30vh', 'overflow': 'auto' },
              'onBlur': () => { },
            }
          }
        />
      </ReferenceInput>
      <ReferenceInput filter='noFilter' key="channelId" source="channelId" reference="slack_channels" label="Slack Team" allowEmpty>
        <AutocompleteInput
          optionText="team"
          optionValue="id"
          options={
            {
              'listStyle': { 'maxHeight': '30vh', 'overflow': 'auto' },
              'onBlur': () => { },
            }
          }
        />
      </ReferenceInput>
      <SelectInput
        source="status"
        choices={createUserStatusesSource}
        validate={[required]}
      />
      <ReferenceInput filter='noFilter' key="ruleId" source="ruleId" reference="volatility_rules_names" label="Volatility Rule" validate={[required]}>
        <SelectInput
          optionText="name"
          optionValue="id"
          options={
            {
              'listStyle': { 'maxHeight': '30vh', 'overflow': 'auto' },
              'onBlur': () => { },
            }
          }
        />
      </ReferenceInput>
      <TextInput
        source="_password"
        type="password"
        label="Password"
        validate={[required, password, validateMinLengthToEight, validateMaxLengthToTwenty]}
      />
      <TextInput
        source="_verify"
        label="Verify Password"
        type="password"
        validate={[required, password, verify, validateMinLengthToEight, validateMaxLengthToTwenty]}
      />
      <BooleanInput
        label="Additional terms"
        source="additionalTerms"
        defaultValue={true}
      />
      <BooleanInput
        label="Greeks Display"
        source="greeks"
        defaultValue={false}
      />
    </SimpleForm>
  </Create>
);

export default UserCreate;
