import React, { Component } from 'react';
import TextField from 'material-ui/TextField';
import moment from 'moment';
import { extractOptionLabel } from '../../../utils/options';
import { TIME_IN_FORCE_OPTIONS } from './../Filters';

export default class GeneralTab extends Component {
  render() {
    const { order } = this.props;
    const { structure } = order;
    return (
      <div className="tab-grid">
        <TextField className="username" floatingLabelText='Username' title='Username' name='username' type="text" value={`${order.first_name} ${order.last_name}`} fullWidth disabled/>
        <TextField className="email" floatingLabelText='Email' title='Email' name='email' type="text" value={order.email} fullWidth disabled/>
        <TextField className="last_edit_by" floatingLabelText='Processed by' title='Processed by' name='last_edit_by' type="text" value={order.last_edit_by} fullWidth disabled/>
        <TextField className="structure" floatingLabelText='Structure' title='Structure' name='structure' type="text" value={order.structure} fullWidth disabled/>
        <TextField className="status" floatingLabelText='Status' title='Order status' name='status' type="text" value={order.status} fullWidth disabled/>
        <TextField className="quoted_currency" floatingLabelText='Currency' title='Currency' name='quoted_currency' type="text" value={order.quoted_currency} fullWidth disabled/>

        <TextField className="order_type" floatingLabelText='Order Type' title='Order Type' name='order_type' type="text" value={order.order_type} fullWidth disabled/>
        <TextField className="time_in_force" floatingLabelText='Time in Force' title='Time in Force' name='time_in_force' type="text" value={extractOptionLabel(order.time_in_force, TIME_IN_FORCE_OPTIONS, 'id', 'name')} fullWidth disabled/>

        <TextField className="stop_price" floatingLabelText='Stop Price' title='Stop Price' name='stop_price' type="text" value={order.stop_price ? `${order.stop_price || 0} / ${order.neon_stop_price || 0}` : ''} fullWidth disabled/>
        <TextField className="max_show" floatingLabelText='Max Show' title='Max Show' name='max_show' type="text" value={order.max_show ? `${order.max_show || 0}` : ''} fullWidth disabled/>
        <TextField className="expiry_date" floatingLabelText='Expiry Date' title='Expiry Date' name='expiry_date' type="text" value={order.expiry_date ? moment(order.expiry_date).format('DD MMMM YYYY HH:mm') : ''} fullWidth disabled/>
        {structure !== 'forwards / ndfs' ? <TextField className="contract_expiry" floatingLabelText='Contract Expiry' title='Contract Expiry' name='contract_expiry' type="text" value={moment(order.contract_expiry).utc().format('MMM YY')} fullWidth disabled/>
          : <TextField className="trade_date" floatingLabelText='Trade Date' title='Trade Date' name='trade_date' type="text" value={moment(order.trade_date).utc().format('MMM YY')} fullWidth disabled/>}
        <TextField className="created_at" floatingLabelText='Created At' title='Created At' name='created_at' type="text" value={moment(order.created_at).format('DD MMMM YYYY HH:mm')} fullWidth disabled/>
        <TextField className="canceled_user_id" floatingLabelText='Cancelled by' title='Cancelled by' name='canceled_user_id' type="text" value={order.canceled_user_id} fullWidth disabled/>
        <TextField className="canceled_at" floatingLabelText='Canceled Date' title='Canceled Date' name='canceled_at' type="text" value={order.canceled_at ? moment(order.canceled_at).format('DD MMMM YYYY HH:mm') : ''} fullWidth disabled/>
        <TextField className="errors" floatingLabelText='Errors' title='Errors' name='errors' type="text" value={order.errors} fullWidth disabled/>
      </div>
    );
  }
}
