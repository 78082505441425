import { GROUP_LABELS, PRICE_REQ_STATUSES, TRADE_STATUSES, SLACK_STATUSES } from './constants';

export const sortLogs = (restFields = {}, empty = false) => {
  if (empty) return { normalFields: [], objectFields: [] };
  return Object.entries(restFields).reduce((response, [label, value]) => {
    if (!value) return response;
    response[typeof value === 'object' ? 'objectFields' : 'normalFields'].push([label, value]);
    return response;
  }, { normalFields: [], objectFields: [] });
};

const getGroupIndex = (type, lastGroupIndex = 0) => {
  if (Object.values(PRICE_REQ_STATUSES).includes(type)) return 0;
  if (Object.values(TRADE_STATUSES).includes(type)) return 1;
  if (Object.values(SLACK_STATUSES).includes(type)) return 2;
  return lastGroupIndex;
};

export const groupByLogs = (logs) => {
  let lastGroupIndex = 0;
  return Object.entries(logs.reduce((current, log) => {
    const { type } = log;
    lastGroupIndex = getGroupIndex(type, lastGroupIndex);
    const groupName = GROUP_LABELS[lastGroupIndex] || 'UNKNOWN';

    return {
      ...current,
      [groupName]: [
        ...current[groupName] || [],
        log,
      ],
    };
  }, {}));
};
