import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { DEFAULT_ROUND, DEFAULT_INNER, DEFAULT_LINE_THICKNESS } from './constants';

const getCoordsOnArc = (angle, offset = 10) => [
  Math.cos(angle - (Math.PI / 2)) * offset,
  Math.sin(angle - (Math.PI / 2)) * offset,
];

const GradientArch = ({ angle, percent, startColor, stopColor, round, inner, lineThickness, showBnob }) => {
  const ref = React.useRef(null);

  const d = React.useMemo(() => d3.arc()
    .innerRadius(inner)
    .outerRadius(inner + lineThickness)
    .cornerRadius(round)
    .startAngle(-Math.PI / 2)
    .endAngle(angle)(), [angle]);

  const colorScale = React.useMemo(() => d3.scaleLinear()
    .domain([0, 1])
    .range([startColor, stopColor]), [startColor, stopColor]);

  const gradientSteps = React.useMemo(() => colorScale.ticks(10)
    .map(value => colorScale(value)), [startColor, stopColor]);

  const markerLocation = getCoordsOnArc(
    angle,
    1 - ((1 - 0.65) / 2),
  );

  return (
    <Fragment>
      <defs>
        <linearGradient
          id="gradient"
          gradientUnits="userSpaceOnUse"
          x1="-1"
          x2="1"
          y2="0">
          {gradientSteps.map((color, index) => (
            <stop
              key={color}
              stopColor={color}
              offset={`${index / (gradientSteps.length - 1)}`}
            />
          ))}
        </linearGradient>
      </defs>
      <path
        ref={ref}
        d={d}
        fill={'url(#gradient)'}
      />
      { showBnob ? <circle
        cx={markerLocation[0]}
        cy={markerLocation[1]}
        r="0.2"
        stroke="#2c3e50"
        strokeWidth="0.01"
        fill={colorScale(percent)}
      /> : null }
    </Fragment>
  );
};

GradientArch.PropTypes = {
  angle: PropTypes.number.isRequired,
  startColor: PropTypes.string.isRequired,
  stopColor: PropTypes.string.isRequired,
  round: PropTypes.number,
  inner: PropTypes.number.isRequired,
  lineThickness: PropTypes.number.isRequired,
  showBnob: PropTypes.bool.isRequired,
};

GradientArch.defaultProps = {
  angle: 0,
  startColor: '#c2cf8b',
  stopColor: '#c93522',
  round: DEFAULT_ROUND,
  inner: DEFAULT_INNER,
  lineThickness: DEFAULT_LINE_THICKNESS,
  showBnob: true,
};

export default GradientArch;
