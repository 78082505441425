/* eslint-disable */
import React from 'react';
import { SimpleForm, TextInput, DisabledInput, SelectInput, required, maxLength, minValue, maxValue, NumberInput, ReferenceInput, BooleanInput } from 'admin-on-rest';
import { validateisIntegerToForty } from './../utils/validations';

const intValidation = (value, allValues) => {
  if (value && value !== Math.trunc(value)) {
      return 'Fwd Months should not be a fraction.';
  }
  return undefined;
}

const validateMaxLengthToForty = maxLength(40);
const validateMinLengthToForty = minValue(1);
const validateFwdMonths = [minValue(1), maxValue(60), intValidation];
const validateAtmBound = [minValue(0), maxValue(1)];
const validateStrategyPeriods = [minValue(0), maxValue(12)];
const inputStyle = {margin: "0 10px 0 10px"};

export const CommodityForm = props => {
  const isSwap = props.resource === 'swap_commodities';
  return (
    <SimpleForm {...props} redirect="list" submitOnEnter={true}>
      {props.record && props.record.id ? (
        <DisabledInput source="commodity_code" />
      ) : (
        <TextInput source="commodity_code" validate={[required, validateMaxLengthToForty]} />
      )}
      <TextInput source="agile_name" validate={[required, validateMaxLengthToForty]} />
      <NumberInput source="num_fwd_months" label="Fwd Months" validate={validateFwdMonths} defaultValue={12} />
      {isSwap ? null : <NumberInput source="atm_bound" label="ATM Bound" validate={validateAtmBound} defaultValue={0.10} step={0.05} /> }
      <NumberInput source="qty" validate={[required, validateMaxLengthToForty]} />
      <NumberInput source="tick" validate={[required, validateMaxLengthToForty]} />
      <NumberInput source="spread" validate={[required, validateMaxLengthToForty]} />
      <label style={{margin: "20px 0 -10px 0"}}>Markup Spread:</label>
      <NumberInput style={inputStyle} label="x" source="markup_spread.x" validate={[required, validateMaxLengthToForty]} />
      <NumberInput style={inputStyle} label="y" source="markup_spread.y" validate={[required, validateMaxLengthToForty]} />
      <NumberInput style={inputStyle} label="z" source="markup_spread.z" validate={[required, validateMaxLengthToForty]} />
      <NumberInput
        source="factor"
        validate={[required, validateMaxLengthToForty, validateMinLengthToForty, validateisIntegerToForty]}
      />
      <SelectInput
        source="status"
        validate={[required]}
        choices={[
          { id: true, name: 'Active' },
          { id: false, name: 'Inactive' },
        ]}
      />
      <ReferenceInput source="proxy_id" reference="cqg_proxies" label="CQG Proxy" allowEmpty>
        <SelectInput optionText="name" optionValue="id" style={{ width: '50%', marginTop: '14px' }} />
      </ReferenceInput>
      <TextInput source="formula" />
      <TextInput source="reverse_formula" />
      {isSwap ? <NumberInput source="strategy_periods" label="Strategy Periods" validate={validateStrategyPeriods} defaultValue={0} step={1} /> : null}
      {isSwap ? null : <TextInput source="strike_formula" />}
      {isSwap ? null : <TextInput source="strike_reverse_formula" />}
      {isSwap ? <BooleanInput
        label="Is Spot Contract"
        source="is_spot"
        defaultValue={false}
      /> : null}
      {isSwap ? <BooleanInput
        label="Is TAS Contract"
        source="is_tas"
        defaultValue={false}
      /> : null}
    </SimpleForm>
  );
};
