import React, { Fragment, useRef, useState } from 'react';
import RotateIcon from 'material-ui/svg-icons/image/rotate-left'
import { FlatButton, RaisedButton, TextField } from 'material-ui';
import Dialog from 'material-ui/Dialog';
import { connect } from 'react-redux';
import {
  translate as adminTranslate,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
} from 'admin-on-rest';
import { compose } from 'redux';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import path from '../path';



const ResetButton = ({
  record,
  translate,
  showNotification,
  refreshView,
  basePath
}) => {
  const { agile_name: agileName, live_position: livePosition } = record;
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatedStartingPosition, setUpdatedStartingPosition] = useState(livePosition);
  const ref = useRef();
  const handleOpen = () => {
    setIsOpen(true);
    setUpdatedStartingPosition(livePosition);
  };

  const handleCancel = () => {
    setUpdatedStartingPosition(livePosition);
    setIsOpen(false);
    refreshView();
  }

  const handleSubmit = () => {
    if (updatedStartingPosition === null || updatedStartingPosition === undefined || updatedStartingPosition === '') {
      showNotification('Enter a valid Starting Position', 'warning')
    } else {
      setLoading(true);
      const newStartingPositionRecord = { sum_of_hedge: 0, sum_of_client: 0, starting_position: updatedStartingPosition };
      fetch(`${path}/admin${basePath}/${record.id}`, {
        method: 'PUT',
        body: JSON.stringify(newStartingPositionRecord),
        headers: {
          'Token': localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
        .then(() => {
          showNotification('Starting Position was updated');
          refreshView();
        })
        .catch((e) => {
          console.error(e);
          showNotification('Starting Position was not updated', 'warning')
        });
      setLoading(false)
      setIsOpen(!isOpen);
    }

  }

  const handleStartingPosition = (e) => {
    setUpdatedStartingPosition(e.target.value);
  }

  return (
    <Fragment>
      <FlatButton
        primary
        label={translate('livePositions.reset.buttonText')}
        icon={<RotateIcon/>}
        style={{ float: 'left', minWidth: '30px' }}
        onClick={handleOpen}
      />
      <Dialog
        title={translate('livePositions.dialog.title')}
        modal={false}
        open={isOpen}
        repositionOnUpdate={false}
        style={{ paddingTop: 0 }}
        bodyStyle={{overflowY: 'auto'}}
      >
        <TextField
          floatingLabelText={translate('livePositions.dialog.commodityLabel')}
          fullWidth
          name="agile_name"
          type="text"
          value={agileName}
          disabled
          style={{ marginBottom: '10px' }}
        />
        <TextField
          floatingLabelText={translate('livePositions.dialog.startingPositionLabel')}
          fullWidth
          required
          name="startingPosition"
          type="number"
          value={updatedStartingPosition}
          onChange={handleStartingPosition}
          style={{ marginBottom: '10px' }}
        />
        <RaisedButton
          label={translate('livePositions.dialog.buttonCancel')}
          onClick={handleCancel}
          icon={<CancelIcon />}
        />
        <RaisedButton
          type="submit"
          style={{ marginLeft: '10px' }}
          label={translate('livePositions.dialog.buttonConfirm')}
          primary={true}
          disabled={loading}
          icon={<SubmitIcon />}
          onClick={handleSubmit}
        />
      </Dialog>
    </Fragment>

  )};


const enhance = compose(
  adminTranslate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);
export default enhance(ResetButton);


// export default withRouter(ResetButton);
