import React from 'react';
import {
  Edit,
  translate,
} from 'admin-on-rest';

import { PricingTemplateStructuredProductsForm } from './pricingTemplateStructuredProductsForm';

const EditTitle = translate(({ record }) => <span>Edit <strong>{ record.name }</strong> SP Pricing Template</span>);

const PricingTemplateStructuredProductsEdit = props => (
  <Edit {...props} title={<EditTitle />}>
    <PricingTemplateStructuredProductsForm />
  </Edit>
);

export default PricingTemplateStructuredProductsEdit;
