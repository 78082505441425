import React from 'react';

import { SimpleForm, Create, ReferenceInput, SelectInput, required } from 'admin-on-rest';

import VolatilityConfigCreateToolbar from './VolatilityConfigCreateToolbar';

const VolatilityConfigCreate = props => (
  <Create {...props}>
    <SimpleForm
      toolbar={<VolatilityConfigCreateToolbar />}
    >
      <ReferenceInput
        allowEmpty
        label="Volatility Bucket"
        source="volatility_bucket_id"
        reference="volatility_bucket"
        filter={{ dropdown: true }}
        validate={[required]}
      >
        <SelectInput
          optionValue="id"
          optionText="name"
        />
      </ReferenceInput>
      <ReferenceInput
        allowEmpty
        label="Market Data"
        source="market_data_id"
        reference="market_data"
        filter={{ volatility_bucket: true, dropdown: true }}
        validate={[required]}
      >
        <SelectInput
          optionValue="id"
          optionText="bloomberg_ticker"
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default VolatilityConfigCreate;
