export default `
.display-checkboxes {
  padding: 5px 10px;
  width: 200px;
}

.display-checkboxes label{
  font-size: 10pt;
}

.display-checkboxes .btn-select {
  cursor: pointer;
  color: #8993a4;
  font: 400 11px system-ui;
  margin: 0px 4px;
  line-height: 2.2857142857142856em;
}

.display-checkboxes .list-checkboxes {
  position: relative;
  padding: 10px 0px;
}

.display-checkboxes .list-checkboxes .ntf-divider {
  background-color: #EBECF0;
  border-radius: 2px;
  top: 0;
  content: '';
  height: 2px;
  left: 0px;
  margin: 0;
  position: absolute;
  right: 0px;
  width: inherit;
}
`;
