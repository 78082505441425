
import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import { ToolbarGroup } from 'material-ui/Toolbar';
import { SelectInput, SimpleForm, TextInput, translate, showNotification as showNotificationAction } from 'admin-on-rest';
import style from './style';
import path from '../path';
import { ClientTable, NeonTable, CalculatorIcon } from './components';
import Seo from '../components/common/Seo';

class Calculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spreadsheet: [],
      commodities: [],
      req: {
        commodity_code: null,
        spreadsheet_id: null,
        cPrice: {
          ask: 0,
          bid: 0,
        },
        oPrice: {
          ask: 0,
          bid: 0,
        },
      },
      response: {},
    };
  }

  componentDidMount() {
    fetch(`${path}/admin/spreadsheets?_start=0&_end=10000&_sort=id&_order=DESC`, {
      method: 'GET',
      headers: {
        Token: localStorage.getItem('session'),
      },
    }).then(res => res.json())
      .then(res => this.setState({ ...this.state, spreadsheet: res }))
      .catch(error => console.error(error));

    fetch(`${path}/admin/commodities?_start=0&_end=10000&_sort=id&_order=DESC`, {
      method: 'GET',
      headers: {
        Token: localStorage.getItem('session'),
      },
    }).then(res => res.json())
      .then(res => this.setState({ ...this.state, commodities: res }))
      .catch(error => console.error(error));
  }

  onChangeSpreadsheet = (val) => {
    this.setState(state => ({
      ...state,
      req: {
        ...state.req,
        spreadsheet_id: val,
      },
      response: {},
    }));
  }

  onChangeCommodity = (val) => {
    const { commodities } = this.state;
    const commodity = commodities.filter(item => item.id === val);
    if (commodity && commodity.length > 0) {
      const { commodity_code } = commodity[0];
      this.setState(state => ({
        ...state,
        req: {
          ...state.req,
          commodity_code,
        },
        response: {},
      }));
    } else {
      this.setState(state => ({
        ...state,
        req: {
          ...state.req,
          commodity_code: null,
        },
        response: {},
      }));
    }
  }

  onCalculate = () => {
    const { showNotification } = this.props;
    fetch(`${path}/admin/spreadsheets/calculator`, {
      method: 'POST',
      body: JSON.stringify(this.state.req),
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    }).then(res => res.json())
      .then((res) => {
        if (res.message) {
          showNotification(res.message, 'warning');
        }
        return this.setState(state => ({
          ...state,
          response: {
            ...res,
          },
        }));
      })
      .catch((error) => {
        console.error('-->', error);
      });
  }

  onChangeAsk = (key, value) => {
    this.setState(state => ({
      ...state,
      req: {
        ...state.req,
        [key]: {
          ...state.req[key],
          ask: value,
        },
      },
      response: {},
    }));
  }

  onChangeBid = (key, value) => {
    this.setState(state => ({
      ...state,
      req: {
        ...state.req,
        [key]: {
          ...state.req[key],
          bid: value,
        },
      },
      response: {},
    }));
  }

  onFillAll = (e) => {
    this.setState(state => ({
      ...state,
      req: {
        ...state.req,
        cPrice: {
          ...state.req.cPrice,
          bid: e.target.value,
          ask: e.target.value,
        },
        oPrice: {
          ...state.req.oPrice,
          bid: e.target.value,
          ask: e.target.value,
        },
      },
      response: {},
    }));
  }

  render() {
    const { spreadsheet, commodities, req: { cPrice, oPrice, commodity_code: commodityCode }, response: { qty, spread, tick, clientPrice, orderPrice } } = this.state;
    const SpreadsheetNameField = ({ record }) => <span>{record.title}</span>;
    const CommoditiesNameField = ({ record }) => <span>{`${record.agile_name} / "${record.commodity_code}"`}</span>;
    const title = qty ? `QTY Limit: ${qty}; Spread: ${spread}: Tick: ${tick}` : '';
    return (
      <Card>
        <Seo title={'Calculator'} />
        <CardHeader className="calc_title" title="Spreadsheet calculator" />
        <SimpleForm
          toolbar={
            <ToolbarGroup
              style={{
                'boxSizing': 'border-box',
                '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
                'backgroundColor': 'rgb(232, 232, 232)',
                'height': '56px',
                'padding': '0px 24px',
                'display': 'flex',
                'justifyContent': 'space-between',
              }}
            >
              <FlatButton
                primary
                label="Calculate"
                className="save-btn"
                onClick={this.onCalculate}
                icon={<CalculatorIcon />}
                style={{
                  float: 'left',
                  backgroundColor: 'rgb(0, 188, 212)',
                  transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
                  boxSizing: 'border-box',
                  display: 'inline-block',
                  cursor: 'pointer',
                  textDecoration: 'none',
                  height: '36px',
                  lineHeight: '36px',
                  borderRadius: '2px',
                  textAlign: 'center',
                  minWidth: '88px',
                }}
              />
            </ToolbarGroup>
          }
        >
          <SelectInput input={{ onChange: this.onChangeSpreadsheet }} source="Spreadsheet" choices={spreadsheet} optionText={<SpreadsheetNameField />} />
          <SelectInput isRequired={true} value={commodityCode} input={{ onChange: this.onChangeCommodity }} source="Commodity" choices={commodities} optionText={<CommoditiesNameField />} />
          <TextInput
            source="fill-all"
            type="number"
            onChange={this.onFillAll}
          />
          <div source="result" className="result">
            <h3 className="title">{title}</h3>

            <div className="client">
              <CardText className="table-title">{'Neon -> Client'}</CardText>
              <ClientTable value={cPrice} onChangeAsk={this.onChangeAsk} onChangeBid={this.onChangeBid} prices={clientPrice} />
            </div>
            <div className="neon">
              <CardText className="table-title">{'Client -> Neon'}</CardText>
              <NeonTable value={oPrice} onChangeAsk={this.onChangeAsk} onChangeBid={this.onChangeBid} prices={orderPrice} />
            </div>
          </div>
        </SimpleForm>
        <style>{style}</style>
      </Card>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
  }),
);

export default enhance(Calculator);
