import React from 'react';
import RoleIcon from 'material-ui/svg-icons/action/accessibility';
import NotificationIcon from 'material-ui/svg-icons/action/announcement';
import OTCConfirmationIcon from 'material-ui/svg-icons/action/assignment';
import AccountsIcon from 'material-ui/svg-icons/action/assignment-ind';
import HistoriesIcon from 'material-ui/svg-icons/action/book';
import PDFHistoriesIcon from 'material-ui/svg-icons/action/bookmark';
import PricingsIcon from 'material-ui/svg-icons/action/chrome-reader-mode';
import MarginCallsIcon from 'material-ui/svg-icons/action/euro-symbol';
import InfoPageIcon from 'material-ui/svg-icons/action/info';
import RefreshDashboardIcon from 'material-ui/svg-icons/action/reorder';
import TeamsIcon from 'material-ui/svg-icons/action/supervisor-account';
import UploadSSIIcon from 'material-ui/svg-icons/action/toc';
import NeonManagementIcon from 'material-ui/svg-icons/action/touch-app';
import NeonOrdersIcon from 'material-ui/svg-icons/action/trending-up';
import HistoryIcon from 'material-ui/svg-icons/action/history';
import DashboardIcon from 'material-ui/svg-icons/action/view-module';
import DashboardRequestIcon from 'material-ui/svg-icons/action/view-quilt';
import MailIcon from 'material-ui/svg-icons/communication/email';
import CheckStatementsArchivesIcon from 'material-ui/svg-icons/content/archive';
import DatasetIcon from 'material-ui/svg-icons/file/cloud-upload';
import GroupsDashboardsIcon from 'material-ui/svg-icons/file/folder';
import ClientDatabaseIcon from 'material-ui/svg-icons/notification/folder-special';
import UsersIcon from 'material-ui/svg-icons/social/people';
import PinDropIcon from 'material-ui/svg-icons/maps/pin-drop';
import AdminsIcon from 'material-ui/svg-icons/social/people-outline';
import PricingTemplatesIcon from 'material-ui/svg-icons/action/tab';
import PricingCardsIcon from 'material-ui/svg-icons/action/picture-in-picture';
import FricingLogsIcon from 'material-ui/svg-icons/action/assignment-return';
import SocialGroup from 'material-ui/svg-icons/social/group';
import SettingsIcons from 'material-ui/svg-icons/action/settings';
import CustomPricingRequestIcon from 'material-ui/svg-icons/notification/sms';
import SubscriptionIcons from 'material-ui/svg-icons/action/settings-remote';
import ScheduleIcons from 'material-ui/svg-icons/action/schedule';
import { AccumulatorDescriptionIcon } from '../../accumulatorDescription';
import { CalculateHistoryIcon } from '../../calculateHistory';
import { CalculatorIcon } from '../../calculator/components';
import { MarketDataIcon } from '../../marketData';

const MENU = [
  { icon: <InfoPageIcon />, path: '/', title: 'Info Page' },
  {
    title: 'Pricings',
    icon: <PricingsIcon />,
    nested: [
      { icon: <DashboardRequestIcon />, path: '/files', title: 'Upload Files' },
      { icon: <CalculateHistoryIcon />, path: '/calculate_history', title: 'Calculate History' },
      { icon: <MarketDataIcon />, path: '/market_data', title: 'Market Data' },
      {
        title: 'CM Pricing Config',
        icon: <PricingsIcon />,
        nested: [
          { icon: <AccumulatorDescriptionIcon />, path: '/accumulator_description', title: 'Accumulator Description' },
          { icon: <MarketDataIcon />, path: '/accumulator_structures', title: 'Structures' },
          { icon: <MarketDataIcon />, path: '/accumulator_underlyings', title: 'Underlyings' },
          { icon: <MarketDataIcon />, path: '/accumulator_underlying_data', title: 'Underlying Data' },
        ],
      },
      { icon: <PricingTemplatesIcon />, path: '/pricing_templates', title: 'CM Pricing Templates' },
      { icon: <PricingCardsIcon />, path: '/pricing_cards', title: 'CM Pricing Cards' },
      { icon: <PricingCardsIcon />, path: '/pricing_card_view_templates', title: 'CM Pricing Card Customization' },
      { icon: <PricingCardsIcon />, path: '/pricing_mini_trials', title: 'CM Pricing Mini Trials' },
      {
        title: 'SP Pricing Config',
        icon: <PricingsIcon />,
        nested: [
          { icon: <MarketDataIcon />, path: '/eq_structures', title: 'Structures' },
          { icon: <MarketDataIcon />, path: '/eq_underlying_data', title: 'Underlying Data' },
          { icon: <MarketDataIcon />, path: '/eq_volatility_shift', title: 'Volatility Shift' },
          { icon: <MarketDataIcon />, path: '/eq_config', title: 'Config' },
        ],
      },
      {
        title: 'SP Screen Configurator',
        icon: <PricingsIcon />,
        nested: [
          { icon: <MarketDataIcon />, path: '/sp_inputs_config', title: 'Payoff Inputs Configurator' },
          { icon: <MarketDataIcon />, path: '/sp_layouts_config', title: 'Payoff Configurator' },
          { icon: <MarketDataIcon />, path: '/sp_wrapper_inputs_config', title: 'Wrapper Inputs Configurator' },
          { icon: <MarketDataIcon />, path: '/sp_wrappers_config', title: 'Wrappers Configurator' },
        ],
      },
      { icon: <PricingTemplatesIcon />, path: '/sp_pricing_template', title: 'SP Pricing Templates' },
      { icon: <HistoryIcon />, path: '/structured_products_pricing', title: 'SP Pricing' },
      { icon: <FricingLogsIcon />, path: '/pricing_logs/pricing_mini_log', title: 'Pricing Logs' },
    ],
  },
  {
    title: 'Dashboards',
    icon: <DashboardIcon />,
    nested: [
      { icon: <DashboardIcon />, path: '/dashboards', title: 'Dashboards' },
      { icon: <DashboardIcon />, path: '/statements', title: 'Statements' },
      { icon: <DashboardRequestIcon />, path: '/dashboard_requests', title: 'Dashboard requests' },
      { icon: <GroupsDashboardsIcon />, path: '/dashboard_groups', title: 'Dashboard Groups' },
      { icon: <MarginCallsIcon />, path: '/margin_calls', title: 'Margin calls' },
      { icon: <UploadSSIIcon />, path: '/ssi', title: 'SSI' },
    ],
  },
  {
    title: 'Histories',
    icon: <HistoriesIcon />,
    nested: [
      { title: 'PDFs', icon: <PDFHistoriesIcon />, path: '/pdf_histories' },
      { title: 'Statements', icon: <RefreshDashboardIcon />, path: '/statement_histories' },
      { icon: <MailIcon />, path: '/mails', title: 'Mails' },
      { icon: <CheckStatementsArchivesIcon />, path: '/check_statements_archives', title: 'Check statements archives' },
    ],
  },
  {
    title: 'Accounts',
    icon: <AccountsIcon />,
    nested: [
      { icon: <UsersIcon />, path: '/users', title: 'Users' },
      { icon: <SocialGroup />, path: '/user_groups', title: 'User groups' },
      { icon: <AdminsIcon />, path: '/admins', title: 'Admins' },
      { icon: <RoleIcon />, path: '/admin_roles', title: 'Admin Roles' },
      { icon: <TeamsIcon />, path: '/sales_teams', title: 'Sales Teams' },
      { icon: <TeamsIcon />, path: '/trade_teams', title: 'Trade Teams' },
      { icon: <RoleIcon />, path: '/roles', title: 'Roles' },
      { icon: <TeamsIcon />, path: '/cqg_accounts_to_sales', title: 'CQG Account to Sale' },
    ],
  },
  {
    title: 'Orders',
    icon: <NeonManagementIcon />,
    nested: [
      { icon: <NeonOrdersIcon />, path: '/neon_orders', title: 'Approval List' },
      { icon: <DatasetIcon />, path: '/swap_commodities', title: 'Swap contracts' },
      { icon: <HistoryIcon />, path: '/live_positions', title: 'Live Positions' },
      { icon: <DatasetIcon />, path: '/vanilla_commodities', title: 'Vanilla contracts' },
      { icon: <DatasetIcon />, path: '/fx_commodities', title: 'FX contracts' },
      { icon: <UploadSSIIcon />, path: '/spreadsheets', title: 'Spread Sheets' },
      { icon: <CalculatorIcon />, path: '/calculator', title: 'Calculator' },
    ],
  },
  { icon: <UploadSSIIcon />, path: '/minifuture_products', title: 'Minifuture Products' },
  { icon: <OTCConfirmationIcon />, path: '/otc_confirmation', title: 'OTC confirmations' },
  { icon: <ClientDatabaseIcon />, path: '/client_database', title: 'Client database' },
  { icon: <AccountsIcon />, path: '/customers', title: 'Customers' },
  { icon: <PinDropIcon />, path: '/locations', title: 'Locations' },
  { icon: <NotificationIcon />, path: '/notify_me', title: 'NotifyMe' },
  { icon: <DatasetIcon />, path: '/datasets', title: 'Datasets' },
  {
    title: 'Content',
    icon: <DashboardIcon />,
    nested: [
      { icon: <DashboardIcon />, path: '/email', title: 'Emails' },
      { icon: <DashboardIcon />, path: '/static_pages', title: 'Pages' },
    ],
  },
  {
    icon: <CustomPricingRequestIcon />,
    title: 'Themes',
    nested: [
      { icon: <CustomPricingRequestIcon />, path: '/themes', title: 'Themes Configurator' },
      { icon: <OTCConfirmationIcon />, path: '/theme_templates', title: 'Theme Templates' },
      { icon: <DashboardIcon />, path: '/theme_configs', title: 'Theme Configs' },
    ],
  },
  {
    title: 'System Settings',
    icon: <SettingsIcons />,
    nested: [
      // { icon: <PricingsIcon />, path: '/pricing_stress', title: 'Pricing Stress', },
      { icon: <SettingsIcons />, path: '/settings', title: 'Settings' },
      { icon: <NeonManagementIcon />, path: '/neon_management', title: 'Proxy logs' },
      { icon: <UsersIcon />, path: '/cqg_proxies', title: 'CQG Proxies' },
      { icon: <UsersIcon />, path: '/ice_accounts', title: 'ICE Accounts' },
      { icon: <UsersIcon />, path: '/gs_trading_accounts', title: 'GS Trading Accounts' },
      { icon: <UsersIcon />, path: '/sftp_accounts', title: 'Sftp Accounts' },
      { icon: <UsersIcon />, path: '/auth_client', title: 'Auth0 Accounts' },
      { icon: <DashboardIcon />, path: '/logs', title: 'Logs' },
      { icon: <ScheduleIcons />, path: '/cron', title: 'Jobs' },
      { icon: <DashboardIcon />, path: '/domains', title: 'Domain/Url' },
      { icon: <SubscriptionIcons />, path: '/sub', title: 'Subscription Monitoring' },
    ],
  },
];

export default MENU;
