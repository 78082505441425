import Moment from 'react-moment';
import { ListItem } from 'material-ui/List';
import DownloadIcon from 'material-ui/svg-icons/action/get-app';
import ReactJson from 'react-json-view';
import React from 'react';

const itemStyle = { display: 'flex', justifyContent: 'space-between' };

const PricingMiniJSON = (props) => {
  const { record } = props;
  return (
    <ListItem
      key={record.id}
      initiallyOpen={false}
      nestedItems={[
        <ReactJson
          key={`${record.id}_json`}
          src={record.zenai_price_payload}
          theme="monokai"
          name={false}
          collapsed={false}
          displayDataTypes={false}
        />,
      ]}
    >
      <a
        href={`data:text/json;charset=utf-8,${JSON.stringify(record.zenai_price_payload, undefined, 2)}`}
        download="Pricing_mini_file.json">
        <div style={{ float: 'left', marginRight: '20px' }}>
          <DownloadIcon color="#006064" style={{ width: '28px', height: '28px', margin: '-5px 0 0' }}/>
        </div>
      </a>
      <div style={itemStyle}>
        <Moment format="HH:mm DD MMMM YYYY">{record.created_at}</Moment>
      </div>
    </ListItem>
  );
};

export default PricingMiniJSON;
