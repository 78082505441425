import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { showNotification } from 'admin-on-rest';

import { RATES_DELETE_SUCCESS, RATES_DELETE_FAILURE } from './RatesAction';

export default function* reviewSaga() {
  yield [
    takeEvery(RATES_DELETE_SUCCESS, function* () {
      yield put(showNotification('rates.notification.delete_success'));
      yield put(push('/'));
      yield put(push('/rates'));
    }),
    takeEvery(RATES_DELETE_FAILURE, function* ({ error }) {
      yield put(showNotification('rates.notification.delete_error', 'warning'));
      console.error(error);
    }),
  ];
}
