import React from 'react';
import { Field } from 'redux-form';
import { SelectArrayInput, LongTextInput, Edit, SimpleForm, TextInput, required, minLength, maxLength, translate } from 'admin-on-rest';
import 'jsoneditor-react/es/editor.min.css';
import JsonEditor from '../helpers/JsonEditor';

const DashboardTitle = translate(({ record }) =>
  <span>{translate('structures.edit.title', { smart_count: 1 })} { record.name }</span>);

const validateMinLengthToTwo = minLength(2);
const validateMaxLengthToTwenty = maxLength(20);
const DashboardEdit = props => (
  <Edit {...props}
    title={<DashboardTitle />}
  >
    <SimpleForm>
      <LongTextInput
        source="description"
        validate={[required, validateMinLengthToTwo]}
      />
      <LongTextInput
        source="group_id"
        validate={[required, validateMinLengthToTwo]}
      />
      <LongTextInput
        source="report_id"
        validate={[required, validateMinLengthToTwo]}
      />
      <TextInput
        source="name"
        validate={[required, validateMinLengthToTwo, validateMaxLengthToTwenty]}
      />
      <SelectArrayInput
        source="pages"
      />
      <Field
        name="powerbi_filter"
        source="powerbi_filter"
        component={JsonEditor}
      />
    </SimpleForm>
  </Edit>
);

export default DashboardEdit;
