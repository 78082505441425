import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { translate, SimpleForm, Create, Toolbar, showNotification as showNotificationAction } from 'admin-on-rest';
import request from 'superagent';
import path from '../path';
import { SaveButton } from '../helpers';
import { CommonComponent } from './components';

const ThemesToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      redirect="list"
      submitOnEnter={true}
      label="Save"
      {...props}
    />
  </Toolbar>
);

class ThemesCreate extends CommonComponent {
  constructor(props) {
    super(props);
    this.translate = this.props.translate;
    this.state = this.defaultState;
  }

  async componentDidMount() {
    this._componentDidMount();
    const [parsedDef, pagesData] = await Promise.all([
      this.getConfig(),
      this.getPages(),
    ]);
    this.setState(state => ({
      ...state,
      pagesData,
      inputs: parsedDef,
      data: parsedDef.reduce((res, key) => {
        const objKey = Object.keys(key);
        /* eslint-disable */
        for (const el of key[objKey]) {
          res[objKey] = {...res[objKey], [el.name]: ''}
        }
        /* eslint-enable */
        return res;
      }, Object.assign({}, { title: '' })),
      load: false,
    }));
  }

  handleSubmit() {
    const { showNotification, history } = this.props;
    const getData = this.getData();
    if (!getData) return;
    const { data, files, title, copyright, pages = [], domainId = null } = getData;
    this.setState({ load: true }, () => {
      const theRequest = request
        .post(`${path}/admin/themes`)
        .set('token', localStorage.getItem('session'))
        .field('description', JSON.stringify(data))
        .field('name', title)
        .field('copyright', copyright)
        .field('pages', JSON.stringify(pages));

      if (domainId) {
        theRequest.field('domainId', domainId);
      }
      /* eslint-disable */
      for (const key of Object.keys(files)) {
        theRequest.attach(key, files[key][0]);
      }
      /* eslint-enable */
      theRequest.then(() => {
        this.setState({ load: false });
        showNotification('Theme was created');
        history.push('/themes');
      }).catch((e) => {
        console.error(e);
        this.setState({ load: false });
        showNotification('Something went wrong', 'warning');
      });
    });
  }

  render() {
    return (
      <Create {...this.props}>
        <SimpleForm
          toolbar={<ThemesToolbar onClick={this.handleSubmit.bind(this)} />}
        >
          {this.getContent()}
        </SimpleForm>
      </Create>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
  }),
);

export default enhance(translate(ThemesCreate));
