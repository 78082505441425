import React from 'react';
import {
  Edit,
  translate,
} from 'admin-on-rest';

import { FxCommodityForm } from './components';

const FxCommodityTitle = translate(({ record }) =>
  <span>Edit { record.agile_name }</span>);

const EditFxCommodity = props =>
  <Edit {...props} title={<FxCommodityTitle />}>
    <FxCommodityForm />
  </Edit>;

export default EditFxCommodity;
