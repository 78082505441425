import { Datagrid, FunctionField, List, NumberField, ReferenceField, refreshView, Responsive, ShowButton, SimpleList, TextField } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import TagButton from '../components/TagButton';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';
import CalculateHistoryFilter from './CalculateHistoryFilter';
import socket from 'ioClient';

class CalculateHistoryList extends React.Component {
  constructor(props) {
    super(props);

    this.socket = socket;
  }

  componentDidMount() {
    this.socket.on('pricing-history', () => {
      this.props.refreshView();
    });
  }

  render() {
    return (<List {...this.props}
      filters={<CalculateHistoryFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={50}
    >
      <Responsive
        small={
          <SimpleList
            primaryText={r => `${r.value} ${r.currency}`}
            secondaryText={r => `${r.strike_level} ${r.barrier_level}`}
            tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
          />
        }
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
            <DateField
              formatDate={formatDateTimeInput}
              source="created_at"
              showTime
            />
            <TextField
              source="structure"
            />
            <TextField
              source="type"
            />
            <TextField
              source="bloomberg_ticker"
            />
            <TextField
              source="commodity_contract"
            />
            <ReferenceField
              label="User"
              source="user_id"
              reference="users"
            >
              <FunctionField
                render={r => `${r.lastName} ${r.firstName} (${r.email})`}
              />
            </ReferenceField>
            <NumberField
              style={{ textAlign: 'left' }}
              source="reference_price"
            />
            <NumberField
              style={{ textAlign: 'left' }}
              label="Accumulation/Strike Level"
              source="strike_level"
            />
            <NumberField
              style={{ textAlign: 'left' }}
              source="barrier_level"
            />
            <NumberField
              style={{ textAlign: 'left' }}
              source="notional"
            />
            <NumberField
              style={{ textAlign: 'left' }}
              source="price"
            />
            <NumberField
              style={{ textAlign: 'left' }}
              source="price_to_display"
            />
            <TextField
              source="quote_units"
            />
            <TextField
              source="quoted_currency"
            />
            <TagButton
              label="Tag" getPrimaryText={({ record }) => record.tag}
            />
            <ShowButton />
          </Datagrid>
        }
      />
    </List>);
  }
}

export default connect(null, {
  refreshView,
})(CalculateHistoryList);
