import React, { Component } from 'react';
import TextField from 'material-ui/TextField';

class OrderTab extends Component {
  render() {
    const { order } = this.props;
    const remainingQuantity = `${parseFloat((order.quantity - order.filled_quantity).toFixed(4)) || 0} / ${parseFloat((order.hedge_quantity - order.filled_quantity).toFixed(4)) || 0}`;
    return (
      <div className="order-tab-grid">
        <TextField className="commodity_contract" floatingLabelText='Contract' title='Contract' name='commodity_contract' type="text" value={order.commodity_contract} fullWidth disabled />
        <TextField className="contract_code" floatingLabelText='Contract Code' title='Contract Code' name='contract_code' type="text" value={order.contract_code} fullWidth disabled />
        <TextField className="filled_quantity" floatingLabelText='Filled' title='Filled' name='filled_quantity' type="text" value={order.filled_quantity} fullWidth disabled />
        <TextField className="quantity" floatingLabelText='Client/Hedge QTY' title='Client/Hedge QTY' name='quantity' type="text" value={`${order.quantity || 0} / ${order.hedge_quantity || 0}`} fullWidth disabled />
        <TextField className="remaining_quantity" floatingLabelText='Client/Hedge Remaining' title='Client/Hedge Remaining' name='remaining_quantity' type="text" value={remainingQuantity} fullWidth disabled />
        <TextField className="direction" floatingLabelText='Direction' title='Direction' name='direction' type="text" value={order.direction} fullWidth disabled />
        <TextField className="price" floatingLabelText='Requested Client/Price' title='Requested Client/Price' name='price' type="text" value={`${order.price || 0} / ${order.neon_price || 0}`} fullWidth disabled />
        <TextField className="avg_price" floatingLabelText='Avg/Client Price' title='Avg/Client Price' name='avg_price' type="text" value={`${parseFloat(order.avg_neon_price) || '-'} / ${parseFloat(order.avg_price) || '-'}`} fullWidth disabled />
        {(order.structure === 'vanilla') ? <TextField className="structure_type" floatingLabelText='Type' title='Type' name='structure_type' type="text" value={order.structure_type} fullWidth disabled /> : null}
        {(order.structure === 'vanilla') ? <TextField className="strike_level" floatingLabelText='Strike/ AСС Level' title='Strike/ AСС Level' name='strike_level' type="text" value={order.strike_level} fullWidth disabled /> : null}
      </div>
    );
  }
}

export default OrderTab;
