import React from 'react';
import { ChipField } from 'admin-on-rest';

const MultiplyChips = props => (
  <div>
    {
      Array.isArray(props.record[props.source]) && props.record[props.source].map((chip, index) => {
        const rec = {
          record: {
            [props.source]: props.name ? chip.name : chip,
          },
          source: props.source,
        };
        return <ChipField {...rec} key={index} />;
      })
    }
  </div>
);

export default MultiplyChips;
