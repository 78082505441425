import { DateInput, Filter, NumberInput, SelectInput, TextInput } from 'admin-on-rest';
import React from 'react';
import { formatDateInput } from '../utils/dates';


const AccumulatorDescriptionFilter = props => (
  <Filter {...props}>
    <TextInput
      label="accumulatorDescription.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="product_category"
    />
    <TextInput
      source="structure"
    />
    <TextInput
      source="structure_code"
    />
    <TextInput
      source="type"
    />
    <TextInput
      source="product"
    />
    <TextInput
      source="product_description"
    />
    <TextInput
      source="barrier_type"
    />
    <TextInput
      source="leverage_style"
    />
    <NumberInput
      source="leverage"
    />
    <SelectInput
      source="skip_for_web"
      choices={[
        { id: 'true', name: 'Yes' },
        { id: 'false', name: 'No' },
      ]}
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
    />
  </Filter>
);

export default AccumulatorDescriptionFilter;
