import React, { DragEventHandler, DragEvent, useState } from 'react';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import ReorderIcon from 'material-ui/svg-icons/action/reorder';
import {
  refreshView as refreshViewAction,
  showNotification as showNotificationAction,
  translate as adminTranslate,
  GET_LIST,
} from 'admin-on-rest';
import request from 'superagent';
import './SpOrderingItems.css';
import restClient from '../../../restClient';
import path from '../../../path';
import { SpCommonDispatchProps } from '../types';
import { ComponentEnhancer } from 'recompose';

interface SpOrderingItemsOwnProps {
  apiSource: string;
  fieldName: string;
}

type SpOrderingItemsProps = SpOrderingItemsOwnProps & SpCommonDispatchProps;
interface Item {
  id: number;
}
const SpOrderingItems: React.FC<SpOrderingItemsProps> = ({
  translate,
  showNotification,
  refreshView,
  apiSource,
  fieldName,
}) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const translationsPrefix = 'configurableSp.ordering';
  const [draggedItem, setDraggedItem] = useState<Item | undefined>();
  const [items, setItems] = useState<Item[]>([]);

  const handleDragStart = (e: DragEvent, item: Item) => {
    setDraggedItem(item);
  };

  const handleDragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent, index: number) => {
    e.preventDefault();
    const newItems = [...items];
    const draggedItemIndex = items.findIndex((item) => item.id === draggedItem?.id);
    newItems.splice(draggedItemIndex, 1);
    // @ts-ignore draggedItem is always defined at this stage
    newItems.splice(index, 0, draggedItem);
    setItems(newItems);
    setDraggedItem(undefined);
  };

  const getData = async () =>
    restClient(GET_LIST, apiSource, {
      sort: {
        field: 'ordering_index',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 1e9,
      },
    });

  const handleOpen = async () => {
    const { data } = await getData();
    setItems(data.map((i) => ({ id: i.id, [fieldName]: i[fieldName] })));
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const orderingItems = items.map((i) => ({ ...i, orderingIndex: items.indexOf(i) + 1 }));
    setSubmitting(true);

    request
      .put(`${path}/admin/${apiSource}/updateOrdering`)
      .set('Token', localStorage.getItem('session'))
      .send({ orderingItems })
      .then(() => {
        showNotification(`${translationsPrefix}.successful`);
        refreshView();
        setOpen(false);
      })
      .catch((e) => {
        console.error(e);
        showNotification(translate(`${translationsPrefix}.error.${e.response.body.message || 'generic'}`, 'warning'));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="main-container">
      <FlatButton label={translate(`${translationsPrefix}.buttonText`)} icon={<ReorderIcon />} onClick={handleOpen} />
      <Dialog
        title={translate(`${translationsPrefix}.title`)}
        modal={false}
        open={open}
        repositionOnUpdate={false}
        className="dialog"
        bodyStyle={{ overflowY: 'auto' }}
      >
        <div>
          {items.map((item, index) => (
            <div
              key={item.id}
              draggable
              onDragStart={(e) => handleDragStart(e, item)}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, index)}
              className="item"
              style={{
                backgroundColor: draggedItem && draggedItem.id === item.id ? 'lightblue' : 'white',
              }}
            >
              <div className="reorder-icon-container">
                <ReorderIcon />
              </div>
              <div className="item-name">{item[fieldName]}</div>
            </div>
          ))}
        </div>
        <div className="buttons-container">
          <RaisedButton
            label={translate('configurableSp.dialog.btnClose')}
            onClick={closeDialog}
            icon={<CancelIcon />}
          />
          <RaisedButton
            type="submit"
            className="submit"
            label={translate('configurableSp.dialog.btnSave')}
            primary={true}
            disabled={submitting}
            icon={<SubmitIcon />}
            onClick={handleSave}
          />
        </div>
      </Dialog>
    </div>
  );
};

const enhance: ComponentEnhancer<SpOrderingItemsProps, SpOrderingItemsOwnProps> = compose(
  adminTranslate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(SpOrderingItems);
