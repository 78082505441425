
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { Responsive
  , RichTextField
  , Datagrid
  , DeleteButton
  , refreshView
  , List
  , ReferenceField
  , FunctionField
  , EditButton
  , BooleanField } from 'admin-on-rest';

import PricingCardFileUploadButton from './PricingCardFileUpload';
import { DownloadJson } from './components';
import { DateField } from '../helpers';
import RemoveFileButton from '../components/RemoveFileButton';


const ButtonsWrapper = props => {
  const record = { ...props.record, card_json: null, file_name: null, slack_workflow_type: 'without_booking' };
  const recordPath = 'admin/pricing_cards';

  return (
    <div style={{ width: '550px' }}>
      <EditButton {...props}>Edit</EditButton>
      <PricingCardFileUploadButton {...props} />
      <RemoveFileButton record={record} recordPath={recordPath} />
      <DeleteButton {...props} />
    </div>
  );
};

class PricingCardsList extends React.Component {
  render() {
    return (<List {...this.props}
      sort={{ field: 'created_at', order: 'DESC' }}
      title="CM Pricing Cards"
      perPage={50}
    >
      <Responsive
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }}>

            <RichTextField
              source="description"
            />
            <RichTextField
              source="title"
            />
            <ReferenceField label="Structure" source="structure_code" reference="accumulator_structures" linkType="show">
              <FunctionField
                render={r => r.structure}
              />
            </ReferenceField>
            <BooleanField
              source="file_upload_status"
            />
            <RichTextField
              source="slack_workflow_type"
            />
            <DownloadJson />
            <DateField
              formatDate={date => moment(date).format('DD MMMM YYYY HH:mm')}
              source="updated_at"
              showTime
            />
            <DateField
              formatDate={date => moment(date).format('DD MMMM YYYY HH:mm')}
              source="created_at"
              showTime
            />
            <ButtonsWrapper />
          </Datagrid>
        }
      />
    </List>);
  }
}

export default connect(null, {
  refreshView,
})(PricingCardsList);
