/* eslint-disable */
import React from 'react';
import { FormField, TextInput, NumberInput } from 'admin-on-rest';
import { TableRowColumn } from 'material-ui/Table';
import { formValueSelector } from 'redux-form';

export const RECORD_FORM = 'record-form';
export const REDUX_FORM_CHANGE_ACTION = '@@redux-form/CHANGE';
export const REDUX_FORM_UNREGISTER_ACTION = '@@redux-form/UNREGISTER_FIELD';
export const NOTIONAL_FIELD_ARRAY = 'notional';
export const FIELD_NOTIONAL_USD = 'notionalUsd';

export const selector = formValueSelector(RECORD_FORM);

export const DIRECTION = {
  LESS: '<',
  GREATER_OR_EQUAL: '>=',
};

export const roundMargin = v => v && _.round(v, 4);

export const INPUT_PROPS = {
  inputStyle: { color: '#000' },
  underlineStyle: { display: 'none' },
};

export const notionalValidation = (value, { notional }) => {
  if (value < 0) {
    return 'Must be greater or equals 0';
  }

  const uniq = notional.filter(({ notionalUsd, direction }) => (notionalUsd === value && direction === DIRECTION.LESS));
  if (uniq && uniq.length > 1) {
    return 'Must be unique value';
  }
  return undefined;
};

export const TableInput = ({ rowStyle = {}, placeholder, disabled = false, inputOptions = {}, type, ...rest }) => {
  const input = React.createElement(
    type === 'number' ? NumberInput : TextInput,
    {
      ...rest,
      addLabel: false,
      options: {
        disabled,
        placeholder,
        floatingLabelText: null,
        ...inputOptions,
      },
    },
  );
  return (
    <TableRowColumn style={{
      overflow: 'none',
      ...rowStyle,
    }}>
      <FormField input={input} />
    </TableRowColumn>
  );
};
