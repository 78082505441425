import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { OTCCancelAction } from './OTCSendAction';
import OTCCancelIcon from './OTCCancelIcon';
import './OTCCancelButton.css';

const OTCCancelButton = ({ record: { status, envelope_id: evenelopeId } = {}, cancelAction }) => {
  const className = evenelopeId && !['voided', 'canceled', 'completed'].includes(status)
    ? 'otc-cancel-button'
    : 'otc-cancel-button-disabled';

  const handleOnCancel = (event) => {
    event.preventDefault();
    cancelAction(evenelopeId);
  };

  return (
    <div className={className}>
      <a onClick={handleOnCancel} href='#' style={{ display: 'block' }}>
        <OTCCancelIcon color="#006064" style={{ width: '28px', height: '28px', margin: '5px 0 0' }} />
      </a>
    </div>
  );
};

OTCCancelButton.propTypes = {
  record: PropTypes.object,
  cancelAction: PropTypes.func,
};

const enhance = compose(
  connect(null, {
    cancelAction: OTCCancelAction,
  }),
);

export default enhance(OTCCancelButton);
