import React from 'react';
import { Edit, SimpleForm, TextInput, TextField } from 'admin-on-rest';

const verify = (value, allValues, props) =>
  (allValues._password === value ? undefined : props.translate('admins.validate.verify'));

const password = (value, allValues, props) =>
  (/^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[A-Z])(?=.*[~<>,./?|!@#$%^&*`()\-_+={}[\];:]))|((?=.*[a-z])(?=.*[0-9])(?=.*[~<>,./?|!@#$%^&*`()\-_+={}[\];:]))|((?=.*[A-Z])(?=.*[0-9])(?=.*[~<>,./?|!@#$%^&*`()\-_+={}[\];:]))).{0,}$/.test(value) || !value ? undefined : props.translate('admins.validate.password'));


const AdminChangePassword = (props) => (
  <Edit {...props}>
    <SimpleForm
      redirect={'/admins'}
    >
      <TextField source="email" />
      <TextInput
        source="_password"
        type="password"
        validate={[password]}
      />
      <TextInput
        source="_verify"
        type="password"
        validate={[password, verify]}
      />
    </SimpleForm>
  </Edit>
);

export default AdminChangePassword;
