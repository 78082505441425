import React from 'react';

import { Edit, SimpleForm, TextInput, required, maxLength, translate } from 'admin-on-rest';

const StatementTitle = translate(({ record }) =>
  <span>{translate('structures.edit.title', { smart_count: 1 })} { record.name }</span>);

const validateMaxLengthToTwenty = maxLength(20);
const StatementEdit = props => (
  <Edit {...props}
    title={<StatementTitle />}
  >
    <SimpleForm>
      <TextInput
        source="client_name"
        validate={[required, validateMaxLengthToTwenty]}
      />
      <TextInput
        source="reference_number"
        validate={[required, validateMaxLengthToTwenty]}
      />
    </SimpleForm>
  </Edit>
);

export default StatementEdit;
