import React from 'react';

import {
  ReferenceArrayInput,
  Edit,
  SimpleForm,
  ListButton,
  RefreshButton,
  TextInput,
  required,
  email,
  minLength,
  maxLength,
  translate,
  ReferenceInput,
  SelectInput,
} from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import SelectArrayInput from '../helpers/SelectArrayInput';
import ChangePasswordBtn from './../adminChangePassword/ChangePasswordBtn';

const AdminTitle = translate(({ record }) =>
  <span>{translate('admins.edit.title', { smart_count: 1 })} { record.email }</span>);

const validateMinLengthToTwo = minLength(2);
const validateMaxLengthToHundred = maxLength(100);

const EditActions = ({ basePath, data, refresh }) => (
  <CardActions
    className="CardActions" >
    <ChangePasswordBtn record={data}/>
    <ListButton
      basePath={basePath}
    />
    <RefreshButton
      refresh={refresh}
    />
  </CardActions>
);

const AdminEdit = props => (
  <Edit {...props}
    title={<AdminTitle />}
    actions={<EditActions />}
  >
    <SimpleForm>
      <TextInput
        source="email"
        type="email"
        validate={[required, email]}
      />
      <TextInput
        source="first_name"
        validate={[required, validateMinLengthToTwo, validateMaxLengthToHundred]}
      />
      <TextInput
        source="last_name"
        validate={[required, validateMinLengthToTwo, validateMaxLengthToHundred]}
      />
      <ReferenceArrayInput
        source="notification_setting_ids"
        reference="admin_notification_settings"
        label="Notification Setting"
        allowEmpty
      >
        <SelectArrayInput
          optionText="name"
          optionValue="id"
          style={{ width: '50%', marginTop: '14px' }}
        />
      </ReferenceArrayInput>
      <TextInput
        label = "Slack ID"
        source="slack_id"
        type="text"
      />
      <ReferenceInput
        label="Admin Role"
        source="role_id"
        reference="admin_roles"
        perPage="1e9"
      >
        <SelectInput
          optionValue="id"
          optionText="title"
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default AdminEdit;
