
import React from 'react';
import { SelectField, MenuItem, TextField } from 'material-ui';
import { NavigationClose } from 'material-ui/svg-icons';
import { AddUnderlying, UnderlyingGroupList } from './';

const getRoundingRuleErrorText = (errRoundingRule, roundingRule, defaultErrorText) => {
  if (errRoundingRule) {
    return roundingRule === '0' ? 'Rounding rule must be greater than 0' : defaultErrorText;
  }
};

const UnderlyingList = ({
  translate,
  translationsPrefix,
  underlyings,
  index: i,
  underlyingsWithCardsCopy,
  pricingCards,
  handleCloseBox,
  handleSelectUnderlyingChange,
  handleChangeRule,
  handleChangeGroup,
  handleCloseGroup,
  errUnderlying,
  errPricingCards,
  errRoundingRule,
  errGroups,
  chips,
  handleAddGroup,
}) => {
  const indexLastGroup = underlyingsWithCardsCopy[i].pricingGroups.length - 1;
  const hasPricingCards = underlyingsWithCardsCopy[i].pricingGroups[indexLastGroup].pricingCards.length === 0;
  const roundingRule = underlyingsWithCardsCopy[i].roundingRule;

  return (
    <div className="markup-item">
      <NavigationClose
        className="close-marckup"
        onClick={() => handleCloseBox(i)}
      />

      <SelectField
        fullWidth
        floatingLabelText={translate(`${translationsPrefix}.underlying`)}
        name={`underlyingsWithCardsGroup_${i}`}
        value={underlyingsWithCardsCopy[i].underlying}
        errorText={errUnderlying && translate('aor.validation.required')}
        onChange={handleSelectUnderlyingChange}
      >
        {underlyings.map(x => <MenuItem key={`k_${x.id}`} value={x.id} primaryText={x.commodity_contract} />)}
      </SelectField>

      <TextField
        errorText={getRoundingRuleErrorText(errRoundingRule, roundingRule, translate('aor.validation.required'))}
        floatingLabelText={'Rounding rule'}
        fullWidth
        name="rounding_rule"
        type="string"
        value={roundingRule}
        onChange={handleChangeRule}
        maxLength="5000"
        min={1}
      />

      {underlyingsWithCardsCopy[i].pricingGroups.map((el, j) =>
        <UnderlyingGroupList
          handleCloseGroup={handleCloseGroup}
          index={j}
          key={j}
          translate={translate}
          translationsPrefix={translationsPrefix}
          group={el}
          handleChangeGroup={handleChangeGroup(j)}
          pricingCards={pricingCards}
          errPricingCards={errPricingCards[j] || false}
          errGroups={errGroups[j] || false}
          chips={chips}
          disabled={!underlyingsWithCardsCopy[i].underlying}
          indexLastGroup={indexLastGroup}
        />)}

      <AddUnderlying onClick={handleAddGroup(indexLastGroup)} text={'Add Group'}
        disabled={hasPricingCards}/>
    </div>);
}

export default UnderlyingList;
