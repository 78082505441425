import { MenuItemLink } from 'admin-on-rest';
import { ListItem } from 'material-ui/List';
import { withRouter } from 'react-router-dom';
import DashboardIcon from 'material-ui/svg-icons/action/view-module';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import AgileMenuNested from './AgileMenuNested';

const handleClick = (onMenuTap, hasLongPath, path, history) => {
  if (hasLongPath) history.push({ pathname: `/${path}` });
  return onMenuTap;
};

const isOpen = (nested, pathname) => {
  const active = _.find(nested, ({ path }) => pathname.includes(path));
  return !!active;
};

const AgileMenuItem = (props) => {
  const { title, path, icon, nested, onMenuTap, hasLongPath, history, location: { pathname }, nestedLevel } = props;
  if (nested && nested.length) {
    const filterNested = nested.filter(item => !!item);
    const nestedItems = AgileMenuNested({ nested: filterNested, onMenuTap, nestedLevel });
    return (
      <ListItem
        initiallyOpen={isOpen(filterNested, pathname)}
        primaryText={title}
        leftIcon={icon}
        primaryTogglesNestedList={true}
        nestedItems={nestedItems}
        nestedLevel={nestedLevel}
        className='menu-cat-link'
      />
    );
  }

  const className = ['menu-link'];
  if (
    (pathname.split('/')[1].includes(path.split('/')[1]) && path !== '/')
    || (path === '/' && pathname === path)
  ) {
    className.push('focused');
  }

  return title ?
    <MenuItemLink
      key={path}
      nestedLevel={ nestedLevel }
      leftIcon={icon}
      to={path}
      primaryText={title}
      onClick={ handleClick.bind(this, onMenuTap, hasLongPath, path, history) }
      className={className.join(' ')}
    />
    :
    null;
};

AgileMenuItem.defaultProps = {
  icon: <DashboardIcon />,
  nestedLevel: 0,
};

AgileMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  icon: PropTypes.node,
  nested: PropTypes.array,
  onMenuTap: PropTypes.func.isRequired,
};

export default withRouter(AgileMenuItem);
