export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATIONC';
export const toggleNotification = () => ({
  type: TOGGLE_NOTIFICATION,
});

export const NC_WS_CONNECTED = 'NC_WS_CONNECTED';
export const wsOnConnectedAction = () => ({
  type: NC_WS_CONNECTED,
});

export const NC_WS_DISCONNECTED = 'NC_WS_DISCONNECTED';
export const wsOnDisconnectedAction = () => ({
  type: NC_WS_DISCONNECTED,
});

export const NC_COUNT_FETCH = 'NC_COUNT_FETCH';
export const fetchCountAction = () => ({
  type: NC_COUNT_FETCH,
});

export const NC_COUNT_UPDATE = 'NC_COUNT_UPDATE';
export const countUpdateAction = (payload = 0) => ({
  type: NC_COUNT_UPDATE,
  payload,
});

export const NC_EVENT = 'NC_EVENT';
export const changeMessageAction = (action, data) => ({
  type: NC_EVENT,
  payload: { action, data },
});

export const NC_READ = 'NC_READ';
export const readNotificationAction = (payload = {}) => ({
  type: NC_READ,
  payload,
});

export const NC_DELETE = 'NC_DELETE';
export const deleteNotificationAction = (id) => ({
  type: NC_DELETE,
  payload: id,
});

export const NC_ALL_ACTION = 'NC_ALL_ACTION';
export const allMeaasgeAction = (action) => ({
  type: NC_ALL_ACTION,
  payload: action,
});
