import React from 'react';

const DisplayText = ({ ...props }) => {
  const text = props.getText(props);
  return (
    <div> { text } </div>
  );
};

export default DisplayText;
