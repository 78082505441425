import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { translate, GET_ONE, SimpleForm, showNotification as showNotificationAction, Toolbar, ListButton } from 'admin-on-rest';
import { Card, CardHeader, CardActions } from 'material-ui/Card';
import request from 'superagent';
import restClient from '../restClient';

import path from '../path';
import { SaveButton } from '../helpers';
import { CommonComponent } from './components';

const ThemesToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      redirect="list"
      submitOnEnter={true}
      label="Save"
      {...props}
    />
  </Toolbar>
);

const ThemesEditActions = () => (
  <CardActions
    className="CardActions"
  >
    <ListButton
      basePath="/themes"
    />
  </CardActions>
);

class ThemesEdit extends CommonComponent {
  constructor(props) {
    super(props);
    this.translate = this.props.translate;
    this.state = this.defaultState;
  }

  async componentDidMount() {
    this._componentDidMount(this.props.match.params.id);
    const { data } = await restClient(GET_ONE, 'themes', { id: this.props.match.params.id });
    const { name, description, pages, domainId, ...fields } = data;
    const [parsedDef, pagesData] = await Promise.all([
      this.getConfig(),
      this.getPages(),
    ]);
    const parsedData = parsedDef.reduce((res, key) => {
      const objKey = Object.keys(key);
      /* eslint-disable */
      for (const el of key[objKey]) {
        res[objKey] = {
          ...res[objKey],
          [el.name]: description[objKey]
          ? (typeof description[objKey][el.name] === 'object')
            ? description[objKey][el.name]
            : description[objKey][el.name]
            || ''
          : ''
      }
      }
      /* eslint-enable */
      return res;
    }, Object.assign({}, { title: name, ...fields, domainId }));
    const { domainId: initDomainId } = parsedData;
    this.setState({
      pagesData,
      pages: pages || [],
      headerTitle: name,
      inputs: parsedDef,
      data: parsedData,
      load: false,
      searchText: initDomainId ? this.state.domains.filter(i => i.id === initDomainId)[0].name : '',
    });
  }

  handleSubmit() {
    const { showNotification, history, match } = this.props;
    const getData = this.getData();
    if (!getData) return;
    const { data, files, title, copyright, pages, domainId = null } = getData;
    this.setState({ load: true }, () => {
      const theRequest = request
        .put(`${path}/admin/themes/${match.params.id}`)
        .set('token', localStorage.getItem('session'))
        .field('description', JSON.stringify(data))
        .field('name', title)
        .field('copyright', copyright)
        .field('pages', JSON.stringify(pages));

      if (domainId) {
        theRequest.field('domainId', domainId);
      }

      /* eslint-disable */
      for (const key of Object.keys(files)) {
        theRequest.attach(key, files[key][0]);
      }
      /* eslint-enable */
      theRequest.then(() => {
        this.setState({ load: false });
        showNotification('Theme was updated');
        history.push('/themes');
      }).catch((e) => {
        console.error(e);
        this.setState({ load: false });
        showNotification('Something went wrong', 'warning');
      });
    });
  }

  render() {
    const { headerTitle } = this.state;
    return (
      <Card>
        <ThemesEditActions />
        <CardHeader titleStyle={{ fontSize: '24px' }} style={{ lineHeight: '36px' }} title={headerTitle} />
        <SimpleForm
          toolbar={<ThemesToolbar onClick={this.handleSubmit.bind(this)} translate={this.translate}/>}
        >
          {this.getContent()}
        </SimpleForm>
      </Card>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
  }),
);

export default enhance(translate(ThemesEdit));
