import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import Dialog from 'material-ui/Dialog';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import RaisedButton from 'material-ui/RaisedButton';
import AutoComplete from 'material-ui/AutoComplete';
import TextField from 'material-ui/TextField';

import { connect } from 'react-redux';
import { translate, GET_LIST, GET_ONE, showNotification as showNotificationAction, refreshView as refreshViewAction } from 'admin-on-rest';

import StatementCreateAction from './StatementCreateAction';

import restClient from '../restClient';
import path from '../path';

import { removeSpecialChars } from '../helpers/utilities';


class StatementCreate extends React.Component {
  defaultState = {
    open: false,
    clientName: '',
    clientRef: '',
    errors: {
      clientName: false,
      clientRef: false,
    },
    clients: [],
  }

  constructor(props) {
    super(props);
    this.translate = this.props.translate;
    this.state = this.defaultState;
  }

  handleOpen() {
    this.setState({ open: true });

    restClient(GET_LIST, 'statements/free_reference_numbers', {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((res) => {
      this.setState({ clients: res.data });
    });
  }

  clientSelected = (client) => {
    restClient(GET_ONE, 'client_database', {
      id: client.id,
    }).then((res) => {
      const {
        client: clientName,
        client_code,
      } = res.data;

      this.setState({
        clientName,
        clientRef: client_code,
        clientId: client.id,
        errors: {
          clientRef: false,
        },
      });
    });
  }

  handleClientInputChange = (value) => {
    if (value.length) return;
    this.setState({
      clientRef: value,
      clients: this.state.clients,
      open: true,
    });
  }

  handleInputChange = inputName => (...event) => this.setState({
    [inputName]: removeSpecialChars(event[1]),
    errors: {
      ...this.state.errors,
      [inputName]: false,
    },
  });

  handleClose() {
    this.setState({ ...this.defaultState, open: false });
  }

  handleSubmit() {
    const { clientName, clientRef } = this.state;
    const { showNotification, refreshView } = this.props;
    if (!clientRef.trim().length) {
      this.setState({
        ...this.state,
        errors: {
          clientRef: true,
        },
      });
      return;
    }

    const session = localStorage.getItem('session');
    fetch(`${path}/admin/statements`, { method: 'POST',
      body: JSON.stringify({ 'client_name': clientName, 'reference_number': clientRef }),
      headers: {
        'Token': session,
        'Content-Type': 'application/json; charset=utf-8',
      } })
      .then(response => response.json())
      .then((data) => {
        if (!data.error) {
          showNotification('Statement was created');
          this.setState({
            ...this.defaultState,
            open: false,
          });
          refreshView();
        } else {
          showNotification(data.error, 'warning');
        }
      })
      .catch(() => {
        showNotification('Something went wrong', 'warning');
      });
  }


  render() {
    const { open, clients } = this.state;

    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label='Add Statement'
          onClick={this.handleOpen.bind(this)}
        />
        <Dialog
          title='Add Statement'
          modal={false}
          open={open}
          autoScrollBodyContent={true}
        >
          <AutoComplete
            floatingLabelText="Select client by ref number"
            filter={AutoComplete.fuzzyFilter}
            dataSource={clients}
            dataSourceConfig={{ text: 'client_code', value: 'id' }}
            onNewRequest={this.clientSelected}
            onUpdateInput={this.handleClientInputChange}
            openOnFocus
            fullWidth
            errorText={this.state.errors.clientRef && this.translate('aor.validation.required')}
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <TextField
            errorText={this.state.errors.clientName && this.translate('aor.validation.required')}
            type="text"
            value={this.state.clientName}
            onChange={this.handleInputChange('clientName')}
            floatingLabelText="Client Name"
            fullWidth
            disabled
          />
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              label={this.translate('pdfs.dialog.btnCancel')}
              onClick={this.handleClose.bind(this)}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label="Add Statement"
              primary={true}
              onClick={this.handleSubmit.bind(this)}
              icon={<SubmitIcon />}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

StatementCreate.propTypes = {
  createAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    createAction: StatementCreateAction,
    refreshView: refreshViewAction,
    showNotification: showNotificationAction,
  }),
);

export default enhance(StatementCreate);
