import {
  BooleanField,
  refreshView as refreshViewAction,
  showNotification as showNotificationAction,
  TextField,
  translate,
} from 'admin-on-rest';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Datagrid, DateField, DisplayText } from '../../helpers';
import { datagridDefaultStyles } from '../../theme/styles';
import { formatDateTimeInput } from '../../utils/dates';

const Columns = (props) => {
  const { columns } = props;

  const getText = (key, record) => {
    switch (key) {
      case 'underlyings': {
        if (typeof record[key] === 'string' && record[key].length) {
        return record[key].split(', ').map((item, i) => <p key={i}>{item}</p>);
        }
        return record[key];
      }
    default: return record[key];
    }
  };

  const renderElement = (key) => {
    const { columns } = props;
    const { status, label, type } = columns[key];
    if (!status) return '';
    switch (type) {
      case 'DisplayText':
      return <DisplayText key={key} label={label} getText={({ record }) => getText(key, record)} />;
      case 'DateField':
      return <DateField formatDate={formatDateTimeInput} source={key} label={label} showTime />;
      case 'BooleanField':
        return <BooleanField key={key} source={key} label={label} />;
      default:
        return <TextField key={key} source={key} label={label} />;
    }
  };

  return (
    <Datagrid
      bodyOptions={{ showRowHover: true }}
      options={{ height: '70vh' }}
      styles={datagridDefaultStyles}
      {...props}
    >
      {Object.keys(columns).map((key) => renderElement(key))}
    </Datagrid>
  );
};

const mapStateToProps = ({
  structuredProductsPricingReducer: { columns },
  admin: { resources: { structured_products_pricing: { data } } },
}) => ({
  columns,
  data,
});

const enhance = compose(
  translate,
  connect(mapStateToProps, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(Columns);
