import { Card, CardHeader, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import React, { Component } from 'react';
import { reloadPage } from './utils';
import './style.css';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    message: null,
  };

  refreshPage = () => {
    reloadPage();
  }

  componentDidCatch(error) {
    const { message } = error;
    this.setState({ hasError: true, message });
  }

  render() {
    const { hasError, message } = this.state;
    const { children, Component } = this.props;
    if (hasError) {
      if (Component) {
        return <Component />
      }
      return (
        <Card className="error-wrapper">
          <CardHeader title={'Error:'} />
          <CardText style={{ paddingTop: 0 }}>
            <div className="error-msg">{message}</div>
            <div className="error-button-wrapper">
              <RaisedButton
                primary
                label={'Reload'}
                className="error-button"
                onClick={this.refreshPage} />
            </div>
          </CardText>
        </Card>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
