import { translate } from 'admin-on-rest';
import Avatar from 'material-ui/Avatar';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import NotSignedOTCConfirmationsIcon from 'material-ui/svg-icons/notification/phone-missed';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';
import React, { Component } from 'react';
import Moment from 'react-moment';

const tableRowStyle = { paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'center', textTransform: 'capitalize' };
const tableHeaderColumnStyle = { paddingLeft: '10px', paddingRight: '10px', textAlign: 'center', whiteSpace: 'pre-wrap' };

class NotSignedOTCConfirmations extends Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  render() {
    const { notSignedOTCConfirmations = [], count = 0 } = this.props;

    return (
      <Card
        style={{ marginBottom: '2em' }}
      >
        <CardHeader
          title={count}
          subtitle={this.translate('dashboard.notSignedOTCConfirmations.subtitle')}
          avatar={<Avatar backgroundColor="#f44336" icon={<NotSignedOTCConfirmationsIcon />} />}
          actAsExpander={true}
          showExpandableButton={true}
        />
        <CardText
          expandable={true}
          style={{ padding: '0' }}
        >
          <Table selectable={false}>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn style={tableHeaderColumnStyle}>
                  {this.translate('dashboard.notSignedOTCConfirmations.table.email')}
                </TableHeaderColumn>
                <TableHeaderColumn style={tableHeaderColumnStyle}>
                  {this.translate('dashboard.notSignedOTCConfirmations.table.status')}
                </TableHeaderColumn>
                <TableHeaderColumn style={tableHeaderColumnStyle}>
                  {this.translate('dashboard.notSignedOTCConfirmations.table.sentReminder')}
                </TableHeaderColumn>
                <TableHeaderColumn style={tableHeaderColumnStyle}>
                  {this.translate('dashboard.notSignedOTCConfirmations.table.remindersCount')}
                </TableHeaderColumn>
                <TableHeaderColumn style={tableHeaderColumnStyle}>
                  {this.translate('dashboard.notSignedOTCConfirmations.table.sentAt')}
                </TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {notSignedOTCConfirmations.length ? notSignedOTCConfirmations.map(r => (
                <TableRow key={r.id}>
                  <TableRowColumn style={tableRowStyle}>
                    {r.signer_email}
                  </TableRowColumn>
                  <TableRowColumn style={tableRowStyle}>
                    {r.status}
                  </TableRowColumn>
                  <TableRowColumn style={tableRowStyle}>
                    {r.reminders_count > 0 ? 'Yes' : 'No'}
                  </TableRowColumn>
                  <TableRowColumn style={tableRowStyle}>
                    {r.reminders_count}
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'center' }}>
                    <Moment format="DD MMMM YYYY">{r.created_at}</Moment>
                  </TableRowColumn>
                </TableRow>
              )) : <TableRow><TableRowColumn style={{ paddingLeft: '16px', fontSize: '14px' }}>{this.translate('dashboard.notSignedOTCConfirmations.empty')}</TableRowColumn></TableRow>}
            </TableBody>
          </Table>
        </CardText>
      </Card>
    );
  }
}

export default translate(NotSignedOTCConfirmations);
