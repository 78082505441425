export const COLUMN_VERSION_SPP = 2;
/**
 * Action for manage columns in Structured Products Pricing table.
 * Turn on/off column.
 * Reducer: structuredProductsPricingReducer.
 * @param {String} col Column key
 * @param {Boolean} status Turn on/off
 */
export const NO_TOGGLE_COLUMN_SPP = 'NO_TOGGLE_COLUMN_SPP';
export const switchColumn = (col, status) => ({
  type: NO_TOGGLE_COLUMN_SPP,
  payload: {
    col,
    status,
  },
});

/**
 * Action for on/off all columns in Structured Products Pricing table.
 * Turn on/off columns.
 * Reducer: structuredProductsPricingReducer.
 * @param {Boolean} status Turn on/off
 */
export const NO_TOGGLE_ALL_COLUMNS_SPP = 'NO_TOGGLE_ALL_COLUMNS_SPP';
export const toggleAllColumns = (status) => ({
  type: NO_TOGGLE_ALL_COLUMNS_SPP,
  payload: status,
});

export const SET_COLUMNS_SPP = 'SET_COLUMNS_SPP';
export const setColumns = (status) => ({
  type: SET_COLUMNS_SPP,
  payload: status,
});
