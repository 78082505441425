import React, { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import request from 'superagent';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import CreateButtonIcon from 'material-ui/svg-icons/content/reply';
import EditIcon from 'material-ui/svg-icons/image/edit';
import {
  translate as adminTranslate,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
} from 'admin-on-rest';
import { removeSpecialChars } from '../../helpers/utilities';
import path from '../../path';
import UploadButton from '../../components/UploadButton';
import ShowFile from '../../components/ShowFile';

const isJsonValid = (SpJson) => {
  try {
    if (SpJson && JSON.parse(SpJson)) {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};

const CreateEditButton = ({ edit = false, record, translate, showNotification, refreshView, resource }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [jsonFile, setJsonFile] = useState(undefined);
  const translationsPrefix = (edit && 'configurableSp.edit') || 'configurableSp.create';
  const showPreviewFile = record && !jsonFile;

  const handleOpen = async () => {
    setOpen(true);
    if (record && record.id) {
      setName(record.name);
    }
  };

  const getValidationErrors = () => {
    const validationErrors = {};
    if (!name.trim()) {
      validationErrors.name = true;
    }

    if (isJsonValid(jsonFile)) {
      validationErrors.jsonFile = true;
    }
    return validationErrors;
  };

  const handleNameChange = (_, value) => {
    setName(removeSpecialChars(value));
    setErrors(getValidationErrors());
  };

  const closeDialog = () => {
    setJsonFile(undefined);
    setOpen(false);
    setName('');
    setErrors({});
    setErrorMessage('');
  };

  const createInputConfig = () => {
    if (!jsonFile) {
      showNotification(`${translationsPrefix}.error.noJsonFile`, 'warning');
      setSubmitting(false);
      return;
    }

    request
      .post(`${path}/admin/${resource}`)
      .set('Token', localStorage.getItem('session'))
      .field('settings', jsonFile)
      .field('name', name)
      .then(() => {
        showNotification(`${translationsPrefix}.successful`);
        refreshView();
        closeDialog();
      })
      .catch((e) => {
        setErrorMessage(translate(`${translationsPrefix}.error.${e.response.body.message || 'error'}`, 'warning'));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const editInputConfig = (id) => {
    const fields = { name };
    if (jsonFile) {
      fields.settings = jsonFile;
    }
    request
      .put(`${path}/admin/${resource}/${id}`)
      .set('Token', localStorage.getItem('session'))
      .field(fields)
      .then(() => {
        showNotification(`${translationsPrefix}.successful`);
        refreshView();
        closeDialog();
      })
      .catch((e) => {
        setErrorMessage(translate(`${translationsPrefix}.error.${e.response.body.message || 'error'}`, 'warning'));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSubmit = () => {
    const validationErrors = getValidationErrors();

    if (Object.keys(validationErrors).length) {
      setErrors({ ...validationErrors });
      return;
    }

    setSubmitting(true);
    setErrorMessage('');

    if (edit) {
      editInputConfig(record.id);
    } else {
      createInputConfig();
    }
  };

  const btnIcon = (edit && <EditIcon />) || <CreateButtonIcon />;
  return (
    <div style={{ float: 'left' }}>
      <FlatButton label={translate(`${translationsPrefix}.buttonText`)} icon={btnIcon} onClick={handleOpen} />
      <Dialog
        title={translate(`${translationsPrefix}.title`)}
        modal={false}
        open={open}
        repositionOnUpdate={false}
        style={{ paddingTop: 0 }}
        bodyStyle={{ overflowY: 'auto' }}
      >
        <TextField
          errorText={errors.name && translate('aor.validation.required')}
          floatingLabelText={translate(`${translationsPrefix}.nameField`)}
          fullWidth
          name="name"
          type="text"
          value={name}
          multiLine={true}
          onChange={handleNameChange}
          maxLength="200"
          style={{ marginBottom: '10px' }}
        />
        <UploadButton onDrop={setJsonFile} accept={'.json'} showPreview={false} style={{ marginLeft: '6px' }} />
        {showPreviewFile && (
          <ShowFile
            id={record.id}
            cardJson={record.settings}
            fileStatus={record.fileUploadStatus}
            fileName={record.fileName || `${resource}.json`}
          />
        )}

        <div style={{ color: 'red', padding: '12px', fontWeight: 600 }}>{errorMessage}</div>
        <div style={{ paddingTop: '15px', float: 'right' }}>
          <RaisedButton
            label={translate('configurableSp.dialog.btnCancel')}
            onClick={closeDialog}
            icon={<CancelIcon />}
          />
          <RaisedButton
            type="submit"
            style={{ marginLeft: '10px' }}
            label={translate('configurableSp.dialog.btnSubmit')}
            primary={true}
            icon={<SubmitIcon />}
            disabled={submitting}
            onClick={handleSubmit}
          />
        </div>
      </Dialog>
    </div>
  );
};

const enhance = compose(
  adminTranslate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(CreateEditButton);
