import { put, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'admin-on-rest';
import path from '../path';
import { REFRESH_AUTH } from './constants';

const fetchLogs = (type) => new Promise((resolve, reject) =>
  fetch(`${path}/admin/auth_client/refresh/${type}`, {
    method: 'PUT',
    headers: {
      Token: localStorage.getItem('session'),
    },
  })
    .then(async (response) => {
      if (response.status === 200) return response.json();
      const { message = 'Request failed' } = await response.json();
      reject(message);
    })
    .then((response) => resolve(response))
    .catch(error => reject(error.message)));

export default function* AuthClientSaga() {
  yield [
    takeEvery(REFRESH_AUTH, function* ({ payload: type }) {
      try {
        yield fetchLogs(type);
        yield put(showNotification('Start refreshing token...'));
      } catch (error) {
        yield put(showNotification(error, 'warning'));
      }
    }),
  ];
}
