import React from 'react';
import {
  required,
  SimpleForm,
  TextInput,
} from 'admin-on-rest';

const Form = ({ toolbar, ...rest }) => (
  <SimpleForm toolbar={toolbar} {...rest}>
    <TextInput
      label='domain.name'
      source='name'
      type='name'
      validate={[required]}
      options={{
        fullWidth: true,
      }}
    />
  </SimpleForm>
);

export default Form;
