import React, { Component } from 'react';
import {
  LongTextInput,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  required,
  minLength,
} from 'admin-on-rest';
import { Permissions } from './components';

const validateMinLengthToTwo = minLength(2);


const Action = (props) => {
  const { match: { path } } = props;
  if (path === '/admin_roles/create') {
    return (<Create {...props}></Create>);
  }

  return (<Edit {...props}></Edit>);
};

export default class Form extends Component {
  render() {
    const { match: { params, path } } = this.props;
    return (
      <Action {...this.props}>
        <SimpleForm redirect="list" submitOnEnter={true}>
          <TextInput
            source="title"
            validate={[required, validateMinLengthToTwo]}
          />
          <LongTextInput
            source="description"
          />

          <Permissions
            source="permissions"
            disabled={params.id === '1'}
            create={path === '/admin_roles/create'}
          />
        </SimpleForm>
      </Action>
    );
  }
}
