import React, { Children } from 'react';
import { FormField } from 'admin-on-rest';
import './style.css';

export const SectionBox = ({ children, basePath, record, resource }) => (
  <div className="footer-wrapper">
    {Children.map(
      children,
      input => (input ? (
        <FormField
          basePath={basePath}
          input={input}
          record={record}
          resource={resource}
        />
      ) : null),
    )}
  </div>
);
