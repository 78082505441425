import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import request from 'superagent';
import { showNotification } from 'admin-on-rest';

import { OTC_SEND_SUCCESS, OTC_SEND_FAILURE, OTC_RELEASE, OTC_CANCEL } from './OTCActions';
import path from '../path';

export default function* reviewSaga() {
  yield [
    takeEvery(OTC_RELEASE, function* ({ payload }) {
      const url = `${path}/admin/otc_confirmation/${payload.id}?sendWithDocusign=${payload.sendWithDocusign}`;
      try {
        yield request.post(url).set('token', localStorage.getItem('session'));
        yield put(showNotification('OTCConfirmations.notification.send_success'));
      } catch (error) {
        yield put(showNotification('OTCConfirmations.notification.send_error', 'warning'));
        console.error(error);
      }
      yield put(push('/'));
      yield put(push('/otc_confirmation'));
    }),
    takeEvery(OTC_SEND_SUCCESS, function* () {
      yield put(showNotification('OTCConfirmations.reminder.send_success'));
      yield put(push('/'));
      yield put(push('/otc_confirmation'));
    }),
    takeEvery(OTC_SEND_FAILURE, function* ({ error }) {
      yield put(showNotification('OTCConfirmations.reminder.send_error', 'warning'));
      console.error(error);
    }),
    takeEvery(OTC_CANCEL, function* ({ payload: { envelopeId } }) {
      const url = `${path}/admin/otc_confirmation/cancel/${envelopeId}`;

      try {
        yield request.put(url).set('token', localStorage.getItem('session'));
        yield put(showNotification('OTCConfirmations.notification.cancel_success'));
      } catch (error) {
        yield put(showNotification('OTCConfirmations.notification.cancel_error', 'warning'));
        console.error(error);
      }

      yield put(push('/'));
      yield put(push('/otc_confirmation'));
    }),
  ];
}
