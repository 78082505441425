export const APP_TITLE = 'Admin Marex Solutions Agile';

// todo: MS-BO used lerna and references shared library to get these
export const AUTOAPPROVE_STATUS_APPROVED = 'autoapproved';
export const AUTOAPPROVE_STATUS_EXCEEDED = 'exceeded_limit';

export const MESSAGE_NEW = 'NEW';
export const MESSAGE_UPDATE = 'UPDATE';
export const MESSAGE_DELETE = 'DELETE';
export const MESSAGE_COUNT_ZERO = 'COUNT_ZERO';

export const MESSAGE_EVENT_ACTIONS = new Map([
  ['ADMIN:ORDER:', MESSAGE_NEW],
  ['MESSAGE::', MESSAGE_NEW],
  ['SYSTEM:Notification:readNotification', MESSAGE_UPDATE],
  ['SYSTEM:Notification:deleteNotification', MESSAGE_DELETE],
  ['SYSTEM:Notification:deleteAllNotifications', MESSAGE_COUNT_ZERO],
  ['SYSTEM:Notification:readAllNotifications', MESSAGE_COUNT_ZERO],
]);

export const ONLY_MAREX_USERS = 'marex';
