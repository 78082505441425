import {
  AUTOAPPROVE_STATUS_APPROVED,
  AUTOAPPROVE_STATUS_EXCEEDED,
  MESSAGE_NEW,
  MESSAGE_UPDATE,
  MESSAGE_DELETE,
  MESSAGE_COUNT_ZERO,
} from '../../constants';
import { GRAPHQL_NETWORK_ONLY, EVENTS_SUBSCRIPTION } from './queries';


const getSound = ({ autoapprove_status: status, quantity }) => {
  if (status === AUTOAPPROVE_STATUS_APPROVED) return 'autoapprove-audio';
  if (quantity >= 100 || status === AUTOAPPROVE_STATUS_EXCEEDED) return 'bell-more-audio';
  return 'bell-audio';
};

export const sound = (order) => {
  if (localStorage.getItem('bell') === 'true') {
    const bell = document.getElementById(getSound(order));
    bell.pause();
    bell.currentTime = 0;
    bell.volume = 0.5;
    bell.play().then(() => { }).catch((error) => {
      console.error('paly error', error);
    });
  }
};


export const getCount = (action, previousState, { isRead }) => {
  if (action === MESSAGE_COUNT_ZERO) {
    return 0;
  }
  if (action === MESSAGE_NEW) {
    return previousState.count + 1;
  }
  if (action === MESSAGE_UPDATE) {
    return previousState.count - 1 > 0 ? previousState.count - 1 : 0;
  }
  if (action === MESSAGE_DELETE && !isRead) {
    return previousState.count - 1 > 0 ? previousState.count - 1 : 0;
  }
  return previousState.count;
};

export const queryOptionsProvider = (query, propertyName, options) => {
  if (!propertyName) return null;
  return {
    options: options || { fetchPolicy: GRAPHQL_NETWORK_ONLY },
    props({ data: { loading, fetchMore, refetch, variables, ...rest } }) {
      return {
        refetch,
        loading,
        [propertyName]: rest[propertyName],
        loadMore: () => fetchMore({
          query,
          variables: {
            ...variables,
            cursor: rest[propertyName].pageInfo.endCursor,
          },
          updateQuery: (previousResult, { fetchMoreResult }, ...restUpdateQuery) => {
            const newEdges = fetchMoreResult[propertyName].edges;
            const pageInfo = fetchMoreResult[propertyName].pageInfo;
            const updatedData = [...previousResult[propertyName].edges, ...newEdges];

            return newEdges.length ? {
              [propertyName]: {
                ...previousResult[propertyName],
                __typename: previousResult[propertyName].__typename,
                edges: updatedData,
                pageInfo,
                ...restUpdateQuery,
              },
            } : previousResult;
          },
        }),
        variables,
        ...rest,
      };
    },
  };
};

export const SUBSCRIBE_TO_MORE = {
  document: EVENTS_SUBSCRIPTION,
  updateQuery: (prev, ddd) => {
    const { subscriptionData } = ddd;
    if (!subscriptionData.data) return prev;
    const { notifications } = subscriptionData.data;
    if (!notifications) return prev;
    if (notifications.type !== 'ADMIN' || !notifications.subject) return prev;
    return {
      ...prev,
      messagesList: {
        ...prev.messagesList,
        edges: [{
          __typename: 'NotificationCenterTypeEdge',
          node: { ...notifications },
        }, ...prev.messagesList.edges],
      },
    };
  },
};

export const mapNodes = ({ edges = [] }) => edges.map(({ node: { __typename, ...rest } }) => rest) || [];
