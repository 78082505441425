import React from 'react';

import { Create, SimpleForm, TextInput, required, ReferenceArrayInput, SelectArrayInput, SaveButton, Toolbar, minLength, maxLength } from 'admin-on-rest';
import { ONLY_MAREX_USERS } from '../constants';

const DashboardToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      redirect="list"
      submitOnEnter={true}
    />
  </Toolbar>
);

const validateMinLengthToTwo = minLength(2);
const validateMaxLengthToHundred = maxLength(100);
const GroupsDashboardsCreate = props => (
  <Create {...props}
    title="Dashboard Group"
  >
    <SimpleForm
      toolbar={<DashboardToolbar />}
    >
      <TextInput
        source="name"
        validate={[required, validateMinLengthToTwo, validateMaxLengthToHundred]}
        elStyle={{ width: '50%' }}
      />
      <TextInput
        source="description"
        validate={[required, validateMinLengthToTwo, validateMaxLengthToHundred]}
        elStyle={{ width: '50%' }}
      />
      <ReferenceArrayInput
        source="dashboards"
        reference="dashboards"
        label="Dashboards"
        allowEmpty
        perPage={100000}
      >
        <SelectArrayInput
          optionText="name"
          options={{ 'listStyle': { 'maxHeight': '30vh', 'overflow': 'auto' }, 'onBlur': () => {} }}
          validate={[required]}
          elStyle={{ width: '50%' }}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="users_ids"
        reference="users"
        label="Users"
        allowEmpty
        perPage="1e9"
        sort={{ field: 'email', order: 'ASC' }}
        filterToQuery={() => ({ _q: ONLY_MAREX_USERS })}
        filter={{ _q: ONLY_MAREX_USERS }}
      >
        <SelectArrayInput
          optionText="email"
          optionValue="id"
          options={{
            'listStyle': { 'maxHeight': '30vh', 'overflow': 'auto' },
            'onBlur': () => {},
            'newChipKeyCodes': [],
            'newChipKeys': [],
          }}
          elStyle={{ width: '50%' }}
          validate={[required]}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default GroupsDashboardsCreate;
