import React from 'react';

import { RefreshButton } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';

import AccumulatorDescriptionDeleteButton from './AccumulatorDescriptionDeleteButton';

const AccumulatorDescriptionActions = ({
  refresh, filters, resource, showFilter, displayedFilters, filterValues, disabled, loaded,
}) => (
  <CardActions>
    <AccumulatorDescriptionDeleteButton disabled={disabled} loaded={loaded()} />
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    <RefreshButton refresh={refresh} />
  </CardActions>
);

export default AccumulatorDescriptionActions;
