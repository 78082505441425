import React from 'react';

import { SimpleForm, Edit, SelectInput, ReferenceInput, required, ReferenceField, FunctionField } from 'admin-on-rest';

import VolatilityConfigEditTitle from './VolatilityConfigEditTitle';

const VolatilityBucketEdit = props => (
  <Edit {...props} title={<VolatilityConfigEditTitle />}>
    <SimpleForm>
      <ReferenceInput
        allowEmpty
        label="Volatility Bucket"
        source="volatility_bucket_id"
        reference="volatility_bucket"
        filter={{ dropdown: true }}
        validate={[required]}
      >
        <SelectInput
          optionValue="id"
          optionText="name"
        />
      </ReferenceInput>
      <ReferenceField
        linkType="show"
        label="market data"
        source="market_data_id"
        reference="market_data"
      >
        <FunctionField
          render={record => `${record.commodity_contract} ${record.bloomberg_ticker} (${record.quoted_currency} ${record.short_code})`}
        />
      </ReferenceField>
    </SimpleForm>
  </Edit>
);

export default VolatilityBucketEdit;
