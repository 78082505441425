import React from 'react';
import { BooleanField, Datagrid, List, Responsive, RichTextField, SimpleList, TextField } from 'admin-on-rest';
import Moment from 'react-moment';
import { DateField, DisplayText } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';
import { LinkedChips } from './../components';
import { DashboardFilter, ButtonsWrapper, getText } from './components';

const DashboardList = props => (
  <List {...props}
    filters={<DashboardFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => r.title}
          secondaryText={r => r.description}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
        />
      }
      medium={
        <Datagrid
          bodyOptions={{ showRowHover: true }}
          options={{ height: '70vh' }}
          styles={datagridDefaultStyles}
        >
          <TextField
            source="seo"
          />
          <TextField
            source="title"
          />
          <RichTextField
            source="description"
          />
          <DisplayText
            source="position"
            getText={({ record }) => getText(record)}
          />
          <LinkedChips
            label="themes"
            source="themesData"
            titleField="name"
            reference="themes"
            target="id"
            elStyle={{
              margin: '2px',
            }}
          />
          <BooleanField
            source="status"
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="updatedAt"
            showTime
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="createdAt"
            showTime
          />
          <ButtonsWrapper props={props} />
        </Datagrid>
      }
    />
  </List>
);

export default DashboardList;
