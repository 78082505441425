import Node from './components/Node';

export const NODE_TYPE_PRODUCER = 'PRODUCER';
export const NODE_TYPE_GROUP = 'GROUP';
export const NODE_TYPE_CARD = 'CARD';
export const NODE_TYPE_TAB = 'TAB';

export const NODE_TYPE_ORDER = {
  [NODE_TYPE_PRODUCER]: 1,
  [NODE_TYPE_GROUP]: 2,
  [NODE_TYPE_CARD]: 3,
  [NODE_TYPE_TAB]: 4,
};

export const NODE_TYPE_ORDER_ACCESS = {
  [NODE_TYPE_CARD]: 1,
  [NODE_TYPE_GROUP]: 2,
  [NODE_TYPE_TAB]: 3,
  [NODE_TYPE_PRODUCER]: 4,
};

export const NODE_CARD_TYPE = 'card';
export const nodeTypes = {
  [NODE_CARD_TYPE]: Node,
};

export const CARD_WIDTH = 600;
export const CARD_HEIGHT = 120;
export const CARD_HEIGHT_LINE = 43;

export const DEFAULT_NODE_POSITION = { x: 0 || 0, y: 0 };
export const NODE_IS_DRAGGABLE = true;
export const EDGE_IS_ANIMATED = true;
export const EDGE_TYPE = 'simplebezier';
export const EDGE_COLOR = '#00bcd4';

export const LAYOUT_DIRECTION_VERTICAL = 'TB';
export const LAYOUT_DIRECTION_HORIZONTAL = 'LR';
export const DEFAULT_LAYOUT_DIRECTION = LAYOUT_DIRECTION_HORIZONTAL;
