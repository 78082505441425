import { Datagrid, DateInput, DeleteButton, Filter, FunctionField, List, ReferenceField, refreshView, Responsive, SelectField, SelectInput, SimpleList, TextInput } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput, formatDateInput } from '../utils/dates';
import RefreshDashboardConfirmButton from './RefreshDashboardConfirmButton';
import socket from 'ioClient';

const RefreshDashboardFilter = props => (
  <Filter {...props}>
    <TextInput
      label="refreshDashboards.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      label="User Email"
      source="email"
    />
    <TextInput
      label="Dashboard Name"
      source="dashboard"
    />
    <SelectInput
      source="status"
      choices={[
        { id: true, name: 'Refreshed' },
        { id: false, name: 'Pending' },
      ]}
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
    />
  </Filter>
);

class RefreshDashboardList extends React.Component {
  constructor(props) {
    super(props);

    this.socket = socket;
  }

  componentDidMount() {
    this.socket.on('dashboard-refresh-request', () => {
      this.props.refreshView();
    });
  }

  render() {
    return (<List {...this.props}
      filters={<RefreshDashboardFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={50}
    >
      <Responsive
        small={
          <SimpleList
            primaryText={r => (r.status ? 'Refreshed' : 'Pending') }
            secondaryText={r => `User ID ${r.user_id}` }
            tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
          />
        }
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
            <ReferenceField
              label="dashboard"
              source="dashboard_id"
              reference="dashboards"
            >
              <FunctionField
                render={record => record.name}
              />
            </ReferenceField>
            <ReferenceField
              label="user"
              source="user_id"
              reference="users"
            >
              <FunctionField
                render={record => `${record.firstName} ${record.lastName}`}
              />
            </ReferenceField>
            <SelectField
              source="status"
              choices={[
                { id: true, name: 'Refreshed' },
                { id: false, name: 'Pending' },
              ]}
            />
            <DateField
              formatDate={formatDateTimeInput}
              source="updated_at"
              showTime
            />
            <DateField
              formatDate={formatDateTimeInput}
              source="created_at"
              showTime
            />
            <RefreshDashboardConfirmButton />
            <DeleteButton disabled={true} />
          </Datagrid>
        }
      />
    </List>);
  }
}

export default connect(null, {
  refreshView,
})(RefreshDashboardList);
