import React from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'admin-on-rest';
import AutoComplete from 'material-ui/AutoComplete';

import {
  validateCode,
  validateMaxLengthCode,
  validateMaxLengthName,
  validateMinLengthCode,
  validateMinLengthName,
} from '../../utils/validations';

const Form = ({ toolbar, ...rest }) => (
  <SimpleForm toolbar={toolbar} {...rest}>
    <ReferenceInput
      key="user_group_id"
      source="user_group_id"
      reference="user_groups"
      label="Group" allowEmpty
      sort={{ field: 'name', order: 'ASC' }}
      filterToQuery={searchText => ({ _q: searchText })}
    >
      <AutocompleteInput
        optionText={r => `${r.name} ${r.code}`}
        optionValue="id"
        elStyle={{ width: '50%' }}
        options={
          {
            'listStyle': { 'maxHeight': '30vh', 'overflow': 'auto' },
            'onBlur': () => {},
            'fullWidth': true,
            'filter': AutoComplete.levenshteinDistance,
          }
        }/>
    </ReferenceInput>
    <TextInput
      label='customer.customerCode'
      source='customer_code'
      type='customerCode'
      validate={[
        required,
        validateMinLengthCode,
        validateMaxLengthCode,
        validateCode,
      ]}
      elStyle={{ width: '50%' }}
      options={{
        fullWidth: true,
      }}
    />
    <TextInput
      label='customer.name'
      source='name'
      type='name'
      validate={[required, validateMinLengthName, validateMaxLengthName]}
      options={{
        fullWidth: true,
      }}
    />
  </SimpleForm>
);

export default Form;
