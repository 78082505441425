const GENERAL = 'General';

// hook for change uri path
const match = {
  params: {
    id: 'list',
  },
};

const RECORD_FORM = 'record-form';

module.exports = {
  GENERAL,
  match,
  RECORD_FORM,
};
