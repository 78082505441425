import React from 'react';
import PropTypes from 'prop-types';
import UserRejectedIcon from 'material-ui/svg-icons/navigation/cancel';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';

import { connect } from 'react-redux';
import { translate, UPDATE } from 'admin-on-rest';

import { USER_REJECT } from './UserAction';

const userRejectAction = (id, data, basePath) => ({
  type: USER_REJECT,
  payload: { id, data: { ...data, status: 3 }, basePath },
  meta: { resource: 'users', fetch: UPDATE, cancelPrevious: false },
});

class UserRejectButton extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  onClick() {
    return () => {
      const { record } = this.props;
      this.props.rejectAction(record.id, record);
    };
  }

  render() {
    const { record, hideLabel } = this.props;
    const label = hideLabel ? '' : this.translate('users.list.reject');
    return (
      <FlatButton
        primary
        onClick={this.onClick()}
        disabled={!record || record.status === 3}
        label={label}
        icon={<UserRejectedIcon/>}
        style={{ float: 'left', minWidth: '60px' }}
      />
    );
  }
}

UserRejectButton.propTypes = {
  record: PropTypes.object,
  rejectAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    rejectAction: userRejectAction,
  }),
);

export default enhance(UserRejectButton);
