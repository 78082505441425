import React from 'react';
import ReactJson from 'react-json-view';
import { ListItem } from 'material-ui/List';
import DownloadIcon from 'material-ui/svg-icons/action/get-app';

const itemStyle = { display: 'flex', justifyContent: 'space-between' };

const ShowFile = (props) => {
  const { id, cardJson, fileStatus, fileName } = props;
  if (!fileStatus) return null;
  return (<ListItem
    initiallyOpen={false}
    nestedItems={[
      <ReactJson
        key={`${id}_json`}
        src={cardJson}
        theme="monokai"
        name={false}
        collapsed={false}
        displayDataTypes={false}
      />,
    ]}
  >
    <a href={`data:text/json;charset=utf-8,${JSON.stringify(cardJson, undefined, 2)}`} download={fileName}>
      <div style={{ float: 'left', marginRight: '20px' }}>
        <DownloadIcon color="#006064" style={{ width: '28px', height: '28px', margin: '-5px 0 0' }}/>
      </div>
    </a>
    <div style={itemStyle}>
      {fileName}
    </div>
  </ListItem>);
};

export default ShowFile;
