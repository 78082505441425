import {
  Datagrid,
  DateInput,
  DeleteButton,
  EditButton,
  Filter,
  List,
  Responsive,
  TextField,
  TextInput,
} from 'admin-on-rest';
import React from 'react';
import { DateField } from '../helpers';
import { formatDateTimeInput, formatDateInput } from '../utils/dates';

const FilterComponent = props => (
  <Filter {...props}>
    <TextInput
      label="admins.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="host"
    />
    <TextInput
      source="username"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
    />
  </Filter>
);

const SftpAccountsList = props => (
  <List {...props}
    filters={<FilterComponent />}
    sort={{ field: 'created_at', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      medium={
        <Datagrid>
          <TextField
            source="id"
          />
          <TextField
            source="host"
          />
          <TextField
            source="username"
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="updated_at"
            showTime
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="created_at"
            showTime
          />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      }
    />
  </List>
);

export default SftpAccountsList;
