import { Datagrid, List, Responsive, ListButton } from 'admin-on-rest';
import React, { Component, Fragment } from 'react';
import { CardActions } from 'material-ui/Card';
import Seo from '../components/common/Seo/';
import { DateField, DisplayText } from '../helpers';
import { formatDateTimeInput } from '../utils/dates';
import HistoryActions from '../components/HistoryActions';

const UsersHistoryActions = () => (
  <CardActions
    className="CardActions"
  >
    <ListButton
      basePath="/users"
      label="Back"
    />
  </CardActions>
);

class UsersHistories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      title: null,
    };
    this.init();
  }

  init() {
    const { match: { params }, history } = this.props;
    const { id } = params;
    if (!parseInt(id, 10)) {
      history.replace('/users_history');
    }
  }

  render() {
    const { match: { params } } = this.props;
    const { id } = params;
    return (
      <Fragment>
        <Seo title={`User #${id} History`} />
        <List {...this.props}
          sort={{ field: 'created_at', order: 'DESC' }}
          filter={{ user_id: id }}
          title={`User #${id} History`}
          actions={<UsersHistoryActions {...this.props} />}
          perPage={10}>
          <Responsive
            medium={
              <Datagrid>
                <DateField
                  formatDate={formatDateTimeInput}
                  source="created_at"
                  showTime
                />
                <DisplayText
                  source="update_by"
                  label="Person Action"
                  getText={({ record }) => record.admin_by || record.user_by}
                />
                <DisplayText
                  source="description"
                  getText={({ record }) => <HistoryActions {...record} />}
                />
              </Datagrid>
            }
          />
        </List>
      </Fragment>
    );
  }
}

export default UsersHistories;
