export const DEFAULT_DISPLAY_SP_COLUMNS = [
  'id',
  'structure',
  'issuer',
  'tag',
  'underlyings',
  'user',
];

export const CONFIGURABLE_SP_COLUMNS = [
  'id',
  'structure',
  'format',
  'currency',
  'tenor',
  'tag',
  'underlyings',
  'notional',
  'reoffer',
  'user',
];

export const DEFAULT_SP_COLUMNS_FIELDS = [
  'id',
  'structure',
  'issuer',
  'tag',
  'underlyings',
  'user',
  'currency',
  'maturity',
  'frequency',
  'first_observation_in',
  'autocall',
  'autocall_trigger_level',
  'step_down',
  'step',
  'coupon_type',
  'memory_coupon',
  'coupon_trigger_level',
  'solve_for',
  'coupon_level',
  'strike_level',
  'downside_leverage',
  'barrier_type',
  'barrier_level',
  'reoffer',
  'no_coupon_paid_at_maturity',
  'basket_type',
  'capital_protection_level',
  'option_type',
  'participation_level',
  'cap_type',
  'cap_level',
  'rebate_level',
  'rebate_level_2',
  'option_type_2',
  'strike_level_2',
  'participation_level_2',
  'cap_type_2',
  'cap_level_2',
  'barrier_type_2',
  'barrier_level_2',
  'created_at',
];
