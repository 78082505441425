import { DateInput, Filter } from 'admin-on-rest';
import React from 'react';
import { formatDateInput } from '../utils/dates';

const PDFHistoryFilter = props => (
  <Filter {...props}>
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
    />
  </Filter>
);

export default PDFHistoryFilter;
