import { List, Responsive, Datagrid, TextField, GET_ONE, ListButton } from 'admin-on-rest';
import React, { Component } from 'react';
import { CardActions } from 'material-ui/Card';
import { formatDateTimeInput } from '../utils/dates';
import { DateField, DisplayText } from '../helpers';
import restClient from '../restClient';
import { Loader } from '../helpers/Loader';

const SpreadSheetsEditActions = () => (
  <CardActions
    className="CardActions"
  >
    <ListButton
      basePath="/spreadsheets"
      label="Back"
    />
  </CardActions>
);

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const subItems = (list) => {
  const { type, agile_name: agileName, state } = list;
  return [
    <span key="sub">{capitalize(type)} <b>{agileName}</b>:</span>,
    <ul key="sub_list">
      {state.map(item => <li key={agileName}>{item}</li>)}
    </ul>,
  ];
};

const historyItems = history => (<ul>
  {history.map((item, key) => <li key={key}>{typeof item === 'object' ? subItems(item) : item}</li>)}
</ul>);


class SpreadSheetsHistory extends Component {
  constructor() {
    super();
    this.state = {
      title: '',
      load: true,
    };
  }

  async componentDidMount() {
    const { data } = await restClient(GET_ONE, 'spreadsheets', {
      id: this.props.match.params.id,
    });
    this.setState(state => ({
      ...state,
      title: `"${data.title}"`,
      load: false,
    }));
  }

  render() {
    const { title, load } = this.state;
    return (
      <div>
        { load ? <Loader text='Please wait...' /> : '' }
        <List {...this.props}
          isLoading={load}
          title={`Spreadsheet ${title} history`}
          sort={{ field: 'created_at', order: 'DESC' }}
          filter={{ spreadsheet_id: this.props.match.params.id }}
          perPage={10}
          actions={<SpreadSheetsEditActions {...this.props} />}
          width={title.length}
        >
          <Responsive
            medium={
              <Datagrid>
                <TextField
                  source="update_by"
                />
                <DisplayText
                  source="description"
                  getText={({ record }) => {
                    const { history } = record.description;
                    if (!history) return null;
                    return historyItems(history);
                  }}
                />
                <DateField
                  formatDate={formatDateTimeInput}
                  source="created_at"
                  showTime
                />
              </Datagrid>
            }
          />
        </List>
      </div>
    );
  }
}
export default SpreadSheetsHistory;
