import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { showNotification } from 'admin-on-rest';

import { REFRESH_DASHBOARD_CONFIRM_SUCCESS, REFRESH_DASHBOARD_CONFIRM_FAILURE } from './RefreshDashboardAction';

export default function* reviewSaga() {
  yield [
    takeEvery(REFRESH_DASHBOARD_CONFIRM_SUCCESS, function* () {
      yield put(showNotification('refreshDashboards.notification.confirm_success'));
      yield put(push('/'));
      yield put(push('/refresh_dashboards'));
    }),
    takeEvery(REFRESH_DASHBOARD_CONFIRM_FAILURE, function* ({ error }) {
      yield put(showNotification('refreshDashboards.notification.confirm_error', 'warning'));
      console.error(error);
    }),
  ];
}
