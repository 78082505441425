export const PRICE_REQ_STATUSES = {
  PRICE_REQUEST: 'PRICE REQUEST',
  OLD_PRICE_REQUEST: 'PRICE REQUST', // neon order history may still have price requst entries
  SLACK_PRICE_REQUEST_REMINDER: 'PRICE REQUEST REMINDER',
  APPROVE_PRICE_REQUEST: 'APPROVE PRICE REQUEST',
  REJECT_PRICE_REQUEST: 'REJECT PRICE REQUEST',
  CANCEL_PRICE_REQUEST: 'CANCEL PRICE REQUEST',
};

export const TRADE_STATUSES = {
  CREATE_TRADE: 'CREATE TRADE',
  TRADE_UPDATE: 'TRADE UPDATE',
  TRADE_APPROVAL_REMINDER: 'TRADE APPROVAL REMINDER',
  TRADE_NOT_APPROVED: 'TRADE NOT APPROVED',

  FX_APPROVED: 'FX APPROVED',
  FX_FILLED: 'FX FILLED',
  FX_REJECTED: 'FX REJECTED',
  FX_CANCELED: 'FX CANCELED',

  NEON_APPROVE: 'NEON APPROVE',
  NEON_REJECT: 'NEON REJECT',
  NEON_CANCEL: 'NEON CANCEL',
  NEON_FILLED: 'NEON FILLED',
  NEON_PARTIAL_FILLED: 'NEON PARTIAL FILLED',
  NEON_EDIT: 'NEON EDIT',

  CQG_APPROVE: 'CQG APPROVE',
  CQG_REJECT: 'CQG REJECT',
  CQG_CANCEL: 'CQG CANCEL',
  CQG_FILLED: 'CQG FILLED',
  CQG_PARTIAL_FILLED: 'CQG PARTIAL FILLED',
  CQG_EDIT: 'CQG EDIT',
};

export const SLACK_STATUSES = {
  TRADE_BOOKED: 'TRADE BOOKED',
  CONFIRM_BOOKING: 'CONFIRM BOOKING',
  GET_EXT_ID: 'GET EXT ID',
  TRADE_RECAP: 'TRADE RECAP',
  OTC: 'OTC CONFIRMATION',
  BOOKING: 'SEND BOOKING FILE',
  FPT: 'SEND TRADE TO FPT',
  POST_TRADE: 'POST TRADE MESSAGE',
  TRADE_CANCEL: 'TRADE CANCEL',
};

export const STEP_PENDING = 'pending';
export const STEP_FAILE = 'faile';
export const STEP_DONE = 'done';

export const WIZARD_TRADE = [
  { label: 'Trade', status: STEP_PENDING },
  { label: 'Post to Booking Channel', status: STEP_PENDING },
  { label: 'Post Trade Message', status: STEP_PENDING },
];

export const WIZARD_PRICE = [
  { label: 'Price request', status: STEP_PENDING },
  ...WIZARD_TRADE,
];

export const GROUP_LABELS = ['price request', 'agile', 'slack'];
