import { DateInput, Filter, NumberInput, TextInput } from 'admin-on-rest';
import React from 'react';
import { formatDateInput } from '../utils/dates';

const VolatilityBucketFilter = props => (
  <Filter {...props}>
    <TextInput
      label="volatilityBucket.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="name"
    />
    <NumberInput
      source="min_shift_buy"
    />
    <NumberInput
      source="incremental_shift_buy"
    />
    <NumberInput
      source="min_shift_sell"
    />
    <NumberInput
      source="incremental_shift_sell"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
    />
  </Filter>
);

export default VolatilityBucketFilter;
