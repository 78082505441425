import React from 'react';

import { RefreshButton } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';

import MarketDataDeleteButton from './MarketDataDeleteButton';

const MarketDataActions = ({
  refresh,
  filters,
  resource,
  showFilter,
  displayedFilters,
  filterValues,
  disabled,
  loaded,
}) => (
  <CardActions>
    <MarketDataDeleteButton disabled={disabled} loaded={loaded()} />
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    <RefreshButton refresh={refresh} />
  </CardActions>
);

export default MarketDataActions;
