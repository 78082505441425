import React from 'react';
import Dropzone from 'react-dropzone';
import FlatButton from 'material-ui/FlatButton';
import FileUploadIcon from 'material-ui/svg-icons/file/file-upload';

import './uploadButton.css';

class UploadButton extends React.Component {
  onDrop = (file) => {
    const { onDrop } = this.props;
    onDrop(file);
  }

  render() {
    return (
      <div>
        <Dropzone
          className="Dropzone"
          onDrop={this.onDrop}
          multiple={false}
          accept=".pdf,.docx,.doc"
        >
          <FlatButton
            primary={this.primary}
            disabled={this.disabled}
            label='Upload File'
            icon={<FileUploadIcon/>}
          />
        </Dropzone>
      </div>
    );
  }
}

export default UploadButton;
