import { BooleanField, Datagrid, FunctionField } from 'admin-on-rest';
import { DateField } from '../../helpers';
import moment from 'moment/moment';
import React, { PropsWithChildren } from 'react';

interface SpDataGripWrapperProps {
  buttonComponent: React.ElementType;
  showVisible?: boolean;
}

const SpDataGripWrapper: React.FC<PropsWithChildren<SpDataGripWrapperProps>> = ({
  children,
  buttonComponent,
  showVisible = true,
  ...rest
}) => {
  const ButtonsWrapper = buttonComponent;
  const formatDate = (date: moment.MomentInput) => moment(date).format('DD MMMM YYYY HH:mm');
  return (
    <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} {...rest}>
      {children}
      <DateField label="Updated at" formatDate={formatDate} source="updatedAt" showTime />
      <DateField label="Created at" formatDate={formatDate} source="createdAt" showTime />
      {showVisible && <BooleanField label="Visible" source="visible" />}
      <ButtonsWrapper />
    </Datagrid>
  );
};

export default SpDataGripWrapper;
