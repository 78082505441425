
import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { showNotification } from 'admin-on-rest';

import { USER_APPROVE_SUCCESS, USER_APPROVE_FAILURE, USER_REJECT_SUCCESS, USER_REJECT_FAILURE } from './UserAction';

export default function* reviewSaga() {
  yield [
    takeEvery(USER_APPROVE_SUCCESS, function* () {
      yield put(showNotification('users.notification.approved_success'));
      yield put(push('/'));
      yield put(push('/users'));
    }),
    takeEvery(USER_APPROVE_FAILURE, function* ({ error }) {
      if (error === "You don't have permission to perform this action") {
        yield put(showNotification(error, 'warning'));
      } else {
        yield put(showNotification('users.notification.approved_error', 'warning'));
      }
      console.error(error);
    }),
    takeEvery(USER_REJECT_SUCCESS, function* () {
      yield put(showNotification('users.notification.rejected_success'));
      yield put(push('/'));
      yield put(push('/users'));
    }),
    takeEvery(USER_REJECT_FAILURE, function* ({ error }) {
      if (error === "You don't have permission to perform this action") {
        yield put(showNotification(error, 'warning'));
      } else {
        yield put(showNotification('users.notification.rejected_error', 'warning'));
      }
      console.error(error);
    }),
  ];
}
