import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import DetailIcon from 'material-ui/svg-icons/editor/insert-comment';
import RaisedButton from 'material-ui/RaisedButton';
import ReactJson from 'react-json-view';

const formatDescription = (str) => {
  try {
    str = JSON.parse(str);
  } catch (err) {
    str = '';
  }
  return (
    <div>
      <ReactJson src={str}
        theme="monokai"
        displayDataTypes={true}
        collapseStringsAfterLength={80}
        collapsed={3} />
    </div>
  );
};

class LogDetails extends React.Component {
  state = {
    open: false,
  }
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  render() {
    const { record } = this.props;
    const { open } = this.state;

    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <FlatButton
          label="Details"
          icon={<DetailIcon />}
          onClick={this.handleOpen}
        />
        <Dialog
          modal={false}
          open={open}
          autoScrollBodyContent={true}
        >
          {formatDescription(record.description)}
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              label="Cancel"
              onClick={this.handleClose}
              icon={<CancelIcon />}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

export default LogDetails;
