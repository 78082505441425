import React from 'react';
import DownloadIcon from 'material-ui/svg-icons/action/get-app';

const DownloadJson = ({ jsonFile, fileStatus, fileName }) => {
  if (!fileStatus) return null;

  const json = fileName;
  return (<div style={{ width: '250px' }} >
    <a href={`data:text/json;charset=utf-8,${JSON.stringify(jsonFile, undefined, 2)}`}
      download={json} style={{
        display: 'inline-block',
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.87)',
      }}>
      <DownloadIcon color="#006064" style={{ width: '28px', height: '28px', margin: '-5px 0 0', display: 'inline-block', verticalAlign: 'middle' }}/>
      <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>{fileName}</span>
    </a>
  </ div>);
};

export default DownloadJson;
