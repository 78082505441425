import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import shouldUpdate from 'recompose/shouldUpdate';
import compose from 'recompose/compose';
import FlatButton from 'material-ui/FlatButton';
import ImageEye from 'material-ui/svg-icons/action/work';

const ChangePasswordBtn = ({
  label = 'Change Password',
  record = {},
}) => (
  <FlatButton
    primary
    label={label}
    icon={<ImageEye />}
    containerElement={
      <Link to={`/admin_change_password/${record.id}`} title={label}/>
    }
    style={{ overflow: 'inherit' }}
  />
);

ChangePasswordBtn.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
};

const enhance = compose(
  shouldUpdate(
    (props, nextProps) =>
      (props.record &&
        nextProps.record &&
        props.record.id !== nextProps.record.id) ||
      props.basePath !== nextProps.basePath ||
      (props.record == null && nextProps.record != null),
  ),
);

export default enhance(ChangePasswordBtn);
