import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { TextField } from 'material-ui';
import {
  translate,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
  GET_LIST,
} from 'admin-on-rest';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import path from '../path';
import restClient from '../restClient';
import { AddUnderlying as Add } from '../pricingTemplates/components';
import FieldsList from './components/FieldsList';
import '../pricingTemplates/components/style.css';

const PREMIUM_AMOUNT = 'premiumAmount';
class DefaultButton extends React.Component {
  emptyCard = { field: '', label: '', boundaries: '' };

  constructor(props) {
    super(props);
    this.translate = this.props.translate;
    this.state = this.getDefaultState();
  }

  getDefaultState = () => ({
    open: false,
    defaultFields: [{ field: PREMIUM_AMOUNT, label: '', defaultValue: '0', boundaries: '' }],
    errors: {},
  });

  closeDialog = () => {
    this.setState({ ...this.getDefaultState() });
  };

  toArrayFields = (argFields) => {
    const fields = [];
    // eslint-disable-next-line
    for (const prop in argFields) {
      fields.push({
        field: prop,
        ...argFields[prop],
      });
    }

    const isPremium = fields.find(i => i.field === PREMIUM_AMOUNT);
    if (!isPremium) {
      fields.push(...this.getDefaultState().defaultFields);
    }

    return fields;
  }
  handleOpen = async () => {
    this.setState({ open: true });
    const { data: { fields } } = await this.getFields();
    this.setState(state => ({
      ...state,
      defaultFields: [...this.toArrayFields(fields)],
    }));
  };

  getFields = () =>
    restClient(GET_LIST, 'pricing_card_default_template', {
      sort: { field: 'fields', order: 'DESC' },
      pagination: { page: 1, perPage: 100000 },
    });


  handleSubmit = async () => {
    const { defaultFields } = this.state;
    const validationErrors = this.getValidationErrors();

    if (Object.keys(validationErrors).length) {
      return this.setState({ errors: validationErrors });
    }
    const { showNotification, refreshView } = this.props;
    const fields = defaultFields.reduce((acc, el) => {
      acc[el.field] = {
        label: el.label,
        defaultValue: el.defaultValue,
        boundaries: el.boundaries,
      };

      return acc;
    }, {});
    const body = {
      fields,
    };
    this.setState({ open: false }, () => {
      fetch(`${path}/admin/pricing_card_default_template`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Token': localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
        .then(() => {
          showNotification('Success!');
          refreshView();
          return this.setState({ ...this.getDefaultState() });
        })
        .catch(e => {
          console.error(e);
          showNotification('Error', 'warning');
        });
    });
  };

  getValidationErrors() {
    const { defaultFields } = this.state;
    const validationErrors = {};

    if (defaultFields && defaultFields.length) {
      // eslint-disable-next-line
      for (let index = 0; index < defaultFields.length; index++) {
        if (!defaultFields[index].field.trim()) validationErrors[`field_${index}`] = true;
        if (!defaultFields[index].label.trim()) validationErrors[`label_${index}`] = true;
        if (!defaultFields[index] && !defaultFields[index].defaultValue.trim()) validationErrors[`defaultValue_${index}`] = true;
        if (!defaultFields[index] && !defaultFields[index].boundaries.trim()) validationErrors[`boundaries_${index}`] = true;
      }
    }
    return validationErrors;
  }

  handleInputChange = (i, inputName) => (...event) => {
    const { defaultFields } = this.state;
    defaultFields[i][inputName] = event[1];
    this.setState({
      defaultFields,
      errors: {
        ...this.state.errors,
        [`${inputName}_${i}`]: false,
      },
    });
  }

  handleAddBox = () => {
    const emptyCard = { ...this.emptyCard };
    this.setState(state => ({
      ...state,
      defaultFields: [...state.defaultFields, emptyCard],
    }));
  };

  handleCloseBox = index => {
    const defaultFields = [...this.state.defaultFields];
    defaultFields.splice(index, 1);
    this.setState(state => ({
      ...state,
      defaultFields,
    }));
  };

  render() {
    const {
      defaultFields,
      errors,
    } = this.state;
    const premiumIndex = defaultFields.findIndex(i => i.field === PREMIUM_AMOUNT);
    const actions = [
      <RaisedButton
        label={this.translate('pricingCards.dialog.btnCancel')}
        onClick={this.closeDialog}
        icon={<CancelIcon />}
        key='1'
      />,
      <RaisedButton
        type="submit"
        style={{ marginLeft: '10px' }}
        label={this.translate('pricingCards.dialog.btnSubmit')}
        primary={true}
        icon={<SubmitIcon />}
        onClick={this.handleSubmit}
        key='2'
      />,
    ];

    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label='Default'
          onClick={this.handleOpen}
        />
        <Dialog
          title='Default card fields'
          modal={false}
          autoScrollBodyContent={true}
          open={this.state.open}
          actions={actions}
          style={{ marginTop: '-10vh' }}
        >
          <h3>Fields:</h3>
          <div className="markups-wrapper">
            {defaultFields.map((el, i) => (
              el.field !== PREMIUM_AMOUNT && <FieldsList
                key={i}
                index={i}
                translate={this.translate}
                fields={el}
                errors={errors}
                handleCloseBox={this.handleCloseBox}
                handleInputChange={this.handleInputChange}
              />
            ))}
            <div className="markup-item">
              <TextField
                errorText={errors[`field_${premiumIndex}`] && translate('aor.validation.required')}
                floatingLabelText={'Field'}
                fullWidth
                name="field"
                disabled
                type="string"
                value={defaultFields[premiumIndex].field}
                onChange={this.handleInputChange(premiumIndex, 'field')}
              />
              <TextField
                errorText={errors[`label_${premiumIndex}`] && translate('aor.validation.required')}
                floatingLabelText={'Label'}
                fullWidth
                name="label"
                type="string"
                value={defaultFields[premiumIndex].label}
                onChange={this.handleInputChange(premiumIndex, 'label')}
              />
              <TextField
                errorText={errors[`defaultValue_${premiumIndex}`] && translate('aor.validation.required')}
                floatingLabelText={'Default value'}
                fullWidth
                name="defaultValue"
                type="string"
                value={defaultFields[premiumIndex].defaultValue}
                onChange={this.handleInputChange(premiumIndex, 'defaultValue')}
              />
              <TextField
                errorText={errors[`boundaries_${premiumIndex}`] && translate('aor.validation.required')}
                floatingLabelText={'Boundaries'}
                fullWidth
                name="boundaries"
                type="string"
                value={defaultFields[premiumIndex].boundaries}
                onChange={this.handleInputChange(premiumIndex, 'boundaries')}
              />
            </div>
          </div>
          <Add onClick={this.handleAddBox} text={'Add Field'} />
        </Dialog>
      </div>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(DefaultButton);
