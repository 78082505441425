import { Menu, Notification, setSidebarVisibility, userLogout } from 'admin-on-rest';
import capitalize from 'lodash/capitalize';
import CircularProgress from 'material-ui/CircularProgress';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import PropTypes from 'prop-types';
import React, { Component, createElement } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import AppBar from '../AppBar';
import ErrorBoundary from '../components/common/ErrorBoundary/';
import Seo from '../components/common/Seo';
import Sidebar from '../components/layout/Sidebar';
import NotificationCenter from '../notificationCenter';
import { agileTheme } from '../theme/agile';
import PATH from '../path';
import { currentAdminReceived as currentAdminReceivedAction } from './reducer';

const styles = {
  wrapper: {
    // Avoid IE bug with Flexbox, see #467
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    position: 'fixed',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  body: {
    backgroundColor: '#edecec',
    display: 'flex',
    flex: 1,
    paddingTop: '64px',
  },
  content: {
    flex: 1,
    padding: '2em',
  },
  loader: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 16,
    zIndex: 1200,
  },
};

/**
 * Create title from children key and page path
*/

export const formatTitle = (str = '') => {
  const words = str.split('_') || [];
  return words.map(word => capitalize(word)).join(' ');
};

const getPageTitleFromList = ({ props }, path = '') => {
  const pathname = path.split('/')[1];
  const items = Array.isArray(props.children) && props.children[1] ? props.children[1] : [];
  const find = items.find(({ key }) => key === pathname);
  const title = find && find.key ? formatTitle(find.key) : null;
  return title;
};

class AgileLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenSidebar: true,
    };
    this.toggleSidebar = this._toggleSidebar.bind(this);
    this.init();
  }

  init() {
    const { isOpenSidebar } = this.state;
    this.initInterval();
    this.props.setSidebarVisibility(isOpenSidebar);
  }

  async componentDidMount() {
    const { currentAdminReceived } = this.props;
    const admin = await fetch(`${PATH}/admin/admins/current`, {
      method: 'GET',
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(response => response.json());
    currentAdminReceived(admin);
  }

  async componentWillUnmount() {
    this.clearInterval();
  }

  initInterval = () => {
    this.interval = setInterval(() => {
      if (Number(localStorage.getItem('ex')) <= Math.floor(Date.now() / 1000)) {
        this.props.userLogout();
      }
    }, 2000);
  }

  clearInterval = () => {
    clearInterval(this.interval);
  }

  _toggleSidebar() {
    const { isOpenSidebar } = this.state;
    this.setState({
      isOpenSidebar: !isOpenSidebar,
    });
  }

  render() {
    const { isOpenSidebar } = this.state;
    const {
      children,
      dashboard,
      isLoading,
      logout,
      menu,
      title,
      theme,
      location,
    } = this.props;
    const sidebarWidth = isOpenSidebar ? agileTheme.drawer.width : 0;
    const stylesBody = {
      ...styles.body,
      paddingLeft: `${sidebarWidth}px`,
    };
    return (
      <MuiThemeProvider muiTheme={theme}>
        <ErrorBoundary>
          <div style={styles.wrapper}>
            <Seo title={getPageTitleFromList(children, location.pathname) || ''} />
            <div style={styles.main}>
              <AppBar title={title} onToggle={this.toggleSidebar} style={styles.appBar} />
              <div className="body" style={stylesBody}>
                <div style={styles.content}>
                  {children}
                </div>
                <Sidebar onClose={this.toggleSidebar}>
                  {createElement(menu || Menu, {
                    logout,
                    hasDashboard: !!dashboard,
                  })}
                </Sidebar>
              </div>
              <Notification />
              {isLoading && (
                <CircularProgress
                  color="#fff"
                  size={30}
                  thickness={2}
                  style={styles.loader}
                />
              )}
              <NotificationCenter />
            </div>
          </div>
        </ErrorBoundary>
      </MuiThemeProvider>
    );
  }
}

AgileLayout.propTypes = {
  authClient: PropTypes.func,
  customRoutes: PropTypes.array,
  dashboard: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isLoading: PropTypes.bool.isRequired,
  menu: PropTypes.func,
  resources: PropTypes.array,
  setSidebarVisibility: PropTypes.func.isRequired,
  userLogout: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });
const mapDispatchToProps = { setSidebarVisibility, userLogout, currentAdminReceived: currentAdminReceivedAction };
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AgileLayout);
