import React from 'react';
import {
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextInput,
  SelectInput,
  SelectArrayInput,
  AutocompleteInput,
  ReferenceInput,
} from 'admin-on-rest';

import AutoComplete from 'material-ui/AutoComplete';

import {
  validateCode,
  validateMaxLengthCode,
  validateMaxLengthName,
  validateMinLengthCode,
  validateMinLengthName,
} from '../../utils/validations';

const Form = ({ toolbar, ...rest }) => (
  <SimpleForm toolbar={toolbar} {...rest}>
    <TextInput
      label="userGroup.code"
      source="code"
      type="code"
      validate={[required, validateMinLengthCode, validateMaxLengthCode, validateCode]}
      elStyle={{ width: '50%' }}
    />
    <TextInput
      label="userGroup.name"
      source="name"
      type="name"
      validate={[required, validateMinLengthName, validateMaxLengthName]}
      elStyle={{ width: '100%' }}
    />
    <ReferenceArrayInput
      key="clients"
      source="clients"
      reference="client_database"
      label="userGroup.clients"
    >
      <SelectArrayInput
        optionText={r => `${r.client} ${r.client_code}`}
        optionValue="id"
        elStyle={{ width: '50%' }}
        options={{
          listStyle: { maxHeight: '30vh', overflow: 'auto' },
          onBlur: () => {},
          fullWidth: true,
        }}
      />
    </ReferenceArrayInput>
    <ReferenceInput
      key="themeId"
      source="themeId"
      reference="themes"
      label="userGroup.theme"
      allowEmpty
      sort={{ field: 'name', order: 'ASC' }}
      filterToQuery={searchText => ({ _q: searchText })}
    >
      <AutocompleteInput
        optionText={r => `${r.name}`}
        optionValue="id"
        elStyle={{ width: '50%' }}
        options={{
          listStyle: { maxHeight: '30vh', overflow: 'auto' },
          onBlur: () => {},
          fullWidth: true,
          filter: AutoComplete.levenshteinDistance,
        }}
      />
    </ReferenceInput>

    <ReferenceInput
      filter="noFilter"
      key="spreadsheetId"
      source="spreadsheetId"
      reference="spreadsheets"
      label="userGroup.spreadSheet"
      allowEmpty
    >
      <SelectInput
        optionText="title"
        optionValue="id"
        elStyle={{ width: '50%' }}
        options={{
          listStyle: { maxHeight: '30vh', overflow: 'auto' },
          onBlur: () => {},
        }}
      />
    </ReferenceInput>
  </SimpleForm>
);

export default Form;
