import { GET_HISTORY_DATA, RESPONSE_HISTORY_DATA } from './actions';

const defaultState = {
  loading: true,
  title: '',
  general: {},
  logs: [],
};

export default (previousState = defaultState, { type, payload }) => {
  switch (type) {
  case GET_HISTORY_DATA:
    return {
      ...previousState,
      loading: true,
    };
  case RESPONSE_HISTORY_DATA:
    return {
      ...previousState,
      ...payload,
      loading: false,
    };
  default:
    return previousState;
  }
};
