
import React, { Component } from 'react';
import ChipInput from 'material-ui-chip-input';

class SelectArrayInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: [],
    };
  }

  componentWillMount() {
    const values = this.props.choices.filter(c => (this.props.input.value.indexOf(c.id) > -1));
    this.setState({ values });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.choices.length !== nextProps.choices.length ||
      this.props.input.value.length !== nextProps.input.value.length
    ) {
      const values = nextProps.choices.filter(c => (nextProps.input.value.indexOf(c.id) > -1));
      this.setState({ ...values });
    }
  }

  handleBlur() {
    const ids = this.state.values.map(i => i.id);
    this.props.input.onBlur(ids);
  }

  handleFocus() {
    const ids = this.state.values.map(i => i.id);
    this.props.input.onFocus(ids);
  }

  handleAdd(chip) {
    const values = [...this.state.values, chip];
    const ids = values.map(i => i.id);
    this.setState({ values });
    this.props.input.onChange(ids);
  }

  handleDelete(chip) {
    const values = this.state.values.filter(c => c.id !== chip);
    const ids = values.map(i => i.id);
    this.setState({ values });
    this.props.input.onChange(ids);
  }

  handleBeforeAdd(chip) {
    const chips = this.props.choices.filter(c => (chip.id && c.id === chip.id));
    return chips.length;
  }

  render() {
    const { input, choices, label, optionText, optionValue } = this.props;
    return (<ChipInput {...input}
      value={this.state.values}
      dataSource={choices}
      dataSourceConfig={{ text: optionText, value: optionValue }}
      openOnFocus={true}
      onBeforeRequestAdd={this.handleBeforeAdd.bind(this)}
      onRequestAdd={this.handleAdd.bind(this)}
      onRequestDelete={this.handleDelete.bind(this)}
      onBlur={this.handleBlur.bind(this)}
      onFocus={this.handleFocus.bind(this)}
      onClick={this.handleFocus.bind(this)}
      floatingLabelText={label}
      fullWidth
    />);
  }
}

export default SelectArrayInput;
