import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import { Table, TableHeader, TableHeaderColumn, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';
import { translate, showNotification as showNotificationAction, GET_LIST } from 'admin-on-rest';
import CheckIcon from 'material-ui/svg-icons/action/check-circle';
import restClient from '../restClient';
import path from '../path';

require('./style.css');

const DeleteButton = props => (
  <div className="delete">
    <DeleteIcon color="red" onClick={props.onClick} />
  </div>
);

class CheckButton extends React.Component {
  defaultState = {
    open: false,
    data: [],
  }
  constructor(props) {
    super(props);

    this.translate = this.props.translate;

    this.state = this.defaultState;
  }

  deleteHandler = name => () => {
    const { data } = this.state;
    this.setState({
      ...this.state,
      data: data.filter(d => d.name !== name),
    });
  }

  handleSubmit = () => {
    const { showNotification } = this.props;
    const { data } = this.state;
    this.setState({ open: false }, () => {
      fetch(`${path}/admin/dashboards/sendCheckStatements`, {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: {
          'Token': localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
        .then((response) => {
          if (response.status === 200) return response;
          throw new Error('Request failed');
        })
        .then(() => {
          showNotification('checkStatements.send.success');
          this.setState({ disabled: false });
        })
        .catch((e) => {
          console.error(e);
          showNotification('checkStatements.send.fail');
          this.setState({ disabled: false });
        });
    });
  }

  getData = () =>
    restClient(GET_LIST, 'dashboards/checkStatementsData', {
      sort: {
        field: 'email',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    });


  handleOpen = async () => {
    const [{ data }] = await Promise.all([
      this.getData(),
    ]);

    this.setState({ ...this.state, open: true, data });
  }

  closeDialog = () => {
    this.setState({ ...this.defaultState });
  }

  render() {
    const { open, data } = this.state;
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label={this.translate('dashboards.list.checkButton')}
          icon={<CheckIcon />}
          onClick={this.handleOpen}
        />
        <Dialog
          title={this.translate('checkStatements.send.title')}
          modal={false}
          autoScrollBodyContent={true}
          open={open}
        >
          <Table
            selectable={false}
            multiSelectable={false}
          >
            <TableHeader
              displaySelectAll={false}
              adjustForCheckbox={false}
            >
              <TableRow>
                <TableHeaderColumn>Sales Teams</TableHeaderColumn>
                <TableHeaderColumn style={{ width: '60%' }}>Dashboards</TableHeaderColumn>
                <TableHeaderColumn></TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody
              displayRowCheckbox={false}
            >
              {data.map((row, index) =>
                (
                  <TableRow key={index}>
                    <TableRowColumn>
                      {row.name}
                    </TableRowColumn>
                    <TableRowColumn style={{ width: '60%', whiteSpace: 'normal', paddingTop: '8px', paddingBottom: '8px' }}>{row.dashboards.join(', ')}</TableRowColumn>
                    <TableRowColumn>
                      <DeleteButton onClick={this.deleteHandler(row.name)} />
                    </TableRowColumn>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
          <div style={{ paddingTop: '15px', float: 'right' }} >
            <RaisedButton
              label={this.translate('pdfs.dialog.btnCancel')}
              onClick={this.closeDialog}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label={this.translate('pdfs.dialog.btnSubmit')}
              primary={true}
              icon={<SubmitIcon />}
              onClick={this.handleSubmit}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

CheckButton.propTypes = {
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
  }),
);

export default enhance(CheckButton);
