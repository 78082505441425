import React from 'react';
import { connect } from 'react-redux';
import {
  DeleteButton,
  Responsive,
  RichTextField,
  refreshView,
  List,
  RefreshButton,
  Filter,
  TextInput,
} from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import CreateEditButton from '../SpCommonComponents/CreateEditButton';
import DownloadRulesJson from '../SpCommonComponents/DownloadRulesJson';
import DownloadOnePagerJson from './DownloadOnePagerJson';
import DownloadFieldValidationRules from '../SpCommonComponents/DownloadFieldValidationRules';
import SpOrderingItems from '../SpCommonComponents/SpOrderingItems/SpOrderingItems';
import GenerateButton from '../SpCommonComponents/GenerateButton';
import DownloadLayoutJson from '../SpCommonComponents/DownloadLayoutJson';
import SpDataGripWrapper from '../SpDataGripWrapper/SpDataGripWrapper';
import ProductRepoButton from '../SpCommonComponents/ProductRepoButton/ProductRepoButton';
import ReviewButton from '../SpCommonComponents/ReviewButton';
import CheckForUpdatesButton from 'spScreenConfigurator/SpCommonComponents/CheckForUpdatesButton';

const API_SOURCE = 'sp_layouts_config';

const Actions = () => (
  <CardActions>
    <GenerateButton
      apiSource={API_SOURCE}
      fieldName="payoff"
      definitionApiSource="getAllPayoffDefinitions"
      floatingLabelText="Payoff"
    />
    <SpOrderingItems apiSource={API_SOURCE} fieldName="structureName" />
    <RefreshButton />
    <CreateEditButton
      apiSource={API_SOURCE}
      defaultFilename="sp_layouts_config.json"
      configurablePrefix="configurableSpLayouts"
      fieldName="structureName"
    />
  </CardActions>
);

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="configurableSp.search" source="_q" alwaysOn />
  </Filter>
);

const ButtonsWrapper = (props) => (
  <div style={{ width: '750px' }}>
    <CreateEditButton
      {...props}
      edit={true}
      apiSource={API_SOURCE}
      defaultFilename="sp_layouts_config.json"
      configurablePrefix="configurableSpLayouts"
      fieldName="structureName"
    />
    <ProductRepoButton {...props} />
    <CheckForUpdatesButton
      {...props}
      apiSource={API_SOURCE}
      apiSourcePrefix='/getPayoffUpdateByURI/'
    />
    <ReviewButton {...props} apiSource={API_SOURCE} fieldName="payoff" />
    <DeleteButton {...props} />
  </div>
);

const SpLayoutsConfigList = (props) => {
  return (
    <List
      {...props}
      title="SP Layouts Configurator"
      filters={<Filters />}
      sort={{ field: 'ordering_index', order: 'ASC' }}
      perPage={50}
      refreshView={true}
      actions={<Actions />}
    >
      <Responsive
        medium={
          <SpDataGripWrapper buttonComponent={ButtonsWrapper}>
            <RichTextField label="structure" source="structureName" />
            <RichTextField label="Payoff name" source="payoffName" />
            <RichTextField label="Payoff Version" source="payoffVersion" />
            <DownloadLayoutJson defaultFileName="sp_layout.json" />
            <DownloadRulesJson />
            <DownloadOnePagerJson />
            <DownloadFieldValidationRules />
          </SpDataGripWrapper>
        }
      />
    </List>
  );
};

export default connect(null, {
  refreshView,
})(SpLayoutsConfigList);
