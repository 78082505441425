import React, { cloneElement } from 'react';
import { CardActions } from 'material-ui/Card';
import { RefreshButton } from 'admin-on-rest';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const MinifutureProductsActions = (props) => {
  const { resource, filters, displayedFilters, filterValues, showFilter } = props;

  return (
    <CardActions style={cardActionStyle}>
      {filters && cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' })}
      <RefreshButton />
    </CardActions>
  )
};

export default MinifutureProductsActions;
