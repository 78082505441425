import { Datagrid, DeleteButton, EditButton, Filter, List, NumberInput, RefreshButton, refreshView as refreshViewAction, showNotification as showNotificationAction, TextField, TextInput, translate } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import CircularProgress from 'material-ui/CircularProgress';
import FlatButton from 'material-ui/FlatButton';
import GetMarginCallIcon from 'material-ui/svg-icons/image/nature';
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { DateField, Text } from '../helpers';
import path from '../path';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';
import NotifyButton from './Notify';

const loaderStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  position: 'fixed',
  zIndex: 1,
  left: '0',
  top: 0,
  backgroundColor: 'rgba(255,255,255, 0.8)',
  overflowX: 'hidden',
  transition: '0.5s',
};

const MarginCallsFilter = props => (
  <Filter {...props}>
    <TextInput
      label="Search (dashboard name)"
      source="_q"
      alwaysOn
      options={{ style: { width: '300px' } }}
    />
    <NumberInput source="id" />
  </Filter>
);

const PostActions = ({ resource, filters, displayedFilters, filterValues, showFilter }) => (
  <CardActions>
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    <RefreshButton />

  </CardActions>
);

const MarginCallButton = ({ onClick, ...props }) => {
  const id = props.record.id;
  return (
    <div source ="margin_call">
      <FlatButton
        icon={<GetMarginCallIcon />}
        onClick={onClick(id)}
        label="Update"
      />
    </div>
  );
};

class MarginCallsList extends React.Component {
  state = {
    load: false,
  }

  getMarginCall = id => () => {
    const { showNotification, refreshView } = this.props;
    this.setState({ load: true }, () => {
      fetch(`${path}/admin/margin_calls/margin_call/${id}`, { method: 'PUT',
        headers: {
          'Token': localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        } })
        .then((response) => {
          if (response.status === 200) return response;
          throw new Error('Request failed');
        })
        .then(() => {
          showNotification('marginCall.list.marginCallUpdated');
          refreshView();
          return this.setState({ load: false });
        })
        .catch((e) => {
          console.error(e);
          this.setState({ load: false });
          showNotification('marginCall.list.marginCallFailed');
        });
    });
  }

  notify = id => () => {
    const { showNotification, refreshView } = this.props;
    this.setState({ open: false }, () => {
      fetch(`${path}/admin/margin_calls/notify/${id}`, { method: 'PUT',
        headers: {
          'Token': localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        } })
        .then(() => {
          showNotification('Sales team created');
          refreshView();
          return this.setState({ ...this.defaultState });
        })

        .catch((e) => {
          console.error(e);
          showNotification('Error', 'warning');
        });
    });
  }

  isLoad() {
    return this.state.load;
  }

  render() {
    return (
      <div>
        {this.isLoad()
          ? <div style={loaderStyle}><CircularProgress size={80} thickness={5} style={{ right: '50%', position: 'absolute' }}/></div>
          : null
        }
        <List {...this.props}
          filters={<MarginCallsFilter />}
          sort={{ field: 'id', order: 'DESC' }}
          title="Margin calls list"
          perPage={50}
          actions={<PostActions />}
        >
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
            <TextField source="id" />
            <TextField source="dashboardName" />
            <Text source="margin_call" />
            <Text source="send_value" />
            <TextField source="currency" />
            <DateField
              formatDate={formatDateTimeInput}
              source="updated_at"
              showTime
            />
            <MarginCallButton onClick={this.getMarginCall} />
            <NotifyButton onClick={this.notify}/>
            <EditButton />
            <DeleteButton />
          </Datagrid>
        </List>
      </div>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    refreshView: refreshViewAction,
    showNotification: showNotificationAction,
  }),
);

export default enhance(MarginCallsList);

