/* eslint-disable */
import React, { Fragment, useEffect } from 'react';
import { required, number } from 'admin-on-rest';
import { formValueSelector, change, FieldArray, arrayPush } from 'redux-form';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Table, TableBody, TableRow, TableRowColumn, TableHeader, TableHeaderColumn } from 'material-ui/Table';
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar';
import DeleteIcon from 'material-ui/svg-icons/content/remove-circle';
import AddIcon from 'material-ui/svg-icons/content/add-circle';
import RaisedButton from 'material-ui/RaisedButton';
import {
  RECORD_FORM,
  DIRECTION,
  INPUT_PROPS,
  TableInput,
  notionalValidation,
  roundMargin,
  FIELD_NOTIONAL_USD,
  NOTIONAL_FIELD_ARRAY,
} from './helpers';

const renderNotionals = ({ fields }) => {
  const removeHandler = i => fields.remove(i);
  return (
    <Fragment>
      {fields.map((item, index) => {
        const isLast = index === fields.length - 1;
        return (
          <TableRow style={{ backgroundColor: isLast ? '#f9f9f9' : 'none' }}>
            <TableInput
              source={`${item}.direction`}
              rowStyle={{ width: '50px' }}
              disabled={true}
              type="text"
              inputOptions={{
                ...INPUT_PROPS,
              }}
            />
            <TableInput
              placeholder="Notional USD"
              source={`${item}.${FIELD_NOTIONAL_USD}`}
              validate={[required, number, notionalValidation]}
              disabled={isLast}
              type="number"
              normalize={roundMargin}
              inputOptions={{ min: 0 }}
            />
            <TableInput
              placeholder="SC Adjustment %"
              source={`${item}.marginValue`}
              normalize={roundMargin}
              validate={[required, number]}
              type="number"
            />
            <TableRowColumn>
              {!isLast ? (
                <RaisedButton
                  label="Remove Notional"
                  secondary={true}
                  icon={<DeleteIcon />}
                  onClick={() => removeHandler(index)}
                />
              ) : null}
            </TableRowColumn>
          </TableRow>
        );
      })}
    </Fragment>
  );
};

const NotionalMarginControl = props => {
  const { arrayPushAction, record } = props;
  const addFields = value => arrayPushAction(RECORD_FORM, NOTIONAL_FIELD_ARRAY, value);

  useEffect(() => {
    if (record && record.notional && record.notional.length) return;
    addFields({
      direction: DIRECTION.GREATER_OR_EQUAL,
      notionalUsd: 0,
    });
  }, []);

  const addElement = () => {
    const lastIdx = props.notional.length-1;
    const last = props.notional[lastIdx];
    addFields({
      notionalUsd: last && last.notionalUsd,
      marginValue: last && last.marginValue,
      direction: DIRECTION.GREATER_OR_EQUAL,
    });
    props.change(RECORD_FORM, `${NOTIONAL_FIELD_ARRAY}[${lastIdx}]`, {direction: DIRECTION.LESS,});
  };

  return (
    <Fragment>
      <Toolbar>
        <ToolbarGroup>
          <ToolbarTitle text="Notional Size" />
        </ToolbarGroup>
        <ToolbarGroup>
          <ToolbarSeparator />
          <RaisedButton label="Add Notional" secondary={true} icon={<AddIcon />} onClick={() => addElement()} />
        </ToolbarGroup>
      </Toolbar>
      <Table selectable={false}>
        <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
          <TableRow>
            <TableHeaderColumn style={{ width: '50px' }}>
              <b>Direction</b>
            </TableHeaderColumn>
            <TableHeaderColumn>
              <b>Notional USD</b>
            </TableHeaderColumn>
            <TableHeaderColumn>
              <b>SC Adjustment %</b>
            </TableHeaderColumn>
            <TableHeaderColumn></TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          <FieldArray name={NOTIONAL_FIELD_ARRAY} component={renderNotionals} />
        </TableBody>
      </Table>
    </Fragment>
  );
};

const selector = formValueSelector(RECORD_FORM);
const enhance = compose(
  connect((state, { source }) => {
    const obj = {
      notional: selector(state, 'notional'),
      data: selector(state, source),
    };
    return obj;
  }, { change, arrayPushAction: arrayPush }),
);
export default enhance(NotionalMarginControl);
