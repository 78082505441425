import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { showNotification } from 'admin-on-rest';

import {
  PDF_SEND_SUCCESS,
  PDF_SEND_FAILURE,
  PDF_SEND_MANY_SUCCESS,
  PDF_SEND_MANY_FAILURE,
  PDF_GENERATE_SUCCESS,
  PDF_GENERATE_FAILURE,
  PDF_GROUP_GENERATE_SUCCESS,
  PDF_GROUP_GENERATE_FAILURE,
} from './PDFAction';

export default function* reviewSaga() {
  yield [
    takeEvery(PDF_SEND_SUCCESS, function* () {
      yield put(showNotification('dashboards.notification.send_success'));
      yield put(push('/'));
      yield put(push('/dashboards'));
    }),
    takeEvery(PDF_SEND_FAILURE, function* ({ error }) {
      yield put(showNotification('dashboards.notification.send_error', 'warning'));
      console.error(error);
    }),
    takeEvery(PDF_SEND_MANY_SUCCESS, function* () {
      yield put(showNotification('dashboards.notification.send_success'));
      yield put(push('/'));
      yield put(push('/dashboards'));
    }),
    takeEvery(PDF_SEND_MANY_FAILURE, function* ({ error }) {
      yield put(showNotification('dashboards.notification.send_error', 'warning'));
      console.error(error);
    }),
    takeEvery(PDF_GENERATE_SUCCESS, function* () {
      yield put(showNotification('dashboards.notification.generate_success'));
      yield put(push('/'));
      yield put(push('/dashboards'));
    }),
    takeEvery(PDF_GENERATE_FAILURE, function* ({ error }) {
      yield put(showNotification('dashboards.notification.generate_error', 'warning'));
      console.error(error);

      yield put(push('/'));
      yield put(push('/dashboards'));
    }),
    takeEvery(PDF_GROUP_GENERATE_SUCCESS, function* () {
      yield put(showNotification('dashboards.notification.generate_group_success'));
      yield put(push('/'));
      yield put(push('/dashboards'));
    }),
    takeEvery(PDF_GROUP_GENERATE_FAILURE, function* ({ error }) {
      yield put(showNotification('dashboards.notification.generate_group_error', 'warning'));
      console.error(error);

      yield put(push('/'));
      yield put(push('/dashboards'));
    }),
  ];
}
