import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { showNotification } from 'admin-on-rest';

export default function* reviewSaga() {
  yield [
    takeLatest('@@router/LOCATION_CHANGE', function* ({ payload }) {
      const { pathname } = payload;
      if (pathname === '/admin_change_password') yield put(push('admins'));
    }),
    takeLatest('AOR/CRUD_GET_ONE_FAILURE', function* ({ error, requestPayload }) {
      const { basePath } = requestPayload;
      if (basePath === '/admin_change_password' && error) yield put(showNotification(error, 'warning'));
    }),
  ];
}
