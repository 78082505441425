import React from 'react';
import { Toolbar, SaveButton } from 'admin-on-rest';

const UserGroupToolbar = props => (
  <Toolbar {...props}>
    <SaveButton redirect='list' submitOnEnter={true} />
  </Toolbar>
);

export default UserGroupToolbar;
