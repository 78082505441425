import React, { Fragment } from 'react';
import FlatButton from 'material-ui/FlatButton';
import { TextField } from 'material-ui';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';
import { grey50 } from 'material-ui/styles/colors';
import path from '../../path';

const adminPath = `${path}/cqg`;

const buttonStyle = { minWidth: '30px', padding: '0 15px' };

class ProductTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: props.refresh,
      userproduct: '',
      usercontract: '',
      subscribedContracts: [],
      sortedProducts: [],
      info: [],
    };
  }

  setDataToState(row) {
    const { sortedProducts, subscribedContracts, info } = this.getSubscribedData(row);
    this.setState((prevState) => {
      const state = { ...prevState, sortedProducts, info };
      if (JSON.stringify(state.subscribedContracts) !== JSON.stringify(subscribedContracts)) {
        state.subscribedContracts = subscribedContracts;
      }

      return state;
    });
  }

  componentWillMount() {
    this.setDataToState(this.props.row);
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.row) !== JSON.stringify(nextProps.row)) {
      this.setDataToState(nextProps.row);
    }
  }

  doForceSubUnsub = (p, c, a) => {
    if (p === '' || c === '') return;
    fetch(`${adminPath}/forceSubUnsub`, {
      method: 'POST',
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        hostName: this.props.row.name,
        productCode: p,
        contractExpiry: c,
        action: a,
      }),
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        // We should have subbed/unsubbed within 1 second - if not manually hit refresh
        setTimeout(() => this.state.refresh(), 1000);
      })
      .catch(e => {
        console.log(e);
        setTimeout(() => this.state.refresh(), 1000);
      });
  };

  doContractLogging = (c) => {
    fetch(`${adminPath}/contractExpiryLog`, {
      method: 'PUT',
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        hostName: this.props.row.name,
        contractExpiry: c,
      }),
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  getSubscribedData(row) {
    const subscribedContracts = [];
    if (!row || !row.products) {
      return {
        subscribedContracts,
        sortedProducts: [],
        info: [],
      };
    }
    const { products, info } = row;
    const sortedProducts = products.sort((a, b) => a.symbol > b.symbol ? 1 : -1);
    if (sortedProducts.length) {
      /* eslint-disable */
      for (const product of sortedProducts) {
        if (product && product.registeredContracts) {
          const subbed = product.registeredContracts.filter((c) => c.subscriptionStatus == 'Connected').sort((a, b) => a.contractSymbol > b.contractSymbol ? 1 : -1);
          for (const { contractSymbol = 'NONE' } of subbed) {
            subscribedContracts.push(contractSymbol);
          }
        }
      }
      /* eslint-enable */
    }

    if (!subscribedContracts.length) {
      subscribedContracts.push('NONE');
    }

    return {
      subscribedContracts,
      sortedProducts,
      info,
    };
  }

  render() {
    const {
      sortedProducts,
      subscribedContracts,
      userproduct,
      usercontract,
      info,
    } = this.state;

    if (!sortedProducts.length) return 'No Items';
    return (
      <Fragment>
        {
          // Additional information per instance
          Array.isArray(info) ? info.map((x, i) => <div key={i}><TextField value={x} disabled={true}/></div>) : null
        }
        <Table fixedHeader={false} selectable={false} style={{ backgroundColor: grey50, width: 'auto' }}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableHeaderColumn>Commodity</TableHeaderColumn>
              <TableHeaderColumn>Is Active</TableHeaderColumn>
              <TableHeaderColumn>Registered Contracts</TableHeaderColumn>
              <TableHeaderColumn>Connected Contracts</TableHeaderColumn>
              <TableHeaderColumn>Current Updates</TableHeaderColumn>
              <TableHeaderColumn>Max Updates</TableHeaderColumn>
              <TableHeaderColumn>Avg Updates</TableHeaderColumn>
              <TableHeaderColumn>Latency</TableHeaderColumn>
              <TableHeaderColumn>Avg Latency</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {sortedProducts.map((x, i) => (
              <TableRow key={i}>
                <TableRowColumn><div onClick={() => this.setState({
                  ...this.state,
                  userproduct: x.symbol,
                  usercontract: x.symbol,
                })}>{x.symbol}</div></TableRowColumn>
                <TableRowColumn>{x.isActive.toString()}</TableRowColumn>
                <TableRowColumn>{x.registeredContracts ? x.registeredContracts.length : 0}</TableRowColumn>
                <TableRowColumn>{x.connectedContracts}</TableRowColumn>
                <TableRowColumn>{x.currentUpdates}</TableRowColumn>
                <TableRowColumn>{x.maxUpdates}</TableRowColumn>
                <TableRowColumn>{x.avgUpdates}</TableRowColumn>
                <TableRowColumn>{x.currentLatency}</TableRowColumn>
                <TableRowColumn>{x.avgLatency}</TableRowColumn>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <h3>CURRENT PROXY SUBSCRIPTIONS</h3>
        <div>
          {
            subscribedContracts.join('\n')
          }
        </div>
        <h3>SUPPORT TOOLS (ONLY FOR DEV INVESTIGATIONS)</h3>
        <div>Product :
          <TextField
            id='product'
            label='Product'
            value={userproduct}
            onChange={(e) => {
              this.setState({ ...this.state, userproduct: e.target.value });
            }}/>
        </div>
        <div>Contract :
          <TextField
            id='contract'
            label='Contract'
            value={usercontract}
            onChange={(e) => {
              this.setState({ ...this.state, usercontract: e.target.value });
            }}/>
        </div>
        <div>
          <FlatButton
            label="SUBSCRIBE"
            onClick={this.doForceSubUnsub.bind(this, userproduct, usercontract, 1)}
            style={buttonStyle}
          />
          <FlatButton
            label="UNSUBSCRIBE"
            onClick={this.doForceSubUnsub.bind(this, userproduct, usercontract, 2)}
            style={buttonStyle}
          />
          <FlatButton
            label="ENABLE CONTRACT PRICE LOGGING"
            onClick={this.doContractLogging.bind(this, usercontract)}
            style={buttonStyle}
          />
          <FlatButton
            label="DISABLE CONTRACT PRICE LOGGING"
            onClick={this.doContractLogging.bind(this, '')}
            style={buttonStyle}
          />
        </div>
      </Fragment>
    );
  }
}

export default ProductTable;
