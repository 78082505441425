import React from 'react';
import TextField from 'material-ui/TextField';
import Divider from 'material-ui/Divider';
import { sortLogs } from './../helpers';

const style = {
  root: {
    margin: '0 15px',
    cursor: 'unset',
    width: 'auto',
  },
  floatingLabelStyle: {
    color: 'rgb(0, 188, 212)',
  },
  inputStyle: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  underlineStyle: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderColor: 'rgb(0, 188, 212)',
  },
  jsonBox: {
    gridColumn: '1/-1',
  },
  jsonTitle: {
    margin: '0 15px',
  },
  divider: {
    marginTop: '30px',
    marginBottom: '25px',
    marginLeft: '72px',
    marginRight: '72px',
  },
};

const getMapValue = (value) => {
  if (value === true) return 'TRUE';
  if (value === false) return 'FALSE';
  if (value === 'true') return 'TRUE';
  if (value === 'false') return 'FALSE';
  if (value === null) return 'NULL';
  return value;
};

const Field = ({ label, value }) => {
  if (value && typeof value === 'object') {
    const { normalFields, objectFields } = sortLogs(value);
    return (
      <div style={style.jsonBox}>
        <Divider style={style.divider}/>
        <h4 style={style.jsonTitle}>{label}</h4>
        {normalFields.map(([l, v]) => (<Field key={l} label={l} value={v}/>))}
        {objectFields.map(([l, v]) => (<Field key={l} label={l} value={v}/>))}
      </div>
    );
  }

  const defaultValue = getMapValue(value);
  return (
    <TextField
      id="text-field-default"
      defaultValue={defaultValue}
      floatingLabelText={label}
      disabled={true}
      style={style.root}
      floatingLabelStyle={style.floatingLabelStyle}
      inputStyle={style.inputStyle}
      underlineDisabledStyle={style.underlineStyle}
    />
  );
};

export default Field;
