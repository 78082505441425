import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  Datagrid,
  List,
  refreshView,
  Responsive,
  SimpleList,
  TextField,
  EditButton,
  DeleteButton,
  translate,
  FunctionField,
} from 'admin-on-rest';
import Seo from '../components/common/Seo';
import { DateField } from '../helpers';
import { FxCommodityFilters } from './components';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';
import Toogles from '../components/Toggles';

import { FxUpdateStatus, FxUpdateDeliverable } from './action';
import CommodityActions from '../commodityContracts/CommodityActions';


const FxCommodityList = props => (
  <Fragment>
    <Seo title={'FX Contracts'} />
    <List {...props}
      sort={{ field: 'fx_code', order: 'DESC' }}
      actions={<CommodityActions {...props} />}
      filters={<FxCommodityFilters />}
      perPage={25}
      refreshView={true}
      title='FX Contracts'
    >
      <Responsive
        small={
          <SimpleList
            primaryText={r => r.fx_code}
            secondaryText={r => r.fx_code}
          />
        }
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
            <TextField source="id" />
            <TextField source="fx_code" />
            <TextField source="agile_name" />
            <TextField source="base_currency" />
            <TextField source="term_currency" />
            <FunctionField
              label="deliverable"
              render={r => <Toogles changeActive={props.changeDeliverable} status={r.deliverable} id={r.id} />}
            />
            <TextField source="base_currency_qty" />
            <TextField source="spread" />
            <FunctionField source="markup_spread" render={r => <p style={{ padding: '0', margin: '0', fontSize: '12px' }}
              dangerouslySetInnerHTML={{
                __html: `${JSON.stringify(r.markup_spread)}`,
              }}></p>} />
            <FunctionField
              label="Inactive/Active"
              render={r => <Toogles changeActive={props.changeActive} status={r.status} id={r.id} />}
            />
            <TextField source="price_digits_convention" />
            <DateField formatDate={formatDateTimeInput} source="updated_at" showTime />
            <DateField formatDate={formatDateTimeInput} source="created_at" showTime />
            <EditButton />
            <DeleteButton />
          </Datagrid>
        }
      />
    </List>
  </Fragment>
);

const enhance = compose(
  translate,
  connect(null, {
    refreshView,
    changeActive: FxUpdateStatus,
    changeDeliverable: FxUpdateDeliverable,
  }),
);

export default enhance(FxCommodityList);
