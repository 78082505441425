import { jsonServerRestClient, fetchUtils } from 'admin-on-rest';

import path from './path';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('Token', localStorage.getItem('session'));
  return fetchUtils.fetchJson(url, options);
};

export default jsonServerRestClient(`${path}/admin`, httpClient);
