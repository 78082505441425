import {
  COLUMN_VERSION_SPP,
  NO_TOGGLE_ALL_COLUMNS_SPP,
  NO_TOGGLE_COLUMN_SPP,
  SET_COLUMNS_SPP,
} from './actions';

import {
  CONFIGURABLE_SP_COLUMNS,
  DEFAULT_DISPLAY_SP_COLUMNS,
  DEFAULT_SP_COLUMNS_FIELDS,
} from './default';

const COLUMNS = 'structured_products_pricing_columns';
const VCOL = '_vcol_spp';

if (!localStorage.getItem(COLUMNS)) {
  localStorage.setItem(COLUMNS, JSON.stringify(DEFAULT_SP_COLUMNS_FIELDS));
  localStorage.setItem(VCOL, COLUMN_VERSION_SPP);
}

if (
  !localStorage.getItem(VCOL) ||
  localStorage.getItem(VCOL) !== `${COLUMN_VERSION_SPP}`
) {
  localStorage.setItem(COLUMNS, JSON.stringify(DEFAULT_SP_COLUMNS_FIELDS));
  localStorage.setItem(VCOL, COLUMN_VERSION_SPP);
}

const defaultState = {
  columns: JSON.parse(localStorage.getItem(COLUMNS)),
  version: 0,
};

const HIDDEN_FIELDS = ['user_id', 'form_values'];

const getFieldType = (column) => {
  switch (column) {
    case 'created_at':
      return 'DateField';
    case 'id':
    case 'underlyings':
    case 'user':
    case 'tag':
      return 'DisplayText';
    default:
      return 'TextField';
  }
};

const getText = (column) => {
  switch (column) {
    case 'id':
      return 'Pricing Id';
    case 'user':
      return 'User Name';
    case 'frequency':
      return 'Autocall Frequency';
    case 'denomination_currency':
      return 'Currency';
    default:
      return column
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (char) => char.toUpperCase());
  }
};

export const structuredProductsPricingReducer = (
  previousState = defaultState,
  { type, payload },
) => {
  switch (type) {
    case NO_TOGGLE_COLUMN_SPP: {
      const { columns } = previousState;
      const { col, status } = payload;
      const display = { ...columns, [col]: { ...columns[col], status } };
      localStorage.setItem(COLUMNS, JSON.stringify(display));
      return { ...previousState, columns: display };
    }
    case NO_TOGGLE_ALL_COLUMNS_SPP: {
      const { columns } = previousState;
      const display = Object.keys(columns).reduce((res, key) => {
        if (!columns[key].disabled) columns[key].status = payload;
        return Object.assign(res, { [key]: columns[key] });
      }, {});
      localStorage.setItem(COLUMNS, JSON.stringify(display));
      return { ...previousState, columns: display };
    }
    case SET_COLUMNS_SPP: {
      const columnFields = payload
        ? CONFIGURABLE_SP_COLUMNS
        : DEFAULT_SP_COLUMNS_FIELDS;
      const columns = columnFields.reduce((columnInfo, columnKey) => {
        if (!HIDDEN_FIELDS.includes(columnKey)) {
          columnInfo[columnKey] = {
            disabled: false,
            type: getFieldType(columnKey),
            label: getText(columnKey),
            status: payload || DEFAULT_DISPLAY_SP_COLUMNS.includes(columnKey),
          };
        }
        return columnInfo;
      }, {});
      return { ...previousState, columns };
    }
    default:
      return previousState;
  }
};
