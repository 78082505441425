import { Responsive } from 'admin-on-rest';
import Drawer from 'material-ui/Drawer';
import Paper from 'material-ui/Paper';
import muiThemeable from 'material-ui/styles/muiThemeable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

const getWidth = width => (typeof width === 'number' ? `${width}px` : width);

const getStyles = ({ drawer, appBar }) => {
  const width = drawer && drawer.width ? getWidth(drawer.width) : '16em';
  const css = {
    position: 'fixed',
    bottom: 0,
    overflowY: 'auto',
    top: appBar.height,
    left: 0,
  };
  return {
    sidebarOpen: {
      flex: `0 0 ${width}`,
      marginLeft: 0,
      order: -1,
      transition: 'margin 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
      zIndex: 1,
      ...css,
    },
    sidebarClosed: {
      flex: `0 0 ${width}`,
      marginLeft: `-${width}`,
      order: -1,
      transition: 'margin 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
      ...css,
    },
  };
};

class Sidebar extends PureComponent {
  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { open, setSidebarVisibility, children, muiTheme } = this.props;
    const styles = getStyles(muiTheme);

    return (
      <Responsive
        small={
          <Drawer
            docked={false}
            open={open}
            onRequestChange={setSidebarVisibility}
          >
            {React.cloneElement(children, {
              onMenuTap: this.handleClose,
            })}
          </Drawer>
        }
        medium={
          <Paper
            style={open ? styles.sidebarOpen : styles.sidebarClosed}
            className="app-sidebar"
          >
            {React.cloneElement(children, {
              onMenuTap: () => null,
            })}
          </Paper>
        }
      />
    );
  }
}

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  muiTheme: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  open: state.admin.ui.sidebarOpen,
  locale: state.locale, // force redraw on locale change
  theme: props.theme, // force redraw on theme changes
});

export default compose(
  muiThemeable(),
  connect(mapStateToProps),
)(Sidebar);
