import { FunctionField, NumberField, ReferenceField, Show, SimpleShowLayout, TextField, translate } from 'admin-on-rest';
import React from 'react';
import { DateField } from '../helpers';
import { formatDateTimeInput } from '../utils/dates';
import PricingMatrix from './PricingMatrix';

const PricingTitle = translate(({ record }) =>
  <span>{translate('pricings.edit.title', { smart_count: 1 })} { `${record.reference_price} ${record.currency}` }</span>);

const PricingShow = props => (
  <Show {...props}
    title={<PricingTitle />}
  >
    <SimpleShowLayout
      style={{ display: 'flow-root', padding: '0px 1em 1em' }}
    >
      <PricingMatrix />
      <ReferenceField
        style={{ display: 'flex' }}
        label="Strucutre"
        source="structure_id"
        reference="structures"
        linkType="show"
      >
        <FunctionField
          render={r => r.code}
        />
      </ReferenceField>
      <ReferenceField
        style={{ display: 'flex' }}
        label="Underlying"
        source="underlying_id"
        reference="underlyings"
        linkType="show"
      >
        <FunctionField
          render={r => `${r.reference_contract} ${r.currency}`}
        />
      </ReferenceField>
      <NumberField
        style={{ textAlign: 'left', display: 'flex' }}
        source="business_days"
      />
      <NumberField
        style={{ textAlign: 'left', display: 'flex' }}
        source="reference_price"
      />
      <TextField
        style={{ display: 'flex' }}
        source="currency"
      />
      <DateField
        style={{ display: 'flex' }}
        label="Started at"
        formatDate={formatDateTimeInput}
        source="start_date"
        showTime
      />
      <DateField
        style={{ display: 'flex' }}
        label="Expired at"
        formatDate={formatDateTimeInput}
        source="expiry_date"
        showTime
      />
      <DateField
        style={{ display: 'flex' }}
        formatDate={formatDateTimeInput}
        source="structure_expired_at"
        showTime
      />
    </SimpleShowLayout>
  </Show>
);

export default PricingShow;
