import { useMemo } from 'react';

const useTenorList = (productFeature = []) => useMemo(() => {
  const tenorList = productFeature[0] && productFeature[0].reduce((result, tenorMargin) => [...result, tenorMargin.tenor], []);
  return {
    tenorList,
  };
}, [productFeature]);

export default useTenorList;
