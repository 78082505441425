import { Datagrid, DateField, DeleteButton, EditButton, List, TextField } from 'admin-on-rest';
import React from 'react';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';

const GroupsDashboardsLsist = props => (
  <List
    {...props}
    perPage={50}
    title="Dashboard Groups List"
  >
    <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
      <TextField
        source="id"
      />
      <TextField
        source="name"
      />
      <TextField
        source="description"
      />
      <DateField
        formatDate={formatDateTimeInput}
        source="created_at"
        showTime
      />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default GroupsDashboardsLsist;
