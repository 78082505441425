import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import ErrorsIcon from 'material-ui/svg-icons/alert/warning';
import Field from './Field';
import { sortLogs } from './../helpers';

const style = {
  titleWraper: {
    'display': 'grid',
    'grid-template-columns': '5fr 2fr 2fr',
  },
  textStyle: {
    display: 'block',
  },
  titleRight: {
    textAlign: 'right',
  },
  cardText: {
    backgroundColor: 'rgb(245, 245, 245)',
  },
  containerStyle: {
    paddingBottom: 0,
  },
  descriptionWrapper: {
    margin: 'auto 5%',
    display: 'grid',
    columnGap: '50px',
    gridTemplateColumns: 'auto auto auto',
  },
  typeCell: {
    fontWeight: 'bold',
  },
  error: {
    color: 'red',
    marginLeft: '15px',
    verticalAlign: 'middle',
  },
};

const Title = ({ type, updateBy, createdAt, isError }) => (
  <div style={style.titleWraper}>
    <div style={style.typeCell}>{type} {isError ? <ErrorsIcon style={style.error}/> : null}</div>
    <div>{updateBy}</div>
    <div style={style.titleRight}>{createdAt}</div>
  </div>
);

const LogItem = ({ type, updateBy, createdAt, open = false, description = {} }) => {
  const [expanded, toogleOpen] = useState(open);

  useEffect(() => {
    toogleOpen(open);
  }, [open]);

  const onExpandChange = () => toogleOpen(!expanded);

  const { message, errors, eventBy, ...restFields } = description;
  const firstFields = {
    ...message ? { message } : {},
    ...errors ? { errors } : {},
  };

  const { normalFields, objectFields } = sortLogs(restFields, !expanded);

  return (
    <Card expanded={expanded} onExpandChange={onExpandChange} containerStyle={style.containerStyle}>
      <CardHeader
        actAsExpander={true}
        showExpandableButton={true}
        textStyle={style.textStyle}
        title={<Title type={type} updateBy={updateBy || eventBy || 'SYSTEM'} createdAt={createdAt} isError={!!description.errors || !!description.error} />}
      />
      <CardText expandable={true} style={style.cardText}>
        <div style={style.descriptionWrapper}>
          {Object.keys(firstFields).map((key) => (<Field key={key} label={key} value={firstFields[key]}/>))}
          {normalFields.map(([label, value]) => (<Field key={label} label={label} value={value}/>))}
          {objectFields.map(([label, value]) => (<Field key={label} label={label} value={value}/>))}
        </div>
      </CardText>
    </Card>
  );
};

export default LogItem;
