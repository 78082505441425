import { Datagrid, List, Responsive, ListButton } from 'admin-on-rest';
import React, { Component, Fragment } from 'react';
import { CardActions } from 'material-ui/Card';
import Seo from '../components/common/Seo/';
import { DateField, DisplayText } from '../helpers';
import { formatDateTimeInput } from '../utils/dates';
import HistoryActions from '../components/HistoryActions';

const MinifutureHistoriesActions = () => (
  <CardActions className="CardActions">
    <ListButton basePath="/minifuture_products" label="Back" />
  </CardActions>
);

const MinifutureProductHistories = (props) => {
  const {
    match: { params },
    history,
  } = props;
  const { id } = params;

  if (!parseInt(id, 10)) {
    history.replace('/minifuture_product_histories');
  }
  return (
    <Fragment>
      <Seo title={`Product #${id} Histories`} />
      <List
        {...props}
        sort={{ field: 'createdAt', order: 'DESC' }}
        filter={{ product_id: id }}
        title={`Product #${id} Histories`}
        actions={<MinifutureHistoriesActions {...props} />}
        perPage={10}
      >
        <Responsive
          medium={
            <Datagrid>
              <DateField formatDate={formatDateTimeInput} source="createdAt" showTime />
              <DisplayText source="updatedBy" label="Platform Action" getText={({ record }) => record.updatedBy} />
              <DisplayText source="description" getText={({ record }) => <HistoryActions {...record} />} />
            </Datagrid>
          }
        />
      </List>
    </Fragment>
  );
};

export default MinifutureProductHistories;