import { NumberField, TextField, BooleanField } from 'admin-on-rest';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import WarningIcon from 'material-ui/svg-icons/alert/warning';
import DoneIcon from 'material-ui/svg-icons/action/done';
import { DateField, DisplayText } from '../../helpers';
import Badge from './Badge';
import ButtonsWrapper from './ButtonsWrapper';
import { extractOptionLabel } from '../../utils/options';
import { TIME_IN_FORCE_OPTIONS, TYPE_MARKET, TYPE_STOP, TYPE_ICEBERG, ORDER_TYPE_OPTIONS, TYPE_OCO } from './Filters';
import { AUTOAPPROVE_STATUS_APPROVED, AUTOAPPROVE_STATUS_EXCEEDED } from '../../constants';
import { FX_STRUCTURE } from '../constants';
import StructureBadge from './StructureBadge';
import { formatDateTimeInput } from '../../utils/dates';
import DatagridWithSubRows from './DatagridWithSubRows';

class Columns extends Component {
  rowStyle({ badge, status, order_type: orderType, legs }) {
    if (status === 'requested') {
      return ({
        backgroundColor: '#eefdec',
        color: 'rgba(0, 0, 0, 0.87)',
        height: '48px',
      });
    }

    if (!badge) {
      return orderType === TYPE_OCO && legs && legs.length ? {
        borderBottom: null,
      } : {
        borderBottom: '1px solid #81b29a',
        color: 'rgba(0, 0, 0, 0.87)',
        height: '48px',
      };
    }
    return (badge === 'NEW')
      ? {
        borderBottom: orderType === TYPE_OCO ? null : '1px solid rgba(91, 192, 222, .4)',
        backgroundColor: 'rgba(91, 192, 222, .1)',
        color: 'rgba(0, 0, 0, 0.87)',
        height: '48px',
      }
      : {
        borderBottom: orderType === TYPE_OCO ? null : '1px solid rgba(240, 173, 78, .4)',
        backgroundColor: 'rgba(240, 173, 78, .1)',
        color: 'rgba(0, 0, 0, 0.87)',
        height: '48px',
      };
  }

  getAutoApproveStatus(state) {
    switch (state) {
    case AUTOAPPROVE_STATUS_APPROVED: return <DoneIcon title="This is my tooltip" />;
    case AUTOAPPROVE_STATUS_EXCEEDED: return <span className="custom-text-cell"><WarningIcon color='#f0ad4e' /><span className='badge badge-yellow'>Exceeded Limit</span></span>;
    default: return '';
    }
  }

  getText(key, record) {
    switch (key) {
    case 'username': return `${record.first_name || ''} ${record.last_name || ''}`;
    case 'direction_view_mf': return `${record.client_direction_first || ''} ${record.client_direction_second ? `/ ${record.client_direction_second}` : ''}`;
    case 'canceled_user_id': return `${record.canceled_user_id || ''}`;
    case 'quantity': return record.order_type === TYPE_OCO ? null : `${record.quantity || 0} / ${record.hedge_quantity || 0}`;
    case 'price': {
      if (record.order_type === TYPE_OCO) return null;
      return record.order_type === TYPE_MARKET || record.order_type === TYPE_STOP ? 'MKT' : `${record.price || 0} / ${record.neon_price || 0}`;
    }
    case 'stop_price': return record.stop_price ? `${record.stop_price || 0} / ${record.neon_stop_price || 0}` : '';
    case 'max_show': return (record.order_type === TYPE_ICEBERG && (record.max_show || record.max_show === 0)) ? `${record.max_show || 0}` : '';
    case 'order_type': return <span>{extractOptionLabel(record[key], ORDER_TYPE_OPTIONS, 'id', 'name') || ''}</span>;
    case 'avg_price': {
      if (record.order_type === TYPE_OCO) return null;
      return `${parseFloat(record.avg_neon_price) || '-'} / ${parseFloat(record.avg_price) || '-'}`;
    }
    case 'structure': return StructureBadge(record);
    case 'quoted_currency': return record.term_currency ? `${record.quoted_currency} / ${record.term_currency}` : record.quoted_currency;
    case 'fx_rate': return record.fx_rate && record.fx_spread_rate ? `${record.fx_rate || 0} / ${record.fx_spread_rate || 0}` : '';
    case 'autoapprove_status': return this.getAutoApproveStatus(record.autoapprove_status);
    case 'time_in_force': return record.rowType === 'legs' ? null : <span>{extractOptionLabel(record[key], TIME_IN_FORCE_OPTIONS, 'id', 'name') || TIME_IN_FORCE_OPTIONS[0].name}</span>;
    case 'remaining_quantity': return record.order_type === TYPE_OCO ? null : `${parseFloat((record.quantity - (record.filled_quantity * record.factor)).toFixed(4)) || 0} / ${parseFloat((record.hedge_quantity - record.filled_quantity).toFixed(4)) || 0}`;
    case 'filled_quantity': return record.order_type === TYPE_OCO ? null : record.filled_quantity;
    case 'factor': return record.order_type === TYPE_OCO ? null : record.factor;
    default: return record[key] ? record[key] : '-';
    }
  }

  renderElement(key) {
    const { columns } = this.props;
    const { status, label, type } = columns[key];
    if (!status) return '';
    switch (type) {
    case 'NumberField':
      return <NumberField key={key} source={key} label={label} options={{ maximumFractionDigits: 4 }} />;
    case 'BooleanField':
      return <BooleanField key={key} source={key} label={label} defaultValue />;
    case 'DisplayText':
      return <DisplayText key={key} label={label} getText={({ record }) => this.getText(key, record)} />;
    case 'DateField': {
      let conditionalFormatting = null;
      if (key === 'contract_expiry' || key === 'contract_expiry_second') {
        conditionalFormatting = ({ record, date }) => {
          if (record.structure === FX_STRUCTURE) {
            return moment(date).utc().format('DD MMM YYYY');
          }
          return moment(date).utc().format('MMM YY');
        };
      }
      return <DateField
        key={key}
        conditionalFormatting={conditionalFormatting}
        formatDate={formatDateTimeInput}
        showTime
        source={key}
        label={label} />;
    }
    default: {
      if (key === 'structure' && label === 'swap') {
        return <Badge source='Badge' />;
      }
      return <TextField defaultValue='-' key={key} source={key} label={label} />;
    }
    }
  }

  render() {
    // JW sets this true to avoid needing to scroll the screen to the right all the time
    const showButtonWrapperOnLeft = localStorage.getItem('buttonsOnLeft');
    const { columns } = this.props;
    return (
      <DatagridWithSubRows
        bodyOptions={{
          showRowHover: true,
        }}
        options={{ width: '100%' }}
        rowStyle={this.rowStyle}
        fieldWithSubRows='legs'
        subRowClassName='sub-row-leg'
        {...this.props}
      >
        <Badge source='Badge' />
        {showButtonWrapperOnLeft ? <ButtonsWrapper /> : null}
        {Object.keys(columns).map(key => this.renderElement(key))}
        {showButtonWrapperOnLeft ? null : <ButtonsWrapper />}
      </DatagridWithSubRows>
    );
  }
}

const mapStateToProps = ({
  neonOrdersReducer: { columns },
  admin: { resources: { neon_orders: { data } } },
}) => ({
  columns,
  data,
});

export default connect(mapStateToProps)(Columns);
