import { JsonEditor as Editor } from 'jsoneditor-react';
import React, { Component } from 'react';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

class JsonEditor extends Component {
  render() {
    const { input: { value, onChange } } = this.props;
    const json = value.length ? value : this.props.record[this.props.source] || '[]';

    return (
      <div>
        <Editor
          allowedModes={['tree', 'code']}
          ace={ace}
          theme="ace/theme/github"
          value={JSON.parse(json)}
          onChange={change => onChange(JSON.stringify(change))}
        />
      </div>
    );
  }
}

export default JsonEditor;
