export const MONTHLY_HEADINGS = {
  0.25: '3 months',
  0.5: '6 months',
  0.75: '9 months',
};

export const INITIAL_UNDERLYINGS = [
  { tenor: 0.25, margin: undefined },
  { tenor: 0.5, margin: undefined },
  { tenor: 0.75, margin: undefined },
  { tenor: 1, margin: undefined },
];
