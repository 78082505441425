import React from 'react';
import DownloadJson from '../../components/DownloadJson';

const DownloadInputSettings = ({ record }) => {
  const { settings, fileUploadStatus, fileName, custom } = record;

  if (custom) {
    return (<div style={{ width: '250px' }}>No config required</div>)
  }

  return <DownloadJson jsonFile={settings} fileStatus={fileUploadStatus} fileName={fileName || 'sp_input_settings.json'} />;
};

export default DownloadInputSettings;
