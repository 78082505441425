import React, { Fragment } from 'react';
import { DeleteButton, EditButton } from 'admin-on-rest';

export const ButtonsWrapper = (props) => {
  const { record: { defaultFooter = false } } = props;
  return (
    <Fragment>
      <div style={{ width: '210px' }}>
        <EditButton {...props} style={{ float: 'left', minWidth: '30px' }}/>
        {!defaultFooter && <DeleteButton {...props} style={{ float: 'left', minWidth: '30px' }}/>}
      </div>
    </Fragment>
  );
};
