import { Datagrid, DateInput, DeleteButton, EditButton, Filter, FunctionField, List, Responsive, ShowButton, SimpleList, TextField, TextInput, BooleanField } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import HistoryButton from './components/HistoryButton';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput, formatDateInput, dateParser } from '../utils/dates';

const SpreadSheetsFilter = props => (
  <Filter {...props}>
    <TextInput
      source="title"
    />
    <TextInput
      source="sales_representative"
    />
    <TextInput
      source="ib_counterparty"
    />
    <TextInput
      source="update_by"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
      parse={dateParser}
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
      parse={dateParser}
    />
  </Filter>
);

const SpreadSheetsList = props => (
  <List {...props}
    filters={<SpreadSheetsFilter />}
    sort={{ field: 'created_at', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => `${r.structure} ${r.underlying}`}
          secondaryText={r => r.currency}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.expired_at}</Moment>}
        />
      }
      medium={
        <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
          <TextField source="id" />
          <TextField source="title" />
          <TextField source="cqg_account" label="CQG Account"/>
          <TextField source="representative" label="Sales Representative"/>
          <TextField source="ib_counterparty" label="IB Counterparty"/>
          <BooleanField source="auto_book" label="Auto Book" />
          <FunctionField label="Swap" render={record => (Array.isArray(record.commodities) ? `${record.commodities.filter(row => row.activate === true && row.type === 'swap').length}/${record.commodities.filter(row => row.type === 'swap').length}` : `${record.spreadsheet_swap}/${record.total_swap}`)} />
          <FunctionField label="Asian Swap" render={record => (Array.isArray(record.commodities) ? `${record.commodities.filter(row => row.activate === true && row.type === 'asian swap').length}/${record.commodities.filter(row => row.type === 'asian swap').length}` : `${record.spreadsheet_asian_swap}/${record.total_swap}`)} />
          <FunctionField label="Vanilla" render={record => (Array.isArray(record.commodities) ? `${record.commodities.filter(row => row.activate === true && row.type === 'vanilla').length}/${record.commodities.filter(row => row.type === 'vanilla').length}` : `${record.spreadsheet_vanilla}/${record.total_vanilla}`)} />
          <FunctionField label="FX" render={record => (Array.isArray(record.commodities) ? `${record.commodities.filter(row => row.activate === true && row.type === 'fx').length}/${record.commodities.filter(row => row.type === 'fx').length}` : `${record.spreadsheet_fx}/${record.total_fx}`)} />
          <FunctionField label="Users" render={record => (Array.isArray(record.users) ? record.users.length : record.users)} />
          <FunctionField label="Groups" render={record => (Array.isArray(record.groups) ? record.groups.length : record.groups)} />
          <FunctionField label="update by" render={record => (record.general ? record.general.update_by : record.update_by)} />
          <DateField
            formatDate={formatDateTimeInput}
            source="updated_at"
            showTime
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="created_at"
            showTime
          />
          <ShowButton />
          <EditButton />
          <HistoryButton />
          <DeleteButton />
        </Datagrid>
      }
    />
  </List>
);

export default SpreadSheetsList;
