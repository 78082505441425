import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import Dialog from 'material-ui/Dialog';
import ChipInput from 'material-ui-chip-input';
import ConfirmIcon from 'material-ui/svg-icons/maps/rate-review';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import RaisedButton from 'material-ui/RaisedButton';

import { connect } from 'react-redux';
import { translate, UPDATE, GET_LIST } from 'admin-on-rest';

import { DASHBOARD_REQUEST_CONFIRM } from './DashboardRequestAction';

import restClient from '../restClient';

const dashboardRequestConfirmAction = (id, data, basePath) => ({
  type: DASHBOARD_REQUEST_CONFIRM,
  payload: { id, data: { ...data, status: false }, basePath },
  meta: { resource: 'dashboard_requests', fetch: UPDATE, cancelPrevious: false },
});

const DashboardRequestDialog = class CustomPricingRequestDialog extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
    this.state = {
      open: false,
      errors: {
        dashboards: false,
        selectedGroups: false,
      },
      chips: [],
      dashboards: [],
      selectedGroups: [],
      groups: [],
    };
  }

  handleOpen() {
    this.setState({ open: true });

    restClient(GET_LIST, 'dashboards', {
      sort: {
        field: 'name',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((res) => {
      this.setState({ chips: res.data });
    });

    restClient(GET_LIST, 'dashboard_groups', {
      sort: {
        field: 'name',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((res) => {
      this.setState({ groups: res.data });
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleSubmit() {
    const { dashboards, selectedGroups } = this.state;
    const errors = {
      dashboards: !dashboards.length,
      selectedGroups: !selectedGroups.length,
    };

    if (errors.dashboards && errors.selectedGroups) {
      this.setState({ errors });
      return;
    }

    const { record } = this.props;
    this.props.confirmAction(record.id, {
      ...record,
      status: false,
      dashboards: dashboards.map(i => i.id),
      groups: selectedGroups.map(i => i.id),
    });
  }

  handleAddChip(chip) {
    const chips = [...this.state.dashboards, chip];
    this.setState({
      dashboards: chips,
      errors: {
        dashboards: !chips.length,
        selectedGroups: !this.state.selectedGroups.length,
      },
    });
  }

  handleDeleteChip(chip) {
    const chips = this.state.dashboards.filter(c => c.id !== chip);
    this.setState({
      dashboards: chips,
      errors: {
        dashboards: !chips.length,
        selectedGroups: !this.state.selectedGroups.length,
      },
    });
  }

  handleAddGroup(chip) {
    const selectedGroups = [...this.state.selectedGroups, chip];
    this.setState({
      selectedGroups,
      errors: {
        dashboards: !this.state.dashboards.length,
        selectedGroups: !selectedGroups.length,
      },
    });
  }

  handleDeleteGroup(chip) {
    const selectedGroups = this.state.selectedGroups.filter(c => c.id !== chip);
    this.setState({
      selectedGroups,
      errors: {
        dashboards: !this.state.dashboards.length,
        selectedGroups: !selectedGroups.length,
      },
    });
  }

  handleBeforeAddChip(chip) {
    const chips = this.state.chips.filter(c => (chip.name && c.name === chip.name));
    return chips.length;
  }

  dataSource() {
    const { user_dashboard_ids: userDashboardIds } = this.props.record;
    return this.state.chips.filter(c => !userDashboardIds.includes(c.id));
  }

  dataSourceGroup() {
    const { user_groups_ids: userGroupsIds } = this.props.record;
    return this.state.groups.filter(c => !userGroupsIds.includes(c.id));
  }

  render() {
    const { record } = this.props;

    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label={this.translate('dashboardRequests.dialog.btnConfirm')}
          icon={<ConfirmIcon />}
          disabled={!record || !record.status}
          onClick={this.handleOpen.bind(this)}
        />
        <Dialog
          title={`${this.translate('dashboardRequests.dialog.title')}${record && record.id}`}
          modal={false}
          open={this.state.open}
          autoScrollBodyContent={true}
        >
          <ChipInput
            value={this.state.dashboards}
            dataSource={this.dataSource()}
            openOnFocus={true}
            onBeforeRequestAdd={this.handleBeforeAddChip.bind(this)}
            onRequestAdd={this.handleAddChip.bind(this)}
            onRequestDelete={this.handleDeleteChip.bind(this)}
            dataSourceConfig={{ text: 'name', value: 'id' }}
            errorText={this.state.errors.dashboards && this.translate('aor.validation.required')}
            floatingLabelText={this.translate('dashboardRequests.dialog.dashboards')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={this.state.selectedGroups}
            dataSource={this.dataSourceGroup()}
            openOnFocus={true}
            dataSourceConfig={{ text: 'name', value: 'id' }}
            onRequestAdd={this.handleAddGroup.bind(this)}
            onRequestDelete={this.handleDeleteGroup.bind(this)}
            errorText={this.state.errors.selectedGroups && this.translate('aor.validation.required')}
            floatingLabelText={this.translate('dashboardRequests.dialog.groups')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              label={this.translate('dashboardRequests.dialog.btnCancel')}
              onClick={this.handleClose.bind(this)}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label={this.translate('dashboardRequests.dialog.btnSubmit')}
              primary={true}
              onClick={this.handleSubmit.bind(this)}
              icon={<SubmitIcon />}
              disabled={!this.state.dashboards.length || !this.state.selectedGroups.length}
            />
          </div>
        </Dialog>
      </div>
    );
  }
};

const enhance = compose(
  translate,
  connect(null, {
    confirmAction: dashboardRequestConfirmAction,
  }),
);

export default enhance(DashboardRequestDialog);
