import React from 'react';
import {
  required,
  TextInput,
  TabbedForm,
  FormTab,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'admin-on-rest';
import {
  validateMaxLengthName,
  validateMinLengthName,
} from '../utils/validations';
import NotionalMarginControl from './notionalMarginControl';
import TenorUnderlyingControl from './TenorUnderlyingControl/TenorUnderlyingControl';

export const PricingTemplateStructuredProductsForm = ({ toolbar, ...rest }) => (
  <TabbedForm toolbar={toolbar} {...rest}>
    <FormTab label="General">
      <TextInput
        label='spPricingTemplate.name'
        source='name'
        type='name'
        validate={[required, validateMinLengthName, validateMaxLengthName]}
        options={{ fullWidth: true }}
      />

      <ReferenceArrayInput source="userIds" reference="users" label="Users" allowEmpty perPage="1e9" filterToQuery={x => ({ _q: x })}>
        <SelectArrayInput
          optionText="email"
          optionValue="id"
          elStyle={{ width: '50%' }}
          options={{ 'listStyle': { 'maxHeight': '30vh', 'overflow': 'auto' }, 'onBlur': () => {} }}
        />
      </ReferenceArrayInput>
    </FormTab>
    <FormTab label="Notional size Margin">
      <NotionalMarginControl {...rest}/>
    </FormTab>
    <FormTab label="Product feature Margin">
      <TenorUnderlyingControl {...rest}/>
    </FormTab>
  </TabbedForm>
);
