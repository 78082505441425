import { Datagrid, DeleteButton, List, Responsive, ShowButton, SimpleList, TextField } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';
import PDFHistoryDownloadButton from './PDFHistoryDownloadButton';
import PDFHistoryFilter from './PDFHistoryFilter';
import PDFHistorySendButton from './PDFHistorySendButton';

const PDFHistoryList = props => (
  <List {...props}
    title="PDF History"
    filters={<PDFHistoryFilter />}
    sort={{ field: 'created_at', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => r.id}
          secondaryText={r => r.email}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.create_at}</Moment>}
        />
      }
      medium={
        <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
          <TextField
            source="id"
          />
          <TextField
            source="client_name"
          />
          <TextField
            source="pdf_name"
            label="PDF"
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="created_at"
            showTime
          />
          <PDFHistoryDownloadButton />
          <PDFHistorySendButton />
          <ShowButton />
          <DeleteButton />
        </Datagrid>
      }
    />
  </List>
);

export default PDFHistoryList;
