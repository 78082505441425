export const PDF_SEND = 'PDF_SEND';
export const PDF_SEND_LOADING = 'PDF_SEND_LOADING';
export const PDF_SEND_FAILURE = 'PDF_SEND_FAILURE';
export const PDF_SEND_SUCCESS = 'PDF_SEND_SUCCESS';

export const PDF_SEND_MANY = 'PDF_SEND_MANY';
export const PDF_SEND_MANY_LOADING = 'PDF_SEND_MANY_LOADING';
export const PDF_SEND_MANY_FAILURE = 'PDF_SEND_MANY_FAILURE';
export const PDF_SEND_MANY_SUCCESS = 'PDF_SEND_MANY_SUCCESS';

export const PDF_GENERATE = 'PDF_GENERATE';
export const PDF_GENERATE_LOADING = 'PDF_GENERATE_LOADING';
export const PDF_GENERATE_FAILURE = 'PDF_GENERATE_FAILURE';
export const PDF_GENERATE_SUCCESS = 'PDF_GENERATE_SUCCESS';

export const PDF_GROUP_GENERATE = 'PDF_GROUP_GENERATE';
export const PDF_GROUP_GENERATE_LOADING = 'PDF_GROUP_GENERATE_LOADING';
export const PDF_GROUP_GENERATE_FAILURE = 'PDF_GROUP_GENERATE_FAILURE';
export const PDF_GROUP_GENERATE_SUCCESS = 'PDF_GROUP_GENERATE_SUCCESS';
