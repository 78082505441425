import React from 'react';
import { Edit, SimpleForm, TextInput, required, minLength, maxLength } from 'admin-on-rest';

const validateMinLengthToOne = minLength(1);
const validateMaxLengthToHundred = maxLength(100);
const MarginCallsEdit = props => (
  <Edit {...props} >
    <SimpleForm>
      <TextInput
        source="margin_call"
        validate={[required, validateMinLengthToOne, validateMaxLengthToHundred]}
        elStyle={{ width: '50%' }}
        type='number'
      />
      <TextInput
        source="send_value"
        validate={[required, validateMinLengthToOne, validateMaxLengthToHundred]}
        elStyle={{ width: '50%' }}
        type='number'
      />
      <TextInput
        source="currency"
        validate={[required, validateMinLengthToOne, validateMaxLengthToHundred]}
        elStyle={{ width: '50%' }}
      />
    </SimpleForm>
  </Edit>
);

export default MarginCallsEdit;
