import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, fetchUtils } from 'admin-on-rest';

import path from './path';

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const req = new Request(`${path}/admin/login`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetchUtils
      .fetchJson(req)
      .then((res) => {
        localStorage.setItem('session', res.json.session);
        localStorage.setItem('ex', res.json.ex);
        localStorage.setItem('_u', res.json._u || '');
      });
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('session');
    localStorage.removeItem('ex');
    localStorage.removeItem('_u');
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return new Promise((res, rej) => {
      const session = localStorage.getItem('session');

      if (!session) return rej({ redirectTo: '/login' });

      const req = new Request(`${path}/admin/admins/validateToken`, {
        method: 'POST',
        body: JSON.stringify({ session }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      });

      return fetchUtils.fetchJson(req).then((result) => {
        try {
          const parsedResults = JSON.parse(result.body);
          if (!parsedResults.validToken) throw new Error('not valid token');
        } catch (e) {
          rej({ redirectTo: '/login' });
        }

        return res();
      });
    });
  }
  return Promise.reject(`Unkown method ${type}`);
};
