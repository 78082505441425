/* eslint-disable */
import React, { Fragment } from 'react';
import { showNotification } from 'admin-on-rest';
import FlatButton from 'material-ui/FlatButton';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import { Toolbar, ToolbarGroup, ToolbarSeparator } from 'material-ui/Toolbar';
import EditIcon from 'material-ui/svg-icons/image/edit';
import compose from 'recompose/compose';
import { showNotification as showNotificationAction } from 'admin-on-rest';
import { connect } from 'react-redux';
import path from '../../path';
import { formatDescription } from './helpers';
import socket from 'ioClient';

const adminPath = `${path}/cqg`;

const AdminButton = props => (
  <FlatButton
    label={props.label}
    onClick={props.onClick}
    icon={<EditIcon color="#01579B" />}
    style={{ minWidth: '30px', padding: '0 15px' }}
  />
);

class CqgLogs extends React.Component {
  constructor(props) {
    super(props);
    this.socket = socket;
    this.state = {
      selectedInstance: null,
      proxyStateList: [],
      contractExpiry: ''
    };
  }

  invokeRest(url, method, body, onData) {
    fetch(`${adminPath}/${url}`, {
      method,
      body: body && JSON.stringify(body),
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(res => res.json())
      .then(onData);
  }

  getState = (forceRefresh = true) =>
    this.invokeRest(`state?refresh=${forceRefresh}`, 'GET', null, data => this.updateHostState(data));

  forceInit = () => this.invokeRest('restart/', 'GET', null, () => { });

  updateHostState(data) {
    const arr = Array.isArray(data) ? data : [{ name: 'none', data }];
    this.setState({
      proxyStateList: arr,
      selectedInstance: this.state.selectedInstance || (arr.length && arr[0].name),
    });
  }

  componentDidMount() {
    this.socket.on('cqg-logs', data => {
      if (data && Array.isArray(data)) {
        this.updateHostState(data);
        return;
      }

      this.getState(false);
    });

    this.getState(false);
  }

  toggleLogon(desc, isLogon) {
    const { selectedInstance } = this.state;
    const { showNotification } = this.props;
    if (!selectedInstance) {
      showNotification('No instance selected', 'warning');
      return;
    }

    const payload = { hostName: selectedInstance, isLogon };
    this.invokeRest('toggleLogon/', 'PUT', payload, data => {
      if (!data.isSuccess) {
        showNotification(`Request to ${desc} failed`, 'warning');
        return;
      }
      showNotification(`Request to ${desc} sent`);
    });
  }

  async changeContractExpiry() {
    const { selectedInstance, contractExpiry } = this.state;
    await window.alert(contractExpiry);
    const payload = { hostName: selectedInstance, contractExpiry };
    this.invokeRest('contractExpiryLog/', 'PUT', payload, data => {
      if (!data.isSuccess) {
        showNotification(`Request to set contract expiry logging failed`, 'warning');
        return;
      }
      showNotification(`Request to set contract expiry logging succeeded`, 'warning');
    });
  }

  changeThrottleMode(mode) {
    const { selectedInstance, proxyStateList } = this.state;
    const { showNotification } = this.props;
    if (!selectedInstance) {
      showNotification('No instance selected', 'warning');
      return;
    }

    const selectedInstanceObj = proxyStateList.find(x => x.name === selectedInstance);
    const enabled = selectedInstanceObj.throttleModeEnabled;
    const payload = { hostName: selectedInstance, mode };
    this.invokeRest('throttleMode/', 'PUT', payload, data => {
      if (!data.isSuccess) {
        showNotification(`Request to set throttle mode ${mode} failed`, 'warning');
        return;
      }
      showNotification(`Request to ${enabled ? 'enable' : 'disable'} throttle mode has been processed`);
    });
  }

  forceLogon = () => this.toggleLogon('Log on', true);
  forceLogoff = () => this.toggleLogon('Log off', false);
  onSelectInstance = (event, index, value) => this.setState({ ...this.state, selectedInstance: value });

  render() {
    const { proxyStateList, selectedInstance } = this.state;

    const selectedInstanceObj = proxyStateList.find(x => x.name === selectedInstance);

    return (
      <div>
        <Toolbar>
          <ToolbarGroup firstChild={true}>
            <AdminButton label="Init" onClick={this.forceInit} />
            <AdminButton label="Refresh" onClick={this.getState} />
            <ToolbarSeparator />
            <DropDownMenu
              value={selectedInstance}
              onChange={this.onSelectInstance}
              style={{ width: 200 }}
              autoWidth={false}
            >
              {proxyStateList.map((x, i) => (
                <MenuItem key={i} value={x.name} primaryText={x.name} />
              ))}
            </DropDownMenu>
            <AdminButton label="Logon" onClick={this.forceLogon} />
            <AdminButton label="Logoff" onClick={this.forceLogoff} />
          </ToolbarGroup>
        </Toolbar>
        <div>{formatDescription(proxyStateList)}</div>
        {selectedInstanceObj == null ? null :
        <Fragment>
        <div>
          <FlatButton label={selectedInstance + ' Throttle Off'} onClick={this.changeThrottleMode.bind(this, 0)} />
        </div>
        <div>
          <FlatButton label={selectedInstance + ' Throttle On (mode 2)'} onClick={this.changeThrottleMode.bind(this, 2)} />
        </div>
        </Fragment>
        }
        {/* <div>
          <FlatButton label={'Modify Contract Expiry Logging for ' + selectedInstance} onClick={this.changeContractExpiry.bind(this)}> </FlatButton>
        </div> */}
      </div>
    );
  }
}

const enhance = compose(connect(null, { showNotification: showNotificationAction }));

export default enhance(CqgLogs);
