import { UPDATE } from 'admin-on-rest';

import { MARKET_DATA_STRUCTURE } from './MarketDataAction';

const MarketDataStructureAction = (id, data) => ({
  type: MARKET_DATA_STRUCTURE,
  payload: { id, data },
  meta: { resource: 'market_data', fetch: UPDATE, cancelPrevious: false },
});

export default MarketDataStructureAction;
