import React from 'react';
import {
  required,
  SimpleForm,
  TextInput,
  ReferenceInput,
  minLength,
  AutocompleteInput,
} from 'admin-on-rest';
import AutoComplete from 'material-ui/AutoComplete';
import { getOptionText } from './helpers';

const textFieldStyle = { display: 'block', marginRight: '20px' };

const accountIdValidation = [minLength(3), required];

export const CqgAccountsToSalesForm = ({ toolbar, ...rest }) => (
  <SimpleForm toolbar={toolbar} {...rest} redirect={'list'}>
    <TextInput label="Account Code" source="accountCode" style={textFieldStyle} validate={accountIdValidation} />
    <ReferenceInput
      key="saleSlackId"
      label="Sale"
      source="saleSlackId"
      reference="cqg_accounts_to_sales/sales"
      validate={[required]}
      filterToQuery={searchText => ({ _q: searchText })}
    >
      <AutocompleteInput
        optionText={getOptionText}
        optionValue="id"
        options={
          {
            'listStyle': { 'maxHeight': '30vh', 'overflow': 'auto' },
            'onBlur': () => { },
            'filter': AutoComplete.levenshteinDistance,
          }
        }
      />
    </ReferenceInput>
  </SimpleForm>
);
