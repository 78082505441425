import React from 'react';

export const STATIC_PAGE_OPTIONS = [
  { id: 'footer', name: 'Footer' },
  { id: 'header', name: 'Header' },
];

const Default = () => (<b style={{ fontSize: '11px' }}> (Default)</b>);

export const getText = ({ position, defaultFooter }) => {
  const [data] = STATIC_PAGE_OPTIONS.filter(({ id }) => (id === position));
  const { name } = data || { name: '' };
  if (!defaultFooter) return (<span>{name}</span>);
  return (<span>{name}<Default /></span>);
};
