import React from 'react';
import { connect } from 'react-redux';
import {
  Responsive,
  refreshView,
  List,
  RefreshButton,
  Filter,
  TextInput,
  RichTextField,
  DeleteButton,
} from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import CreateEditButton from '../SpCommonComponents/CreateEditButton';
import SpOrderingItems from '../SpCommonComponents/SpOrderingItems/SpOrderingItems';
import SpDataGripWrapper from '../SpDataGripWrapper/SpDataGripWrapper';
import DownloadLayoutJson from '../SpCommonComponents/DownloadLayoutJson';
import DownloadRulesJson from '../SpCommonComponents/DownloadRulesJson';
import DownloadFieldValidationRules from '../SpCommonComponents/DownloadFieldValidationRules';
import ReviewButton from '../SpCommonComponents/ReviewButton';
import GenerateButton from '../SpCommonComponents/GenerateButton';
import ProductRepoButton from '../SpCommonComponents/ProductRepoButton/ProductRepoButton';
import { WrapperDataRecord } from '../types';
import CheckForUpdatesButton from 'spScreenConfigurator/SpCommonComponents/CheckForUpdatesButton';

const API_SOURCE = 'sp_wrappers_config';

const Actions = () => (
  <CardActions>
    <GenerateButton
      fieldName="wrapper"
      floatingLabelText="Wrapper"
      configurablePrefix="configurableSpWrappers.generate"
    />
    <SpOrderingItems apiSource={API_SOURCE} fieldName="wrapperName" />
    <RefreshButton />
    <CreateEditButton
      apiSource={API_SOURCE}
      defaultFilename="sp_wrappers_config.json"
      configurablePrefix="configurableSpWrappers"
      fieldName="wrapperName"
    />
  </CardActions>
);

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="configurableSp.search" source="_q" alwaysOn />
  </Filter>
);

interface SpWrappersConfigOwnProps {
  record: WrapperDataRecord;
}

interface SpWrappersConfigDispatchProps {
  refreshView: typeof refreshView;
}

type SpWrappersConfigProps = SpWrappersConfigOwnProps & SpWrappersConfigDispatchProps;

const ButtonsWrapper: React.FC<SpWrappersConfigProps> = (props) => (
  <div style={{ width: '750px' }}>
    <CreateEditButton
      {...props}
      edit={true}
      apiSource={API_SOURCE}
      defaultFilename="sp_wrappers_config.json"
      configurablePrefix="configurableSpWrappers"
      fieldName="wrapperName"
    />
    <ProductRepoButton {...props} field="wrapper" floatingLabelText="Wrapper" />
    <CheckForUpdatesButton
      {...props}
      apiSource={API_SOURCE}
      apiSourcePrefix='/getWrapperUpdateByURI/'
    />
    <ReviewButton {...props} apiSource={API_SOURCE} fieldName="wrapper" />
    <DeleteButton {...props} />
  </div>
);

const SpWrappersConfig: React.FC<SpWrappersConfigProps> = (props) => {
  return (
    <List
      {...props}
      title="SP Wrappers Configurator"
      filters={<Filters />}
      sort={{ field: 'ordering_index', order: 'ASC' }}
      perPage={50}
      refreshView={true}
      actions={<Actions />}
    >
      <Responsive
        medium={
          <SpDataGripWrapper buttonComponent={ButtonsWrapper}>
            <RichTextField label="wrapper" source="wrapperName" />
            <RichTextField label="Wrapper Version" source="wrapperVersion" />
            <DownloadLayoutJson defaultFileName="sp_wrapper.json" />
            <DownloadRulesJson />
            <DownloadFieldValidationRules />
          </SpDataGripWrapper>
        }
      />
    </List>
  );
};

export default connect(null, {
  refreshView,
})(SpWrappersConfig);
