import React from 'react';
import Plot from 'react-plotly.js';

import { translate } from 'admin-on-rest';

import path from '../path';

class MarketDataMatrix extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
    this.state = {
      matrix: [],
    };
  }
  componentDidMount() {
    const { record } = this.props;

    fetch(`${path}/admin/market_data/matrix/${record.id}`, {
      method: 'GET',
      headers: {
        Token: localStorage.getItem('session'),
      },
    }).then(res => res.json())
      .then(res => this.setState({ matrix: res }));
  }

  get show() {
    return !!this.state.matrix.length;
  }

  render() {
    const x = this.state.matrix.map(i => i.date);
    const y = this.state.matrix.map(i => i.price);
    return (
      <div style={{ float: 'right', marginRight: '10px' }}>
        <Plot
          data={[
            {
              x,
              y,
              type: 'scatter',
              mode: 'lines+points',
              marker: {
                color: '#00bcd4',
              },
            },
          ]}
          layout={{ width: 1000, height: 400, xaxis: { tickformat: '%b %Y' } }}
        />
      </div>
    );
  }
}

export default translate(MarketDataMatrix);
