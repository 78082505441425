import { Fragment } from "react/jsx-runtime";

export const API_SOURCE_CONFIG = {
  payoff: {
    apiSource: 'sp_layouts_config',
    definition: 'getPayoffDefinition',
    allDefinition: 'getAllPayoffDefinitions',
    fields: {
      name: 'payoffName',
      uri: 'payoffUri',
    },
  },
  wrapper: {
    apiSource: 'sp_wrappers_config',
    definition: 'getWrapperDefinition',
    allDefinition: 'getAllWrappersDefinitions',
    fields: {
      name: 'wrapperName',
      uri: 'wrapperUri',
    },
  },
};

export interface MapFieldValuesToDisplayProps {
  value: {
    categorisations: Array<string>;
    dataType: string;
    defaultValue: string | number | null | undefined;
    field: string;
    mandatory: boolean;
    path: string;
    section: string;
  },
  translate: (value: string) => string,
  translationsPrefix: string;
}

export const mapFieldValuesToDisplay = (props: MapFieldValuesToDisplayProps) => {
  const { value, translate, translationsPrefix } = props;
  const { categorisations, dataType, defaultValue, field, mandatory, path, section } = value;
  return (
    <div style={{ paddingLeft: '4em'}}>
      <div><strong>{translate(`${translationsPrefix}.fieldProperties.categorisations`)}</strong>{categorisations.map((el, index) => <span key={index}>{el}{', '}</span>)}</div>
      <div><strong>{translate(`${translationsPrefix}.fieldProperties.dataType`)}</strong>{dataType}</div>
      <div><strong>{translate(`${translationsPrefix}.fieldProperties.defaultValue`)}</strong>{String(defaultValue)}</div>
      <div><strong>{translate(`${translationsPrefix}.field`)}</strong>{field}</div>
      <div><strong>{translate(`${translationsPrefix}.fieldProperties.mandatory`)}</strong>{String(mandatory)}</div>
      <div><strong>{translate(`${translationsPrefix}.fieldProperties.path`)}</strong>{path}</div>
      <div><strong>{translate(`${translationsPrefix}.section`)}</strong>{section}</div>
    </div>
  )
}

export const mapChangesToDisplay = (changes, setChangeObj, translate, translationsPrefix) => {
    setChangeObj(changes.map(( section, sectionIndex ) => {
      return (
        <div style={{ color: 'black'}} key={sectionIndex}>
          {
            section.key === 'version' ? (
              <h4 style={{ paddingLeft: '1em', margin: 0 }} key={section.newVersion}>{translate(`${translationsPrefix}.versionUpdate`)}{section.currentVersion}{' >>> '}{section.newVersion}</h4>
            )
            : section.key === 'URI' ? (
              <h4 style={{ paddingLeft: '1em', margin: 0 }} key={section.newUri}>{translate(`${translationsPrefix}.uriUpdate`)}{section.currentUri}{' >>> '}{section.newUri}</h4>
            )
            : (
              <Fragment key={`${section.key}_${sectionIndex}`}>
                <h4 style={{ paddingLeft: '1em', margin: '0.5em 0 0 0' }} key={section.key}>{translate(`${translationsPrefix}.section`) + section.key.charAt(0).toUpperCase() + section.key.substring(1)}</h4>
                {
                  section.changes.map(( field ) => {
                    return (
                      <div style={{ margin: '0.5em 0'}} key={`${field.key}_${section.key}`}>
                        <p style={{ paddingLeft: '2em', margin: 0 }} key={field.key}><strong>{translate(`${translationsPrefix}.field`)}</strong>{field.key}</p>
                        {
                          field.changes.map((change, changeIndex) => {
                            const { key, oldValue, value, type } = change;
                            return (
                              <div style={{ paddingLeft: '3em' }} key={change.key}>
                                { type === 'ADD' &&
                                  <Fragment key={`${field.key}_${change.key}_add`}>
                                    <div key={`${field.key}_${change.key}_add_${changeIndex}_no_value`}><strong>{translate(`${translationsPrefix}.fieldProperties.currentValue`)}</strong>{translate(`${translationsPrefix}.noValue`)}</div>
                                    <div key={`${field.key}_${change.key}_add_${changeIndex}_${value.field}`}><strong>{translate(`${translationsPrefix}.fieldProperties.newValue`)}</strong>{mapFieldValuesToDisplay({value, translate, translationsPrefix})}</div>
                                  </Fragment>
                                }
                                { type === 'REMOVE' &&
                                  <Fragment key={`${field.key}_${change.key}_remove`}>
                                    <div key={`${field.key}_${change.key}_remove_${changeIndex}_${key}`}><strong>{translate(`${translationsPrefix}.fieldProperties.property`)}</strong>{key}</div>
                                    <div key={`${field.key}_${change.key}_remove_${changeIndex}_${value.field}`}><strong>{translate(`${translationsPrefix}.fieldProperties.currentValue`)}</strong>{mapFieldValuesToDisplay({value, translate, translationsPrefix})}</div>
                                    <div key={`${field.key}_${change.key}_remove_${changeIndex}_no_value`}><strong>{translate(`${translationsPrefix}.fieldProperties.newValue`)}</strong>{translate(`${translationsPrefix}.noValue`)}</div>
                                  </Fragment>
                                }
                                { type === 'UPDATE' &&
                                  <Fragment key={`${field.key}_${change.key}_update`}>
                                    <div key={`${field.key}_${change.key}_update_${changeIndex}_${key}`}><strong>{translate(`${translationsPrefix}.fieldProperties.property`)}</strong>{key}</div>
                                    <div key={`${field.key}_${change.key}_update_${changeIndex}_${oldValue}`}><strong>{translate(`${translationsPrefix}.fieldProperties.currentValue`)}</strong>{String(oldValue)}</div>
                                    <div key={`${field.key}_${change.key}_update_${changeIndex}_${value}`}><strong>{translate(`${translationsPrefix}.fieldProperties.newValue`)}</strong>{String(value)}</div>
                                  </Fragment>
                                }
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </Fragment>
            )
          }
        </div>
      )
    }))
  }
