import React, { useContext, useEffect, useState } from 'react';
import { getAuthToken } from './../apollo';
import socket from 'ioClient';

const originalSetItem = localStorage.setItem;
localStorage.setItem = function () {
  // eslint-disable-next-line prefer-rest-params
  const [type, ...ards] = arguments;
  originalSetItem.apply(this, [type, ...ards]);
  if (type === 'session') document.dispatchEvent(new Event('storageChange'));
};

const originalRemoveItem = localStorage.removeItem;
localStorage.removeItem = function () {
  // eslint-disable-next-line prefer-rest-params
  const [type, ...ards] = arguments;
  originalRemoveItem.apply(this, [type, ...ards]);
  if (type === 'session') document.dispatchEvent(new Event('storageChange'));
};

export const SocketContext = React.createContext({});

const STATUS = {
  DISCONECTED: 'DISCONECTED',
  CONNECTING: 'CONNECTING',
  CONNECTED: 'CONNECTED',
};

export const SocketProvider = ({ children }) => {
  const [status, setStatus] = useState(STATUS.DISCONECTED);
  const [ws, setWs] = useState(null);


  const setLocalItem = () => {
    const token = getAuthToken();
    if (token && token !== '') setStatus(STATUS.CONNECTING);
    if (ws && (!token || token === '')) {
      ws.disconnect();
    }
  };

  useEffect(() => {
    document.addEventListener('storageChange', setLocalItem, false);
    const token = getAuthToken();
    if (token && token !== '') setStatus(STATUS.CONNECTING);
    return () => {
      document.removeEventListener('storageChange', setLocalItem);
      ws && ws.disconnect();
    };
  }, []);

  useEffect(() => {
    if (status === STATUS.CONNECTING) {
      const socketIO = socket;
      socketIO.on('connect', () => {
        setStatus(STATUS.CONNECTED);
        setWs(socketIO);
      });

      socketIO.on('disconnect', () => {
        setStatus(STATUS.DISCONECTED);
        setWs(null);
      });
    }
  }, [status]);


  return <SocketContext.Provider value={{
    status, ws,
  }}>{children}</SocketContext.Provider>;
};

export const useSocket = (key, callback) => {
  const { status, ws } = useContext(SocketContext);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (ws) {
      ws.on(key, (data) => {
        if (callback) callback(data);
        if (!callback && message !== data) setMessage(data);
      });
    }
    return () => ws && ws.off(key);
  }, [ws]);

  return {
    status, key, message: !callback ? message : null,
  };
};
