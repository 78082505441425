import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import EditIcon from 'material-ui/svg-icons/image/edit';
import Toggle from 'material-ui/Toggle';
import compose from 'recompose/compose';
import { showNotification as showNotificationAction } from 'admin-on-rest';
import { connect } from 'react-redux';
import path from '../../path';
import { formatDescription } from './helpers';
import socket from 'ioClient';

class MspxLogs extends React.Component {
  constructor(props) {
    super(props);
    this.socket = socket;
    this.state = {
      structures: {
        fx: 'false',
      },
    };
  }

  getState = () => {
    fetch(`${path}/mspx/state/`, {
      method: 'GET',
      headers: {
        Token: localStorage.getItem('session'),
      },
    }).then(res => res.json())
      .then(res => this.setState({ ...this.state, fxState: res }));
  }

  updateConfig = () => {
    fetch(`${path}/mspx/updateConfig/`, {
      method: 'GET',
      headers: {
        Token: localStorage.getItem('session'),
      },
    });
  }

  componentDidMount() {
    this.socket.on('mspx-logs', () => {
      this.getState();
    });

    this.getState();

    fetch(`${path}/mspx/structures/`, {
      method: 'GET',
      headers: {
        Token: localStorage.getItem('session'),
      },
    }).then(res => res.json())
      .then(res => this.setState({ ...this.state, structures: res }))
      .catch(error => console.error(error));
  }

  refreshButton = () => (
    <FlatButton
      label="Refresh"
      icon={<EditIcon color='#01579B' />}
      onClick={this.getState}
      style= {{ minWidth: '30px', padding: '0 15px' }}
    />
  );

  updateConfigButton = () => (
    <FlatButton
      label="Update Config"
      icon={<EditIcon color='#01579B' />}
      onClick={this.updateConfig}
      style= {{ minWidth: '30px', padding: '0 15px' }}
    />
  );

  killQR = () => {
    const { showNotification } = this.props;

    const qr = prompt('Please enter QR to cancel', '123');
    if (qr == null || qr === '') {
      // "User cancelled the request.";
    } else {
      fetch(`${path}/mspx/killqr`, {
        method: 'PUT',
        body: JSON.stringify({ qr }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Token': localStorage.getItem('session'),
        },
      }).then(res => res.json())
        .then(() => {
          showNotification(`Cancel requested for ${qr}`);
        })
        .catch((e) => {
          console.error(e);
          showNotification('Error Cancelling QR', 'warning');
        });
    }
  }

  killQRButton = () => (
    <FlatButton
      label="Kill QR"
      icon={<EditIcon color='#01579B' />}
      onClick={this.killQR}
      style= {{ minWidth: '30px', padding: '0 15px' }}
    />
  );

  onToggleFx = () => {
    if (window.confirm('Are you sure you wish to turn On/Off "FX Forwards & NDFs"?')) {
      const { structures: { fx } } = this.state;
      const value = !(fx === 'true') === true ? 'true' : 'false';
      this.updateStructure('fx', value);
    }
  }

  updateStructure(type, value) {
    fetch(`${path}/mspx/structures/${type}`, {
      method: 'PUT',
      body: JSON.stringify({ value }),
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    }).then(res => res.json())
      .then((res) => {
        this.setState(state => ({
          ...state,
          structures: {
            ...state.structures,
            ...res,
          },
        }));
      })
      .catch(error => console.error(error));
  }

  render() {
    const toggleStyle = { width: '200px', display: 'inline-block', margin: '15px' };
    const { fxState, structures: { fx } } = this.state;
    return (
      <div>
        <div>
          <Toggle
            toggled={fx === 'true'}
            onToggle={this.onToggleFx}
            label='FX Forwards & NDFs:'
            style={toggleStyle}
          />
        </div>
        {this.refreshButton()}
        {this.killQRButton()}
        {this.updateConfigButton()}
        {formatDescription(fxState)}
      </div>
    );
  }
}

const enhance = compose(
  connect(null, {
    showNotification: showNotificationAction,
  }),
);

export default enhance(MspxLogs);

