import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import CircularProgress from 'material-ui/CircularProgress';

import { connect } from 'react-redux';
import { translate } from 'admin-on-rest';

import PDFGenerateIcon from './PDFGenerateIcon';
import PDFGenerateAction from './PDFGenerateAction';

const styleLoader = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  backgroundColor: 'rgba(255,255,255, 0.3)',
  overflowX: 'hidden',
  transition: '0.5s',
};

class PDFGenerateButton extends React.Component {
  state = {
    load: false,
  };
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  onClick = () => {
    const { record } = this.props;

    this.props.generateAction({
      dashboard: record.id,
      name: record.name,
      session: localStorage.getItem('session'),
    });

    this.setState({ load: true });
  }

  isLoad() {
    return this.state.load;
  }

  render() {
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label={this.translate('dashboards.list.btnGenerate')}
          icon={<PDFGenerateIcon />}
          onClick={this.onClick}
        />
        {this.isLoad() ? <div style={styleLoader}><CircularProgress size={80} thickness={5} /></div> : ''}
      </div>
    );
  }
}

PDFGenerateButton.propTypes = {
  record: PropTypes.object,
  generateAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    generateAction: PDFGenerateAction,
  }),
);

export default enhance(PDFGenerateButton);
