import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import DoneAllIcon from 'material-ui/svg-icons/action/done-all';
import ClearAllIcon from 'material-ui/svg-icons/content/clear';

import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';

import { connect } from 'react-redux';
import { translate, GET_LIST, showNotification as showNotificationAction, refreshView as refreshViewAction } from 'admin-on-rest';

import PDFGroupGenerateIcon from './PDFGroupGenerateIcon';
import PDFSendManyAction from './PDFSendManyAction';

import restClient from '../restClient';
import path from '../path';

const styleLoader = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  position: 'fixed',
  zIndex: 1,
  left: '270px',
  top: 0,
  backgroundColor: 'rgba(255,255,255, 0.8)',
  overflowX: 'hidden',
  transition: '0.5s',
};

class PDFSendManyButton extends React.Component {
  state = {
    open: false,
    openD: false,
    load: false,
    error: false,
    dashboards: [],
    chips: [],
    ids: [],
    dashboardsNotGenereted: [],
  }

  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  isLoad() {
    return this.state.load;
  }

  isClear() {
    const { chips, dashboards } = this.state;

    const ids = dashboards.map(i => i.id);
    const dif = chips.filter(i => !ids.includes(i.id));

    return !dif.length;
  }

  handleClear = () => {
    this.setState({ dashboards: [] });
  }

  handleSelect = () => {
    const { chips } = this.state;
    this.setState({ dashboards: chips });
  }

  handleOpen = () => {
    this.setState({ open: true });

    restClient(GET_LIST, 'client_database/dashboards', {
      sort: {
        field: 'id',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((resA) => {
      this.setState({ chips: resA.data });
    });

    restClient(GET_LIST, 'client_database/dashboards/lastHour', {
      sort: {
        field: 'id',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((res) => {
      this.setState({ dashboards: res.data });
    });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  handleCloseD = () => {
    const { refreshView } = this.props;

    this.setState({ openD: false });
    refreshView();
  }

  handleSubmit = () => {
    const { showNotification } = this.props;

    if (this.state.dashboards.length) {
      this.setState({ error: false, open: false, load: true });
    } else {
      this.setState({ error: true });
      return;
    }

    const { dashboards } = this.state;
    const session = localStorage.getItem('session');

    fetch(`${path}/admin/pdfs`, { method: 'PUT',
      body: JSON.stringify({ dashboards }),
      headers: {
        'Token': session,
        'Content-Type': 'application/json; charset=utf-8',
      } })
      .then(response => response.json())
      .then((data) => {
        if (data.dashboardSuccess.length !== 0) {
          showNotification('Email was sent');
        }
        if (data.dashboards.length !== 0) {
          this.setState({ error: false, open: false, load: false, openD: true, dashboardsNotGenereted: data.dashboards });
        } else {
          showNotification('Email was sent');
          this.setState({ error: false, open: false, load: false });
        }
      })
      .catch((e) => {
        console.error(e);
        showNotification('Error', 'warning');
      });
  }

  handleAddDashboard = (chip) => {
    const chips = [...this.state.dashboards, chip];
    this.setState({ dashboards: chips });
  }

  handleDeleteDashboard = (chip) => {
    const chips = this.state.dashboards.filter(i => i.id !== chip);
    this.setState({ dashboards: chips });
  }

  handleBeforeAddDashboard = (chip) => {
    const { dashboards } = this.state;
    return !dashboards.map(i => i.id).includes(chip.id);
  }

  dataSource() {
    const { dashboards } = this.state;

    const ids = dashboards.map(i => i.id);

    return this.state.chips.filter(c => !ids.includes(c.id));
  }

  render() {
    const { open, openD, dashboards } = this.state;
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label={this.translate('pdfs.dialog.btnSendMany')}
          icon={<PDFGroupGenerateIcon />}
          onClick={this.handleOpen}
        />
        {this.isLoad() ? <div style={styleLoader}><CircularProgress size={80} thickness={5} style={{ right: '15%' }}/><p style={{ position: 'relative', color: 'rgb(0, 188, 212)', fontSize: '14px', right: '15%' }}>{this.translate('pdfs.dialog.loaderMail')}</p></div> : ''}
        <Dialog
          title={this.translate('pdfs.dialog.titleSendMany')}
          modal={false}
          open={open}
          autoScrollBodyContent={true}
        >
          <ChipInput
            value={dashboards}
            dataSource={this.dataSource()}
            openOnFocus={true}
            dataSourceConfig={{ text: 'name', value: 'id' }}
            onBeforeRequestAdd={this.handleBeforeAddDashboard}
            onRequestAdd={this.handleAddDashboard}
            onRequestDelete={this.handleDeleteDashboard}
            floatingLabelText={this.translate('pdfs.dialog.textDashboards')}
            errorText={this.state.error && this.translate('aor.validation.required')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            {this.isClear() ?
              <RaisedButton
                label={this.translate('pdfs.dialog.btnClearAll')}
                onClick={this.handleClear}
                icon={<ClearAllIcon />}
              />
              :
              <RaisedButton
                label={this.translate('pdfs.dialog.btnSelectAll')}
                onClick={this.handleSelect}
                icon={<DoneAllIcon />}
              />
            }
            <RaisedButton
              style={{ marginLeft: '10px' }}
              label={this.translate('pdfs.dialog.btnCancel')}
              onClick={this.handleClose}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label={this.translate('pdfs.dialog.btnSubmit')}
              primary={true}
              onClick={this.handleSubmit}
              icon={<SubmitIcon />}
            />
          </div>
        </Dialog>
        <Dialog
          title={this.translate('pdfs.dialog.titleNotGenerated')}
          modal={false}
          open={openD}
          autoScrollBodyContent={true}
        >
          { this.state.dashboardsNotGenereted.map((item, i) => (
            <li className="travelcompany-input" key={i}>
              <span className="input-label">{ item.name }</span>
            </li>
          ))}
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              style={{ marginLeft: '10px' }}
              label={this.translate('pdfs.dialog.btnClose')}
              onClick={this.handleCloseD}
              icon={<CancelIcon />}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

PDFSendManyButton.propTypes = {
  record: PropTypes.object,
  sendManyAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
    sendManyAction: PDFSendManyAction,
  }),
);

export default enhance(PDFSendManyButton);
