import { DELETE } from 'admin-on-rest';
import { ACCUMULATOR_DESCRIPTION_DELETE } from './AccumulatorDescriptionAction';

const AccumulatorDescriptionDeleteAction = (ids, data, basePath) => ({
  type: ACCUMULATOR_DESCRIPTION_DELETE,
  payload: { id: ids.join('-'), data, basePath },
  meta: { resource: 'accumulator_description', fetch: DELETE, cancelPrevious: false },
});

export default AccumulatorDescriptionDeleteAction;
