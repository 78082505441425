const defaultState = {
  name: null,
  access: [],
};

export const SET_CURRENT_ADMIN_ACTION = 'SET_CURRENT_ADMIN_ACTION';

export const currentAdminReceived = admin => ({
  type: SET_CURRENT_ADMIN_ACTION,
  payload: { ...admin },
});

export const currentAdminReducer = (previousState = defaultState, { type, payload }) => {
  switch (type) {
  case SET_CURRENT_ADMIN_ACTION:
    return { ...previousState, ...payload };
  default:
    return previousState;
  }
};
