import FlatButton from 'material-ui/FlatButton';
import HistoryIcon from 'material-ui/svg-icons/action/assignment';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

const HistoryButton = (props) => {
  const { id, history, url } = props;

  const onClick = () => {
    history.push(`/${url}/${id}`);
  };

  return (
    <FlatButton
      primary
      onClick={onClick}
      icon={<HistoryIcon />}
      style={{ float: 'left', minWidth: '30px' }}
    />
  );
};

HistoryButton.propTypes = {
  id: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  url: PropTypes.object.isRequired,
};

export default withRouter(HistoryButton);
