import { FunctionField, NumberField, ReferenceField, Show, SimpleShowLayout, TextField, translate } from 'admin-on-rest';
import React from 'react';
import { DateField } from '../helpers';
import { formatDateTimeInput } from '../utils/dates';

const PricingTitle = translate(({ record }) =>
  <span>{translate('pricingHistories.edit.title', { smart_count: 1 })} { `${record.value} ${record.currency}` }</span>);

const PricingHistoryShow = props => (
  <Show {...props}
    title={<PricingTitle />}
  >
    <SimpleShowLayout>
      <DateField
        formatDate={formatDateTimeInput}
        label="Time"
        source="created_at"
        showTime
      />
      <ReferenceField
        label="User"
        source="user_id"
        reference="users"
        linkType="show"
      >
        <FunctionField
          render={r => `${r.firstName} ${r.lastName}`}
        />
      </ReferenceField>
      <ReferenceField
        label="Strucutre"
        source="structure_id"
        reference="structures"
        linkType="show"
      >
        <FunctionField
          render={r => r.code}
        />
      </ReferenceField>
      <ReferenceField
        label="Bloomberg Ticker"
        source="underlying_id"
        reference="underlyings"
        linkType="show"
      >
        <FunctionField
          render={r => r.bloomberg_ticker}
        />
      </ReferenceField>
      <ReferenceField
        label="Underlying"
        source="underlying_id"
        reference="underlyings"
        linkType="show"
      >
        <FunctionField
          render={r => r.reference_contract}
        />
      </ReferenceField>
      <NumberField
        style={{ textAlign: 'left' }}
        source="notional"
      />
      <TextField
        source="currency"
      />
      <TextField
        source="type"
      />
      <NumberField
        style={{ textAlign: 'left' }}
        source="reference_price"
      />
      <NumberField
        style={{ textAlign: 'left' }}
        label="Price"
        source="value"
      />
      <NumberField
        style={{ textAlign: 'left' }}
        label="Accumulation"
        source="strike_level"
      />
      <NumberField
        style={{ textAlign: 'left' }}
        label="Barrier"
        source="barrier_level"
      />
      <TextField
        source="tag"
      />
    </SimpleShowLayout>
  </Show>
);

export default PricingHistoryShow;
