import { showNotification as showNotificationAction, translate } from 'admin-on-rest';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import { Tab, Tabs } from 'material-ui/Tabs';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import request from 'superagent';
import path from '../../../path';
import { addOrderToPopup as addOrderToPopupAction } from '../../actions';
import ApproveButton from './../ButtonsWrapper/ApproveButton';
import CancelButton from './../ButtonsWrapper/CancelButton';
import RejectButton from './../ButtonsWrapper/RejectButton';
import GeneralTab from './GeneralTab';
import OrderTab from './OrderTab';
import style, { styleTitle } from './style';

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      orderId: null,
    };
    this.translate = this.props.translate;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.order !== this.props.order || nextState.open !== this.state.open ||
      (nextState.orderId !== this.state.orderId && nextState.orderId);
  }

  componentWillReceiveProps({ history: { location } }) {
    const pathname = location.pathname.split('/');
    if (pathname.length > 0 && parseInt(pathname[2], 0) > 0) {
      if (this.state.orderId !== parseInt(pathname[2], 0)) {
        this.setState(state => ({ ...state, orderId: parseInt(pathname[2], 0) }));
      }
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.state.orderId !== prevState.orderId && this.state.orderId) {
      this.fetchOrder(this.state.orderId);
    }
  }

  fetchOrder = (orderId) => {
    const { showNotification, addOrderToPopup, location } = this.props;

    request
      .get(`${path}/admin/neon_orders/${orderId}${location.search}`)
      .set('token', localStorage.getItem('session'))
      .then(({ body }) => {
        if (!body || !body.id) {
          this.props.history.push('/neon_orders');
          throw new Error('This order does not exist');
        }
        addOrderToPopup(body);
        this.setState(state => ({ ...state, open: true }));
      })
      .catch(() => showNotification('NeonOrders.get.status.fail', 'warning'));
  }

  handleClose() {
    this.setState({
      open: false,
      orderId: null,
    }, () => {
      this.props.history.push('/neon_orders');
    });
  }

  title() {
    const { order } = this.props;
    const direction = order.direction === 'buyer' ? 'Buy' : 'Sell';
    const orderUnit = order && order.factor !== 1 ? '' : 'lots ';
    const title = `Order #${order.id} - ${order.status} - ${direction} ${order.quantity} ${orderUnit}${order.commodity_contract} ${moment(order.contract_expiry || order.trade_date).format('MMM YY')} @ ${order.price}`;
    return (<h3 style={styleTitle.title}>
      {title}
      {order.badge && order.badge === 'UPDATED' ? (<span style={styleTitle.badge} className="badge badge-yellow">UPDATED</span>) : ''}
    </h3>);
  }

  render() {
    const { order } = this.props;
    const { open } = this.state;
    return (
      <Dialog
        title={this.title()}
        modal={false}
        open={open}
        autoScrollBodyContent={true}
      >
        <Tabs>
          <Tab className="form-tab" label='General' >
            <GeneralTab order={order} />
          </Tab>
          <Tab className="form-tab" label='Order' >
            <OrderTab order={order} />
          </Tab>
        </Tabs>
        <div className="popup-action-bar">
          <div className="button-wrapper">
            <CancelButton record={order} label={this.translate('NeonOrders.actions.cancel')} />
          </div>
          <div className="button-wrapper">
            <RejectButton record={order} label={this.translate('NeonOrders.actions.reject')} />
          </div>
          <div className="button-wrapper">
            <ApproveButton record={order} label={this.translate('NeonOrders.actions.approve')} />
          </div>
        </div>
        <FlatButton
          label='Close'
          onClick={this.handleClose.bind(this)}
          style={{ position: 'absolute', right: 0, top: 0 }}
          icon={<CancelIcon />}
        />
        <style>{style}</style>
      </Dialog >
    );
  }
}

Popup.propTypes = {
  translate: PropTypes.func,
};

const mapStateToProps = ({
  admin: { resources: { neon_orders: { data } } },
  neonOrdersReducer: { order },
}) => ({
  data,
  order,
});

const mapDispatchToProps = {
  showNotification: showNotificationAction,
  addOrderToPopup: addOrderToPopupAction,
};

export default compose(
  translate,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Popup);
