import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import RaisedButton from 'material-ui/RaisedButton';

import { connect } from 'react-redux';
import { translate } from 'admin-on-rest';

import MarketDataStructureIcon from './MarketDataStructureIcon';
import MarketDataStructureAction from './MarketDataStructureAction';

class MarketDataStructureButton extends React.Component {
  state = {
    open: false,
    structure: [],
  }
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  handleSubmit = () => {
    this.setState({ open: false });

    const { record } = this.props;
    const { structure } = this.state;

    this.props.structureAction(record.id, { structure });
  }

  handleBeforeAddStructure = (chip) => {
    const { structure } = this.state;
    return !structure.includes(chip.id);
  }

  handleAddStructure = (chip) => {
    const structure = [...this.state.structure, chip.id];
    this.setState({ structure });
  }

  handleDeleteStructure = (chip) => {
    const structure = this.state.structure.filter(i => i !== chip);
    this.setState({ structure });
  }

  componentDidMount() {
    const { record } = this.props;
    this.setState({ structure: record.structure });
  }

  render() {
    const { record } = this.props;
    const { structure, open } = this.state;

    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p style={{ fontSize: '8px', padding: '0 10px 0 0' }} dangerouslySetInnerHTML={{ __html: record.structure.length ? record.structure.join('<br/>') : '-' }}>

        </p>
        <FlatButton
          labelStyle={{ fontSize: '8px' }}
          label="Choose Structure"
          icon={<MarketDataStructureIcon />}
          onClick={this.handleOpen}
        />
        <Dialog
          title={`Choose Structure For Deactivate Market Data ${record && record.bloomberg_ticker}`}
          modal={false}
          open={open}
          autoScrollBodyContent={true}
        >
          <ChipInput
            value={structure.map(i => ({ name: i, id: i }))}
            dataSourceConfig={{ text: 'name', value: 'id' }}
            dataSource={[{
              name: 'Accumulator',
              id: 'Accumulator',
            }, {
              name: 'Vanilla',
              id: 'Vanilla',
            }]}
            openOnFocus={true}
            onBeforeRequestAdd={this.handleBeforeAddStructure}
            onRequestAdd={this.handleAddStructure}
            onRequestDelete={this.handleDeleteStructure}
            floatingLabelText="Choose structure"
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              label="Cancel"
              onClick={this.handleClose}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label="Submit"
              primary={true}
              onClick={this.handleSubmit}
              icon={<SubmitIcon />}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

MarketDataStructureButton.propTypes = {
  record: PropTypes.object,
  structureAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    structureAction: MarketDataStructureAction,
  }),
);

export default enhance(MarketDataStructureButton);
