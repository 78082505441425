import { Datagrid, DeleteButton, FunctionField, List, Responsive, SimpleList, TextField } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';
import CronActions from './CronActions';
import CronFilters from './CronFilters';
import CronToggles from './CronToggles';

const CronList = props => (
  <List {...props}
    actions={<CronActions />}
    filters={<CronFilters />}
    sort={{ field: 'updated_at', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => r.name}
          secondaryText={r => r.status}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.updated_at}</Moment>}
        />
      }
      medium={
        <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
          <TextField
            source="name"
          />
          <TextField
            source="time_exec"
          />
          <TextField
            source="time_zone"
          />
          <FunctionField
            label="Status"
            render={r => (r.status ? r.status : '-')}
          />
          <FunctionField
            label="Active"
            render={r => <CronToggles record={r} />}
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="updated_at"
            showTime
          />
          <DeleteButton />
        </Datagrid>
      }
    />
  </List>
);

export default CronList;
