import React from 'react';
import DownloadJson from '../../components/DownloadJson';
import { StructureDataRecord, WrapperDataRecord } from '../types';

interface DownloadLayoutJsonProps {
  record?: StructureDataRecord | WrapperDataRecord;
  defaultFileName: string;
}

const DownloadLayoutJson: React.FC<DownloadLayoutJsonProps> = ({ record = {}, defaultFileName }) => {
  const { layout, layoutFileUploadStatus, layoutFilename } = record;
  return (
    <DownloadJson jsonFile={layout} fileStatus={layoutFileUploadStatus} fileName={layoutFilename || defaultFileName} />
  );
};

export default DownloadLayoutJson;
