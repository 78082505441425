import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { formValueSelector, change, Field } from 'redux-form';
import { Table, TableBody, TableRow, TableRowColumn, TableHeader, TableHeaderColumn } from 'material-ui/Table';
import RaisedButton from 'material-ui/RaisedButton';
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar';
import DeleteIcon from 'material-ui/svg-icons/content/remove-circle';
import AddIcon from 'material-ui/svg-icons/content/add-circle';
import { required, number } from 'admin-on-rest';
import { RECORD_FORM, roundMargin } from '../helpers';
import HeadingsRow from './components/HeadingsRow';
import TextFieldWrapper from './components/TextFieldWrapper';

import usePopulateProductFeatureIfNewRecord from './hooks/usePopulateProductFeatureIfNewRecord';
import useTenorList from './hooks/useTenorList';
import useUpdateTenorsCallback from './hooks/useUpdateTenorsCallback';
import useUpdateUnderlyingsCallbacks from './hooks/useUpdateUnderlyingsCallbacks';

const NOT_REQUIRED_LIST = [0.25, 0.5, 0.75];

const TenorUnderlyingControl = ({
  record,
  change,
  productFeature,
}) => {
  const changeField = (fieldName, value) => change(RECORD_FORM, fieldName, value);

  usePopulateProductFeatureIfNewRecord(record, changeField);

  const { tenorList = [] } = useTenorList(productFeature);
  const { addTenor, removeTenor } = useUpdateTenorsCallback(tenorList, productFeature, changeField);
  const { addUnderlying, removeUnderlying } = useUpdateUnderlyingsCallbacks(tenorList, productFeature, changeField);

  return (
    <Fragment>
      <Toolbar>
        <ToolbarGroup>
          <ToolbarTitle text="Product Feature" />
        </ToolbarGroup>
        <ToolbarGroup>
          <ToolbarSeparator />
          <RaisedButton label="Add Tenor" secondary={true} icon={<AddIcon />} onClick={addTenor} />
          <RaisedButton label="Remove Tenor" secondary={true} icon={<DeleteIcon />} onClick={removeTenor} />
          <RaisedButton label="Add Underlying" secondary={true} icon={<AddIcon />} onClick={addUnderlying} />
          <RaisedButton label="Remove Underlying" secondary={true} icon={<DeleteIcon />} onClick={removeUnderlying} />
        </ToolbarGroup>
      </Toolbar>
      <Table selectable={false}>
        <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
          <TableRow>
            <TableHeaderColumn style={{ width: '120px' }}>
              <b>Underlying</b>
            </TableHeaderColumn>
            <TableHeaderColumn colSpan={tenorList.length} style={{ textAlign: 'center' }}>
              <b>Tenor</b>
            </TableHeaderColumn>
          </TableRow>
          <HeadingsRow tenorList={tenorList}/>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {productFeature.map((underlying, j) => (
            <TableRow key={j}>
              <TableRowColumn style={{ width: '120px' }}>{j + 1}</TableRowColumn>
              {underlying.map(({ tenor }, i) => (
                <TableRowColumn key={i} style={{ textAlign: 'left' }}>
                  <Field
                    name={`productFeature[${j}][${i}].margin`}
                    component={TextFieldWrapper}
                    type="number"
                    validate={NOT_REQUIRED_LIST.includes(tenor) ? [number] : [required, number]}
                    normalize={roundMargin}
                    placeholder={NOT_REQUIRED_LIST.includes(tenor) ? 'optional' : 'margin'}
                  />
                </TableRowColumn>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Fragment>
  );
};


const selector = formValueSelector(RECORD_FORM);

const enhance = compose(
  connect((state, { source }) => {
    const obj = {
      productFeature: selector(state, 'productFeature') || [],
      data: selector(state, source),
    };
    return obj;
  }, { change }),
);

export default enhance(TenorUnderlyingControl);
