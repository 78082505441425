import React from 'react';
import {
  Edit,
  translate,
} from 'admin-on-rest';

import { ThemeTemplateForm } from './components';

const ThemeTemplateTitle = translate(({ record }) =>
  <span>Edit { record.name }</span>);

const EditThemeTemplate = props =>
  <Edit {...props} title={<ThemeTemplateTitle />}>
    <ThemeTemplateForm />
  </Edit>;

export default EditThemeTemplate;
