import React from 'react';
import { Datagrid, List, RichTextField, TextField, DeleteButton, EditButton } from 'admin-on-rest';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';

const Delete = (props) => {
  const { record: { id } } = props;
  return id !== 1 ? <DeleteButton {...props} /> : null;
};

const AdminRolesList = props => (
  <List {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    perPage={50}
  >
    <Datagrid
      bodyOptions={{ showRowHover: true }}
      options={{ height: '70vh' }}
      styles={datagridDefaultStyles}
    >
      <TextField
        source="id"
      />
      <TextField
        source="title"
      />
      <RichTextField
        source="description"
      />
      <DateField
        formatDate={formatDateTimeInput}
        source="updatedAt"
        showTime
      />
      <DateField
        formatDate={formatDateTimeInput}
        source="createdAt"
        showTime
      />
      <EditButton />
      <Delete />
    </Datagrid>
  </List>
);

export default AdminRolesList;
