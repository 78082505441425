import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { RefreshButton } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import DateRangePicker from './../components/DateRangePicker';
import OTCCreateButton from './OTCCreateButton';
import ToPDFButton from './ToPDFButton';
import OTCUploadButton from './OTCUploadButton';
import { Loader } from '../helpers/Loader';
import path from '../path';

const REQ_DATE_FORMAT = 'YYYY-MM-DD';

const style = {
  dateField: {
    width: '150px',
  },
};

const getDefaultdates = () => ([
  moment().subtract(1, 'M').startOf('M').toDate(),
  moment().endOf('D').toDate(),
]);

const ListActions = (props) => {
  const [defaultStart, defaultEnd] = getDefaultdates();
  const { resource, filters, displayedFilters, filterValues, showFilter } = props;
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(defaultStart);
  const [endDate, setEndDate] = useState(defaultEnd);

  const changeDateHandler = (dates) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };

  useEffect(() => {
    const [start, end] = getDefaultdates();
    setStartDate(start);
    setEndDate(end);
  }, [open]);


  const handleExportModal = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoad(false);
  };

  const handleExportCSV = () => {
    setLoad(true);
    setOpen(false);

    const url = new URL(`${path}/admin/otc_confirmation/export`);
    url.searchParams.append('startDate', moment(startDate).format(REQ_DATE_FORMAT));
    url.searchParams.append('endDate', moment(endDate).format(REQ_DATE_FORMAT));

    fetch(url, {
      method: 'GET',
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      .then((href) => {
        const div = document.getElementsByClassName('body')[0];
        const link = document.createElement('a');
        link.setAttribute('href', href);
        link.setAttribute('download', 'OTC_Confirmations.csv');
        div.appendChild(link);
        link.click();
        div.removeChild(link);
        setLoad(false);
      })
      .catch((error) => {
        console.error('error', error);
        setLoad(false);
      });
  };

  const exportExtIdCSV = (e) => {
    e.preventDefault();
    setLoad(true);
    fetch(`${path}/admin/otc_confirmation/exportExternalId`, {
      method: 'GET',
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      .then((href) => {
        const div = document.getElementsByClassName('body')[0];
        const link = document.createElement('a');
        link.setAttribute('href', href);
        link.setAttribute('download', 'External_ID_list.csv');
        div.appendChild(link);
        link.click();
        div.removeChild(link);
        setLoad(false);
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  const isDisabled = !startDate || !endDate;

  const actions = [
    <FlatButton
      key="CancelExportOtcFlatButton"
      label="Cancel"
      primary={true}
      onClick={handleClose}
    />,
    <FlatButton
      key="SubmitOtcFlatButton"
      label="Submit"
      primary={true}
      keyboardFocused={true}
      disabled={isDisabled}
      onClick={handleExportCSV}
    />,
  ];

  return (
    <CardActions>
      {load ? <Loader text='Please wait...' /> : ''}
      {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' })}
      <Dialog
        title="Export Confirmations"
        actions={actions}
        modal={true}
        open={open}
        onRequestClose={handleClose}
      >
        Please adjust your date range filter.
        <div style={{ textAlign: 'center' }}>
          <p>
            <TextField
              style={style.dateField}
              disabled={true}
              value={startDate ? moment(startDate).format('DD MMMM YYYY') : ''}
              floatingLabelText="Start Date"
            />
            <span> to </span>
            <TextField
              style={style.dateField}
              disabled={true}
              value={endDate ? moment(endDate).format('DD MMMM YYYY') : ''}
              floatingLabelText="End Date"
            />
          </p>
          <DateRangePicker
            from={startDate}
            to={endDate}
            maxDate={moment().endOf('D').toDate()}
            onChange={changeDateHandler}
          />
        </div>
      </Dialog>
      <RefreshButton />
      <ToPDFButton />
      <OTCCreateButton />
      <OTCUploadButton />
      <FlatButton primary label="Export External ID" onClick={exportExtIdCSV} />
      <FlatButton primary label="Export Confirmations" onClick={handleExportModal} />
    </CardActions>
  );
};

export default ListActions;
