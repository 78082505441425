import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { CardActions } from 'material-ui/Card';
import {
  Responsive,
  Datagrid,
  DeleteButton,
  refreshView,
  List,
  TextField,
  BooleanField,
  FunctionField,
  RefreshButton,
  Filter,
  TextInput,
  DateInput,
} from 'admin-on-rest';
import { formatDateInput } from '../utils/dates';
import { DateField } from '../helpers';
import PricingCardViewTemplatesCreateEditButton from './PricingCardViewTemplatesCreateEdit';
import MultiplyChips from '../helpers/MultiplyChips';
import PricingCardViewTemplatesDefault from './PricingCardViewTemplatesDefault';

const Actions = () => (
  <CardActions>
    <RefreshButton />
    <PricingCardViewTemplatesDefault />
    <PricingCardViewTemplatesCreateEditButton />
  </CardActions>
);

const ButtonsWrapper = props => (
  <div style={{ width: '230px' }}>
    <PricingCardViewTemplatesCreateEditButton {...props} edit />
    <DeleteButton {...props} />
  </div>
);

const formatLabels = labels => {
  try {
    labels = labels.map((el, i) => (
      <p
        style={{ padding: '0', margin: '0', fontSize: '12px' }}
        key={i}
        dangerouslySetInnerHTML={{ __html: `<b>Label ${i + 1}:</b> ${el} <br/>` }}
      ></p>
    ));
  } catch (err) {
    console.log(err, 'err');
    labels = '';
  }
  return labels;
};

const formatPremiumFields = fields => {
  if (fields) {
    return Object.entries(fields).map(([field, value], i) => (
      <p
        style={{ padding: '0', margin: '0', fontSize: '12px' }}
        key={i}
        dangerouslySetInnerHTML={{ __html: `<b>${field.replace(/_/g, ' ').replace('premium', '')}</b>: ${value} <br/>` }}
      ></p>
    ));
  }
};

const Filters = props => (
  <Filter {...props}>
    <TextInput label="pricingTemplates.search" source="_q" alwaysOn />
    <TextInput source="name" />
    <DateInput label="Created" options={{ formatDate: formatDateInput }} source="createdAt" showTime />
  </Filter>
);
class PricingCardViewTemplatesList extends React.Component {
  render() {
    return (
      <List
        {...this.props}
        sort={{ field: 'createdAt', order: 'DESC' }}
        filters={<Filters />}
        perPage={50}
        refreshView={true}
        title="CM Pricing Card Customization"
        actions={<Actions />}
      >
        <Responsive
          medium={
            <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }}>
              <TextField source="id" />
              <TextField label="Template Name" source="templateName" />
              <MultiplyChips label="Structures" source="structureLabels" sortable={false} />
              <FunctionField sortable={false} source="Labels" render={r => formatLabels(r.labels)} />
              <BooleanField label="Is Premium" source="isPremium" />
              <FunctionField sortable={false} label="Premium" source="premiumAmount" render={r => formatPremiumFields(r.premiumAmount)} />
              <TextField label="Description" source="description" />
              <DateField label="Updated At" formatDate={date => moment(date).format('DD MMMM YYYY HH:mm')} source="updatedAt" showTime />
              <DateField label="Created At" formatDate={date => moment(date).format('DD MMMM YYYY HH:mm')} source="createdAt" showTime />
              <ButtonsWrapper />
            </Datagrid>
          }
        />
      </List>
    );
  }
}

export default connect(null, {
  refreshView,
})(PricingCardViewTemplatesList);
