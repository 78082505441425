import { connect } from 'react-redux';
import { Datagrid, DateInput, Filter, List, Responsive, SimpleList, TextField, TextInput, BooleanField } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput, formatDateInput, dateParser } from '../utils/dates';

const AccumulatorStructuresFilter = props => (
  <Filter {...props}>
    <TextInput
      source="above_amount"
    />
    <TextInput
      source="below_amount"
    />
    <TextInput
      source="call_put_1"
    />
    <TextInput
      source="call_put_2"
    />
    <TextInput
      source="instrument_type"
    />
    <TextInput
      source="leverage"
    />
    <TextInput
      source="structure"
    />
    <TextInput
      source="trigger_amount"
    />
    <TextInput
      source="trigger_level_multiplier"
    />
    <TextInput
      source="trigger_type"
    />
    <TextInput
      source="xml_type"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
      parse={dateParser}
    />
  </Filter>
);

const HIDE = ['id', 'total_count', 'inputs'];
const BOOLEAN_FIELDS = ['book_barrier_shift', 'use_msa', 'validation_for_mini_card'];

const renderFieldStrategy = (column) => {
  if (BOOLEAN_FIELDS.includes(column)) return <BooleanField key={column} source={column} />;
  if (column !== 'created_at') return <TextField key={column} source={column} sortable={!column.includes('argument')} />;
  return (<DateField
    formatDate={formatDateTimeInput}
    source="created_at"
    showTime
  />);
};

class AccumulatorStructuresList extends React.Component {
  render() {
    const { columns } = this.props;
    const visible = columns && columns.filter(column => !HIDE.includes(column));
    return (<List {...this.props}
      filters={<AccumulatorStructuresFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={50}
    >
      <Responsive
        small={
          <SimpleList
            primaryText={r => `${r.value} ${r.currency}`}
            secondaryText={r => `${r.strike_level} ${r.barrier_level}`}
            tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
          />
        }
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
            {visible && visible.map(column => (renderFieldStrategy(column)))}
          </Datagrid>
        }
      />
    </List>);
  }
}
const mapStateToProps = ({
  admin: { resources: { accumulator_structures: { data } } },
}) => {
  const numberOfKeys = data && Object.keys(data).map((i) => ({ id: i, total: Object.keys(data[i]).length }));
  const maxTotal = Math.max(...numberOfKeys.map(i => i.total));
  const key = numberOfKeys.find(i => i.total === maxTotal);
  const item = key ? data[key.id] : null;
  const columns = item ? Object.keys(item) : null;
  return {
    columns,
    data,
  };
};

export default connect(mapStateToProps)(AccumulatorStructuresList);
