import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  LongTextInput
} from 'admin-on-rest';

import {
  validateMaxLength200,
  validateMaxLength5000,
  validateMinLengthName,
} from '../../utils/validations';
import ShowFile from './../../components/ShowFile';

const SLACK_WORKFLOW_OPTIONS = [
  { id: 'without_booking', name: 'without_booking' },
  { id: 'current_workflow', name: 'current_workflow' }
];

export const PricingCardForm = props => {
  const { record: { id, card_json, file_upload_status, file_name } } = props;
  return (
    <SimpleForm {...props} redirect="list">
      <TextInput
        label="pricingCards.form.titleField"
        source="title"
        validate={[required, validateMinLengthName, validateMaxLength200]}
        elStyle={{ width: '50%' }}
      />
      <LongTextInput
        label="pricingCards.form.description"
        source="description"
        validate={[required, validateMinLengthName, validateMaxLength5000]}
        elStyle={{ width: '50%' }}
      />

      <ReferenceInput
        key="id"
        source="structure_code"
        reference="accumulator_structures"
        label="pricingCards.form.structure"
        validate={[required]}
        elStyle={{ width: '50%' }}
        filterToQuery={searchText => ({ structure: searchText })}
        sort={{ field: 'structure', order: 'ASC' }}
      >
        <AutocompleteInput
          optionText="structure"
          optionValue="id"
          options={{
            onBlur: () => { },
          }}
        />
      </ReferenceInput>
      <BooleanInput label="pricingCards.form.flexible" source="flexible" defaultValue/>
      {file_upload_status ? <SelectInput label="pricingCards.form.slack_workflow" source="slack_workflow_type" choices={SLACK_WORKFLOW_OPTIONS}/> : null}
      <ShowFile id={id} cardJson={card_json} fileStatus={file_upload_status} fileName={file_name || 'pricing_mini_card.json'}/>
    </SimpleForm>
  )
};
