import React from 'react';

import { RefreshButton } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';

const ListActions = ({ resource, filters, displayedFilters, filterValues, showFilter }) => (
  <CardActions>
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    <RefreshButton />
  </CardActions>
);

export default ListActions;
