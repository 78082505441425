import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import DoneAllIcon from 'material-ui/svg-icons/action/done-all';
import ClearAllIcon from 'material-ui/svg-icons/content/clear';

import RaisedButton from 'material-ui/RaisedButton';

import { connect } from 'react-redux';
import { translate, GET_LIST, refreshView as refreshViewAction, showNotification as showNotificationAction } from 'admin-on-rest';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import PDFGroupGenerateIcon from './PDFGroupGenerateIcon';
import PDFGroupGenerateAction from './PDFGroupGenerateAction';
import { Loader } from './Loader';

import restClient from '../restClient';
import path from '../path';

class PDFGroupGenerateButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      openDialog: false,
      load: false,
      errors: {
        generalDashboards: false,
        checkDashboards: false,
      },
      dashboards: [],
      chips: [],
      checkChips: [],
      ids: [],
      checkDashboards: [],
      statementsNotPulled: [],
      statementsCsvNotPulled: [],
    };

    this.translate = this.props.translate;
  }

  isLoad() {
    return this.state.load;
  }

  isClear() {
    const { chips, dashboards } = this.state;

    const ids = dashboards.map(i => i.id);
    const dif = chips.filter(i => !ids.includes(i.id));

    return !dif.length;
  }

  handleClear = () => {
    this.setState({ dashboards: [] });
  }

  handleSelect = () => {
    const { chips } = this.state;
    this.setState({ dashboards: chips });
  }

  handleOpen = () => {
    this.setState({ open: true });

    restClient(GET_LIST, 'statements/get_all_statements', {
      sort: {
        field: 'id',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((resA) => {
      this.setState({ chips: resA.data, checkChips: resA.data });
    });

    const session = localStorage.getItem('session');
    fetch(`${path}/admin/statements/get_pull_clients`, { method: 'POST',
      body: JSON.stringify({ type: 'general' }),
      headers: {
        'Token': session,
        'Content-Type': 'application/json; charset=utf-8',
      } })
      .then(response => response.json())
      .then((res) => {
        this.setState({ dashboards: res });
      });

    fetch(`${path}/admin/statements/get_pull_clients`, { method: 'POST',
      body: JSON.stringify({ type: 'check' }),
      headers: {
        'Token': session,
        'Content-Type': 'application/json; charset=utf-8',
      } })
      .then(response => response.json())
      .then((res) => {
        this.setState({ checkDashboards: res });
      });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  handleCloseDialog = () => {
    const { refreshView } = this.props;

    this.setState({ openDialog: false });
    refreshView();
  }

  handleSubmit = type => () => {
    const { refreshView, showNotification } = this.props;

    if (this.state[type].length) {
      this.setState({
        open: false,
      });
    } else {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          [type]: true,
        },
      });
      return;
    }
    if (this.state.dashboards.length) {
      this.setState({ error: false, open: false, load: true });
    } else {
      this.setState({ error: true });
      return;
    }

    const statements = this.state[type];
    const session = localStorage.getItem('session');

    fetch(`${path}/admin/statements/pull_all`, { method: 'POST',
      body: JSON.stringify({ statements }),
      headers: {
        'Token': session,
        'Content-Type': 'application/json; charset=utf-8',
      } })
      .then(response => response.json())
      .then((data) => {
        if (data.failed.length || data.failed_csv.length) {
          showNotification('Statements was pulled');
          this.setState({
            error: false,
            open: false,
            load: false,
            openDialog: true,
            statementsNotPulled: data.failed,
            statementsCsvNotPulled: data.failed_csv,
          });
        } else {
          showNotification('Statements was pulled');
          this.setState({ error: false, open: false, load: false });
          refreshView();
        }
      })
      .catch((e) => {
        console.error(e);
        this.setState({ load: false });
        showNotification('Error', 'warning');
      });
  }

  handleAddDashboard = type => (chip) => {
    const chips = [...this.state[type], chip];
    this.setState({ [type]: chips });
  }

  handleDeleteDashboard = type => (chip) => {
    const chips = this.state[type].filter(i => i.id !== chip);
    this.setState({ [type]: chips });
  }

  handleBeforeAddDashboard = type => (chip) => {
    const chips = this.state[type];
    return !chips.map(i => i.id).includes(chip.id);
  }

  dataSource(dashboard, chip) {
    const ids = this.state[dashboard].map(i => i.id);
    return this.state[chip].filter(c => !ids.includes(c.id));
  }

  render() {
    const { open, dashboards, checkDashboards, openDialog, statementsNotPulled, statementsCsvNotPulled } = this.state;
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label="Pull statements"
          icon={<PDFGroupGenerateIcon />}
          onClick={this.handleOpen}
        />
        {this.isLoad() ? <Loader text={this.translate('pdfs.dialog.loader')} /> : ''}
        <Dialog
          title="Pull statements"
          modal={false}
          open={open}
          autoScrollBodyContent={true}
        >
          <Tabs>
            <TabList>
              <Tab>General</Tab>
              <Tab>Check statements</Tab>
            </TabList>
            <TabPanel>
              <ChipInput
                value={dashboards}
                dataSource={this.dataSource('dashboards', 'chips')}
                openOnFocus={true}
                dataSourceConfig={{ text: 'client_name', value: 'id' }}
                onBeforeRequestAdd={this.handleBeforeAddDashboard('dashboards')}
                onRequestAdd={this.handleAddDashboard('dashboards')}
                onRequestDelete={this.handleDeleteDashboard('dashboards')}
                floatingLabelText={this.translate('pdfs.dialog.textStatements')}
                errorText={this.state.errors.generalDashboards && this.translate('aor.validation.required')}
                fullWidth
                fullWidthInput
                listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
              />
              <div
                style={{ paddingTop: '15px', float: 'right' }}
              >
                {this.isClear() ?
                  <RaisedButton
                    label={this.translate('pdfs.dialog.btnClearAll')}
                    onClick={this.handleClear}
                    icon={<ClearAllIcon />}
                  />
                  :
                  <RaisedButton
                    label={this.translate('pdfs.dialog.btnSelectAll')}
                    onClick={this.handleSelect}
                    icon={<DoneAllIcon />}
                  />
                }
                <RaisedButton
                  style={{ marginLeft: '10px' }}
                  label={this.translate('pdfs.dialog.btnCancel')}
                  onClick={this.handleClose}
                  icon={<CancelIcon />}
                />
                <RaisedButton
                  type="submit"
                  style={{ marginLeft: '10px' }}
                  label={this.translate('pdfs.dialog.btnSubmit')}
                  primary={true}
                  onClick={this.handleSubmit('dashboards')}
                  icon={<SubmitIcon />}
                />
              </div>
            </TabPanel>
            <TabPanel>
              <ChipInput
                value={checkDashboards}
                dataSource={this.dataSource('checkDashboards', 'checkChips')}
                openOnFocus={true}
                dataSourceConfig={{ text: 'client_name', value: 'id' }}
                onBeforeRequestAdd={this.handleBeforeAddDashboard('checkDashboards')}
                onRequestAdd={this.handleAddDashboard('checkDashboards')}
                onRequestDelete={this.handleDeleteDashboard('checkDashboards')}
                floatingLabelText={this.translate('pdfs.dialog.textStatements')}
                errorText={this.state.errors.checkDashboards && this.translate('aor.validation.required')}
                fullWidth
                fullWidthInput
                listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
              />
              <div
                style={{ paddingTop: '15px', float: 'right' }}
              >
                <RaisedButton
                  style={{ marginLeft: '10px' }}
                  label={this.translate('pdfs.dialog.btnCancel')}
                  onClick={this.handleClose}
                  icon={<CancelIcon />}
                />
                <RaisedButton
                  style={{ marginLeft: '10px' }}
                  label={this.translate('pdfs.dialog.btnSubmit')}
                  primary={true}
                  onClick={this.handleSubmit('checkDashboards')}
                  icon={<SubmitIcon />}
                />
              </ div>
            </TabPanel>
          </Tabs>
        </Dialog>
        <Dialog
          title='Not pulled'
          modal={false}
          open={openDialog}
          autoScrollBodyContent={true}
        >
          <h2>PDFS</h2>
          { statementsNotPulled.map((item, i) => (
            <li className="travelcompany-input" key={i}>
              <span className="input-label">{ item.clientName } - { item.referenceNumber }</span>
            </li>
          ))}
          <h2>CSVS</h2>
          { statementsCsvNotPulled.map((item, i) => (
            <li className="travelcompany-input" key={i}>
              <span className="input-label">{ item.clientName } - { item.referenceNumber }</span>
            </li>
          ))}
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              style={{ marginLeft: '10px' }}
              label={this.translate('pdfs.dialog.btnClose')}
              onClick={this.handleCloseDialog}
              icon={<CancelIcon />}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

PDFGroupGenerateButton.propTypes = {
  record: PropTypes.object,
  generateAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    refreshView: refreshViewAction,
    generateAction: PDFGroupGenerateAction,
    showNotification: showNotificationAction,
  }),
);

export default enhance(PDFGroupGenerateButton);
