import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { showNotification } from 'admin-on-rest';

import { MARKET_DATA_STRUCTURE_SUCCESS, MARKET_DATA_STRUCTURE_FAILURE, MARKET_DATA_DELETE_FAILURE, MARKET_DATA_DELETE_SUCCESS } from './MarketDataAction';

export default function* reviewSaga() {
  yield [
    takeEvery(MARKET_DATA_STRUCTURE_SUCCESS, function* () {
      yield put(showNotification('marketData.notification.active_success'));
      yield put(push('/'));
      yield put(push('/market_data'));
    }),
    takeEvery(MARKET_DATA_STRUCTURE_FAILURE, function* ({ error }) {
      yield put(showNotification('marketData.notification.active_error', 'warning'));
      console.error(error);
    }),
    takeEvery(MARKET_DATA_DELETE_SUCCESS, function* () {
      yield put(showNotification('marketData.notification.delete_success'));
      yield put(push('/'));
      yield put(push('/market_data'));
    }),
    takeEvery(MARKET_DATA_DELETE_FAILURE, function* ({ error }) {
      yield put(showNotification('marketData.notification.delete_error', 'warning'));
      console.error(error);
    }),
  ];
}
