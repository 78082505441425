import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import { translate, GET_LIST } from 'admin-on-rest';

import PDFDownloadIcon from './PDFDownloadIcon';

import restClient from '../restClient';
import { PresignedURLDownload } from '../helpers/PresignedURLDownload';


class PDFDownloadButton extends React.Component {
  state = {
    src: null,
    id: null,
  }

  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  componentDidMount() {
    const { record } = this.props;

    restClient(GET_LIST, 'pdfs', {
      filter: {
        dashboard_id: record.id,
      },
      sort: {
        field: 'id',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 1,
      },
    }).then((res) => {
      if (res.data[0]) {
        this.setState({ src: res.data[0].file_exists, id: res.data[0].id });
      }
    });
  }

  getLink = id => (e) => {
    e.preventDefault();
    PresignedURLDownload('dashboard', id);
  }

  render() {
    const { src, id } = this.state;
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label={this.translate('dashboards.list.btnDownload')}
          icon={<PDFDownloadIcon />}
          disabled={!src}
          target="_blank"
          onClick={this.getLink(id)}
          href=""
        />
      </div>
    );
  }
}

PDFDownloadButton.propTypes = {
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
);

export default enhance(PDFDownloadButton);
