import { useCallback, useMemo } from 'react';

const useUpdateUnderlyingsCallbacks = (tenorList, productFeature, changeField) => {
  const addUnderlying = useCallback(() => {
    const newUnderLying = tenorList.reduce((result, tenor) => [...result, { tenor, margin: undefined }], []);
    changeField('productFeature', [...productFeature, newUnderLying]);
  }, [tenorList, changeField]);

  const removeUnderlying = useCallback(() => {
    const newProductFeature = [...productFeature];
    newProductFeature.pop();

    if (newProductFeature.length < 1) {
      return;
    }

    changeField('productFeature', newProductFeature);
  }, [productFeature, changeField]);

  return useMemo(() => ({ addUnderlying, removeUnderlying }), [addUnderlying, removeUnderlying]);
};

export default useUpdateUnderlyingsCallbacks;
