import { DateField, RichTextField, Show, SimpleShowLayout, TextField, translate } from 'admin-on-rest';
import React from 'react';
import { formatDateTimeInput } from '../utils/dates';

const VanillaDescriptionTitle = translate(({ record }) =>
  <span>{translate('vanillaDescription.show.title', { smart_count: 1 })} { record.code }</span>);

const VanillaDescriptionShow = props => (
  <Show {...props}
    title={<VanillaDescriptionTitle />}
  >
    <SimpleShowLayout>
      <TextField
        source="product_category"
      />
      <RichTextField
        source="structure"
      />
      <RichTextField
        source="type"
      />
      <TextField
        source="product"
      />
      <TextField
        source="product_description"
      />
      <DateField
        formatDate={formatDateTimeInput}
        source="updated_at"
        showTime
      />
    </SimpleShowLayout>
  </Show>
);

export default VanillaDescriptionShow;
