import React from 'react';

import { RefreshButton, CreateButton } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';

const EmailActions = ({ basePath, resource, filters, displayedFilters, filterValues, showFilter }) => (
  <CardActions>
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
);

export default EmailActions;
