import React from 'react';
import { Create, Edit } from 'admin-on-rest';
import { CqgAccountsToSalesForm } from './CqgAccountsToSalesForm';


export const CqgAccountsToSalesEdit = (props) =>
  <Edit title="Edit CQG Account" {...props}>
    <CqgAccountsToSalesForm {...props} />
  </Edit>;

export const CqgAccountsToSalesCreate = (props) =>
  <Create title="Create CQG Account" {...props}>
    <CqgAccountsToSalesForm {...props} />
  </Create>;

export { default as CqgAccountsToSalesList } from './CqgAccountsToSalesList';
