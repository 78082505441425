import React from 'react';
import Paper from 'material-ui/Paper';
import ToggleDisplay from 'react-toggle-display';
import NumericLabel from 'react-pretty-numbers';

import { translate } from 'admin-on-rest';

import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';

import path from '../path';

class PricingMatrix extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
    this.state = {
      matrix: [],
    };
  }
  componentDidMount() {
    const { record } = this.props;

    fetch(`${path}/admin/pricings/matrix/${record.id}`, {
      method: 'GET',
      headers: {
        Token: localStorage.getItem('session'),
      },
    }).then(res => res.json())
      .then(res => this.setState({ matrix: res }));
  }

  get show() {
    return !!this.state.matrix.length;
  }

  get matrix() {
    const obj = this.state.matrix.reduce((curr, i) => {
      if (!curr[i.strike_level]) {
        curr[i.strike_level] = {
          row: i,
          rows: [],
        };
      }
      curr[i.strike_level].rows.push(i);
      return curr;
    }, {});
    return Object.values(obj);
  }

  get accumulation() {
    return !!(this.state.matrix.length && this.state.matrix[0].barrier_level);
  }

  render() {
    return (
      <ToggleDisplay
        show={this.show}
      >
        <Paper style={{ width: `${this.accumulation ? '70' : '40'}%`, float: 'right' }} zDepth={2}>
          <Table selectable={false}>
            <TableBody displayRowCheckbox={false}>
              {!this.accumulation && <TableRow key="title-accumulation">
                <TableRowColumn
                  style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', whiteSpace: 'pre-wrap', textAlign: 'right' }}
                  key="title"
                >
                  {this.translate('pricings.matrix.title2')}
                </TableRowColumn>
                <TableRowColumn
                  style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px' }}
                  key="title-empty"
                  colSpan={1}
                >
                </TableRowColumn>
              </TableRow>}
              {this.accumulation && <TableRow key="barrier">
                <TableRowColumn
                  style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', whiteSpace: 'pre-wrap', textAlign: 'right' }}
                  key="title"
                  colSpan={1}
                >
                  {this.translate('pricings.matrix.title1')}
                </TableRowColumn>
                {this.matrix.length && this.matrix[0].rows.map(r => (
                  <TableRowColumn
                    style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px' }}
                    key={r.id}
                    colSpan={1}
                  >
                    <NumericLabel params={{ precision: 3, justification: 'L' }}>
                      {r.barrier_level}
                    </NumericLabel>
                  </TableRowColumn>
                ))}
              </TableRow>}
              {this.matrix.map(r => (
                <TableRow key={r.row.id}>
                  <TableRowColumn
                    style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px' }}
                    key={`accumulation-${r.row.id}`}
                    colSpan={1}
                  >
                    <NumericLabel params={{ precision: 3 }}>
                      {r.row.strike_level}
                    </NumericLabel>
                  </TableRowColumn>
                  {r.rows.map(c => (
                    <TableRowColumn
                      style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px' }}
                      key={`value-${r.row.id}`}
                      colSpan={1}
                    >
                      <NumericLabel params={{ precision: 5, justification: 'L' }}>
                        {c.value}
                      </NumericLabel>
                    </TableRowColumn>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </ToggleDisplay>
    );
  }
}

export default translate(PricingMatrix);
