import { Datagrid, DateInput, DeleteButton, Filter, List, ReferenceInput, refreshView, Responsive, RichTextField, SelectField, SelectInput, SimpleList, TextField } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { DateField, UrlField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateInput, formatDateTimeInput } from '../utils/dates';
import DashboardRequestConfirm from './DashboardRequestConfirm';
import socket from 'ioClient';

const DashboardRequestFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="User"
      source="user_id"
      reference="users"
      perPage="1e9"
    >
      <SelectInput
        optionText="email"
      />
    </ReferenceInput>
    <SelectInput
      source="status"
      choices={[
        { id: true, name: 'Pending' },
        { id: false, name: 'Approved' },
      ]}
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
    />
  </Filter>
);

const GetUrl = props => <UrlField href={`admin#/users/${props.record.user_id}`} label={`${props.record.user_last_name} ${props.record.user_first_name} (${props.record.user_email})`} />;

class DashboardRequestList extends React.Component {
  constructor(props) {
    super(props);
    this.socket = socket;
  }

  componentDidMount() {
    this.socket.on('dashboard-request', () => {
      this.props.refreshView();
    });
  }

  render() {
    return (<List {...this.props}
      filters={<DashboardRequestFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={50}
    >
      <Responsive
        small={
          <SimpleList
            primaryText={r => (r.status ? 'Pending' : 'Approved') }
            secondaryText={r => `User ID ${r.user_id}` }
            tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
          />
        }
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
            <TextField
              source="id"
            />
            <GetUrl field="user" label="user"/>
            <RichTextField
              source="request_details"
            />
            <SelectField
              source="status"
              choices={[
                { id: true, name: 'Pending' },
                { id: false, name: 'Approved' },
              ]}
            />
            <DateField
              formatDate={formatDateTimeInput}
              source="updated_at"
              showTime
            />
            <DateField
              formatDate={formatDateTimeInput}
              source="created_at"
              showTime
            />
            <DashboardRequestConfirm />
            <DeleteButton />
          </Datagrid>
        }
      />
    </List>);
  }
}

export default connect(null, {
  refreshView,
})(DashboardRequestList);
