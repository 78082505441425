import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import RaisedButton from 'material-ui/RaisedButton';
import AutoComplete from 'material-ui/AutoComplete';
import CircularProgress from 'material-ui/CircularProgress';
import request from 'superagent';

import { connect } from 'react-redux';
import { translate, Responsive, GET_LIST, showNotification as showNotificationAction, refreshView as refreshViewAction, GET_ONE } from 'admin-on-rest';
import TextField from 'material-ui/TextField';
import UploadButton from '../components/UploadButton';

import OtcConfirmationSendIcon from './OTCSendIcon';
import { OTCSendAction } from './OTCSendAction';
import path from '../path';
import { removeSpecialChars } from '../helpers/utilities';

import restClient from '../restClient';

import ValidateEmail from '../helpers/ValidateEmail';
import './OTCCreateButton.css';

const styleLoader = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  position: 'fixed',
  zIndex: 1,
  left: '0',
  top: 0,
  backgroundColor: 'rgba(255,255,255, 0.8)',
  overflowX: 'hidden',
  transition: '0.5s',
};
class OTCCreateButton extends Component {
  defaultState = {
    open: false,
    chips: [],
    tradeNumber: '',
    tradeType: '',
    signerName: '',
    signer: [],
    secondSignerName: '',
    secondSigner: [],
    bcc: [],
    cc: [],
    file: null,
    clients: [],
    viewers: [],
    client: '',
    clientFullName: '',
    errors: {
      signer: false,
      tradeNumber: false,
      tradeType: false,
      file: false,
      signerName: false,
      secondSignerName: false,
      secondSigner: false,
    },
    load: false,
  }

  constructor(props) {
    super(props);
    this.translate = this.props.translate;
    this.state = this.defaultState;
  }

  isLoad() {
    return this.state.load;
  }

  dataSource() {
    const { signer, bcc, cc } = this.state;
    return this.state.chips.filter(
      c => !signer.includes(c) && !bcc.includes(c) && !cc.includes(c),
    );
  }

  signersDataSource = (type) => {
    const { signer, secondSigner, bcc, cc } = this.state;
    if (this.state[type].length) return [];
    return this.state.chips.filter(
      c => !signer.includes(c) && !secondSigner.includes(c) && !bcc.includes(c) && !cc.includes(c),
    );
  }

  changeDialogStatus = status => () => {
    this.setState({ open: status });
    restClient(GET_LIST, 'users', {
      sort: {
        field: 'email',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((res) => {
      this.setState({ chips: res.data.map(i => i.email) });
    });

    restClient(GET_LIST, 'client_database/names', {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((res) => {
      this.setState({ clients: res.data });
    });
  };

  onDrop = (file) => {
    this.setState({
      file,
      errors: {
        ...this.state.errors,
        file: false,
      },
    });
  }

  getValidationErrors() {
    const { tradeNumber, tradeType, signer, file, signerName, secondSigner, secondSignerName } = this.state;
    const validationErrors = {};

    if (!tradeNumber.trim()) validationErrors.tradeNumber = true;
    if (!tradeType.trim()) validationErrors.tradeType = true;
    if (!signer.length) validationErrors.signer = true;
    if (!file) validationErrors.file = true;
    if (!signerName.trim()) validationErrors.signerName = true;

    if (secondSigner.length || secondSignerName.trim()) {
      if (!secondSigner.length) validationErrors.secondSigner = true;
      if (!secondSignerName.trim()) validationErrors.secondSignerName = true;
    }

    return validationErrors;
  }


  handleSubmit = () => {
    const validationErrors = this.getValidationErrors();
    if (Object.keys(validationErrors).length) {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          ...validationErrors,
        },
      });
      return;
    }
    this.submitForm();
  }

  submitForm() {
    const { tradeNumber
      , tradeType
      , signerName
      , signer
      , bcc
      , cc
      , file
      , client
      , viewers
      , secondSigner
      , secondSignerName } = this.state;

    const { showNotification, refreshView } = this.props;

    this.setState({ ...this.state, open: false, load: true }, () => {
      request
        .post(`${path}/admin/otc_confirmation`)
        .set('token', localStorage.getItem('session'))
        .field('file', file)
        .field('signer', signer)
        .field('tradeNumber', tradeNumber)
        .field('tradeType', tradeType)
        .field('signerName', signerName || '')
        .field('cc', cc)
        .field('bcc', bcc)
        .field('client', client.clientName || '')
        .field('viewers', viewers)
        .field('secondSigner', secondSigner)
        .field('secondSignerName', secondSignerName || '')
        .then((res) => {
          if (res.body && res.body.error && res.body.value === 'UNABLE_TO_CONVERT_DOCUMENT') {
            this.setState({ ...this.state, load: false });
            return showNotification('OTCConfirmations.wrongFileFormat', 'warning');
          }
          if (res.body && res.body.error && res.body.value === 'ANCHOR_TAB_STRING_NOT_FOUND') {
            this.setState({ ...this.state, load: false });
            return showNotification('OTCConfirmations.fileMissingAnchor', 'warning');
          }
          showNotification('OTCConfirmations.notification.save_success');
          this.setState({
            ...this.defaultState,
            chips: this.state.chips,
            clients: this.state.clients,
            load: false,
          });
          return refreshView();
        })
        .catch(() => {
          this.setState({ load: false });
          showNotification('OTCConfirmations.notification.save_error', 'warning');
        });
    });
  }

  handleAddChips = type => (chip) => {
    const chips = [...this.state[type], chip.toLowerCase()];
    this.setState({
      [type]: chips,
      errors: {
        ...this.state.errors,
        [type]: false,
      },
    });
  }

  handleAddSigners = type => (chip) => {
    const chips = [...this.state[type], chip.toLowerCase()];
    const session = localStorage.getItem('session');
    fetch(`${path}/admin/users/email`, { method: 'POST',
      body: JSON.stringify({ 'email': chips[0] }),
      headers: {
        'Token': session,
        'Content-Type': 'application/json; charset=utf-8',
      } })
      .then(response => response.json())
      .then((data) => {
        if (data && data.length !== 0) {
          const res = data[0];
          this.setState({
            [`${type}Name`]: `${res.firstName} ${res.lastName}`,
            errors: {
              ...this.state.errors,
              [`${type}Name`]: false,
            },
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
    this.setState({
      [type]: chips,
      errors: {
        ...this.state.errors,
        [type]: false,
      },
    });
  }

  handleDeleteChips = type => (chip) => {
    if (type === 'signer') {
      this.setState({
        signerName: '',
      });
    }
    if (type === 'secondSigner') {
      this.setState({
        secondSignerName: '',
      });
    }
    const chips = this.state[type].filter(c => c !== chip);
    this.setState({
      [type]: chips,
    });
  }

  handleBeforeAddChip = (chip) => {
    const { signer, bcc, cc } = this.state;
    if (!ValidateEmail(chip)) return false;
    return !signer.includes(chip.toLowerCase()) && !bcc.includes(chip.toLowerCase()) && !cc.includes(chip.toLowerCase());
  }

  handleBeforeAddSignersChip = type => (chip) => {
    const { signer, secondSigner, bcc, cc } = this.state;
    if (this.state[type].length || !ValidateEmail(chip)) return false;
    return !secondSigner.includes(chip) && !signer.includes(chip) && !bcc.includes(chip) && !cc.includes(chip);
  }

  handleInputChange = inputName => (...event) => this.setState({
    [inputName]: removeSpecialChars(event[1]),
    errors: {
      ...this.state.errors,
      [inputName]: false,
    },
  });

  closeDialog = () => {
    this.setState({
      ...this.defaultState,
      chips: this.state.chips,
      clients: this.state.clients,
    }, () => this.changeDialogStatus(false),
    );
  }


  clientSelected = (client) => {
    restClient(GET_ONE, 'client_database', {
      id: client.id,
    }).then((res) => {
      const { client_code: clientRefNumber
        , otc_signer: confirmationSigner
        , otc_cc: confirmationCc
        , otc_bcc: confirmationBcc
        , otc_viewers: viewers
        , signer_full_name: clientFullName
        , second_otc_signer: secondConfirmationSigner
        , second_signer_full_name: secondClientFullName } = res.data;

      this.setState({
        ...this.state,
        tradeNumber: clientRefNumber ? `${clientRefNumber}-` : '',
        signer: confirmationSigner ? [confirmationSigner] : [],
        cc: confirmationCc || [],
        bcc: confirmationBcc || [],
        viewers: viewers || [],
        client,
        signerName: clientFullName,
        secondSignerName: secondClientFullName || '',
        secondSigner: secondConfirmationSigner ? [secondConfirmationSigner] : [],
      });
    });
  }

  handleClientInputChange = (value) => {
    if (value.length) return;
    this.setState({
      ...this.defaultState,
      chips: this.state.chips,
      clients: this.state.clients,
      open: true,
    });
  }

  render() {
    return (
      <div style={{ float: 'left' }}>
        {this.isLoad() ? <div style={styleLoader}><CircularProgress size={80} thickness={5} style={{ right: '15%' }}/><p style={{ position: 'relative', color: 'rgb(0, 188, 212)', fontSize: '14px', right: '15%' }}></p></div> : ''}
        <FlatButton
          label={this.translate('OTCConfirmations.create.buttonText')}
          icon={<OtcConfirmationSendIcon />}
          onClick={this.changeDialogStatus(true)}
        />
        <Dialog
          title={this.translate('OTCConfirmations.create.title')}
          modal={false}
          open={this.state.open}
          autoScrollBodyContent={true}
        >
          <AutoComplete
            floatingLabelText="Select client"
            filter={AutoComplete.fuzzyFilter}
            dataSource={this.state.clients}
            dataSourceConfig={{ text: 'clientName', value: 'id' }}
            onNewRequest={this.clientSelected}
            onUpdateInput={this.handleClientInputChange}
            openOnFocus={true}
            maxLength="50"
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <TextField
            errorText={this.state.errors.tradeNumber && this.translate('aor.validation.required')}
            floatingLabelText={this.translate('OTCConfirmations.create.tradeNumber')}
            fullWidth
            onChange={this.handleInputChange('tradeNumber')}
            name="tradeNumber"
            type="text"
            multiLine={true}
            value={this.state.tradeNumber}
            maxLength="11"
          />
          <TextField
            errorText={this.state.errors.tradeType && this.translate('aor.validation.required')}
            floatingLabelText={this.translate('OTCConfirmations.create.tradeType')}
            onChange={this.handleInputChange('tradeType')}
            fullWidth
            name="tradeType"
            type="text"
            multiLine={true}
            maxLength="50"
          />
          <div className="popup-custom-card">
            <div className="card-lable">First Signer: <span>(<span className="error">*</span>Required)</span></div>
            <ChipInput
              value={this.state.signer}
              openOnFocus={true}
              floatingLabelText={this.translate('OTCConfirmations.create.signerText')}
              dataSource={this.signersDataSource('signer')}
              onRequestAdd={this.handleAddSigners('signer')}
              onRequestDelete={this.handleDeleteChips('signer')}
              errorText={this.state.errors.signer && this.translate('aor.validation.required')}
              onBeforeRequestAdd={this.handleBeforeAddSignersChip('signer')}
              fullWidth
              fullWidthInput
              listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
            />
            <TextField
              errorText={this.state.errors.signerName && this.translate('aor.validation.required')}
              type="text"
              value={this.state.signerName}
              onChange={this.handleInputChange('signerName')}
              floatingLabelText={this.translate('OTCConfirmations.create.fullName')}
              fullWidth
            />
          </div>
          <div className="popup-custom-card">
            <div className="card-lable">Second Signer: <span>(Optional)</span></div>
            <ChipInput
              value={this.state.secondSigner}
              openOnFocus={true}
              floatingLabelText={this.translate('OTCConfirmations.create.signerText')}
              dataSource={this.signersDataSource('secondSigner')}
              onRequestAdd={this.handleAddSigners('secondSigner')}
              onRequestDelete={this.handleDeleteChips('secondSigner')}
              errorText={this.state.errors.secondSigner && this.translate('aor.validation.required')}
              onBeforeRequestAdd={this.handleBeforeAddSignersChip('secondSigner')}
              fullWidth
              fullWidthInput
              listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
            />
            <TextField
              errorText={this.state.errors.secondSignerName && this.translate('aor.validation.required')}
              type="text"
              value={this.state.secondSignerName}
              onChange={this.handleInputChange('secondSignerName')}
              floatingLabelText={this.translate('OTCConfirmations.create.fullName')}
              fullWidth
            />
          </div>
          <ChipInput
            value={this.state.cc}
            floatingLabelText={this.translate('OTCConfirmations.create.ccText')}
            openOnFocus={true}
            dataSource={this.dataSource()}
            onRequestAdd={this.handleAddChips('cc')}
            onBeforeRequestAdd={this.handleBeforeAddChip}
            onRequestDelete={this.handleDeleteChips('cc')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={this.state.bcc}
            floatingLabelText={this.translate('OTCConfirmations.create.bccText')}
            openOnFocus={true}
            dataSource={this.dataSource()}
            onRequestAdd={this.handleAddChips('bcc')}
            onRequestDelete={this.handleDeleteChips('bcc')}
            onBeforeRequestAdd={this.handleBeforeAddChip}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={this.state.viewers}
            floatingLabelText={this.translate('OTCConfirmations.create.viewersText')}
            openOnFocus={true}
            dataSource={this.state.chips}
            onRequestAdd={this.handleAddChips('viewers')}
            onRequestDelete={this.handleDeleteChips('viewers')}
            onBeforeRequestAdd={ValidateEmail}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <UploadButton
            onDrop={this.onDrop}
            error={this.state.errors.file}
          />
          <Responsive
            small={
              <div
                style={{ paddingTop: '15px' }}
              >
                <RaisedButton
                  label={this.translate('pdfs.dialog.btnCancel')}
                  onClick={this.closeDialog}
                  icon={<CancelIcon />}
                  fullWidth
                />
                <RaisedButton
                  type="submit"
                  label={this.translate('pdfs.dialog.btnSubmit')}
                  primary={true}
                  icon={<SubmitIcon />}
                  onClick={this.handleSubmit}
                  style={{ marginTop: '10px' }}
                  fullWidth
                />
              </div>
            }
            medium={
              <div
                style={{ paddingTop: '15px', float: 'right' }}
              >
                <RaisedButton
                  label={this.translate('pdfs.dialog.btnCancel')}
                  onClick={this.closeDialog}
                  icon={<CancelIcon />}
                />
                <RaisedButton
                  type="submit"
                  style={{ marginLeft: '10px' }}
                  label={this.translate('pdfs.dialog.btnSubmit')}
                  primary={true}
                  icon={<SubmitIcon />}
                  onClick={this.handleSubmit}
                />
              </div>
            }
          />
        </Dialog>
      </div>
    );
  }
}

OTCCreateButton.propTypes = {
  record: PropTypes.object,
  sendAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    sendAction: OTCSendAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(OTCCreateButton);
