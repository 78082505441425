import React from 'react';
import {
  Toolbar,
  SaveButton,
} from 'admin-on-rest';

const DomainToolbar = props => (
  <Toolbar {...props}>
    <SaveButton redirect='list' submitOnEnter={true} />
  </Toolbar>
);

export default DomainToolbar;
