/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { CardActions } from 'material-ui/Card';
import EditIcon from 'material-ui/svg-icons/image/edit';
import FlatButton from 'material-ui/FlatButton';
import {
  Responsive,
  Datagrid,
  DeleteButton,
  refreshView,
  List,
  TextField,
  BooleanField,
  FunctionField,
  RefreshButton,
  Filter,
  TextInput,
  DateInput,
  SelectInput
} from 'admin-on-rest';
import { formatDateInput } from '../utils/dates';
import { AUTO_BOOK_OPTIONS } from './helpers';
import { DateField } from '../helpers';

import CreateEditButton from './PricingTemplateCreateEdit';
import { formatMarkups } from './PricingTemplatesActions';

const Actions = ({ resource, filters, displayedFilters, filterValues, showFilter }) => (
  <CardActions>
    <RefreshButton />
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    <CreateEditButton />
  </CardActions>
);

const ButtonsWrapper = props => {
  const cardFilter = `%7B"template_id"%3A"${props.record.id}"%7D&order=DESC&page=1&perPage=25&sort=templateName`
  return (
    <div style={{ width: '230px' }}>
      <CreateEditButton {...props} edit />
      <FlatButton label="CARDS" {...props} icon={<EditIcon />} href={`#/pricing_template_cards?filter=${cardFilter}`} />
      <CreateEditButton {...props} clone />
      <DeleteButton {...props} />
    </div>
  );
};

const formatUnderlyings = underlyings => {
  try {
    underlyings = underlyings.map((el, i) => (
      <p
        style={{ padding: '0', margin: '0', fontSize: '12px' }}
        key={i}
        dangerouslySetInnerHTML={{ __html: `${el} <br/>` }}
      ></p>
    ));
  } catch (err) {
    console.log(err, 'err');
    underlyings = '';
  }
  return underlyings;
};

const Filters = props => (
  <Filter {...props}>
    <TextInput label="pricingTemplates.search" source="_q" alwaysOn />
    <TextInput source="name" />
    <SelectInput
      source="auto_book"
      choices={AUTO_BOOK_OPTIONS}
    />
    <DateInput label="Created" options={{ formatDate: formatDateInput, }} source="createdAt" showTime />
  </Filter>
);
class PricingTemplatesList extends React.Component {
  render() {
    return (
      <List
        {...this.props}
        sort={{ field: 'created_at', order: 'DESC' }}
        filters={<Filters />}
        perPage={50}
        refreshView={true}
        title="CM Pricing Templates"
        actions={<Actions />}
      >
        <Responsive
          medium={
            <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }}>
              <TextField source="name" />
              <FunctionField source="markups" render={r => formatMarkups(r.markups)} />
              <BooleanField source="useAbsMarkup" label="ABS Markup" />
              <FunctionField source="underlyings" render={r => formatUnderlyings(r.underlyings)} />
              <BooleanField source="auto_book" label="Auto Book" />
              <DateField formatDate={date => moment(date).format('DD MMMM YYYY HH:mm')} source="updated_at" showTime />
              <DateField formatDate={date => moment(date).format('DD MMMM YYYY HH:mm')} source="created_at" showTime />
              <ButtonsWrapper />
            </Datagrid>
          }
        />
      </List>
    );
  }
}

export default connect(null, {
  refreshView,
})(PricingTemplatesList);
