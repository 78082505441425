import React from 'react';
import { connect } from 'react-redux';
import {
  Responsive,
  RichTextField,
  DeleteButton,
  refreshView,
  List,
  RefreshButton,
  BooleanField,
  Filter,
  TextInput,
} from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import CreateEditButton from './CreateEditButton';
import DownloadInputSettings from './DownloadInputSettings';
import PreviewButton from './PreviewButton';
import SpDataGripWrapper from '../SpDataGripWrapper/SpDataGripWrapper';

const Actions = ({ resource }) => (
  <CardActions>
    <RefreshButton />
    <CreateEditButton resource={resource} />
  </CardActions>
);

const ButtonsWrapper = (props) => {
  if (props.record.custom) {
    return (
      <div className='delete-button-wrapper'>
        <DeleteButton {...props} />
      </div>
    );
  }

  return (
    <div style={{ width: '550px' }}>
      <CreateEditButton {...props} edit={true} />
      <DeleteButton {...props} />
      <PreviewButton {...props} />
    </div>
  );
};

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="configurableSp.search" source="_q" alwaysOn />
  </Filter>
);

const SpInputsConfig = (props) => {
  return (
    <List
      {...props}
      filters={<Filters />}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={50}
      refreshView={true}
      actions={<Actions resource={props.resource} />}
    >
      <Responsive
        medium={
          <SpDataGripWrapper buttonComponent={ButtonsWrapper} showVisible={false}>
            <RichTextField source="name" />
            <RichTextField label="component" source="componentType" />
            <BooleanField label="File upload status" source="fileUploadStatus" sortable={false} />
            <DownloadInputSettings />
          </SpDataGripWrapper>
        }
      />
    </List>
  );
};

export default connect(null, {
  refreshView,
})(SpInputsConfig);
