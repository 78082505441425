import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import AutoComplete from 'material-ui/AutoComplete';
import RaisedButton from 'material-ui/RaisedButton';
import request from 'superagent';
import OtcUploadIcon from 'material-ui/svg-icons/action/backup';
import { connect } from 'react-redux';
import { translate, Responsive, GET_LIST, showNotification as showNotificationAction, refreshView as refreshViewAction, GET_ONE } from 'admin-on-rest';
import TextField from 'material-ui/TextField';
import UploadButton from '../components/UploadButton';
import { OTCSendAction } from './OTCSendAction';
import path from '../path';
import { removeSpecialChars } from '../helpers/utilities';

import restClient from '../restClient';

import ValidateEmail from '../helpers/ValidateEmail';

class OTCCreateButton extends React.Component {
  defaultState = {
    open: false,
    emails: [],
    tradeNumber: '',
    tradeType: '',
    signer: [],
    file: null,
    clients: [],
    client: {},
    viewers: [],
    errors: {
      signer: false,
      tradeNumber: false,
      tradeType: false,
      file: false,
    },
  }

  constructor(props) {
    super(props);
    this.translate = this.props.translate;
    this.state = this.defaultState;
  }

  changeDialogStatus = status => () => {
    this.setState({ open: status });
    restClient(GET_LIST, 'users', {
      sort: {
        field: 'email',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((res) => {
      this.setState({ emails: res.data.map(i => i.email) });
    });

    restClient(GET_LIST, 'client_database/names', {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((res) => {
      this.setState({ clients: res.data });
    });
  };

  onDrop = (file) => {
    this.setState({
      file,
      errors: {
        ...this.state.errors,
        file: false,
      },
    });
  }

  getValidationErrors() {
    const { tradeNumber, tradeType, signer, file } = this.state;
    const validationErrors = {};

    if (!tradeNumber.trim()) validationErrors.tradeNumber = true;
    if (!tradeType.trim()) validationErrors.tradeType = true;
    if (!signer.length) validationErrors.signer = true;
    if (!file) validationErrors.file = true;

    return validationErrors;
  }


  handleSubmit = () => {
    const validationErrors = this.getValidationErrors();
    if (Object.keys(validationErrors).length) {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          ...validationErrors,
        },
      });
      return;
    }
    this.submitForm();
  }

  submitForm() {
    const { tradeNumber, tradeType, signer, file, viewers, client } = this.state;
    const { showNotification, refreshView } = this.props;

    this.setState({ open: false }, () => {
      request
        .post(`${path}/admin/otc_confirmation/uploadSigned`)
        .set('token', localStorage.getItem('session'))
        .field('file', file)
        .field('signer', signer)
        .field('tradeNumber', tradeNumber)
        .field('tradeType', tradeType)
        .field('viewers', viewers)
        .field('client', client.clientName || '')
        .then(() => {
          showNotification('OTCConfirmations.upload.success');

          this.setState({
            ...this.defaultState,
            emails: this.state.emails,
            clients: this.state.clients,
          });

          refreshView();
        })
        .catch(() => {
          showNotification('OTCConfirmations.upload.fail', 'warning');
        });
    });
  }

  handleAddChips = type => (chip) => {
    const chips = [...this.state[type], chip];
    this.setState({
      [type]: chips,
      errors: {
        ...this.state.errors,
        [type]: false,
      },
    });
  }

  handleAddSigners = (chip) => {
    const chips = [...this.state.signer, chip.toLowerCase()];
    this.setState({
      signer: chips,
      errors: {
        ...this.state.errors,
        signer: false,
      },
    });
  }

  handleDeleteChips = type => (chip) => {
    const chips = this.state[type].filter(c => c !== chip);
    this.setState({
      [type]: chips,
    });
  }

  handleBeforeAddChip = (chip) => {
    const { signer, viewers } = this.state;
    if (!ValidateEmail(chip)) return false;
    return !signer.includes(chip.toLowerCase()) || !viewers.includes(chip.toLowerCase());
  }

  handleInputChange = inputName => (...event) => this.setState({
    [inputName]: removeSpecialChars(event[1]),
    errors: {
      ...this.state.errors,
      [inputName]: false,
    },
  });

  clientSelected = (client) => {
    restClient(GET_ONE, 'client_database', {
      id: client.id,
    }).then((res) => {
      const { client_code: clientRefNumber
        , otc_signer: confirmationSigner
        , otc_cc: confirmationCc
        , otc_bcc: confirmationBcc
        , otc_viewers: viewers } = res.data;

      this.setState({
        ...this.state,
        tradeNumber: clientRefNumber ? `${clientRefNumber}-` : '',
        signer: confirmationSigner ? [confirmationSigner] : [],
        cc: confirmationCc || [],
        bcc: confirmationBcc || [],
        viewers: viewers || [],
        client,
      });
    });
  }

  closeDialog = () => {
    this.setState({
      ...this.defaultState,
      emails: this.state.emails,
      clients: this.state.clients,
    }, () => this.changeDialogStatus(false),
    );
  }

  render() {
    const { emails } = this.state;
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label={this.translate('OTCConfirmations.upload.title')}
          icon={<OtcUploadIcon />}
          onClick={this.changeDialogStatus(true)}
        />
        <Dialog
          title={this.translate('OTCConfirmations.upload.formTitle')}
          modal={false}
          open={this.state.open}
          autoScrollBodyContent={true}
        >
          <AutoComplete
            floatingLabelText="Select client"
            filter={AutoComplete.fuzzyFilter}
            dataSource={this.state.clients}
            dataSourceConfig={{ text: 'clientName', value: 'id' }}
            onNewRequest={this.clientSelected}
            openOnFocus={true}
            maxLength="50"
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <TextField
            errorText={this.state.errors.tradeNumber && this.translate('aor.validation.required')}
            floatingLabelText={this.translate('OTCConfirmations.create.tradeNumber')}
            fullWidth
            onChange={this.handleInputChange('tradeNumber')}
            name="tradeNumber"
            type="text"
            multiLine={true}
            value={this.state.tradeNumber}
            maxLength="11"
          />
          <TextField
            errorText={this.state.errors.tradeType && this.translate('aor.validation.required')}
            floatingLabelText={this.translate('OTCConfirmations.create.tradeType')}
            onChange={this.handleInputChange('tradeType')}
            fullWidth
            name="tradeType"
            type="text"
            multiLine={true}
            maxLength="50"
          />
          <ChipInput
            value={this.state.signer}
            openOnFocus={true}
            floatingLabelText={this.translate('OTCConfirmations.create.signerText')}
            dataSource={ emails }
            onRequestAdd={this.handleAddSigners}
            onRequestDelete={this.handleDeleteChips('signer')}
            errorText={this.state.errors.signer && this.translate('aor.validation.required')}
            onBeforeRequestAdd={this.handleBeforeAddChip}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={this.state.viewers}
            openOnFocus={true}
            floatingLabelText={this.translate('OTCConfirmations.create.viewersText')}
            dataSource={ emails }
            onRequestAdd={this.handleAddChips('viewers')}
            onRequestDelete={this.handleDeleteChips('viewers')}
            onBeforeRequestAdd={this.handleBeforeAddChip}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <UploadButton
            onDrop={this.onDrop}
            error={this.state.errors.file}
          />
          <Responsive
            small={
              <div
                style={{ paddingTop: '15px' }}
              >
                <RaisedButton
                  label={this.translate('pdfs.dialog.btnCancel')}
                  onClick={this.closeDialog}
                  icon={<CancelIcon />}
                  fullWidth
                />
                <RaisedButton
                  type="submit"
                  style={{ marginTop: '10px' }}
                  label={this.translate('pdfs.dialog.btnSubmit')}
                  primary={true}
                  icon={<SubmitIcon />}
                  onClick={this.handleSubmit}
                  fullWidth
                />
              </div>
            }
            medium={
              <div
                style={{ paddingTop: '15px', float: 'right' }}
              >
                <RaisedButton
                  label={this.translate('pdfs.dialog.btnCancel')}
                  onClick={this.closeDialog}
                  icon={<CancelIcon />}
                />
                <RaisedButton
                  type="submit"
                  style={{ marginLeft: '10px' }}
                  label={this.translate('pdfs.dialog.btnSubmit')}
                  primary={true}
                  icon={<SubmitIcon />}
                  onClick={this.handleSubmit}
                />
              </div>
            }
          />
        </Dialog>
      </div>
    );
  }
}

OTCCreateButton.propTypes = {
  record: PropTypes.object,
  sendAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    sendAction: OTCSendAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(OTCCreateButton);
