import React from 'react';

const Text = ({ ...props }) => {
  const value = props.record[props.source] ? props.record[props.source] : '&mdash;';
  return (
    <div
      style={props.style}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};

export default Text;
