import { useEffect } from 'react';
import { INITIAL_UNDERLYINGS } from '../constants';

const usePopulateProductFeatureIfNewRecord = (record, changeField) => {
  useEffect(() => {
    if (record && record.id) return;
    // initial values
    changeField('productFeature', [[...INITIAL_UNDERLYINGS]]);
  }, []);
};

export default usePopulateProductFeatureIfNewRecord;
