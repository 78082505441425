import React from 'react';
import { Edit } from 'admin-on-rest';

import { GSAccountsForm } from './GSAccountsForm';

export const GSAccountsEdit = (props) =>
  <Edit title="Edit account" {...props}>
    <GSAccountsForm {...props} />
  </Edit>;

export { default as GSAccountsList } from './GSAccountsList';
