
import React, { Component } from 'react';
import { Table, TableHeader, TableRow, TableHeaderColumn, TableBody, TableRowColumn } from 'material-ui/Table';

const rowStyle = {
  fontWeight: 'normal',
  fontSize: '12px',
  paddingLeft: '24px',
  paddingRight: '24px',
  height: '24px',
  textAlign: 'left',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: 'rgb(158, 158, 158)',
  position: 'relative',
  backgroundColor: 'inherit',
};

export default class ClientTable extends Component {
  onAsk = (e) => {
    this.props.onChangeAsk('cPrice', e.target.value);
  }

  onBid = (e) => {
    this.props.onChangeBid('cPrice', e.target.value);
  }

  render() {
    const { value: { bid, ask }, prices } = this.props;
    return (
      <Table
        fixedHeader={false}
        selectable={false}
      >
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn style={rowStyle}></TableHeaderColumn>
            <TableHeaderColumn style={rowStyle}>Price</TableHeaderColumn>
            <TableHeaderColumn style={rowStyle}>Formula</TableHeaderColumn>
            <TableHeaderColumn style={rowStyle}>Spread</TableHeaderColumn>
            <TableHeaderColumn style={rowStyle}>Result</TableHeaderColumn>
          </TableRow>
        </TableHeader>

        <TableBody displayRowCheckbox={false}>
          <TableRow>
            <TableRowColumn><b>Ask/Buy:</b></TableRowColumn>
            <TableRowColumn>
              <input
                type="number"
                name="ask"
                value={ask}
                className="custom-input"
                onChange={this.onAsk}
              />
            </TableRowColumn>
            <TableRowColumn>{prices ? prices.ask.formula : '-'}</TableRowColumn>
            <TableRowColumn>{prices ? prices.ask.spread : '-'}</TableRowColumn>
            <TableRowColumn>{prices ? prices.ask.result : '-'}</TableRowColumn>
          </TableRow>

          <TableRow striped={true}>
            <TableRowColumn><b>Bid/Sell:</b></TableRowColumn>
            <TableRowColumn>
              <input
                type="number"
                name="bid"
                value={bid}
                className="custom-input"
                onChange={this.onBid}
              />
            </TableRowColumn>
            <TableRowColumn>{prices ? prices.bid.formula : '-'}</TableRowColumn>
            <TableRowColumn>{prices ? prices.bid.spread : '-'}</TableRowColumn>
            <TableRowColumn>{prices ? prices.bid.result : '-'}</TableRowColumn>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}
