
export const USER_REJECT = 'USER_REJECT';
export const USER_REJECT_LOADING = 'USER_REJECT_LOADING';
export const USER_REJECT_FAILURE = 'USER_REJECT_FAILURE';
export const USER_REJECT_SUCCESS = 'USER_REJECT_SUCCESS';

export const USER_APPROVE = 'USER_APPROVE';
export const USER_APPROVE_LOADING = 'USER_APPROVE_LOADING';
export const USER_APPROVE_FAILURE = 'USER_APPROVE_FAILURE';
export const USER_APPROVE_SUCCESS = 'USER_APPROVE_SUCCESS';
