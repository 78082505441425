/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  SimpleForm,
  SelectInput,
  required,
  NumberInput,
  ReferenceInput,
  FunctionField,
  translate as translateAction,
} from 'admin-on-rest';
import { compose } from 'redux';

const LivePositionForm = (props) => {
  const { translate } = props;

  return (
  <SimpleForm {...props} redirect="list" submitOnEnter={true}>
    <ReferenceInput  source="swap_commodity_id" reference="swap_commodities" label={translate('livePositions.form.commodityLabel')} filter={{ is_live_positioned: false, is_spot: true }} validate={[required]}>
        <SelectInput optionText="agile_name" optionValue="id" style={{ width: '50%', marginTop: '14px' }} />
    </ReferenceInput>
    <NumberInput source="starting_position" label={translate('livePositions.form.startingPosition')} validate={[required]}/>
    <FunctionField source="sum_of_client" label={translate('livePositions.form.sumOfClient')} render={record => record.sum_of_client || 0} />
    <FunctionField source="sum_of_hedge" label={translate('livePositions.form.sumOfHedge')} render={record => record.sum_of_hedge || 0}/>
    <FunctionField source="live_position" label={translate('livePositions.form.livePosition')} render={({sum_of_client, sum_of_hedge, starting_position}) => sum_of_hedge + sum_of_client + starting_position || 0}/>
  </SimpleForm>
)};

export default compose(translateAction)(LivePositionForm);
