import { CreateButton, Datagrid, DateInput, DeleteButton, EditButton, Filter, FunctionField, List, RefreshButton, Responsive, RichTextField, SimpleList, TextField, TextInput } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import Chip from 'material-ui/Chip';
import moment from 'moment';
import React from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateInput } from '../utils/dates';
import CheckStatementButton from './CheckStatementButton';
import PDFDownloadButton from './PDFDownloadButton';
import PDFGenerateButton from './PDFGenerateButton';
import PDFGroupGenerateButton from './PDFGroupGenerateButton';
import PDFNotifyManyButton from './PDFNotifyManyButton';
import PDFSendButton from './PDFSendButton';
import PDFSendButtonWithoutPdf from './PDFSendButtonWithoutPdf';
import PDFSendManyButton from './PDFSendManyButton';

const DashboardFilter = props => (
  <Filter {...props}>
    <TextInput
      label="dashboards.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="name"
    />
    <TextInput
      source="description"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="pdf_generated_at"
      showTime
    />
  </Filter>
);

const DashboardAction = (props) => {
  const { filters, basePath, resource, showFilter, displayedFilters, filterValues } = props;
  return (
    <CardActions>
      <CheckStatementButton />
      <PDFNotifyManyButton/>
      <PDFSendManyButton/>
      <PDFGroupGenerateButton />
      {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
      <CreateButton basePath={basePath} />
      <RefreshButton />
    </CardActions>
  );
};

const DashboardList = props => (
  <List {...props}
    actions={<DashboardAction />}
    filters={<DashboardFilter />}
    sort={{ field: 'created_at', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => r.name}
          secondaryText={r => r.url}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
        />
      }
      medium={
        <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
          <TextField
            source="name"
          />
          <RichTextField
            source="description"
          />
          <DateField
            formatDate={date => (date ? moment(date).format('DD MMMM YYYY HH:mm') : '')}
            source="pdf_generated_at"
            showTime
          />
          <TextField source="powerbi_filter" />
          <FunctionField
            label="Pages"
            render={r => (r.pages || []).map(i => (<Chip style={{ margin: 4 }} key={`${r.id}-${i}`}>{i}</Chip>))}
          />
          <PDFGenerateButton />
          <PDFDownloadButton />
          <PDFSendButton />
          <PDFSendButtonWithoutPdf />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      }
    />
  </List>
);

export default DashboardList;
