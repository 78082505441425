import React from 'react';
import { Filter, TextInput } from 'admin-on-rest';

const LocationFilters = props => (
  <Filter {...props}>
    <TextInput
      label="location.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="name"
    />
    <TextInput
      source="location_code"
    />
    <TextInput
      source="client"
    />
  </Filter>
);

export default LocationFilters;
