import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { showNotification } from 'admin-on-rest';

import { ACCUMULATOR_DESCRIPTION_DELETE_SUCCESS, ACCUMULATOR_DESCRIPTION_DELETE_FAILURE } from './AccumulatorDescriptionAction';

export default function* reviewSaga() {
  yield [
    takeEvery(ACCUMULATOR_DESCRIPTION_DELETE_SUCCESS, function* () {
      yield put(showNotification('accumulatorDescription.notification.delete_success'));
      yield put(push('/'));
      yield put(push('/accumulator_description'));
    }),
    takeEvery(ACCUMULATOR_DESCRIPTION_DELETE_FAILURE, function* ({ error }) {
      yield put(showNotification('accumulatorDescription.notification.delete_error', 'warning'));
      console.error(error);
    }),
  ];
}
