import React from 'react';
import {
  TextField,
  ChipField,
  refreshView as refreshViewAction,
  showNotification as showNotificationAction,
  translate,
} from 'admin-on-rest';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import MultiplyChips from '../../../helpers/MultiplyChips';
import { Datagrid, DisplayText } from '../../../helpers';
import path from '../../../path';
import CheckboxCustom from './CheckboxCustom';
import { datagridDefaultStyles } from '../../../theme/styles';
import EditButton from '../Edit';

class Columns extends React.Component {
  constructor(props) {
    super(props);
    this.ids = [];
    this.state = { ids: [], rows: [] };
  }

  handleRowClick = (id, status, field, label) => {
    const { showNotification, refreshView } = this.props;
    const statusMessage = !status ? 'checked' : 'unchecked';
    fetch(`${path}/admin/client_database/edit_status/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        field,
        value: !status,
      }),
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then((response) => {
        if (response.status === 200) return response;
        throw new Error('Request failed');
      })
      .then(() => {
        showNotification(`${label} ${statusMessage}`);
        refreshView();
      })
      .catch(() => {
        showNotification('Error', 'warning');
      });
  }

  editMarginCallStatus = (id, status, field) => {
    const { showNotification, refreshView } = this.props;
    const statusMessage = !status ? 'checked' : 'unchecked';
    fetch(`${path}/admin/client_database/edit_margin_call_status/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        field,
        value: !status,
      }),
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then((response) => {
        if (response.status === 200) return response;
        throw new Error('Request failed');
      })
      .then(() => {
        showNotification(`Margin call status: ${statusMessage}`);
        refreshView();
      })
      .catch(() => {
        showNotification('Error', 'warning');
      });
  }

  getText(key, record) {
    switch (key) {
    case 'sftpAcc': return `${record.username || ''} ${record.host ? `(${record.host})` : ''}`;
    default: return record[key];
    }
  }

  renderElement(key) {
    const { columns } = this.props;
    const { status, label, type, sortable } = columns[key];
    if (!status) return '';
    switch (type) {
    case 'CheckboxCustom':
      return <CheckboxCustom key={key} source={key} label={label} sortable={sortable} field={key} onClick={key === 'margin_call_status' ? this.editMarginCallStatus : this.handleRowClick} />;
    case 'RichTextField':
      return <DisplayText key={key} label={label} getText={({ record }) => this.getText(key, record)} />;
    case 'MultiplyChips':
      return <MultiplyChips key={key} source={key} sortable={false} name={key === 'dashboard'} />;
    case 'ChipField':
      return <ChipField key={key} source={key} />;
    default:
      return <TextField key={key} source={key} label={label} />;
    }
  }

  render() {
    const { columns } = this.props;
    return (
      <Datagrid
        bodyOptions={{ showRowHover: true }}
        options={{ height: '70vh' }} styles={datagridDefaultStyles}
        {...this.props}
      >
        {Object.keys(columns).map(key => this.renderElement(key))}
        <EditButton />
      </Datagrid>
    );
  }
}

const mapStateToProps = ({
  clientDataBaseReducer: { columns },
  admin: { resources: { client_database: { data } } },
}) => ({
  columns,
  data,
});

const enhance = compose(
  translate,
  connect(mapStateToProps, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(Columns);
