import React from 'react';
import get from 'lodash/get';

const toLocaleStringSupportsLocales = (() => {
  try {
    new Date().toLocaleString('i');
  } catch (error) {
    return error instanceof RangeError;
  }
  return false;
})();

type Record = { [key: string]: string | Date };
interface conditionalFormattingParam {
  record: Record;
  date: Date;
}
interface DateFieldProps {
  elStyle?: React.CSSProperties;
  label?: string;
  locales?: string | string[];
  options?: Intl.DateTimeFormatOptions;
  record?: Record;
  showTime?: boolean;
  source: string;
  formatDate?: (date: Date) => string;
  conditionalFormatting?: (param: conditionalFormattingParam) => string;
}

export const DateField: React.FC<DateFieldProps> = ({
  elStyle,
  locales,
  options,
  record,
  showTime = false,
  formatDate,
  source,
  conditionalFormatting,
}) => {
  if (!record) {
    return null;
  }
  const value = get(record, source);

  if (value == null) {
    return null;
  }
  const date = value instanceof Date ? value : new Date(value);

  const dateString = showTime
    ? toLocaleStringSupportsLocales
      ? date.toLocaleString(locales, options)
      : date.toLocaleString()
    : toLocaleStringSupportsLocales
      ? date.toLocaleDateString(locales, options)
      : date.toLocaleDateString();
  return (
    <span style={elStyle}>
      {conditionalFormatting ? conditionalFormatting({ record, date }) : formatDate ? formatDate(date) : dateString}
    </span>
  );
};

export default DateField;
