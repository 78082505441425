import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import CreateButtonIcon from 'material-ui/svg-icons/content/reply';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import { connect } from 'react-redux';
import { translate, showNotification as showNotificationAction, refreshView as refreshViewAction, GET_LIST } from 'admin-on-rest';
import restClient from '../restClient';
import { removeSpecialChars } from '../helpers/utilities';

import path from '../path';

class EditButton extends React.Component {
  defaultState = {
    open: false,
    name: '',
    users: [],
    selectedUsers: [],
    validationErrors: {
      name: false,
    },
  }

  constructor(props) {
    super(props);
    this.translate = this.props.translate;
    this.state = this.defaultState;
  }

  validate() {
    const { name } = this.state;
    const errors = {};

    if (!name.length) errors.name = true;

    return errors;
  }

  handleSubmit = () => {
    const validationErrors = this.validate();

    if (Object.keys(validationErrors).length) {
      this.setState({
        ...this.state,
        validationErrors,
      });
      return;
    }

    const { showNotification, refreshView, record } = this.props;
    const { name, selectedUsers } = this.state;

    this.setState({ open: false }, () => {
      fetch(`${path}/admin/trade_teams/${record.id}`, { method: 'PUT',
        body: JSON.stringify({ name, selectedUsers }),
        headers: {
          'Token': localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        } })
        .then(() => {
          showNotification('Trade team updated');
          refreshView();
          return this.setState({ ...this.defaultState });
        })

        .catch((e) => {
          console.error(e);
          showNotification('Error', 'warning');
        });
    });
  }

  handleInputChange = inputName => (...event) => this.setState({
    ...this.state,
    [inputName]: removeSpecialChars(event[1]),
    validationErrors: {
      ...this.state.validationErrors,
      [inputName]: false,
    },
  });

  getUsers = () =>
    restClient(GET_LIST, 'users', {
      sort: {
        field: 'email',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    });

  handleOpen = async () => {
    this.initState();
    const { data: users } = await this.getUsers();
    const { selectedUsers } = this.state;
    const mappedUsers = users.map(({ name = '', email = '' }) => `${name} (${email})`.trim());
    this.setState({
      ...this.state,
      users: mappedUsers,
      selectedUsers: selectedUsers.filter(user => user.trim()),
    });
  }

  initState = () => {
    const { name, users = [] } = this.props.record;
    this.setState({
      ...this.state,
      name,
      selectedUsers: users,
      open: true,
    });
  }

  usersDataSource() {
    const { users, selectedUsers } = this.state;
    return users.filter(user => !selectedUsers.includes(user));
  }

  handleDeleteUsers = (user) => {
    const selectedUsers = this.state.selectedUsers.filter(u => u !== user);
    this.setState({ selectedUsers });
  }

  handleAddUsers = (user) => {
    const selectedUsers = [...this.state.selectedUsers, user];
    this.setState({ selectedUsers });
  }

  closeDialog = () => {
    this.setState({ ...this.defaultState });
  }

  render() {
    const { selectedUsers } = this.state;
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label={this.translate('tradeTeams.edit.buttonText')}
          icon={<CreateButtonIcon />}
          onClick={this.handleOpen}
        />
        <Dialog
          title={this.translate('tradeTeams.edit.title')}
          modal={false}
          autoScrollBodyContent={true}
          open={this.state.open}
        >
          <TextField
            floatingLabelText={this.translate('tradeTeams.edit.name')}
            errorText={this.state.validationErrors.name && this.translate('aor.validation.required')}
            fullWidth
            type="text"
            multiLine={true}
            value={this.state.name}
            onChange={this.handleInputChange('name')}
          />
          <ChipInput
            value={selectedUsers}
            openOnFocus={true}
            floatingLabelText={this.translate('tradeTeams.edit.users')}
            dataSource={this.usersDataSource()}
            onRequestAdd={this.handleAddUsers}
            onRequestDelete={this.handleDeleteUsers}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <div style={{ paddingTop: '15px', float: 'right' }} >
            <RaisedButton
              label={this.translate('pdfs.dialog.btnCancel')}
              onClick={this.closeDialog}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label={this.translate('pdfs.dialog.btnSubmit')}
              primary={true}
              icon={<SubmitIcon />}
              onClick={this.handleSubmit}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

EditButton.propTypes = {
  record: PropTypes.object,
  sendAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(EditButton);
