import { englishMessages } from 'admin-on-rest';
import label from './label';

export default {
  ...englishMessages,
  ...label,
  resources: {
    pdf_histories: {
      fields: {
        pdf_id: 'PDF',
      },
      show: {
        title: 'PDF Show',
      },
    },
  },
  dashboard: {
    title: 'Dashboard',
    welcome: {
      title: 'Welcome',
      subtitle: 'This is the admin of Marex Solutions Agile application.',
      playground: 'Graphql Playground',
      graphdoc: 'GraphDoc',
      voyager: 'Graphql Structure',
    },
    notSignedOTCConfirmations: {
      subtitle: 'Not Signed OTC Confirmations',
      empty: 'List does not exist',
      table: {
        email: 'Email',
        status: 'Status',
        sentAt: 'Sent at',
        sentReminder: 'Sent reminder',
        remindersCount: 'Reminders Count',
      },
    },
    dashboardRefresh: {
      subtitle: 'Dashboard Refresh Request',
      empty: 'List does not exist',
    },
    dashboardRequest: {
      subtitle: 'Dashboard Request',
      empty: 'List does not exist',
    },
    calculateHistory: {
      subtitle: 'Pricing History',
      empty: 'List does not exist',
      table: {
        type: 'Type',
        structure: 'Structure',
        barrierlevel: 'Barrier level',
        price: 'Price',
        strikeLevel: 'Accumulation Strike Level',
        referencePrice: 'Reference Price',
        quotedCurrency: 'Quoted Currency',
        notional: 'Notional',
        bloombergTicker: 'Bloomberg Ticker',
        commodityContract: 'Commodity Contract',
        user: 'User',
        time: 'Time',
        tag: 'Tag',
      },
    },
    pricingHistory: {
      subtitle: 'Pricing History',
      empty: 'List does not exist',
      table: {
        type: 'Type',
        structure: 'Structure',
        barrier: 'Barrier',
        price: 'Price',
        accumulation: 'Accumulation',
        referencePrice: 'Reference Price',
        currency: 'Currency',
        notional: 'Notional',
        underlying: 'Underlying',
        bloombergTicker: 'Bloomberg Ticker',
        user: 'User',
        createdAt: 'Time',
        tag: 'Tag',
      },
    },
    pending_user: {
      subtitle: 'Pending Users',
      empty: 'List does not exist',
    },
    all_user: {
      subtitle: 'All Users',
      empty: 'List does not exist',
    },
    app_user: {
      subtitle: 'Logged Users',
      empty: 'List does not exist',
    },
    rejected_user: {
      subtitle: 'Rejected Users',
      empty: 'List does not exist',
    },
    approved_user: {
      subtitle: 'Approved Users',
      empty: 'List does not exist',
    },
  },
  login: {
    field: {
      email: 'Email',
      password: 'Password',
    },
  },
  users: {
    validate: {
      password: 'Not a valid Password',
      verify: 'Password must be verified',
      minValue: 'Must be at least 0',
      isInteger: 'Must be an integer',
    },
    edit: {
      title: 'User',
      info: 'Information',
      permission: 'Permission',
    },
    show: {
      title: 'User',
      info: 'Information',
      history: 'Price History',
    },
    list: {
      search: 'Search',
      reject: 'Reject',
      approve: 'Approve',
    },
    notification: {
      approved_success: 'User approved',
      approved_error: 'Error: User not approved',
      rejected_success: 'User rejected',
      rejected_error: 'Error: User not rejected',
    },
  },
  underlyings: {
    edit: {
      title: 'Underlying',
    },
    list: {
      delete: 'Delete Selected',
      search: 'Search',
    },
    dialog: {
      title: 'Delete Underlyings',
      message: 'Are you sure?',
      btnCancel: 'Cancel',
      btnSubmit: 'Confirm',
    },
    notification: {
      delete_success: 'Underlyings deleted',
      delete_error: 'Error: Underlyings canot deleted',
    },
  },
  staticPages: {
    edit: {
      title: 'Static Page',
    },
    list: {
      search: 'Search',
    },
  },
  roles: {
    list: {
      search: 'Search',
    },
  },
  dashboards: {
    edit: {
      title: 'Dashboard',
    },
    list: {
      search: 'Search',
      btnGenerate: 'Generate PDF',
      btnDownload: 'Download PDF',
      btnDownloadCsv: 'Download CSV',
      btnSend: 'Send PDF',
      checkButton: 'Check statements',
    },
    notification: {
      send_success: 'Email was sent',
      send_error: 'Error: PDF not send',
      generate_success: 'PDF was generated',
      generate_error: 'Error: PDF not generate',
      generate_group_error: 'Error: PDF generation process not started',
      generate_group_success: 'PDF generation process started',
    },
  },
  structures: {
    edit: {
      title: 'Structure',
    },
    list: {
      delete: 'Delete Selected',
      search: 'Search',
    },
    dialog: {
      title: 'Delete Structures',
      message: 'Are you sure?',
      btnCancel: 'Cancel',
      btnSubmit: 'Confirm',
    },
    notification: {
      delete_success: 'Structures deleted',
      delete_error: 'Error: Structures canot deleted',
    },
  },
  pricings: {
    edit: {
      title: 'Pricing',
    },
    matrix: {
      title1: 'Barrier Accumulation',
      title2: 'Accumulation',
    },
    list: {
      delete: 'Delete Selected',
    },
    dialog: {
      title: 'Delete Pricings',
      message: 'Are you sure?',
      btnCancel: 'Cancel',
      btnSubmit: 'Confirm',
    },
    notification: {
      delete_success: 'Pricings deleted',
      delete_error: 'Error: Pricings canot deleted',
    },
  },
  pricingHistories: {
    edit: {
      title: 'Pricing History',
    },
  },
  dashboardRequests: {
    dialog: {
      btnConfirm: 'Confirm',
      btnCancel: 'Cancel',
      btnSubmit: 'Confirm',
      dashboards: 'User dashboards',
      title: 'Confirm Dashboard Request #',
      groups: 'User groups',
    },
    notification: {
      confirm_success: 'Dashboard Request confirmed',
      confirm_error: 'Error: Dashboard Request canot corfirmed',
    },
  },
  admins: {
    validate: {
      password: 'Not a valid Password',
      verify: 'Password must be verified',
    },
    edit: {
      title: 'User',
    },
    list: {
      search: 'Search',
    },
  },
  mails: {
    list: {
      search: 'Search',
    },
  },
  notifications: {
    list: {
      search: 'Search',
    },
  },
  pdfs: {
    dialog: {
      emailText: 'Choose user email or enter custom',
      bccText: 'Choose user BCC email or enter custom',
      ccText: 'Choose user CC email or enter custom',
      btnCancel: 'Cancel',
      btnClose: 'Close',
      btnSubmit: 'Submit',
      btnSend: 'Send',
      btnSendMany: 'Send many PDF',
      btnNotifyMany: 'Notify many PDF',
      btnSelectAll: 'Select All',
      btnClearAll: 'Clear All',
      btnGenerate: 'Generate many PDF',
      titleGenerate: 'Generate many PDF',
      titleSendMany: 'Send many PDF',
      titleNotifyMany: 'Notify many PDF',
      titleNotGenerated: 'Pdfs not generated',
      textDashboards: 'Choose Dashboards for PDF',
      textStatements: 'Choose Clients for PDF',
      title: 'Send PDF ',
      notifyTitle: 'Send notify ',
      loader: 'PDF generation is in progress, please wait according to queue',
      loaderMail: 'Emails send is in progress, please wait according to queue',
    },
    notification: {
      send_success: 'Email was sent',
      send_error: 'Error: PDF not send',
      generate_success: 'PDF was generated',
      generate_error: 'Error: PDF not generate',
    },
    sendWithoutPdf: {
      btnSend: 'Notify',
    },
  },
  pdfHistories: {
    dialog: {
      emailText: 'Choose user Email from existing user or type some email',
      bccText: 'Choose user BCC from existing user or type some email',
      ccText: 'Choose user CC from existing user or type some email',
      btnCancel: 'Cancel',
      btnSend: 'Send Again',
      btnSubmit: 'Submit',
      title: 'Send Again PDF ',
    },
    notification: {
      send_success: 'PDF was sent again',
      send_error: 'Error: PDF not send again',
    },
  },
  files: {
    list: {
      upload: 'Upload Files',
      uploadSingle: 'Upload File',
      search: 'Search',
    },
    notification: {
      remove_success: 'File removed',
      remove_error: 'Error: Can not remove file',
      upload_success: 'File uploaded',
      upload_error: 'Error: File not uploaded',
    },
  },
  refreshDashboards: {
    list: {
      search: 'Search',
      confirm: 'Confirm',
    },
    notification: {
      confirm_success: 'Dashboard refresh request confirmed',
      confirm_error: 'Error: Dashboard refresh request not confirmed',
    },
  },
  OTCConfirmations: {
    sendButton: {
      btnSend: 'Remind',
    },
    dialog: {
      title: 'Remind OTC confirmation #',
    },
    release: {
      title: 'Send OTC confirmatiln #',
      message: 'OTC confirmation will be send to email',
    },
    notification: {
      save_success: 'OTC confirmation was saved',
      save_error: 'Error: OTC confirmation not saved',
      send_success: 'OTC confirmation was sent',
      send_error: 'Error: OTC confirmation not sent',
      cancel_success: 'OTC confirmation was canceled',
      cancel_error: 'Error: OTC confirmation not canceled',
    },
    reminder: {
      send_success: 'Reminder was sent',
      send_error: "Error: Reminder haven't been sent",
    },
    create: {
      buttonText: 'Create',
      title: 'Create OTC confirmation',
      tradeNumber: 'Enter trade number',
      tradeType: 'Enter trade type',
      signerText: 'Enter signer email',
      bccText: 'Choose user BCC from existing user or type some email',
      ccText: 'Choose user CC from existing user or type some email',
      fullName: "Enter signer's full name",
      viewersText: 'Choose viewers',
    },
    topdf: {
      buttonText: 'TPL to PDF',
      title: 'Conver HTML to PDF file',
      save_error: 'Server Error',
    },
    wrongFileFormat: 'Wrong file format',
    fileMissingAnchor: "File does not include field - 'Signature:'.",
    edit: {
      buttonText: 'Viewers',
      title: "Edit OTC confirmation's users to display",
      emails: 'Emails',
      viewers_edit_success: "OTC confirmation's viewers have been updated",
      viewers_edit_error: "Error: viewers haven't been updated",
    },
    upload: {
      title: 'Upload',
      formTitle: 'Upload signed OTC confirmation',
      btnCancel: 'Cancel',
      btnSubmit: 'Upload',
      success: 'OTC confirmation successfully uploaded',
      fail: 'Error while uploading',
    },
  },
  marketData: {
    edit: {
      title: 'Market Data',
    },
    list: {
      active: 'Active',
      inactive: 'Inactive',
      delete: 'Delete Selected',
      search: 'Search (Bloomberg ticker, Quoted currency, Commodity contract)',
    },
    dialog: {
      title: 'Delete Market Data',
      message: 'Are you sure?',
      btnCancel: 'Cancel',
      btnConfirm: 'Confirm',
    },
    notification: {
      active_success: 'Market Data status changed',
      active_error: 'Error: Market Data can`t change status',
      delete_success: 'Market Data records deleted',
      delete_error: 'Error: Market Data can`t delete records',
    },
  },
  volatilityBucket: {
    edit: {
      title: 'Volatility Bucket',
    },
    list: {
      search: 'Search (Name)',
    },
  },
  volatilityConfig: {
    edit: {
      title: 'Volatility Config',
    },
    list: {
      search: 'Search (Market Data, Volatility Bucket)',
    },
  },
  vanillaDescription: {
    show: {
      title: 'Vanilla Description',
    },
    list: {
      delete: 'Delete Selected',
      search: 'Search (Product, Product category)',
    },
    dialog: {
      title: 'Delete Vanilla Description',
      message: 'Are you sure?',
      btnCancel: 'Cancel',
      btnSubmit: 'Confirm',
    },
    notification: {
      delete_success: 'Vanilla Description deleted',
      delete_error: 'Error: Vanilla Description canot deleted',
    },
  },
  rates: {
    show: {
      title: 'Rates',
    },
    list: {
      delete: 'Delete Selected',
    },
    dialog: {
      title: 'Delete Rates',
      message: 'Are you sure?',
      btnCancel: 'Cancel',
      btnSubmit: 'Confirm',
    },
    notification: {
      delete_success: 'Rates deleted',
      delete_error: 'Error: Rates canot deleted',
    },
  },
  volatilitySurface: {
    show: {
      title: 'Volatility Surface',
    },
    list: {
      delete: 'Delete Selected',
    },
    dialog: {
      title: 'Delete Volatility Surface',
      message: 'Are you sure?',
      btnCancel: 'Cancel',
      btnSubmit: 'Confirm',
    },
    notification: {
      delete_success: 'Volatility surface deleted',
      delete_error: 'Error: Volatility surface canot deleted',
    },
  },
  calculateHistory: {
    show: {
      title: 'Calculate History',
    },
    list: {
      search: 'Search (Structure, Type, Bloomberg Ticker, Commodity Contract)',
    },
  },
  accumulatorDescription: {
    show: {
      title: 'Accumulator Description',
    },
    list: {
      delete: 'Delete Selected',
      search: 'Search (Product Category, Structure, Structure Code, Product)',
    },
    dialog: {
      title: 'Delete Accumulator Description',
      message: 'Are you sure?',
      btnCancel: 'Cancel',
      btnSubmit: 'Confirm',
    },
    notification: {
      delete_success: 'Accumulator Description deleted',
      delete_error: 'Error: Accumulator Description canot deleted',
    },
  },
  accumulatorPricings: {
    show: {
      title: 'Accumulator Pricings',
    },
    list: {
      delete: 'Delete Selected',
      search: 'Search (Bloomberg Ticker, Currency, Structure Code)',
    },
    dialog: {
      title: 'Delete Accumulator Pricings',
      message: 'Are you sure?',
      btnCancel: 'Cancel',
      btnSubmit: 'Confirm',
    },
    notification: {
      delete_success: 'Accumulator Pricings deleted',
      delete_error: 'Error: Accumulator Pricings canot deleted',
    },
  },
  clientDatabase: {
    create: {
      to: 'Recap To',
      cc: 'Recap CC',
      bcc: 'Recap BCC',
      from: 'From',
      signerFullName: 'Signer Full Name',
      address: 'Address',
      entity: 'Entity',
      phoneNumber: 'Phone Number',
      unitsCode: 'Units Code',
      clientLanguage: 'Client Language',
      sales: 'Sales',
      trade: 'Trade',
      agreementType: 'Agreement Type',
      clientCode: 'Client Code',
      tradeNumber: 'Trade Number',
      contactName: 'Contact Name',
      team: 'Team',
      swapExpiryType: 'Swap Expiry Type',
      optionExpiryType: 'Option Expiry Type',
      buttonText: 'Create',
      title: 'Create client',
      clientName: 'Client name',
      dashboard: 'Dashboard name',
      refNumber: 'Client ref number',
      statementSigner: 'Statement signer',
      statementCc: 'Statement CC',
      statementBcc: 'Statement BCC',
      confirmationSigner: 'OTC signer',
      confirmationCc: 'OTC CC',
      confirmationBcc: 'OTC BCC',
      confirmationViewers: 'OTC viewers',
      confirmationCheckRecipients: 'Check recipients',
      selectedSalesTeams: 'Sales teams',
      selectedTradeTeams: 'Trade teams',
      successful: 'Client has been successfully created',
      sftpAccount: 'Client SFTP account',
      isDefaultAccount: 'Default Account',
      recordType: 'Record Type',
    },
    edit: {
      to: 'Recap To',
      cc: 'Recap CC',
      bcc: 'Recap BCC',
      from: 'From',
      signerFullName: 'Signer Full Name',
      address: 'Address',
      entity: 'Entity',
      phoneNumber: 'Phone Number',
      unitsCode: 'Units Code',
      clientLanguage: 'Client Language',
      sales: 'Sales',
      trade: 'Trade',
      agreementType: 'Agreement Type',
      clientCode: 'Client Code',
      tradeNumber: 'Trade Number',
      contactName: 'Contact Name',
      team: 'Team',
      swapExpiryType: 'Swap Expiry Type',
      optionExpiryType: 'Option Expiry Type',
      buttonText: 'Edit',
      title: 'Edit client',
      clientName: 'Client name',
      dashboard: 'Dashboard name',
      refNumber: 'Client ref number',
      statementSigner: 'Statement signer',
      statementCc: 'Statement CC',
      statementBcc: 'Statement BCC',
      confirmationSigner: 'OTC signer',
      confirmationCc: 'OTC CC',
      confirmationBcc: 'OTC BCC',
      confirmationViewers: 'OTC viewers',
      confirmationCheckRecipients: 'Check recipients',
      selectedSalesTeams: 'Sales teams',
      successful: 'Client has been successfully updated',
      sftpAccount: 'Client SFTP account',
      isDefaultAccount: 'Default Account',
      recordType: 'Record Type',
    },
  },
  salesTeams: {
    create: {
      buttonText: 'Create',
      title: 'Create sales team',
      recipients: 'Recipients',
      name: 'Name of team',
      clients: 'Clients Names',
    },
    edit: {
      buttonText: 'Edit',
      title: 'Edit sales team',
      recipients: 'Recipients',
      name: 'Sales Team Name',
      clients: 'Clients Names',
    },
  },
  tradeTeams: {
    create: {
      buttonText: 'Create',
      title: 'Create trade team',
      recipients: 'Recipients',
      name: 'Name of team',
      users: 'Users',
    },
    edit: {
      buttonText: 'Edit',
      title: 'Edit trade team',
      recipients: 'Recipients',
      name: 'Trade Team',
      users: 'Users',
    },
  },
  checkStatements: {
    send: {
      title: 'Check statements',
      success: 'Checks has been successfully sent',
      fail: 'Failed to send checks',
    },
  },
  marginCall: {
    notify: {
      recipients: 'Recipients',
      bcc: 'Choose user BCC from existing user or type some email',
      cc: 'Choose user CC from existing user or type some email',
      currency: 'Currency',
      sendValue: 'Send value',
      marginCall: 'Margin call',
      required: 'required',
      formTitle: 'Margin call notification',
      success: 'Margin call notification successfully sent',
    },
    list: {
      marginCallUpdated: 'Margin call succesfully updated',
      marginCallFailed: 'Margin call update failed',
    },
  },
  cron: {
    notification: {
      active_success: 'Cron active status changed',
      active_error: 'Error: Cron can`t change active status',
    },
  },
  NeonOrders: {
    update: {
      status: {
        success: 'Succesfully updated status',
        fail: 'Update status failed',
      },
    },
    post: {
      status: {
        success_neon: 'Order succesfully sent',
        fail_neon: 'Order approve failed',
        success_fx: 'Successfully approved price request',
        fail_fx: 'Price request failed',
      },
    },
    get: {
      status: {
        fail: 'Get order info failed',
      },
    },
    cancel: {
      status: {
        success: 'Cancel order request sent',
        fail: 'Cancel order request failed',
      },
    },
    actions: {
      cancel: 'Cancel',
      reject: 'Reject',
      approve: 'Approve',
    },
  },
  configurableSp: {
    search: 'Search',
    edit: {
      title: 'Edit SP Input',
      buttonText: 'Edit',
      nameField: 'name',
      successful: 'Config Updated',
      error: {
        nameExists: 'Name already exists, please change name to something unique',
        invalidComponentType: 'ComponentType not valid',
        NoFiles: 'Error uploading file, please try again',
      },
    },
    preview: {
      title: 'Preview SP Input',
      buttonText: 'Preview',
    },
    review: {
      title: 'Review',
      buttonText: 'Review',
      visible: 'Make visible to all',
      successful: 'Config Updated',
      noErrors: 'No errors detected in config files. Please ensure to test in the SP Test tab in Agile.',
      noLayout: 'No layout config file has been uploaded',
      noRules: 'No rules config file has been uploaded',
      layoutComponentError: 'Layout contains components not defined in SP Inputs Configurator',
    },
    productRepo: {
      title: 'Product Repo Mapping',
      buttonText: 'Product Repo',
      successful: 'Config Updated',
      nameField: 'Payoff',
      error: {
        noPayoff: 'No payoff found',
        noWrapper: 'No wrapper found',
        generic: 'Something went wrong, please try again',
      },
    },
    ordering: {
      title: 'Ordering',
      buttonText: 'Ordering',
      successful: 'Config Updated',
      error: {
        generic: 'Something went wrong, please try again',
      },
    },
    create: {
      title: 'Create SP Input',
      buttonText: 'Create',
      nameField: 'name',
      successful: 'Config Created',
      error: {
        nameExists: 'Name already exists, please change name to something unique',
        invalidComponentType: 'ComponentType not valid',
        noJsonFile: 'No JSON file provided',
      },
    },
    dialog: {
      btnCancel: 'Cancel',
      btnSubmit: 'Submit',
      btnClose: 'Close',
      btnSave: 'Save',
      btnSearch: 'Search',
    },
    checkForUpdates: {
      title: 'Check for Updates',
      questionCheck: 'Do you want to check for updates?',
      checkLabel: 'Check',
      availableUpdates: 'Available Updates: ',
      noUpdatesAvailable: 'No Updates are avavilable at the moment',
      versionUpdate: 'Version Update: ',
      uriUpdate: 'URI Update: ',
      section: 'Section: ',
      field: 'Field: ',
      fieldProperties: {
        property: 'Property: ',
        currentValue: 'Current Value: ',
        newValue: 'New Value: ',
        categorisations: 'Categorisations: ',
        dataType: 'Data Type: ',
        defaultValue: 'Default Value: ',
        mandatory: 'Mandatory: ',
        path: 'Path: ',
      },
      noValue: 'No Value',
      updateLabel: 'Update',
      successful: 'Record updated successfully',
      successfullyReceivedChanges: 'Record changes received successfully',
      error: {
        generic: 'Something went wrong, please try again',
      },
    },
  },
  configurableSpLayouts: {
    edit: {
      title: 'Edit SP Layout',
      buttonText: 'Edit',
      nameField: 'name',
      successful: 'Config Updated',
      error: {
        structureExists: 'Structure already exists, please change name to something unique',
        invalidComponentType: 'ComponentType not valid',
        NoFiles: 'Error uploading file(s), please check file and try again',
        generic: 'Something went wrong, please try again',
        invalidRules: 'Error parsing rules config, please check file',
        invalidLayout: 'Error parsing layouts config, please check file',
      },
    },
    preview: {
      title: 'Preview SP Structure',
      buttonText: 'Preview',
    },
    create: {
      title: 'Create SP Wrapper Structure',
      buttonText: 'Create',
      nameField: 'name',
      successful: 'Config Created',
      error: {
        structureExists: 'Structure already exists, please change name to something unique',
        invalidComponentType: 'ComponentType not valid',
        noJsonFile: 'No JSON file provided',
        NoFiles: 'Error uploading file(s), please check file and try again',
        generic: 'Something went wrong, please try again',
        invalidRules: 'Error parsing rules config, please check file',
        invalidLayout: 'Error parsing layouts config, please check file',
      },
    },
    generate: {
      title: 'Generate layout from Payoff',
      buttonText: 'Generate',
      error: {
        payoffAlreadyExists: 'Payoff already exists, no payoff generated',
        generatePayoffError: 'Something went wrong, please try again',
        generic: 'Something went wrong, please try again',
      },
    },
  },
  configurableSpWrappers: {
    edit: {
      title: 'Edit SP Wrapper',
      buttonText: 'Edit',
      nameField: 'name',
      successful: 'Config Updated',
      error: {
        wrapperExists: 'Wrapper already exists, please change name to something unique',
        invalidComponentType: 'ComponentType not valid',
        NoFiles: 'Error uploading file(s), please check file and try again',
        generic: 'Something went wrong, please try again',
        invalidRules: 'Error parsing rules config, please check file',
        invalidLayout: 'Error parsing layouts config, please check file',
      },
    },
    preview: {
      title: 'Preview SP Wrapper',
      buttonText: 'Preview',
    },
    create: {
      title: 'Create SP Wrapper',
      buttonText: 'Create',
      nameField: 'name',
      successful: 'Config Created',
      error: {
        invalidComponentType: 'ComponentType not valid',
        noJsonFile: 'No JSON file provided',
        NoFiles: 'Error uploading file(s), please check file and try again',
        generic: 'Something went wrong, please try again',
        invalidRules: 'Error parsing rules config, please check file',
        invalidLayout: 'Error parsing layouts config, please check file',
      },
    },
    generate: {
      title: 'Generate layout from Wrapper',
      buttonText: 'Generate',
      error: {
        wrapperAlreadyExists: 'Wrapper already exists, no wrapper generated',
        generatePayoffError: 'Something went wrong, please try again',
        generic: 'Something went wrong, please try again',
      },
    },
  },
  pricingCards: {
    create: {
      buttonText: 'Create',
      title: 'Create CM Pricing Card',
      frequency: 'Frequency',
      direction: 'Direction',
      payoffBound: 'Payoff Bound Type',
      type: 'Type',
      options: 'Include calculation Options',
      successful: 'Pricing card has been successfully created',
    },
    edit: {
      buttonText: 'Edit',
      title: 'Edit CM Pricing Card',
      frequency: 'Frequency',
      direction: 'Direction',
      payoffBound: 'Payoff Bound Type',
      type: 'Type',
      options: 'Include calculation Options',
      successful: 'Pricing card has been successfully updated',
    },
    form: {
      titleField: 'Title',
      description: 'Description',
      structure: 'Structure Code',
      flexible: 'Flexible',
      slack_workflow: 'Slack Workflow Type',
    },
    dialog: {
      btnCancel: 'Cancel',
      btnSubmit: 'Submit',
    },
    notification: {
      reply_success: 'Pricing Cards Request replied',
      reply_error: 'Error: Pricing Cards Request cannot reply',
    },
  },
  spPricingTemplate: {
    title: 'Structured Product Pricing Templates',
    create: 'Create SP Pricing Template',
    shortTitle: 'SP Pricing Template',
    search: 'Search',
    name: 'Name',
    userGroups: 'User Groups',
    created: 'Created',
    updated: 'Updated',
  },
  pricingTemplates: {
    search: 'Search',
    create: {
      buttonText: 'Create',
      title: 'Create CM Pricing Template',
      name: 'Name',
      markups: 'Markups',
      users: 'Assign Users to Template',
      user_groups: 'Assign User Groups to Template',
      underlying: 'Underlying',
      pricingCard: 'Pricing Cards',
      successful: 'Pricing template has been successfully created',
      group: 'Group',
    },
    edit: {
      buttonText: 'Edit',
      title: 'Edit CM Pricing Template',
      name: 'Name',
      markups: 'Markups',
      users: 'Edit assigned Users',
      user_groups: 'Edit assigned User Groups',
      underlying: 'Underlying',
      pricingCard: 'Pricing Cards',
      successful: 'Pricing template has been successfully updated',
      group: 'Group',
    },
    clone: {
      buttonText: 'Copy',
      title: 'Duplicate CM Pricing Template',
      name: 'Name',
      markups: 'Markups',
      users: 'Edit assigned Users',
      user_groups: 'Edit assigned User Groups',
      underlying: 'Underlying',
      pricingCard: 'Pricing Cards',
      successful: 'Pricing template has been successfully duplicated',
      group: 'Group',
    },
    common: {
      markupSales: 'Sales Markup',
      markupIb: 'IB Markup',
      markupClient: 'Client Markup',
      useAbsMarkup: 'Use Absolute Values',
      cardsTitle: 'Pricing Template Cards',
      search: 'Search',
    },
    dialog: {
      btnCancel: 'Cancel',
      btnSubmit: 'Submit',
    },
    notification: {
      reply_success: 'Pricing Templates Request replied',
      reply_error: 'Error: Pricing Templates Request cannot reply',
      group_error: 'The underlying with selected card already added',
    },
  },
  customer: {
    create: {
      buttonText: 'Create',
      title: 'Create customer',
    },
    edit: {
      buttonText: 'Edit',
      title: 'Edit customer',
    },
    list: {
      search: 'Search',
    },
    customerCode: 'Enter customer code',
    client: 'Select client',
    name: 'Enter customer name',
    validate: {
      clientCode: 'Allowed characters: A-Z, 0-9 and underscore',
      save_error: 'Error: Customer not saved',
      save_success: 'Customer was saved',
    },
  },
  fxCommodities: {
    list: {
      search: 'Search',
    },
  },
  domain: {
    create: {
      buttonText: 'Create',
      title: 'Create domain',
    },
    edit: {
      buttonText: 'Edit',
      title: 'Edit domain',
    },
    list: {
      search: 'Search',
    },
    name: 'Enter domain name',
  },
  theme_template: {
    create: {
      buttonText: 'Create',
      title: 'Create theme template',
    },
    edit: {
      buttonText: 'Edit',
      title: 'Edit theme template',
    },
    list: {
      search: 'Search',
    },
    themeTemplateDefinition: 'Enter theme template definition',
    name: 'Enter theme template name',
    validate: {
      clientCode: 'Allowed characters: A-Z, 0-9 and underscore',
      save_error: 'Error: Theme template not saved',
      save_success: 'Theme template was saved',
    },
  },
  location: {
    create: {
      buttonText: 'Create',
      title: 'Create location',
    },
    edit: {
      buttonText: 'Edit',
      title: 'Edit location',
    },
    list: {
      search: 'Search',
    },
    locationCode: 'Enter location code',
    client: 'Select client',
    name: 'Enter location name',
    validate: {
      clientCode: 'Allowed characters: A-Z, 0-9 and underscore',
      save_error: 'Error: Customer not saved',
      save_success: 'Customer was saved',
    },
  },
  userGroup: {
    create: {
      buttonText: 'Create',
      title: 'Create group',
    },
    code: 'Enter user group code',
    name: 'Enter user group name',
    clients: 'Select clients',
    spPricingTemplate: 'SP Pricing Template',
    theme: 'Theme',
    spreadSheet: 'Spread Sheet',
  },
  pricingCardViewTemplates: {
    create: {
      buttonText: 'Create',
      title: 'Create Card View Template',
      templateField: 'Template',
      structureField: 'Structures',
      successful: 'Card View Template has been successfully created',
    },
    edit: {
      buttonText: 'Edit',
      title: 'Edit Card View Template',
      templateField: 'Template',
      structureField: 'Structures',
      successful: 'Card View Template been successfully updated',
    },
    dialog: {
      btnCancel: 'Cancel',
      btnSubmit: 'Submit',
    },
    notification: {
      reply_success: 'Pricing Card View Templates Request replied',
      reply_error: 'Error: Pricing Card View Templates Request cannot reply',
    },
  },
  livePositions: {
    reset: {
      buttonText: 'Reset',
      successMessage: 'Sums of Client and Hedge were reset',
      failureMessage: 'Error: Sums of Client and Hedge were not reset',
    },
    dialog: {
      title: 'Reset Live Position',
      buttonCancel: 'Close',
      buttonConfirm: 'Confirm',
      commodityLabel: 'Commodity',
      startingPositionLabel: 'Starting Position',
    },
    delete: {
      title: 'Delete Live Position Record for ',
      buttonText: 'Delete',
      successMessage: 'Record deleted',
      failureMessage: 'Error: Record was not deleted',
      confirmation: 'Delete Record ',
    },
    form: {
      startingPosition: 'Starting Position',
      sumOfClient: 'Sum Of Client',
      sumOfHedge: 'Sum Of Hedge',
      livePosition: 'Live Position',
      commodityLabel: 'Commodity',
    },
    list: {
      title: 'Live Positions',
    },
  },
  minifutureProducts: {
    list: {
      title: 'Minifuture Products',
      commodityCode: 'Commodity Code',
      structureName: 'Structure Name',
      externalId: 'External Id',
      strike: 'Strike',
      type: 'Type',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
      clientBarrierLevel: 'Client Barrier Level',
      clientCode: 'Client Code',
      contractExpiry: 'Contract Expiry',
      expiryDates: 'Contract Expiry / FX Value Date',
      fxValueDate: 'FX Value Date',
      ratio: 'Ratio',
      isAgileProduct: 'Is Agile Product',
      qtoValue: 'Quanto Value',
      financing: 'Financing',
      div: 'Div',
      issueSize: 'Issue Size',
      ccy: 'ccy',
      status: 'status',
      version: 'version',
    },
    show: {
      showProduct: 'Minifuture Product Show',
    }
  }
};
