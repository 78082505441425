import UserSaga from './users/UserSaga';
import DashboardRequestSaga from './dashboardRequests/DashboardRequestSaga';
import PricingSaga from './pricings/PricingSaga';
import RefreshDashboardSaga from './refreshDashboards/RefreshDashboardSaga';
import PDFSaga from './dashboards/PDFSaga';
import PDFHistorySaga from './pdfHistories/PDFHistorySaga';
import OTCSaga from './otcConfirmation/OTCSaga';
import VanillaDescriptionSaga from './vanillaDescription/VanillaDescriptionSaga';
import AccumulatorDescriptionSaga from './accumulatorDescription/AccumulatorDescriptionSaga';
import RatesSaga from './rates/RatesSaga';
import VolatilitySurfaceSaga from './volatilitySurface/VolatilitySurfaceSaga';
import MarketDataSaga from './marketData/MarketDataSaga';
import { AdminChangePasswordSaga } from './adminChangePassword';
import spSaga from './pricingTemplateStructuredProducts/spSaga';
import NotificationCenterSaga from './notificationCenter/saga';
import OrderHistorySaga from './neonOrdersHistories/saga';
import AuthClientSaga from './authClient/saga';

export default [
  UserSaga,
  DashboardRequestSaga,
  PricingSaga,
  RefreshDashboardSaga,
  PDFSaga,
  PDFHistorySaga,
  OTCSaga,
  VanillaDescriptionSaga,
  RatesSaga,
  VolatilitySurfaceSaga,
  MarketDataSaga,
  AccumulatorDescriptionSaga,
  AdminChangePasswordSaga,
  spSaga,
  NotificationCenterSaga,
  OrderHistorySaga,
  AuthClientSaga,
];
