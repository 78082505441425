import { Datagrid, DateInput, Filter, List, NumberInput, Responsive, SimpleList, TextField, TextInput } from 'admin-on-rest';
import React, { Component } from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput, formatDateInput, dateParser } from '../utils/dates';

const EqVolatilityShiftFilter = props => (
  <Filter {...props}>
    <TextInput
      source="volatility_bucket"
    />
    <NumberInput
      source="min_shift_buy"
    />
    <NumberInput
      source="min_shift_sell"
    />
    <NumberInput
      source="incremental_shift_buy"
    />
    <NumberInput
      source="incremental_shift_sell"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
      parse={dateParser}
    />
  </Filter>
);

class EqVolatilityShiftList extends Component {
  render() {
    return (<List {...this.props}
      filters={<EqVolatilityShiftFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={50}
    >
      <Responsive
        small={
          <SimpleList
            primaryText={r => `${r.value} ${r.currency}`}
            secondaryText={r => `${r.strike_level} ${r.barrier_level}`}
            tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
          />
        }
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
            <TextField
              source="volatility_bucket"
            />
            <TextField
              source="min_shift_buy"
            />
            <TextField
              source="min_shift_sell"
            />
            <TextField
              source="incremental_shift_buy"
            />
            <TextField
              source="incremental_shift_sell"
            />
            <DateField
              formatDate={formatDateTimeInput}
              source="created_at"
            />
          </Datagrid>
        }
      />
    </List>);
  }
}

export default EqVolatilityShiftList;
