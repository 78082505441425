import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Toggle from 'material-ui/Toggle';
import request from 'superagent';
import debounce from 'lodash/debounce';

import {
  showNotification as showNotificationAction,
} from 'admin-on-rest';
import path from '../../path';

import './TradeBtn.css';

class TradeBtn extends Component {
  constructor(props) {
    super(props);
    const { record: { id, slackTeam, isTrade } } = props;
    this.state = {
      id,
      slackTeam,
      value: isTrade || false,
      disabled: !slackTeam,
    };
  }

  shouldComponentUpdate({ record: prev }, nextState) {
    const { record: { slackTeam, isTrade } } = this.props;

    if (slackTeam !== prev.slackTeam || isTrade !== prev.isTrade) {
      return true;
    }

    if (
      nextState.slackTeam !== this.state.slackTeam
      || nextState.isTrade !== this.state.isTrade
    ) {
      return true;
    }
    return nextState.value !== this.state.value;
  }

  componentDidUpdate({ record: prev }) {
    const { record: { slackTeam, isTrade } } = this.props;
    if (slackTeam !== prev.slackTeam) {
      this.setState(state => ({
        ...state,
        slackTeam,
        disabled: !slackTeam,
        value: isTrade || false,
      }));
    }
  }

  onToggle = debounce(() => {
    const { id, value, disabled } = this.state;
    const { showNotification } = this.props;
    if (!disabled) {
      request
        .put(`${path}/admin/users/trade/${id}`)
        .set('token', localStorage.getItem('session'))
        .send({
          status: !value,
        })
        .then(({ body }) => {
          if (body.error) {
            showNotification(body.error, 'warning');
          }
          this.setState(state => ({
            ...state,
            value: !value,
          }));
        })
        .catch(() => showNotification('Something went wrong', 'warning'));
    } else {
      showNotification('Please add a SlackTeam to the user prior to activating "Trade".', 'warning');
    }
  }, 300)

  render() {
    const { value, disabled } = this.state;
    const className = !disabled ? 'trade-btn-active' : 'trade-btn-disabled';
    return (
      <Toggle
        className={className}
        toggled={value}
        onToggle={this.onToggle}
      />
    );
  }
}

const enhance = compose(
  connect(null, {
    showNotification: showNotificationAction,
  }),
);

export default enhance(TradeBtn);
