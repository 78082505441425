import React from 'react';
import {
  Edit,
  translate,
} from 'admin-on-rest';

import { DomainForm } from './components';

const DomainTitle = translate(({ record }) =>
  <span>Edit { record.name } domain/url</span>);

const EditDomain = props =>
  <Edit {...props} title={<DomainTitle />}>
    <DomainForm />
  </Edit>;

export default EditDomain;
