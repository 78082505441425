import React from 'react';
import Paper from 'material-ui/Paper';
import ToggleDisplay from 'react-toggle-display';
import NumericLabel from 'react-pretty-numbers';
import Moment from 'react-moment';

import { translate } from 'admin-on-rest';
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';

import path from '../path';

class VolatilitySurfaceMatrix extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
    this.state = {
      matrix: [],
      show: false,
    };
  }

  componentDidMount() {
    const { record } = this.props;

    fetch(`${path}/admin/volatility_surface/matrix/${record.id}`, {
      method: 'GET',
      headers: {
        Token: localStorage.getItem('session'),
      },
    }).then(res => res.json())
      .then(res => this.setState({ matrix: res, show: true }));
  }

  render() {
    const matrix = Object.values(this.state.matrix.reduce((curr, i) => {
      if (!curr[i.date]) {
        curr[i.date] = {
          row: i,
          rows: [],
        };
      }
      curr[i.date].rows.push(i);
      return curr;
    }, {}));

    return (
      <ToggleDisplay
        show={this.state.show}
      >
        <Paper style={{ width: '70%', float: 'right' }} zDepth={2}>
          <Table selectable={false}>
            <TableBody displayRowCheckbox={false}>
              <TableRow key="barrier">
                <TableRowColumn
                  style={{ padding: '0px', fontSize: '10px', whiteSpace: 'pre-wrap', textAlign: 'right' }}
                  key="title"
                  colSpan={2}
                />
                {matrix.length && matrix[0].rows.map(r => (
                  <TableRowColumn
                    style={{ padding: '0px', fontSize: '10px' }}
                    key={r.id}
                    colSpan={1}
                  >
                    <NumericLabel params={{ precision: 0, justification: 'L' }}>
                      {Number(r.strike_level)}
                    </NumericLabel>
                  </TableRowColumn>
                ))}
              </TableRow>
              {matrix.map(r => (
                <TableRow key={r.row.id}>
                  <TableRowColumn
                    style={{ padding: '0px', textAlign: 'center', fontSize: '10px' }}
                    key={`accumulation-${r.row.id}`}
                    colSpan={2}
                  >
                    <Moment format="DD/MM/YYYY">{r.row.date}</Moment>
                  </TableRowColumn>
                  {r.rows.map(c => (
                    <TableRowColumn
                      style={{ padding: '0px', fontSize: '10px' }}
                      key={`value-${r.row.id}`}
                      colSpan={1}
                    >
                      <NumericLabel params={{ precision: 2, justification: 'L', percentage: true }}>
                        {Number(c.percentage) / 100}
                      </NumericLabel>
                    </TableRowColumn>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </ToggleDisplay>
    );
  }
}

export default translate(VolatilitySurfaceMatrix);
