import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

import Axis from './Axis';

const canvasWidth = 100;
const barWidth = 15;
const barMargin = 0.5;
const leftX = (canvasWidth / 2) - (barWidth + barMargin);
const rightX = (canvasWidth / 2) + (barWidth + barMargin);
const barX = (canvasWidth / 2) - (barWidth / 2);
const barHeight = 150;

const D3SingleBar = (props) => {
  const { value, label, units, style, maxValue, startColor, stopColor } = props;


  const axisScale = useMemo(() => (
    d3.scaleLinear()
      .domain([0, maxValue])
      .range([barHeight, 0])
  ), [maxValue]);

  const colorScale = React.useMemo(() => d3.scaleLinear()
    .domain([0, maxValue])
    .range([stopColor, startColor]), [startColor, stopColor, maxValue]);
  const gradientSteps = React.useMemo(() => colorScale.ticks(10)
    .map(v => colorScale(v)), [startColor, stopColor, maxValue]);


  const {
    y,
    height,
  } = React.useMemo(() => {
    const h = barHeight - axisScale(value);
    return { y: h < barHeight ? axisScale(value) : 0, height: h < barHeight ? h : barHeight };
  }, [value]);


  return (
    <div style={{
      textAlign: 'center',
      ...style,
    }}>
      <svg
        style={{ overflow: 'visible' }}
        width={canvasWidth}
      >
        <Axis direction='left' xPos={leftX} scale={axisScale} />
        <defs>
          <linearGradient
            id="gradient1"
            gradientUnits="userSpaceOnUse"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%">
            {gradientSteps.map((color, index) => (
              <stop
                key={color}
                stopColor={color}
                offset={`${index / (gradientSteps.length - 1)}`}
              />
            ))}
          </linearGradient>
        </defs>
        <rect width={barWidth} height={barHeight} x={barX} y={0} fill={'url(#gradient1)'}/>
        <rect width={barWidth} height={height} x={barX} y={y} fill={'rgb(31, 119, 180)'}/>
        <Axis direction='right' xPos={rightX} scale={axisScale} />
      </svg>

      <div style={{
        fontSize: '1.5em',
        lineHeight: '1em',
        fontWeight: '900',
        fontFeatureSettings: "'zero', 'tnum' 1",
      }}>
        {value}
      </div>
      {!!label && (
        <div style={{
          color: '#8b8ba7',
          fontSize: '1.3em',
          lineHeight: '1.3em',
          fontWeight: '700',
        }}>
          {label}
        </div>
      )}
      {!!units && (
        <div style={{
          color: '#8b8ba7',
          lineHeight: '1.3em',
          fontWeight: '300',
        }}>
          {units}
        </div>
      )}

    </div>
  );
};

D3SingleBar.PropTypes = {
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  style: PropTypes.join,
  label: PropTypes.string,
  units: PropTypes.string,
  startColor: PropTypes.string.isRequired,
  stopColor: PropTypes.string.isRequired,
};

D3SingleBar.defaultProps = {
  value: 0,
  maxValue: 200,
  style: {},
  label: null,
  units: null,
  startColor: '#c2cf8b',
  stopColor: '#c93522',
};

export default D3SingleBar;
