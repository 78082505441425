export const STATEMENT_SEND = 'STATEMENT_SEND';
export const STATEMENT_SEND_LOADING = 'STATEMENT_SEND_LOADING';
export const STATEMENT_SEND_FAILURE = 'STATEMENT_SEND_FAILURE';
export const STATEMENT_SEND_SUCCESS = 'STATEMENT_SEND_SUCCESS';

export const STATEMENT_SEND_MANY = 'STATEMENT_SEND_MANY';
export const STATEMENT_SEND_MANY_LOADING = 'STATEMENT_SEND_MANY_LOADING';
export const STATEMENT_SEND_MANY_FAILURE = 'STATEMENT_SEND_MANY_FAILURE';
export const STATEMENT_SEND_MANY_SUCCESS = 'STATEMENT_SEND_MANY_SUCCESS';

export const STATEMENT_GENERATE = 'STATEMENT_GENERATE';
export const STATEMENT_GENERATE_LOADING = 'STATEMENT_GENERATE_LOADING';
export const STATEMENT_GENERATE_FAILURE = 'STATEMENT_GENERATE_FAILURE';
export const STATEMENT_GENERATE_SUCCESS = 'STATEMENT_GENERATE_SUCCESS';

export const STATEMENT_GROUP_GENERATE = 'STATEMENT_GROUP_GENERATE';
export const STATEMENT_GROUP_GENERATE_LOADING = 'STATEMENT_GROUP_GENERATE_LOADING';
export const STATEMENT_GROUP_GENERATE_FAILURE = 'STATEMENT_GROUP_GENERATE_FAILURE';
export const STATEMENT_GROUP_GENERATE_SUCCESS = 'STATEMENT_GROUP_GENERATE_SUCCESS';

export const STATEMENT_CREATE = 'STATEMENT_CREATE';
export const STATEMENT_CREATE_LOADING = 'STATEMENT_CREATE_LOADING';
export const STATEMENT_CREATE_FAILURE = 'STATEMENT_CREATE_FAILURE';
export const STATEMENT_CREATE_SUCCESS = 'STATEMENT_CREATE_SUCCESS';
