/* eslint-disable */
import React from 'react';
import { push as pushAction } from 'react-router-redux';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { CardActions } from 'material-ui/Card';
import {
  Edit,
  SimpleForm,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
  translate,
  NumberInput,
  TextField,
  BooleanInput,
  ListButton,
  RefreshButton,
  CheckboxGroupInput,
} from 'admin-on-rest';
import { SectionBox } from './../components';

const RECORD_FORM = 'record-form';
const selector = formValueSelector(RECORD_FORM);

const MarkUpGroup = ({ area, label, useAbsMarkup, ...rest }) => (
  <div className="markup-group" style={{ maxWidth: '800px' }}>
    <label style={{ color: 'rgba(0, 0, 0, 0.3)', display: 'block', marginTop: '30px' }}>
      {rest.translate(`pricingTemplates.common.${label}`)}
    </label>
    {useAbsMarkup ? (
      <SectionBox {...rest}>
        <NumberInput source={`markups.${area}.abs`} label="ABS" {...rest} />
      </SectionBox>
    ) : (
      <SectionBox {...rest}>
        <NumberInput source={`markups.${area}.x`} label="x" {...rest} />
        <NumberInput source={`markups.${area}.y`} label="y" {...rest} />
        <NumberInput source={`markups.${area}.z`} label="z" {...rest} />
      </SectionBox>
    )}
  </div>
);

const redirectUrl = ({ template_id }) => {
  const cardFilter = `%7B"template_id"%3A"${template_id}"%7D&order=DESC&page=1&perPage=25&sort=templateName`;
  return `/pricing_template_cards?filter=${cardFilter}`;
}

const FormActions = props => {
  return (
    <CardActions style={{ zIndex: 2, display: 'inline-block', float: 'right', }}>
      <ListButton basePath={redirectUrl(props)} />
      <RefreshButton />
    </CardActions>
  );
};

const textFieldStyle = { display: 'inline-block', marginRight: '20px' };

const PricingTemplateCardsEdit = props => (
  <Edit {...props} title="CM Pricing template card editing" actions={<FormActions {...props} />}>
    <SimpleForm redirect={redirectUrl(props)}>
      <TextField label="Template" source="templateName" style={textFieldStyle} />
      <TextField label="Group" source="groupName" style={textFieldStyle} />
      <TextField label="Underlying" source="underlyingName" style={textFieldStyle} />
      <TextField label="Card" source="cardName" />
      <h3>Markups:</h3>
      <BooleanInput source="useAbsMarkup" label={props.translate('pricingTemplates.common.useAbsMarkup')} />
      <MarkUpGroup area="sales" label="markupSales" {...props} />
      <MarkUpGroup area="ib" label="markupIb" {...props} />
      <MarkUpGroup area="client" label="markupClient" {...props} />
      <CheckboxGroupInput source="dateConfig.expiryAllowedDays" label="Expiry days" choices={[
        { id: '1', name: 'Monday' },
        { id: '2', name: 'Tuesday' },
        { id: '3', name: 'Wednesday' },
        { id: '4', name: 'Thursday' },
        { id: '5', name: 'Friday' },
      ]} />
    </SimpleForm>
  </Edit>
);

const mapStateToProps = state => ({
  useAbsMarkup: selector(state, 'useAbsMarkup'),
  template_id: selector(state, 'template_id'),
});

const enhance = compose(
  translate,
  connect(mapStateToProps, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
    push: pushAction,
  }),
);

export default enhance(PricingTemplateCardsEdit);
