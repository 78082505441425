import React, { Component, Fragment } from 'react';
import { Tabs, Tab } from 'material-ui/Tabs';
import { Datagrid, DateInput, Filter, List, translate } from 'admin-on-rest';
import compose from 'recompose/compose';
import { formatDateInput, dateParser } from '../utils/dates';
import PricingMiniJSON from './components/pricingMini';
import AgileXML from './components/agileXml';
import PricingMiniRatios from './components/ratios';

const PricingLogsFilter = props => (
  <Filter {...props}>
    <DateInput
      options={{ formatDate: formatDateInput }}
      source="created_at"
      showTime
      parse={dateParser}
    />
  </Filter>
);

const pricingMiniType = '/pricing_logs/pricing_mini_log';
const agileType = '/pricing_logs/agile';
const slackbotType = '/pricing_logs/bot';
const autocallType = '/pricing_logs/autocall';
const pricingMiniRatios = '/pricing_logs/pricing_mini_ratios';

class PricingLogsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: pricingMiniType,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const type = location.pathname;
    this.setState({ type });
  }

  handleTab = (event, value, tabPropshandle) => {
    const { props: tabProps } = tabPropshandle;
    const { history } = this.props;
    history.push({ pathname: tabProps.value });
  }

  List = CustomComponent => (<List {...this.props}
    width={this.state.type.length} // force rerender
    filters={<PricingLogsFilter/>}
    sort={{ field: 'created_at', order: 'DESC' }}
    title="Pricing logs"
  >
    <Datagrid>
      { CustomComponent }
    </Datagrid>
  </List>)

  renderSwitch() {
    switch (this.state.type) {
    case pricingMiniType:
      return this.List(<PricingMiniJSON />);
    case pricingMiniRatios:
      return <PricingMiniRatios />;
    default:
      return this.List(<AgileXML />);
    }
  }

  render() {
    return (
      <Fragment>
        <Tabs value={this.state.type} onChange={this.handleTab}>
          <Tab className="form-tab" value={pricingMiniType} label='Accumulator pricing mini json files' />
          <Tab className="form-tab" value={agileType} label='Agile XML files' />
          <Tab className="form-tab" value={slackbotType} label='Slack bot XML files' />
          <Tab className="form-tab" value={autocallType} label='Autocallable XML files' />
          <Tab className="form-tab" value={pricingMiniRatios} label='Ratios' />
        </Tabs>
        {this.renderSwitch()}
      </Fragment>
    );
  }
}

const enhance = compose(
  translate,
);

export default enhance(PricingLogsList);
