import React from 'react';
import { Create } from 'admin-on-rest';

import { DomainForm, DomainToolbar } from './components';

const CreateButton = props => (
  <Create {...props}>
    <DomainForm toolbar={<DomainToolbar />} />
  </Create>
);

export default CreateButton;
