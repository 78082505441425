import { DateInput, DeleteButton, Filter, FunctionField, List, NumberField, NumberInput, ReferenceField, RefreshButton, Responsive, ShowButton, SimpleList, TextField, TextInput } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import React from 'react';
import Moment from 'react-moment';
import { Datagrid, DateField } from '../helpers';
import { formatDateTimeInput, formatDateInput, dateParser } from '../utils/dates';
import PricingDeleteButton from './PricingDeleteButton';

const PricingFilter = props => (
  <Filter {...props}>
    <TextInput
      label="Strucutre (Name, Code)"
      source="structure_id"
    />
    <TextInput
      label="Underlying (Reference Contract)"
      source="underlying_id"
    />
    <NumberInput
      source="business_days"
    />
    <NumberInput
      source="reference_price"
    />
    <TextInput
      source="currency"
    />
    <DateInput
      label="started at"
      options={{
        formatDate: formatDateInput,
      }}
      source="start_date"
      showTime
      parse={dateParser}
    />
    <DateInput
      label="expired at"
      options={{
        formatDate: formatDateInput,
      }}
      source="expiry_date"
      showTime
      parse={dateParser}
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="structure_expired_at"
      showTime
      parse={dateParser}
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
      parse={dateParser}
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
      parse={dateParser}
    />
  </Filter>
);

const PricingAction = ({
  refresh,
  filters,
  resource,
  showFilter,
  displayedFilters,
  filterValues,
  disabled,
  loaded,
}) => (
  <CardActions>
    <PricingDeleteButton disabled={disabled} loaded={loaded()} />
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    <RefreshButton refresh={refresh} />
  </CardActions>
);

class PricingList extends React.Component {
  constructor(props) {
    super(props);

    this.ids = [];
    this.state = { ids: [], rows: [] };
  }

  async handleRowClick(rows) {
    if (rows === 'all') {
      await this.setState({
        ids: this.ids,
        rows,
      });
      return;
    }
    if (rows === 'none') {
      await this.setState({
        ids: [],
        rows,
      });
      return;
    }
    await this.setState({
      ids: this.ids.filter((el, i) => rows.includes(i)),
      rows,
    });
  }
  render() {
    return (
      <List {...this.props}
        actions={<PricingAction disabled={() => !this.state.ids.length} loaded={() => this.state.ids} />}
        filters={<PricingFilter />}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={50}
      >
        <Responsive
          small={
            <SimpleList
              primaryText={r => r.reference_price}
              secondaryText={r => r.currency}
              tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.structure_expired_at}</Moment>}
            />
          }
          medium={
            <Datagrid
              headerOptions={{ adjustForCheckbox: true, displaySelectAll: true }}
              bodyOptions={{ displayRowCheckbox: true, showRowHover: true }}
              rowOptions={{ selectable: true }}
              options={{ multiSelectable: true, onRowSelection: this.handleRowClick.bind(this), height: '70vh' }}
              selected={i => this.state.rows.includes(i)}
            >
              <ReferenceField
                label="strucutre"
                source="structure_id"
                reference="structures"
                linkType="show"
              >
                <FunctionField
                  render={record => record.code}
                />
              </ReferenceField>
              <ReferenceField
                label="underlying"
                source="underlying_id"
                reference="underlyings"
                linkType="show"
              >
                <FunctionField
                  render={record => record.reference_contract}
                />
              </ReferenceField>
              <NumberField
                source="business_days"
              />
              <NumberField
                source="reference_price"
              />
              <TextField
                ref={el => (el ? this.ids.push(el.props.record.id) : null)}
                source="currency"
              />
              <DateField
                label="started at"
                formatDate={formatDateTimeInput}
                source="start_date"
                showTime
              />
              <DateField
                label="expired at"
                formatDate={formatDateTimeInput}
                source="expiry_date"
                showTime
              />
              <DateField
                formatDate={formatDateTimeInput}
                source="structure_expired_at"
                showTime
              />
              <DateField
                formatDate={formatDateTimeInput}
                source="updated_at"
                showTime
              />
              <DateField
                formatDate={formatDateTimeInput}
                source="created_at"
                showTime
              />
              <ShowButton />
              <DeleteButton />
            </Datagrid>
          }
        />
      </List>
    );
  }
}

export default PricingList;
