import React from 'react';
import debounce from 'lodash/debounce';
import { formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  required,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
} from 'admin-on-rest';


import {
  validateMaxLengthToForty,
  validateMinLengthCurrencyCode,
  validateMaxLengthCurrencyCode,
  validateMinLengthCurrencyPair,
} from '../../utils/validations';

const RECORD_FORM = 'record-form';
const selector = formValueSelector(RECORD_FORM);
const inputStyle = { margin: '0 10px 0 10px' };

const FxCommodityForm = ({ toolbar, baseCurrency = '', termCurrency = '', change: changeField, ...rest }) => {
  const onCurrencyChanged = (type) => debounce((e) => {
    const value = typeof e === 'object' ? e.target.value : e;
    const code = Object.values({ baseCurrency, termCurrency, ...{ [type]: value } }).map((str) => str.toUpperCase()).join('');
    changeField(RECORD_FORM, 'fx_code', code);
  }, 100);

  return (
    <SimpleForm toolbar={toolbar} {...rest}>
      <TextInput
        options={{
          disabled: true,
        }}
        source="fx_code"
        validate={[required, validateMinLengthCurrencyPair]}
      />
      <TextInput
        source="agile_name"
        validate={[required, validateMaxLengthToForty]}
      />
      <TextInput
        source="base_currency"
        validate={[required, validateMinLengthCurrencyCode, validateMaxLengthCurrencyCode]}
        onChange={onCurrencyChanged('baseCurrency')}
      />
      <TextInput
        source="term_currency"
        validate={[required, validateMinLengthCurrencyCode, validateMaxLengthCurrencyCode]}
        onChange={onCurrencyChanged('termCurrency')}
      />
      <SelectInput
        source="deliverable"
        validate={[required]}
        choices={[
          { id: true, name: 'Yes' },
          { id: false, name: 'No' },
        ]}
      />
      <NumberInput
        source="base_currency_qty"
        validate={[required, validateMaxLengthToForty]}
      />
      <NumberInput
        source="spread"
        validate={[required, validateMaxLengthToForty]}
      />
      <label style={{ margin: '20px 0 -10px 0' }}>Markup Spread:</label>
      <NumberInput style={inputStyle} label="x" source="markup_spread.x" validate={[required, validateMaxLengthToForty]} />
      <NumberInput style={inputStyle} label="y" source="markup_spread.y" validate={[required, validateMaxLengthToForty]} />
      <NumberInput style={inputStyle} label="z" source="markup_spread.z" validate={[required, validateMaxLengthToForty]} />
      <SelectInput
        source="status"
        validate={[required]}
        choices={[
          { id: true, name: 'Active' },
          { id: false, name: 'Inactive' },
        ]}
      />
      <SelectInput
        source="price_digits_convention"
        validate={[required]}
        choices={[
          { id: 3, name: '3' },
          { id: 4, name: '4' },
          { id: 5, name: '5' },
          { id: 6, name: '6' },
        ]}
      />
    </SimpleForm>
  );
};

const enhance = compose(
  connect(state => ({
    baseCurrency: selector(state, 'base_currency'),
    termCurrency: selector(state, 'term_currency'),
  }), { change }),
);

export default enhance(FxCommodityForm);
// export default FxCommodityForm;
