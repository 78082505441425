import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Labeled } from 'admin-on-rest';
import { formValueSelector, change } from 'redux-form';
import { Table, TableHeader, TableBody, TableRow, TableHeaderColumn, TableRowColumn } from 'material-ui/Table';
import Checkbox from 'material-ui/Checkbox';
import _ from 'lodash';
import ReferenceLoadingProgress from './ReferenceLoadingProgress';
import path from '../../path';

const RECORD_FORM = 'record-form';

class Permissions extends Component {
  defaultState = {
    list: [],
    loading: true,
  }

  constructor(props) {
    super(props);
    this.state = this.defaultState;
  }

  async componentDidMount() {
    const { change: changeField, create, source } = this.props;
    const { list } = await fetch(`${path}/admin/admin_roles/permissions`, {
      method: 'GET',
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(response => response.json());
    this.setState(state => ({
      ...state,
      list,
      loading: false,
    }));
    if (create) changeField(RECORD_FORM, source, list.map(({ resource }) => ({ resource, actions: [] })));
  }

  handleCheck = (event, isChecked) => {
    const [resource, action] = event.target.value.split('-');
    const { change: changeField, source, data } = this.props;

    const item = _.find(data, { resource });
    if (!item) data.push({ resource, actions: [] });

    const newData = [...data].map(({ resource: res, actions }) => {
      if (res !== resource) return { resource: res, actions };
      return {
        resource: res,
        actions: isChecked ?
          [...actions, action] :
          [...actions].filter(el => el !== action),
      };
    });
    changeField(RECORD_FORM, source, newData);
  }

  isChecked(resource, action) {
    const { actions } = _.find(this.props.data, { resource }) || { actions: [] };
    return actions.includes(action);
  }

  setCheckbox = (resource, ations, action) =>
    (ations.includes(action) ?
      <Checkbox
        checked={this.isChecked(resource, action)}
        onCheck={this.handleCheck}
        value={`${resource}-${action}`}
        disabled={this.props.disabled}
      />
      : null)

  render() {
    const { label } = this.props;
    const { loading, list } = this.state;
    if (loading) {
      return <ReferenceLoadingProgress label={label} />;
    }
    return (<Fragment>
      <Labeled label={label}>
        <Table fixedHeader={false}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableHeaderColumn>
                <span>Resource</span>
              </TableHeaderColumn>
              <TableHeaderColumn>
                <span>Read</span>
              </TableHeaderColumn>
              <TableHeaderColumn>
                <span>Create</span>
              </TableHeaderColumn>
              <TableHeaderColumn>
                <span>Update</span>
              </TableHeaderColumn>
              <TableHeaderColumn>
                <span>Delete</span>
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={false}
            className="datagrid-body"
          >
            {list.map(({ resource, actions }, rowIndex) => (
              <TableRow selectable={false} key={rowIndex}>
                <TableRowColumn>{resource}</TableRowColumn>
                <TableRowColumn>
                  {this.setCheckbox(resource, actions, 'read:any')}
                </TableRowColumn>
                <TableRowColumn>
                  {this.setCheckbox(resource, actions, 'create:any')}
                </TableRowColumn>
                <TableRowColumn>
                  {this.setCheckbox(resource, actions, 'update:any')}
                </TableRowColumn>
                <TableRowColumn>
                  {this.setCheckbox(resource, actions, 'delete:any')}
                </TableRowColumn>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Labeled>
    </Fragment>);
  }
}

Permissions.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
  disabled: PropTypes.bool,
};

Permissions.defaultProps = {
  label: 'Permissions',
  source: 'permissions',
  disabled: false,
};

const selector = formValueSelector(RECORD_FORM);

const enhance = compose(
  connect((state, { source }) => ({
    data: selector(state, source),
  }), { change }),
);

export default enhance(Permissions);
