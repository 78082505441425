import { connect } from 'react-redux';
import { Datagrid, DateInput, Filter, List, Responsive, TextField, TextInput } from 'admin-on-rest';
import React from 'react';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput, formatDateInput, dateParser } from '../utils/dates';

const EqStructuresFilter = props => (
  <Filter {...props}>
    <TextInput
      source="structure"
    />
    <TextInput
      source="instrument_type"
    />
    <TextInput
      source="xml_type"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
      parse={dateParser}
    />
  </Filter>
);

class EqStructuresList extends React.Component {
  render() {
    const { columns } = this.props;
    const hide = ['id', 'total_count'];
    const visible = columns && columns.filter(column => !hide.includes(column));
    return (<List {...this.props}
      filters={<EqStructuresFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={50}
    >
      <Responsive
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
            {visible && visible.map(column => (column !== 'created_at'
              ? <TextField key={column} source={column} />
              : <DateField
                formatDate={formatDateTimeInput}
                source="created_at"
                showTime
              />))
            }
          </Datagrid>
        }
      />
    </List>);
  }
}
const mapStateToProps = ({
  admin: { resources: { eq_structures: { data } } },
}) => {
  const keys = data && Object.keys(data);
  const item = keys && keys[0] ? data[keys[0]] : null;
  const columns = item ? Object.keys(item) : null;
  return {
    columns,
    data,
  };
};

export default connect(mapStateToProps)(EqStructuresList);
