import { DeleteButton, List, Responsive, ShowButton, SimpleList, TextField } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { Datagrid, DateField } from '../helpers';
import { formatDateTimeInput } from '../utils/dates';
import VolatilitySurfaceActions from './VolatilitySurfaceActions';
import VolatilitySurfaceFilter from './VolatilitySurfaceFilter';

class VolatilitySurfaceList extends React.Component {
  constructor(props) {
    super(props);

    this.ids = [];
    this.state = { ids: [], rows: [] };
  }

  async handleRowClick(rows) {
    if (rows === 'all') {
      await this.setState({
        ids: this.ids,
        rows,
      });
      return;
    }
    if (rows === 'none') {
      await this.setState({
        ids: [],
        rows,
      });
      return;
    }
    await this.setState({
      ids: this.ids.filter((el, i) => rows.includes(i)),
      rows,
    });
  }
  render() {
    return (
      <List {...this.props}
        actions={<VolatilitySurfaceActions disabled={() => !this.state.ids.length} loaded={() => this.state.ids} />}
        filters={<VolatilitySurfaceFilter />}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={50}
      >
        <Responsive
          small={
            <SimpleList
              primaryText={r => r.reference_price}
              secondaryText={r => r.currency}
              tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.structure_expired_at}</Moment>}
            />
          }
          medium={
            <Datagrid
              headerOptions={{ adjustForCheckbox: true, displaySelectAll: true }}
              bodyOptions={{ displayRowCheckbox: true }}
              rowOptions={{ selectable: true }}
              options={{ multiSelectable: true, onRowSelection: this.handleRowClick.bind(this) }}
              selected={i => this.state.rows.includes(i)}
            >
              <TextField
                ref={el => (el ? this.ids.push(el.props.record.id) : null)}
                source="short_code"
              />
              <DateField
                formatDate={formatDateTimeInput}
                source="generated_at"
                showTime
              />
              <DateField
                formatDate={formatDateTimeInput}
                source="updated_at"
                showTime
              />
              <ShowButton />
              <DeleteButton />
            </Datagrid>
          }
        />
      </List>
    );
  }
}

export default VolatilitySurfaceList;
