
import React from 'react';
import ShowIcon from 'material-ui/svg-icons/image/remove-red-eye';
import './DeleteButton.css';

const ShowButton = props => (
  <div className='delete-button' >
    <a href={`#${props.basePath}/${props.record.id}/show`}>
      <ShowIcon color="rgb(0, 188, 212)" style={{ margin: '7px 0 0' }} />
    </a>
  </div>
);

export default ShowButton;
