import React from 'react';
import { required, SimpleForm, TextInput, SaveButton, Toolbar } from 'admin-on-rest';


const UpdateToolbar = props => <Toolbar {...props} >
  <SaveButton label="Save" redirect={props.redirect} submitOnEnter={true} disabled={true} />
</Toolbar>;

export const GSAccountsForm = ({ toolbar, record, ...rest }) => (
  <SimpleForm toolbar={<UpdateToolbar />} {...rest} record={record}>
    <TextInput label="Type" source="type" options={{ disabled: true }} validate={[required]} />
    <TextInput label="Account" source="account" validate={[required]} {...rest} />
    <TextInput label="Alloc Account" source="allocAccount" validate={[required]} {...rest} />
  </SimpleForm>
);
