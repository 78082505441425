import React from 'react';
import { compose } from 'redux';
import Moment from 'react-moment';
import {
  Datagrid,
  SimpleList,
  NumberField,
  DateField,
  BooleanField,
  List,
  Responsive,
  TextField,
  translate,
  Filter,
  TextInput,
  NumberInput,
  ShowButton,
  FunctionField,
  DeleteButton,
} from 'admin-on-rest';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';
import MinifutureProductsActions from './MinifutureProductsActions';
import HistoryButton from 'components/HistoryButton';

const ButtonsWrapper = (props) => (
  <div style={{ width: 'max-content' }}>
    <HistoryButton
      id={props?.record?.id}
      name={props?.record?.name}
      url="minifuture_product_histories"
      style={{ float: 'left' }}
      title={'History'}
    />
    <ShowButton {...props} />
    <DeleteButton {...props} />
  </div>
);

const MinifutureProductsSearch = (props) => {
  const { translate } = props;

  return (
    <Filter {...props}>
      <TextInput label="Search (external id)" source="_q" alwaysOn options={{ style: { width: '300px' } }} />
      <TextInput source="underlying" label={translate('minifutureProducts.list.commodityCode')} />
      <TextInput source="structureName" label={translate('minifutureProducts.list.structureName')} />
      <TextInput source="externalId" label={translate('minifutureProducts.list.externalId')} />
      <TextInput source="type" label={translate('minifutureProducts.list.type')} />
      <TextInput source="clientCode" label={translate('minifutureProducts.list.clientCode')} />
      <NumberInput source="ratio" label={translate('minifutureProducts.list.ratio')} />
      <NumberInput source="strike" label={translate('minifutureProducts.list.strike')} />
      <NumberInput source="qtoValue" label={translate('minifutureProducts.list.qtoValue')} />
      <NumberInput source="financing" label={translate('minifutureProducts.list.financing')} />
      <NumberInput source="div" label={translate('minifutureProducts.list.div')} />
      <NumberInput source="issueSize" label={translate('minifutureProducts.list.issueSize')} />
      <TextInput source="ccy" label={translate('minifutureProducts.list.ccy')} />
    </Filter>
  );
};

const MinifuturesProductsList = (props) => {
  const { translate } = props;
  return (
    <List
      {...props}
      title={translate('minifutureProducts.list.title')}
      actions={<MinifutureProductsActions {...props} />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      perPage={50}
      filters={<MinifutureProductsSearch {...props} />}
    >
      <Responsive
        small={
          <SimpleList
            primaryText={(r) => `${r.underlying}`}
            secondaryText={(r) => <Moment format="DD MMMM YYYY HH:mm">{r.updatedAt}</Moment>}
          />
        }
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
            <TextField source="id" />
            <TextField source="underlying" label={translate('minifutureProducts.list.commodityCode')} />
            <TextField source="structureName" label={translate('minifutureProducts.list.structureName')} />
            <TextField source="externalId" label={translate('minifutureProducts.list.externalId')} />
            <TextField source="type" label={translate('minifutureProducts.list.type')} />
            <NumberField source="strike" label={translate('minifutureProducts.list.strike')} />
            <NumberField source="clientBarrierLevel" label={translate('minifutureProducts.list.clientBarrierLevel')} />
            <TextField source="clientCode" label={translate('minifutureProducts.list.clientCode')} />
            <DateField
              formatDate={formatDateTimeInput}
              source="contractExpiry"
              label={translate('minifutureProducts.list.contractExpiry')}
              showTime
            />
            <DateField
              formatDate={formatDateTimeInput}
              source="fxValueDate"
              label={translate('minifutureProducts.list.fxValueDate')}
              showTime
            />
            <TextField source="ratio" label={translate('minifutureProducts.list.ratio')} />
            <BooleanField source="isAgileProduct" label={translate('minifutureProducts.list.isAgileProduct')} />
            <FunctionField
              source="qtoValue"
              label={translate('minifutureProducts.list.qtoValue')}
              render={(record) => record.qtoValue ?? 0}
            />
            <FunctionField
              source="financing"
              label={translate('minifutureProducts.list.financing')}
              render={(record) => record.financing ?? 0}
            />
            <FunctionField
              source="div"
              label={translate('minifutureProducts.list.div')}
              render={(record) => record.div ?? 0}
            />
            <NumberField source="issueSize" label={translate('minifutureProducts.list.issueSize')} />
            <TextField source="ccy" label={translate('minifutureProducts.list.ccy')} />
            <NumberField source="version" label={translate('minifutureProducts.list.version')} />
            <TextField source="status" label={translate('minifutureProducts.list.status')} />
            <DateField
              formatDate={formatDateTimeInput}
              label={translate('minifutureProducts.list.updatedAt')}
              source="updatedAt"
              showTime
            />
            <DateField
              formatDate={formatDateTimeInput}
              label={translate('minifutureProducts.list.createdAt')}
              source="createdAt"
              showTime
            />
            <ButtonsWrapper />
          </Datagrid>
        }
      />
    </List>
  );
};

export const MinifutureProductsList = compose(translate)(MinifuturesProductsList);
