import React, { useState } from 'react';
import { compose } from 'redux';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import {
  refreshView as refreshViewAction,
  showNotification as showNotificationAction,
  translate as adminTranslate,
} from 'admin-on-rest';
import request from 'superagent';
import { connect } from 'react-redux';
import { ActionViewModule } from 'material-ui/svg-icons/index.es';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import AvSubtitles from 'material-ui/svg-icons/av/subtitles';
import path from '../../path';
import { API_SOURCE_CONFIG } from './utils';
import { StructureDataRecord, WrapperDataRecord } from '../types';
import { ComponentEnhancer } from 'recompose';
import { SpCommonDispatchProps, spFieldListData } from './types';

interface GenerateButtonOwnProps {
  record?: StructureDataRecord | WrapperDataRecord;
  configurablePrefix?: string;
  fieldName: string;
  floatingLabelText: string;
}

type GenerateButtonProps = GenerateButtonOwnProps & SpCommonDispatchProps;

const GenerateButton: React.FC<GenerateButtonProps> = ({
  record,
  translate,
  showNotification,
  refreshView,
  fieldName,
  floatingLabelText,
  configurablePrefix = 'configurableSpLayouts.generate',
}) => {
  const [open, setOpen] = useState(false);
  const [loading, isLoading] = useState(false);
  const [spField, setSpField] = useState<string>(record?.[fieldName]?.name?.toLowerCase() || '');
  const [spFieldList, setSpFieldList] = useState<spFieldListData[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { apiSource, allDefinition } = API_SOURCE_CONFIG[fieldName];

  const handleOpen = async () => {
    setOpen(true);
    setSpField(record?.[fieldName]?.name?.toLowerCase() || '');
    setErrorMessage(undefined);
    isLoading(false);

    request
      .get(`${path}/admin/${apiSource}/${allDefinition}`)
      .set('Token', localStorage.getItem('session'))
      .then((res) => {
        const list = res.body;
        setSpFieldList(list);
      })
      .catch((e) => {
        showNotification(translate(`${configurablePrefix}.error.${e.response.body.message || 'generic'}`, 'warning'));
      });
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleSpFieldChange = (_, index: number) => {
    setSpField(spFieldList[index]?.name);
  };

  const handleGenerate = () => {
    setErrorMessage(undefined);
    isLoading(true);

    const uri = spFieldList.find((p) => p.name === spField)?.id;

    request
      .post(`${path}/admin/${apiSource}/generate`)
      .set('Token', localStorage.getItem('session'))
      // product repo has hyphens for spaces in the GET request
      .send({ [fieldName]: uri })
      .then(() => {
        closeDialog();
      })
      .catch((e) => {
        setErrorMessage(translate(`${configurablePrefix}.error.${e.response.body.message || 'generic'}`, 'warning'));
      })
      .finally(() => {
        isLoading(false);
        refreshView();
      });
  };

  return (
    <div>
      <FlatButton
        label={translate(`${configurablePrefix}.buttonText`)}
        icon={<ActionViewModule />}
        onClick={handleOpen}
      />
      <Dialog
        title={translate(`${configurablePrefix}.title`)}
        modal={false}
        open={open}
        repositionOnUpdate={false}
        style={{ paddingTop: '50px' }}
        bodyStyle={{ overflowY: 'auto' }}
      >
        <div style={{ paddingTop: '15px' }}>
          <div style={{ paddingBottom: '15px' }}>
            <SelectField
              disabled={!spFieldList.length}
              floatingLabelText={floatingLabelText}
              fullWidth
              value={spField}
              onChange={handleSpFieldChange}
              required
            >
              {spFieldList.map((x) => (
                <MenuItem key={x.id} value={x.name} primaryText={x.name} />
              ))}
            </SelectField>
            {errorMessage && <div style={{ color: 'red', paddingBottom: '6px' }}>{errorMessage}</div>}

            <RaisedButton
              label={translate(`${configurablePrefix}.buttonText`)}
              onClick={handleGenerate}
              primary={true}
              disabled={!spField || loading}
              icon={<AvSubtitles />}
            />
          </div>

          <div style={{ paddingTop: '15px', float: 'right' }}>
            <RaisedButton
              label={translate('configurableSp.dialog.btnClose')}
              onClick={closeDialog}
              icon={<CancelIcon />}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const enhance: ComponentEnhancer<GenerateButtonProps, GenerateButtonOwnProps> = compose(
  adminTranslate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(GenerateButton);
