import { DateInput, Filter, NumberInput, SelectInput, TextInput } from 'admin-on-rest';
import React from 'react';
import { formatDateInput } from '../utils/dates';

const LogFilters = props => (
  <Filter {...props}>
    <TextInput
      label="Search (name, description)"
      source="_q"
      alwaysOn
      options={{ style: { width: '300px' } }}
    />
    <NumberInput
      source="id"
    />
    <TextInput
      source="name"
    />
    <TextInput
      source="description"
    />
    <SelectInput label="Status" source="status" choices={[
      { id: 'true', name: 'true' },
      { id: 'false', name: 'false' },
    ]} />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
    />
  </Filter>
);

export default LogFilters;
