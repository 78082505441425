import React from 'react';
import { SelectInput, SimpleForm } from 'admin-on-rest';
import { Table, TableHeader, TableRow, TableHeaderColumn, TableBody, TableRowColumn } from 'material-ui/Table';
import { Loader } from '../../helpers/Loader';
import path from '../../path';
// import { ToolbarGroup } from 'material-ui/Toolbar';

export default class Instruments extends React.Component {
  constructor() {
    super();
    this.state = {
      products: [],
      products_selected: null,
      month: [],
      list: [],
      load: false,
    };
  }

  componentDidMount() {
    fetch(`${path}/admin/neon_management/vanilla_products`, {
      method: 'GET',
      headers: {
        Token: localStorage.getItem('session'),
      },
    }).then(res => res.json())
      .then((res) => {
        this.setState(state => ({
          ...state,
          products: res.commodities,
          month: [],
          list: [],
          load: false,
        }));
      });
  }

  onChangeProduct = (val) => {
    this.setState(state => ({
      ...state,
      month: [],
      list: [],
      load: true,
      products_selected: null,
    }));

    const codes = this.state.products.find(item => item.id === val);
    if (!codes || !codes.commodityCode) {
      this.setState(state => ({
        ...state,
        load: false,
      }));
    }

    fetch(`${path}/admin/neon_management/vanilla_exp`, {
      method: 'POST',
      body: JSON.stringify({
        commodityCode: codes.commodityCode,
      }),
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    }).then(res => res.json())
      .then((res) => {
        this.setState(state => ({
          ...state,
          products_selected: codes.commodityCode,
          month: res.exp,
          list: [],
          load: false,
        }));
      });
  };

  onChangeMonth = (val) => {
    this.setState(state => ({
      ...state,
      list: [],
      load: true,
    }));

    const monthes = this.state.month.find(item => item.id === val);
    if (!monthes || !monthes.expiry) {
      this.setState(state => ({
        ...state,
        load: false,
      }));
    }

    fetch(`${path}/admin/neon_management/vanilla_list`, {
      method: 'POST',
      body: JSON.stringify({
        commodityCode: this.state.products_selected,
        expiry: monthes.expiry,
      }),
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    }).then(res => res.json())
      .then((res) => {
        this.setState(state => ({
          ...state,
          list: res.list,
          load: false,
        }));
      }).catch((error) => {
        this.setState(state => ({
          ...state,
          load: false,
        }));
        console.error(error);
      });
  };

  render() {
    const ProductNameField = ({ record }) => <span>{record.commodity}</span>;
    const MonthNameField = ({ record }) => <span>{record.expiry}</span>;
    const { products, month, load, list } = this.state;
    return (
      <SimpleForm>
        {load ? <Loader text='Please wait...' /> : ''}
        <SelectInput input={{ onChange: this.onChangeProduct }} source="Product" choices={products} optionText={<ProductNameField />}/>
        { month.length > 0 ? <SelectInput input={{ onChange: this.onChangeMonth }} source="Month" choices={month} optionText={<MonthNameField />}/> : null }

        <Table
          fixedHeader={false}
          selectable={false}
        >
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableHeaderColumn colSpan={4}>Call</TableHeaderColumn>
              <TableHeaderColumn ></TableHeaderColumn>
              <TableHeaderColumn colSpan={4}>Put</TableHeaderColumn>
            </TableRow>
            <TableRow>
              <TableHeaderColumn >Status</TableHeaderColumn>
              <TableHeaderColumn >Code</TableHeaderColumn>
              <TableHeaderColumn >Bid</TableHeaderColumn>
              <TableHeaderColumn >Ask</TableHeaderColumn>
              <TableHeaderColumn >Strike price/agile/level</TableHeaderColumn>
              <TableHeaderColumn >Bid</TableHeaderColumn>
              <TableHeaderColumn >Ask</TableHeaderColumn>
              <TableHeaderColumn >Code</TableHeaderColumn>
              <TableHeaderColumn >Status</TableHeaderColumn>
            </TableRow>
          </TableHeader>

          <TableBody displayRowCheckbox={false}>
            {list.map((item, key) => (
              <TableRow key={key}>
                <TableRowColumn>{item.cstatus}</TableRowColumn>
                <TableRowColumn>{item.ccode}</TableRowColumn>
                <TableRowColumn>{item.cbid}</TableRowColumn>
                <TableRowColumn>{item.cask}</TableRowColumn>
                <TableRowColumn>{item.strike}</TableRowColumn>
                <TableRowColumn>{item.pbid}</TableRowColumn>
                <TableRowColumn>{item.pask}</TableRowColumn>
                <TableRowColumn>{item.pcode}</TableRowColumn>
                <TableRowColumn>{item.pstatus}</TableRowColumn>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SimpleForm>
    );
  }
}
