import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  Datagrid,
  List,
  refreshView,
  Responsive,
  SimpleList,
  TextField,
  translate,
} from 'admin-on-rest';
import Seo from '../components/common/Seo';
import {
  DeleteButton,
  EditButton,
} from '../components';
import { DomainFilters } from './components';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';
import { DateField } from '../helpers';

const DomainsList = props => (
  <Fragment>
    <Seo title={'Domains/URL'} />
    <List {...props}
      sort={{ field: 'name', order: 'DESC' }}
      filters={<DomainFilters />}
      perPage={25}
      refreshView={true}
      title='Domains/URL'
    >
      <Responsive
        small={
          <SimpleList
            primaryText={r => r.name}
            secondaryText={r => r.customer_code}
            tertiaryText={r => r.group}
          />
        }
        medium={
          <Datagrid
            bodyOptions={{ showRowHover: true }}
            options={{ height: '70vh' }}
            styles={datagridDefaultStyles}
          >
            <TextField source="id" />
            <TextField source="name" />
            <DateField
              formatDate={formatDateTimeInput}
              source="updatedAt"
              showTime
            />
            <DateField
              formatDate={formatDateTimeInput}
              source="createdAt"
              showTime
            />
            <DeleteButton />
            <EditButton />
          </Datagrid>
        }
      />
    </List>
  </Fragment>
);

const enhance = compose(
  translate,
  connect(null, {
    refreshView,
  }),
);

export default enhance(DomainsList);
