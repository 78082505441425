/* eslint-disable */
import { showNotification as showNotificationAction } from 'admin-on-rest';
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import { Table, TableBody, TableFooter, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import { grey50 } from 'material-ui/styles/colors';
import EditIcon from 'material-ui/svg-icons/image/edit';
import ExpandMoreIcon from 'material-ui/svg-icons/navigation/expand-more';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import path from '../../path';
import ProductTable from './ProductTable';

const adminPath = `${path}/cqg`;

const AdminButton = props => (
  <FlatButton
    label={props.label}
    onClick={props.onClick}
    icon={<EditIcon color="#01579B" />}
    style={{ minWidth: '30px', padding: '0 15px' }}
  />
);

class CqgStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: []
    };
  }

  refresh = () => {
    fetch(`${adminPath}/marketdata`, {
      method: 'GET',
      headers: {
        Token: localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(res => res.json())
      .then(data => {
        const expandState = this.state.rows ? this.state.rows.map(x => ({ name: x.name, open: x.open })) : [];
        const isOpen = name => {
          var existing = expandState.find(x => x.name === name);
          return existing && existing.open;
        };

        const arr = Array.isArray(data.instances) ? data.instances : [];
        this.setState(
          {
            rows: arr.map(x => ({ ...x, open: isOpen(x.name) })),
            mem: data.mem,
            pid: data.pid,
          });
      });
  };

  onExpandRow = rowIdx => () => {
    this.setState({
      rows: this.state.rows.map((x, i) => ({
        ...x,
        userproduct: '',
        usercontract: '',
        open: rowIdx === i ? !x.open : x.open,
      })),
    });
  };

  componentDidMount() {
    this.refresh();
  }

  render() {
    return (
      <Fragment>
        <div>
          <Toolbar>
            <ToolbarGroup firstChild={true}>
              <AdminButton label="Refresh" onClick={this.refresh} />
            </ToolbarGroup>
          </Toolbar>

          <Table fixedHeader={false}>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn>CQG Proxy</TableHeaderColumn>
                <TableHeaderColumn>Num Products</TableHeaderColumn>
                <TableHeaderColumn>Num Connected Contracts</TableHeaderColumn>
                <TableHeaderColumn>Num Registered Contracts</TableHeaderColumn>
                <TableHeaderColumn>Num Updates</TableHeaderColumn>
                <TableHeaderColumn>Avg Updates</TableHeaderColumn>
                <TableHeaderColumn>Avg Latency</TableHeaderColumn>
                <TableHeaderColumn>Expand</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false} deselectOnClickaway={false}>
              {this.state.rows.map((x, i) => (
                <Fragment>
                  <TableRow key={i}>
                    <TableRowColumn>{x.name}</TableRowColumn>
                    <TableRowColumn>{x.numProducts}</TableRowColumn>
                    <TableRowColumn>{x.connectedContracts}</TableRowColumn>
                    <TableRowColumn>{x.registeredContracts}</TableRowColumn>
                    <TableRowColumn>{x.numUpdates}</TableRowColumn>
                    <TableRowColumn>{x.avgUpdates}</TableRowColumn>
                    <TableRowColumn>{x.maxAvgLatency}</TableRowColumn>
                    <TableRowColumn>
                      <IconButton onClick={this.onExpandRow(i)}>
                        <ExpandMoreIcon />
                      </IconButton>
                    </TableRowColumn>
                  </TableRow>
                  {!x.open ? null : (
                    <TableRow key={'sub' + i} style={{ backgroundColor: grey50 }}>
                      <TableRowColumn colSpan={8} style={{ paddingLeft: '40px' }} >
                        <ProductTable row={x} refresh={this.refresh.bind(this)} />
                      </TableRowColumn>
                    </TableRow>
                  )}
                </Fragment>
              ))}
            </TableBody>
            <TableFooter>
              <div> System Information : MS-BE (PID: {this.state.pid}) Memory Usage: {(!!this.state.mem) ? JSON.stringify(this.state.mem) : null}
              </div>
            </TableFooter>
          </Table>
        </div>
      </Fragment>
    );
  }
}

const enhance = compose(connect(null, { showNotification: showNotificationAction }));

export default enhance(CqgStats);
