import {
  TOGGLE_NOTIFICATION,
  NC_COUNT_UPDATE,
  NC_EVENT,
} from './actions';
import {
  getCount,
  sound,
} from './helpers';

import {
  MESSAGE_NEW,
} from './../constants';

const defaultState = {
  notificationOpen: false,
  count: 0,
};

export default (previousState = defaultState, { type, payload }) => {
  switch (type) {
  case TOGGLE_NOTIFICATION:
    return {
      ...previousState,
      notificationOpen: !previousState.notificationOpen,
    };

  case NC_COUNT_UPDATE:
    return {
      ...previousState,
      count: payload || 0,
    };

  case NC_EVENT: {
    const { action, data } = payload;
    if (action === MESSAGE_NEW) sound(data);
    return {
      ...previousState,
      count: getCount(action, previousState, data),
    };
  }

  default:
    return previousState;
  }
};
