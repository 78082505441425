import {
  Datagrid,
  EditButton,
  List,
  TextField,
} from 'admin-on-rest';
import React from 'react';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';

const GSAccountsList = props => (
  <List {...props} perPage={10}>
    <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
      <TextField source="type"/>
      <TextField source="account"/>
      <TextField label="Alloc Account" source="allocAccount"/>
      <DateField formatDate={formatDateTimeInput} label="Updated" source="updatedAt" showTime/>
      <DateField formatDate={formatDateTimeInput} label="Created" source="createdAt" showTime/>
      <EditButton/>
    </Datagrid>
  </List>
);

export default GSAccountsList;
