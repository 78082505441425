/* eslint-disable */
import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  Datagrid,
  List,
  ListButton,
  RefreshButton,
  refreshView,
  TextField,
  TextInput,
  FunctionField,
  BooleanField,
  Filter,
  GET_ONE,
  translate,
} from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import Seo from '../components/common/Seo';
import { EditButton } from '../components';
import { datagridDefaultStyles } from '../theme/styles';
import { formatMarkups } from './PricingTemplatesActions';
import restClient from '../restClient';

const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="pricingTemplates.common.search" source="_q" alwaysOn />
    <TextInput label="Group" source="groupName" />
    <TextInput label="Underlying" source="underlyingName" />
  </Filter>
);

const ListActions = ({resource, filters, displayedFilters, filterValues, showFilter, refresh, template_id }) => {
  return (
    <CardActions className="CardActions">
      {filters &&
        React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' })}
      <ListButton basePath='/pricing_templates' />
      <RefreshButton refresh={refresh} />
    </CardActions>
  );
};

const PricingTemplateCardsList = props => {
  const [parentName, setParentName] = useState('');
  const [templateId, setTemplateId] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const arr = props.location.search.split('&');
    const filter = arr[0].split('=');
    const filterObj = JSON.parse(decodeURIComponent(filter[1]));
    const loadParent = async () => {
      const result = await restClient(GET_ONE, 'pricing_templates', { id: filterObj.template_id });
      setParentName(result.data.name);
      setTemplateId(filterObj.template_id);
      setLoading(false);
    };
    loadParent();
  }, []);

  if (loading) return null;
  return (
    <Fragment>
      <Seo title={parentName} />
      <List
        {...props}
        sort={{ field: 'templateName', order: 'DESC' }}
        filters={<ListFilters />}
        perPage={25}
        actions={<ListActions template_id={templateId}/>}
        title={parentName ? `CM Pricing Template Cards for ${parentName}` : ''}
      >
        <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
          <TextField source="templateName" label="Template" />
          <TextField source="underlyingName" label="Underlying" />
          <TextField source="groupName" label="Group" />
          <TextField source="cardName" label="Card" />
          <BooleanField source="useAbsMarkup" label="ABS Markup" />
          <FunctionField source="markups" render={r => formatMarkups(r.markups)} />
          <EditButton {...props} />
        </Datagrid>
      </List>
    </Fragment>
  );
};

const enhance = compose(
  translate,
  connect(null, {
    refreshView,
  })
);

export default enhance(PricingTemplateCardsList);
