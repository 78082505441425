import React from 'react';
import Avatar from 'material-ui/Avatar';
import PricingHistoryIcon from 'material-ui/svg-icons/action/history';
import FlatButton from 'material-ui/FlatButton';
import Moment from 'react-moment';
import ShowIcon from 'material-ui/svg-icons/action/visibility';
import NumericLabel from 'react-pretty-numbers';

import { translate } from 'admin-on-rest';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import { Table, TableHeader, TableHeaderColumn, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';

import TagButton from '../components/TagButton';

class CalculateHistory extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  render() {
    const { histories = [], count = 0 } = this.props;
    return (
      <Card
        style={{ marginBottom: '2em' }}
      >
        <CardHeader
          title={count}
          subtitle={this.translate('dashboard.calculateHistory.subtitle')}
          avatar={<Avatar backgroundColor="#4caf50" icon={<PricingHistoryIcon />} />}
          actAsExpander={true}
          showExpandableButton={true}
        />
        <CardText
          expandable={true}
          style={{ padding: '0' }}
        >
          <Table selectable={false}>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'right', whiteSpace: 'pre-wrap' }}>
                  {this.translate('dashboard.calculateHistory.table.time')}
                </TableHeaderColumn>
                <TableHeaderColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'right', whiteSpace: 'pre-wrap' }}>
                  {this.translate('dashboard.calculateHistory.table.user')}
                </TableHeaderColumn>
                <TableHeaderColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'right', whiteSpace: 'pre-wrap' }}>
                  {this.translate('dashboard.calculateHistory.table.structure')}
                </TableHeaderColumn>
                <TableHeaderColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'right', whiteSpace: 'pre-wrap' }}>
                  {this.translate('dashboard.calculateHistory.table.bloombergTicker')}
                </TableHeaderColumn>
                <TableHeaderColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'right', whiteSpace: 'pre-wrap' }}>
                  {this.translate('dashboard.calculateHistory.table.commodityContract')}
                </TableHeaderColumn>
                <TableHeaderColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'right', whiteSpace: 'pre-wrap' }}>
                  {this.translate('dashboard.calculateHistory.table.notional')}
                </TableHeaderColumn>
                <TableHeaderColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'right', whiteSpace: 'pre-wrap' }}>
                  {this.translate('dashboard.calculateHistory.table.quotedCurrency')}
                </TableHeaderColumn>
                <TableHeaderColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'right', whiteSpace: 'pre-wrap' }}>
                  {this.translate('dashboard.calculateHistory.table.type')}
                </TableHeaderColumn>
                <TableHeaderColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'right', whiteSpace: 'pre-wrap' }}>
                  {this.translate('dashboard.calculateHistory.table.referencePrice')}
                </TableHeaderColumn>
                <TableHeaderColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'right', whiteSpace: 'pre-wrap' }}>
                  {this.translate('dashboard.calculateHistory.table.price')}
                </TableHeaderColumn>
                <TableHeaderColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'right', whiteSpace: 'pre-wrap' }}>
                  {this.translate('dashboard.calculateHistory.table.strikeLevel')}
                </TableHeaderColumn>
                <TableHeaderColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'right', whiteSpace: 'pre-wrap' }}>
                  {this.translate('dashboard.calculateHistory.table.barrierlevel')}
                </TableHeaderColumn>
                <TableHeaderColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'right', whiteSpace: 'pre-wrap' }}>
                  {this.translate('dashboard.calculateHistory.table.tag')}
                </TableHeaderColumn>
                <TableHeaderColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'right', whiteSpace: 'pre-wrap' }}>
                </TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {histories.length ? histories.map(r => (
                <TableRow key={r.id}>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'right' }}>
                    <Moment format="DD/MM/YYYY">{r.created_at}</Moment>
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '8px', textAlign: 'right' }}>
                    <FlatButton
                      labelStyle={{ fontSize: '10px', textTransform: 'capitalize' }}
                      label={`${r.user_names}`}
                      href={`#/users/${r.user_id}/show`}
                    />
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'right' }}>
                    {r.structure}
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'right' }}>
                    {r.bloomberg_ticker}
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'right' }}>
                    {r.commodity_contract}
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'right' }}>
                    <NumericLabel params={{ precision: 2 }}>{r.notional}</NumericLabel>
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'right' }}>
                    {r.quoted_currency}
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'right' }}>
                    {r.type}
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'right' }}>
                    <NumericLabel params={{ precision: 2 }}>{r.reference_price}</NumericLabel>
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'right' }}>
                    <NumericLabel params={{ precision: 2 }}>{r.price}</NumericLabel>
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'right' }}>
                    <NumericLabel params={{ precision: 2 }}>{r.strike_level}</NumericLabel>
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'right' }}>
                    {r.barrier_level.length > 1 ? <NumericLabel params={{ precision: 2 }}>{r.barrier_level}</NumericLabel> : '-'}
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'right' }}>
                    <TagButton tag={r.tag} getPrimaryText={result => result.tag}/>
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '10px', textAlign: 'right' }}>
                    <FlatButton
                      icon={<ShowIcon />}
                      primary={true}
                      href={`#/calculate_history/${r.id}/show`}
                    />
                  </TableRowColumn>
                </TableRow>
              )) : <TableRow><TableRowColumn style={{ paddingLeft: '16px', fontSize: '14px' }}>{this.translate('dashboard.pricingHistory.empty')}</TableRowColumn></TableRow>}
            </TableBody>
          </Table>
        </CardText>
      </Card>
    );
  }
}

export default translate(CalculateHistory);
