import React from 'react';
import moment from 'moment';

import { NumberField, Show, SimpleShowLayout, TextField, translate } from 'admin-on-rest';
import { DateField } from '../helpers';

const AccumulatorDescriptionTitle = translate(({ record }) =>
  <span>{translate('accumulatorPricings.show.title', { smart_count: 1 })} { record.code }</span>);

const AccumulatorDescriptionShow = props => (
  <Show {...props}
    title={<AccumulatorDescriptionTitle />}
  >
    <SimpleShowLayout>
      <TextField
        source="product_category"
      />
      <TextField
        source="structure"
      />
      <TextField
        source="structure_code"
      />
      <TextField
        source="type"
      />
      <TextField
        source="product"
      />
      <TextField
        source="product_description"
      />
      <TextField
        source="barrier_type"
      />
      <TextField
        source="leverage_style"
      />
      <NumberField
        style={{ textAlign: 'left', display: 'flex' }}
        source="leverage"
      />
      <DateField
        formatDate={date => (date ? moment(date).format('DD MMMM YYYY HH:mm') : '')}
        source="updated_at"
        showTime
      />
    </SimpleShowLayout>
  </Show>
);

export default AccumulatorDescriptionShow;
