import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import { showNotification as showNotificationAction, refreshView } from 'admin-on-rest';
import compose from 'recompose/compose';
import FileDeleteIcon from 'material-ui/svg-icons/action/delete';

import path from '../path';

class RemoveFileButton extends Component {
    handleClick = () => {
      const { record, recordPath } = this.props;
      fetch(`${path}/${recordPath}/${record.id}`, { method: 'PUT',
        body: JSON.stringify(record),
        headers: {
          'Token': localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
        .then(() => {
          this.props.showNotification('files.notification.remove_success');
          this.props.refreshView();
        })
        .catch((e) => {
          console.error(e);
          this.props.refreshView();
          this.props.showNotification('files.notification.remove_error');
        });
    }

    render() {
      return <FlatButton
        label="Remove File"
        icon={<FileDeleteIcon/>}
        onClick={this.handleClick}
      />;
    }
}

RemoveFileButton.propTypes = {
  record: PropTypes.object,
  showNotification: PropTypes.func,
  refreshView: PropTypes.func,
  recordPath: PropTypes.string,
};

const enhance = compose(
  connect(null, {
    refreshView,
    showNotification: showNotificationAction,
  }),
);

export default enhance(RemoveFileButton);
