import React from 'react';
import FlatButton from 'material-ui/FlatButton';

import StatementHistoryDownloadIcon from './StatementHistoryDownloadIcon';
import { PresignedURLDownload } from '../helpers/PresignedURLDownload';

class StatementHistoryDownloadButton extends React.Component {
  getLink = id => (e) => {
    e.preventDefault();
    PresignedURLDownload('statement', id);
  }
  render() {
    const { statement_id } = this.props.record;

    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label="Download Statement"
          icon={<StatementHistoryDownloadIcon />}
          target="_blank"
          onClick={this.getLink(statement_id)}
          href=""
        />
      </div>
    );
  }
}

export default StatementHistoryDownloadButton;
