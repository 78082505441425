import React, { Component } from 'react';
import compose from 'recompose/compose';
import Dialog from 'material-ui/Dialog';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import { connect } from 'react-redux';
import { translate } from 'admin-on-rest';
import FlatButton from 'material-ui/FlatButton';
import SendIcon from 'material-ui/svg-icons/content/send';
import Checkbox from 'material-ui/Checkbox';
import CircularProgress from 'material-ui/CircularProgress';
import { OTCReleaseAction } from './OTCSendAction';

const styleLoader = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  backgroundColor: 'rgba(255,255,255, 0.8)',
  overflowX: 'hidden',
  transition: '0.5s',
};

class OTCReleaseButton extends Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;

    const {
      record: {
        status,
        envelope_id: isSentViaDocusign,
        email_subject: emailSubject,
        autosign,
      },
    } = props;

    const checked = Boolean((autosign && status === 'pending') ? true : isSentViaDocusign);

    this.state = {
      isOpenModal: false,
      load: false,
      isUploaded: !emailSubject,
      checked,
      status,
    };
  }

  static getDerivedStateFromProps({ record: { status, envelope_id: isSentViaDocusign } }, state) {
    if (status !== state.status) {
      const checked = Boolean(status === 'pending' ? true : isSentViaDocusign);
      return { status, checked };
    }

    return null;
  }

  handleCheckbox(checked) {
    this.setState({ checked: !checked });
  }

  toggleModal() {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal });
  }

  handleSubmit(id, { sendWithDocusign }, emailSubject) {
    this.setState({
      load: true,
      isOpenModal: false,
      checked: sendWithDocusign,
    });
    this.props.sendAction(id, sendWithDocusign, emailSubject);
  }

  render() {
    const {
      record: {
        id,
        signers,
        status,
      },
    } = this.props;
    const {
      checked,
      isUploaded,
      load,
      isOpenModal,
      emailSubject,
    } = this.state;

    const isPending = status === 'pending';
    const flatButtonColor = isPending ? '#00838F' : 'rgba(0, 0, 0, 0.3)';
    let signersEmail = [];
    if (isOpenModal) {
      signersEmail = signers.filter(n => n);
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexGrow: '1' }}>
        {load ? <div style={styleLoader}><CircularProgress size={80} thickness={5} style={{ right: '15%' }} /><p style={{ position: 'relative', color: 'rgb(0, 188, 212)', fontSize: '14px', right: '15%' }}></p></div> : ''}

        {!isUploaded && isPending &&
          <FlatButton
            icon={<SendIcon color={flatButtonColor} />}
            onClick={this.toggleModal.bind(this)}
            disabled={!isPending}
            style={{ minWidth: '20px', padding: '0 7px' }}
          />
        }

        {!isUploaded &&
          <Checkbox
            label="Send via Docusign"
            checked={checked}
            disabled={!isPending}
            onCheck={this.handleCheckbox.bind(this, checked)}
            color="primary"
            style={{ width: '170px', marginLeft: '5px' }}
          />
        }

        <Dialog
          title={`${this.translate('OTCConfirmations.release.title')}${id}`}
          modal={true}
          open={isOpenModal}
          autoScrollBodyContent={true}
        >
          {this.translate('OTCConfirmations.release.message')}{signersEmail.length > 1 ? 's' : ''}: <b>{signersEmail.join(', ')}</b>
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              label={this.translate('pdfs.dialog.btnCancel')}
              onClick={this.toggleModal.bind(this)}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label={this.translate('pdfs.dialog.btnSubmit')}
              primary={true}
              onClick={this.handleSubmit.bind(this, id, { sendWithDocusign: checked }, emailSubject)}
              icon={<SubmitIcon />}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

OTCReleaseButton.propTypes = {
  record: PropTypes.object,
  sendAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    sendAction: OTCReleaseAction,
  }),
);

export default enhance(OTCReleaseButton);
