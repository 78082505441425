import React from 'react';

import {
  SimpleForm,
  Create,
  TextInput,
  Toolbar,
  SaveButton,
  required,
} from 'admin-on-rest';

import { verify } from './helpers';

const ToolbarComponent = props => (
  <Toolbar {...props}>
    <SaveButton
      redirect="list"
      submitOnEnter={true}
    />
  </Toolbar>
);

const SftpAccountsCreate = props => (
  <Create {...props}>
    <SimpleForm
      toolbar={<ToolbarComponent />}
    >
      <TextInput
        source="host"
        validate={[required]}
      />
      <TextInput
        source="username"
        validate={[required]}
      />
      <TextInput
        source="password"
        type="password"
        validate={[required]}
      />
      <TextInput
        source="_verify"
        type="password"
        validate={[required, verify]}
      />
    </SimpleForm>
  </Create>
);

export default SftpAccountsCreate;
