import React from 'react';
import { Card, CardHeader } from 'material-ui/Card';

const style = {
  titleWraper: {
    'font-weight': '600',
    'display': 'grid',
    'grid-template-columns': '5fr 2fr 2fr',
  },
  textStyle: {
    display: 'block',
  },
  titleRight: {
    'text-align': 'right',
  },
};

const Title = () => (
  <div style={style.titleWraper}>
    <div>TYPE</div>
    <div>UPDATED BY</div>
    <div style={style.titleRight}>TIME</div>
  </div>
);

const LogHeader = () => (
  <Card >
    <CardHeader
      actAsExpander={false}
      showExpandableButton={false}
      textStyle={style.textStyle}
      title={<Title />}
    />
  </Card>
);

export default LogHeader;
