import React from 'react';
import {
  required,
  SimpleForm,
  TextInput,
  LongTextInput,
} from 'admin-on-rest';

import {
  validateMaxLengthName,
  validateMinLengthName,
} from '../../utils/validations';

const Form = ({ toolbar, ...rest }) => (
  <SimpleForm toolbar={toolbar} {...rest}>
    <TextInput
      label='theme_template.name'
      source='name'
      type='name'
      validate={[required, validateMinLengthName, validateMaxLengthName]}
      elStyle={{ width: '50%' }}
      options={{
        fullWidth: true,
        disabled: true,
      }}
    />
    <LongTextInput
      label='theme_template.themeTemplateDefinition'
      source='definition'
      type='themeTemplateDefinition'
      validate={[
        required,
        validateMinLengthName,
      ]}
      elStyle={{ width: '50%' }}
      options={{
        fullWidth: true,
      }}
    />
  </SimpleForm>
);

export default Form;
