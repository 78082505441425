import { compose } from 'redux';
import {
  refreshView as refreshViewAction,
  showNotification as showNotificationAction,
  translate as adminTranslate,
} from 'admin-on-rest';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import request from 'superagent';
import path from '../../path';
import FlatButton from 'material-ui/FlatButton';
import PreviewIcon from 'material-ui/svg-icons/image/view-compact';
import Dialog from 'material-ui/Dialog';
import { List, ListItem, Toggle } from 'material-ui';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import { StructureDataRecord, WrapperDataRecord } from '../types';
import { SpCommonDispatchProps } from './types';
import { ComponentEnhancer } from 'recompose';

type Record = StructureDataRecord | WrapperDataRecord;

interface ReviewButtonOwnProps {
  record: Record;
  apiSource: string;
  fieldName: string;
  floatingLabelText?: string;
}

interface ErrorMessageProps {
  errors: Record['errors'];
  rules: Record['rules'];
  showOnePagerWarning: boolean;
  fieldValidationRules: Record['fieldValidationRules'];
  fieldName: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  errors = [],
  rules,
  showOnePagerWarning,
  fieldValidationRules,
  fieldName,
}) => {
  return (
    <div className="review-errors">
      {errors.length ? (
        <>
          <span>The following components in layouts cannot be found. Please check inputs and layouts config.</span>
          <List style={{ columnCount: 1, paddingBottom: '20px' }}>
            {errors.map((component, index) => (
              <ListItem style={{ color: 'red' }} key={index}>
                {component}
              </ListItem>
            ))}
          </List>
        </>
      ) : (
        <div style={{ paddingBottom: '20px' }}>
          <span>Initial checks validated! Please test within Agile "Preview SP" tab</span>
        </div>
      )}
      {!rules && <div style={{ color: 'orange' }}>Warning: no rules config has been uploaded</div>}
      {showOnePagerWarning && <div style={{ color: 'orange' }}>Warning: no One Pager config has been uploaded</div>}
      {!fieldValidationRules && <div style={{ color: 'red' }}>Error: no Validation Rules config has been uploaded</div>}
      {!fieldName && <div style={{ color: 'red' }}>{`Error: no Product repo ${fieldName} has been mapped`}</div>}
    </div>
  );
};

type ReviewButtonProps = ReviewButtonOwnProps & SpCommonDispatchProps;

const ReviewButton: React.FC<ReviewButtonProps> = ({
  record,
  translate,
  showNotification,
  refreshView,
  apiSource,
  fieldName,
}) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const translationsPrefix = 'configurableSp.review';
  const { id, visible, rules, errors = [], fieldValidationRules } = record;

  const [visibleToggle, setVisibleToggle] = useState(visible);

  const handleOpen = async () => {
    setOpen(true);
    setVisibleToggle(visible);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setSubmitting(true);

    request
      .put(`${path}/admin/${apiSource}/${id}`)
      .set('Token', localStorage.getItem('session'))
      .field({ visible: visibleToggle })
      .then(() => {
        showNotification(`${translationsPrefix}.successful`);
        refreshView();
      })
      .catch((e) => {
        console.error(e);
        showNotification(translate(`${translationsPrefix}.error.${e.response.body.message || 'generic'}`, 'warning'));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onToggle = (event, value) => {
    setVisibleToggle(value);
  };

  return (
    <div className="sp-review-dialog">
      <FlatButton label={translate(`${translationsPrefix}.buttonText`)} icon={<PreviewIcon />} onClick={handleOpen} />
      <Dialog
        title={translate(`${translationsPrefix}.title`)}
        modal={false}
        open={open}
        repositionOnUpdate={false}
        style={{ paddingTop: '50px' }}
        bodyStyle={{ overflowY: 'auto' }}
      >
        <ErrorMessage
          errors={errors}
          rules={rules}
          showOnePagerWarning={'onePager' in record && !record.onePager}
          fieldValidationRules={fieldValidationRules}
          fieldName={fieldName}
        />
        <Toggle
          style={{ paddingTop: '20px' }}
          defaultToggled={!!visibleToggle}
          label="Visible"
          labelPosition="right"
          disabled={!!errors.length}
          onToggle={onToggle}
        />
        <div style={{ paddingTop: '15px', float: 'right' }}>
          <RaisedButton
            label={translate('configurableSp.dialog.btnClose')}
            onClick={closeDialog}
            icon={<CancelIcon />}
          />
          <RaisedButton
            type="submit"
            style={{ marginLeft: '10px' }}
            label={translate('configurableSp.dialog.btnSave')}
            primary={true}
            icon={<SubmitIcon />}
            disabled={submitting || !!errors.length || !fieldValidationRules || !record[fieldName]}
            onClick={handleSave}
          />
        </div>
      </Dialog>
    </div>
  );
};

const enhance: ComponentEnhancer<ReviewButtonProps, ReviewButtonOwnProps> = compose(
  adminTranslate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(ReviewButton);
