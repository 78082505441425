import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { showNotification } from 'admin-on-rest';

import { VOLATILITY_SURFACE_DELETE_SUCCESS, VOLATILITY_SURFACE_DELETE_FAILURE } from './VolatilitySurfaceAction';

export default function* reviewSaga() {
  yield [
    takeEvery(VOLATILITY_SURFACE_DELETE_SUCCESS, function* () {
      yield put(showNotification('volatilitySurface.notification.delete_success'));
      yield put(push('/'));
      yield put(push('/volatility_surface'));
    }),
    takeEvery(VOLATILITY_SURFACE_DELETE_FAILURE, function* ({ error }) {
      yield put(showNotification('volatilitySurface.notification.delete_error', 'warning'));
      console.error(error);
    }),
  ];
}
