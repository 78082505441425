import React from 'react';
import ErrorsIcon from 'material-ui/svg-icons/alert/warning';

const style = {
  error: {
    color: 'red',
    marginLeft: '15px',
    verticalAlign: 'middle',
  },
};

export const getOptionText = ({
  saleName,
  id,
  firstName,
  lastName,
}) => `${saleName} - ${id} (${firstName} ${lastName})`;

export const SaleLabel = (record) => {
  const { userId, saleName, id } = record;
  if (!userId) {
    return (
      <div>
        <ErrorsIcon style={style.error}/>
        {saleName} - {id} (Sale has not Agile account)
      </div>
    );
  }
  return (
    <div>
      {getOptionText(record)}
    </div>
  );
};
