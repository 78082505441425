import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import { translate } from 'admin-on-rest';

import PDFDownloadIcon from './PDFDownloadIcon';
import { PresignedURLDownload } from '../helpers/PresignedURLDownload';

class CSVDownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.translate;
  }

  getLink = id => (e) => {
    e.preventDefault();
    PresignedURLDownload('statement_csv', id);
  }

  render() {
    const { file_csv_exists: csv, id } = this.props.record;
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label="CSV"
          icon={<PDFDownloadIcon />}
          disabled={!csv}
          target="_blank"
          onClick={this.getLink(id)}
          href=""
        />
      </div>
    );
  }
}

CSVDownloadButton.propTypes = {
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
);

export default enhance(CSVDownloadButton);
