import { List, RefreshButton, refreshView as refreshViewAction, RichTextField, showNotification as showNotificationAction, translate } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import request from 'superagent';
import { Datagrid, DateField } from '../helpers';
import DownloadButton from '../helpers/DownloadButton';
import path from '../path';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';
import UploadButton from './UploadButton';

const Actions = ({
  onDropHandler }) => (
  <CardActions>
    <RefreshButton />
    <UploadButton onDrop ={onDropHandler}/>
  </CardActions>
);

class UploadSSIList extends React.Component {
  constructor(props) {
    super(props);
    this.ids = [];
    this.state = {
      file: null,
      errors: { file: false },
    };
  }

  onDrop = (file) => {
    const { showNotification, refreshView } = this.props;

    request
      .post(`${path}/admin/ssi/upload`)
      .set('token', localStorage.getItem('session'))
      .field('file', file)
      .then(() => {
        showNotification('clientDatabase.edit.successful');
        refreshView();
      })
      .catch((e) => {
        console.error(e);
        showNotification('Error', 'warning');
      });
  }

  render() {
    return (
      <List {...this.props}
        sort={{ field: 'created_at', order: 'DESC' }}
        title="SSI"
        perPage={10}
        actions={<Actions onDropHandler= {this.onDrop} />}
      >
        <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
          <RichTextField source="name" label="File name" />
          <DownloadButton {...this.props} source="link" label="download" />
          <DateField
            formatDate={formatDateTimeInput}
            source="created_at"
            showTime
          />
        </Datagrid>
      </List>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(UploadSSIList);

