import React from 'react';
import { DateInput, Filter, TextInput } from 'admin-on-rest';
import { formatDateInput } from '../../utils/dates';

const ListFilters = props => (
  <Filter {...props}>
    <TextInput
      label="Pricing Id"
      source="id"
    />
    <TextInput
      source="structure"
    />
    <TextInput
      source="issuer"
    />
    <TextInput
      source="currency"
    />
    <TextInput
      source="maturity"
    />
    <TextInput
      label="Autocall Frequency"
      source="frequency"
    />
    <TextInput
      label="First Observation In"
      source="first_observation_in"
    />
    <TextInput
      label="Autocall Trigger"
      source="autocall"
    />
    <TextInput
      label="Level Autocall Trigger"
      source="autocall_trigger_level"
    />
    <TextInput
      label="Level Step Down"
      source="step_down"
    />
    <TextInput
      source="step"
    />
    <TextInput
      source="coupon_type"
    />
    <TextInput
      source="memory_coupon"
    />
    <TextInput
      source="coupon_trigger_level"
    />
    <TextInput
      source="solve_for"
    />
    <TextInput
      source="coupon_level"
    />
    <TextInput
      source="strike_level"
    />
    <TextInput
      source="downside_leverage"
    />
    <TextInput
      source="barrier_type"
    />
    <TextInput
      source="barrier_level"
    />
    <TextInput
      source="reoffer"
    />
    <TextInput
      source="basket_type"
    />
    <TextInput
      source="capital_protection_level"
    />
    <TextInput
      source="option_type"
    />
    <TextInput
      source="participation_level"
    />
    <TextInput
      source="cap_type"
    />
    <TextInput
      source="cap_level"
    />
    <TextInput
      source="rebate_level"
    />
    <TextInput
      label="Rebate Level 2"
      source="rebate_level_2"
    />
    <TextInput
      label="Option Type 2"
      source="option_type_2"
    />
    <TextInput
      label="Strike Level 2"
      source="strike_level_2"
    />
    <TextInput
      label="Participation Level 2"
      source="participation_level_2"
    />
    <TextInput
      label="Cap Type 2"
      source="cap_type_2"
    />
    <TextInput
      label="Cap Level 2"
      source="cap_level_2"
    />
    <TextInput
      label="Barrier Type 2"
      source="barrier_type_2"
    />
    <TextInput
      label="Barrier Level 2"
      source="barrier_level_2"
    />
    <TextInput
      label="Pricing Tag"
      source="tag"
    />
    <TextInput
      label="Underlying"
      source="underlyings"
    />
    <TextInput
      label="User Name"
      source="user"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
    />
  </Filter>
);

export default ListFilters;
