import React, { Fragment, useEffect } from 'react';
import { required, number } from 'admin-on-rest';
import { formValueSelector, change, FieldArray, arrayPush } from 'redux-form';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Table, TableBody, TableRow, TableRowColumn, TableHeader, TableHeaderColumn } from 'material-ui/Table';
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar';
import DeleteIcon from 'material-ui/svg-icons/content/remove-circle';
import AddIcon from 'material-ui/svg-icons/content/add-circle';
import RaisedButton from 'material-ui/RaisedButton';
import {
  RECORD_FORM,
  DIRECTION,
  INPUT_PROPS,
  TableInput,
  businessDaysValidation,
  round,
  FIELD_BUSINESS_DAYS,
  TRIALS_FIELD_ARRAY,
  TRIALS_TYPES,
  inputFormatterNumber,
  parseInput,
} from './helpers';

const renderTrials = (props) => {
  const { fields } = props;
  const removeHandler = i => fields.remove(i);
  return (
    <Fragment>
      {fields.map((item, index) => {
        const isLast = index === fields.length - 1;
        return (
          <TableRow key={index} style={{ backgroundColor: isLast ? '#f9f9f9' : 'none' }}>
            <TableInput
              source={`${item}.direction`}
              rowStyle={{ width: '50px' }}
              disabled={true}
              type="text"
              inputOptions={{
                ...INPUT_PROPS,
              }}
            />
            <TableInput
              placeholder="Business Days"
              source={`${item}.${FIELD_BUSINESS_DAYS}`}
              validate={[required, number, businessDaysValidation]}
              disabled={isLast}
              type="number"
              normalize={round}
              inputOptions={{ min: 0 }}
            />
            <TableInput
              placeholder="Paths"
              source={`${item}.paths`}
              format={inputFormatterNumber}
              parse={parseInput}
              validate={[required, number]}
              type="string"
            />
            <TableRowColumn>
              {!isLast ? (
                <RaisedButton
                  label="Remove Trial"
                  secondary={true}
                  icon={<DeleteIcon />}
                  onClick={() => removeHandler(index)}
                />
              ) : null}
            </TableRowColumn>
          </TableRow>
        );
      })}
    </Fragment>
  );
};

const TrialsControl = props => {
  const { arrayPushAction, record } = props;
  const addFields = (type, value) => arrayPushAction(RECORD_FORM, `${TRIALS_FIELD_ARRAY}.${type}`, value);

  useEffect(() => {
    // eslint-disable-next-line
    for (const {key, val} of TRIALS_TYPES) {
      if (record && record.trials && record.trials[key] && record.trials[key].length) return;
      addFields(key, {
        direction: DIRECTION.GREATER,
        businessDays: 0,
        paths: 0,
      });
    }
  }, []);

  const addElement = (type) => {
    const lastIdx = props.trials[type].length - 1;
    const last = props.trials[type][lastIdx];
    addFields(type, {
      paths: last && last.paths,
      businessDays: last && last.businessDays,
      direction: DIRECTION.GREATER,
    });
    props.change(RECORD_FORM, `${TRIALS_FIELD_ARRAY}.${type}[${lastIdx}]`, { direction: DIRECTION.LESS_OR_EQUAL });
  };

  return (
    <Fragment>
      {TRIALS_TYPES.map((item) => (
        <Fragment key={item.key}>
          <Toolbar>
            <ToolbarGroup>
              <ToolbarTitle text={item.value} />
            </ToolbarGroup>
            <ToolbarGroup>
              <ToolbarSeparator />
              <RaisedButton label="Add Trial" secondary={true} icon={<AddIcon />} onClick={() => addElement(item.key)} />
            </ToolbarGroup>
          </Toolbar>
          <Table selectable={false}>
            <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
              <TableRow>
                <TableHeaderColumn style={{ width: '50px' }}>
                  <b>Direction</b>
                </TableHeaderColumn>
                <TableHeaderColumn>
                  <b>Business Days</b>
                </TableHeaderColumn>
                <TableHeaderColumn>
                  <b>Paths</b>
                </TableHeaderColumn>
                <TableHeaderColumn></TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              <FieldArray name={`${TRIALS_FIELD_ARRAY}.${item.key}`} component={renderTrials} />
            </TableBody>
          </Table>
        </Fragment>
      ))}
    </Fragment>
  );
};

const selector = formValueSelector(RECORD_FORM);
const enhance = compose(
  connect((state, { source }) => {
    const obj = {
      trials: selector(state, TRIALS_FIELD_ARRAY),
      data: selector(state, source),
    };

    return obj;
  }, { change, arrayPushAction: arrayPush }),
);
export default enhance(TrialsControl);
