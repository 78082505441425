import {
  Datagrid,
  DateInput,
  Filter,
  List,
  Responsive,
  SelectField,
  SelectInput,
  BooleanInput,
  SimpleList,
  TextField,
  BooleanField,
  TextInput,
} from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import EditButton from '../helpers/EditButton';
import MultiplyChips from '../helpers/MultiplyChips';
import ShowButton from '../helpers/ShowButton';
import { formatDateInput, formatDateTimeInput } from '../utils/dates';
import { Structure, TradeBtn } from './components';
import UserApproveButton from './UserApproveButton';
import UserRejectButton from './UserRejectButton';
import { withPendingStatusesSource, filterStatusesSource } from './helpers';
import HistoryButton from 'components/HistoryButton';

const ButtonsWrapper = props => (
  <div style={{ width: '340px', overflow: 'hidden' }} >
    <HistoryButton id={props.record.id} name={props.record.name} url='users_history' style={{ float: 'left' }} title={'History'} />
    <UserApproveButton {...props} hideLabel={true} />
    <UserRejectButton {...props} hideLabel={true} />
    <ShowButton {...props} />
    <EditButton {...props} />
  </div>
);


const Group = ({ record: { groupName, groupCode } }) => <span style={{ whiteSpace: 'nowrap' }}>{[groupName, groupCode].join(' ')}</span>;

const UserFilter = props => (
  <Filter {...props}>
    <TextInput
      label="users.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="email"
    />
    <TextInput
      label="First Name"
      source="firstName"
    />
    <TextInput
      label="Last Name"
      source="lastName"
    />
    <SelectInput
      source="status"
      choices={filterStatusesSource}
    />
    <BooleanInput
      source="greeks"
      label="Greeks Display"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updatedAt"
      label="Updated At"
      showTime
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="createdAt"
      label="Created At"
      showTime
    />
  </Filter>
);

const UserList = props => (
  <List {...props}
    filters={<UserFilter />}
    sort={{ field: 'created_at', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => r.name}
          secondaryText={r => r.email}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
        />
      }
      medium={
        <Datagrid
          bodyOptions={{ showRowHover: true }}
          options={{
            height: '70vh',
            className: 'sticky-header sticky-users',
          }}
        >
          {localStorage.getItem('buttonsOnLeft') ? <ButtonsWrapper/> : null}
          <TextField
            source="email"
          />
          <TextField
            source="id"
          />
          <TextField
            label="Slack Id"
            source="slackId"
          />
          <TextField
            label="Volatility Rule"
            source="ruleName"
          />
          <TextField
            source="name"
          />
          <Group source="group" />
          <TextField
            source="spPricingTemplate"
            label="Sp Pricing Template"
          />
          <SelectField
            source="status"
            choices={withPendingStatusesSource}
          />
          <BooleanField
            source="additionalTerms"
            label="Additional Terms"
          />
          <MultiplyChips label="Dashboards" source="dashboards" sortable={false} />
          <MultiplyChips label="Roles" source="roles" sortable={false} />
          <TextField
            source="spreadsheet"
          />
          <MultiplyChips label="Trading Team" source="tradeTeams" sortable={false} />
          <TextField
            source="slackTeam"
            label="Slack Team"
          />
          <MultiplyChips label="Dashboard Groups" source="groupsEmails" sortable={false} />
          <BooleanField
            source="greeks"
            label="Greeks Display"
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="createdAt"
            label="Created At"
            showTime
          />
          <TradeBtn label="Trade Button" />
          <Structure source="swap" />
          <Structure source="vanilla" className="stick" />
          <Structure label="Forwards / NDFs" source="fx" />
          <Structure label="Asian Swap" source="asianSwap" />
          {localStorage.getItem('buttonsOnLeft') ? null : <ButtonsWrapper/>}
        </Datagrid>
      }
    />
  </List>
);

export default UserList;
