import React from 'react';
import { SimpleForm, Create, TextInput, required, minLength, NumberInput } from 'admin-on-rest';
import VolatilityBucketCreateToolbar from './VolatilityBucketCreateToolbar';

const validateMinLengthToTwo = minLength(2);
const VolatilityBucketCreate = props => (
  <Create {...props}>
    <SimpleForm
      toolbar={<VolatilityBucketCreateToolbar />}
    >
      <TextInput
        source="name"
        validate={[required, validateMinLengthToTwo]}
      />
      <NumberInput
        source="min_shift_buy"
        validate={[required]}
      />
      <NumberInput
        source="incremental_shift_buy"
        validate={[required]}
      />
      <NumberInput
        source="min_shift_sell"
        validate={[required]}
      />
      <NumberInput
        source="incremental_shift_sell"
        validate={[required]}
      />
    </SimpleForm>
  </Create>
);

export default VolatilityBucketCreate;
