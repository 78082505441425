import React, { useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  List,
} from 'admin-on-rest';
import {
  Card,
  CardActions,
  CardHeader,
  CardText,
} from 'material-ui/Card';
import Chip from 'material-ui/Chip';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import { refrashTokenAction } from './actions';
import { match, styles } from './constants';


const LableChips = ({ label = [] }) => (
  <div style={styles.chipWrapper}>
    { label.map(id => <Chip key={id} style={styles.chip}>{id}</Chip>) }
  </div>
);

const Status = ({ isToken, scope }) => (
  <div style={{ margin: '4px' }}>
    <div style={styles.b}>{isToken ? 'Connected' : 'Disconected'}.</div>
    { scope ? <div>
      <span style={styles.b}>Scope:</span> <span>{scope}</span>
    </div> : null}
  </div>
);

const AuthGrid = ({ ids, data, refrashToken }) => {
  const [disabled, setDisabled] = useState(false);
  const handleRefreshToken = ({ label = [] }) => () => {
    setDisabled(true);
    setTimeout(() => setDisabled(false), 5000);
    if (label[0]) refrashToken(label[0]);
  };

  return (
    <div style={{ margin: '1em' }}>
      {ids.map(id =>
        <Card key={id} style={styles.cardStyle}>
          <CardHeader
            title={<LableChips {...data[id]} />}
            style={styles.cardHeader}
            subtitle={<Status {...data[id]}/>}
          />
          <CardText>
            <TextField defaultValue={data[id].audience} floatingLabelText="Audience" disabled={true} style={styles.cardField}/>
            <TextField defaultValue={data[id].domain} floatingLabelText="Domain" disabled={true} style={styles.cardField}/>
            <TextField defaultValue={data[id].username} floatingLabelText="Username" disabled={true} style={styles.cardField}/>
            <TextField defaultValue={data[id].password} floatingLabelText="Password" disabled={true} style={styles.cardField}/>
            <TextField defaultValue={data[id].clientId} floatingLabelText="ClientId" disabled={true} style={styles.cardField}/>
            <TextField defaultValue={data[id].clientSecret} floatingLabelText="clientSecret" disabled={true} style={styles.cardField}/>
          </CardText>
          <CardActions style={{ textAlign: 'right' }}>
            { data[id].isToken ? <FlatButton label="Refresh" primary={true} onClick={handleRefreshToken(data[id])} disabled={disabled}/> : null}
          </CardActions>
        </Card>,
      )}
    </div>
  );
};

const AuthClient = ({ refrashToken, ...props }) => (
  <List
    actions={<CardActions></CardActions>}
    {...props}
    match={match}
  >
    <AuthGrid refrashToken={refrashToken}/>
  </List>
);


export default compose(
  connect(null, {
    refrashToken: refrashTokenAction,
  }),
)(AuthClient);
