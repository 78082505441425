import { DateInput, Filter, TextInput } from 'admin-on-rest';
import React from 'react';
import { formatDateInput } from '../../utils/dates';

interface ConfigurableSPFiltersProps {
  context?: string;
  displayedFilters?: { [key: string]: boolean };
  filterValues?: { [key: string]: string | number };
  hideFilter?: (filterName: string) => void;
  resource?: string;
  setFilters?: any;
}

const ConfigurableSPFilters: React.FC<ConfigurableSPFiltersProps> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label='Pricing Id' source='id' />
      <TextInput source='structure' />
      <TextInput source='format' />
      <TextInput source='currency' />
      <TextInput source='tenor' />
      <TextInput source='reoffer' />
      <TextInput label='Pricing Tag' source='tag' />
      <TextInput label='Underlying' source='underlyings' />
      <TextInput label='Notional' source='notional' />
      <TextInput label='User Name' source='user' />
      <DateInput
        options={{
          formatDate: formatDateInput,
        }}
        source='created_at'
        showTime
      />
    </Filter>
  );
};

export default ConfigurableSPFilters;
