const style = {
  wrapper: {
    boxSizing: 'border-box',
    display: 'block',
    height: 'inherit',
    margin: '0 auto',
    minWidth: '550px',
    position: 'relative',
    width: 'calc(96%)',
    maxWidth: '680px',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    position: 'relative',
  },
  headerh2: {
    color: 'rgb(32, 33, 36)',
    fontSize: '109%',
    fontWeight: '400',
    letterSpacing: '0.25px',
    marginBottom: '12px',
    marginTop: '21px',
    outline: 'none',
    paddingBottom: '4px',
    paddingTop: '8px',
  },
  card: {
    borderRadius: '8px',
    boxShadow: 'rgba(60, 64, 67, .3) 0 1px 2px 0, rgba(60, 64, 67, .15) 0 2px 6px 2px',
    flex: 1,
  },
  hr: {
    borderTop: '1px solid rgba(0, 0, 0, 0.06)',
  },
  pointer: {
    cursor: 'pointer',
  },
  SettingsRowWrapp: {
    padding: ' 0 20px',
    alignItems: 'center',
    display: 'flex',
    minHeight: '65px',
  },
  infoSection: {
    flex: 1,
    flexBasis: '1e-9px',
    padding: '12px 0',
    minWidth: 0,
    marginInlineEnd: '20px',
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '154%',
    fontSize: '95%',
    textAlign: 'start',
    fontWeight: 500,
  },
  secondaryLabel: {
    whiteSpace: 'nowrap',
    color: 'rgb(95, 99, 104)',
    fontWeight: 400,
    lineHeight: '154%',
    fontSize: '85%',
    textAlign: 'start',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  select: {
    width: '200px',
    height: '32px',
    borderRadius: '4px',
    backgroundColor: 'rgb(241, 243, 244)',
    border: 'none',
    outline: 'none',
  },
  selectOverride: {
    underlineStyle: { display: 'none' },
    iconStyle: {
      width: '32px',
      height: '32px',
      padding: 0,
      fill: 'rgb(144 144 144)',
    },
    labelStyle: {
      height: '32px',
      lineHeight: '32px',
      top: 0,
      paddingLeft: '8px',
    },
  },
};

module.exports = {
  style,
};
