export default `
  .page-number {
    min-width: 40px !important;
  }

  table tr th:first-child {
    color: #fff;
    visibility: hidden;
  }

  .custom-text-cell {
    display: flex;
    align-items: center;
  }

`;
