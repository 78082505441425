import React, { Component } from 'react';
import Checkbox from 'material-ui/Checkbox';

export default class ColumnCheckbox extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.status !== this.props.status;
  }

  render() {
    const { value, status, label, disabled, handleCheck } = this.props;
    return <Checkbox
      value={value}
      checked={status}
      label={label}
      disabled={disabled}
      onCheck={handleCheck} />;
  }
}
