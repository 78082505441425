import React from 'react';
import FlatButton from 'material-ui/FlatButton';

import PDFHistoryDownloadIcon from './PDFHistoryDownloadIcon';
import { PresignedURLDownload } from '../helpers/PresignedURLDownload';

class PDFHistoryDownloadButton extends React.Component {
  getLink = id => (e) => {
    e.preventDefault();
    PresignedURLDownload('dashboard', id);
  }
  render() {
    const { pdf_id } = this.props.record;

    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label="Download PDF"
          icon={<PDFHistoryDownloadIcon />}
          target="_blank"
          onClick={this.getLink(pdf_id)}
          href=""
        />
      </div>
    );
  }
}

export default PDFHistoryDownloadButton;
