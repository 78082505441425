import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

const Axis = ({ scale, direction, xPos }) => {
  const ref = React.useRef(null);
  const axis = useMemo(() => (
    d3[direction === 'left' ? 'axisLeft' : 'axisRight']()
      .scale(scale)
      .ticks(4)
  ), [scale, direction]);


  useEffect(() => {
    d3.select(ref.current)
      .transition()
      .duration(250)
      .ease(d3.easeLinear)
      .call(axis);
  }, []);
  return (<g ref={ref} transform={`translate(${xPos}, 0)`}></g>);
};

Axis.PropTypes = {
  scale: PropTypes.func.isRequired,
  direction: PropTypes.string.isRequired,
  xPos: PropTypes.number.isRequired,
};

Axis.defaultProps = {
  scale: null,
  direction: 'left',
  xPos: 0,
};

export default Axis;
