
import { CREATE } from 'admin-on-rest';

import { STATEMENT_GROUP_GENERATE } from './PDFAction';

const PDFGroupGenerateAction = (data, basePath) => ({
  type: STATEMENT_GROUP_GENERATE,
  payload: { data, basePath },
  meta: { resource: 'statements/pull_all', fetch: CREATE, cancelPrevious: false },
});

export default PDFGroupGenerateAction;
