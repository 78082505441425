import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

const TextField = ({ value, elStyle }) => <span style={elStyle}>{value}</span>;

TextField.propTypes = {
  addLabel: PropTypes.bool,
  elStyle: PropTypes.object,
  label: PropTypes.string,
};

const PureTextField = pure(TextField);

PureTextField.defaultProps = {
  addLabel: true,
};

export default PureTextField;
