import { UPDATE } from 'admin-on-rest';

export const COMMODITY_CONTRACT_REPLY = 'COMMODITY_CONTRACT_REPLY';
export const COMMODITY_CONTRACT_REPLY_LOADING = 'COMMODITY_CONTRACT_REPLY_LOADING';
export const COMMODITY_CONTRACT_REPLY_FAILURE = 'COMMODITY_CONTRACT_REPLY_FAILURE';
export const COMMODITY_CONTRACT_ACTIVE = 'COMMODITY_CONTRACT_ACTIVE';


export const VANILLA_CONTRACT_REPLY = 'VANILLA_CONTRACT_REPLY';
export const VANILLA_CONTRACT_REPLY_LOADING = 'VANILLA_CONTRACT_REPLY_LOADING';
export const VANILLA_CONTRACT_REPLY_FAILURE = 'VANILLA_CONTRACT_REPLY_FAILURE';
export const VANILLA_CONTRACT_ACTIVE = 'VANILLA_CONTRACT_ACTIVE';


export const CommodityContractsActive = (id, active) => ({
  type: COMMODITY_CONTRACT_ACTIVE,
  payload: { id: `${id}/${active}` },
  meta: { resource: 'swap_commodities', fetch: UPDATE, cancelPrevious: false },
});

export const VanillaContractsActive = (id, active) => ({
  type: VANILLA_CONTRACT_ACTIVE,
  payload: { id: `${id}/${active}` },
  meta: { resource: 'vanilla_commodities', fetch: UPDATE, cancelPrevious: false },
});
