import React from 'react';
import Moment from 'react-moment';

import { List, Responsive, SimpleList, Datagrid, TextField, DeleteButton, NumberField, EditButton } from 'admin-on-rest';

import VolatilityBucketFilter from './VolatilityBucketFilter';

const VolatilityBucketList = props => (
  <List {...props}
    title="Volatility Bucket"
    filters={<VolatilityBucketFilter />}
    sort={{ field: 'created_at', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => r.id}
          econdaryText={r => r.name}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.updated_at}</Moment>}
        />
      }
      medium={
        <Datagrid>
          <TextField
            source="id"
          />
          <TextField
            source="name"
          />
          <NumberField
            source="min_shift_buy"
          />
          <NumberField
            source="incremental_shift_buy"
          />
          <NumberField
            source="min_shift_sell"
          />
          <NumberField
            source="incremental_shift_sell"
          />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      }
    />
  </List>
);

export default VolatilityBucketList;
