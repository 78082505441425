const slectInputOptions = {
  'listStyle': { 'maxHeight': '30vh', 'overflow': 'auto' },
  'onBlur': () => {},
};

const statusesSource = [
  { id: 2, name: 'Approved' },
  { id: 3, name: 'Rejected' },
  { id: 6, name: 'Unlocked' },
  { id: 7, name: 'Locked' },
];

const withPendingStatusesSource = [
  { id: 5, name: 'Pending' },
  { id: 1, name: 'Pending' },
  ...statusesSource,
];

const filterStatusesSource = withPendingStatusesSource
  .reduce((acc, i) => {
    if (i.id !== 1) acc.push(i);
    return acc;
  }, []);

const createUserStatusesSource = withPendingStatusesSource
  .reduce((acc, i) => {
    if (i.id >= 1 && i.id <= 3) acc.push(i);
    return acc;
  }, []);

export {
  slectInputOptions,
  statusesSource,
  withPendingStatusesSource,
  createUserStatusesSource,
  filterStatusesSource,
};
