import React from 'react';
import Moment from 'react-moment';
import { ListItem } from 'material-ui/List';
import DownloadIcon from 'material-ui/svg-icons/action/get-app';

const AgileXML = (props) => {
  const { record } = props;
  const fileName = record.type.replace(record.type[0], record.type[0].toUpperCase());
  return (
    <ListItem
      key={record.id}
      initiallyOpen={false}
      nestedItems={[
        <ListItem key={`${record.id}_xml`} style={{ backgroundColor: 'rgb(39, 40, 34)', color: 'rgb(249, 248, 245)' }}>
          <pre>
            {record.xml}
          </pre>
        </ListItem>,
      ]}
    >
      <a href={`data:text/xml;charset=utf-8,${record.xml}`} download={`${fileName}_file.xml`}>
        <div style={{ float: 'left', marginRight: '20px' }}>
          <DownloadIcon color="#006064" style={{ width: '28px', height: '28px', margin: '-5px 0 0' }} />
        </div>
      </a>
      <div>
        <Moment format="HH:mm DD MMMM YYYY">{record.created_at}</Moment>
      </div>
    </ListItem>
  );
};

export default AgileXML;
