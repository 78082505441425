import { Datagrid, List, Responsive, ShowButton, SimpleList, TextField } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';
import StatementHistoryDownloadButton from './StatementHistoryDownloadButton';
import StatementHistoryDownloadCSVButton from './StatementHistoryDownloadCSVButton';
import StatementHistoryFilter from './StatementHistoryFilter';

const StatementHistoryList = props => (
  <List {...props}
    title="Statement History"
    filters={<StatementHistoryFilter />}
    sort={{ field: 'created_at', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => r.id}
          secondaryText={r => r.email}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.create_at}</Moment>}
        />
      }
      medium={
        <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
          <TextField
            source="id"
          />
          <TextField
            source="client_name"
          />
          <TextField
            source="client_ref_number"
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="created_at"
            showTime
          />
          <StatementHistoryDownloadButton />
          <StatementHistoryDownloadCSVButton />
          <ShowButton />
        </Datagrid>
      }
    />
  </List>
);

export default StatementHistoryList;
