import React from 'react';
import { TableRow, TableRowColumn } from 'material-ui/Table';
import { MONTHLY_HEADINGS } from '../constants';

const HeadingsRow = ({ tenorList = [] }) => (
  <TableRow>
    <TableRowColumn></TableRowColumn>
    {
      tenorList.map(tenor => {
        const heading = MONTHLY_HEADINGS[tenor] || `Year ${tenor}`;
        return (<TableRowColumn key={tenor}><b>{heading}</b></TableRowColumn>);
      })
    }
  </TableRow>
);

export default HeadingsRow;
