import React from 'react';
import {
  FormField,
  NumberInput,
  BooleanInput,
  TextInput,
  SelectInput,
  required,
  number,
  minValue,
} from 'admin-on-rest';
import { style } from './style';

const validateMinLength = minValue(1);
const SelectOptions = ({ record }) => <span>{record.title}</span>;

export const getInputs = ({ type, key, options }) => {
  switch (type) {
  case 'BooleanInput':
    return <BooleanInput source={key} options={{ label: undefined }} />;
  case 'SelectInput':
    return <SelectInput source={key} choices={options} optionText={<SelectOptions />} options={{ floatingLabelText: undefined, ...style.selectOverride }} elStyle={style.select} />;
  case 'NumberInput':
    return <NumberInput source={key} validate={[required, number, validateMinLength]} options={{ floatingLabelText: undefined }} elStyle={{ width: '200px' }} />;
  default: {
    return <TextInput source={key} options={{ floatingLabelText: undefined }} elStyle={{ width: '200px' }} />;
  }
  }
};

export const RenderElement = ({ basePath, record, resource, item }) => (<FormField
  basePath={basePath}
  input={getInputs(item)}
  record={record}
  resource={resource}
/>);

