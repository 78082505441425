import _ from 'lodash';

export const COLUMN_VERSION = 4;
export const PROCESSING = 'processing';
export const DONE = 'done';
/**
 * Action for manage columns in neon orders table.
 * Turn on/off column.
 * Reducer: neonOrdersReducer.
 * @param {String} col Column key
 * @param {Boolean} status Turn on/off
 */
export const NO_TOGGLE_COLUMN = 'NO_TOGGLE_COLUMN';
export const switchColumn = (col, status) => ({
  type: NO_TOGGLE_COLUMN,
  payload: {
    col,
    status,
  },
});

/**
 * Action for on/off all columns in neon orders table.
 * Turn on/off columns.
 * Reducer: neonOrdersReducer.
 * @param {Boolean} status Turn on/off
 */
export const NO_TOGGLE_ALL_COLUMNS = 'NO_TOGGLE_ALL_COLUMNS';
export const toogleAllColumns = status => ({
  type: NO_TOGGLE_ALL_COLUMNS,
  payload: status,
});

/**
 * Action for add order to popup.
 * Reducer: neonOrdersReducer.
 * @param {Object} order Order fields from API by order id
 */
export const NO_POPUP_ORDER = 'NO_POPUP_ORDER';
export const addOrderToPopup = order => ({
  type: NO_POPUP_ORDER,
  payload: order,
});

/**
 * Action for update existing orders.
 * Reducer: adminInjection.
 * @param {Object} order Updated neon order fields
 */
export const NO_UPDATE = 'NO_UPDATE';
export const updateOrder = (fields, isBadge = true) => {
  const data = { ...fields };

  if (fields.legs) {
    data.legs = fields.legs.map((leg) => ({ ..._.mapKeys(leg, (value, key) => _.snakeCase(key)) }));
  }

  return {
    type: NO_UPDATE,
    payload: {
      id: fields.id,
      data,
      isBadge,
    },
  };
};

/**
 * Action for create new order.
 * Reducer: adminInjection.
 * @param {Object} order New order fields
 */
export const NO_ADD = 'NO_ADD';
export const addOrder = ({
  id,
  filledQuantity,
  quantity,
  hedge_quantity,
  status,
  price,
  neon_price,
  commodityCode,
  commodityContract,
  contractCode,
  contractExpiry,
  contractExpirySecond,
  direction,
  quotedCurrency,
  termCurrency,
  userId,
  createdAt,
  updatedAt,
  lastEditBy,
  email,
  firstName,
  lastName,
  totalCount,
  structure,
  structureType,
  strikeLevel,
  timeInForce,
  agile_code_id,
  clientDirectionFirst,
  clientDirectionSecond,
  autoapprove_status,
  orderType,
  expiryDate,
  stopPrice,
  neon_stop_price,
  cardId,
  isCompo,
  fxSpreadRate,
  fxRate,
  unit,
  factor,
  avgPrice,
  avgNeonPrice,
  neonOrderId,
  legs,
  skip_hedging,
}) => ({
  type: NO_ADD,
  payload: { id,
    data: {
      id,
      filled_quantity: filledQuantity,
      status,
      quantity,
      hedge_quantity,
      price,
      neon_price,
      commodity_code: commodityCode,
      commodity_contract: commodityContract,
      contract_code: contractCode,
      contract_expiry: contractExpiry,
      contract_expiry_second: contractExpirySecond,
      direction,
      quoted_currency: quotedCurrency,
      user_id: userId,
      created_at: createdAt,
      updated_at: updatedAt,
      last_edit_by: lastEditBy,
      email,
      first_name: firstName,
      last_name: lastName,
      time_in_force: timeInForce,
      totalCount,
      structure,
      structure_type: structureType,
      strike_level: strikeLevel,
      agile_code_id,
      client_direction_first: clientDirectionFirst,
      client_direction_second: clientDirectionSecond,
      autoapprove_status,
      order_type: orderType,
      expiry_date: expiryDate,
      stop_price: stopPrice,
      neon_stop_price,
      card_id: cardId,
      is_compo: isCompo,
      fx_spread_rate: fxSpreadRate,
      fx_rate: fxRate,
      term_currency: termCurrency,
      unit,
      factor,
      avg_price: avgPrice,
      avg_neon_price: avgNeonPrice,
      neon_order_id: neonOrderId,
      legs,
      skip_hedging,
    },
  },
});
