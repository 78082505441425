import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';

const styleLoader = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  position: 'fixed',
  zIndex: 1,
  left: '270px',
  top: 0,
  backgroundColor: 'rgba(255,255,255, 0.8)',
  overflowX: 'hidden',
  transition: '0.5s',
};

export const Loader = ({ text }) => <div style={styleLoader}><CircularProgress size={80} thickness={5} style={{ right: '15%' }}/><p style={{ position: 'relative', color: 'rgb(0, 188, 212)', fontSize: '14px', right: '15%' }}>{text}</p></div>;

