import React from 'react';
import { Create } from 'admin-on-rest';

import { LocationForm, LocationToolbar } from './components';

const CreateButton = props => (
  <Create {...props}>
    <LocationForm toolbar={<LocationToolbar />} />
  </Create>
);

export default CreateButton;
