/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField,
  ReferenceField,
  FunctionField,
  DateField,
} from 'admin-on-rest';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';
import { SaleLabel } from './helpers';

const CqgAccountsToSalesList = props => (
  <List {...props} sort={{ field: 'created_at', order: 'DESC' }} perPage={50}>
    <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
      <TextField label="Account Code" source="accountCode" />
      <ReferenceField
        label="Sale"
        source="saleSlackId"
        reference="cqg_accounts_to_sales/sales"
        linkType={false}
      >
        <FunctionField
          render={sale => <SaleLabel {...sale} />}
        />
      </ReferenceField>
      <DateField formatDate={formatDateTimeInput} label="Updated" source="updatedAt" showTime />
      <DateField formatDate={formatDateTimeInput} label="Created" source="createdAt" showTime />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default CqgAccountsToSalesList;
