import React, { Component } from 'react';
import { List } from 'material-ui/List';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AgileMenu from './AgileMenu';
import MENU from './Menu';

const mapValuesDeep = (array, access) => (array.reduce((current, item) => {
  const { path, nested } = item;
  if (path === '/') current.push(item);
  if (path && access.includes(path.split('/')[1])) {
    current.push(item);
  }
  if (nested) {
    const t = mapValuesDeep(nested, access);
    if (t.length) {
      current.push({
        ...item,
        nested: t.filter(item => item.display !== false),
      });
    }
  }
  return current;
}, []));


class AgileMenuContainer extends Component {
  shouldComponentUpdate({ access: NextAccess }) {
    const { access } = this.props;
    return access.length !== NextAccess.length;
  }

  felterByAccess(list) {
    const { access } = this.props;
    return mapValuesDeep(list, access);
  }

  render() {
    const { onMenuTap, logout } = this.props;
    return (
      localStorage.getItem('session') ?
        <List>
          <AgileMenu items={this.felterByAccess(MENU)} onMenuTap={onMenuTap} />
          {logout}
        </List>
        : null
    );
  }
}

AgileMenuContainer.propTypes = {
  onMenuTap: PropTypes.func.isRequired,
};

const mapStateToProps = ({ currentAdminReducer }) => ({
  access: currentAdminReducer.access,
});

export default connect(mapStateToProps, {})(AgileMenuContainer);
