import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import CreateButtonIcon from 'material-ui/svg-icons/content/reply';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import { connect } from 'react-redux';
import { translate, showNotification as showNotificationAction, refreshView as refreshViewAction, GET_LIST } from 'admin-on-rest';
import restClient from '../restClient';
import { removeSpecialChars } from '../helpers/utilities';

import ValidateEmail from '../helpers/ValidateEmail';
import path from '../path';

class EditButton extends React.Component {
  defaultState = {
    open: false,
    name: '',
    emails: [],
    selectedEmails: [],
    clients: [],
    selectedClients: [],
    validationErrors: {
      name: false,
      selectedEmails: false,
    },
  }

  constructor(props) {
    super(props);
    this.translate = this.props.translate;
    this.state = this.defaultState;
  }

  validate() {
    const { name, selectedEmails } = this.state;
    const errors = {};

    if (!name.length) errors.name = true;
    if (!selectedEmails.length) errors.selectedEmails = true;

    return errors;
  }

  handleSubmit = () => {
    const validationErrors = this.validate();

    if (Object.keys(validationErrors).length) {
      this.setState({
        ...this.state,
        validationErrors,
      });
      return;
    }

    const { showNotification, refreshView, record } = this.props;
    const { name, selectedEmails, selectedClients } = this.state;

    this.setState({ open: false }, () => {
      fetch(`${path}/admin/sales_teams/${record.id}`, { method: 'PUT',
        body: JSON.stringify({ name, selectedEmails, selectedClients }),
        headers: {
          'Token': localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        } })
        .then(() => {
          showNotification('Sales team created');
          refreshView();
          return this.setState({ ...this.defaultState });
        })

        .catch((e) => {
          console.error(e);
          showNotification('Error', 'warning');
        });
    });
  }

  handleInputChange = inputName => (...event) => this.setState({
    ...this.state,
    [inputName]: removeSpecialChars(event[1]),
    validationErrors: {
      ...this.state.validationErrors,
      [inputName]: false,
    },
  });

  getEmails = () =>
    restClient(GET_LIST, 'users', {
      sort: {
        field: 'email',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    });

  getClients = () =>
    restClient(GET_LIST, 'client_database/names', {
      sort: {
        field: 'email',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    });

  handleOpen = async () => {
    this.initState();
    const [emails, clients] = await Promise.all([this.getEmails(), this.getClients()]);
    this.setState({
      ...this.state,
      emails: emails.data.map(e => e.email),
      clients: clients.data.map(c => c.clientName),
    });
  }

  initState = () => {
    const { name, recipients, clients } = this.props.record;
    this.setState({
      ...this.state,
      name,
      selectedEmails: recipients,
      selectedClients: clients || [],
      open: true,
    });
  }

  clientsDataSource() {
    const { clients, selectedClients } = this.state;
    return clients.filter(client => !selectedClients.includes(client));
  }

  handleDeleteClients = (client) => {
    const selectedClients = this.state.selectedClients.filter(c => c !== client);
    this.setState({ selectedClients });
  }

  handleAddClients = (client) => {
    const selectedClients = [...this.state.selectedClients, client];
    this.setState({ selectedClients });
  }

  closeDialog = () => {
    this.setState({ ...this.defaultState });
  }

  emailsDataSource() {
    const { emails, selectedEmails } = this.state;
    return emails.filter(email => !selectedEmails.includes(email));
  }

  handleAddRecipients = (chip) => {
    const chips = [...this.state.selectedEmails, chip.toLowerCase()];
    this.setState({
      ...this.state,
      selectedEmails: chips,
      validationErrors: {
        ...this.state.validationErrors,
        selectedEmails: false,
      },
    });
  }

  handleBeforeAddRecipientsChip = (chip) => {
    const { selectedEmails } = this.state;
    if (!ValidateEmail(chip)) return false;
    return !selectedEmails.includes(chip.toLowerCase());
  }

  handleDeleteRecipients = (chip) => {
    const chips = this.state.selectedEmails.filter(e => e !== chip);
    this.setState({ selectedEmails: chips });
  }

  render() {
    const { selectedEmails, selectedClients } = this.state;
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label={this.translate('salesTeams.edit.buttonText')}
          icon={<CreateButtonIcon />}
          onClick={this.handleOpen}
        />
        <Dialog
          title={this.translate('salesTeams.edit.title')}
          modal={false}
          autoScrollBodyContent={true}
          open={this.state.open}
        >
          <TextField
            floatingLabelText={this.translate('salesTeams.edit.name')}
            errorText={this.state.validationErrors.name && this.translate('aor.validation.required')}
            fullWidth
            type="text"
            multiLine={true}
            value={this.state.name}
            onChange={this.handleInputChange('name')}
          />
          <ChipInput
            errorText={this.state.validationErrors.selectedEmails && this.translate('aor.validation.required')}
            value={selectedEmails}
            openOnFocus={true}
            floatingLabelText={this.translate('salesTeams.edit.recipients')}
            dataSource={this.emailsDataSource()}
            onRequestAdd={this.handleAddRecipients}
            onRequestDelete={this.handleDeleteRecipients}
            onBeforeRequestAdd={this.handleBeforeAddRecipientsChip}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={selectedClients}
            openOnFocus={true}
            floatingLabelText={this.translate('salesTeams.edit.clients')}
            dataSource={this.clientsDataSource()}
            onRequestAdd={this.handleAddClients}
            onRequestDelete={this.handleDeleteClients}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <div style={{ paddingTop: '15px', float: 'right' }} >
            <RaisedButton
              label={this.translate('pdfs.dialog.btnCancel')}
              onClick={this.closeDialog}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label={this.translate('pdfs.dialog.btnSubmit')}
              primary={true}
              icon={<SubmitIcon />}
              onClick={this.handleSubmit}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

EditButton.propTypes = {
  record: PropTypes.object,
  sendAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(EditButton);
