/* eslint-disable */
import React, { Children } from 'react';
import { required, SimpleForm, TextInput, regex } from 'admin-on-rest';
import { SaveButton, Toolbar } from 'admin-on-rest';
import { FormField } from 'admin-on-rest';

const validateHost = regex(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i, 'Host: must be a valid uri');

const SectionBox = ({ children, basePath, record, resource }) => Children.map(
  children,
  input => (input ? (
    <div className="aor-input aor-input-type">
      <FormField
        basePath={basePath}
        input={input}
        record={record}
        resource={resource}
      />
    </div>
  ) : null),
);

const FieldsByType = ({ type = '', ...rest }) => {
  if (type === 'ICE') {
    return (
      <SectionBox {...rest}>
        <TextInput label="Host" source="host" validate={[required, validateHost]} />
        <TextInput label="Auth Token" source="authToken" validate={[required]} {...rest} />
      </SectionBox>
    )
  }
  return (
    <SectionBox {...rest}>
      <TextInput label="Host" source="host" validate={[required, validateHost]} />
      <TextInput label="User" source="username" validate={[required]} />
      <TextInput label="Password" source="password" type="password" validate={[required]} />
    </SectionBox>
  )
}

const UpdateToolbar = props => <Toolbar {...props} >
  <SaveButton label="Save" redirect={props.redirect} submitOnEnter={true} disabled={true} />
</Toolbar>;

export const IceAccountsForm = ({ toolbar, record: { type }, record, ...rest }) => {
  return (
    <SimpleForm toolbar={<UpdateToolbar />} {...rest} record={record}>
      <TextInput label="Type" source="type" options={{ disabled: true }} validate={[required]} />
      <FieldsByType type={type} {...rest} />
    </SimpleForm>
  );
}
