import React from 'react';
import { Edit } from 'admin-on-rest';

import { IceAccountsForm } from './IceAccountsForm';

export const IceAccountsEdit = (props) =>
  <Edit title="Edit account" {...props}>
    <IceAccountsForm {...props} />
  </Edit>;

export { default as IceAccountsList } from './IceAccountsList';
