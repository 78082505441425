
import { UPDATE } from 'admin-on-rest';

import { STATEMENT_GENERATE } from './PDFAction';

const PDFGenerateAction = data => ({
  type: STATEMENT_GENERATE,
  payload: { id: data.id },
  meta: { resource: 'statements/pull_by_id', fetch: UPDATE, cancelPrevious: false },
});

export default PDFGenerateAction;
