import { Datagrid, DateInput, DeleteButton, EditButton, Filter, FunctionField, List, Responsive, SelectInput, SimpleList, TextField, TextInput, BooleanField } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateInput, formatDateTimeInput, dateParser } from '../utils/dates';
import Toogles from '../components/Toggles';
import CommodityActions from './CommodityActions';
import socket from 'ioClient';

const CommodityContractsFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="_q" alwaysOn />
    <TextInput source="commodity_code" />
    <TextInput source="agile_name" />
    <TextInput source="proxy_name" label="CQG Proxy" />
    <SelectInput source="status" choices={[
      { id: true, name: 'Active' },
      { id: false, name: 'Inactive' },
    ]} />
    <DateInput
      options={{ formatDate: formatDateInput }}
      source="updated_at"
      showTime
      parse={dateParser}
    />
    <DateInput
      options={{ formatDate: formatDateInput }}
      source="created_at"
      showTime
      parse={dateParser}
    />
  </Filter>
);

export class CommodityContractsList extends React.Component {
  constructor(props) {
    super(props);
    this.socket = socket;
  }

  componentDidMount() {
    this.socket.on('custom-pricing-request', () => {
      this.props.refreshView();
    });
  }

  render() {
    const isSwap = this.props.resource === 'swap_commodities';
    return (<List {...this.props}
      title={`${isSwap ? 'Swap' : 'Vanilla'} Contracts`}
      filters={<CommodityContractsFilter />}
      actions={<CommodityActions {...this.props} />}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={50}
    >
      <Responsive
        small={
          <SimpleList
            primaryText={r => `${r.structure} ${r.underlying}`}
            secondaryText={r => r.currency}
            tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.expired_at}</Moment>}
          />
        }
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
            <TextField source="id" />
            <TextField source="commodity_code" />
            <TextField source="agile_name" />
            <TextField source="num_fwd_months" label="Fwd Months" />
            { isSwap ? null : <TextField source="atm_bound" label="ATM Bound" /> }
            <TextField source="qty" />
            <TextField source="spread" />
            <FunctionField source="markup_spread" render={r => <p style={{ padding: '0', margin: '0', fontSize: '12px' }}
              dangerouslySetInnerHTML={{
                __html: `${JSON.stringify(r.markup_spread)}`,
              }}></p>} />
            <TextField source="tick" />
            <TextField source="factor" />
            <FunctionField label="Inactive/Active" render={r => <Toogles changeActive={this.props.changeActive} status={r.status} id={r.id} />} />
            <TextField source="proxy_name" label="CQG Proxy" />
            { isSwap ? <BooleanField
              source="is_spot"
              label="Spot"
            /> : null }
            { isSwap ? <BooleanField
              source="is_tas"
              label="TAS"
            /> : null }
            { isSwap ? <TextField source="strategy_periods" label="Strategy Periods" /> : null }
            <DateField formatDate={formatDateTimeInput} source="updated_at" showTime />
            <DateField formatDate={formatDateTimeInput} source="created_at" showTime />
            <EditButton />
            <DeleteButton />
          </Datagrid>
        }
      />
    </List>);
  }
}

