import React from 'react';

import { SimpleForm, Edit, TextInput, required, minLength, NumberInput } from 'admin-on-rest';

import VolatilityBucketEditTitle from './VolatilityBucketEditTitle';

const validateMinLengthToTwo = minLength(2);
const VolatilityBucketEdit = props => (
  <Edit {...props} title={<VolatilityBucketEditTitle />}>
    <SimpleForm>
      <TextInput
        source="name"
        validate={[required, validateMinLengthToTwo]}
      />
      <NumberInput
        source="min_shift_buy"
        validate={[required]}
      />
      <NumberInput
        source="incremental_shift_buy"
        validate={[required]}
      />
      <NumberInput
        source="min_shift_sell"
        validate={[required]}
      />
      <NumberInput
        source="incremental_shift_sell"
        validate={[required]}
      />
    </SimpleForm>
  </Edit>
);

export default VolatilityBucketEdit;
