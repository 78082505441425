import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';

import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { MuiThemeProvider, getMuiTheme } from 'material-ui/styles';
import { Card, CardActions } from 'material-ui/Card';
import { Notification, translate, userLogin } from 'admin-on-rest';
import Seo from '../components/common/Seo';

const component = ({ meta: { touched, error } = {}, input: { ...inputProps }, ...props }) =>
  <TextField
    errorText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />;

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.disabled = this.props.submitting;
    this.muiTheme = getMuiTheme(this.props.theme);
    this.translate = this.props.translate;
  }

  get path() {
    return this.props.location.state ? this.props.location.state.nextPathname : '/';
  }

  onSubmit() {
    return this.props.handleSubmit(({ email, password }) =>
      this.props.userLogin({ email, password }, this.path));
  }

  render() {
    return (
      <MuiThemeProvider muiTheme={this.muiTheme}>
        <Seo title={'Login'} />
        <div className="Login">
          <Card
            className="Login-card"
          >
            <div className="Login-avatar">
              <img src="img/logo.png" />
            </div>
            <form onSubmit={this.onSubmit()}>
              <div className="Login-input">
                <div>
                  <Field
                    name="email"
                    component={component}
                    floatingLabelText={this.translate('login.field.email')}
                    type="email"
                  />
                </div>
                <div>
                  <Field
                    name="password"
                    component={component}
                    floatingLabelText={this.translate('login.field.password')}
                    type="password"
                  />
                </div>
              </div>
              <CardActions
                className="Login-button"
              >
                <RaisedButton
                  type="submit"
                  disabled={this.disabled}
                  label={this.translate('aor.auth.sign_in')}
                  primary
                  fullWidth
                />
              </CardActions>
            </form>
          </Card>
          <Notification />
        </div>
      </MuiThemeProvider>
    );
  }
}

Login.propTypes = {
  ...propTypes,
  authClient: PropTypes.func,
  previousRoute: PropTypes.string,
  theme: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

Login.defaultProps = {
  theme: {},
};

const enhance = compose(
  translate,
  reduxForm({
    form: 'signIn',
    validate: (v, props) => {
      const errors = {};
      if (!v.email) {
        errors.email = props.translate('aor.validation.required');
      }
      if (!v.password) {
        errors.password = props.translate('aor.validation.required');
      }
      return errors;
    },
  }),
  connect(null, { userLogin }),
);

export default enhance(Login);
