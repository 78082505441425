export default `
.ntf-center {

}
.ntf-center .ntf-wp {
  z-index: 1101;
  margin: 0;
  padding: 0;
  background: rgba(9, 30, 66, 0.54);
  bottom: 0px;
  left: 0px;
  right: 0px;
  position: fixed;
  top: 0px;
}
.ntf-center .ntf-col {
  background-color: rgb(255, 255, 255);
  display: flex;
  position: fixed;
  width: 600px;
  height: 100vh;
  left: 0px;
  top: 0px;
  overflow: hidden;
  z-index: 1102;
}

.ntf-center .ntf-col .ntf-close {
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  color: rgb(66, 82, 110);
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  height: 100vh;
  padding-bottom: 16px;
  padding-top: 24px;
  width: 64px;
}

.ntf-close button {
  border-style: initial;
  border-width: 0px;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  outline: none;
}
.ntf-close button:hover {
  background-color: rgba(9, 30, 66, 0.08);
}

.ntf-messages {
  margin-top: 24px;
  width: 380pt;
}

.ntf-messages .ntf-header {
  font-size: 1.71429em;
  font-style: inherit;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 1.16667;
}

.ntf-messages .header-actions {
  color: #8993a4;
  font: 400 11px system-ui;
}

.ntf-messages .header-actions span {
  cursor: pointer;
  margin: 0px 4px;
}

.ntf-messages .header-actions span:hover {
  color: #0bb2dd!important;
}

.ntf-divider {
  background-color: #EBECF0;
  border-radius: 2px;
  top: 0;
  content: '';
  height: 2px;
  left: 0px;
  margin: 0;
  position: absolute;
  right: 0px;
  width: inherit;
}

.ntf-content {
  position: relative;
  margin: 15px 0px 0px;
}

.card-list {
  padding-top: 20px;
  height:90vh;
  overflow:auto;
}

.card-list .card {
  margin: 0px 0px 8px 0px;
  border-radius: 5px;
  background: none;
  padding: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  flex-direction: unset;
}
.card-list .card:hover {
  background: rgb(244, 245, 247);
}
.card-content {
  width: 332pt;
}
.card-content, .card-actions {
  display: flex;
  flex-direction: column;
}
.card-actions {
  margin-right: 5px;
}

.card-content .content-subject {
  font-weight: 600;
  color: rgb(23, 43, 77);
  font-size: 14px;
  font-style: normal;
  line-height: 1.42857;
  letter-spacing: -0.005em;
}
.card-content .content-body {
  text-overflow: ellipsis;
  color: rgb(23, 43, 77);
  overflow: hidden;
  font-size: 14px;
  font-style: normal;
  line-height: 1.42857;
  letter-spacing: -0.005em;
}
.card-content .content-createdAt {
  font-weight: 600;
  color: rgb(137, 147, 164);
  font-size: 13px;
  margin-top: 2px;
}

.card-actions .close {
  border-width: 0;
  background: none;
  cursor: pointer;
  color: #505F79;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  -webkit-box-align: baseline;
  display: inline-flex;
  box-sizing: border-box;
  white-space: nowrap;
  width: auto;
  height: auto;
  outline: none;
}
.card-actions .close span {
  align-self: center;
  display: inline-block;
  flex-wrap: nowrap;
  max-width: 100%;
  font: 400 11px system-ui;
}
.card-actions .close span svg {
  height: 16px;
  width: 16px;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  vertical-align: bottom;
}
.card-actions .close:hover {
  background: rgba(9, 30, 66, 0.08);
}

.card-actions .read {
  transition: none;
  background: #ebecf0;
  border-color: #ebecf0;
  border-radius: 100%;
  border: 4px solid transparent;
  width: 18px;
  height: 18px;
  outline: none;
  cursor: pointer;
  display: inline-block;
  align-items: flex-start;
  text-align: center;
  cursor: default;
  padding:0px;
}

.card-actions .read.active {
  border-color: #ebecf0;
  background: #0052cc;
}

.card-actions .read.unactive {
  border-color: #ebecf0;
  background: #fff;
}

.sound-btn {
  float: right;
  cursor: pointer;
  color: #8993a4;
  font: 400 11px system-ui;
  margin: 0px 4px;
  line-height: 3.2857142857142856em;
}

.sound-btn:hover {
  color: #0bb2dd!important;
}

.card-list .empty-list {
  color: #8993a4;
  font-weight: 500;
  font-style: inherit;
  font-size: 1.5em;
  text-align: center;
  margin-top: 30px;
}

`;
