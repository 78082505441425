import React from 'react';
import compose from 'recompose/compose';
import { translate, showNotification as showNotificationAction } from 'admin-on-rest';
import Icon from 'material-ui/svg-icons/content/block';
import FlatButton from 'material-ui/FlatButton';
import request from 'superagent';
import { connect } from 'react-redux';
import path from '../../../path';
import { isCqgContract } from '../../../helpers/utilities';
import { MINIFUTURE } from '../../constants';

class CancelButton extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.translate;
  }

  onClick = () => {
    const { showNotification, record } = this.props;
    const proxyType = (record.structure === 'forwards / ndfs' || (record.structure === MINIFUTURE && !!record.term_currency) ? 'mspx' : (isCqgContract(record.commodity_code) ? 'cqg' : 'neon'));

    // need to be changed according to the implementation on the backend
    request
      .post(`${path}/${proxyType}/cancel_order/${record.id}`)
      .set('token', localStorage.getItem('session'))
      .send({ status: 'cancelled' })
      .then(() => {
        showNotification('NeonOrders.cancel.status.success');
      })
      .catch(() => showNotification('NeonOrders.cancel.status.fail', 'warning'));
  };

  onClick() {}

  render() {
    const { record, label } = this.props;
    return record.status === 'active' ? (
      <FlatButton
        primary
        label={label}
        onClick={this.onClick}
        icon={<Icon />}
        className={'btn-cancel'}
        style={{ float: 'left', minWidth: '30px' }}
      />
    ) : (
      ''
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
  }),
);

export default enhance(CancelButton);
