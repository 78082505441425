import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'recompose/compose';
import hash from 'object-hash';

import { MessageScroll, HeaderActions } from './components';
import style from './style';
import { closeWsLink, connectWsLink } from './../apollo';

import {
  wsOnConnectedAction,
  wsOnDisconnectedAction,
  toggleNotification as toggleNotificationAction,
  changeMessageAction,
  readNotificationAction,
  deleteNotificationAction,
  allMeaasgeAction,
} from './actions';
import {
  updateOrder as updateOrderAction,
  addOrder as addOrderAction,
} from './../neonOrders/actions';
import {
  EVENTS_SUBSCRIPTION,
} from './helpers';
import {
  MESSAGE_EVENT_ACTIONS,
} from './../constants';
import { updateLivePosition as updateLivePositionAction } from '../livePosition/actions';

class NotificationCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sound: localStorage.getItem('bell') === 'true',
      reset: null,
    };
  }

  componentWillMount() {
    const { wsOnConnected, wsOnDisconnected } = this.props;
    const ws = connectWsLink();
    ws.onReconnecting(() => wsOnConnected());
    ws.onConnected(() => wsOnConnected());
    ws.onDisconnected(() => wsOnDisconnected());
  }

  componentWillUnmount() {
    closeWsLink();
  }

  shouldComponentUpdate({ notificationOpen }, { sound, reset }) {
    return notificationOpen !== this.props.notificationOpen ||
      sound !== this.state.sound ||
      reset !== this.state.reset;
  }

  // handle all received events from ws subscription
  componentWillReceiveProps({ data, updateOrder, addOrder, changeMessage, updateLivePosition }) {
    const event = this.validateWsEvent(data || {});
    if (event) {
      const { type, action } = event.payload;
      const key = `${event.type}:${type || ''}:${action || ''}`;

      if (MESSAGE_EVENT_ACTIONS.has(key)) {
        changeMessage(MESSAGE_EVENT_ACTIONS.get(key), { ...event });
      }

      if (action === 'readAllNotifications' || action === 'deleteAllNotifications') {
        this.setState(state => ({
          ...state,
          reset: new Date().getTime(),
        }));
      }

      if (type === 'ORDER') {
        const isCanceled = event.subject === 'Cancel order';
        if (event.type === 'ADMIN' && !isCanceled) {
          addOrder(event.payload);
        } else if (event.type === 'SYSTEM' || (event.type === 'ADMIN' && isCanceled)) {
          updateOrder(event.payload);
        }
      }
      if (type === 'LP_UPDATE') {
        updateLivePosition(event.payload);
      }
    }
  }

  validateWsEvent = ({ notifications }) => {
    if (!notifications) return null;
    const { data: { notifications: oldNotification } } = this.props;
    if (hash(oldNotification || {}) === hash(notifications || {})) return null;
    return notifications;
  }

  onRead = (id, isRead, type, orderID) => {
    if (!isRead) {
      const { readNotification } = this.props;
      readNotification({ id, type, orderID });
    }
  }

  onDelete = (id) => this.props.deleteNotification(id);

  toggleSount = () => {
    const status = !this.state.sound;
    localStorage.setItem('bell', status);
    this.setState({
      sound: status,
    });
  }

  onHeaderAction = (type) => this.props.allMeaasge(type);

  render() {
    const { notificationOpen, toggleNotification } = this.props;
    const { sound, reset } = this.state;
    const soundStatus = sound ? 'off' : 'on';
    return notificationOpen ? (
      <div className="ntf-center">
        <div className="ntf-wp"></div>
        <div className="ntf-col">
          <div className="ntf-close">
            <button onClick={toggleNotification}>
              <span>
                <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M8.414 11.5H18a1 1 0 0 1 0 2H8.414l3.793 3.793a1 1 0 0 1-1.414 1.414l-5.5-5.5a1 1 0 0 1 0-1.414l5.5-5.5a1 1 0 0 1 1.414 1.414L8.414 11.5z" fill="currentColor" fillRule="evenodd"></path></svg>
              </span>
            </button>
          </div>
          <div className="ntf-messages">
            <div className="ntf-header">
              <span>Notifications</span>
              <span className="sound-btn" onClick={this.toggleSount}>Turn {soundStatus} sound</span>
            </div>
            <HeaderActions onHeaderAction={this.onHeaderAction} />
            <div className="ntf-content">
              <span className="ntf-divider"></span>
              <MessageScroll onRead={this.onRead} onDelete={this.onDelete} total={10} reset={reset} />
            </div>
          </div>
        </div>
        <style>{style}</style>
      </div>
    ) : '';
  }
}

const mapStateToProps = ({ notificationReducer }) => ({
  notificationOpen: notificationReducer.notificationOpen,
});

export default compose(
  connect(mapStateToProps, {
    wsOnConnected: wsOnConnectedAction,
    toggleNotification: toggleNotificationAction,
    wsOnDisconnected: wsOnDisconnectedAction,
    updateOrder: updateOrderAction,
    addOrder: addOrderAction,
    changeMessage: changeMessageAction,
    readNotification: readNotificationAction,
    deleteNotification: deleteNotificationAction,
    allMeaasge: allMeaasgeAction,
    updateLivePosition: updateLivePositionAction,
  }),
  graphql(EVENTS_SUBSCRIPTION),
)(NotificationCenter);
