import { put, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'admin-on-rest';
import { push } from 'react-router-redux';
import {
  NC_WS_CONNECTED,
  NC_WS_DISCONNECTED,
  NC_COUNT_FETCH,
  TOGGLE_NOTIFICATION,
  NC_READ,
  NC_DELETE,
  NC_ALL_ACTION,
  fetchCountAction,
  countUpdateAction,
  toggleNotification,
} from './actions';

import {
  fetchNotificationsCount,
  readMessage,
  deleteMessage,
  readAllMessage,
  deleteAllMessage,
} from './helpers';

export default function* NotificationCenterSaga() {
  yield [
    takeEvery(TOGGLE_NOTIFICATION, function* () {
      yield put(fetchCountAction());
    }),
    takeEvery(NC_WS_CONNECTED, function* () {
      yield put(fetchCountAction());
      yield console.info('WebSocket connected.');
    }),
    takeEvery(NC_WS_DISCONNECTED, function* () {
      yield console.error('WebSocket reconnecting...');
    }),
    takeEvery(NC_COUNT_FETCH, function* () {
      const { unreadNotifications } = yield fetchNotificationsCount();
      yield put(countUpdateAction(Number(unreadNotifications)));
    }),
    takeEvery(NC_READ, function* ({ payload: { id, type, orderID } }) {
      try {
        yield readMessage({ id });
        if (type && type === 'ORDER') {
          yield put(push('/'));
          yield put(push(`/neon_orders/${orderID}`));
          yield put(toggleNotification());
        }
      } catch (error) {
        yield put(showNotification(`${error.message.split(':')[1]}`, 'warning'));
      }
    }),
    takeEvery(NC_DELETE, function* ({ payload: id }) {
      try {
        yield deleteMessage({ id });
      } catch (error) {
        yield put(showNotification(`${error.message.split(':')[1]}`, 'warning'));
      }
    }),
    takeEvery(NC_ALL_ACTION, function* ({ payload: title }) {
      try {
        if (title === 'Delete All') {
          yield deleteAllMessage();
        } else {
          yield readAllMessage();
        }
      } catch (error) {
        yield put(showNotification(`${error.message.split(':')[1]}`, 'warning'));
      }
    }),
  ];
}
