import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { showNotification } from 'admin-on-rest';

import { PDF_HISTORY_SEND_SUCCESS, PDF_HISTORY_SEND_FAILURE } from './PDFHistoryAction';

export default function* reviewSaga() {
  yield [
    takeEvery(PDF_HISTORY_SEND_SUCCESS, function* () {
      yield put(showNotification('pdfHistories.notification.send_success'));
      yield put(push('/'));
      yield put(push('/pdf_histories'));
    }),
    takeEvery(PDF_HISTORY_SEND_FAILURE, function* ({ error }) {
      yield put(showNotification('pdfHistories.notification.send_error', 'warning'));
      console.error(error);
    }),
  ];
}
