import React from 'react';
import { SimpleForm, Create, TextInput, required, LongTextInput, regex } from 'admin-on-rest';
import EmailToolbar from './EmailToolbar';
import { RichTextInput } from '../helpers';

const validateMinLengthToTwo = regex(/\S{2,}/, 'Should contain at leat 2 characters');

const EmailCreate = props => (
  <Create {...props}>
    <SimpleForm
      toolbar={<EmailToolbar />}
    >
      <TextInput
        source="name"
        validate={[required, validateMinLengthToTwo]}
      />
      <LongTextInput
        source="subject"
        validate={[required, validateMinLengthToTwo]}
      />
      <LongTextInput
        source="text"
        validate={[required, validateMinLengthToTwo]}
      />
      <RichTextInput
        source="html"
        validate={[required, validateMinLengthToTwo]}
      />
    </SimpleForm>
  </Create>
);

export default EmailCreate;
