import React from 'react';
import moment from 'moment';
import Moment from 'react-moment';

import { NumberField, List, Responsive, SimpleList, TextField, BooleanField, ShowButton, DeleteButton } from 'admin-on-rest';

import AccumulatorDescriptionFilter from './AccumulatorDescriptionFilter';
import AccumulatorDescriptionActions from './AccumulatorDescriptionActions';

import { DateField, Datagrid } from '../helpers';

class AccumulatorDescriptionList extends React.Component {
  constructor(props) {
    super(props);

    this.ids = [];
    this.state = { ids: [], rows: [] };
  }

  async handleRowClick(rows) {
    if (rows === 'all') {
      await this.setState({
        ids: this.ids,
        rows,
      });
      return;
    }
    if (rows === 'none') {
      await this.setState({
        ids: [],
        rows,
      });
      return;
    }
    await this.setState({
      ids: this.ids.filter((el, i) => rows.includes(i)),
      rows,
    });
  }
  render() {
    return (
      <List {...this.props}
        actions={<AccumulatorDescriptionActions disabled={() => !this.state.ids.length} loaded={() => this.state.ids} />}
        filters={<AccumulatorDescriptionFilter />}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={50}
      >
        <Responsive
          small={
            <SimpleList
              primaryText={r => r.code}
              secondaryText={r => r.name}
              tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
            />
          }
          medium={
            <Datagrid
              headerOptions={{ adjustForCheckbox: true, displaySelectAll: true }}
              bodyOptions={{ displayRowCheckbox: true }}
              rowOptions={{ selectable: true }}
              options={{ multiSelectable: true, onRowSelection: this.handleRowClick.bind(this) }}
              selected={i => this.state.rows.includes(i)}
            >
              <TextField
                ref={el => (el ? this.ids.push(el.props.record.id) : null)}
                source="product_category"
              />
              <TextField
                source="structure"
              />
              <TextField
                source="structure_code"
              />
              <TextField
                source="type"
              />
              <TextField
                source="product"
              />
              <TextField
                source="product_description"
              />
              <TextField
                source="barrier_type"
              />
              <TextField
                source="leverage_style"
              />
              <NumberField
                source="leverage"
              />
              <BooleanField
                source="skip_for_web"
              />
              <DateField
                formatDate={date => (date ? moment(date).format('DD MMMM YYYY HH:mm') : '')}
                source="updated_at"
                showTime
              />
              <ShowButton />
              <DeleteButton />
            </Datagrid>
          }
        />
      </List>
    );
  }
}

export default AccumulatorDescriptionList;
