import { put, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'admin-on-rest';
import { push } from 'react-router-redux';
import path from '../path';
import {
  GET_HISTORY_DATA,
  historyLoaded,
} from './actions';

const fetchLogs = (orderId) => new Promise((resolve, reject) =>
  fetch(`${path}/admin/neon_orders_history/general/${orderId}`, {
    method: 'GET',
    headers: {
      Token: localStorage.getItem('session'),
    },
  })
    .then(async (response) => {
      if (response.status === 200) return response.json();
      const { message = 'Request failed' } = await response.json();
      reject(message);
    })
    .then((response) => resolve(response))
    .catch(error => reject(error.message)));

export default function* OrderHistorySaga() {
  yield [
    takeEvery(GET_HISTORY_DATA, function* ({ payload: orderId }) {
      try {
        const {
          title, general = {}, logs = [],
        } = yield fetchLogs(orderId);

        yield put(historyLoaded(title, general, logs));
      } catch (error) {
        yield put(showNotification(error, 'warning'));
        yield put(push('/'));
        yield put(push('/neon_orders'));
      }
    }),
  ];
}
