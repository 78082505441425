import React from 'react';
import Dropzone from 'react-dropzone';
import request from 'superagent';
import FlatButton from 'material-ui/FlatButton';
import FileUploadIcon from 'material-ui/svg-icons/file/file-upload';
import compose from 'recompose/compose';

import { connect } from 'react-redux';
import { translate, refreshView, showNotification } from 'admin-on-rest';

import path from '../path';

class PricingCardFileUploadButton extends React.Component {
  constructor(props) {
    super(props);
    const cardId = (props.record && props.record.id) || null;
    this.state = { cardId };
    this.translate = this.props.translate;
  }

  onDrop(file) {
    const { cardId } = this.state;
    request
      .put(`${path}/admin/pricing_cards/${cardId}/file`)
      .set('Token', localStorage.getItem('session'))
      .field('file', file)
      .then(() => {
        this.props.showNotification('files.notification.upload_success');
        this.props.refreshView();
      })
      .catch(() => {
        this.props.showNotification('files.notification.upload_error');
        this.props.refreshView();
      });
  }

  get label() {
    return this.translate('files.list.uploadSingle');
  }

  render() {
    return (
      <Dropzone
        className="Dropzone"
        onDrop={this.onDrop.bind(this)}
        multiple={false}
        accept=".json"
        style={{ 'display': 'inline-block' }}
      >
        <FlatButton
          label={this.label}
          icon={<FileUploadIcon/>}
        />
      </Dropzone>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    refreshView,
    showNotification,
  }),
);

export default enhance(PricingCardFileUploadButton);
