import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CardActions } from 'material-ui/Card';
import {
  RefreshButton,
  CreateButton,
  refreshView as refreshViewAction,
  showNotification as showNotificationAction,
} from 'admin-on-rest';
import Checkbox from 'material-ui/Checkbox';
import path from '../path';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

class CommodityActions extends Component {
  onToggle = (active) => () => {
    const { refreshView, resource, showNotification } = this.props;

    fetch(`${path}/admin/${resource}/changeSpreadLogic/${active}`, {
      method: 'PUT',
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    }).then((response) => {
      if (response.status === 200) return response;
      throw new Error('Request failed');
    }).then(() => {
      showNotification('Success');
      refreshView();
    }).catch(() => {
      showNotification('Error', 'warning');
    });
  }

  render() {
    const { basePath, resource, filters, displayedFilters, filterValues, showFilter, record } = this.props;
    const isFixedSpread = record && record.use_fixed_spread;
    return (
      <CardActions style={cardActionStyle}>
        <Checkbox
          style={{ float: 'left', marginTop: '6px', width: 'none' }}
          label="Spread"
          onClick={this.onToggle(!isFixedSpread)}
          checked={isFixedSpread}
        />
        {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' })}
        <CreateButton basePath={basePath}/>
        <RefreshButton />
      </CardActions>
    );
  }
}

const mapStateToProps = (state, props) => ({
  record: state.admin.resources[props.resource].data[state.admin.resources[props.resource].list.ids[0]] || [],
});

export default connect(mapStateToProps, {
  showNotification: showNotificationAction,
  refreshView: refreshViewAction,
})(CommodityActions);
