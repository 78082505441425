import React from 'react';

import { Filter, TextInput } from 'admin-on-rest';

const ListFilters = props => (
  <Filter {...props}>
    <TextInput
      label="Search (client name, client code, dashboard)"
      source="_q"
      alwaysOn
      options={{ style: { width: '320px' } }}
    />
    <TextInput source="client" />
    <TextInput source="client_code" />
    <TextInput source="signer_full_name" />
    <TextInput source="dashboard_name" />
  </Filter>
);

export default ListFilters;
