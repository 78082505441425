/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { Create, Edit, refreshView } from 'admin-on-rest';
import { CommodityForm } from './CommodityForm';
import { CommodityContractsList } from './CommodityContractsList';
import { CommodityContractsActive, VanillaContractsActive } from './CommodityContractsActive';


export const SwapContractsList = connect(null, {
  refreshView,
  changeActive: CommodityContractsActive,
})(CommodityContractsList);

export const CommodityContractsCreate = (props) =>
  <Create {...props} title="Create Swap Contract">
    <CommodityForm {...props} />
  </Create>;

export const CommodityContractsEdit = (props) =>
  <Edit {...props} title="Edit Swap Contract">
    <CommodityForm {...props} />
  </Edit>;


export const VanillaContractsList = connect(null, {
  refreshView,
  changeActive: VanillaContractsActive,
})(CommodityContractsList);

export const VanillaContractsCreate = (props) =>
  <Create {...props} title="Create Vanilla Contract">
    <CommodityForm {...props} />
  </Create>;

export const VanillaContractsEdit = (props) =>
  <Edit {...props} title="Edit Vanilla Contract">
    <CommodityForm {...props} />
  </Edit>;

