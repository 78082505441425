export default `
.notification-icon {
    display: flex;
    box-sizing: border-box;
    padding: 0;
    margin: 0 25px 0 0;
    position: relative;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .notificationBtn {
    background-color: rgb(0, 188, 212);
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: flex;
    border-radius: 50%;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    border: 0;
    position: relative;
    height: 35px;
    width: 35px;
    outline: none;
  }
  .notificationBtn:hover {
    background-color: rgb(11, 173, 195);
  }
  .ntf-wp-top {
    left: 20px;
    top: -4px;
    position: absolute;
    border-radius: 50%;
  }
  .ntf-wp-count {
    background-color: rgb(222, 53, 11);
    color: rgb(255, 255, 255);
    display: inline-block;
    border-radius: 2em;
    text-align: center;
    position: absolute;
    padding: 0.166667em 0.5em;
  }

  svg#bell-icon {
    margin-top: 5px;
  }

  svg.notify{
    -webkit-animation: ring 1.5s ease;
    // -webkit-transform: rotate(35deg);
    animation: ring 1.5s ease;
  }


  @-webkit-keyframes ring {
    0% {
        -webkit-transform: rotate(35deg);
    }
    12.5% {
        -webkit-transform: rotate(-30deg);
    }
    25% {
        -webkit-transform: rotate(25deg);
    }
    37.5% {
        -webkit-transform: rotate(-20deg);
    }
    50% {
        -webkit-transform: rotate(15deg);
    }
    62.5% {
        -webkit-transform: rotate(-10deg);
    }
    75% {
        -webkit-transform: rotate(5deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
    }
  }

  @keyframes ring {
    0% {
        -webkit-transform: rotate(35deg);
        transform: rotate(35deg);
    }
    12.5% {
        -webkit-transform: rotate(-30deg);
        transform: rotate(-30deg);
    }
    25% {
        -webkit-transform: rotate(25deg);
        transform: rotate(25deg);
    }
    37.5% {
        -webkit-transform: rotate(-20deg);
        transform: rotate(-20deg);
    }
    50% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }
    62.5% {
        -webkit-transform: rotate(-10deg);
        transform: rotate(-10deg);
    }
    75% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
  }
`;
