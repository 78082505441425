import React, { Fragment } from 'react';
import { RenderElement } from './FieldRenderer';
import { style } from './style';


export const SettingsRow = ({ item, order, total, ...rest }) => {
  const { title, description } = item;
  return (
    <Fragment>
      <div style={style.pointer} className='settings-hever'>
        <div style={style.SettingsRowWrapp}>
          <div style={style.infoSection}>
            <div style={style.label}>{title}</div>
            {description ? <div style={style.secondaryLabel}>{description}</div> : null}
          </div>
          <div style={{ height: '32px' }}>
            <RenderElement {...rest} item={item} />
          </div>
        </div>
      </div>
      {order < total - 1 ? <div style={style.hr}></div> : null}
    </Fragment>
  );
};

export const SettingSection = ({ title, settings, ...rest }) => (
  <section>
    <div className='header' style={style.header}>
      <h2 style={style.headerh2}>{title}</h2>
    </div>
    <div className='card' style={style.card}>
      <div>
        {settings.map((el, index) => <SettingsRow key={index} order={index} total={settings.length} item={el} {...rest} />)}
      </div>
    </div>
  </section>
);
