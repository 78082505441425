import React from 'react';
import { Create } from 'admin-on-rest';

import { CustomerForm, CustomerToolbar } from './components';

const CreateButton = props => (
  <Create {...props}>
    <CustomerForm toolbar={<CustomerToolbar />} />
  </Create>
);

export default CreateButton;
