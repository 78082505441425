import moment from 'moment';

export const DATE_FORMAT_SHORT = 'MMM YY';
export const DATE_FORMAT = 'DD MMMM YYYY';
export const DATETIME_FORMAT = 'DD MMMM YYYY HH:mm';

const formatDate = format => date => moment(date).format(format);

export const formatDateInput = formatDate(DATE_FORMAT);
export const formatDateTimeInput = formatDate(DATETIME_FORMAT);

// official admin-on-rest fix for timezone offsets in datetime picker
// https://marmelab.com/admin-on-rest/Inputs.html#transforming-input-value-tofrom-record
export const tzOffset = new Date().getTimezoneOffset() / 60;
export const dateParser = (v) => {
  if (!v) return;

  const regexp = /(\d{4})-(\d{2})-(\d{2})/;
  let match = regexp.exec(v);
  if (match === null) return;

  let year = match[1];
  let month = match[2];
  let day = match[3];

  if (tzOffset < 0) {
  // negative offset means our picked UTC date got converted to previous day
    const date = new Date(v);
    date.setDate(date.getDate() + 1);
    match = regexp.exec(date.toISOString());
    year = match[1];
    month = match[2];
    day = match[3];
  }
  return [year, month, day].join('-');
};
