import React from 'react';
import DownloadJson from '../../components/DownloadJson';

const DownloadOnePagerJson = ({ record }) => {
  const { onePager, onePagerFileUploadStatus, onePagerFilename } = record;

  if (!onePager) {
    return <div style={{ width: '150px', color: 'red' }}>No One Pager config uploaded</div>
  }
  return <DownloadJson jsonFile={onePager} fileStatus={onePagerFileUploadStatus} fileName={onePagerFilename || 'sp_one_pager.json'} />;
};

export default DownloadOnePagerJson;
