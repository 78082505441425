import { ApolloLink, HttpLink } from 'apollo-boost';
import {
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';

export const getAuthToken = () => {
  const token = localStorage.getItem('session');
  return token ? `Bearer ${token}` : '';
};

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/graphql` });
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: getAuthToken(),
    },
  });
  return forward(operation);
});

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_WS_PROTOCOL || 'ws'}://${process.env.REACT_APP_SUBSCRIPTION_HOST}/subscription`,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      authorization: getAuthToken(),
    }),
  },
});

wsLink.subscriptionClient.onError(error => console.log('***> ws error', error));

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  authLink.concat(wsLink),
  authLink.concat(httpLink),
);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({}),
});

export const closeWsLink = () => {
  wsLink.subscriptionClient.close();
};

export const connectWsLink = () => {
  wsLink.subscriptionClient.connect();
  return wsLink.subscriptionClient;
};
