import React from 'react';
import Avatar from 'material-ui/Avatar';
import StructureIcon from 'material-ui/svg-icons/action/lightbulb-outline';

import { translate } from 'admin-on-rest';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import { Table, TableHeader, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';

class Structure extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  render() {
    const { list = [], count = 0 } = this.props;
    return (
      <Card
        style={{ marginBottom: '2em' }}
      >
        <CardHeader
          title={count}
          subtitle="List of not available structure contracts"
          avatar={<Avatar backgroundColor="#ff9800" icon={<StructureIcon />} />}
          actAsExpander={true}
          showExpandableButton={true}
        />
        <CardText
          expandable={true}
          style={{ padding: '0' }}
        >
          <Table selectable={false}>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>

              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {list.length ? list.map((r, i) => (
                <TableRow key={i}>
                  <TableRowColumn style={{ width: 100, paddingLeft: '10px', paddingRight: '10px', textAlign: 'right' }}>
                    {r.structure}
                  </TableRowColumn>
                  <TableRowColumn style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                    {r.message}
                  </TableRowColumn>
                </TableRow>
              )) : <TableRow><TableRowColumn style={{ paddingLeft: '16px', fontSize: '14px' }}>List does not exist</TableRowColumn></TableRow>}
            </TableBody>
          </Table>
        </CardText>
      </Card>
    );
  }
}

export default translate(Structure);
