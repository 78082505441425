import { DateInput, Filter, NumberInput, TextInput } from 'admin-on-rest';
import React from 'react';
import { formatDateInput } from '../utils/dates';


const MarketDataFilter = props => (
  <Filter {...props}>
    <TextInput
      label="marketData.list.search"
      source="_q"
      alwaysOn
    />
    <NumberInput
      source="lot_size"
    />
    <TextInput
      source="fut_trading_units"
    />
    <NumberInput
      source="fut_val_pt"
    />
    <TextInput
      source="quote_units"
    />
    <TextInput
      source="currency"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="expiry_date"
      showTime
    />
    <TextInput
      source="bloomberg_ticker"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="contract_expiry"
      showTime
    />
    <TextInput
      source="short_code"
    />
    <TextInput
      source="commodity_contract"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
    />
  </Filter>
);

export default MarketDataFilter;
