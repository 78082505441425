import React, { Component } from 'react';
import FlatButton from 'material-ui/FlatButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import CheckIcon from 'material-ui/svg-icons/navigation/check';
import Dialog from 'material-ui/Dialog';
import {
  SelectInput,
} from 'admin-on-rest';
import './ChannelSelect.css';

export default class ChannelSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      prevValue: null,
      nextValue: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { input: { value } } = nextProps;
    this.setState({
      prevValue: value,
      nextValue: value,
    });
  }

  handleChange(e) {
    const { value, onChange } = this.props.input;
    if (e === null && value !== null) {
      this.setState(state => ({
        ...state,
        open: true,
        prevValue: value,
        nextValue: e,
      }));
    } else {
      onChange(e);
    }
  }

  handleClose() {
    const { prevValue } = this.state;
    const { onChange } = this.props.input;
    onChange(prevValue);

    this.setState(state => ({
      ...state,
      open: false,
      nextValue: prevValue,
    }));
  }

  handleYes() {
    const { nextValue } = this.state;
    const { onChange } = this.props.input;
    onChange(nextValue);

    this.setState(state => ({
      ...state,
      open: false,
    }));
  }

  render() {
    const { open, nextValue } = this.state;
    const { title, text } = this.props;

    return (<div>
      <Dialog
        title={title}
        modal={false}
        open={open}
        autoScrollBodyContent={true}
      >
        <div className="slack-team-confirm">
          <div>{text}</div>
          <div>Are you sure you would like to proceed?</div>
          <div className="slack-team-confirm-btn">
            <FlatButton
              label='YES'
              onClick={this.handleYes.bind(this)}
              icon={<CheckIcon />}
            />
            <FlatButton
              label='NO'
              onClick={this.handleClose.bind(this)}
              icon={<CancelIcon />}
            />
          </div>
        </div>
      </Dialog>
      <SelectInput
        elStyle={{ width: '50%' }}
        optionText="team"
        optionValue="id"
        options={
          {
            'listStyle': { 'maxHeight': '30vh', 'overflow': 'auto' },
            'onBlur': () => {},
          }
        }
        {...this.props}
        input={{
          ...this.props.input,
          onChange: this.handleChange.bind(this),
          value: nextValue,
        }}
      />
    </div>);
  }
}

ChannelSelect.defaultProps = {
  title: 'Remove user from SlackTeam',
  text: 'A SlackTeam is required for "Trade" to be active. "Trade" will be disabled for the user.',
};
