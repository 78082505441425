import path from '../path';

export const PresignedURLDownload = (type, id) => {
  fetch(`${path}/admin/s3_handler/generate_presigned_url`, { method: 'POST',
    body: JSON.stringify({ type, id }),
    headers: {
      'Token': localStorage.getItem('session'),
      'Content-Type': 'application/json; charset=utf-8',
    } })
    .then((response) => response.json())
    .then((res) => {
      const div = document.getElementsByClassName('body')[0];
      const newlink = document.createElement('a');
      newlink.setAttribute('class', 'presignedUrl');
      newlink.setAttribute('href', res.signedUrl);
      div.appendChild(newlink);
      newlink.click();
      div.removeChild(newlink);
    })
    .catch((error) => {
      console.log('error', error);
    });
};
