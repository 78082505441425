import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import MarketDataDeleteIcon from 'material-ui/svg-icons/communication/clear-all';
import compose from 'recompose/compose';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import RaisedButton from 'material-ui/RaisedButton';

import { connect } from 'react-redux';
import { translate, DELETE } from 'admin-on-rest';

import { MARKET_DATA_DELETE } from './MarketDataAction';

const marketDataDeleteAction = (ids, data, basePath) => ({
  type: MARKET_DATA_DELETE,
  payload: { id: ids.join('-'), data, basePath },
  meta: { resource: 'market_data', fetch: DELETE, cancelPrevious: false },
});

class MarketDataDeleteButton extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
    this.state = {
      open: false,
      ids: [],
    };
  }

  handleOpen() {
    this.setState({
      open: true,
      ids: this.props.loaded,
    });
  }

  handleClose() {
    this.setState({
      open: false,
      ids: [],
    });
  }

  handleSubmit() {
    this.props.deleteAction(this.state.ids, {});
  }

  render() {
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          primary={true}
          onClick={this.handleOpen.bind(this)}
          disabled={this.props.disabled()}
          label={this.translate('marketData.list.delete')}
          icon={<MarketDataDeleteIcon/>}
        />
        <Dialog
          title={this.translate('marketData.dialog.title')}
          modal={false}
          open={this.state.open}
        >
          {this.translate('marketData.dialog.message')}
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              label={this.translate('marketData.dialog.btnCancel')}
              onClick={this.handleClose.bind(this)}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label={this.translate('marketData.dialog.btnConfirm')}
              primary={true}
              onClick={this.handleSubmit.bind(this)}
              icon={<SubmitIcon />}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    deleteAction: marketDataDeleteAction,
  }),
);

export default enhance(MarketDataDeleteButton);
