import dagre from 'dagre';
import _ from 'lodash';
import subscriptionPath from '../subscriptionPath';
import path from '../path';
import {
  CARD_WIDTH,
  CARD_HEIGHT_LINE,
  CARD_HEIGHT,
  DEFAULT_NODE_POSITION,
  NODE_CARD_TYPE,
  NODE_IS_DRAGGABLE,
  NODE_TYPE_ORDER,
  NODE_TYPE_ORDER_ACCESS,
  EDGE_IS_ANIMATED,
  EDGE_TYPE,
  EDGE_COLOR,
  DEFAULT_LAYOUT_DIRECTION,
  LAYOUT_DIRECTION_HORIZONTAL,
  NODE_TYPE_TAB,
  NODE_TYPE_CARD,
  NODE_TYPE_GROUP,
} from './constants';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

export const fetchLogs = () => new Promise((resolve, reject) =>
  fetch(`${subscriptionPath}/sub/debug`, {
    method: 'GET',
    headers: {
      Token: localStorage.getItem('session'),
    },
  })
    .then(async (response) => {
      if (response.status === 200) return response.json();
      const { message = 'Request failed' } = await response.json();
      reject(message);
    })
    .then((response) => resolve(response))
    .catch(error => reject(error.message)));

export const fetchUser = (userId = 0) => new Promise((resolve, reject) =>
  fetch(`${path}/admin/users/${userId}`, {
    method: 'GET',
    headers: {
      Token: localStorage.getItem('session'),
    },
  })
    .then(async (response) => {
      if (response.status === 200) return response.json();
      const { message = 'Request failed' } = await response.json();
      reject(message);
    })
    .then((response) => resolve(response))
    .catch(error => reject(error.message)));

export const calcNodeHeight = (attributes = {}) => (Object.entries(attributes).length * CARD_HEIGHT_LINE) + CARD_HEIGHT;

export const getLayoutedElements = (nodes, edges, direction = DEFAULT_LAYOUT_DIRECTION) => {
  const isHorizontal = direction === LAYOUT_DIRECTION_HORIZONTAL;
  dagreGraph.setGraph({ rankdir: direction, ranksep: 150, ranker: 'longest-path' });

  nodes.forEach(({ id, style }) => {
    dagreGraph.setNode(id, { width: style.width, height: style.height });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const { id, style: { width, height } } = node;
    const nodeWithPosition = dagreGraph.node(id);
    node.data.direction = direction;
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';
    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - (width / 2),
      y: nodeWithPosition.y - (height / 2),
    };

    return node;
  });

  return { nodes, edges };
};

export const buildGraph = (responseGraph, selected) => {
  console.log('selected', selected);
  const nodes = [];
  const edges = [];
  const addedNodes = [];
  let tabSize = 0;
  let cardSize = 0;

  const accessList = _.sortBy(Object.entries(responseGraph), ([, { nodeType }]) => NODE_TYPE_ORDER_ACCESS[nodeType] || -1);
  const allowedNode = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, { nodeType, attributes, edges: nodeEdges }] of accessList) {
    if (nodeType === NODE_TYPE_CARD && selected.includes(attributes.userId)) {
      allowedNode.push(...nodeEdges, key);
    }

    if (nodeType === NODE_TYPE_GROUP && allowedNode.includes(key)) {
      allowedNode.push(...nodeEdges.filter((i) => i.split(':').length > 2));
    }

    if (nodeType === NODE_TYPE_TAB) {
      break;
    }
  }

  const userList = new Set();
  const nodeList = _.sortBy(Object.entries(responseGraph), ([, { nodeType }]) => NODE_TYPE_ORDER[nodeType] || -1);
  // console.log('nodeList', nodeList);

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, node] of nodeList) {
    const { nodeType, attributes, edges: nodeEdges } = node;
    if (allowedNode.includes(key)) {
      nodes.push({
        id: key,
        data: { label: key, nodeType, attributes },
        position: DEFAULT_NODE_POSITION,
        style: {
          fontSize: 25,
          width: CARD_WIDTH,
          height: calcNodeHeight(attributes),
        },
        draggable: NODE_IS_DRAGGABLE,
        type: NODE_CARD_TYPE,
      });
      addedNodes.push(key);

      // eslint-disable-next-line no-restricted-syntax
      for (const target of nodeEdges) {
        if (!addedNodes.includes(target)) {
          edges.push({
            id: `${key}-${target}`,
            source: key,
            target,
            animated: EDGE_IS_ANIMATED,
            type: EDGE_TYPE,
            style: {
              strokeWidth: 3,
              stroke: EDGE_COLOR,
            },
          });
        }
      }
    }

    if (nodeType === NODE_TYPE_TAB) tabSize += 1;
    if (nodeType === NODE_TYPE_CARD) {
      cardSize += 1;
      userList.add(attributes.userId || 0);
    }
  }

  return {
    tabSize,
    cardSize,
    userList: _.sortBy([...userList]),
    ...getLayoutedElements(
      nodes,
      edges,
    ),
  };
};
