
import React from 'react';
import {
  TextField,
} from 'material-ui';
import { NavigationClose } from 'material-ui/svg-icons';

const FieldsList = (props) => {
  const {
    translate,
    index: i,
    fields,
    handleCloseBox,
    handleInputChange,
    errors,
  } = props;
  return (<div className="markup-item">
    <NavigationClose
      className="close-marckup"
      onClick={() => handleCloseBox(i)}
    />
    <TextField
      errorText={errors[`field_${i}`] && translate('aor.validation.required')}
      floatingLabelText={'Field'}
      fullWidth
      name="field"
      type="string"
      value={fields.field}
      onChange={handleInputChange(i, 'field')}
    />
    <TextField
      errorText={errors[`label_${i}`] && translate('aor.validation.required')}
      floatingLabelText={'Label'}
      fullWidth
      name="label"
      type="string"
      value={fields.label}
      onChange={handleInputChange(i, 'label')}
    />
  </div>);
};

export default FieldsList;
