import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { showNotification } from 'admin-on-rest';

import { VANILLA_DESCRIPTION_DELETE_SUCCESS, VANILLA_DESCRIPTION_DELETE_FAILURE } from './VanillaDescriptionAction';

export default function* reviewSaga() {
  yield [
    takeEvery(VANILLA_DESCRIPTION_DELETE_SUCCESS, function* () {
      yield put(showNotification('vanillaDescription.notification.delete_success'));
      yield put(push('/'));
      yield put(push('/vanilla_description'));
    }),
    takeEvery(VANILLA_DESCRIPTION_DELETE_FAILURE, function* ({ error }) {
      yield put(showNotification('vanillaDescription.notification.delete_error', 'warning'));
      console.error(error);
    }),
  ];
}
