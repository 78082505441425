
import React from 'react';
import { Show, translate, ListButton, RefreshButton } from 'admin-on-rest';

import { CardActions, SimpleShowLayout, TextField } from 'material-ui/Card';

const PricingTitle = translate(({ record }) =>
  <span>{translate('pricings.edit.title', { smart_count: 1 })} { `${record.reference_price} ${record.currency}` }</span>);

const CustomPricingRequestActions = ({ basePath, refresh }) => (
  <CardActions
    className="CardActions">
    <ListButton
      basePath={basePath}
    />
    <RefreshButton
      refresh={refresh}
    />
  </CardActions>
);

const PricingTemplatesShow = ({ ...props }) => (
  <Show {...props}
    title={<PricingTitle />}
    actions={<CustomPricingRequestActions />}
  >
    <SimpleShowLayout>
      <TextField
        source="markups"
      />
    </SimpleShowLayout>

  </Show>
);

export default PricingTemplatesShow;
