import React from 'react';
import { List } from 'admin-on-rest';
import { ClientDataBaseActions, ListFilters, Columns } from './components';

export default class ClientDatabaseList extends React.Component {
  render() {
    return (
      <List {...this.props}
        filters={<ListFilters />}
        sort={{ field: 'client_code', order: 'ASC' }}
        title="Client database"
        perPage={50}
        actions={<ClientDataBaseActions />}
      >
        <Columns />
      </List>
    );
  }
}

