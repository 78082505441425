import { DateInput, Filter, TextInput } from 'admin-on-rest';
import React from 'react';
import { formatDateInput } from '../utils/dates';

const VanillaDescriptionFilter = props => (
  <Filter {...props}>
    <TextInput
      label="vanillaDescription.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="product"
    />
    <TextInput
      source="product_category"
    />
    <TextInput
      source="structure"
    />
    <TextInput
      source="type"
    />
    <TextInput
      source="product_description"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
    />
  </Filter>
);

export default VanillaDescriptionFilter;
