/* eslint-disable */
import React, { Component, useMemo } from 'react';
import hash from 'object-hash';
import { isBoolean, sum } from 'lodash';
import TextField from 'material-ui/TextField';
import ChipInput from 'material-ui-chip-input';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { Tabs, Tab } from 'material-ui/Tabs';
import {
  GET_ONE,
  GET_LIST,
  ListButton,
  Toolbar,
  SimpleForm
} from 'admin-on-rest';
import dotProp from 'dot-prop-immutable';
import { removeSpecialChars } from '../helpers/utilities';
import { Loader } from '../helpers/Loader';
import { SaveButton } from '../helpers';
import path from '../path';
import CommodityList from './components/CommodityList';
import restClient from '../restClient';

const SpreadSheetsToolbar = props => (
  <Toolbar {...props}>
    <ListButton basePath="/spreadsheets" label={props.translate('Back')} />
    <SaveButton redirect="list" submitOnEnter={false} label="Save" {...props} />
  </Toolbar>
);

const listParam = type => ({
  sort: { field: type === 'users' ? 'email' : 'id', order: 'ASC', },
  pagination: { page: 1, perPage: 100000, },
});

const diffStructures = (lObj, rObj) => Object.keys(lObj).reduce((acc, key) => {
    const {
      qty,
      spread,
      markup_spread,
      id,
      agile_name,
      status,
      isDefault,
      daily_qty_limit: dailyQtyLimit,
      qty_approval: qtyApproval,
      factor,
      use_fixed_spread: useFixedSpread,
      ib_fee: ibFee,
      sales_comission: salesComission,
      cqg_account: cqgAccount
    } = lObj[key];
    const {
      qty: qtyOld,
      spread: spreadOld,
      markup_spread: markupSpreadOld,
      status: statusOld,
      isDefault: isDefaultOld,
      qty_approval: qtyApprovalOld,
      daily_qty_limit: dailyQtyLimitOld,
      factor: factorOld,
      use_fixed_spread: useFixedSpreadOld,
      ib_fee: ibFeeOld,
      sales_comission: salesComissionOld,
      cqg_account: cqgAccountOld,
    } = rObj[key];

    const res = { id, agile_name, };

    if (qty !== qtyOld) {
      res.qty = { from: qtyOld, to: qty, };
    }
    if (factor !== factorOld) {
      res.factor = { from: factorOld, to: factor, };
    }
    if (qtyApproval !== qtyApprovalOld) {
      res.qty_approval = { from: qtyApprovalOld, to: qtyApproval, };
    }
    if (dailyQtyLimit !== dailyQtyLimitOld) {
      res.daily_qty_limit = { from: dailyQtyLimitOld, to: dailyQtyLimit, };
    }
    if (spread !== spreadOld) {
      res.spread = { from: spreadOld, to: spread, };
    }
    if (markup_spread !== markupSpreadOld) {
      res.markup_spread = { from: markupSpreadOld, to: markup_spread, };
    }

    if (ibFee !== ibFeeOld) {
      res.ib_fee = { from: ibFeeOld, to: ibFee, };
    }

    if (salesComission !== salesComissionOld) {
      res.sales_comission = { from: salesComissionOld, to: salesComission, };
    }

    if (cqgAccount !== cqgAccountOld) {
      res.cqg_account = { from: cqgAccountOld, to: cqgAccount, };
    }

    const isStatusChanged = status !== statusOld;
    const isDefaultChanged = isDefault !== isDefaultOld;
    if (isStatusChanged || isDefaultChanged) {
      res.status = { from: statusOld, to: status, };
    }
    if (isStatusChanged || isDefaultChanged) {
      res.isDefault = { from: isDefaultOld, to: isDefault, };
    }

    const isUseFixesSpreadChanged = useFixedSpread !== useFixedSpreadOld;
    if (isUseFixesSpreadChanged) {
      res.use_fixed_spread = { from: useFixedSpreadOld, to: useFixedSpread, };
    }

    if (res.qty || res.spread || res.markup_spread || res.status || res.qty_approval || res.daily_qty_limit || res.factor || res.isDefault || res.use_fixed_spread || res.ib_fee || res.sales_comission || res.cqg_account) acc.push(res);
    return acc;
  }, []);


export class SpreadsheetForm extends Component {

  constructor(props) {
    super(props);
    this.translate = this.props.translate;
    this.state =  {
      recordId: this.props.record && this.props.record.id,
      title: '',
      chips: [],
      users: [],
      groupChipsSource: [],
      groups: [],
      cqgAccount: null,
      cqgAccounts: [],
      salesRepresentative: null,
      salesRepresentatives: [],
      additionalSalesCoverage: null,
      additionalSalesCoverages: [],
      ibCounterparty: null,
      ibCounterparties: [],
      autoBook: null,
      swapContracts: [],
      asianSwapContracts: [],
      vanillaContracts: [],
      fxContracts: [],
      load: true,
      autoBookOptions: [
        { id: true, name: 'Yes' },
        { id: false, name: 'No' },
      ],
      old: {
        title: '',
        salesRepresentative: null,
        additionalSalesCoverage: null,
        ibCounterparty: null,
        users: [],
        usersHash: hash([]),
        groups: [],
        groupsHash: hash([]),
        swapContracts: [],
        swapHash: hash([]),
        asianSwapContracts: [],
        asianSwapHash: hash([]),
        vanillaContracts: [],
        vanillaHash: hash([]),
        fxContracts: [],
        fxHash: hash([]),
      },
      sumIbFee: 0,
      errors: {
        title: false,
      }
    };
  }

  async componentDidMount() {
    const [spreadsheets, usersResponse, allGroups, cqgAccounts, itemsSwap, itemsVanilla, itemsFx, salesRepresentatives, additionalSalesCoveragesItems, ibCounterparties ] = await Promise.all([
      this.state.recordId ? restClient(GET_ONE, 'spreadsheets', { id: this.state.recordId }) : Promise.resolve(),
      this.getList('users'),
      this.getList('user_groups'),
      fetch(`${path}/cqg/accounts`, {
        method: 'GET',
        headers: {
          'Token': localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        },
      }).then(response => response.json()),

      this.state.recordId ? Promise.resolve() : this.getList('swap_commodities'),
      this.state.recordId ? Promise.resolve() : this.getList('vanilla_commodities'),
      this.state.recordId ? Promise.resolve() : this.getList('fx_commodities'),
      fetch(`${path}/admin/spreadsheets/sales_representative`, {
        method: 'GET',
        headers: {
          'Token': localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        },
      }).then(response => response.json())
      .catch(e => {
        console.error('Was not able to get sales representative', e);
        showNotification('Something went wrong', 'warning');
      }),

      fetch(`${path}/admin/spreadsheets/additional_sales_coverage`, {
        method: 'GET',
        headers: {
          'Token': localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        },
      }).then(response => response.json())
      .catch(e => {
        console.error('Was not able to get additional sales coverage items', e);
        showNotification('Something went wrong', 'warning');
      }),

      fetch(`${path}/admin/spreadsheets/ib_counterparty`, {
        method: 'GET',
        headers: {
          'Token': localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        },
      }).then(response => response.json())
      .catch(e => {
        console.error('Was not able to get ib counterparty', e);
        showNotification('Something went wrong', 'warning');
      }),
    ]);

    const additionalSalesCoverages = {
      data: additionalSalesCoveragesItems?.data?.map(({ id, name}) => ({id: String(id), name})) || []
    }
    const title = spreadsheets ? spreadsheets.data.general.title : '';
    const users = spreadsheets ? this.userSort(spreadsheets.data.users.map(row => ({ id: row.id, email: row.email }))) : [];
    const groups = spreadsheets ? this.groupSort(spreadsheets.data.groups) : [];
    const swap = spreadsheets
      ? this.codeSort(spreadsheets.data.commodities.filter(item => item.type === 'swap'))
      : this.codeSort(itemsSwap.data.map(row => ({ ...row, status: false, type: 'swap', ib_fee: 0, sales_comission: 0, cqg_account: null })));
    const asianSwap = spreadsheets
      ? this.codeSort(spreadsheets.data.commodities.filter(item => item.type === 'asian swap'))
      : this.codeSort(itemsSwap.data.map(row => ({ ...row, status: false, type: 'asian swap', ib_fee: 0, sales_comission: 0, cqg_account: null })));
    const vanilla = spreadsheets
      ? this.codeSort(spreadsheets.data.commodities.filter(item => item.type === 'vanilla'))
      : this.codeSort(itemsVanilla.data.map(row => ({ ...row, status: false, type: 'vanilla', ib_fee: 0, sales_comission: 0, cqg_account: null })));
    const fx = spreadsheets
      ? this.codeSort(spreadsheets.data.commodities.filter(item => item.type === 'fx'))
      : this.codeSort(itemsFx.data.map(row => ({
          ...row,
          commodity_code: row.fx_code,
          qty: row.base_currency_qty,
          status: false,
          type: 'fx',
          ib_fee: 0,
          sales_comission: 0
        })));
    const cqgAccount = spreadsheets ? spreadsheets.data.cqgAccount : undefined;
    const autoBook = spreadsheets ? spreadsheets.data.autoBook : undefined;

    const salesRepresentative = spreadsheets ? spreadsheets.data?.general.sales_representative : null;
    const additionalSalesCoverage = spreadsheets ? spreadsheets.data?.general.additional_sales_coverage : null;
    const ibCounterparty = spreadsheets ? spreadsheets.data?.general.ib_counterparty : null;
    this.setState(state => ({
      ...state,
      title,
      swapContracts : [...swap],
      asianSwapContracts : [...asianSwap],
      vanillaContracts: [...vanilla],
      fxContracts: [...fx],
      users: [...users],
      groups: [...groups],
      cqgAccount: cqgAccount,
      cqgAccounts:  [{ id: null, name: '' }, ...(cqgAccounts && cqgAccounts.data || [])],
      salesRepresentative,
      salesRepresentatives:  [{ id: null, name: '' }, ...(salesRepresentatives && salesRepresentatives.data || [])],
      additionalSalesCoverage,
      additionalSalesCoverages: [{ id: null, name: '' }, ...(additionalSalesCoverages && additionalSalesCoverages.data || [])],
      ibCounterparty,
      ibCounterparties:  [{ id: null, name: '' }, ...(ibCounterparties && ibCounterparties.data || [])],
      autoBook,
      userChipsSource: this.userSort(usersResponse.data.map(i => ({ email: i.email, id: i.id }))),
      groupChipsSource: this.groupSort(allGroups.data),
      old: {
        title,
        users: [...users],
        groups: [...groups],
        cqgAccount: cqgAccount,
        salesRepresentative,
        additionalSalesCoverage,
        ibCounterparty,
        autoBook,
        usersHash: hash(users),
        groupsHash: hash(groups),
        swapContracts: [...swap],
        swapHash: hash(swap),
        asianSwapContracts : [...asianSwap],
        asianSwapHash: hash(asianSwap),
        vanillaContracts: [...vanilla],
        vanillaHash: hash(vanilla),
        fxContracts: [...fx],
        fxHash: hash(fx),
      },
      load: false,
    }), () => this.calculateAllIbFee());
  }

  getList = async type => restClient(GET_LIST, type, listParam(type)).catch(httpError => {
    if (httpError && httpError.body) {
      const { message } = httpError.body;
      console.error(`${type}:ERROR`, message);
      const { showNotification, history } = this.props;
      showNotification(message, 'warning');
      history.push('/spreadsheets');
    }
    return { data: [] };
  });
  userSort = arr => arr.sort((a, b) => (a.email > b.email ? 1 : -1));
  groupSort = arr => arr.sort((a, b) => (a.name > b.name ? 1 : -1));
  codeSort = arr => arr.sort((a, b) => (a.agile_name > b.agile_name && a.id > b.id ? 1 : -1));

  isChanged() {
    const {
      title,
      autoBook,
      users,
      groups,
      swapContracts,
      asianSwapContracts,
      vanillaContracts,
      fxContracts,
      sumIbFee,
      old: { title: titleOld, autoBook: autoBookOld, usersHash, groupsHash, swapHash, asianSwapHash, vanillaHash, fxHash },
    } = this.state;
    if (!this.state.salesRepresentative) return false;
    if (sumIbFee > 0 && !this.state.ibCounterparty) return false;

    if (this.state.old.cqgAccount !== this.state.cqgAccount) return true;
    if (this.state.old.salesRepresentative !== this.state.salesRepresentative) return true;
    if (this.state.old.additionalSalesCoverage !== this.state.additionalSalesCoverage) return true;
    if (this.state.old.ibCounterparty !== this.state.ibCounterparty) return true;
    if (titleOld !== title) return true;
    if (autoBookOld !== autoBook) return true;
    if (usersHash !== hash(this.userSort(users))) return true;
    if (groupsHash !== hash(this.groupSort(groups))) return true;
    if (swapHash !== hash(swapContracts)) return true;
    if (asianSwapHash !== hash(asianSwapContracts)) return true;
    if (vanillaHash !== hash(vanillaContracts)) return true;
    if (fxHash !== hash(fxContracts)) return true;

    return false;
  }

  handleRequestClose = () => {
    this.setState(state => ({ ...state, warning: '', }));
  };

  handleInputChange = inputName => (...event) => this.setState({
    [inputName]: removeSpecialChars(event[1]),
    errors: { ...this.state.errors, [inputName]: false },
  });

  handleSelectChange = selected => (...event) => {
    const [, , value] = event;
    this.setState({ [selected]: isBoolean(value) ? value : value || null });
  };

  handleAddChips = type => (chip) => {
    const chips = [...this.state[type], chip];
    this.setState({
      [type]: chips,
      errors: { ...this.state.errors, [type]: false, },
    });
  }

  handleDeleteChips = type => (chip) => {
    const chips = this.state[type].filter(c => c.id !== chip);
    this.setState({ [type]: chips, });
  }

  handleBeforeAddChip = type => (chip) => {
    const chips = this.state[type];
    return !chips.some(c => c.id === chip.id);
  }

  handleCheckAllTableChange = (status, structure) => () => {
    let structureState = this.state[structure];
    const checkedDefaultIndex = structureState.findIndex(({ isDefault }) => isDefault);
    if (status === false && checkedDefaultIndex > -1) {
      structureState = dotProp.set(structureState, `${checkedDefaultIndex}.isDefault`, false);
    }

    this.setState({
      [structure]: structureState.map(row => ({ ...row, status })),
    }, () => this.calculateAllIbFee());
  }

  handleFixedSpreadChange = (status, structure) => () => {
    let structureState = this.state[structure];
    this.setState({
      [structure]: structureState.map(row => ({ ...row, use_fixed_spread: status })),
    });
  }

  handleCheckBoxTableChange = (i, status, structure) => () => {
    let structureState = this.state[structure];
    const isDefault = structureState[i].isDefault;
    if (status === false && isDefault === true) {
      structureState = dotProp.set(structureState, `${i}.isDefault`, false);
    }
    this.setState({
      [structure]: [...dotProp.set(structureState, `${i}.status`, status)],
    });
    this.calculateIbFee(status, dotProp.get(structureState, `${i}.ib_fee`))
  }

  handleInputTableChange = (i, inputName, structure) => ({ target }) => {
    let value;
    switch (inputName) {
      case 'daily_qty_limit': value = Math.abs(Number(target.value)); break;
      case 'ib_fee': value = Math.abs(Number(target.value)); break;
      case 'sales_comission': value = Math.abs(Number(target.value)); break;
      default: value = Number(target.value); break;
    }

    const structureState = this.state[structure];

    if(inputName === 'ib_fee') {
      this.calculateIbFee(true, value - dotProp.get(structureState, `${i}.ib_fee`))
    }

    this.setState({
      [structure]: [...dotProp.set(structureState, `${i}.${inputName}`, value)],
    });
  }

  handleSelectCommodityCqgAccChange = (i, selected, structure) => (...event) => {
    const [, , value] = event;

    const structureState = this.state[structure];

    this.setState({
      [structure]: [...dotProp.set(structureState, `${i}.${selected}`, value)],
    });
  }

  getData() {
    const { title, autoBook, users, groups, cqgAccount, salesRepresentative, additionalSalesCoverage, ibCounterparty, swapContracts, asianSwapContracts, vanillaContracts, fxContracts } = this.state;
    if (!title.trim().length) {
      this.setState({ ...this.state, errors: { title: true, }, });
      return null;
    }
    const mapper = row => ({
      commodity_code: row.commodity_code || row.fx_code,
      spread: row.spread,
      markup_spread: row.markup_spread,
      qty: row.qty,
      daily_qty_limit: row.daily_qty_limit,
      qty_approval: row.qty_approval,
      activate: row.status,
      type: row.type,
      factor: row.factor,
      use_fixed_spread: row.use_fixed_spread,
      ib_fee: row.ib_fee,
      sales_comission: row.sales_comission,
      cqg_account: row.cqg_account
    });
    const result = {
      general: { title, salesRepresentative, additionalSalesCoverage, ibCounterparty, cqgAccount },
      autoBook,
      groups: groups.map(row => row.id),
      users: users.map(row => row.id),
      commodities: [
        ...swapContracts.map(mapper),
        ...asianSwapContracts.map(mapper),
        ...vanillaContracts.map(mapper),
        ...fxContracts.map(mapper),
      ],
    };
    return result;
  }


  handleSubmit() {
    const { showNotification, history } = this.props;

    if (!this.state.recordId) {
      // create
      const data = this.getData();
      const { groups } = this.state;
      if (!data) return;

      fetch(`${path}/admin/spreadsheets`, {
          method: 'POST',
          body: JSON.stringify({ ...data, groups }),
          headers: {
            'Token': localStorage.getItem('session'),
            'Content-Type': 'application/json; charset=utf-8',
          },
        })
        .then(response => response.json())
        .then(res => {
          if (!res.message) {
            showNotification('Spread sheet was created');
            history.push('/spreadsheets');
          } else {
            showNotification(res.message, 'warning');
          }
        })
        .catch(e => {
          console.error('spreadsheet create fail', e);
          showNotification('Something went wrong', 'warning');
        });
      return;
    }

    const {
      title,
      autoBook,
      users,
      groups,
      cqgAccount,
      salesRepresentative,
      additionalSalesCoverage,
      ibCounterparty,
      swapContracts,
      asianSwapContracts,
      vanillaContracts,
      fxContracts,
      old: {
        title: titleOld,
        autoBook: autoBookOld,
        salesRepresentative: salesRepresentativeOld,
        additionalSalesCoverage: additionalSalesCoverageOld,
        ibCounterparty: ibCounterpartyOld,
        usersHash,
        users: usersOld,
        groupsHash,
        groups: groupsOld,
        swapHash,
        swapContracts: swapContractsOld,
        asianSwapContracts: asianSwapContractsOld,
        asianSwapHash,
        vanillaHash,
        vanillaContracts: vanillaContractsOld,
        fxHash,
        fxContracts: fxContractsOld,
      },
    } = this.state;
    const data = {
      cqgAccount,
    };


    if (titleOld !== title) {
      data.title = {
        from: titleOld,
        to: title,
      };
    }

    if (ibCounterpartyOld !== ibCounterparty) {
      data.ibCounterparty = {
        from: ibCounterpartyOld,
        to: ibCounterparty,
      };
    }

    if (salesRepresentativeOld !== salesRepresentative) {
      data.salesRepresentative = {
        from: salesRepresentativeOld,
        to: salesRepresentative,
      };
    }

    if (additionalSalesCoverageOld !== additionalSalesCoverage) {
      data.additionalSalesCoverage = {
        from: additionalSalesCoverageOld,
        to: additionalSalesCoverage,
      };
    }

    if (autoBookOld !== autoBook) {
      data.autoBook = {
        from: autoBookOld,
        to: autoBook,
      };
    }

    if (usersHash !== hash(this.userSort(users))) {
      const deleted = usersOld.filter(o => !users.some(v => v.id === o.id));
      const add = users.filter(o => !usersOld.some(v => v.id === o.id));
      data.users = {
        deleted,
        add,
      };
    }

    if (groupsHash !== hash(this.groupSort(groups))) {
      const deleted = groupsOld.filter(o => !groups.some(v => v.id === o.id));
      const add = groups.filter(o => !groupsOld.some(v => v.id === o.id));
      data.groups = {
        deleted,
        add,
      };
    }

    if (swapHash !== hash(swapContracts)) {
      data.swap = diffStructures(swapContracts, swapContractsOld);
    }

    if (asianSwapHash !== hash(asianSwapContracts)) {
      data.asianSwap = diffStructures(asianSwapContracts, asianSwapContractsOld);
    }

    if (vanillaHash !== hash(vanillaContracts)) {
      data.vanilla = diffStructures(vanillaContracts, vanillaContractsOld);
    }

    if (fxHash !== hash(fxContracts)) {
      data.fx = diffStructures(fxContracts, fxContractsOld);
    }

    if (Object.keys(data).length === 0) return null;

    fetch(`${path}/admin/spreadsheets/${this.state.recordId}`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Token: localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      .then(response => response.json())
      .then(res => {
        if (!res.message) {
          showNotification('Spread sheet was updated');
          history.push('/spreadsheets');
          return null;
        }
        if (Array.isArray(res.message)) {
          const warning = (
            <ul>
              {res.message.map((msg, key) => (
                <li key={key}>{msg}</li>
              ))}
            </ul>
          );
          this.setState(state => ({ ...state, warning, }));
          return null;
        }
        showNotification(res.message, 'warning');
        return null;
      })
      .catch(e => {
        console.error('spreadsheet update fail', e);
        showNotification('Something went wrong', 'warning');
      });
    return null;
  }

  calculateAllIbFee = () => {
    const { swapContracts, asianSwapContracts, vanillaContracts, fxContracts } = this.state;
    const sumIbFee = sum([...swapContracts, ...asianSwapContracts, ...vanillaContracts, ...fxContracts]
      .filter(({ status }) => !!status )
      .map(({ ib_fee }) => ib_fee));

    this.setState(state => ({
      ...state,
      sumIbFee,
    }));
  }

  calculateIbFee = (status, value) => {
    const { sumIbFee } = this.state;
    const result = status ? sumIbFee + value : sumIbFee - value;
    this.setState(state => ({
      ...state,
      sumIbFee: result > 0 ? result : 0,
    }))
  }

  render() {
    const {
      swapContracts,
      asianSwapContracts,
      vanillaContracts,
      fxContracts,
      load,
      warning: warnMsg,
      cqgAccounts,
      ibCounterparties,
      ibCounterparty,
      salesRepresentatives,
      salesRepresentative,
      additionalSalesCoverages,
      additionalSalesCoverage,
      sumIbFee
    } = this.state;

    if (load) return (<Loader text="Please wait..." />);

    const ibCounterpartyItems = ibCounterparties || [];
    let ibCounterpartyError = ibCounterparties.length > 1 ? '' : `error loading - selected Counterparty '${ibCounterparty}' `;
    if(sumIbFee > 0 && !ibCounterparty && !ibCounterpartyError) ibCounterpartyError = 'IB Counterparty cannot be left blank';

    const salesRepresentativeItems = salesRepresentatives || [];
    let salesRepresentativeError = salesRepresentatives.length > 1 ? '' : `error loading - selected Sale '${salesRepresentative}' `;
    if(!salesRepresentative && !salesRepresentativeError) salesRepresentativeError = 'Sales Representative cannot be left blank';

    const additionalSalesCoverageItems = additionalSalesCoverages || [];
    let additionalSalesCoverageError = additionalSalesCoverages.length > 1 ? '' : `error loading - selected Sale '${additionalSalesCoverage}' `;

    const cqgAccountItems = cqgAccounts || [];
    const cqgAccountsError = cqgAccountItems.length > 1 ? '' : `error loading - selected account '${this.state.cqgAccount}' `;
    if (cqgAccountItems.length == 0 && this.state.cqgAccount) {
      cqgAccountItems.push({id: 1, name: this.state.cqgAccount});
    }
    return (
      <SimpleForm
        toolbar={
          <SpreadSheetsToolbar
            disabled={this.props.isReadOnly || !this.isChanged()}
            onClick={this.handleSubmit.bind(this)}
            translate={this.translate}
          />
        }>
        {warnMsg ? (
          <Snackbar
            open={true}
            message={warnMsg}
            autoHideDuration={6000}
            onRequestClose={this.handleRequestClose.bind(this)}
            bodyStyle={style}
            contentStyle={{ color: '#856404' }}
          />
        ) : ( '' )}

        <TextField
          errorText={this.state.errors.title && this.translate('aor.validation.required')}
          type="text"
          value={this.state.title}
          onChange={this.handleInputChange('title')}
          floatingLabelText="Spread sheet name"
          disabled={this.props.isReadOnly}
          fullWidth
        />

        <ChipInput
          value={this.state.users}
          floatingLabelText="Users"
          openOnFocus={true}
          dataSource={this.state.userChipsSource}
          dataSourceConfig={{ text: 'email', value: 'id' }}
          onRequestAdd={this.handleAddChips('users')}
          onRequestDelete={this.handleDeleteChips('users')}
          onBeforeRequestAdd={this.handleBeforeAddChip('users')}
          disabled={this.props.isReadOnly}
          fullWidth
          fullWidthInput
          listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
        />

        <ChipInput
          value={this.state.groups}
          floatingLabelText="User Groups"
          openOnFocus={true}
          dataSource={this.state.groupChipsSource}
          dataSourceConfig={{ text: 'name', value: 'id' }}
          onRequestAdd={this.handleAddChips('groups')}
          onRequestDelete={this.handleDeleteChips('groups')}
          onBeforeRequestAdd={this.handleBeforeAddChip('groups')}
          disabled={this.props.isReadOnly}
          fullWidth
          fullWidthInput
          listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
        />

        <SelectField
          fullWidth
          errorText={cqgAccountsError}
          name="cqgAccount"
          onChange={this.handleSelectChange('cqgAccount')}
          floatingLabelText="CQG Account"
          disabled={this.props.isReadOnly}
          value={this.state.cqgAccount}
        >
          {cqgAccountItems.map(x => (
            <MenuItem key={x.name} value={x.name} primaryText={x.name}/>
          ))}
        </SelectField>

        <SelectField
          fullWidth
          errorText={ibCounterpartyError}
          name="ibCounterparty"
          onChange={this.handleSelectChange('ibCounterparty')}
          floatingLabelText="IB Counterparty"
          disabled={this.props.isReadOnly}
          value={this.state.ibCounterparty}
        >
          {ibCounterpartyItems.map(x => (
            <MenuItem key={x.id} value={x.id} primaryText={x.name}/>
          ))}
        </SelectField>

        <SelectField
          fullWidth
          errorText={salesRepresentativeError}
          name="salesRepresentative"
          onChange={this.handleSelectChange('salesRepresentative')}
          floatingLabelText="Sales Representative"
          disabled={this.props.isReadOnly}
          value={this.state.salesRepresentative}
        >
          {salesRepresentativeItems.map(x => (
            <MenuItem key={x.id} value={x.id} primaryText={x.name}/>
          ))}
        </SelectField>

        <SelectField
          fullWidth
          errorText={additionalSalesCoverageError}
          name="additionalSalesCoverage"
          onChange={this.handleSelectChange('additionalSalesCoverage')}
          floatingLabelText="Additional Sales Coverage"
          disabled={this.props.isReadOnly}
          value={this.state.additionalSalesCoverage}
        >
          {additionalSalesCoverageItems.map(x => (
            <MenuItem key={x.id} value={x.id} primaryText={x.name}/>
          ))}
        </SelectField>

        <SelectField
          fullWidth
          name="autoBook"
          onChange={this.handleSelectChange('autoBook')}
          floatingLabelText="Auto Book"
          disabled={this.props.isReadOnly}
          value={this.state.autoBook}
        >
          {this.state.autoBookOptions.map(x => (
            <MenuItem key={x.name} value={x.id} primaryText={x.name}/>
          ))}
        </SelectField>

        <Tabs>
          <Tab className="form-tab" label="Swap">
            <CommodityList
              handleCheckAllTableChange={this.handleCheckAllTableChange}
              handleCheckBoxTableChange={this.handleCheckBoxTableChange}
              handleInputTableChange={this.handleInputTableChange}
              handleFixedSpreadChange={this.handleFixedSpreadChange}
              commodities={swapContracts}
              disabled={this.props.isReadOnly}
              type="swapContracts"
              cqgAccounts={cqgAccountItems}
              handleSelectCommodityCqgAccChange={this.handleSelectCommodityCqgAccChange}
            />
          </Tab>
          <Tab className="form-tab" label="Asian Swap">
            <CommodityList
              handleCheckAllTableChange={this.handleCheckAllTableChange}
              handleCheckBoxTableChange={this.handleCheckBoxTableChange}
              handleInputTableChange={this.handleInputTableChange}
              handleFixedSpreadChange={this.handleFixedSpreadChange}
              commodities={asianSwapContracts}
              disabled={this.props.isReadOnly}
              type="asianSwapContracts"
              cqgAccounts={cqgAccountItems}
              handleSelectCommodityCqgAccChange={this.handleSelectCommodityCqgAccChange}
            />
          </Tab>
          <Tab className="form-tab" label="Vanilla">
            <CommodityList
              handleCheckAllTableChange={this.handleCheckAllTableChange}
              handleCheckBoxTableChange={this.handleCheckBoxTableChange}
              handleInputTableChange={this.handleInputTableChange}
              handleFixedSpreadChange={this.handleFixedSpreadChange}
              commodities={vanillaContracts}
              disabled={this.props.isReadOnly}
              type="vanillaContracts"
              cqgAccounts={cqgAccountItems}
              handleSelectCommodityCqgAccChange={this.handleSelectCommodityCqgAccChange}
            />
          </Tab>
          <Tab className="form-tab" label="FX">
            <CommodityList
              handleCheckAllTableChange={this.handleCheckAllTableChange}
              handleCheckBoxTableChange={this.handleCheckBoxTableChange}
              handleInputTableChange={this.handleInputTableChange}
              handleFixedSpreadChange={this.handleFixedSpreadChange}
              commodities={fxContracts}
              disabled={this.props.isReadOnly}
              type="fxContracts"
            />
          </Tab>
        </Tabs>

      </SimpleForm>
    );
  }
}
