import { DateInput, Filter, TextInput } from 'admin-on-rest';
import React from 'react';
import { formatDateInput } from '../utils/dates';

const EmailFilters = props => (
  <Filter {...props}>
    <TextInput
      label="Search (name, subject)"
      source="_q"
      alwaysOn
      options={{ style: { width: '300px' } }}
    />
    <TextInput
      source="name"
    />
    <TextInput
      source="subject"
    />
    <TextInput
      source="text"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
    />
  </Filter>
);

export default EmailFilters;
