import { UPDATE } from 'admin-on-rest';

import { OTC_SEND, OTC_RELEASE, OTC_CANCEL } from './OTCActions';

export const OTCSendAction = (id, data, basePath) => ({
  type: OTC_SEND,
  payload: { id, data, basePath },
  meta: { resource: 'otc_confirmation', fetch: UPDATE, cancelPrevious: false },
});

export const OTCReleaseAction = (id, sendWithDocusign, emailSubject) => {
  if (id) {
    return {
      type: OTC_RELEASE,
      payload: { id, sendWithDocusign, emailSubject },
    };
  }
  return null;
};

export const OTCCancelAction = envelopeId => ({
  type: OTC_CANCEL,
  payload: { envelopeId },
});
