import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  Datagrid,
  List,
  refreshView,
  Responsive,
  SimpleList,
  TextField,
  translate,
  EditButton,
} from 'admin-on-rest';
import Seo from '../components/common/Seo';
import { datagridDefaultStyles } from '../theme/styles';

const ThemeConfigsList = props => (
  <Fragment>
    <Seo title={'Theme config'} />
    <List {...props}
      sort={{ field: 'name', order: 'DESC' }}
      perPage={25}
      refreshView={true}
      title='Theme config'
    >
      <Responsive
        small={
          <SimpleList
            primaryText={r => r.name}
            secondaryText={r => r.definition}
          />
        }
        medium={
          <Datagrid
            bodyOptions={{ showRowHover: true }}
            options={{ height: '70vh' }}
            styles={datagridDefaultStyles}
          >
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="created_at" />
            <TextField source="updated_at" />
            <EditButton />
          </Datagrid>
        }
      />
    </List>
  </Fragment>
);

const enhance = compose(
  translate,
  connect(null, {
    refreshView,
  }),
);

export default enhance(ThemeConfigsList);
