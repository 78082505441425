import React, { PureComponent } from 'react';

import {
  ReferenceInput,
  SelectArrayInput,
  ReferenceArrayInput,
  FormTab,
  TabbedForm,
  ShowButton,
  ListButton,
  RefreshButton,
  Edit,
  TextInput,
  BooleanInput,
  SelectInput,
  required,
  email,
  minLength,
  maxLength,
  translate,
  AutocompleteInput,
} from 'admin-on-rest';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { CardActions } from 'material-ui/Card';
import AutoComplete from 'material-ui/AutoComplete';
import UserApproveButton from './UserApproveButton';
import UserRejectButton from './UserRejectButton';
import { ChannelSelect, SelectArrayInputWrapper } from './components';
import { slectInputOptions, statusesSource } from './helpers';

const RECORD_FORM = 'record-form';

const UserTitle = translate(({ record }) =>
  <span>{ record.email }</span>);

const UserEditActions = ({ basePath, data, refresh }) => (
  <CardActions
    className="CardActions"
  >
    <UserApproveButton
      record={data}
    />
    <UserRejectButton
      record={data}
    />
    <ShowButton
      basePath={basePath}
      record={data}
    />
    <ListButton
      basePath={basePath}
    />
    <RefreshButton
      refresh={refresh}
    />
  </CardActions>
);

const validateMinLength = minLength(2);
const validateMaxLength = maxLength(100);
const defaultStyle = { width: '50%' };
class UserEdit extends PureComponent {
  render() {
    return (
      <Edit {...this.props}
        title={<UserTitle />}
        actions={<UserEditActions />}
      >
        <TabbedForm>
          <FormTab label="users.edit.info">
            <TextInput
              source="email"
              type="email"
              validate={[required, email]}
              elStyle={defaultStyle}
            />
            <TextInput
              source="firstName"
              label="First Name"
              validate={[required, validateMinLength, validateMaxLength]}
              elStyle={defaultStyle}
            />
            <TextInput
              source="lastName"
              label="Last Name"
              validate={[required, validateMinLength, validateMaxLength]}
              elStyle={defaultStyle}
            />
            <TextInput
              label="Slack Id"
              source="slackId"
              elStyle={defaultStyle}
            />
            <SelectInput
              source="status"
              choices={statusesSource}
              validate={[required]}
              elStyle={defaultStyle}
            />
            <BooleanInput
              label = "Additional terms"
              source="additionalTerms"
            />
            <ReferenceInput filter='noFilter' key="ruleId" source="ruleId" reference="volatility_rules_names" label="Volatility Rule" validate={[required]}>
              <SelectInput
                optionText="name"
                optionValue="id"
                options={slectInputOptions}
                style={defaultStyle}
              />
            </ReferenceInput>
          </FormTab>
          <FormTab label="users.edit.permission">
            <ReferenceArrayInput source="dashboardIds" reference="dashboards" label="Personnel dashboards" allowEmpty>
              <SelectArrayInput
                optionText="name"
                optionValue="id"
                elStyle={defaultStyle}
                options={slectInputOptions}
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput source="roleIds" reference="roles" label="Roles" allowEmpty>
              <SelectArrayInput
                optionText="name"
                optionValue="id"
                elStyle={defaultStyle}
                options={slectInputOptions}
              />
            </ReferenceArrayInput>
            <ReferenceInput
              key="userGroupId"
              source="userGroupId"
              reference="user_groups"
              label="Group"
              allowEmpty
              sort={{ field: 'name', order: 'ASC' }}
              filterToQuery={searchText => ({ _q: searchText })}
              filter='noFilter'
            >
              <AutocompleteInput
                optionText={r => `${r.name} ${r.code}`}
                optionValue="id"
                elStyle={defaultStyle}
                options={
                  {
                    ...slectInputOptions,
                    'fullWidth': true,
                    'filter': AutoComplete.levenshteinDistance,
                  }
                }/>
            </ReferenceInput>
            <ReferenceArrayInput source="groupsIds" reference="user_dashboard_groups" label="Dashboard Groups" allowEmpty>
              <SelectArrayInputWrapper email={this.props.email} />
            </ReferenceArrayInput>
            <ReferenceInput perPage="1e9" filter='noFilter' key="spreadsheetId" source="spreadsheetId" reference="spreadsheets" label="Spread sheet" allowEmpty>
              <SelectInput
                optionText="title"
                optionValue="id"
                elStyle={defaultStyle}
                options={slectInputOptions}
              />
            </ReferenceInput>
            <ReferenceInput filter='noFilter' key="channelId" source="channelId" reference="slack_channels" label="Slack Team" allowEmpty>
              <ChannelSelect />
            </ReferenceInput>
            <ReferenceArrayInput source="tradingTeamIds" reference="trade_teams" label="Trading Team" allowEmpty>
              <SelectArrayInput
                optionText="name"
                optionValue="id"
                elStyle={defaultStyle}
                options={slectInputOptions}
              />
            </ReferenceArrayInput>
            <ReferenceInput
              perPage="1e9"
              filter='noFilter'
              key="pricingTemplateSpId"
              source="pricingTemplateSpId"
              reference="sp_pricing_template"
              label="SP Pricing Template"
              allowEmpty
            >
              <SelectInput
                optionText="name"
                optionValue="id"
                elStyle={defaultStyle}
                options={slectInputOptions}
              />
            </ReferenceInput>
            <BooleanInput
              label="Greeks Display"
              source="greeks"
            />
          </FormTab>
        </TabbedForm>
      </Edit>
    );
  }
}

const selector = formValueSelector(RECORD_FORM);

const enhance = compose(
  connect((state) => ({
    email: selector(state, 'email'),
  })),
);


export default enhance(UserEdit);
