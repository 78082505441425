import getMuiTheme from 'material-ui/styles/getMuiTheme';

const getColor = ENV => (ENV !== 'prod' ? '#1A237E' : '#0bb2dd');

export const agileTheme = {
  palette: {
    primary1Color: getColor(process.env.REACT_APP_ENV),
  },
  drawer: {
    width: 280,
  },
};


const theme = getMuiTheme(agileTheme);
export default theme;
