import React from 'react';
import { TextField } from 'material-ui';
import ChipInput from 'material-ui-chip-input';
import { ContentRemove } from 'material-ui/svg-icons';

const UnderlyingList = ({
  index,
  translate,
  translationsPrefix,
  group,
  pricingCards,
  handleChangeGroup,
  errPricingCards,
  errGroups,
  handleCloseGroup,
  disabled,
  indexLastGroup,
  chips: {
    onBeforeRequestAdd,
    onRequestAdd,
    onRequestDelete,
  }}) => {

  return (
    <div className="group-item">

      {indexLastGroup === 0 && indexLastGroup === index ? null : <ContentRemove className="close-group" onClick={() => handleCloseGroup(index)} />}

      <TextField
        floatingLabelText={translate(`${translationsPrefix}.group`)}
        fullWidth
        name="groupName"
        type="text"
        value={group.groupName === 'Ungrouped' ? '' : group.groupName}
        onChange={handleChangeGroup}
        maxLength="200"
        disabled={disabled}
        multiLine={true}
        errorText={errGroups && translate('pricingTemplates.notification.group_error')}
      />

      <ChipInput
        fullWidth
        fullWidthInput
        alwaysShowPlaceholder
        listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
        value={group.pricingCardsForChips}
        openOnFocus={true}
        dataSourceConfig={{ text: 'description', value: 'id' }}
        floatingLabelText={translate(`${translationsPrefix}.pricingCard`)}
        dataSource={pricingCards}
        errorText={errPricingCards && translate('aor.validation.required')}
        onRequestAdd={onRequestAdd(index)}
        onRequestDelete={onRequestDelete(index)}
        onBeforeRequestAdd={onBeforeRequestAdd(index)}
        disabled={disabled}
      />
    </div>
  );
}

export default UnderlyingList;
