import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { List, ListItem } from 'material-ui/List';
import Checkbox from 'material-ui/Checkbox';
import Subheader from 'material-ui/Subheader';
import LinearProgress from 'material-ui/LinearProgress';
import Divider from 'material-ui/Divider';
import { fetchUser } from './../helpers';

const User = React.memo(({ userId = 0, onChange, checked }) => {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const user = await fetchUser(userId);

      if (user) {
        setEmail(user.email);
        setName(`${user.firstName} ${user.lastName}`);
        setLoading(false);
      }
    };

    if (userId) fetchData();
  }, []);

  const handleOnCheck = useCallback(() => {
    onChange(userId, !checked);
  }, [userId, checked]);

  return (
    <Fragment>
      <ListItem
        leftCheckbox={<Checkbox checked={checked} onCheck={handleOnCheck} />}
        primaryText={ loading ? <LinearProgress mode="indeterminate" /> : name }
        secondaryText={ loading ? null : `${userId} - ${email}`}
      />
      <Divider inset={true} />
    </Fragment>
  );
});

const UserList = ({ users, selected, onChange }) => (
  <List
    style={{
      height: '230px',
      overflowY: 'auto',
      // borderTop: '1px solid #d8d8d8',
      // borderBottom: '1px solid #d8d8d8',
      marginBottom: '20px',
    }}
  >
    <Subheader>Active users</Subheader>
    {users.map((userId) => <User key={`user-${userId}`} userId={userId} checked={selected.includes(userId)} onChange={onChange} />)}
  </List>
);

export default React.memo(UserList);
