import React from 'react';

import { ReferenceArrayInput, Edit, SimpleForm, TextInput, required, SelectArrayInput, minLength, maxLength } from 'admin-on-rest';
import { ONLY_MAREX_USERS } from '../constants';

const validateMinLengthToTwo = minLength(2);
const validateMaxLengthToHundred = maxLength(100);
const GroupsDashboardsEdit = props => (
  <Edit {...props}
    title="Dashboard Group"
  >
    <SimpleForm>
      <TextInput
        source="name"
        validate={[required, validateMinLengthToTwo, validateMaxLengthToHundred]}
        elStyle={{ width: '50%' }}
      />
      <TextInput
        source="description"
        validate={[required, validateMinLengthToTwo, validateMaxLengthToHundred]}
        elStyle={{ width: '50%' }}
      />
      <ReferenceArrayInput
        source="dashboard_ids"
        reference="dashboards"
        label="Dashboards"
        allowEmpty
        perPage={100000}
      >
        <SelectArrayInput
          optionText="name"
          optionValue="id"
          options={{ 'listStyle': { 'maxHeight': '30vh', 'overflow': 'auto' }, 'onBlur': () => {} }}
          elStyle={{ width: '50%' }}
          validate={[required]}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="users_ids"
        reference="users"
        label="Users"
        allowEmpty
        perPage="1e9"
        sort={{ field: 'email', order: 'ASC' }}
        filterToQuery={() => ({ _q: ONLY_MAREX_USERS })}
        filter={{ _q: ONLY_MAREX_USERS }}
      >
        <SelectArrayInput
          optionText="email"
          optionValue="id"
          options={{
            'listStyle': { 'maxHeight': '30vh', 'overflow': 'auto' },
            'onBlur': () => {},
            'newChipKeyCodes': [],
            'newChipKeys': [],
          }}
          elStyle={{ width: '50%' }}
          validate={[required]}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export default GroupsDashboardsEdit;
