import React from 'react';
import Toggle from 'material-ui/Toggle';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import { translate } from 'admin-on-rest';

class Toggles extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  onToggle = (ev, active) => {
    const { id, changeActive } = this.props;
    changeActive(id, active);
  }

  render() {
    const { status } = this.props;
    return (
      <Toggle
        label=""
        defaultToggled={status}
        onToggle={this.onToggle}
      />
    );
  }
}

Toggles.propTypes = {
  id: PropTypes.string,
  status: PropTypes.bool,
  sendManyAction: PropTypes.func,
  translate: PropTypes.func,
  changeActive: PropTypes.func,
};

const enhance = compose(
  translate,
);

export default enhance(Toggles);
