import { UPDATE } from 'admin-on-rest';

import { PDF_SEND_MANY } from './PDFAction';

const PDFSendManyAction = (data, sendWithoutPdf = false) => ({
  type: PDF_SEND_MANY,
  payload: { id: '', data, sendWithoutPdf },
  meta: { resource: 'pdfs', fetch: UPDATE, cancelPrevious: false },
});

export default PDFSendManyAction;
