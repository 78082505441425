import { BooleanField, BooleanInput, Datagrid, DateInput, Filter, List, RefreshButton, Responsive, SimpleList, TextField, TextInput, userLogout } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import React, { Fragment, Component } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { DateField, DisplayText } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateInput, formatDateTimeInput, dateParser } from '../utils/dates';
import FileUploadButton from './FileUploadButton';
import Seo from '../components/common/Seo';

const FileFilter = props => (
  <Filter {...props}>
    <TextInput
      label="files.list.search"
      source="_q"
      alwaysOn
    />
    <BooleanInput
      source="status"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
      parse={dateParser}
    />
  </Filter>
);

class FileList extends Component {
  FileAction({ refresh, filters, resource, showFilter, displayedFilters, filterValues }) {
    return (
      <CardActions>
        <FileUploadButton />
        {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' })}
        <RefreshButton refresh={refresh} />
      </CardActions>
    );
  }

  render() {
    return (
      <Fragment>
        <Seo title={'Files'} />
        <List {...this.props}
          actions={<this.FileAction />}
          filters={<FileFilter />}
          sort={{ field: 'created_at', order: 'DESC' }}
          perPage={50}
        >
          <Responsive
            small={
              <SimpleList
                primaryText={r => r.upload_message}
                secondaryText={r => r.name}
                tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
              />
            }
            medium={
              <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
                <TextField
                  source="id"
                />
                <DisplayText source="name" label="name" getText={({ record }) => record.driver || record.name} />
                <TextField
                  source="description"
                  sortable={false}
                />
                <BooleanField
                  source="status"
                />
                <TextField
                  source="updated_by"
                  sortable={false}
                />
                <DateField
                  formatDate={formatDateTimeInput}
                  source="created_at"
                  showTime
                />
              </Datagrid>
            }
          />
        </List>
      </Fragment>
    );
  }
}

export default connect(undefined, { userLogout })(FileList);
