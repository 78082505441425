import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MuiAppBar from 'material-ui/AppBar';
import { userLogout as userLogoutAction } from 'admin-on-rest';
import { push as pushAction } from 'react-router-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';
import {
  Menu,
  MenuItem,
  Divider,
  Subheader,
  List,
  ListItem,
} from 'material-ui';
import Popover from 'material-ui/Popover/Popover';
import compose from 'recompose/compose';
import NotifyBell from './notificationCenter/components/NotifyBell';

export const TOGGLE_SIDEBAR = 'AOR/TOGGLE_SIDEBAR';

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
});

const Welcome = (props) => {
  const { admin: { id, name, email, role }, userLogout, push } = props;
  if (!name) return null;

  const [open, toogleOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    toogleOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    toogleOpen(!open);
  };

  const handleLogout = () => {
    userLogout();
  };

  const handleProfile = async () => {
    await push('/');
    await push(`/admins/${id}`);
    toogleOpen(!open);
  };

  const handleChangePassword = async () => {
    await push('/');
    await push(`/admin_change_password/${id}`);
    toogleOpen(!open);
  };

  return (
    <Fragment>
      <div className="welcome-bar" onClick={handleClick}>Welcome, <span>{name}</span></div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onRequestClose={handleRequestClose}
      >
        <Menu>
          <List>
            <Subheader>General:</Subheader>
            <ListItem
              primaryText={name}
              secondaryText={`${email} - ${role}`}
            />
          </List>
          <Divider />
          <MenuItem primaryText="Profile" onClick={handleProfile} />
          <MenuItem primaryText="Change Password" onClick={handleChangePassword} />
          <MenuItem primaryText="Sign out" onClick={handleLogout} />
        </Menu>
      </Popover>
    </Fragment>
  );
};

const AppBar = (props) => {
  const { title, toggleSidebar: toggleSidebarProps, onToggle, style, current, userLogout, push } = props;
  const toggle = () => {
    toggleSidebarProps();
    onToggle();
  };

  return (
    <MuiAppBar title={title} onLeftIconButtonTouchTap={toggle} className="app-header" style={style} >
      <NotifyBell />
      <Welcome admin={current} userLogout={userLogout} push={push} />
    </MuiAppBar>
  );
};

AppBar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  current: state.currentAdminReducer,
});
const enhance = compose(
  muiThemeable(),
  connect(mapStateToProps, {
    toggleSidebar,
    userLogout: userLogoutAction,
    push: pushAction,
  }),
);

export default enhance(AppBar);
