import React, { Component } from 'react';
import compose from 'recompose/compose';
import { formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { CardActions } from 'material-ui/Card';
import {
  LongTextInput,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  BooleanInput,
  minLength,
  translate,
  ListButton,
  DeleteButton,
  RefreshButton,
} from 'admin-on-rest';
import PositionFooter from './PositionFooter';
import { RichTextInput } from '../helpers';
import { STATIC_PAGE_OPTIONS } from './components';

const RECORD_FORM = 'record-form';
const selector = formValueSelector(RECORD_FORM);

const StaticPageTitle = translate((props) => {
  const { record } = props;
  return record ? <span>{translate('staticPages.edit.title', { smart_count: 1 })} {record.name}</span> : null;
});

const validateMinLengthToTwo = minLength(2);

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const StaticPageEditActions = ({ basePath, data }) => {
  const isTerms = data && data.defaultFooter;
  return (
    basePath && data ?
      <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} />
        {!isTerms && <DeleteButton basePath={basePath} record={data} />}
        <RefreshButton />
      </CardActions>
      : null
  );
};

const Action = (props) => {
  const { match: { path } } = props;
  if (path === '/static_pages/create') {
    return (<Create {...props}></Create>);
  }

  return (
    <Edit {...props}
      actions={<StaticPageEditActions />}
      title={<StaticPageTitle />}
    ></Edit>
  );
};

class StaticPageForm extends Component {
  componentWillReceiveProps({ title }) {
    const { title: titleOld, change: changeField, seo: seoField } = this.props;

    const _seoOld = this.parseTitle(titleOld);
    const _seoNew = this.parseTitle(title);
    if (_seoOld === (seoField || '') && _seoOld !== _seoNew) {
      changeField(RECORD_FORM, 'seo', _seoNew);
    }
  }

  parseTitle(title) {
    return (title || '')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase()
      .replace(/&/g, '-and-')
      .replace(/[^a-z0-9-]/g, '')
      .replace(/-+/g, '-')
      .replace(/^-*/, '')
      .replace(/-*$/, '');
  }

  render() {
    const { position } = this.props;
    return (
      <Action {...this.props}>
        <SimpleForm redirect="list" submitOnEnter={true}>
          <TextInput
            source="title"
            validate={[required, validateMinLengthToTwo]}
          />
          <TextInput
            source="seo"
            validate={[required, validateMinLengthToTwo]}
          />
          <LongTextInput
            source="description"
            validate={[required, validateMinLengthToTwo]}
          />
          <RichTextInput
            source="content"
            validate={[required, validateMinLengthToTwo]}
          />
          <SelectInput
            source="position"
            choices={STATIC_PAGE_OPTIONS}
            validate={[required]}
          />
          { position === 'footer' && <PositionFooter {...this.props}/>}
          <BooleanInput
            source="status"
          />
        </SimpleForm>
      </Action>
    );
  }
}

const enhance = compose(
  connect(state => ({
    position: selector(state, 'position'),
    title: selector(state, 'title'),
    seo: selector(state, 'seo'),
  }), { change }),
);

export default enhance(StaticPageForm);
