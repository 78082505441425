import { Admin, adminReducer, Delete, Resource } from 'admin-on-rest';
import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import reduceReducers from 'reduce-reducers';
import { AccumulatorDescriptionList, AccumulatorDescriptionShow } from './accumulatorDescription';
import {
  PricingTemplatesShow,
  PricingTemplatesList,
  PricingTemplateCardsEdit,
  PricingTemplateCardsList,
} from './pricingTemplates';
import { PricingCardsList, PricingCardCreate, PricingCardEdit } from './pricingCards';
import { PricingLogsList } from './pricingLogs';
import { AdminCreate, AdminEdit, AdminList } from './admins';
import authClient from './authClient';
import { CalculateHistoryList, CalculateHistoryShow } from './calculateHistory';
import { Calculator } from './calculator';
import { checkStatementsArchivesList } from './checkStatementsArchives';
import { ClientDatabaseList } from './clientDatabase';
import { clientDataBaseReducer } from './clientDatabase/reducer';
import { structuredProductsPricingReducer } from './pricingStructuredProducts/reducer';
import {
  CommodityContractsCreate,
  CommodityContractsEdit,
  SwapContractsList,
  VanillaContractsCreate,
  VanillaContractsEdit,
  VanillaContractsList,
} from './commodityContracts';
import ScrollToTop from './components/common/ScrollToTop';
import Menu from './components/menu';
import { CronList } from './crons';
import { Dashboard } from './dashboard';
import { DashboardRequestList } from './dashboardRequests';
import { DashboardCreate, DashboardEdit, DashboardList } from './dashboards';
import { DatasetsList } from './datasets';
import { EmailCreate, EmailEdit, EmailList } from './emails';
import { FileList } from './files';
import { GroupsDashboardsCreate, GroupsDashboardsEdit, GroupsDashboardsList } from './groupsDashboards';
import { en } from './i18n';
import { Login } from './login';
import { LogList } from './logs';
import { MailList } from './mails';
import { MarginCallsEdit, MarginCallsList } from './marginCalls';
import { MarketDataList, MarketDataShow } from './marketData';
import AgileLayout from './layout/AgileLayout';
import { currentAdminReducer } from './layout/reducer';
import { CqgProxyList, CqgProxyCreate, CqgProxyEdit } from './cqgProxies';
import { CqgAccountsToSalesList, CqgAccountsToSalesCreate, CqgAccountsToSalesEdit } from './cqgAccountsToSales';
import { NeonManagementList } from './neonManagement';
import { NeonOrdersList } from './neonOrders';
import { StructuredProductsPricingList } from './pricingStructuredProducts';
import {
  PricingTemplateStructuredProductsList,
  PricingTemplateStructuredProductsCreate,
  PricingTemplateStructuredProductsEdit,
} from './pricingTemplateStructuredProducts';
import { adminInjection, neonOrdersReducer } from './neonOrders/reducer';
import notificationReducer from './notificationCenter/reducer';
import { NotifyMeList } from './notifyMe';
import { OtcConfirmationList } from './otcConfirmation';
import { PDFHistoryList, PDFHistoryShow } from './pdfHistories';
import { AccumulatorStructuresList, AccumulatorUnderlyingDataList, AccumulatorUnderlyingsList } from './pricingConfig';
import { ConfigList, StructuresList, UnderlyingDataList, VolatilityShiftList } from './pricingConfigEq';
import { PricingHistoryList, PricingHistoryShow } from './pricingHistories';
import { PricingList, PricingShow } from './pricings';
import { RatesList, RatesShow } from './rates';
import { RefreshDashboardList } from './refreshDashboards';
import restClient from './restClient';
import { RoleList } from './roles';
import sagas from './sagas';
import { SalesTeamsList } from './salesTeams';
import { TradeTeamsList } from './tradeTeams';
import {
  SpreadSheetsCreate,
  SpreadSheetsEdit,
  SpreadSheetsHistory,
  SpreadSheetsList,
  SpreadSheetsShow,
} from './spreadSheets';
import { SSIList } from './SSI';
import { StatementHistoryList, StatementHistoryShow } from './statementHistories';
import { StatementCreate, StatementEdit, StatementList } from './statements';
import { StaticPageForm, StaticPageList } from './staticPages';
import theme from './theme/agile';
import { UserCreate, UserEdit, UserList, UserShow } from './users';
import { UsersHistories } from './usersHistories';
import { CreateFxCommodity, EditFxCommodity, FxCommodityList } from './fxCommodities';
import { VanillaDescriptionList, VanillaDescriptionShow } from './vanillaDescription';
import { VolatilityBucketCreate, VolatilityBucketEdit, VolatilityBucketList } from './volatilityBucket';
import { VolatilityConfigCreate, VolatilityConfigEdit, VolatilityConfigList } from './volatilityConfig';
import { VolatilitySurfaceList, VolatilitySurfaceShow } from './volatilitySurface';
import { NeonOrdersHistories } from './neonOrdersHistories';
import orderHistoryReducer from './neonOrdersHistories/reducer';
import { livePositionsReducer } from './livePosition/reducer';
import { SftpAccountsList, SftpAccountsCreate, SftpAccountsEdit } from './sftpAccounts';
import { APP_TITLE } from './constants';
import { CustomersList, CustomerEdit, CustomerCreate } from './customers';
import { ThemeTemplatesList, ThemeTemplateEdit } from './themeTemplates';
import { LocationsList, LocationEdit, LocationCreate } from './locations';
import { UserGroupList, UserGroupEdit, UserGroupCreate } from './userGroups';
import { ThemesList, ThemesCreate, ThemesEdit } from './agileThemes';
import { ThemeConfigsList, ThemeConfigsEdit } from './themeConfig';
import { DomainsList, DomainEdit, DomainCreate } from './domains';
import { AdminRolesList, AdminRolesForm } from './adminRoles';
import { AdminChangePassword } from './adminChangePassword';
import { IceAccountsList, IceAccountsEdit } from './iceAccounts';
import { GSAccountsList, GSAccountsEdit } from './gsAccounts';
import { client as apolloClient } from './apollo';
import { Settings } from './settings';
import { subscriptionGraph } from './subscriptionGraph';
import { AuthClient } from './authClient/index';
import { SocketProvider } from './SocketProvider';
import { PricingCardViewTemplatesList } from './pricingCardViewTemplates';
import { PricingMiniTrialsList } from './pricingMiniTrials';
import SpInputsConfigList from './spScreenConfigurator/SpInputsConfig/SpInputsConfigList';
import SpLayoutsConfigList from './spScreenConfigurator/SpLayoutsConfig/SpLayoutsConfigList';
import SpWrappersConfig from './spScreenConfigurator/SpWrappersConfig/SpWrappersConfig';
import { LivePositionCreate, LivePositionList } from './livePosition';
import { MinifutureProductsList, MinifutureProductsShow } from './minifutureProducts';
import MinifutureProductHistories from 'minifutureProducts/MinifutureProductHistories';

const createSpInputsConfigListRenderer = (title) => (props) => <SpInputsConfigList {...props} title={title} />;

class App extends Component {
  constructor(props) {
    super(props);

    this.title = APP_TITLE;
    this.locale = 'en';
    this.i18n = { en };
    this.i18n.en.aor.page.dashboard = 'Info Page';
  }

  render() {
    return (
      <HashRouter>
        <ScrollToTop>
          <SocketProvider>
            <ApolloProvider client={apolloClient}>
              <Admin
                appLayout={AgileLayout}
                dashboard={Dashboard}
                loginPage={Login}
                title={this.title}
                locale={this.locale}
                messages={this.i18n}
                customSagas={sagas}
                authClient={authClient}
                restClient={restClient}
                menu={Menu}
                customReducers={{
                  orderHistoryReducer,
                  currentAdminReducer,
                  notificationReducer,
                  neonOrdersReducer,
                  clientDataBaseReducer,
                  structuredProductsPricingReducer,
                  admin: reduceReducers(adminReducer, adminInjection, livePositionsReducer),
                }}
                theme={theme}
              >
                <Resource name="files" list={FileList} />
                <Resource name="accumulator_underlyings" list={AccumulatorUnderlyingsList} />
                <Resource name="accumulator_underlying_data" list={AccumulatorUnderlyingDataList} />
                <Resource name="accumulator_structures" list={AccumulatorStructuresList} />
                <Resource name="eq_underlying_data" list={UnderlyingDataList} />
                <Resource name="eq_structures" list={StructuresList} />
                <Resource name="eq_volatility_shift" list={VolatilityShiftList} />
                <Resource name="eq_config" list={ConfigList} />
                <Resource
                  name="sp_inputs_config"
                  list={createSpInputsConfigListRenderer('SP Payoff Inputs Configurator')}
                  remove={Delete}
                />
                <Resource name="sp_layouts_config" list={SpLayoutsConfigList} remove={Delete} />
                <Resource
                  name="sp_wrapper_inputs_config"
                  list={createSpInputsConfigListRenderer('SP Wrapper Inputs Configurator')}
                  remove={Delete}
                />
                <Resource name="sp_wrappers_config" list={SpWrappersConfig} remove={Delete} />
                <Resource name="pricings" list={PricingList} show={PricingShow} remove={Delete} />
                <Resource
                  name="pricing_histories"
                  options={{ label: 'Pricing History' }}
                  list={PricingHistoryList}
                  show={PricingHistoryShow}
                  remove={Delete}
                />
                <Resource name="datasets" list={DatasetsList} />
                <Resource
                  name="otc_confirmation"
                  options={{ label: 'OTC confirmation' }}
                  list={OtcConfirmationList}
                  remove={Delete}
                />
                <Resource name="users" list={UserList} create={UserCreate} edit={UserEdit} show={UserShow} />
                <Resource name="users_history" edit={UsersHistories} />
                <Resource name="admins" list={AdminList} create={AdminCreate} edit={AdminEdit} />
                <Resource
                  name="swap_commodities"
                  list={SwapContractsList}
                  create={CommodityContractsCreate}
                  remove={Delete}
                  edit={CommodityContractsEdit}
                />
                <Resource
                  name="vanilla_commodities"
                  list={VanillaContractsList}
                  create={VanillaContractsCreate}
                  remove={Delete}
                  edit={VanillaContractsEdit}
                />
                <Resource
                  name="fx_commodities"
                  list={FxCommodityList}
                  create={CreateFxCommodity}
                  remove={Delete}
                  edit={EditFxCommodity}
                />
                <Resource
                  name="spreadsheets"
                  list={SpreadSheetsList}
                  create={SpreadSheetsCreate}
                  remove={Delete}
                  edit={SpreadSheetsEdit}
                  show={SpreadSheetsShow}
                  history={SpreadSheetsHistory}
                />
                <Resource name="spreadsheets_history" edit={SpreadSheetsHistory} />
                <Resource name="roles" list={RoleList} />
                <Resource
                  name="dashboards"
                  list={DashboardList}
                  create={DashboardCreate}
                  edit={DashboardEdit}
                  remove={Delete}
                />
                <Resource
                  name="statements"
                  list={StatementList}
                  create={StatementCreate}
                  edit={StatementEdit}
                  remove={Delete}
                />
                <Resource
                  name="dashboard_requests"
                  options={{ label: 'Dashboard Requests' }}
                  list={DashboardRequestList}
                  remove={Delete}
                />
                <Resource
                  options={{ label: 'Dashboard Refresh' }}
                  name="refresh_dashboards"
                  list={RefreshDashboardList}
                  remove={Delete}
                />
                <Resource
                  name="dashboard_groups"
                  list={GroupsDashboardsList}
                  create={GroupsDashboardsCreate}
                  edit={GroupsDashboardsEdit}
                  remove={Delete}
                />
                <Resource name="pdfs" />
                <Resource
                  name="pdf_histories"
                  options={{ label: 'PDF History' }}
                  list={PDFHistoryList}
                  show={PDFHistoryShow}
                  remove={Delete}
                />
                <Resource
                  name="statement_histories"
                  options={{ label: 'Statement History' }}
                  list={StatementHistoryList}
                  show={StatementHistoryShow}
                />
                <Resource
                  name="pricing_templates"
                  options={{ label: 'Pricing Templates' }}
                  show={PricingTemplatesShow}
                  list={PricingTemplatesList}
                  remove={Delete}
                />
                <Resource
                  name="pricing_template_cards"
                  options={{ label: 'Pricing Template Cards' }}
                  edit={PricingTemplateCardsEdit}
                  list={PricingTemplateCardsList}
                />
                <Resource
                  name="pricing_cards"
                  options={{ label: 'Pricing Cards' }}
                  list={PricingCardsList}
                  create={PricingCardCreate}
                  edit={PricingCardEdit}
                  remove={Delete}
                />
                <Resource
                  name="pricing_card_view_templates"
                  options={{ label: 'Pricing Card View Templates' }}
                  list={PricingCardViewTemplatesList}
                  remove={Delete}
                />
                <Resource
                  name="pricing_mini_trials"
                  options={{ label: 'Pricing Mini Trials' }}
                  list={PricingMiniTrialsList}
                />
                <Resource name="pricing_logs/pricing_mini_log" list={PricingLogsList} />
                <Resource name="pricing_logs/agile" list={PricingLogsList} />
                <Resource name="pricing_logs/bot" list={PricingLogsList} />
                <Resource name="pricing_logs/autocall" list={PricingLogsList} />
                <Resource name="pricing_logs/pricing_mini_ratios" list={PricingLogsList} />
                <Resource name="notify_me" list={NotifyMeList} />
                <Resource name="mails" list={MailList} />
                <Resource name="settings" list={Settings} />
                <Resource name="auth_client" list={AuthClient} />
                <Resource name="check_statements_archives" list={checkStatementsArchivesList} remove={Delete} />
                <Resource
                  name="static_pages"
                  options={{ label: 'Static Pages' }}
                  list={StaticPageList}
                  create={StaticPageForm}
                  edit={StaticPageForm}
                  remove={Delete}
                />
                <Resource name="sales_teams" options={{ label: 'Sales teams' }} list={SalesTeamsList} remove={Delete} />
                <Resource name="trade_teams" options={{ label: 'Trade teams' }} list={TradeTeamsList} remove={Delete} />
                <Resource name="user_dashboard_groups" />
                <Resource name="admin_notification_settings" />
                <Resource name="admin_params" />
                <Resource name="group_pdfs" />
                <Resource name="client_database" list={ClientDatabaseList} remove={Delete} />
                <Resource
                  name="accumulator_description"
                  options={{ label: 'Accumulator Description' }}
                  list={AccumulatorDescriptionList}
                  show={AccumulatorDescriptionShow}
                  remove={Delete}
                />
                <Resource
                  name="vanilla_description"
                  options={{ label: 'Vanilla Description' }}
                  list={VanillaDescriptionList}
                  show={VanillaDescriptionShow}
                  remove={Delete}
                />
                <Resource name="rates" options={{ label: 'Rates' }} list={RatesList} show={RatesShow} remove={Delete} />
                <Resource
                  name="volatility_surface"
                  options={{ label: 'Volatility Surface' }}
                  list={VolatilitySurfaceList}
                  show={VolatilitySurfaceShow}
                  remove={Delete}
                />
                <Resource
                  name="market_data"
                  options={{ label: 'Market Data' }}
                  list={MarketDataList}
                  show={MarketDataShow}
                  remove={Delete}
                />
                <Resource
                  name="calculate_history"
                  options={{ label: 'Calculate History' }}
                  list={CalculateHistoryList}
                  show={CalculateHistoryShow}
                  remove={Delete}
                />
                <Resource
                  name="volatility_bucket"
                  options={{ label: 'Volatility Bucket' }}
                  list={VolatilityBucketList}
                  create={VolatilityBucketCreate}
                  edit={VolatilityBucketEdit}
                  remove={Delete}
                />
                <Resource
                  name="volatility_config"
                  options={{ label: 'Volatility Config' }}
                  list={VolatilityConfigList}
                  create={VolatilityConfigCreate}
                  edit={VolatilityConfigEdit}
                  remove={Delete}
                />
                <Resource name="logs" options={{ label: 'logs' }} list={LogList} remove={Delete} />
                <Resource
                  name="email"
                  options={{ label: 'Email Templates' }}
                  list={EmailList}
                  edit={EmailEdit}
                  create={EmailCreate}
                  remove={Delete}
                />
                <Resource name="cron" options={{ label: 'Cron Jobs' }} list={CronList} remove={Delete} />
                <Resource
                  name="margin_calls"
                  options={{ label: 'Margin Calls' }}
                  list={MarginCallsList}
                  edit={MarginCallsEdit}
                  remove={Delete}
                />
                <Resource name="ssi" options={{ label: 'Upload SSI' }} list={SSIList} remove={Delete} />
                <Resource name="neon_management" options={{ label: 'NeonManagement' }} list={NeonManagementList} />
                <Resource
                  name="cqg_proxies"
                  options={{ label: 'CqgProxies' }}
                  title={'cqg_proxies'}
                  list={CqgProxyList}
                  create={CqgProxyCreate}
                  edit={CqgProxyEdit}
                  remove={Delete}
                />
                <Resource
                  name="cqg_accounts_to_sales"
                  options={{ label: 'Cqg Accounts to Sales' }}
                  title={'cqg_accounts_to_sales'}
                  list={CqgAccountsToSalesList}
                  create={CqgAccountsToSalesCreate}
                  edit={CqgAccountsToSalesEdit}
                  remove={Delete}
                />
                <Resource name="cqg_accounts_to_sales/sales" />
                <Resource
                  name="ice_accounts"
                  options={{ label: 'ICE Accounts' }}
                  title={'ice_accounts'}
                  list={IceAccountsList}
                  edit={IceAccountsEdit}
                />
                <Resource
                  name="gs_trading_accounts"
                  options={{ label: 'GS Trading Accounts' }}
                  title={'gs_trading_accounts'}
                  list={GSAccountsList}
                  edit={GSAccountsEdit}
                />
                <Resource
                  name="neon_orders"
                  options={{ label: 'Approval List' }}
                  list={NeonOrdersList}
                  edit={NeonOrdersList}
                />
                <Resource
                  name="live_positions"
                  options={{ label: 'Live Positions' }}
                  list={LivePositionList}
                  create={LivePositionCreate}
                />
                <Resource
                  name="structured_products_pricing"
                  options={{ label: 'Structured Products Pricing' }}
                  list={StructuredProductsPricingList}
                />
                <Resource
                  name="sp_pricing_template"
                  options={{ label: 'Structured Products Pricing Template' }}
                  list={PricingTemplateStructuredProductsList}
                  create={PricingTemplateStructuredProductsCreate}
                  edit={PricingTemplateStructuredProductsEdit}
                  remove={Delete}
                />
                <Resource name="neon_orders_history" edit={NeonOrdersHistories} />
                <Resource
                  name="sftp_accounts"
                  edit={SftpAccountsEdit}
                  list={SftpAccountsList}
                  create={SftpAccountsCreate}
                  remove={Delete}
                />
                <Resource name="slack_channels" />
                <Resource name="volatility_rules_names" />
                <Resource name="commodities" />
                <Resource name="calculator" options={{ label: 'CommodityCalculator' }} list={Calculator} />
                <Resource
                  name="customers"
                  options={{ label: 'Customer' }}
                  list={CustomersList}
                  create={CustomerCreate}
                  remove={Delete}
                  edit={CustomerEdit}
                />
                <Resource
                  name="theme_templates"
                  options={{ label: 'Theme templates' }}
                  list={ThemeTemplatesList}
                  remove={Delete}
                  edit={ThemeTemplateEdit}
                />
                <Resource
                  name="locations"
                  options={{ label: 'Location' }}
                  list={LocationsList}
                  create={LocationCreate}
                  remove={Delete}
                  edit={LocationEdit}
                />
                <Resource
                  name="user_groups"
                  options={{ label: 'User Groups' }}
                  list={UserGroupList}
                  create={UserGroupCreate}
                  remove={Delete}
                  edit={UserGroupEdit}
                />
                <Resource name="themes" list={ThemesList} create={ThemesCreate} remove={Delete} edit={ThemesEdit} />
                <Resource name="theme_configs" list={ThemeConfigsList} edit={ThemeConfigsEdit} />
                <Resource name="domains" list={DomainsList} edit={DomainEdit} create={DomainCreate} remove={Delete} />
                {/* <Resource
                name="pricing_stress"
                list={PricingStressList}
                create={PricingStressCreate}
                remove={Delete}
                edit={PricingStressEdit}
              /> */}
                <Resource
                  name="admin_roles"
                  options={{ label: 'Admin Roles' }}
                  list={AdminRolesList}
                  create={AdminRolesForm}
                  edit={AdminRolesForm}
                  remove={Delete}
                />
                <Resource
                  name="admin_change_password"
                  edit={AdminChangePassword}
                  // list={AdminChangePasswordList}
                />
                <Resource 
                  name="minifuture_products"
                  options={{ label: 'Minifuture Products' }}
                  list={MinifutureProductsList}
                  show={MinifutureProductsShow}
                  remove={Delete}
                />
                <Resource name="minifuture_product_histories" edit={MinifutureProductHistories} />
                <Resource name="sub" list={subscriptionGraph} />
              </Admin>
            </ApolloProvider>
          </SocketProvider>
        </ScrollToTop>
      </HashRouter>
    );
  }
}

export default App;
