import React from 'react';
import { Filter, TextInput } from 'admin-on-rest';

const CustomerFilters = props => (
  <Filter {...props}>
    <TextInput
      label="customer.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="name"
    />
    <TextInput
      source="customer_code"
    />
    <TextInput
      source="group"
    />
  </Filter>
);

export default CustomerFilters;
