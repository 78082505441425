import React from 'react';
import Dropzone from 'react-dropzone';
import FlatButton from 'material-ui/FlatButton';
import FileUploadIcon from 'material-ui/svg-icons/file/file-upload';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import './uploadButton.css';

class UploadButton extends React.Component {
  state = {
    file: null,
  }

  onDrop = (file) => {
    const { onDrop } = this.props;
    this.setState({ file: file ? file[0] : null });
    onDrop(file);
  }

  render() {
    const { file } = this.state;
    const name = file && file.name ? file.name : null;
    const preview = file && file.preview ? file.preview : null;
    let accept = '.pdf,.docx,.doc';
    const { error, messageError = 'Required', accept: overrideAccept, className = 'Dropzone', label = 'Upload File', src, isName = true, showPreview = true, style = {} } = this.props;
    if (overrideAccept) {
      accept = overrideAccept;
    }
    return (
      <div>
        <Dropzone
          style={style}
          className={className}
          onDrop={this.onDrop}
          multiple={false}
          accept={accept}
        >
          <FlatButton
            primary={this.primary}
            disabled={this.disabled}
            label={label}
            icon={<FileUploadIcon/>}
          />
        </Dropzone>
        {((src || preview) && showPreview) ? <div><img width={80} src={src || preview} /> <span><CloseIcon className='deleteFile' onClick={this.onDrop.bind(this, null)}/></span></div> : null}
        { isName && name ? <div className='uploadedFile'> { name } <span><CloseIcon className='deleteFile' onClick={this.onDrop.bind(this, null)}/></span></div> : null }
        { error ? <div className='fileError'>{messageError} </div> : null }
      </div>
    );
  }
}

export default UploadButton;
