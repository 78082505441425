import React from 'react';
import { Create } from 'admin-on-rest';

import { FxCommodityForm, FxCommodityToolbar } from './components';

const CreateFxCommodity = props => (
  <Create {...props} title="Create FX Contract" >
    <FxCommodityForm toolbar={<FxCommodityToolbar />} />
  </Create>
);

export default CreateFxCommodity;
