import React from 'react';
import DownloadJson from '../../components/DownloadJson';
import { StructureDataRecord, WrapperDataRecord } from '../types';

interface DownloadRulesJsonProps {
  record?: StructureDataRecord | WrapperDataRecord;
}

const DownloadRulesJson: React.FC<DownloadRulesJsonProps> = ({ record = {} }) => {
  const { rules, rulesFileUploadStatus, rulesFilename } = record;

  if (!rules) {
    return <div style={{ width: '150px', color: 'red' }}>No Rules uploaded</div>;
  }
  return (
    <DownloadJson jsonFile={rules} fileStatus={rulesFileUploadStatus} fileName={rulesFilename || 'sp_rules.json'} />
  );
};

export default DownloadRulesJson;
