
import { CREATE } from 'admin-on-rest';

import { PDF_GROUP_GENERATE } from './PDFAction';

const PDFGroupGenerateAction = (data, basePath) => ({
  type: PDF_GROUP_GENERATE,
  payload: { data, basePath },
  meta: { resource: 'group_pdfs', fetch: CREATE, cancelPrevious: false },
});

export default PDFGroupGenerateAction;
