import React, { Component } from 'react';
import PropTypes from 'prop-types';

import JoditEditor from 'jodit-react';

class RichTextInput extends Component {
  state = {
    value: '',
  }

  config = {
    readonly: false,
  }

  onChange = (value) => {
    this.setState({ value });
    this.props.input.onChange(value);
  }

  componentDidMount() {
    const { value } = this.props.input;
    this.setState({ value });
  }

  render() {
    const { value } = this.state;

    return (
      <JoditEditor
        value={value}
        config={this.config}
        onChange={this.onChange}
      />
    );
  }
}

RichTextInput.propTypes = {
  addField: PropTypes.bool.isRequired,
  addLabel: PropTypes.bool.isRequired,
  input: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string,
};

RichTextInput.defaultProps = {
  addField: true,
  addLabel: true,
  options: {},
  record: {},
};

export default RichTextInput;
