import { Datagrid, DateInput, Filter, List, Responsive, SimpleList, TextField, TextInput } from 'admin-on-rest';
import React, { Fragment } from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput, formatDateInput, dateParser } from '../utils/dates';
import Seo from '../components/common/Seo';

const AccumulatorUnderlyingsFilter = props => (
  <Filter {...props}>
    <TextInput
      source="axe_buy"
    />
    <TextInput
      source="axe_sell"
    />
    <TextInput
      source="commodity_contract"
    />
    <TextInput
      source="currency"
    />
    <TextInput
      source="fut_trading_units"
    />
    <TextInput
      source="fut_val_pt"
    />
    <TextInput
      source="lot_size"
    />
    <TextInput
      source="quote_units"
    />
    <TextInput
      source="traded_currency"
    />
    <TextInput
      source="volatility_bucket"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
      parse={dateParser}
    />
  </Filter>
);

class AccumulatorUnderlyingsList extends React.Component {
  render() {
    return (
      <Fragment>
        <Seo title={'Accumulator Underlyings'} />
        <List {...this.props}
          filters={<AccumulatorUnderlyingsFilter />}
          sort={{ field: 'created_at', order: 'DESC' }}
          perPage={50}
        >
          <Responsive
            small={
              <SimpleList
                primaryText={r => `${r.value} ${r.currency}`}
                secondaryText={r => `${r.strike_level} ${r.barrier_level}`}
                tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
              />
            }
            medium={
              <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
                <TextField
                  source="axe_buy"
                />
                <TextField
                  source="axe_sell"
                />
                <TextField
                  source="commodity_contract"
                />
                <TextField
                  source="currency"
                />
                <TextField
                  source="fut_trading_units"
                />
                <TextField
                  source="fut_val_pt"
                />
                <TextField
                  source="lot_size"
                />
                <TextField
                  source="quote_units"
                />
                <TextField
                  source="traded_currency"
                />
                <TextField
                  source="volatility_bucket"
                />
                <DateField
                  formatDate={formatDateTimeInput}
                  source="created_at"
                />

              </Datagrid>
            }
          />
        </List>
      </Fragment>
    );
  }
}

export default AccumulatorUnderlyingsList;
