import { Datagrid, DeleteButton, List, TextField } from 'admin-on-rest';
import React from 'react';
import { DateField } from '../helpers';
import DownloadButton from '../helpers/DownloadButton';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';

const ArchivesList = props => (
  <List {...props}
    sort={{ field: 'created_at', order: 'DESC' }}
    perPage={50}
  >
    <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
      <TextField
        source="id"
      />
      <TextField
        source="name"
      />
      <DownloadButton {...props} source="link" label="download" />
      <DateField
        formatDate={formatDateTimeInput}
        source="created_at"
        showTime
      />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default ArchivesList;
