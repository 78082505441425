import { DateInput, Filter, List, NumberInput, RefreshButton, refreshView as refreshViewAction, RichTextField, showNotification as showNotificationAction, TextField, TextInput, translate } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Datagrid, DateField } from '../helpers';
import DeleteButton from '../helpers/DeleteButton';
import MultiplyChips from '../helpers/MultiplyChips';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput, formatDateInput } from '../utils/dates';
import CreateButton from './Create';
import EditButton from './Edit';

const ListFilter = props => (
  <Filter {...props}>
    <TextInput
      label="Search (name)"
      source="_q"
      alwaysOn
      options={{ style: { width: '300px' } }}
    />
    <NumberInput source="id" />
    <TextInput source="name" />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
    />
  </Filter>
);

const Actions = ({ resource, filters, displayedFilters, filterValues, showFilter }) => (
  <CardActions>
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    <RefreshButton />
    <CreateButton />
  </CardActions>
);

const ButtonsWrapper = props => (
  <div style={{ width: '220px' }} >
    <EditButton {...props} />
    <DeleteButton {...props} />
  </div>
);

class TradeTeamsList extends React.Component {
  constructor(props) {
    super(props);
    this.ids = [];
    this.state = { ids: [], rows: [] };
  }

  render() {
    return (
      <List {...this.props}
        sort={{ field: 'created_at', order: 'DESC' }}
        title="Trade Teams"
        perPage={50}
        actions={<Actions />}
        filters={<ListFilter />}
      >
        <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
          <TextField source="id" />
          <RichTextField source="name" label="Trade Team" />
          <MultiplyChips source="users" label="Users" sortable={false} />
          <DateField
            formatDate={formatDateTimeInput}
            source="created_at"
            showTime
          />
          <ButtonsWrapper />
        </Datagrid>
      </List>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(TradeTeamsList);
