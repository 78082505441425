import { DeleteButton, FunctionField, List, NumberField, Responsive, ShowButton, SimpleList, TextField } from 'admin-on-rest';
import React, { Component } from 'react';
import Moment from 'react-moment';
import { Datagrid, DateField } from '../helpers';
import { formatDateTimeInput, formatDateInput } from '../utils/dates';
import MarketDataActions from './MarketDataActions';
import MarketDataFilter from './MarketDataFilter';
import MarketDataLogo from './MarketDataLogo';
import MarketDataStructureButton from './MarketDataStructureButton';

class MarketDataList extends Component {
  constructor(props) {
    super(props);

    this.ids = [];
    this.state = { ids: [], rows: [] };
  }

  handleRowClick = async (rows) => {
    if (rows === 'all') {
      await this.setState({
        ids: this.ids,
        rows,
      });
      return;
    }
    if (rows === 'none') {
      await this.setState({
        ids: [],
        rows,
      });
      return;
    }
    await this.setState({
      ids: this.ids.filter((el, i) => rows.includes(i)),
      rows,
    });
  }

  render() {
    return (
      <List {...this.props}
        actions={<MarketDataActions disabled={() => !this.state.ids.length} loaded={() => this.state.ids} />}
        filters={<MarketDataFilter />}
        sort={{ field: 'created_at', order: 'DESC' }}
        perPage={50}
      >
        <Responsive
          small={
            <SimpleList
              primaryText={r => r.short_code}
              econdaryText={r => r.bloomberg_ticker}
              tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.updated_at}</Moment>}
            />
          }
          medium={
            <Datagrid
              headerOptions={{ adjustForCheckbox: true, displaySelectAll: true }}
              bodyOptions={{ displayRowCheckbox: true }}
              rowOptions={{ selectable: true }}
              options={{ multiSelectable: true, onRowSelection: this.handleRowClick }}
              selected={i => this.state.rows.includes(i)}
            >
              <TextField
                ref={el => (el ? this.ids.push(el.props.record.id) : null)}
                source="id"
              />
              <FunctionField
                source="logo"
                render={r => <MarketDataLogo record={r} />}
              />
              <TextField
                sortable={false}
                source="volatility_bucket"
              />
              <NumberField
                source="lot_size"
              />
              <TextField
                source="fut_trading_units"
              />
              <NumberField
                source="fut_val_pt"
              />
              <TextField
                source="quote_units"
              />
              <TextField
                source="currency"
              />
              <DateField
                formatDate={formatDateTimeInput}
                source="expiry_date"
                label="Structure Expiry"
                showTime
              />
              <TextField
                source="bloomberg_ticker"
              />
              <DateField
                formatDate={formatDateInput}
                source="contract_code"
                label="Contract Expiry"
                showTime
              />
              <TextField
                source="commodity_contract"
              />
              <DateField
                formatDate={formatDateTimeInput}
                source="updated_at"
                showTime
              />
              <FunctionField
                label="Disabled For Structure"
                source="structure"
                render={r => <MarketDataStructureButton record={r} />}
              />
              <ShowButton />
              <DeleteButton />
            </Datagrid>
          }
        />
      </List>
    );
  }
}

export default MarketDataList;
