import React, { Component } from 'react';
import ColumnCheckbox from './ColumnCheckbox';
import style from './style';

export default class DisplayCheckboxes extends Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.columns !== this.props.columns) {
      const elem = document.getElementsByClassName('body')[0];
      elem.scrollLeft = elem.scrollWidth + elem.scrollLeft;
      return true;
    }
    return false;
  }

  handleCheck = (event, isChecked) => {
    this.props.switchColumn(event.target.value, isChecked);
  };

  handleAll = (status) => {
    this.props.toggleAllColumns(status);
  }

  filter = (obj, predicate) => Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce((res, key) => res.concat(key), []);

  render() {
    const { columns } = this.props;
    const isChecked = this.filter(columns, item => item.status && !item.disabled);
    const btnTitle = isChecked.length === 0 ? 'Select all' : 'Unselect all';
    return (
      <div className="display-checkboxes">
        <span className="btn-select" onClick={() => this.handleAll(isChecked.length === 0)}>{btnTitle}</span>
        <div className="list-checkboxes">
          <span className="ntf-divider"></span>
          {Object.keys(columns).map(key => <ColumnCheckbox key={key} value={key} handleCheck={this.handleCheck} {...columns[key]} />)}
        </div>
        <style>{style}</style>
      </div>
    );
  }
}
