import { Datagrid, EditButton, FunctionField, List, Responsive, SimpleList, TextField } from 'admin-on-rest';
import React from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';
import EmailActions from './EmailActions';
import EmailFilters from './EmailFilters';

const formatText = str =>
  <p style={{ padding: '0', margin: '0', fontSize: '10px' }}>{str ? str.substring(0, 100) : ''}</p>;

const EmailList = props => (
  <List {...props}
    actions={<EmailActions />}
    filters={<EmailFilters />}
    sort={{ field: 'updated_at', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => r.name}
          secondaryText={r => r.subject}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.updated_at}</Moment>}
        />
      }
      medium={
        <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
          <TextField
            source="name"
          />
          <FunctionField
            source="subject"
            render={r => formatText(r.subject)}
          />
          <FunctionField
            source="text"
            render={r => formatText(r.text)}
          />
          <FunctionField
            source="html"
            render={r => formatText(r.html)}
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="updated_at"
            showTime
          />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default EmailList;
