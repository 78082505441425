import React, {
  useState,
  useEffect,
} from 'react';

import {
  SelectArrayInput,
} from 'admin-on-rest';
import { slectInputOptions } from '../helpers';
import { ONLY_MAREX_USERS } from '../../constants';

const isDisabled = (argEmail) => {
  const condition = new RegExp(ONLY_MAREX_USERS, 'gi');
  return !condition.test(argEmail);
};

const SelectArrayInputWrapper = ({ email: argEmail, ...record }) => {
  const [disabled, setDisabled] = useState(false);
  useEffect(() => { setDisabled(isDisabled(argEmail)); }, [argEmail]);

  return (
    <SelectArrayInput
      {...record}
      optionText="name"
      optionValue="id"
      elStyle={{ width: '50%' }}
      options={{
        ...slectInputOptions,
        disabled,
      }}
    />
  );
};

export default SelectArrayInputWrapper;
