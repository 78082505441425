import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import request from 'superagent';
import LinearProgress from 'material-ui/LinearProgress';
import DownloadIcon from 'material-ui/svg-icons/action/get-app';

import { connect } from 'react-redux';
import { translate, showNotification as showNotificationAction, refreshView as refreshViewAction } from 'admin-on-rest';
import UploadButton from '../components/UploadButton';
import path from '../path';

import OtcConfirmationSendIcon from './OTCSendIcon';
import { OTCSendAction } from './OTCSendAction';

import './tplDownloadButton.css';
import socket from 'ioClient';

const getPrefix = (min, max) => Math.floor(Math.random() * ((max - min) + 1)) + min;

class OTCCreateButton extends Component {
  defaultState = {
    open: false,
    load: false,
    file: null,
    prefix: getPrefix(5, 20),
    id: 99,
    errors: {
      file: false,
    },
  }

  constructor(props) {
    super(props);
    this.translate = this.props.translate;
    this.state = {
      ...this.defaultState,
    };
    this.socket = socket;
  }

  componentDidMount() {
    this.socket.on('to_pdf', (data) => {
      const { id, status, url } = data;
      if (this.state.id === id) {
        const { showNotification } = this.props;
        if (status === 'success') {
          this.setState(state => ({
            ...state,
            status: this.getSuccess(url),
          }));
        } else {
          this.setState(state => ({
            ...state,
            load: false,
          }));
          showNotification('OTCConfirmations.topdf.save_error', 'warning');
        }
      }
    });
  }

  getSuccess = url => <div className="tpl-btn">
    <a href={url} style={{ display: 'block' }}>
      <div >
        <div>Your PDF file succesfully created</div>
        <DownloadIcon color="#0bb2dd" style={{ width: '36px', height: '36px', margin: '5px 0 0' }} />
      </div>
    </a>
  </div>;

  changeDialogStatus = status => () => {
    this.setState({ ...this.defaultState, open: status });
  };

  onDrop = (file) => {
    const { showNotification } = this.props;
    const { prefix } = this.state;
    const status = this.getFirstStep(file ? file[0] : null);
    const id = `${prefix}-${new Date().getTime()}`;
    this.setState({ ...this.state, load: true, status, id }, () => {
      request
        .post(`${path}/admin/otc_confirmation/topdf`)
        .set('token', localStorage.getItem('session'))
        .field('file', file)
        .field('id', id)
        .then(() => {})
        .catch(() => {
          showNotification('OTCConfirmations.topdf.save_error', 'warning');
          this.setState(state => ({
            ...state,
            load: false,
          }));
        });
    });
  }

  getFirstStep = (file) => {
    const name = file && file.name ? file.name : null;
    return <div className='step-one'>
      <div className='uploadedFile'>
        <div className='uploadedFile-title'>Converting file {name} to the PDF</div><span><LinearProgress /></span>
      </div>
    </div>;
  }

  render() {
    const { open, errors, load, status } = this.state;
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label={this.translate('OTCConfirmations.topdf.buttonText')}
          icon={<OtcConfirmationSendIcon />}
          onClick={this.changeDialogStatus(true)}
        />
        <Dialog
          title={this.translate('OTCConfirmations.topdf.title')}
          modal={false}
          open={open}
          autoScrollBodyContent={true}
        >
          { !load ? <UploadButton
            onDrop={this.onDrop}
            accept='.tpl.html,.tpl,.html'
            className='Dropzone-center'
            error={errors.file}
          /> : status }

          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              label={this.translate('pdfs.dialog.btnCancel')}
              onClick={this.changeDialogStatus(false)}
              icon={<CancelIcon />}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

OTCCreateButton.propTypes = {
  record: PropTypes.object,
  sendAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    sendAction: OTCSendAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(OTCCreateButton);
