import React, { Fragment, useEffect, useState, useCallback } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  useNodesState,
  useEdgesState,
  Controls,
  MiniMap,
  // useReactFlow,
  // ReactFlowProvider,
} from 'react-flow-renderer';
import { Tabs, Tab } from 'material-ui/Tabs';
import Drawer from 'material-ui/Drawer';
import Toggle from 'material-ui/Toggle';
import FlatButton from 'material-ui/FlatButton';
import RefreshIcon from 'material-ui/svg-icons/navigation/refresh';
import ReactJson from 'react-json-view';
import { SectionBox } from './../../components';
import Seo from '../../components/common/Seo/';
import { useSocket } from './../../SocketProvider';
import {
  D3SimpleGauge,
  // D3IOGauge
} from './../components/D3Gauge';

import {
  D3SingleBar,
} from './../components/D3SingleBar';

import UserList from './../components/UserList';

import {
  nodeTypes,
  LAYOUT_DIRECTION_HORIZONTAL,
  LAYOUT_DIRECTION_VERTICAL,
  DEFAULT_LAYOUT_DIRECTION,
} from './../constants';
import { fetchLogs, buildGraph, getLayoutedElements } from './../helpers';
import './../style.css';

const style = {
  control: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginLeft: '15px',
  },
  controlChart: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    marginLeft: '15px',
  },
  statusUl: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    marginTop: '15px',
    marginRight: '35px',
  },
};

const getStats = (data, key) => data[key] ? data[key].toFixed(1) : 0;

const PriceUpdateMonitor = () => {
  const [rawGraph, setRawGraph] = useState(null);
  const [mode, setLiveMode] = useState(false);

  const [active, setActive] = useState(false);
  const [subSize, setSubSize] = useState(0);
  const [userTabs, setUserTabs] = useState({});
  const [graph, setGraph] = useState({});
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [direction, setDirection] = useState(DEFAULT_LAYOUT_DIRECTION);
  const [stats, setStats] = useState({});

  const [tabs, setTabs] = useState(0);
  const [cards, setCards] = useState(0);
  const [users, setUsers] = useState([]);
  const [selected, setSelectedUsers] = useState([]);
  // const { setViewport } = useReactFlow();

  const reDrawGraph = (responseGraph, activeUsers) => {
    if (!responseGraph) return null;
    const { nodes: layoutedNodes, edges: layoutedEdges, tabSize, cardSize, userList } = buildGraph(responseGraph, activeUsers);
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);

    setGraph(responseGraph);
    setTabs(tabSize);
    setUsers(userList);
    setCards(cardSize);
  };

  const refreshGraph = async () => {
    const { graph: responseGraph, subSize: size, userTabs: responseUserTabs } = await fetchLogs();
    if (responseGraph) {
      setSubSize(size);
      setUserTabs(responseUserTabs);
      setRawGraph(responseGraph);
      reDrawGraph(responseGraph, selected);
    }
  };

  const { message = {} } = useSocket('INFRSTRUCTURE');
  if (message && message.type === 'GRAPH' && mode) {
    message.type = null;
    refreshGraph();
  }
  useEffect(() => {
    if (message && message.type === 'METRICS' && message.data) {
      setStats(message.data);
    }
  }, [message]);

  useEffect(() => {
    (async () => {
      refreshGraph();
    })();
  }, []);

  const onConnect = useCallback((connection) => {
    setEdges((eds) => addEdge(connection, eds));
    // setViewport({ x: 0, y: 0, zoom: 1 }, { duration: 800 });
  }, []);

  const onPaneClick = () => setActive(false);
  const onNodeClick = (event, { id }) => setActive(id);

  const onLayout = useCallback((dir) => {
    setDirection(dir);
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      nodes,
      edges,
      dir,
    );

    setNodes([...layoutedNodes]);
    setEdges([...layoutedEdges]);
  }, [nodes, edges]);

  const liveModeStatusHandler = () => setLiveMode(!mode);

  const handleActiveUsers = useCallback((userId, status) => {
    const userList = status ? [...selected, userId].filter((id) => users.includes(id)) : [...selected].filter((id) => id !== userId);
    setSelectedUsers(userList);
    reDrawGraph(rawGraph, userList);
  }, [users]);

  return (
    <Fragment>
      <Seo title={`Subscription handler: ${subSize}`} />
      <SectionBox>
        <div>
          <div className="controls">
            <div style={{ ...style.control, width: '300px', borderRight: '2px solid #dbdbe7', paddingRight: '15px' }}>
              <UserList users={users} selected={selected} onChange={handleActiveUsers} />
            </div>
            <div style={{ ...style.control, width: '300px' }}>
              <div style={{ ...style.control, marginLeft: 0, width: '150px' }}>
                <Toggle
                  label="Live Update Graph"
                  toggled={mode}
                  onToggle={liveModeStatusHandler}
                />
              </div>
              {!mode && (<div style={{ ...style.control }}>
                <FlatButton
                  icon={<RefreshIcon />}
                  onClick={refreshGraph}
                />
              </div>)}
              <div style={{ marginTop: '15px' }}>
                <span>{direction}</span>
                <button onClick={() => onLayout(LAYOUT_DIRECTION_VERTICAL)}>vertical layout</button>
                <button onClick={() => onLayout(LAYOUT_DIRECTION_HORIZONTAL)}>horizontal layout</button>
              </div>
            </div>
            <div style={{ ...style.control, borderLeft: '2px solid #dbdbe7' }}>
              <div style={style.control}>
                <ul style={style.statusUl}>
                  <li><b>Redis Subs:</b> {subSize}</li>
                  <li><b>Cards:</b> {cards}</li>
                  <li><b>Users:</b> {users.length}</li>
                  <li><b>Tabs:</b> {tabs}</li>
                </ul>
              </div>
              {/* <D3IOGauge value={[5500, 1234]} maxValue={100000} label='i/o' units='req/s' style={style.control}/> */}
              <D3SimpleGauge value={getStats(stats, 'events')} maxValue={100000} label='PubSub events' units='req/s' round={0} style={style.controlChart} />
              <D3SingleBar value={getStats(stats, 'latency')} maxValue={1000} label='Price Latency' units='AVG ms' style={style.controlChart} />
              <D3SimpleGauge value={getStats(stats, 'cpu')} maxValue={100} label='CPU' units='%' round={0} style={style.controlChart} />
              <D3SimpleGauge value={getStats(stats, 'memory')} maxValue={2048} label='Memory' units='MB' round={0} style={style.controlChart} />
            </div>
          </div>
          <Tabs>
            <Tab className="form-tab-ui" label='Graph UI' >
              <div style={{ height: '750px', borderTop: '1px solid #dbdbe7' }}>
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  elementsSelectable={true}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  onPaneClick={onPaneClick}
                  onNodeClick={onNodeClick}
                  nodeTypes={nodeTypes}
                  zoomOnScroll={false}
                  fitView
                >
                  <MiniMap style={{ height: 120, border: '1px solid rgb(134, 163, 184)' }} zoomable pannable />
                  <Controls />
                  <Background />
                </ReactFlow>
                <Drawer width={500} openSecondary={true} open={!!active} >
                  <ReactJson src={{ key: active, ...graph[active] || {} }}
                    theme="monokai"
                    displayDataTypes={true}
                    collapseStringsAfterLength={80}
                    collapsed={3} />
                </Drawer>
              </div>
            </Tab>
            <Tab className="form-tab-json" label='JSON' >
              <div>
                <ReactJson src={{ subSize, userTabs, graph }}
                  theme="monokai"
                  displayDataTypes={true}
                  collapseStringsAfterLength={80}
                  collapsed={3} />
              </div>
            </Tab>
          </Tabs>
        </div>
      </SectionBox>
    </Fragment>
  );
};

export default PriceUpdateMonitor;
