import React, { Fragment, memo } from 'react';
import { Handle } from 'react-flow-renderer';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import { LAYOUT_DIRECTION_HORIZONTAL, NODE_TYPE_PRODUCER, NODE_TYPE_CARD } from '../constants';

const style = {
  titleStyle: {
    fontWeight: 900,
    fontSize: '25px',
  },
  headerStyle: (nodeType) => {
    if (nodeType === NODE_TYPE_PRODUCER) {
      return {
        background: 'rgb(232, 210, 166)',
      };
    }

    if (nodeType === NODE_TYPE_CARD) {
      return {
        background: '#86A3B8',
      };
    }
    return {
      background: 'rgba(0, 0, 0, 0.1)',
    };
  },
  fieldStyle: {
    fontSize: '20px',
  },
  fieldLabelStyle: {
    fontWeight: 900,
  },
  handle: {
    backgroundColor: '#00bcd4',
    height: '10px',
    width: '10px',
    borderRadius: 0,
  },
};

const getMapValue = (value) => {
  if (value === true) return 'TRUE';
  if (value === false) return 'FALSE';
  if (value === 'true') return 'TRUE';
  if (value === 'false') return 'FALSE';
  if (value === null) return 'NULL';
  if (typeof value === 'object') return 'Object{}';
  return value;
};

const Title = ({ label }) => (<Fragment>{label}</Fragment>);
const Field = ({ label, value, nodeType }) => {
  if (nodeType === 'GROUP') {
    return (<p style={style.fieldStyle}>{getMapValue(value)}</p>);
  }
  return (<p style={style.fieldStyle}><span style={style.fieldLabelStyle}>{label}:</span> {getMapValue(value)}</p>);
};

export default memo(({ data: { label, attributes, nodeType, direction }, isConnectable }) => (
  <Fragment>
    { nodeType !== 'PRODUCER' ? <Handle
      type="target"
      position={direction === LAYOUT_DIRECTION_HORIZONTAL ? 'left' : 'top'}
      style={style.handle}
      isConnectable={isConnectable}
    /> : null }
    <Card expanded={true}>
      <CardHeader
        style={style.headerStyle(nodeType)}
        titleStyle={style.titleStyle}
        title={<Title label={label} />}
      />
      <CardText>
        {Object.keys(attributes).map((key) => (<Field key={key} label={key} nodeType={nodeType} value={attributes[key]}/>))}
      </CardText>
    </Card>
    { nodeType !== 'TAB' ? <Handle
      type='source'
      position={direction === LAYOUT_DIRECTION_HORIZONTAL ? 'right' : 'bottom'}
      style={style.handle}
      isConnectable={isConnectable}
    /> : null }
  </Fragment>
));
