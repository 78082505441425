/* eslint-disable */
import {
  Datagrid,
  DeleteButton,
  EditButton,
  Filter,
  List,
  BooleanField,
  TextField,
  NumberField,
  TextInput,
} from 'admin-on-rest';
import React from 'react';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';

const ItemsFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="_q" alwaysOn />
  </Filter>
);

const CqgProxyList = props => (
  <List {...props} filters={<ItemsFilter />} sort={{ field: 'created_at', order: 'DESC' }} perPage={50}>
    <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="host" />
      <TextField source="user" />
      <TextField source="location" />
      <NumberField source="bandwidth" />
      <BooleanField label="Active" source="active" />
      <BooleanField label="Orders" source="orders" />
      <BooleanField label="On-Demand Subscriptions" source="onDemandSubscriptions" />
      <DateField formatDate={formatDateTimeInput} label="Updated" source="updatedAt" showTime />
      <DateField formatDate={formatDateTimeInput} label="Created" source="createdAt" showTime />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default CqgProxyList;
