import React, { Fragment, useState } from 'react';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import { FlatButton, RaisedButton } from 'material-ui';
import Dialog from 'material-ui/Dialog';
import { connect } from 'react-redux';
import {
  translate as adminTranslate,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
} from 'admin-on-rest';
import { compose } from 'redux';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import path from '../path';



const DeleteButton = ({
  record,
  translate,
  showNotification,
  refreshView,
  basePath
}) => {
  const { id, swap_commodity_id, agile_name} = record;
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true)

    const deleteRecord = { id, swap_commodity_id }

    fetch(`${path}/admin${basePath}/${record.id}`, {
      method: 'DELETE',
      body: JSON.stringify(deleteRecord),
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(() => {
        showNotification(translate('livePositions.delete.successMessage'));
      })
      .catch((e) => {
        console.error(e);
        showNotification(translate('livePositions.delete.failureMessage'), 'warning')
      });
    setLoading(false)
    setIsOpen(false);
    refreshView();
  };

  return (
    <Fragment>
      <FlatButton
        primary
        label={translate('livePositions.delete.buttonText')}
        icon={<DeleteIcon color="red"/>}
        style={{ float: 'left', minWidth: '30px', color: 'red' }}
        onClick={() => setIsOpen(true)}
      />
      <Dialog
        title={`${translate('livePositions.delete.title')}${agile_name}?`}
        modal={false}
        open={isOpen}
        repositionOnUpdate={false}
        style={{ paddingTop: 0 }}
        bodyStyle={{overflowY: 'auto'}}
      >
        <RaisedButton
          label={translate('livePositions.dialog.buttonCancel')}
          onClick={() => setIsOpen(false)}
          icon={<CancelIcon />}
        />
        <RaisedButton
          type="submit"
          style={{ marginLeft: '10px' }}
          label={translate('livePositions.dialog.buttonConfirm')}
          primary={true}
          disabled={loading}
          icon={<SubmitIcon />}
          onClick={handleSubmit}
        />
      </Dialog>
    </Fragment>

  )};


const enhance = compose(
  adminTranslate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);
export default enhance(DeleteButton);
