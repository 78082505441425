import React from 'react';

import { Filter, ReferenceInput, SelectInput } from 'admin-on-rest';

const VolatilityConfigFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Volatility Bucket"
      source="volatility_bucket_id"
      reference="volatility_bucket"
    >
      <SelectInput
        optionValue="id"
        optionText="name"
      />
    </ReferenceInput>
    <ReferenceInput
      label="Market Data"
      source="market_data_id"
      reference="market_data"
    >
      <SelectInput
        optionValue="id"
        optionText="bloomberg_ticker"
      />
    </ReferenceInput>
  </Filter>
);

export default VolatilityConfigFilter;
