import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { DEFAULT_ROUND, DEFAULT_INNER, DEFAULT_LINE_THICKNESS } from './constants';

const Arch = ({ fill = '#dbdbe7', round, inner, lineThickness }) => {
  const d = React.useMemo(() => d3.arc()
    .innerRadius(inner)
    .outerRadius(inner + lineThickness)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)
    .cornerRadius(round)(), []);
  return (
    <Fragment>
      <path
        d={d}
        fill={fill}
      />
    </Fragment>
  );
};

Arch.PropTypes = {
  fill: PropTypes.string.isRequired,
  round: PropTypes.number.isRequired,
  inner: PropTypes.number.isRequired,
  lineThickness: PropTypes.number.isRequired,
};

Arch.defaultProps = {
  fill: '#dbdbe7',
  round: DEFAULT_ROUND,
  inner: DEFAULT_INNER,
  lineThickness: DEFAULT_LINE_THICKNESS,
};

export default Arch;
