import FlatButton from 'material-ui/FlatButton';
import HistoryIcon from 'material-ui/svg-icons/action/assignment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const HistoryButton = ({ id }) => (
  <FlatButton
    primary
    icon={<HistoryIcon />}
    style={{ float: 'left', minWidth: '30px' }}
    containerElement={
      <Link to={`/neon_orders_history/${id}`} title={'History'}/>
    }
  />
);

HistoryButton.propTypes = {
  id: PropTypes.bool.isRequired,
};


export default HistoryButton;
