import { DateInput, Filter, NumberInput, ReferenceInput, SelectInput, TextInput } from 'admin-on-rest';
import React from 'react';
import { formatDateInput } from '../utils/dates';


const CalculateHistoryFilter = props => (
  <Filter {...props}>
    <TextInput
      label="calculateHistory.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="structure"
    />
    <TextInput
      source="type"
    />
    <TextInput
      source="bloomberg_ticker"
    />
    <TextInput
      source="commodity_contract"
    />
    <ReferenceInput
      label="User"
      source="user_id"
      reference="users"
      perPage="1e9"
    >
      <SelectInput
        optionValue="id"
        optionText="email"
      />
    </ReferenceInput>
    <NumberInput
      source="reference_price"
    />
    <NumberInput
      label="Accumulation/Strike Level"
      source="strike_level"
    />
    <NumberInput
      source="barrier_level"
    />
    <NumberInput
      source="notional"
    />
    <NumberInput
      source="price"
    />
    <NumberInput
      source="price_to_display"
    />
    <TextInput
      source="quote_units"
    />
    <TextInput
      source="quoted_currency"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
    />
  </Filter>
);

export default CalculateHistoryFilter;
