import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import React from 'react';
import AgileMenuItem from './AgileMenuItem';

const AgileMenu = ({ items, onMenuTap, ...rest }) => (
  items && items.length ?
    items.map((item) => {
      const key = snakeCase(item.title);
      return <AgileMenuItem key={key} {...item} onMenuTap={onMenuTap} {...rest} />;
    })
    : null
);

AgileMenu.propTypes = {
  items: PropTypes.array.isRequired,
  onMenuTap: PropTypes.func.isRequired,
};

export default AgileMenu;
