import { CREATE } from 'admin-on-rest';

import { STATEMENT_CREATE } from './PDFAction';

const StatementCreateAction = (data, basePath) => ({
  type: STATEMENT_CREATE,
  payload: { data, basePath },
  meta: { resource: 'statements', fetch: CREATE, cancelPrevious: false },
});

export default StatementCreateAction;
