import React from 'react';
import compose from 'recompose/compose';
import { Create, Toolbar, SaveButton, translate } from 'admin-on-rest';
import { PricingTemplateStructuredProductsForm } from './pricingTemplateStructuredProductsForm';

const CreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton redirect='list' submitOnEnter={true} />
  </Toolbar>
);

const PricingTemplateStructuredProductsCreate = props =>
  <Create title='spPricingTemplate.create' {...props}>
    <PricingTemplateStructuredProductsForm toolbar={<CreateToolbar />} />
  </Create>;


const enhance = compose(translate);
export default enhance(PricingTemplateStructuredProductsCreate);
