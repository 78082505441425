import React from 'react';
import Avatar from 'material-ui/Avatar';
import DashboarRequestIcon from 'material-ui/svg-icons/action/view-quilt';
import Moment from 'react-moment';
import Chip from 'material-ui/Chip';
import Subheader from 'material-ui/Subheader';

import { translate } from 'admin-on-rest';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import { List, ListItem } from 'material-ui/List';
import { blue200, green200 } from 'material-ui/styles/colors';

const avatar = r =>
  <Avatar src={`https://ui-avatars.com/api/?name=${r.user_first_name}+${r.user_last_name}&background=808080&size=32x32&font-size=0.33&color=ffffff`} />;

const info = r =>
  <Chip backgroundColor={r.status ? blue200 : green200}>{r.status ? 'Pending' : 'Approved'} <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment></Chip>;

class DashboardRequest extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  render() {
    const { dashboardRequests = [], count = 0, countApproved = 0, countPending = 0 } = this.props;
    return (
      <Card>
        <CardHeader
          title={<span><small style={{ fontSize: '12px', color: '#31708f' }}>Total:</small> {count} <small style={{ fontSize: '12px', color: '#4caf50' }}>Total Approved:</small> {countApproved} <small style={{ fontSize: '12px', color: '#f44336' }}>Total Pending:</small> {countPending}</span>}
          subtitle={this.translate('dashboard.dashboardRequest.subtitle')}
          avatar={<Avatar backgroundColor="#ff9800" icon={<DashboarRequestIcon />} />}
          actAsExpander={true}
          showExpandableButton={true}
        />
        <CardText
          expandable={true}
          actAsExpander={false}
          style={{ padding: '0' }}
        >
          <List style={{ height: 288, overflow: 'auto' }}>
            {dashboardRequests.length ? dashboardRequests.map(r => <ListItem
              href={`#/users/${r.user_id}/show`}
              leftAvatar={avatar(r)}
              primaryText={`${r.user_first_name} ${r.user_last_name}`}
              secondaryText={r.user_email}
              rightAvatar={info(r)}
              key={r.id}
            />) : <Subheader>{this.translate('dashboard.dashboardRequest.empty')}</Subheader>}
          </List>
        </CardText>
      </Card>
    );
  }
}

export default translate(DashboardRequest);
