import { useCallback, useMemo } from 'react';

const useUpdateTenorsCallback = (tenorList = [], productFeature, changeField) => {
  const addTenor = useCallback(() => {
    const updatedNum = tenorList[tenorList.length - 1] + 1;
    const newProductFeature = productFeature.map((product) => [...product, { tenor: updatedNum, margin: undefined }]);

    changeField('productFeature', newProductFeature);
  }, [productFeature, tenorList, changeField]);

  const removeTenor = useCallback(() => {
    const firstUnderlying = productFeature[0];
    if (!firstUnderlying || firstUnderlying[firstUnderlying.length - 1].tenor === 1) {
      return;
    }
    const newProductFeature = productFeature.map((product) => {
      const newProduct = [...product];
      newProduct.pop();
      return newProduct;
    });

    changeField('productFeature', newProductFeature);
  }, [productFeature, changeField]);

  return useMemo(() => ({ addTenor, removeTenor }), [addTenor, removeTenor]);
};

export default useUpdateTenorsCallback;
