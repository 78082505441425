import { UPDATE } from 'admin-on-rest';

import { PDF_HISTORY_SEND } from './PDFHistoryAction';

const PDFHistorySendAction = (id, data, basePath) => ({
  type: PDF_HISTORY_SEND,
  payload: { id, data, basePath },
  meta: { resource: 'pdf_histories', fetch: UPDATE, cancelPrevious: false },
});

export default PDFHistorySendAction;
