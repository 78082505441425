import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import GradientArch from './GradientArch';
import Arch from './Arch';
import { DEFAULT_INNER } from './constants';

const LINE_THICKNESS = 0.3;

const D3IOGauge = (props) => {
  const { label, value, minValue, maxValue, units, style } = props;
  const [value1 = 0, value2 = 0] = value;

  const angleScale = React.useMemo(() => d3.scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true), []);

  const percentScale = React.useMemo(() => d3.scaleLinear()
    .domain([minValue, maxValue])
    .range([0, 1]), [minValue, maxValue]);

  const { percent1, angle1 } = React.useMemo(() => {
    const p = percentScale(value1);
    return {
      percent1: p,
      angle1: angleScale(p),
    };
  }, [value1]);

  const { percent2, angle2 } = React.useMemo(() => {
    const p = percentScale(value2);
    return {
      percent2: p,
      angle2: angleScale(p),
    };
  }, [value2]);

  return (
    <div style={{
      textAlign: 'center',
      ...style,
    }}>
      <svg style={{ overflow: 'visible' }}
        width="9em"
        viewBox={[
          -1, -1,
          2, 1,
        ].join(' ')}>
        <g>
          <Arch round={0} showBnob={false} lineThickness={LINE_THICKNESS} />
          <GradientArch angle={angle1} percent={percent1} round={0} lineThickness={LINE_THICKNESS} showBnob={false}/>

          <Arch round={0} inner={DEFAULT_INNER - LINE_THICKNESS - 0.02} lineThickness={LINE_THICKNESS} showBnob={false}/>
          <GradientArch angle={angle2} percent={percent2} round={0} inner={DEFAULT_INNER - LINE_THICKNESS - 0.02} lineThickness={LINE_THICKNESS} showBnob={false}/>
        </g>
      </svg>
      <div style={{
        fontSize: '1.5em',
        lineHeight: '1em',
        fontWeight: '900',
        fontFeatureSettings: "'zero', 'tnum' 1",
      }}>
        {value1} / {value2}
      </div>
      {!!label && (
        <div style={{
          color: '#8b8ba7',
          // marginTop: '0.3em',
          fontSize: '1.3em',
          lineHeight: '1.3em',
          fontWeight: '700',
        }}>
          {label}
        </div>
      )}
      {!!units && (
        <div style={{
          color: '#8b8ba7',
          lineHeight: '1.3em',
          fontWeight: '300',
        }}>
          { units }
        </div>
      )}
    </div>
  );
};

D3IOGauge.PropTypes = {
  value: PropTypes.array.isRequired,
  size: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  label: PropTypes.string,
  units: PropTypes.string,
  style: PropTypes.join,
};

D3IOGauge.defaultProps = {
  value: 0,
  minValue: 0,
  maxValue: 200,
  label: null,
  units: null,
  style: {},
};

export default D3IOGauge;
