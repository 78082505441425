import isObject from 'lodash/isObject';
import React from 'react';
import { formatTitle } from '../layout/AgileLayout';
import { isNil } from 'lodash';

function HistoryActions(props) {
  const { description } = props;
  return (
    isObject(description) ?
      <div style={{ padding: '10px 0' }}>
        <div key={0} className="history-action">
          <span className="text-label">Message</span> - {description.message}
        </div>
        {Object
          .keys(description)
          .map(key => (key !== 'message' ? <div key={key} className="history-action">
            <span className="text-label">{formatTitle(key)}</span> - {!isNil(description[key]) ? description[key].toString() : null}
          </div> : null),
          )}
      </div>
      : null
  );
}

export default HistoryActions;
