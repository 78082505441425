import React from 'react';
import DownloadIcon from 'material-ui/svg-icons/action/get-app';
import PropTypes from 'prop-types';
import './DownloadButton.css';

const DownloadButton = ({ record = {}, onClick, activateField = 'file_exists' }) => {
  const { id } = record;
  const handleClick = typeof onClick === 'function' && id ? onClick(id) : null;
  let className = 'otc-download-button';
  if (!record[activateField]) className = `${className}-disabled`;
  return <div className={className}>
    <a onClick={handleClick} href="#" style={{ display: 'block' }}>
      <div >
        <DownloadIcon color="#006064" style={{ width: '28px', height: '28px', margin: '5px 0 0' }} />
      </div>
    </a>
  </div>;
};

DownloadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default DownloadButton;
