import React from 'react';
import {
  Edit,
  translate,
} from 'admin-on-rest';

import { CustomerForm } from './components';

const CustomerTitle = translate(({ record }) =>
  <span>Edit { record.name } customer</span>);

const EditCustomer = props =>
  <Edit {...props} title={<CustomerTitle />}>
    <CustomerForm />
  </Edit>;

export default EditCustomer;
