import { UPDATE } from 'admin-on-rest';

import { CRON_ACTIVE } from './CronAction';

const CronActive = (id, active) => ({
  type: CRON_ACTIVE,
  payload: { id, data: { is_active: active, status: null } },
  meta: { resource: 'cron', fetch: UPDATE, cancelPrevious: false },
});

export default CronActive;
