import React from 'react';
import Toggle from 'material-ui/Toggle';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import { translate, refreshView } from 'admin-on-rest';
import { connect } from 'react-redux';

import CronActive from './CronActive';

class CronToggles extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  onToggle = (ev, active) => {
    const { record } = this.props;
    this.props.changeActive(record.id, active);
  }

  render() {
    const { record } = this.props;
    return (
      <Toggle
        label=""
        defaultToggled={record.is_active}
        onToggle={this.onToggle}
      />
    );
  }
}

CronToggles.propTypes = {
  record: PropTypes.object,
  sendManyAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    refreshView,
    changeActive: CronActive,
  }),
);

export default enhance(CronToggles);
