// hook for change uri path
const match = {
  params: {
    id: 'list',
  },
};

const REFRESH_AUTH = 'REFRESH_AUTH';

const styles = {
  cardStyle: {
    width: 600,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  chip: {
    margin: 4,
  },
  chipWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cardHeader: {
    backgroundColor: 'rgb(241, 243, 244)',
  },
  cardField: {
    margin: '0 5px',
  },
  b: {
    fontWeight: '600',
  },
};

module.exports = {
  REFRESH_AUTH,
  match,
  styles,
};
