import React from 'react';
import compose from 'recompose/compose';
import { showNotification as showNotificationAction, translate } from 'admin-on-rest';
import { connect } from 'react-redux';
import { Card } from 'material-ui/Card';
import { Tabs, Tab } from 'material-ui/Tabs';
import MspxLogs from './components/MspxLogs';
import CqgLogs from './components/CqgLogs';
import CqgStats from './components/CqgStats';


import Instruments from './components/Instruments';
// import Cards from './components/Cards';

class List extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <Card>
        <Tabs>
          <Tab className="form-tab" label='CQG Proxy Logs' >
            <CqgLogs />
          </Tab>
          <Tab className="form-tab" label='FX Proxy Logs' >
            <MspxLogs />
          </Tab>
          <Tab className="form-tab" label='CQG Stats' >
            <CqgStats />
          </Tab>
          <Tab className="form-tab" label='Vanilla Instruments' >
            <Instruments />
          </Tab>
        </Tabs>
      </Card>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
  }),
);

export default enhance(List);

