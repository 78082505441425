import React from "react";
import { compose } from "redux";
import Moment from "react-moment";
import {
  Datagrid,
  SimpleList,
  NumberField,
  DateField,
  FunctionField,
  List,
  Responsive,
  TextField,
  translate
} from 'admin-on-rest';
import { datagridDefaultStyles } from "../theme/styles";
import { formatDateTimeInput} from "../utils/dates";
import LivePositionsActions from "./LivePositionActions";
import ResetButton from "./LivePositionResetButton";
import DeleteButton from "./LivePositionDeleteButton";


const LivePositionsList = (props) => {
  const { translate } = props;

  return (
    <List
      {...props}
      title={translate('livePositions.list.title')}
      actions={<LivePositionsActions {...props} />}
      sort={{ field: 'updated_at', order: 'DESC' }}
      perPage={50}
    >
      <Responsive
        small={
          <SimpleList
            primaryText={r => `${r.agile_name} ${r.live_position}`}
            secondaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.updated_at}</Moment>}
          />
        }
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles} >
            <TextField source="id" />
            <TextField source="agile_name" label="Commodity Name"/>
            <NumberField source="starting_position" label={translate('livePositions.form.startingPosition')}/>
            <FunctionField source="sum_of_client" label={translate('livePositions.form.sumOfClient')} render={record => record.sum_of_client || 0} />
            <FunctionField source="sum_of_hedge" label={translate('livePositions.form.sumOfHedge')} render={record => record.sum_of_hedge || 0}/>
            <FunctionField source="live_position" label={translate('livePositions.form.livePosition')} render={record => record.live_position || 0}/>
            <DateField formatDate={formatDateTimeInput} source="updated_at" showTime />
            <ResetButton {...props}/>
            <DeleteButton {...props} />
          </Datagrid>
        }
      />
    </List>
  )
};

export const LivePositionList = compose(translate)(LivePositionsList);
