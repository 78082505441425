import {
  minLength,
  maxLength,
  minValue,
} from 'admin-on-rest';

export const validateisIntegerToForty = value => (value !== parseInt(value, 10)
  ? 'Must be an Integer.'
  : undefined
);

// Customers and location
export const validateMinLengthCode = minLength(3);
export const validateMaxLengthCode = maxLength(64);
export const validateMinLengthName = minLength(3);
export const validateMaxLengthName = maxLength(1024);
export const validateMaxLengthToForty = maxLength(40);
export const validateMinLengthToForty = minValue(1);
export const validateMinLengthCurrencyCode = minLength(3);
export const validateMaxLengthCurrencyCode = maxLength(3);
export const validateMinLengthCurrencyPair = minLength(6);

export const validateMaxLength200 = maxLength(200);
export const validateMaxLength5000 = maxLength(5000);


// use regular function instead for arrow func because of linter error: https://eslint.org/docs/rules/no-confusing-arrow
export function validateCode(value) {
  return /\W/.test(value) ? 'Allowed characters: A-Z, 0-9 and underscore' : null;
}
