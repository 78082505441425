import React, { Component } from 'react';
import { GET_ONE } from 'admin-on-rest';
import TextField from 'material-ui/TextField';
import { CardText } from 'material-ui/Card';
import Paper from 'material-ui/Paper';
import ChipInput from 'material-ui-chip-input';
import AutoComplete from 'material-ui/AutoComplete';
import _ from 'lodash';
import { RenderComponent } from './';
import restClient from '../../restClient';
import { Loader } from '../../helpers/Loader';
import path from '../../path';

const COPYRIGHT_DEFAULT_VALUE = '';

export default class CommonComponent extends Component {
  defaultState = {
    headerTitle: '',
    inputs: [],
    data: {},
    files: {},
    errors: {
      title: false,
      copyright: false,
    },
    load: true,
    domains: [],
    pages: [],
    searchText: '',
  }

  _componentDidMount(id = null) {
    fetch(`${path}/admin/domains/notAssigned/${id}`, {
      method: 'GET',
      headers: {
        Token: localStorage.getItem('session'),
      },
    }).then(res => res.json())
      .then(res => this.setState({ ...this.state, domains: res }))
      .catch(error => console.error(error));
  }

  getConfig = async () => {
    const { data: { definition } } = await restClient(GET_ONE, 'theme_configs', { id: 1 });
    return JSON.parse(definition);
  }

  getPages = async () => {
    const { data } = await restClient(GET_ONE, 'static_pages', { id: 'footer' });
    return data;
  }

  handleInputChange = (section, inputName, type) => (...event) => {
    const state = {
      data: {
        ...this.state.data,
        [section]: {
          ...this.state.data[section],
          [inputName]: event[1] || event[1] === '' ? event[1] : event[0],
        },
      },
      errors: {
        ...this.state.errors,
        [inputName]: false,
      },
    };

    if (type === 'file' && event[0]) {
      state.files = {
        ...this.state.files,
        [inputName]: event[0],
        errors: {
          ...this.state.errors,
          [inputName]: false,
        },
      };
    } else {
      state.files = {
        ...this.state.files,
        [inputName]: '',
      };
    }

    this.setState(state);
  };

  handleInputChangeField = inputName => (...event) => this.setState({
    data: {
      ...this.state.data,
      [inputName]: event[1],
    },
    errors: {
      ...this.state.errors,
      [inputName]: false,
    },
  });

  getData = () => {
    const { data, files, pages = [] } = this.state;
    const { title, copyright = COPYRIGHT_DEFAULT_VALUE, domainId } = data;
    const validationErrors = this.getValidationErrors();
    if (Object.keys(validationErrors).length) {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          ...validationErrors,
        },
      });
      return null;
    }

    return {
      data,
      files,
      title,
      copyright,
      pages,
      domainId: domainId || null,
    };
  }

  getValidationErrors() {
    const { data: { colors, images, title, texts } } = this.state;
    const validationErrors = {};

    Object.keys(colors).forEach((key) => {
      if (!colors[key].trim()) {
        validationErrors[key] = {
          error: true,
          message: this.translate('aor.validation.required'),
        };
      }
    });

    Object.keys(texts).forEach((key) => {
      if (!texts[key].trim()) {
        validationErrors[key] = {
          error: true,
          message: this.translate('aor.validation.required'),
        };
      }
    });

    Object.keys(images).forEach((key) => {
      if (!(typeof images[key] === 'object') || images[key] === null) {
        validationErrors[key] = {
          error: true,
          message: this.translate('aor.validation.required'),
        };
      }
    });

    if (!title.trim()) {
      validationErrors.title = {
        error: true,
        message: this.translate('aor.validation.required'),
      };
    } else if (title.length < 3) {
      validationErrors.title = {
        error: true,
        message: 'Must be 3 characters at least',
      };
    } else if (title.length > 200) {
      validationErrors.title = {
        error: true,
        message: 'Must be 200 characters or less',
      };
    }
    return validationErrors;
  }

  handleSearchTextChange = (value) => {
    this.setState({
      searchText: value,
      data: {
        ...this.state.data,
        domainId: value || null,
      },
    });
  }

  domainSelected = domain => this.setState({
    data: {
      ...this.state.data,
      domainId: domain.id,
    },
  });

  handleAddChips = (chip) => {
    this.setState(state => ({
      ...state,
      pages: [...state.pages, chip],
    }));
  }

  handleDeleteChips = (chip) => {
    const { pages } = this.state;
    _.remove(pages, ({ id }) => (chip === id));
    this.setState(state => ({
      ...state,
      pages: [...pages],
    }));
  }

  getContent = () => {
    const { load, inputs, data, errors, pagesData, pages, domains, searchText } = this.state;
    const { title, copyright } = data;
    return (
      <CardText
        style={{ display: 'flow-root', padding: '0px 1em 1em' }}
      >
        {load ? <Loader text='Please wait...' /> : ''}
        <Paper style={{ width: '50%', float: 'left', boxShadow: 'none' }}>
          <TextField
            errorText={errors.title.error && errors.title.message}
            fullWidth
            name='title'
            floatingLabelText='Title'
            type="text"
            onChange={this.handleInputChangeField('title')}
            maxLength="255"
            minLength="3"
            value={title}
            required
          />
          <AutoComplete
            floatingLabelText="Select Domain/Url"
            filter={AutoComplete.levenshteinDistance}
            dataSource={domains}
            dataSourceConfig={{ text: 'name', value: 'id' }}
            onNewRequest={this.domainSelected}
            onUpdateInput={this.handleSearchTextChange}
            openOnFocus
            fullWidth
            searchText={searchText}
            listStyle={{ 'maxHeight': '30vh', 'overflow': 'auto' }}
            onBlur = {() => {}}
            onKeyPress= {() => {}}
          />
          {inputs.map((i, index) => (
            <div style={{ margin: '50px 0 0 0' }} key={index}>
              <h2>{this.typeFormatter(Object.keys(i))}</h2>
              {i[Object.keys(i)].map((field) => {
                const { name, type } = field;
                return RenderComponent({
                  input: field,
                  onChange: this.handleInputChange(Object.keys(i), name, type),
                  value: data[Object.keys(i)][name],
                  errorObj: errors[name],
                });
              })}
            </div>
          ))}
          <TextField
            errorText={errors.copyright.error && errors.copyright.message}
            fullWidth
            name='copyright'
            floatingLabelText='Copyright'
            type="text"
            onChange={this.handleInputChangeField('copyright')}
            maxLength="255"
            minLength="3"
            value={copyright || COPYRIGHT_DEFAULT_VALUE}
            required
          />
          <ChipInput
            value={pages}
            dataSource={pagesData}
            openOnFocus={true}
            floatingLabelText="Footer"
            dataSourceConfig={{ text: 'title', value: 'id' }}
            onRequestAdd={this.handleAddChips}
            onRequestDelete={this.handleDeleteChips}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
        </Paper>
      </CardText>
    );
  }

  typeFormatter = (type) => {
    const [t] = type;
    if (!t) return t;

    return t[0].toUpperCase() + t.slice(1);
  }
}
