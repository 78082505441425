import { Show, SimpleShowLayout, TextField, translate } from 'admin-on-rest';
import React from 'react';
import { DateField } from '../helpers';
import { formatDateTimeInput } from '../utils/dates';
import RatesMatrix from './RatesMatrix';

const PricingTitle = translate(({ record }) =>
  <span>{translate('rates.show.title', { smart_count: 1 })} { `${record.currency}` }</span>);

const RatesShow = props => (
  <Show {...props}
    title={<PricingTitle />}
  >
    <SimpleShowLayout
      style={{ display: 'flow-root', padding: '0px 1em 1em' }}
    >
      <RatesMatrix />
      <TextField
        style={{ display: 'flex' }}
        source="currency"
      />
      <DateField
        style={{ display: 'flex' }}
        formatDate={formatDateTimeInput}
        source="updated_at"
        showTime
      />
    </SimpleShowLayout>
  </Show>
);

export default RatesShow;
