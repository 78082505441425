import React, { Component, Fragment } from 'react';
import Checkbox from 'material-ui/Checkbox';
import { Table, TableBody, TableRow, TableRowColumn, TableHeader } from 'material-ui/Table';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

export default class CommodityList extends Component {
  render() {
    const {
      commodities,
      handleCheckAllTableChange,
      handleCheckBoxTableChange,
      handleInputTableChange,
      handleFixedSpreadChange,
      type,
      disabled,
      cqgAccounts,
      handleSelectCommodityCqgAccChange
    } = this.props;

    return (
      <Table selectable={false}>
        <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
          <TableRow>
            <TableRowColumn>
              <span>
                <Checkbox
                  labelStyle={{ fontWeight: 'bold' }}
                  onClick={handleCheckAllTableChange(!commodities.find(row => row.status === true), type)}
                  checked={!commodities.find(row => row.status === false)}
                  disabled={disabled}
                />
              </span>
            </TableRowColumn>
            {type === 'fxContracts' ? null : <TableRowColumn> <b>CQG Account</b> </TableRowColumn>}
            <TableRowColumn> <b>{type === 'fxContracts' ? 'FX' : 'Commodity' } Code</b> </TableRowColumn>
            <TableRowColumn> <b>Agile Name</b> </TableRowColumn>
            <TableRowColumn> <b>Qty</b> </TableRowColumn>
            <TableRowColumn> <b>Daily Qty Limit</b> </TableRowColumn>
            <TableRowColumn> <b>Automatic Approval</b> </TableRowColumn>
            <TableRowColumn>
              <span>
                <Checkbox
                  label="Spread"
                  labelStyle={{ fontWeight: 'bold' }}
                  onClick={handleFixedSpreadChange(!commodities[0]?.use_fixed_spread, type)}
                  checked={commodities[0]?.use_fixed_spread}
                />
              </span>
            </TableRowColumn>
            <TableRowColumn> <b>Markup Spread</b> </TableRowColumn>
            {type === 'fxContracts' ? null : <TableRowColumn> <b>Tick</b> </TableRowColumn> }
            {type === 'fxContracts' ? null : <TableRowColumn> <b>Factor</b> </TableRowColumn> }
            <TableRowColumn> <b>IB Fee</b> </TableRowColumn>
            <TableRowColumn> <b>Sales Commission</b> </TableRowColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {commodities.map((row, i) => (
            <TableRow key={row.commodity_code} >
              <TableRowColumn>
                <Checkbox
                  disabled={disabled}
                  onClick={handleCheckBoxTableChange(i, !row.status, type)}
                  checked={row.status}
                />
              </TableRowColumn>
              {type === 'fxContracts' ? null : <TableRowColumn>
                <SelectField
                  name="cqg_account"
                  onChange={handleSelectCommodityCqgAccChange(i, 'cqg_account', type)}
                  value={row.cqg_account}
                >
                  {cqgAccounts?.map(x => (
                    <MenuItem key={x.id} value={x.name} primaryText={x.name}/>
                  ))}
                </SelectField>
              </TableRowColumn>}
              <TableRowColumn>{row.commodity_code}</TableRowColumn>
              <TableRowColumn>{row.agile_name}</TableRowColumn>
              <TableRowColumn>
                {disabled ? (
                  row.qty
                ) : (
                  <TextField
                    type="number"
                    name="qty"
                    value={row.qty}
                    onChange={handleInputTableChange(i, 'qty', type)}
                    fullWidth
                    disabled={!row.status}
                    min={0}
                  />
                )}
              </TableRowColumn>
              <TableRowColumn>
                {disabled ? (
                  row.daily_qty_limit
                ) : (
                  <TextField
                    type="number"
                    name="daily_qty_limit"
                    value={row.daily_qty_limit}
                    onChange={handleInputTableChange(i, 'daily_qty_limit', type)}
                    fullWidth
                    disabled={!row.status}
                    min={0}
                  />
                )}
              </TableRowColumn>
              <TableRowColumn>
                {disabled ? (
                  row.qty_approval
                ) : (
                  <TextField
                    type="number"
                    name="qty_approval"
                    value={row.qty_approval || 0}
                    onChange={handleInputTableChange(i, 'qty_approval', type)}
                    fullWidth
                    disabled={!row.status}
                    min={0}
                  />
                )}
              </TableRowColumn>
              <TableRowColumn>
                {disabled ? (
                  row.spread
                ) : (
                  <TextField
                    type="number"
                    name="spread"
                    value={row.spread}
                    onChange={handleInputTableChange(i, 'spread', type)}
                    fullWidth
                    disabled={!row.status || !row.use_fixed_spread}
                    min={0}
                  />
                )}
              </TableRowColumn>
              <TableRowColumn style={{ 'white-space': 'pre-wrap' }}>
                {disabled || row.use_fixed_spread ? (
                  JSON.stringify(row.markup_spread)
                ) : (
                  <Fragment>
                    <TextField
                      type="number"
                      name="x"
                      value={row.markup_spread.x}
                      onChange={handleInputTableChange(i, 'markup_spread.x', type)}
                      disabled={!row.status}
                      min={0}
                    />
                    <TextField
                      type="number"
                      name="y"
                      value={row.markup_spread.y}
                      onChange={handleInputTableChange(i, 'markup_spread.y', type)}
                      disabled={!row.status}
                      min={0}
                    />
                    <TextField
                      type="number"
                      name="z"
                      value={row.markup_spread.z}
                      onChange={handleInputTableChange(i, 'markup_spread.z', type)}
                      // fullWidth
                      disabled={!row.status}
                      min={0}
                    />
                  </Fragment>
                )}
              </TableRowColumn>
              {type === 'fxContracts' ? null : <TableRowColumn>{row.tick}</TableRowColumn>}

              {type === 'fxContracts' ? null : (
                <TableRowColumn>
                  {disabled ? (
                    row.factor
                  ) : (
                    <TextField
                      type="number"
                      name="factor"
                      value={row.factor}
                      onChange={handleInputTableChange(i, 'factor', type)}
                      fullWidth
                      disabled={!row.status}
                      min={0}
                    />
                  )}
                </TableRowColumn>
              )}

              <TableRowColumn>
                {disabled ? (
                  row.ib_fee
                ) : (
                  <TextField
                    type="number"
                    name="ib_fee"
                    value={row.ib_fee || 0}
                    onChange={handleInputTableChange(i, 'ib_fee', type)}
                    fullWidth
                    disabled={!row.status}
                    min={0}
                  />
                )}
              </TableRowColumn>
              <TableRowColumn>
                {disabled ? (
                  row.sales_comission
                ) : (
                  <TextField
                    type="number"
                    name="sales_comission"
                    value={row.sales_comission || 0}
                    onChange={handleInputTableChange(i, 'sales_comission', type)}
                    fullWidth
                    disabled={!row.status}
                    min={0}
                  />
                )}
              </TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}
