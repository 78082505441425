import React from 'react';
import Avatar from 'material-ui/Avatar';
import UserIcon from 'material-ui/svg-icons/action/supervisor-account';
import Subheader from 'material-ui/Subheader';
import Chip from 'material-ui/Chip';
import Moment from 'react-moment';

import { translate } from 'admin-on-rest';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import { List, ListItem } from 'material-ui/List';
import { green200 } from 'material-ui/styles/colors';

const avatar = r =>
  <Avatar src={`https://ui-avatars.com/api/?name=${r.firstName}+${r.lastName}&background=808080&size=32x32&font-size=0.33&color=ffffff`} />;

const info = r =>
  <Chip backgroundColor={green200}>Logged in <Moment format="DD MMMM YYYY HH:mm">{r.loggedAt}</Moment></Chip>;

class AppUser extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  render() {
    const { users = [], count = 0 } = this.props;
    return (
      <Card>
        <CardHeader
          title={count}
          subtitle={this.translate('dashboard.app_user.subtitle')}
          avatar={<Avatar backgroundColor="#FFEB3B" icon={<UserIcon />} />}
          actAsExpander={true}
          showExpandableButton={true}
        />
        <CardText
          expandable={true}
          actAsExpander={false}
          style={{ padding: '0' }}
        >
          <List style={{ height: 288, overflow: 'auto' }}>
            {users.length ? users.map((r, i) => <ListItem
              href={`#/users/${r.id}/show`}
              leftAvatar={avatar(r)}
              primaryText={`${r.firstName} ${r.lastName}`}
              secondaryText={r.email}
              rightAvatar={info(r)}
              key={i}
            />) : <Subheader>{this.translate('dashboard.app_user.empty')}</Subheader>}
          </List>
        </CardText>
      </Card>
    );
  }
}

export default translate(AppUser);
