import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import RaisedButton from 'material-ui/RaisedButton';

import { connect } from 'react-redux';
import { translate, GET_LIST } from 'admin-on-rest';

import OtcConfirmationSendIcon from './OTCSendIcon';
import { OTCSendAction } from './OTCSendAction';

import restClient from '../restClient';

import ValidateEmail from '../helpers/ValidateEmail';

class OTCRemindButton extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
    this.state = {
      open: false,
      error: false,
      emails: [props.record.signer_email],
      bcc: props.record.bcc_emails || [],
      cc: props.record.cc_emails || [],
      chips: [],
    };
  }

  handleOpen() {
    this.setState({ open: true });
    restClient(GET_LIST, 'users', {
      sort: {
        field: 'email',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((res) => {
      this.setState({ chips: res.data.map(i => i.email) });
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleSubmit() {
    if (this.state.emails.length) {
      this.setState({ error: false, open: false });
    } else {
      this.setState({ error: true });
      return;
    }

    const { record } = this.props;

    this.props.sendAction(record.id, {
      emails: this.state.emails,
      bcc: this.state.bcc,
      cc: this.state.cc,
    });
  }

  handleAddEmail(chip) {
    const chips = [...this.state.emails, chip.toLowerCase()];
    this.setState({
      emails: chips,
      error: !chips.length,
    });
  }

  handleAddBCC(chip) {
    const chips = [...this.state.bcc, chip.toLowerCase()];
    this.setState({
      bcc: chips,
    });
  }

  handleAddCC(chip) {
    const chips = [...this.state.cc, chip.toLowerCase()];
    this.setState({
      cc: chips,
    });
  }

  handleDeleteEmail(chip) {
    const chips = this.state.emails.filter(c => c !== chip);
    this.setState({
      emails: chips,
      error: !chips.length,
    });
  }

  handleDeleteBCC(chip) {
    const chips = this.state.bcc.filter(c => c !== chip);
    this.setState({
      bcc: chips,
    });
  }

  handleDeleteCC(chip) {
    const chips = this.state.cc.filter(c => c !== chip);
    this.setState({
      cc: chips,
    });
  }

  handleBeforeAddChip(chip) {
    const { emails, bcc, cc } = this.state;
    if (!ValidateEmail(chip)) return false;
    return !emails.includes(chip) && !bcc.includes(chip) && !cc.includes(chip);
  }

  dataSource() {
    const { chips, emails, bcc, cc } = this.state;
    return chips.filter(c => !emails.includes(c) && !bcc.includes(c) && !cc.includes(c));
  }

  render() {
    const { record } = this.props;
    const disabled = !['released', 'delivered'].includes(record.status);
    const flatButtonColor = disabled ? 'rgba(0, 0, 0, 0.3)' : '#00838F';

    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          icon={<OtcConfirmationSendIcon color={flatButtonColor} />}
          onClick={this.handleOpen.bind(this)}
          disabled={disabled}
          style= {{ minWidth: '20px', padding: '0 7px' }}
        />
        <Dialog
          title={`${this.translate('OTCConfirmations.dialog.title')}${record && record.id}`}
          modal={false}
          open={this.state.open}
          autoScrollBodyContent={true}
        >
          <ChipInput
            value={this.state.emails}
            dataSource={this.dataSource()}
            openOnFocus={true}
            onBeforeRequestAdd={this.handleBeforeAddChip.bind(this)}
            onRequestAdd={this.handleAddEmail.bind(this)}
            onRequestDelete={this.handleDeleteEmail.bind(this)}
            errorText={this.state.error && this.translate('aor.validation.required')}
            floatingLabelText={this.translate('pdfs.dialog.emailText')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={this.state.cc}
            dataSource={this.dataSource()}
            openOnFocus={true}
            onBeforeRequestAdd={this.handleBeforeAddChip.bind(this)}
            onRequestAdd={this.handleAddCC.bind(this)}
            onRequestDelete={this.handleDeleteCC.bind(this)}
            floatingLabelText={this.translate('pdfs.dialog.ccText')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={this.state.bcc}
            dataSource={this.dataSource()}
            openOnFocus={true}
            onBeforeRequestAdd={this.handleBeforeAddChip.bind(this)}
            onRequestAdd={this.handleAddBCC.bind(this)}
            onRequestDelete={this.handleDeleteBCC.bind(this)}
            floatingLabelText={this.translate('pdfs.dialog.bccText')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <div
            style={{ paddingTop: '15px', float: 'right' }}
          >
            <RaisedButton
              label={this.translate('pdfs.dialog.btnCancel')}
              onClick={this.handleClose.bind(this)}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label={this.translate('pdfs.dialog.btnSubmit')}
              primary={true}
              onClick={this.handleSubmit.bind(this)}
              icon={<SubmitIcon />}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

OTCRemindButton.propTypes = {
  record: PropTypes.object,
  sendAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    sendAction: OTCSendAction,
  }),
);

export default enhance(OTCRemindButton);
