import { DateInput, Filter, SelectInput, TextInput } from 'admin-on-rest';
import React from 'react';
import { formatDateInput } from '../utils/dates';

const CronFilters = props => (
  <Filter {...props}>
    <TextInput
      label="Search (Name, Status)"
      source="_q"
      alwaysOn
      options={{ style: { width: '300px' } }}
    />
    <TextInput
      source="name"
    />
    <TextInput
      source="time_zone"
    />
    <SelectInput source="status" choices={[
      { id: 'done', name: 'Done' },
      { id: 'wait', name: 'Wait' },
    ]} />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
    />
  </Filter>
);

export default CronFilters;
