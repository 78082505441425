import { DELETE } from 'admin-on-rest';
import { VANILLA_DESCRIPTION_DELETE } from './VanillaDescriptionAction';

const VanillaDescriptionDeleteAction = (ids, data, basePath) => ({
  type: VANILLA_DESCRIPTION_DELETE,
  payload: { id: ids.join('-'), data, basePath },
  meta: { resource: 'vanilla_description', fetch: DELETE, cancelPrevious: false },
});

export default VanillaDescriptionDeleteAction;
