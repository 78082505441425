import React from 'react';
import ToggleDisplay from 'react-toggle-display';
import withWidth from 'material-ui/utils/withWidth';

import { AppBarMobile, translate, GET_LIST, GET_ONE } from 'admin-on-rest';

import Welcome from './Welcome';
import Structure from './Structure';
import CalculateHistory from './CalculateHistory';
import PendingUser from './PendingUser';
import AppUser from './AppUser';
import AllUser from './AllUser';
import RejectedUser from './RejectedUser';
import ApprovedUser from './ApprovedUser';
import DashboardRequest from './DashboardRequest';
import DashboardRefresh from './DashboardRefresh';
import NotSignedOTCConfirmation from './NotSignedOTCConfirmations';

import restClient from '../restClient';
import Seo from '../components/common/Seo';
import socket from 'ioClient';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.socket = socket;
    this.translate = this.props.translate;
    this.state = {};
  }

  loadAllUsers() {
    restClient(GET_LIST, 'users', {
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
    }).then((res) => {
      this.setState({ allUsers: res.data });
      this.setState({ countAllUsers: res.total });
    });
  }

  loadNotSignedOTCConfirmations() {
    restClient(GET_LIST, 'otc_confirmation/unsignedOTC', {
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
      filter: {
        status: 'released',
      },
    }).then((res) => {
      this.setState({
        countNotSignedOTCConfirmations: res.total,
        notSignedOTCConfirmations: res.data,
      });
    });
  }

  loadPendingUsers() {
    restClient(GET_LIST, 'users', {
      filter: {
        status: 5,
      },
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
    }).then((res) => {
      this.setState({ pendingUsers: res.data });
      this.setState({ countPendingUsers: res.total });
    });
  }

  loadRejectedUsers() {
    restClient(GET_LIST, 'users', {
      filter: {
        status: 3,
      },
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
    }).then((res) => {
      this.setState({ rejectedUsers: res.data });
      this.setState({ countRejectedUsers: res.total });
    });
  }

  loadApprovedUsers() {
    restClient(GET_LIST, 'users', {
      filter: {
        status: 2,
      },
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
    }).then((res) => {
      this.setState({ approvedUsers: res.data });
      this.setState({ countApprovedUsers: res.total });
    });
  }

  loadCalculateHistory() {
    restClient(GET_LIST, 'calculate_history', {
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
    }).then((res) => {
      this.setState({ calculateHistory: res.data });
      this.setState({ countCalculateHistory: res.total });
    });
  }

  loadPricingHistories() {
    restClient(GET_LIST, 'pricing_histories', {
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
    }).then((res) => {
      this.setState({ pricingHistories: res.data });
      this.setState({ countPricingHistories: res.total });
    });
  }

  loadStructure() {
    restClient(GET_LIST, 'structure_data', {
      sort: {
        field: 'bloomberg_ticker',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 100,
      },
    }).then((res) => {
      this.setState({ listStructure: res.data });
      this.setState({ countStructure: res.total });
    });
  }

  loadDashboardRequests() {
    restClient(GET_LIST, 'dashboard_requests', {
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 15,
      },
    }).then((res) => {
      this.setState({ dashboardRequests: res.data });
      this.setState({ countDashboardRequests: res.total });
    });

    restClient(GET_LIST, 'dashboard_requests', {
      filter: {
        status: false,
      },
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 1,
      },
    }).then((res) => {
      this.setState({ countApprovedDashboardRequests: res.total });
    });

    restClient(GET_LIST, 'dashboard_requests', {
      filter: {
        status: true,
      },
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 1,
      },
    }).then((res) => {
      this.setState({ countPendingDashboardRequests: res.total });
    });
  }

  loadRefreshDashboars() {
    restClient(GET_LIST, 'refresh_dashboards', {
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 15,
      },
    }).then((res) => {
      this.setState({ refreshDashboards: res.data });
      this.setState({ countRefreshDashboards: res.total });
    });

    restClient(GET_LIST, 'refresh_dashboards', {
      filter: {
        status: true,
      },
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 1,
      },
    }).then((res) => {
      this.setState({ countRefreshRefreshedDashboards: res.total });
    });

    restClient(GET_LIST, 'refresh_dashboards', {
      filter: {
        status: false,
      },
      sort: {
        field: 'created_at',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 1,
      },
    }).then((res) => {
      this.setState({ countPendingRefreshDashboards: res.total });
    });
  }

  async loadOnlineUsers() {
    const { data } = await restClient(GET_ONE, 'users', { id: 'online' });
    if (data && data.total) {
      this.setState({
        appUsers: data.users,
        countAppUsers: data.total,
      });
    }
  }

  componentDidMount() {
    this.socket.on('user-sign-in', (data) => {
      const users = (this.state.appUsers || []).filter(u => (u.id !== data.id)).concat([data]);
      const count = users.length;

      this.setState({
        appUsers: users,
        countAppUsers: count,
      });
    });

    this.socket.on('user-sign-out', (data) => {
      const users = (this.state.appUsers || []).filter(u => (u.id !== data.id));
      const count = users.length;

      this.setState({
        appUsers: users,
        countAppUsers: count,
      });
    });

    this.socket.on('user', () => {
      this.loadAllUsers();

      this.loadPendingUsers();

      this.loadRejectedUsers();

      this.loadApprovedUsers();
    });

    this.socket.on('unsigned-OTC-refresh', () => {
      this.loadNotSignedOTCConfirmations();
    });

    this.socket.on('dashboard-request', () => {
      this.loadDashboardRequests();
    });

    this.socket.on('pricing-history', () => {
      this.loadCalculateHistory();
    });

    this.socket.on('dashboard-refresh-request', () => {
      this.loadRefreshDashboars();
    });

    this.loadOnlineUsers();

    this.loadAllUsers();

    this.loadPendingUsers();

    this.loadRejectedUsers();

    this.loadApprovedUsers();

    this.loadCalculateHistory();

    this.loadDashboardRequests();

    this.loadRefreshDashboars();

    this.loadNotSignedOTCConfirmations();

    this.loadStructure();
  }

  render() {
    const { appUsers, countAppUsers } = this.state;
    const { allUsers, countAllUsers } = this.state;
    const { pendingUsers, countPendingUsers } = this.state;
    const { rejectedUsers, countRejectedUsers } = this.state;
    const { approvedUsers, countApprovedUsers } = this.state;
    const { calculateHistory, countCalculateHistory } = this.state;
    const { dashboardRequests, countDashboardRequests, countApprovedDashboardRequests, countPendingDashboardRequests } = this.state;
    const { refreshDashboards, countRefreshDashboards, countRefreshRefreshedDashboards, countPendingRefreshDashboards } = this.state;
    const { countNotSignedOTCConfirmations, notSignedOTCConfirmations } = this.state;
    const { countStructure, listStructure } = this.state;

    return (
      <div>
        <Seo title={'Info Page'} />
        <ToggleDisplay
          show={this.props.width === 1}
        >
          <AppBarMobile
            title={this.translate('dashboard.title')}
          />
        </ToggleDisplay>
        <Welcome />
        <CalculateHistory
          count={countCalculateHistory}
          histories={calculateHistory}
        />
        <NotSignedOTCConfirmation
          count={countNotSignedOTCConfirmations}
          notSignedOTCConfirmations={notSignedOTCConfirmations}
        />
        <div style={{ display: 'flex', marginBottom: '2em' }}>
          <div style={{ flex: 1, marginRight: '1em' }}>
            <div>
              <AppUser
                count={countAppUsers}
                users={appUsers}
              />
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: '1em' }}>
            <div>
              <DashboardRequest
                count={countDashboardRequests}
                countApproved={countApprovedDashboardRequests}
                countPending={countPendingDashboardRequests}
                dashboardRequests={dashboardRequests}
              />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: '2em' }}>
          <div style={{ flex: 1, marginRight: '1em' }}>
            <div>
              <Structure
                count={countStructure}
                list={listStructure}
              />
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: '1em' }}>
            <div>
              <DashboardRefresh
                count={countRefreshDashboards}
                countRefreshed={countRefreshRefreshedDashboards}
                countPending={countPendingRefreshDashboards}
                refreshDashboards={refreshDashboards}
              />
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', marginBottom: '2em' }}>
          <div style={{ flex: 1, marginRight: '1em' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '50%' }}>
                <AllUser
                  count={countAllUsers}
                  users={allUsers}
                />
              </div>
              <div style={{ width: '50%' }}>
                <PendingUser
                  count={countPendingUsers}
                  users={pendingUsers}
                />
              </div>
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: '1em' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '50%' }} >
                <RejectedUser
                  count={countRejectedUsers}
                  users={rejectedUsers}
                />
              </div>
              <div style={{ width: '50%' }} >
                <ApprovedUser
                  count={countApprovedUsers}
                  users={approvedUsers}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate(withWidth()(Dashboard));
