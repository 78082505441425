import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleNotification } from '../../actions';
import style from './style';

class NotifyBell extends Component {
  shouldComponentUpdate({ notificationCount }) {
    return notificationCount !== this.props.notificationCount;
  }

  componentWillReceiveProps() {
    const bell = document.getElementById('bell-icon');
    bell.classList.remove('notify');
    // eslint-disable-next-line no-self-assign
    bell.offsetWidth = bell.offsetWidth;
    setTimeout(() => {
      bell.classList.add('notify');
    }, 5);
  }

  render() {
    // eslint-disable-next-line
    const { toggleNotification, notificationCount } = this.props;
    return (
      <div className="notification-icon">
        <style>{style}</style>
        <audio id="bell-audio">
          <source src="exquisite.mp3" type="audio/mpeg"/>
          <source src="exquisite.ogg" type="audio/ogg"/>
        </audio>
        <audio id="bell-more-audio">
          <source src="gaster_blaster.mp3" type="audio/mpeg"/>
          <source src="gaster_blaster.ogg" type="audio/ogg"/>
        </audio>
        <audio id="autoapprove-audio">
          <source src="meepmeep.mp3" type="audio/mpeg"/>
          <source src="meepmeep.ogg" type="audio/ogg"/>
        </audio>
        <button className="notificationBtn" onClick={toggleNotification}>
          <div>
            <span className="Icon__IconWrapper-dyhwwi-0 elWdVp" aria-label="Notifications">
              <svg id="bell-icon" className="" width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M6.485 17.669a2 2 0 0 0 2.829 0l-2.829-2.83a2 2 0 0 0 0 2.83zm4.897-12.191l-.725.725c-.782.782-2.21 1.813-3.206 2.311l-3.017 1.509c-.495.248-.584.774-.187 1.171l8.556 8.556c.398.396.922.313 1.171-.188l1.51-3.016c.494-.988 1.526-2.42 2.311-3.206l.725-.726a5.048 5.048 0 0 0 .64-6.356 1.01 1.01 0 1 0-1.354-1.494c-.023.025-.046.049-.066.075a5.043 5.043 0 0 0-2.788-.84 5.036 5.036 0 0 0-3.57 1.478z" fill="currentColor" fillRule="evenodd"></path></svg>
            </span>
          </div>
          <div className="ntf-wp-top" >
            <div>
              <span className="ntf-wp-count">{notificationCount}</span>
            </div>
          </div>
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => ({ notificationCount: state.notificationReducer.count });
export default connect(mapStateToProps, { toggleNotification })(NotifyBell);
