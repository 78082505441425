import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import EditIcon from 'material-ui/svg-icons/image/edit';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import { connect } from 'react-redux';
import { translate, showNotification as showNotificationAction, refreshView as refreshViewAction, GET_LIST } from 'admin-on-rest';
import restClient from '../../restClient';
import { removeSpecialChars } from '../../helpers/utilities';
import ValidateEmail from '../../helpers/ValidateEmail';
import path from '../../path';

class EditButton extends React.Component {
  defaultState = {
    open: false,
    to: [],
    cc: [],
    bcc: [],
    address: '',
    entity: '',
    dashboards: [],
    selectedDashboards: [],
    emails: [],
    statementSigner: [],
    statementCc: [],
    statementBcc: [],
    otcSigner: [],
    otcCc: [],
    otcBcc: [],
    otcViewers: [],
    signerFullName: '',
    clientName: '',
    team: [],
    clientCode: '',
    contactName: '',
    phoneNumber: '',
    clientLanguage: '',
    agreementType: '',
    sales: '',
    salesTeams: [],
    selectedSalesTeams: [],
    secondOtcSigner: [],
    secondSignerFullName: '',
    id: null,
    sftpAccountId: null,
    sftpAccounts: [],
    recordType: '',
    isDefaultAccount: false,
    errors: {
      clientName: false,
      clientCode: false,
      otcSigner: false,
      signerFullName: false,
      secondOtcSigner: false,
      secondSignerFullName: false,
    },
  }

  getValidationErrors() {
    const { clientName, clientCode, secondOtcSigner, secondSignerFullName, otcSigner, signerFullName } = this.state;
    const validationErrors = {};

    if (!clientName.trim()) validationErrors.clientName = true;
    if (!clientCode.trim()) validationErrors.clientCode = true;

    if (otcSigner.length || (signerFullName && signerFullName.trim())) {
      if (!otcSigner.length) validationErrors.otcSigner = true;
      if (!signerFullName || !signerFullName.trim()) validationErrors.signerFullName = true;
    }

    if (secondOtcSigner.length || (secondSignerFullName && secondSignerFullName.trim())) {
      if (!secondOtcSigner.length) validationErrors.secondOtcSigner = true;
      if (!secondSignerFullName || !secondSignerFullName.trim()) validationErrors.secondSignerFullName = true;
    }

    return validationErrors;
  }

  constructor(props) {
    super(props);
    this.translate = this.props.translate;
    this.state = this.defaultState;
  }

  initState = () => {
    const { dashboard
      , statement_bcc: statementBcc
      , statement_cc: statementCc
      , statement_signer: statementSigner
      , otc_viewers: otcViewers
      , otc_cc: otcCc
      , otc_bcc: otcBcc
      , otc_signer: otcSigner
      , signer_full_name: signerFullName
      , client: clientName
      , id
      , team
      , client_code: clientCode
      , contact_name: contactName
      , phone_number: phoneNumber
      , client_language: clientLanguage
      , agreement_type: agreementType
      , sales
      , to
      , cc
      , bcc
      , address
      , entity
      , second_otc_signer: secondOtcSigner
      , second_signer_full_name: secondSignerFullName
      , sales_teams: selectedSalesTeams
      , sftp_account_id: sftpAccountId
      , record_type: recordType
      , is_default_account: isDefaultAccount
      ,
    } = this.props.record;

    this.setState({
      ...this.state,
      selectedDashboards: dashboard && dashboard[0] && dashboard[0].id ? dashboard : [],
      statementBcc: statementBcc || [],
      statementCc: statementCc || [],
      statementSigner: statementSigner || [],
      otcViewers: otcViewers || [],
      otcCc: otcCc || [],
      otcBcc: otcBcc || [],
      otcSigner: otcSigner ? [otcSigner] : [],
      to: to || [],
      cc: cc || [],
      bcc: bcc || [],
      signerFullName,
      clientName,
      id,
      address,
      entity,
      phoneNumber,
      clientLanguage,
      sales,
      agreementType,
      team: team || [],
      clientCode,
      contactName,
      open: true,
      secondOtcSigner: secondOtcSigner ? [secondOtcSigner] : [],
      secondSignerFullName: secondSignerFullName || '',
      selectedSalesTeams: selectedSalesTeams || [],
      sftpAccountId: sftpAccountId || null,
      recordType: recordType || null,
      isDefaultAccount: isDefaultAccount || false,
    });
  }

  handleDeleteChips = type => (chip) => {
    const chips = this.state[type].filter(c => c !== chip);
    this.setState({
      [type]: chips,
    });
  }

  handleDeleteDashboard = (dashboard) => {
    const selectedDashboards = this.state.selectedDashboards.filter(d => d.id !== dashboard);
    this.setState({
      ...this.state,
      selectedDashboards,
    });
  }

  handleSubmit = () => {
    const validationErrors = this.getValidationErrors();
    if (Object.keys(validationErrors).length) {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          ...validationErrors,
        },
      });
      return;
    }
    const { showNotification, refreshView } = this.props;
    const { open, dashboards, emails, ...body } = this.state;

    this.setState({ open: false }, () => {
      fetch(`${path}/admin/client_database/${this.state.id}`, { method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Token': localStorage.getItem('session'),
          'Content-Type': 'application/json; charset=utf-8',
        } })
        .then(res => res.json())
        .then(({ error }) => {
          if (error) {
            console.error(error);
            return showNotification(error, 'warning');
          }
          showNotification('clientDatabase.edit.successful');
          return refreshView();
        })
        .catch((e) => {
          console.error(e);
          showNotification('Error', 'warning');
        });
    });
  }

  dashboardsDataSource() {
    const { selectedDashboards } = this.state;
    return this.state.dashboards.filter(dashboard => !selectedDashboards.includes(dashboard));
  }

  handleBeforeAddCc = (chip) => {
    const { confirmationCc, emails } = this.state;
    const lcChip = chip.toLowerCase();

    if (!ValidateEmail(lcChip)) return false;
    return !confirmationCc.includes(lcChip) && emails.includes(lcChip);
  }

  changeDialogStatus = status => () => this.setState({ open: status });

  getEmails = () =>
    restClient(GET_LIST, 'users', {
      sort: {
        field: 'email',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    });

  getDashboards = () =>
    restClient(GET_LIST, 'dashboards', {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    });

  getSalesTeams = () =>
    restClient(GET_LIST, 'sales_teams', {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    });

  getSftpAccounts = () =>
    restClient(GET_LIST, 'sftp_accounts', {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    });

  handleOpen = async () => {
    this.initState();
    const [boards, emails, salesTeams, sftpAccounts] = await Promise.all([
      this.getDashboards(),
      this.getEmails(),
      this.getSalesTeams(),
      this.getSftpAccounts(),
    ]);
    this.setState({
      ...this.state,
      emails: Array.isArray(emails.data) && emails.data.map(e => e.email),
      dashboards: Array.isArray(boards.data) && boards.data.map(({ name, id }) => ({ name, id })),
      salesTeams: Array.isArray(salesTeams.data) && salesTeams.data.map(({ name }) => name),
      sftpAccounts: Array.isArray(sftpAccounts.data) && [{}, ...sftpAccounts.data.map(({ host, id }) => ({ host, id }))],
    });
  }

  statementDataSource() {
    const { emails, statementSigner, statementCc, statementBcc } = this.state;
    return emails.filter(
      email => !statementSigner.includes(email)
        && !statementCc.includes(email)
        && !statementBcc.includes(email),
    );
  }

  tradeRecapDataSource() {
    const { emails, to, cc, bcc } = this.state;
    return emails.filter(
      email => !to.includes(email)
        && !cc.includes(email)
        && !bcc.includes(email),
    );
  }

  confirmationDataSource() {
    const { emails, otcSigner, secondOtcSigner, otcCc, otcBcc } = this.state;
    return emails.filter(
      email => !otcSigner.includes(email)
        && !secondOtcSigner.includes(email)
        && !otcCc.includes(email)
        && !otcBcc.includes(email),
    );
  }

  confirmationViewersDataSource() {
    const { emails, otcViewers } = this.state;
    if (!otcViewers) return [];
    return emails.filter(email => !otcViewers.includes(email));
  }

  handleBeforeAddOtcSignersChip = type => (chip) => {
    const { otcSigner, otcBcc, otcCc, secondOtcSigner } = this.state;
    if (this.state[type].length || !ValidateEmail(chip)) return false;
    return !secondOtcSigner.includes(chip) && !otcSigner.includes(chip) && !otcBcc.includes(chip) && !otcCc.includes(chip);
  }

  handleAddSalesTeam = (chip) => {
    const { salesTeams } = this.state;
    if (!salesTeams.includes(chip)) return;
    const selectedSalesTeams = [...this.state.selectedSalesTeams, chip];
    this.setState({
      ...this.state,
      selectedSalesTeams,
    });
  };

  handleAddChips = type => (chip) => {
    const chips = [
      ...this.state[type],
      ((typeof chip === 'object') || (type === 'team')) ? chip : chip.toLowerCase(),
    ];
    this.setState({ [type]: chips });
  }

  handleBeforeAddChip = type => (chip) => {
    const selected = this.state[type];
    if (!ValidateEmail(chip)) return false;
    return !selected.includes(chip.toLowerCase());
  }

  handleToggle = inputName => () => {
    this.setState(
      { [inputName]: !this.state[inputName] },
    );
  }

  handleInputChange = inputName => (...event) => {
    this.setState(
      { [inputName]: removeSpecialChars(event[1]) },
      () => this.setState({ errors: { ...this.getValidationErrors() } }),
    );
  }

  handleAddDashboard = (dashboard) => {
    const selectedDashboards = [...this.state.selectedDashboards, dashboard];
    this.setState({
      ...this.state,
      selectedDashboards,
    });
  }

  handleDeleteSalesTeam = (salesTeam) => {
    const selectedSalesTeams = this.state.selectedSalesTeams.filter(s => s !== salesTeam);
    this.setState({
      ...this.state,
      selectedSalesTeams,
    });
  }

  salesTeamsDataSource() {
    const { emails = [], checkRecipients = [] } = this.state;
    return emails.filter(email => !checkRecipients.includes(email));
  }

  handleBeforeAddDashboardChip = () => !this.state.selectedDashboards.length

  handleSignerDeleteChips = type => (chip) => {
    const key = type === 'otcSigner' ? 'signerFullName' : 'secondSignerFullName';
    const chips = this.state[type].filter(c => c !== chip);
    this.setState({
      [type]: chips,
      [key]: '',
    });
  }

  handleAddSigners = type => (chip) => {
    const chips = [...this.state[type], chip.toLowerCase()];
    const session = localStorage.getItem('session');
    fetch(`${path}/admin/users/email`, { method: 'POST',
      body: JSON.stringify({ 'email': chips[0] }),
      headers: {
        'Token': session,
        'Content-Type': 'application/json; charset=utf-8',
      } })
      .then(response => response.json())
      .then((data) => {
        if (data && data.length !== 0) {
          const res = data[0];
          const key = type === 'otcSigner' ? 'signerFullName' : 'secondSignerFullName';
          this.setState({
            [key]: `${res.firstName} ${res.lastName}`,
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
    this.setState({
      [type]: chips,
    });
  }

  handleSelectChange = selected => (...event) => {
    const [, , value] = event;
    this.setState(
      { [selected]: value || null },
    );
  }

  render() {
    const { statementSigner
      , statementCc
      , statementBcc
      , selectedDashboards
      , selectedSalesTeams
      , otcViewers
      , otcCc
      , otcBcc
      , otcSigner
      , to
      , cc
      , bcc
      , signerFullName
      , clientName
      , address
      , entity
      , phoneNumber
      , clientLanguage
      , sales
      , agreementType
      , team
      , clientCode
      , secondOtcSigner
      , secondSignerFullName
      , contactName
      , sftpAccountId
      , sftpAccounts
      , recordType
      , isDefaultAccount
      ,
    } = this.state;

    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label={this.translate('clientDatabase.edit.buttonText')}
          icon={<EditIcon />}
          onClick={this.handleOpen}
        />
        <Dialog
          title={this.translate('clientDatabase.edit.title')}
          modal={false}
          autoScrollBodyContent={true}
          open={this.state.open}
        >
          <TextField
            errorText={this.state.errors.clientName && this.translate('aor.validation.required')}
            floatingLabelText={this.translate('clientDatabase.edit.clientName')}
            fullWidth
            name="clientName"
            type="text"
            multiLine={true}
            value={clientName}
            onChange={this.handleInputChange('clientName')}
            maxLength="50"
          />
          <ChipInput
            value={selectedDashboards}
            openOnFocus={true}
            floatingLabelText={this.translate('clientDatabase.edit.dashboard')}
            onBeforeRequestAdd={this.handleBeforeAddDashboardChip}
            dataSource={this.dashboardsDataSource()}
            onRequestAdd={this.handleAddDashboard}
            onRequestDelete={this.handleDeleteDashboard}
            dataSourceConfig={{ text: 'name', value: 'id' }}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <SelectField
            floatingLabelText={this.translate('clientDatabase.edit.sftpAccount')}
            fullWidth
            name="sftpAccountId"
            value={sftpAccountId}
            onChange={this.handleSelectChange('sftpAccountId')}
          >
            {sftpAccounts.map(x => <MenuItem key={x.id} value={x.id} primaryText={x.host} />)}
          </SelectField>
          <ChipInput
            value={statementSigner}
            openOnFocus={true}
            floatingLabelText={this.translate('clientDatabase.edit.statementSigner')}
            dataSource={this.statementDataSource()}
            onRequestAdd={this.handleAddChips('statementSigner')}
            onRequestDelete={this.handleDeleteChips('statementSigner')}
            onBeforeRequestAdd={this.handleBeforeAddChip('statementSigner')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={statementCc}
            openOnFocus={true}
            floatingLabelText={this.translate('clientDatabase.edit.statementCc')}
            dataSource={this.statementDataSource()}
            onRequestAdd={this.handleAddChips('statementCc')}
            onRequestDelete={this.handleDeleteChips('statementCc')}
            onBeforeRequestAdd={this.handleBeforeAddChip('statementCc')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={statementBcc}
            openOnFocus={true}
            floatingLabelText={this.translate('clientDatabase.edit.statementBcc')}
            dataSource={this.statementDataSource()}
            onRequestAdd={this.handleAddChips('statementBcc')}
            onRequestDelete={this.handleDeleteChips('statementBcc')}
            onBeforeRequestAdd={this.handleBeforeAddChip('statementBcc')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={selectedSalesTeams}
            openOnFocus={true}
            floatingLabelText={this.translate('clientDatabase.create.selectedSalesTeams')}
            dataSource={this.state.salesTeams}
            onRequestAdd={this.handleAddSalesTeam}
            onRequestDelete={this.handleDeleteSalesTeam}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <div className="popup-custom-card">
            <div className="card-lable">First Signer: <span>(Optional)</span></div>
            <ChipInput
              value={otcSigner}
              openOnFocus={true}
              floatingLabelText={this.translate('clientDatabase.edit.confirmationSigner')}
              dataSource={this.confirmationDataSource()}
              onBeforeRequestAdd={this.handleBeforeAddOtcSignersChip('otcSigner')}
              onRequestAdd={this.handleAddSigners('otcSigner')}
              onRequestDelete={this.handleSignerDeleteChips('otcSigner')}
              fullWidth
              fullWidthInput
              listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
              errorText={this.state.errors.otcSigner && this.translate('aor.validation.required')}
            />
            <TextField
              floatingLabelText={this.translate('clientDatabase.edit.signerFullName')}
              fullWidth
              name="signerFullName"
              type="text"
              value={signerFullName}
              multiLine={true}
              onChange={this.handleInputChange('signerFullName')}
              errorText={this.state.errors.signerFullName && this.translate('aor.validation.required')}
            />
          </div>
          <div className="popup-custom-card">
            <div className="card-lable">Second Signer: <span>(Optional)</span></div>
            <ChipInput
              value={secondOtcSigner}
              openOnFocus={true}
              floatingLabelText={this.translate('clientDatabase.edit.confirmationSigner')}
              dataSource={this.confirmationDataSource()}
              onBeforeRequestAdd={this.handleBeforeAddOtcSignersChip('secondOtcSigner')}
              onRequestAdd={this.handleAddSigners('secondOtcSigner')}
              onRequestDelete={this.handleSignerDeleteChips('secondOtcSigner')}
              fullWidth
              fullWidthInput
              listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
              errorText={this.state.errors.secondOtcSigner && this.translate('aor.validation.required')}
            />
            <TextField
              floatingLabelText={this.translate('clientDatabase.edit.signerFullName')}
              fullWidth
              name="secondSignerFullName"
              type="text"
              value={secondSignerFullName}
              multiLine={true}
              onChange={this.handleInputChange('secondSignerFullName')}
              errorText={this.state.errors.secondSignerFullName && this.translate('aor.validation.required')}
            />
          </div>
          <ChipInput
            value={otcCc}
            openOnFocus={true}
            floatingLabelText={this.translate('clientDatabase.edit.confirmationCc')}
            dataSource={this.confirmationDataSource()}
            onBeforeRequestAdd={this.handleBeforeAddChip('otcCc')}
            onRequestAdd={this.handleAddChips('otcCc')}
            onRequestDelete={this.handleDeleteChips('otcCc')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={otcBcc}
            openOnFocus={true}
            floatingLabelText={this.translate('clientDatabase.edit.confirmationBcc')}
            dataSource={this.confirmationDataSource()}
            onRequestAdd={this.handleAddChips('otcBcc')}
            onRequestDelete={this.handleDeleteChips('otcBcc')}
            onBeforeRequestAdd={this.handleBeforeAddChip('otcBcc')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={otcViewers}
            openOnFocus={true}
            floatingLabelText={this.translate('clientDatabase.edit.confirmationViewers')}
            dataSource={this.confirmationViewersDataSource()}
            onRequestAdd={this.handleAddChips('otcViewers')}
            onRequestDelete={this.handleDeleteChips('otcViewers')}
            onBeforeRequestAdd={this.handleBeforeAddChip('otcViewers')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={to}
            openOnFocus={true}
            floatingLabelText={this.translate('clientDatabase.edit.to')}
            dataSource={this.tradeRecapDataSource()}
            onRequestAdd={this.handleAddChips('to')}
            onRequestDelete={this.handleDeleteChips('to')}
            onBeforeRequestAdd={this.handleBeforeAddChip('to')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={cc}
            openOnFocus={true}
            floatingLabelText={this.translate('clientDatabase.edit.cc')}
            dataSource={this.tradeRecapDataSource()}
            onRequestAdd={this.handleAddChips('cc')}
            onRequestDelete={this.handleDeleteChips('cc')}
            onBeforeRequestAdd={this.handleBeforeAddChip('cc')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <ChipInput
            value={bcc}
            openOnFocus={true}
            floatingLabelText={this.translate('clientDatabase.edit.bcc')}
            dataSource={this.tradeRecapDataSource()}
            onRequestAdd={this.handleAddChips('bcc')}
            onRequestDelete={this.handleDeleteChips('bcc')}
            onBeforeRequestAdd={this.handleBeforeAddChip('bcc')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <TextField
            floatingLabelText={this.translate('clientDatabase.edit.address')}
            fullWidth
            name="address"
            type="text"
            value={address}
            multiLine={true}
            onChange={this.handleInputChange('address')}
          />
          <TextField
            floatingLabelText={this.translate('clientDatabase.edit.entity')}
            fullWidth
            name="entity"
            type="text"
            value={entity}
            multiLine={true}
            onChange={this.handleInputChange('entity')}
          />
          <TextField
            floatingLabelText={this.translate('clientDatabase.edit.phoneNumber')}
            fullWidth
            name="phoneNumber"
            type="text"
            value={phoneNumber}
            multiLine={true}
            onChange={this.handleInputChange('phoneNumber')}
          />
          <TextField
            floatingLabelText={this.translate('clientDatabase.edit.clientLanguage')}
            fullWidth
            name="clientLanguage"
            type="text"
            value={clientLanguage}
            multiLine={true}
            onChange={this.handleInputChange('clientLanguage')}
          />
          <TextField
            floatingLabelText={this.translate('clientDatabase.edit.sales')}
            fullWidth
            name="sales"
            type="text"
            value={sales}
            multiLine={true}
            onChange={this.handleInputChange('sales')}
          />
          <TextField
            floatingLabelText={this.translate('clientDatabase.edit.agreementType')}
            fullWidth
            name="agreementType"
            type="text"
            value={agreementType}
            multiLine={true}
            onChange={this.handleInputChange('agreementType')}
          />
          <TextField
            errorText={this.state.errors.clientCode && this.translate('aor.validation.required')}
            floatingLabelText={this.translate('clientDatabase.edit.clientCode')}
            fullWidth
            name="clientCode"
            type="text"
            value={clientCode}
            multiLine={true}
            onChange={this.handleInputChange('clientCode')}
          />
          <TextField
            floatingLabelText={this.translate('clientDatabase.edit.contactName')}
            fullWidth
            name="contactName"
            type="text"
            value={contactName}
            multiLine={true}
            onChange={this.handleInputChange('contactName')}
          />
          <TextField
            value={recordType}
            floatingLabelText={this.translate('clientDatabase.edit.recordType')}
            fullWidth
            name="recordType"
            type="text"
            multiLine={false}
            onChange={this.handleInputChange('recordType')}
          />
          <div style={{ paddingTop: '15px' }} >
            <Toggle
              toggled={isDefaultAccount}
              label={this.translate('clientDatabase.edit.isDefaultAccount')}
              onToggle={this.handleToggle('isDefaultAccount')}
            />
          </div>
          <ChipInput
            value={team}
            openOnFocus={true}
            floatingLabelText={this.translate('clientDatabase.edit.team')}
            onRequestAdd={this.handleAddChips('team')}
            onRequestDelete={this.handleDeleteChips('team')}
            fullWidth
            fullWidthInput
            listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
          />
          <div style={{ paddingTop: '15px', float: 'right' }} >
            <RaisedButton
              label={this.translate('pdfs.dialog.btnCancel')}
              onClick={this.changeDialogStatus(false)}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label={this.translate('pdfs.dialog.btnSubmit')}
              primary={true}
              icon={<SubmitIcon />}
              onClick={this.handleSubmit}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

EditButton.propTypes = {
  record: PropTypes.object,
  sendAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(EditButton);
