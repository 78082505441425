import { Datagrid, DateInput, DeleteButton, Filter, List, RefreshButton, Responsive, RichTextField, SimpleList, TextField, TextInput } from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import moment from 'moment';
import React from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateInput } from '../utils/dates';
import CheckStatementButton from './CheckStatementButton';
import CSVDownloadButton from './CSVDownloadButton';
import PDFDownloadButton from './PDFDownloadButton';
import PDFGenerateButton from './PDFGenerateButton';
import PDFGroupGenerateButton from './PDFGroupGenerateButton';
import PDFNotifyManyButton from './PDFNotifyManyButton';
import PDFSendButton from './PDFSendButton';
import PDFSendButtonWithoutPdf from './PDFSendButtonWithoutPdf';
import PDFSendManyButton from './PDFSendManyButton';
import StatementCreate from './StatementCreate';

const StatementsFilter = props => (
  <Filter {...props}>
    <TextInput
      label="dashboards.list.search"
      source="_q"
      alwaysOn
    />
    <TextInput
      source="client_name"
    />
    <TextInput
      source="reference_number"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="pulled_at"
      showTime
    />
  </Filter>
);

const StatementsAction = (props) => {
  const { filters, resource, showFilter, displayedFilters, filterValues } = props;
  return (
    <CardActions>
      <CheckStatementButton />
      <PDFNotifyManyButton/>
      <PDFSendManyButton/>
      <PDFGroupGenerateButton />
      {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
      <StatementCreate />
      <RefreshButton />
    </CardActions>
  );
};

const StatementsList = props => (
  <List {...props}
    actions={<StatementsAction />}
    filters={<StatementsFilter />}
    sort={{ field: 'created_at', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => r.name}
          secondaryText={r => r.url}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.created_at}</Moment>}
        />
      }
      medium={
        <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
          <TextField
            source="client_name"
          />
          <RichTextField
            source="reference_number"
          />
          <DateField
            formatDate={date => (date ? moment(date).format('DD MMMM YYYY HH:mm') : '')}
            source="pulled_at"
            showTime
          />
          <PDFGenerateButton />
          <PDFDownloadButton />
          <CSVDownloadButton />
          <PDFSendButton />
          <PDFSendButtonWithoutPdf />
          <DeleteButton />
        </Datagrid>
      }
    />
  </List>
);

export default StatementsList;
