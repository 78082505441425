import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  Datagrid,
  List,
  refreshView,
  Responsive,
  SimpleList,
  TextField,
  translate,
  DeleteButton,
  EditButton,
} from 'admin-on-rest';
import Seo from '../components/common/Seo';
import { ThemesFilters } from './components';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';
import { DateField } from '../helpers';
import { LinkedChips } from './../components';

const ThemesList = props => (
  <Fragment>
    <Seo title={'Themes'} />
    <List {...props}
      sort={{ field: 'name', order: 'DESC' }}
      filters={<ThemesFilters />}
      perPage={25}
      refreshView={true}
      title='Themes'
    >
      <Responsive
        small={
          <SimpleList
            primaryText={r => r.name}
            secondaryText={r => r.customer_code}
            tertiaryText={r => r.group}
          />
        }
        medium={
          <Datagrid
            bodyOptions={{ showRowHover: true }}
            options={{ height: '70vh' }}
            styles={datagridDefaultStyles}
          >
            <TextField source="id" />
            <TextField
              source="name"
            />
            <LinkedChips
              label="Footer"
              source="pagesData"
              titleField="name"
              reference="static_pages"
              target="id"
              elStyle={{
                margin: '2px',
              }}
            />
            <TextField
              source="domainName"
              label="domain"
              sortable={false}
            />
            <DateField
              formatDate={formatDateTimeInput}
              source="updatedAt"
              showTime
            />
            <DateField
              formatDate={formatDateTimeInput}
              source="createdAt"
              showTime
            />
            <DeleteButton />
            <EditButton />
          </Datagrid>
        }
      />
    </List>
  </Fragment>
);

const enhance = compose(
  translate,
  connect(null, {
    refreshView,
  }),
);

export default enhance(ThemesList);
