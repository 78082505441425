import { UPDATE } from 'admin-on-rest';

import { FX_CONTRACT_ACTIVE, FX_CONTRACT_DELIVERABLE } from './const';

export const FxUpdateStatus = (id, active) => ({
  type: FX_CONTRACT_ACTIVE,
  payload: { id: `${id}/${active}` },
  meta: { resource: 'fx_commodities', fetch: UPDATE, cancelPrevious: false },
});

export const FxUpdateDeliverable = (id, active) => ({
  type: FX_CONTRACT_DELIVERABLE,
  payload: { id: `${id}/deliverable/${active}` },
  meta: { resource: 'fx_commodities', fetch: UPDATE, cancelPrevious: false },
});

