const APPROVE_REJECT_STATUSES = ['requested', 'pending'];
const FX_STRUCTURE = 'forwards / ndfs';
const SWAP = 'swap';
const MINIFUTURE = 'minifuture';
const CALENDAR_SPREAD = 'calendar spread';
const ASIAN_SWAP = 'asian swap';
const BULLET_STRIP = 'strip bullet swap';

module.exports = {
  APPROVE_REJECT_STATUSES,
  FX_STRUCTURE,
  SWAP,
  CALENDAR_SPREAD,
  MINIFUTURE,
  ASIAN_SWAP,
  BULLET_STRIP
};
