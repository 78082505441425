import React from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'admin-on-rest';
import AutoComplete from 'material-ui/AutoComplete';

import {
  validateCode,
  validateMaxLengthCode,
  validateMaxLengthName,
  validateMinLengthCode,
  validateMinLengthName,
} from '../../utils/validations';

const Form = ({ toolbar, ...rest }) => (
  <SimpleForm toolbar={toolbar} {...rest}>
    <ReferenceInput
      key="client_id"
      source="client_id"
      reference="client_database"
      validate= {[required]}
      label="location.client">
      <AutocompleteInput
        optionText={r => `${r.client} ${r.client_code}`}
        optionValue="id"
        elStyle={{ width: '50%' }}
        options={
          {
            'listStyle': { 'maxHeight': '30vh', 'overflow': 'auto' },
            'onBlur': () => {},
            'fullWidth': true,
            'filter': AutoComplete.levenshteinDistance,
          }
        }/>
    </ReferenceInput>
    <TextInput
      label="location.locationCode"
      source="location_code"
      type="locationCode"
      validate={[
        required,
        validateMinLengthCode,
        validateMaxLengthCode,
        validateCode,
      ]}
      elStyle={{ width: '50%' }}
      options={{
        fullWidth: true,
      }}
    />
    <TextInput
      label="location.name"
      source="name"
      type="name"
      validate={[
        required,
        validateMinLengthName,
        validateMaxLengthName,
      ]}
      options={{
        fullWidth: true,
      }}
    />
  </SimpleForm>
);

export default Form;
