import React from 'react';
import { ContentAdd, NavigationArrowDropDown } from 'material-ui/svg-icons';

const AddUnderlying = (props) => {
  const { onClick, text, disabled = false } = props;
  return (<div className="add-underlying">
    <button className={disabled ? 'btn' : 'btn enable'} disabled={disabled} onClick={onClick}>
      { new RegExp('Group').test(text) ?
        <NavigationArrowDropDown className="add-group-btn"/> :
        <ContentAdd />
      }
      <span>{ text }</span>
    </button>
  </div>);
};

export default AddUnderlying;
