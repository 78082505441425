import { Datagrid, DateInput, Filter, List, Responsive, TextField, TextInput } from 'admin-on-rest';
import React from 'react';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput, formatDateInput, dateParser } from '../utils/dates';

const EqUnderlyingDataFilter = props => (
  <Filter {...props}>
    <TextInput
      source="bloomberg_ticker"
    />
    <TextInput
      source="commodity_contract"
    />
    <TextInput
      source="underlying_asset"
    />
    <TextInput
      source="volatility_bucket"
    />
    <TextInput
      source="axe_buy"
    />
    <TextInput
      source="axe_sell"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
      parse={dateParser}
    />
  </Filter>
);

class EqUnderlyingDataList extends React.Component {
  render() {
    return (<List {...this.props}
      filters={<EqUnderlyingDataFilter />}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={50}
    >
      <Responsive
        medium={
          <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
            <TextField
              source="bloomberg_ticker"
            />
            <TextField
              source="commodity_contract"
            />
            <TextField
              source="underlying_asset"
            />
            <TextField
              source="volatility_bucket"
            />
            <TextField
              source="axe_buy"
            />
            <TextField
              source="axe_sell"
            />
            <DateField
              formatDate={formatDateTimeInput}
              source="created_at"
            />
          </Datagrid>
        }
      />
    </List>);
  }
}

export default EqUnderlyingDataList;
