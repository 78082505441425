/* eslint-disable */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  Datagrid,
  List,
  refreshView,
  TextField,
  TextInput,
  DateInput,
  Filter,
  translate,
} from 'admin-on-rest';
import Seo from '../components/common/Seo';
import { DeleteButton, EditButton } from '../components';
import { datagridDefaultStyles } from '../theme/styles';
import { DateField } from '../helpers';
import { formatDateTimeInput, formatDateInput } from '../utils/dates';

const ButtonsWrapper = props => (
  <div style={{ width: '340px', overflow: 'hidden' }}>
    <DeleteButton {...props} />
    <EditButton {...props} />
  </div>
);

const Filters = props => (
  <Filter {...props}>
    <TextInput label="spPricingTemplate.search" source="_q" alwaysOn />
    <TextInput source="name" />
    <DateInput label="spPricingTemplate.created" options={{ formatDate: formatDateInput, }} source="createdAt" showTime />
  </Filter>
);

const PricingTemplateStructuredProductsList = props => (
  <Fragment>
    <Seo title="SP Pricing Templates" />
    <List
      {...props}
      sort={{ field: 'name', order: 'DESC' }}
      filters={<Filters />}
      perPage={25}
      refreshView={true}
      title="spPricingTemplate.title"
    >
      <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
        <TextField source="id" />
        <TextField source="name" label="spPricingTemplate.name" />
        <TextField source="maxTenorYear" label="Tenors" />
        <TextField source="maxUnderlyingNum" label="Underlyings" />
        <TextField source="maxNotionalUsd" label="Max Notional" />
        <DateField formatDate={formatDateTimeInput} source="updatedAt" showTime label="spPricingTemplate.updated" />
        <DateField formatDate={formatDateTimeInput} source="createdAt" showTime label="spPricingTemplate.created" />
        <ButtonsWrapper />
      </Datagrid>
    </List>
  </Fragment>
);

const enhance = compose(translate, connect(null, { refreshView }));

export default enhance(PricingTemplateStructuredProductsList);
