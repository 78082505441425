export const COLUMN_VERSION_CDB = 10;
/**
 * Action for manage columns in neon orders table.
 * Turn on/off column.
 * Reducer: neonOrdersReducer.
 * @param {String} col Column key
 * @param {Boolean} status Turn on/off
 */
export const NO_TOGGLE_COLUMN_CDB = 'NO_TOGGLE_COLUMN_CDB';
export const switchColumn = (col, status) => ({
  type: NO_TOGGLE_COLUMN_CDB,
  payload: {
    col,
    status,
  },
});

/**
 * Action for on/off all columns in neon orders table.
 * Turn on/off columns.
 * Reducer: neonOrdersReducer.
 * @param {Boolean} status Turn on/off
 */
export const NO_TOGGLE_ALL_COLUMNS_CDB = 'NO_TOGGLE_ALL_COLUMNS_CDB';
export const toogleAllColumns = status => ({
  type: NO_TOGGLE_ALL_COLUMNS_CDB,
  payload: status,
});
