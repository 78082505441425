import React, { memo, useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import request from 'superagent';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import HistoryIcon from 'material-ui/svg-icons/action/history';
import {
  translate as adminTranslate,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
} from 'admin-on-rest';
import path from '../../path';
import { ComponentEnhancer } from 'recompose';
import { StructureDataRecord, WrapperDataRecord } from '../types';
import { SpCommonDispatchProps } from './types';
import { isEmpty } from 'lodash';
import { mapChangesToDisplay } from './utils';

interface CheckForUpdatesOwnProps {
  record?: StructureDataRecord | WrapperDataRecord;
  apiSource: string;
  apiSourcePrefix: string;
}

type CheckForUpdatesButtonProps = CheckForUpdatesOwnProps & SpCommonDispatchProps;

enum STAGES {
  CHECK = 'check',
  UPDATE = 'update',
}

const CONFIGURABLE_PREFIX = 'configurableSp';

const CheckForUpdatesButton: React.FC<CheckForUpdatesButtonProps> = memo(({
  record = {},
  translate,
  showNotification,
  refreshView,
  apiSource,
  apiSourcePrefix,
}) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [stage, setStage] = useState(STAGES.CHECK);
  const [changes, setChanges] = useState<any>(null);
  const [changeObj, setChangeObj] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const translationsPrefix = `${CONFIGURABLE_PREFIX}.checkForUpdates`;

  const handleOpen = async () => {
    setOpen(true);
    setErrorMessage('');
  };

  const closeDialog = () => {
    setOpen(false);
    setStage(STAGES.CHECK);
  };

  const handleUpdate = () => {
    setSubmitting(true);
    const fields: { syncWithMscs: boolean } = { syncWithMscs: true };

    request
      .put(`${path}/admin/${apiSource}/${record?.id}`)
      .set('Token', localStorage.getItem('session'))
      .field(fields)
      .then(() => {
        showNotification(`${translationsPrefix}.successful`);
        refreshView();
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage(translate(`${translationsPrefix}.error.generic`, 'warning'));
      })
      .finally(() => {
        setSubmitting(false);
        closeDialog()
        refreshView()
      });
  };

  const handleCheck = () => {
    setSubmitting(true);
    setErrorMessage('');

    request
      .get(`${path}/admin/${apiSource}${apiSourcePrefix}${record?.id}`)
      .set('Token', localStorage.getItem('session'))
      .then((res) => {
        setChanges(res.body);
        showNotification(`${translationsPrefix}.successfullyReceivedChanges`);
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage(translate(`${translationsPrefix}.error.generic`, 'warning'));
      })
      .finally(() => {
        setSubmitting(false);
        setStage(STAGES.UPDATE);
      });
  };

  useEffect(() => {
    if (stage === STAGES.UPDATE) {
      if (!isEmpty(changes)) {
        mapChangesToDisplay(changes, setChangeObj, translate, translationsPrefix);
      }
    }

  }, [changes, stage])

  const btnIcon = <HistoryIcon />;

  return (
    <div style={{ float: 'left' }}>
      <FlatButton label={translate(`${translationsPrefix}.title`)} icon={btnIcon} onClick={handleOpen} />
      <Dialog
        title={translate(`${translationsPrefix}.title`)}
        modal={false}
        open={open}
        repositionOnUpdate={false}
        style={{ paddingTop: 0 }}
        bodyStyle={{ overflowY: 'auto' }}
      >
        {stage === STAGES.CHECK ?
          <p>{translate(`${translationsPrefix}.questionCheck`)}</p>
          : 
          changeObj ? (
            <>
              <h3 style={{ color: 'black'}}>{translate(`${translationsPrefix}.availableUpdates`)}</h3>
              {
                changeObj?.map((element) => element)
              }
            </>
          ) 
          :
          (
            <p>{translate(`${translationsPrefix}.noUpdatesAvailable`)}</p>
          )
        }
        
        <div style={{ color: 'red', padding: '12px', fontWeight: 600 }}>{errorMessage}</div>
        <div style={{ paddingTop: '15px', float: 'right' }}>
          <RaisedButton
            label={translate('configurableSp.dialog.btnCancel')}
            onClick={closeDialog}
            icon={<CancelIcon />}
          />
          <RaisedButton
            type="submit"
            style={{ marginLeft: '10px' }}
            label={
              stage === STAGES.CHECK 
              ? translate(`${translationsPrefix}.checkLabel`) 
              : translate(`${translationsPrefix}.updateLabel`)
            }
            primary={true}
            icon={<SubmitIcon />}
            disabled={
              submitting ||  
              (isEmpty(changes) && stage === STAGES.UPDATE)
            }
            onClick={
              stage === STAGES.CHECK ? handleCheck : handleUpdate    
            }
          />
        </div>
      </Dialog>
    </div>
  );
});

const enhance: ComponentEnhancer<CheckForUpdatesButtonProps, CheckForUpdatesOwnProps> = compose(
  adminTranslate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(CheckForUpdatesButton);
