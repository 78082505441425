import React from 'react';
import Avatar from 'material-ui/Avatar';
import UserIcon from 'material-ui/svg-icons/social/group-add';
import Subheader from 'material-ui/Subheader';

import { translate } from 'admin-on-rest';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import { List, ListItem } from 'material-ui/List';

const avatar = r =>
  <Avatar src={`https://ui-avatars.com/api/?name=${r.firstName}+${r.lastName}&background=808080&size=32x32&font-size=0.33&color=ffffff`} />;

class PendingUser extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  render() {
    const { users = [], count = 0 } = this.props;
    return (
      <Card
        style={{ flex: 1, marginLeft: '1em' }}
      >
        <CardHeader
          title={count}
          subtitle={this.translate('dashboard.pending_user.subtitle')}
          avatar={<Avatar backgroundColor="#ff9800" icon={<UserIcon />} />}
          actAsExpander={true}
          showExpandableButton={true}
        />
        <CardText
          expandable={true}
          actAsExpander={false}
          style={{ padding: '0' }}
        >
          <List style={{ height: 288, overflow: 'auto' }}>
            {users.length ? users.map(r => <ListItem
              href={`#/users/${r.id}/show`}
              leftAvatar={avatar(r)}
              primaryText={`${r.firstName} ${r.lastName}`}
              secondaryText={r.email}
              key={r.id}
            />) : <Subheader>{this.translate('dashboard.pending_user.empty')}</Subheader>}
          </List>
        </CardText>
      </Card>
    );
  }
}

export default translate(PendingUser);
