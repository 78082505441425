/* eslint-disable */
import React from 'react';
import { Show, Create, Edit, translate, showNotification as showNotificationAction } from 'admin-on-rest';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { SpreadsheetForm } from './SpreadSheetsForm';

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
  })
);

export const SpreadSheetsCreate = enhance(
  translate(props => (
    <Create title="Create Spreadsheet" {...props}>
      <SpreadsheetForm {...props} />
    </Create>
  ))
);

export const SpreadSheetsEdit = enhance(
  translate(props => (
    <Edit title="Edit Spreadsheet" {...props}>
      <SpreadsheetForm {...props} />
    </Edit>
  ))
);

export const SpreadSheetsShow = enhance(
  translate(props => (
    <Show title="View Spreadsheet" {...props}>
      <SpreadsheetForm {...props} isReadOnly={true} />
    </Show>
  ))
);

export { default as SpreadSheetsList } from './SpreadSheetsList';
export { default as SpreadSheetsHistory } from './SpreadSheetsHistory';
