import React from 'react';
import { Show, SimpleShowLayout, translate, TextField, NumberField, DateField, FunctionField } from 'admin-on-rest';
import { compose } from 'redux';
import { formatDateTimeInput } from 'utils/dates';

const MinifutureShowTitle = translate(({ record }) => (
  <span>
    {translate('minifutureProducts.show.productShow', { smart_count: 1 })} {`${record.underlying}`}
  </span>
));

const MinifuturesProductsShow = (props) => {
  const { translate } = props;
  return (
    <Show {...props} title={<MinifutureShowTitle />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="underlying" label={translate('minifutureProducts.list.commodityCode')} />
        <TextField source="structureName" label={translate('minifutureProducts.list.structureName')} />
        <TextField source="externalId" label={translate('minifutureProducts.list.externalId')} />
        <TextField source="type" label={translate('minifutureProducts.list.type')} />
        <NumberField
          source="strike"
          label={translate('minifutureProducts.list.strike')}
          style={{ textAlign: 'left' }}
        />
        <NumberField
          source="clientBarrierLevel"
          label={translate('minifutureProducts.list.clientBarrierLevel')}
          style={{ textAlign: 'left' }}
        />
        <TextField source="clientCode" label={translate('minifutureProducts.list.clientCode')} />
        <FunctionField
          label={translate('minifutureProducts.list.expiryDates')}
          render={(record) =>
            record.fx_value_date
              ? formatDateTimeInput(record.fxValueDate)
              : formatDateTimeInput(record.contractExpiry)
          }
          showTime
        />
        <TextField source="ratio" label={translate('minifutureProducts.list.ratio')} />
        <FunctionField
          source="isAgileProduct"
          label={translate('minifutureProducts.list.isAgileProduct')}
          render={(record) => (record.is_agile_product ? 'Yes' : 'No')}
        />
        <FunctionField
          source="qtoValue"
          label={translate('minifutureProducts.list.qtoValue')}
          render={(record) => record.qto_value ?? 0}
        />
        <FunctionField
          source="financing"
          label={translate('minifutureProducts.list.financing')}
          render={(record) => record.financing ?? 0}
        />
        <FunctionField
          source="div"
          label={translate('minifutureProducts.list.div')}
          render={(record) => record.div ?? 0}
        />
        <NumberField
          source="issueSize"
          label={translate('minifutureProducts.list.issueSize')}
          style={{ textAlign: 'left' }}
        />
        <TextField source="ccy" label={translate('minifutureProducts.list.ccy')} />
        <NumberField source="version" label={translate('minifutureProducts.list.version')} />
        <TextField source="status" label={translate('minifutureProducts.list.status')} />
        <DateField
          formatDate={formatDateTimeInput}
          label={translate('minifutureProducts.list.updatedAt')}
          source="updatedAt"
          showTime
        />
        <DateField
          formatDate={formatDateTimeInput}
          label={translate('minifutureProducts.list.createdAt')}
          source="createdAt"
          showTime
        />
      </SimpleShowLayout>
    </Show>
  );
};

export const MinifutureProductsShow = compose(translate)(MinifuturesProductsShow);
