import { Create } from 'admin-on-rest';
import LivePositionForm from './LivePositionForm';

export { LivePositionList } from './LivePositionList';

export const LivePositionCreate = (props) =>
  <Create {...props} title="Create Live Position">
    <LivePositionForm {...props} />
  </Create>;

