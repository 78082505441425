import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'material-ui/Chip';
import { Link } from 'react-router-dom';
import muiThemeable from 'material-ui/styles/muiThemeable';
import compose from 'recompose/compose';

/**
 * For instance, if the API returns the following ‘book’ record:
 * {
 *  id: 1234,
 *  title: 'War and Peace',
 *  author: [{
 *      id: 1,
 *      name: 'Tolstoi'
 *  }
 * }
 * @example Display all the books by the current author
 * <LinkedChips source="author" titleField="name" reference="author" target="id" />
 */
const linkToRecord = (basePath, id) => (id ? `${basePath}/${encodeURIComponent(id)}` : null);

const getStyles = muiTheme => ({
  link: {
    color: muiTheme.palette.accent1Color,
  },
});

const getChipLable = (name, href, linkStyle) => (href ?
  <Link style={linkStyle} to={href} >{name}</Link>
  :
  name);

const ChipField = ({ elStyle, linkStyle, chip, titleField, reference, target, rootPath }) => (
  <Chip style={elStyle}>
    {getChipLable(
      chip[titleField] || '',
      linkToRecord(`${rootPath}/${reference}`, chip[target]),
      linkStyle,
    )}
  </Chip>
);

const LinkedChips = ({ basePath, record, source, muiTheme, elStyle, ...rest }) => {
  const records = record[source] || [];
  const linkStyle = { ...getStyles(muiTheme).link, ...elStyle.link || {} };
  const rootPath = basePath.split('/').slice(0, -1).join('/');

  return (<div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {records.map((item, index) => <ChipField
      key={index}
      rootPath={rootPath}
      linkStyle={linkStyle}
      chip={item}
      elStyle={elStyle}
      {...rest}/>)}
  </div>);
};

LinkedChips.propTypes = {
  source: PropTypes.string.isRequired,
  titleField: PropTypes.string,
  reference: PropTypes.string,
  target: PropTypes.string,
  elStyle: PropTypes.object,
};

LinkedChips.defaultProps = {
  titleField: 'title',
  elStyle: {},
};

export default compose(
  muiThemeable(),
)(LinkedChips);
