import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Message from './Message';

const MessageList = ({ onRead, onDelete, items }) => (
  <Fragment>
    {items.map((card, key) => <Message key={key} {...card} onRead={onRead} onDelete={onDelete} />)}
  </Fragment>
);

MessageList.propTypes = {
  items: PropTypes.array.isRequired,
  onRead: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

MessageList.defaultProps = {
  items: [],
};

export default MessageList;
