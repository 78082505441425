import React, { Component } from 'react';
import FileSaver from 'file-saver';
import FlatButton from 'material-ui/FlatButton';

import path from '../path';
import { json2csv } from '../utils/json2csv';

class ExportCSVButton extends Component {
  generateFile = () => {
    const { apiEndpoint, saveAs, jsonRemapFn } = this.props;

    fetch(`${path}/${apiEndpoint}`, {
      method: 'GET',
      headers: {
        'token': localStorage.getItem('session'),
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
      .then(res => res.json())
      .then((json) => {
        const data = json.map(jsonRemapFn);
        const csv = json2csv(data);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        FileSaver.saveAs(blob, saveAs);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const { buttonLabel } = this.props;

    return (
      <div style={{ display: 'inline-block' }}>
        <FlatButton primary label={buttonLabel} onClick={this.generateFile} />
      </div>
    );
  }
}

export default ExportCSVButton;
