import { UPDATE } from 'admin-on-rest';

import { STATEMENT_SEND_MANY } from './PDFAction';

const PDFSendManyAction = (data, sendWithoutPdf = true) => ({
  type: STATEMENT_SEND_MANY,
  payload: { id: '', data, sendWithoutPdf },
  meta: { resource: 'statements/send_all', fetch: UPDATE, cancelPrevious: false },
});

export default PDFSendManyAction;
