import { mapKeys, snakeCase } from "lodash";

export const LP_UPDATE = 'LP_UPDATE';


export const updateLivePosition = (fields) => {
  const data = mapKeys(fields, (value, key) => snakeCase(key));

  return {
    type: LP_UPDATE,
    payload: {
      id: fields.id,
      data,
    }
  }
}