import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import compose from 'recompose/compose';
import ChipInput from 'material-ui-chip-input';
import Dialog from 'material-ui/Dialog';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import DoneAllIcon from 'material-ui/svg-icons/action/done-all';
import ClearAllIcon from 'material-ui/svg-icons/content/clear';

import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';

import { connect } from 'react-redux';
import { translate, GET_LIST, refreshView } from 'admin-on-rest';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import PDFGroupGenerateIcon from './PDFGroupGenerateIcon';
import PDFGroupGenerateAction from './PDFGroupGenerateAction';

import restClient from '../restClient';
import socket from 'ioClient';

const styleLoader = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  position: 'fixed',
  zIndex: 1,
  left: '270px',
  top: 0,
  backgroundColor: 'rgba(255,255,255, 0.8)',
  overflowX: 'hidden',
  transition: '0.5s',
};

class PDFGroupGenerateButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      load: false,
      errors: {
        generalDashboards: false,
        checkDashboards: false,
      },
      dashboards: [],
      chips: [],
      checkChips: [],
      ids: [],
      checkDashboards: [],
    };

    this.translate = this.props.translate;
    this.socket = socket;
  }

  isLoad() {
    return this.state.load;
  }

  isClear() {
    const { chips, dashboards } = this.state;

    const ids = dashboards.map(i => i.id);
    const dif = chips.filter(i => !ids.includes(i.id));

    return !dif.length;
  }

  handleClear = () => {
    this.setState({ dashboards: [] });
  }

  handleSelect = () => {
    const { chips } = this.state;
    this.setState({ dashboards: chips });
  }

  handleOpen = () => {
    this.setState({ open: true });

    restClient(GET_LIST, 'dashboards', {
      sort: {
        field: 'id',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((resA) => {
      this.setState({ chips: resA.data, checkChips: resA.data });
    });

    restClient(GET_LIST, 'client_database/checkDashboards', {
      sort: {
        field: 'email',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((res) => {
      this.setState({ checkDashboards: res.data });
    });

    restClient(GET_LIST, 'client_database/dashboards', {
      sort: {
        field: 'id',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    }).then((res) => {
      this.setState({ dashboards: res.data });
    });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  handleSubmit = type => () => {
    if (this.state[type].length) {
      this.setState({
        open: false,
      });
    } else {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          [type]: true,
        },
      });
      return;
    }

    const dashboards = this.state[type];
    const session = localStorage.getItem('session');

    this.props.generateAction({ dashboards, session });
  }

  handleAddDashboard = type => (chip) => {
    const chips = [...this.state[type], chip];
    this.setState({ [type]: chips });
  }

  handleDeleteDashboard = type => (chip) => {
    const chips = this.state[type].filter(i => i.id !== chip);
    this.setState({ [type]: chips });
  }

  handleBeforeAddDashboard = type => (chip) => {
    const chips = this.state[type];
    return !chips.map(i => i.id).includes(chip.id);
  }

  componentDidMount() {
    this.socket.on('group-pdfs-loader', (data) => {
      this.setState({ load: data });
    });

    this.socket.on('group-pdfs-update', () => {
      this.props.refreshView();
    });
  }

  componentWillUnmount() {
    this.socket.close();
  }

  dataSource(dashboard, chip) {
    const ids = this.state[dashboard].map(i => i.id);
    return this.state[chip].filter(c => !ids.includes(c.id));
  }

  render() {
    const { open, dashboards, checkDashboards } = this.state;
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label={this.translate('pdfs.dialog.btnGenerate')}
          icon={<PDFGroupGenerateIcon />}
          onClick={this.handleOpen}
        />
        {this.isLoad() ? <div style={styleLoader}><CircularProgress size={80} thickness={5} style={{ right: '15%' }}/><p style={{ position: 'relative', color: 'rgb(0, 188, 212)', fontSize: '14px', right: '15%' }}>{this.translate('pdfs.dialog.loader')}</p></div> : ''}
        <Dialog
          title={this.translate('pdfs.dialog.titleGenerate')}
          modal={false}
          open={open}
          autoScrollBodyContent={true}
        >
          <Tabs>
            <TabList>
              <Tab>General</Tab>
              <Tab>Check statements</Tab>
            </TabList>
            <TabPanel>
              <ChipInput
                value={dashboards}
                dataSource={this.dataSource('dashboards', 'chips')}
                openOnFocus={true}
                dataSourceConfig={{ text: 'name', value: 'id' }}
                onBeforeRequestAdd={this.handleBeforeAddDashboard('dashboards')}
                onRequestAdd={this.handleAddDashboard('dashboards')}
                onRequestDelete={this.handleDeleteDashboard('dashboards')}
                floatingLabelText={this.translate('pdfs.dialog.textDashboards')}
                errorText={this.state.errors.generalDashboards && this.translate('aor.validation.required')}
                fullWidth
                fullWidthInput
                listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
              />
              <div
                style={{ paddingTop: '15px', float: 'right' }}
              >
                {this.isClear() ?
                  <RaisedButton
                    label={this.translate('pdfs.dialog.btnClearAll')}
                    onClick={this.handleClear}
                    icon={<ClearAllIcon />}
                  />
                  :
                  <RaisedButton
                    label={this.translate('pdfs.dialog.btnSelectAll')}
                    onClick={this.handleSelect}
                    icon={<DoneAllIcon />}
                  />
                }
                <RaisedButton
                  style={{ marginLeft: '10px' }}
                  label={this.translate('pdfs.dialog.btnCancel')}
                  onClick={this.handleClose}
                  icon={<CancelIcon />}
                />
                <RaisedButton
                  type="submit"
                  style={{ marginLeft: '10px' }}
                  label={this.translate('pdfs.dialog.btnSubmit')}
                  primary={true}
                  onClick={this.handleSubmit('dashboards')}
                  icon={<SubmitIcon />}
                />
              </div>
            </TabPanel>
            <TabPanel>
              <ChipInput
                value={checkDashboards}
                dataSource={this.dataSource('checkDashboards', 'checkChips')}
                openOnFocus={true}
                dataSourceConfig={{ text: 'name', value: 'id' }}
                onBeforeRequestAdd={this.handleBeforeAddDashboard('checkDashboards')}
                onRequestAdd={this.handleAddDashboard('checkDashboards')}
                onRequestDelete={this.handleDeleteDashboard('checkDashboards')}
                floatingLabelText={this.translate('pdfs.dialog.textDashboards')}
                errorText={this.state.errors.checkDashboards && this.translate('aor.validation.required')}
                fullWidth
                fullWidthInput
                listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
              />
              <div
                style={{ paddingTop: '15px', float: 'right' }}
              >
                <RaisedButton
                  style={{ marginLeft: '10px' }}
                  label={this.translate('pdfs.dialog.btnCancel')}
                  onClick={this.handleClose}
                  icon={<CancelIcon />}
                />
                <RaisedButton
                  style={{ marginLeft: '10px' }}
                  label={this.translate('pdfs.dialog.btnSubmit')}
                  primary={true}
                  onClick={this.handleSubmit('checkDashboards')}
                  icon={<SubmitIcon />}
                />
              </ div>
            </TabPanel>
          </Tabs>
        </Dialog>
      </div>
    );
  }
}

PDFGroupGenerateButton.propTypes = {
  record: PropTypes.object,
  generateAction: PropTypes.func,
  translate: PropTypes.func,
};

const enhance = compose(
  translate,
  connect(null, {
    refreshView,
    generateAction: PDFGroupGenerateAction,
  }),
);

export default enhance(PDFGroupGenerateButton);
