import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from 'material-ui/svg-icons/image/edit';
import FlatButton from 'material-ui/FlatButton';
// eslint-disable-next-line
import { withRouter, Link } from 'react-router-dom';

class EditButton extends React.Component {
  constructor(props) {
    super(props);

    this.translate = this.props.translate;
  }

  render() {
    const { record: { id } } = this.props;

    return (
      <FlatButton
        primary
        icon={<EditIcon/>}
        style={{ float: 'left', minWidth: '30px' }}
        containerElement={
          <Link to={`/neon_orders/${id}`}/>
        }
      />
    );
  }
}

EditButton.propTypes = {
  record: PropTypes.object,
};

export default withRouter(EditButton);
