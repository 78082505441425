import { BooleanField, Datagrid, DeleteButton, FunctionField, List, refreshView, TextField, translate } from 'admin-on-rest';
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput } from '../utils/dates';
import LogActions from './LogActions';
import LogDetails from './LogDetails';
import LogFilters from './LogFilters';

const formatDescription = (str) => {
  try {
    str = JSON.parse(str);
    str = <p style={{ padding: '0', margin: '0', fontSize: '10px' }} dangerouslySetInnerHTML={{ __html: `REQ: ${JSON.stringify(str.REQ).substring(0, 100)}<br/>RES: ${JSON.stringify(str.RES).substring(0, 100)}` }}></p>;
  } catch (err) {
    str = '';
  }
  return str;
};

const LogList = props => (
  <List {...props}
    filters={<LogFilters />}
    sort={{ field: 'id', order: 'DESC' }}
    title="Logs list"
    perPage={50}
    actions={<LogActions />}
  >
    <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
      <TextField
        source="id"
      />
      <TextField
        source="name"
      />
      <FunctionField
        source="description"
        render={r => formatDescription(r.description)}
      />
      <BooleanField
        source="status"
      />
      <DateField
        formatDate={formatDateTimeInput}
        source="created_at"
        showTime
      />
      <FunctionField
        label=""
        source="description"
        render={r => <LogDetails record={r} />}
      />
      <DeleteButton />
    </Datagrid>
  </List>
);

const enhance = compose(
  translate,
  connect(null, {
    refreshView,
  }),
);

export default enhance(LogList);

