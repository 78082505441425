import React from 'react';
import PropTypes from 'prop-types';
import {
  ReferenceArrayInput,
  SelectArrayInput,
} from 'admin-on-rest';
import { SectionBox, SingleCheckbox } from './../components';

const PositionFooter = props => (
  <SectionBox {...props}>
    <SingleCheckbox
      source="defaultFooter"
      labelGroup="Default footer page"
      label="We will show that page for users without an assigned theme"
    />
    <ReferenceArrayInput
      source="themes"
      reference="themes"
      label="Themes"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={20}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
  </SectionBox>
);


PositionFooter.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
};

export default PositionFooter;
