import React from 'react';

class TagButton extends React.Component {
  constructor(props) {
    super(props);
    const tag = props.getPrimaryText(props);

    this.state = {
      text: tag,
      tag,
    };
  }

  copyToClipboard = () => {
    const textField = document.createElement('textarea');
    textField.innerText = this.state.tag;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    this.setState(
      { text: 'Copied' },
      () => setTimeout(() => { this.setState({ text: this.state.tag }); }, 2000));
  }

  render() {
    return (
      <span style={{ cursor: 'pointer' }} onClick={this.copyToClipboard}>{this.state.text}</span>
    );
  }
}

export default TagButton;
