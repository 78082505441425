import React from 'react';
import { Edit, translate } from 'admin-on-rest';
import { PricingCardForm } from './components';

const Title = translate(({ record }) =>
  <span>Edit CM Pricing Card - { record.description }</span>);

export const PricingCardEdit = props => (
  <Edit {...props} title={<Title />}>
    <PricingCardForm />
  </Edit>
);
