import ReactJson from 'react-json-view';

export const formatDescription = (str) => {
  if (!str) return null;
  return (
    <div>
      <ReactJson src={str}
        theme="monokai"
        displayDataTypes={true}
        collapseStringsAfterLength={80}
        collapsed={3} />
    </div>
  );
};
