import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import FlatButton from 'material-ui/FlatButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import ChipInput from 'material-ui-chip-input';
import RaisedButton from 'material-ui/RaisedButton';
import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';
import CreateButtonIcon from 'material-ui/svg-icons/content/reply';
import EditIcon from 'material-ui/svg-icons/image/edit';
import {
  translate,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
  GET_LIST,
  GET_ONE,
  BooleanInput,
} from 'admin-on-rest';
import path from '../path';
import restClient from '../restClient';

const LABEL = 'label';
const ACTION = {
  ADD: 'ADD',
  DELETE: 'DELETE',
};

const PREMIUM_FIELDS = [{
  field: 'premium_label',
  label: 'Premium Label',
}, {
  field: 'premium_default_value',
  label: 'Premium Default Value',
}, {
  field: 'premium_boundaries',
  label: 'Premium Boundaries',
}];

class PricingCardViewTemplatesCreateEditButton extends React.Component {
  defaultState = {
    open: false,
    submitting: false,
    id: null,
    templates: [],
    structuresData: [],
    structures: [],
    templateId: null,
    title: '',
    isPremium: false,
    fields: [],
    premiumFields: null,
    description: '',
    errors: {},
  };

  constructor(props) {
    super(props);
    this.translate = this.props.translate;
    this.state = this.defaultState;
    this.translationsPrefix = (props.edit && 'pricingCardViewTemplates.edit') || 'pricingCardViewTemplates.create';
  }

  handleOpen = async () => {
    this.setState({ open: true });
    const record = this.props.record;
    if (record && record.id) {
      const { data: structuresData } = await this.getStructures(record.pricingTemplateId);
      this.setState({
        id: record.id,
        templateId: record.pricingTemplateId,
        isPremium: record.isPremium,
        structures: record.structures,
        premiumFields: record.premiumAmount,
        structuresData,
        description: record.description,
        fields: record.labels.reduce((acc, el, i) => {
          acc.push({ [`${LABEL}_${i + 1}`]: el });
          return acc;
        }, []),
      });
    }
    const templates = await this.getCardTemplates();
    this.setState({
      templates: templates.data,
    });
  };

  closeDialog = () => {
    this.setState({ ...this.defaultState });
  };

  mergeArrayObjects(arr1, arr2) {
    const arr = [];
    // eslint-disable-next-line
    for (const { structure, id } of arr1) {
      const inputs = arr2.find(i => i.id === id).inputs;
      arr.push({
        structure,
        id,
        inputs: inputs ? inputs.length : 0,
      });
    }

    return arr;
  }

  handleInputChange = (i, inputName) => (...event) => {
    const value = event[1];
    const fields = this.state.fields;
    fields[i - 1][inputName] = value;
    this.setState({ fields }, () =>
      this.setState({ errors: { ...this.getValidationErrors() } }),
    );
  };

  handleDescriptionInputChange = (...event) => {
    this.setState({ description: event[1] }, () =>
      this.setState({ errors: { ...this.getValidationErrors() } }),
    );
  };

  handlePremiumInputChange = (i, inputName) => (...event) => {
    const value = event[1];
    const premiumFields = this.state.premiumFields;
    premiumFields[inputName] = value;
    this.setState({ premiumFields }, () =>
      this.setState({ errors: { ...this.getValidationErrors() } }),
    );
  };

  handleToggle = (inputName, value) => {
    this.setState({ [inputName]: value });
    if (value) {
      this.setState({ premiumFields: PREMIUM_FIELDS.reduce((acc, el) => {
        acc[el.field] = '';
        return acc;
      }, {}) });
    } else {
      this.setState({ premiumFields: this.defaultState.premiumFields });
    }
  };

  handleSelectChange = selected => async (...event) => {
    const [, , value] = event;
    const { data: structuresData } = await this.getStructures(value);
    this.setState({
      [selected]: value,
      structuresData,
      structures: [],
      fields: [],
    }, () => this.setState({ errors: { ...this.getValidationErrors() } }));
  };

  getValidationErrors() {
    const { templateId, structures, fields, isPremium, premiumFields, structuresData } = this.state;
    const validationErrors = {};
    const tasStructuresUsingMSA = structuresData?.filter(({ id: selectedId, useMsa, xmlType }) => structures?.find(({id}) => id === selectedId && useMsa && xmlType.toLowerCase() === 'tas'));

    if (!templateId) validationErrors.templateId = true;
    if (!structures.length) validationErrors.structures = true; 
    if (!fields.length && isEmpty(tasStructuresUsingMSA)) validationErrors.structures = true;

    if (fields && fields.length) {
      // eslint-disable-next-line
      for (let index = 0; index < fields.length; index++) {
        const element = fields[index][`${LABEL}_${index + 1}`];
        if (!element.trim()) validationErrors[`${LABEL}_${index + 1}`] = true;
      }
    }

    if (isPremium) {
      // eslint-disable-next-line
      for (const property in premiumFields) {
        const element = premiumFields[property];
        if (!element.trim()) validationErrors[property] = true;
      }
    }

    return validationErrors;
  }

  handleSubmit = () => {
    const validationErrors = this.getValidationErrors();
    if (Object.keys(validationErrors).length) {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          ...validationErrors,
        },
      });
      return;
    }

    const { showNotification, refreshView } = this.props;
    const {
      structures,
      templateId,
      isPremium,
      fields,
      premiumFields,
      description,
    } = this.state;

    const labelArrayValues = fields.map((el, index) => el[`${LABEL}_${index + 1}`]);
    const mappedStructureIds = structures.map(i => i.id);

    const payload = {
      pricingTemplateId: templateId,
      structureIds: mappedStructureIds,
      isPremium,
      labels: labelArrayValues,
      premiumAmount: premiumFields,
      description: isEmpty(description ? description.trim() : '') ? null : description.trim(),
    };

    this.setState({ submitting: true });
    const id = this.state.id || '';
    const method = (this.props.edit && 'PUT') || 'POST';
    fetch(`${path}/admin/pricing_card_view_templates/${id}`, {
      method,
      body: JSON.stringify(payload),
      headers: {
        'Token': localStorage.getItem('session'),
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then(res => res.json())
      .then(data => {
        if (!data.isSuccess) {
          showNotification(data.message, 'warning');
          return;
        }
        showNotification(`${this.translationsPrefix}.successful`);
        refreshView();
        this.setState({ ...this.defaultState });
      })
      .catch(e => {
        console.error(e);
        showNotification('Error', 'warning');
      })
      .then(() => this.setState({ submitting: false }));
  };

  structuresDataSource() {
    const { structuresData = [], structures } = this.state;
    return structuresData.filter(structure => !structures.includes(structure));
  }

  getStructures = (templateId) =>
    restClient(GET_ONE, 'pricing_card_view_templates/structures', { id: templateId });

  getCardTemplates= () =>
    restClient(GET_LIST, 'pricing_templates', {
      sort: {
        field: 'id',
        order: 'ASC',
      },
      pagination: {
        page: 1,
        perPage: 100000,
      },
    });

  handleAddChips = type => chip => {
    const chips = [...this.state[type], typeof chip === 'object' ? chip : chip.toLowerCase()];
    this.setState({ [type]: chips }, () => this.setState({ errors: { ...this.getValidationErrors() } }));
  };

  generateFields = (fields = [], inputs = [], action) => {
    const fieldsLength = fields ? fields.length : 0;
    const inputsLength = inputs ? inputs.length : 0;

    if (action === ACTION.DELETE) {
      if (fieldsLength && fieldsLength > inputsLength) {
        return fields.splice(0, fieldsLength - (fieldsLength - inputsLength));
      }
      if (fieldsLength >= inputsLength) {
        return fields;
      }
    }
    if (action === ACTION.ADD) {
      if (fieldsLength >= inputsLength) {
        return fields;
      }
      if (fieldsLength && fieldsLength < inputsLength) {
        let labelIndex = fieldsLength;
        // eslint-disable-next-line
        for (let index = 0; index < inputsLength - fieldsLength; index++) {
          labelIndex += 1;
          fields.push({ [`${LABEL}_${labelIndex}`]: '' });
        }
        return fields;
      }

      const empty = Array.from(Array(inputsLength).keys()).reduce((acc, el, i) => {
        acc.push({ [`${LABEL}_${i + 1}`]: '' });
        return acc;
      }, []);

      return empty;
    }
  }

  handleBeforeAddChip = type => chip => {
    const selected = this.state[type];
    const { inputs } = chip;
    const { fields } = this.state;
    const selectedCondition = !selected.includes(chip.structure.toLowerCase());
    if (selectedCondition) {
      this.setState({
        fields: this.generateFields(fields, inputs, ACTION.ADD),
      });
    }
    return selectedCondition;
  };

  handleDeleteChips = type => chip => {
    const chips = this.state[type].filter(c => c.id !== chip);
    const mergedArrayChips = this.mergeArrayObjects(chips, this.state.structuresData);
    this.setState({
      [type]: chips,
      fields: !chips.length ? [] : this.generateFields(this.state.fields, Array.from(Array(Math.max(...mergedArrayChips.map(i => i.inputs))).keys()), ACTION.DELETE),
    }, () => this.setState({ errors: { ...this.getValidationErrors() } }));
  };

  render() {
    const {
      structures,
      templates,
      templateId,
      isPremium,
      fields,
      errors,
      open,
      submitting,
      structuresData,
      premiumFields,
      description,
    } = this.state;

    const btnIcon = (this.props.edit && <EditIcon />) || <CreateButtonIcon />;
    return (
      <div style={{ float: 'left' }}>
        <FlatButton
          label={this.translate(`${this.translationsPrefix}.buttonText`)}
          icon={btnIcon}
          onClick={this.handleOpen}
        />
        <Dialog
          title={this.translate(`${this.translationsPrefix}.title`)}
          modal={false}
          autoScrollBodyContent={true}
          open={open}
          style={{ maxHeigh: '500px' }}
        >
          <SelectField
            errorText={errors.templateId && this.translate('aor.validation.required')}
            floatingLabelText={this.translate(`${this.translationsPrefix}.templateField`)}
            fullWidth
            name="template"
            value={templateId}
            onChange={this.handleSelectChange('templateId')}
          >
            {templates.map(x => (
              <MenuItem key={x.id} value={x.id} primaryText={x.name} />
            )).sort((a, b) => a.props.primaryText.localeCompare(b.props.primaryText))}
          </SelectField>
          {
            structuresData.length ? (
              <ChipInput
                errorText={errors.structures && this.translate('aor.validation.required')}
                value={structures}
                openOnFocus={true}
                dataSourceConfig={{ text: 'structure', value: 'id' }}
                floatingLabelText={this.translate(`${this.translationsPrefix}.structureField`)}
                dataSource={this.structuresDataSource()}
                onRequestAdd={this.handleAddChips('structures')}
                onRequestDelete={this.handleDeleteChips('structures')}
                onBeforeRequestAdd={this.handleBeforeAddChip('structures')}
                fullWidth
                fullWidthInput
                listStyle={{ maxHeight: '30vh', overflow: 'auto' }}
              />
            ) : null
          }
          <TextField
            key='description'
            floatingLabelText='Description'
            fullWidth
            name='description'
            type="text"
            value={description}
            multiLine={true}
            onChange={this.handleDescriptionInputChange}
          />
          {
            fields && fields.map((el, i) => {
              i += 1;
              return (
                <TextField
                  key={i}
                  errorText={this.state.errors[`${LABEL}_${i}`] && this.translate('aor.validation.required')}
                  floatingLabelText={`Label ${i}`}
                  fullWidth
                  name={`${LABEL}_${i}`}
                  type="text"
                  value={el[`${LABEL}_${i}`]}
                  onChange={this.handleInputChange(i, `${LABEL}_${i}`)}
                  maxLength="200"
                />
              );
            })
          }
          <div style={{ marginTop: '15px' }}>
            <BooleanInput
              input={{ value: isPremium, onChange: e => this.handleToggle('isPremium', e) }}
              label="Premium"
              source="value"
            />
            {isPremium && PREMIUM_FIELDS.map((el, index) => (<TextField
              key={index}
              errorText={this.state.errors[el.field] && this.translate('aor.validation.required')}
              floatingLabelText={el.label}
              fullWidth
              name={el.field}
              type="text"
              value={premiumFields[el.field]}
              onChange={this.handlePremiumInputChange(index, el.field)}
              maxLength="200"
            />)) }
          </div>

          <div style={{ paddingTop: '15px', float: 'right' }}>
            <RaisedButton
              label={this.translate('pricingCardViewTemplates.dialog.btnCancel')}
              onClick={this.closeDialog}
              icon={<CancelIcon />}
            />
            <RaisedButton
              type="submit"
              style={{ marginLeft: '10px' }}
              label={this.translate('pricingCardViewTemplates.dialog.btnSubmit')}
              primary={true}
              icon={<SubmitIcon />}
              disabled={submitting}
              onClick={this.handleSubmit}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
);

export default enhance(PricingCardViewTemplatesCreateEditButton);
