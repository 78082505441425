import { BooleanField, BooleanInput, Datagrid, DateInput, Filter, FunctionField, List, ReferenceInput, Responsive, SelectInput, SimpleList, TextField, TextInput } from 'admin-on-rest';
import Chip from 'material-ui/Chip';
import React from 'react';
import Moment from 'react-moment';
import { DateField } from '../helpers';
import { datagridDefaultStyles } from '../theme/styles';
import { formatDateTimeInput, formatDateInput } from '../utils/dates';

const MailFilter = props => (
  <Filter {...props}>
    <TextInput
      label="mails.list.search"
      source="_q"
      alwaysOn
    />
    <ReferenceInput
      label="User"
      source="user_id"
      reference="users"
      perPage="1e9"
    >
      <SelectInput
        optionText="email"
      />
    </ReferenceInput>
    <TextInput
      source="name"
    />
    <TextInput
      source="params"
    />
    <TextInput
      source="send_message"
    />
    <BooleanInput
      source="send_status"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="created_at"
      showTime
    />
  </Filter>
);

const MailList = props => (
  <List {...props}
    filters={<MailFilter />}
    sort={{ field: 'created_at', order: 'DESC' }}
    perPage={50}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={r => r.send_message}
          secondaryText={r => r.name}
          tertiaryText={r => <Moment format="DD MMMM YYYY HH:mm">{r.create_at}</Moment>}
        />
      }
      medium={
        <Datagrid bodyOptions={{ showRowHover: true }} options={{ height: '70vh' }} styles={datagridDefaultStyles}>
          <TextField
            source="id"
          />
          <FunctionField
            label="User"
            source="user_id"
            render={r => (r.email ? (<Chip>{r.email}</Chip>) : '')}
          />
          <FunctionField
            label="Receiver"
            source="emails"
            render={r => (r.emails && r.emails.length ? <div>{r.emails.map((email, i) => (<Chip style={{ margin: 4 }} key={i}>{email}</Chip>))}</div> : '')}
          />
          <FunctionField
            source="cc"
            render={r => (r.cc && r.cc.length ? <div>{r.cc.map((email, i) => (<Chip style={{ margin: 4 }} key={i}>{email}</Chip>))}</div> : '')}
          />
          <FunctionField
            source="bcc"
            render={r => (r.bcc && r.bcc.length ? <div>{r.bcc.map((email, i) => (<Chip style={{ margin: 4 }} key={i}>{email}</Chip>))}</div> : '')}
          />
          <TextField
            source="name"
          />
          <TextField
            source="params"
          />
          <TextField
            source="send_message"
          />
          <BooleanField
            source="send_status"
          />
          <DateField
            formatDate={formatDateTimeInput}
            source="created_at"
            showTime
          />
        </Datagrid>
      }
    />
  </List>
);

export default MailList;
