import { DateInput, Filter, TextInput } from 'admin-on-rest';
import React from 'react';
import { formatDateInput } from '../utils/dates';

const VolatilitySurfaceFilter = props => (
  <Filter {...props}>
    <TextInput
      source="short_code"
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="generated_at"
      showTime
    />
    <DateInput
      options={{
        formatDate: formatDateInput,
      }}
      source="updated_at"
      showTime
    />
  </Filter>
);

export default VolatilitySurfaceFilter;
