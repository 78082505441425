export const removeSpecialChars = str => str.replace(/[^a-zA-Z0-9- #_$%-]/g, '');
export const convertUTCDateToLocalDate = (date) => {
  if (date) {
    const dateFunc = new Date();
    const splitTime = date.split(':');
    dateFunc.setHours(splitTime[0], splitTime[1], 0);
    /* eslint-disable */
    const newDate = new Date(dateFunc.getTime() + dateFunc.getTimezoneOffset() * 60 * 1000);

    const offset = dateFunc.getTimezoneOffset() / 60;
    const hours = dateFunc.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }
  return null;
}

export const isCqgContract = (contractCode) => {
  const code = contractCode.toString().toUpperCase();
  return ['F.','C.','P.','O.','D.', 'X.'].some(x => code.substring(0,2).includes(x));
};
