/* eslint-disable */
import dotProp from 'dot-prop-immutable';
import displayDefault from './default';
import { NO_TOGGLE_COLUMN, NO_UPDATE, NO_ADD, NO_TOGGLE_ALL_COLUMNS, NO_POPUP_ORDER, COLUMN_VERSION } from './actions';

const getOrderColumns = () => {
  if (!localStorage.getItem('_vcol_ol') || localStorage.getItem('_vcol_ol') !== `${COLUMN_VERSION}`) {
    localStorage.setItem('order_columns', JSON.stringify(displayDefault));
    localStorage.setItem('_vcol_ol', COLUMN_VERSION);
  }

  const savedCols = JSON.parse(localStorage.getItem('order_columns'));
  if (!savedCols) return { orderColumns: displayDefault, resave: true };

  // remove any keys that no longer exist
  let resave = false;
  Object.keys(savedCols).forEach(k => {
    if (k in displayDefault && savedCols[k].label === displayDefault[k].label) return;
    delete savedCols[k];
    resave = true;
  });

  // add any new defaults
  Object.keys(displayDefault).forEach(k => {
    if (k in savedCols) return;
    savedCols[k] = displayDefault[k];
    resave = true;
  });
  return { orderColumns: savedCols, resave };
};

const { orderColumns, resave } = getOrderColumns();
if (resave) {
  localStorage.setItem('order_columns', JSON.stringify(orderColumns));
}

const defaultState = {
  columns: orderColumns,
  version: 0,
  order: {},
};

export const neonOrdersReducer = (previousState = defaultState, { type, payload }) => {
  switch (type) {
    case NO_UPDATE: {
      if (previousState.order && previousState.order.id === payload.id) {
        return {
          ...previousState,
          order: { ...previousState.order, ...payload.data, badge: payload.isBadge ? 'UPDATED' : '' },
        };
      }
      return previousState;
    }
    case NO_POPUP_ORDER:
      return { ...previousState, order: { ...payload } };
    case NO_TOGGLE_COLUMN: {
      const { columns } = previousState;
      const { col, status } = payload;
      const display = { ...columns, [col]: { ...columns[col], status } };
      localStorage.setItem('order_columns', JSON.stringify(display));
      return { ...previousState, columns: display };
    }
    case NO_TOGGLE_ALL_COLUMNS: {
      const { columns } = previousState;
      const display = Object.keys(columns).reduce((res, key) => {
        if (!columns[key].disabled) columns[key].status = payload;
        return Object.assign(res, { [key]: columns[key] });
      }, {});
      localStorage.setItem('order_columns', JSON.stringify(display));
      return { ...previousState, columns: display };
    }
    default:
      return previousState;
  }
};

/**
 * Update already existing orders.
 * This method need for live update.
 * @param {Object} state Previouse state
 * @param {Object} data List of existing orders
 * @param {Object} payload Payload from redux action (new order)
 */
const updateOrder = (state, data, payload) =>
  payload.id && data[payload.id]
    ? dotProp.set(state, 'resources.neon_orders.data', list => ({
        ...list,
        [payload.id]: { ...data[payload.id], ...payload.data, badge: payload.isBadge ? 'UPDATED' : '' },
      }))
    : state;

/**
 * Mixing new orders to neon order list state.
 * This method need for live update.
 * @param {Object} state Previouse state
 * @param {Object} payload Payload from redux action (new order)
 * If we will need include new orders to pagination and total per page modify addOrder method as:
 * @example
 * const addOrder = (state, payload) => {
 *  let total = 0;
 *  const updatedData = dotProp.set(state, 'resources.neon_orders.data', list => ({ [payload.id]: { ...payload.data, badge: 'NEW' }, ...list }));
 *  const updatedIds = dotProp.set(updatedData, 'resources.neon_orders.list.ids', (ids) => {
 *    total = ids.length + 1;
 *    return [payload.id, ...ids];
 *  });
 *  return dotProp.set(updatedIds, 'resources.neon_orders.list.total', total);
 * };
 */
const addOrder = (state, payload) => {
  const filter = dotProp.get(state, 'resources.neon_orders.list.params.filter');
  if (
    Object.keys(filter).includes('autoapprove_status') &&
    filter.autoapprove_status !== payload.data.autoapprove_status
  ) {
    return state;
  }

  const updatedData = dotProp.set(state, 'resources.neon_orders.data', list => ({
    [payload.id]: { ...payload.data, badge: 'NEW' },
    ...list,
  }));

  const orderIds = dotProp.get(updatedData, 'resources.neon_orders.list.ids');
  if (orderIds.includes(payload.id)) return updatedData; // react admin may have updated cache before we processed this action

  return dotProp.set(updatedData, 'resources.neon_orders.list.ids', ids => [payload.id, ...ids]);
};

export const adminInjection = (state, { type, payload }) => {
  switch (type) {
    case NO_UPDATE: {
      const { resources: { neon_orders: { data }, }, } = state;
      return updateOrder(state, data, payload);
    }
    case NO_ADD:
      return addOrder(state, payload);
    default:
      return state;
  }
};
