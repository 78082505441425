import React from 'react';
import { Field } from 'redux-form';
import 'jsoneditor-react/es/editor.min.css';

import { SelectArrayInput, SimpleForm, Create, TextInput, Toolbar, SaveButton, required, LongTextInput, minLength, maxLength } from 'admin-on-rest';

import JsonEditor from '../helpers/JsonEditor';

const DashboardToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      redirect="list"
      submitOnEnter={true}
    />
  </Toolbar>
);
const validateMinLengthToTwo = minLength(2);
const validateMaxLengthToTwenty = maxLength(20);

const DashboardCreate = props => (
  <Create {...props}>
    <SimpleForm
      toolbar={<DashboardToolbar />}
    >
      <LongTextInput
        source="description"
        validate={[required, validateMinLengthToTwo]}
      />
      <TextInput
        source="group_id"
        validate={[required, validateMinLengthToTwo]}
      />
      <TextInput
        source="report_id"
        validate={[required, validateMinLengthToTwo]}
      />
      <TextInput
        source="name"
        validate={[required, validateMinLengthToTwo, validateMaxLengthToTwenty]}
      />
      <SelectArrayInput
        source="pages"
      />
      <Field
        name="powerbi_filter"
        source="powerbi_filter"
        component={JsonEditor}
      />
    </SimpleForm>
  </Create>
);

export default DashboardCreate;
