import React from 'react';
import DownloadJson from '../../components/DownloadJson';
import { StructureDataRecord, WrapperDataRecord } from '../types';

interface DownloadFieldValidationRulesProps {
  record?: StructureDataRecord | WrapperDataRecord;
}

const DownloadFieldValidationRules: React.FC<DownloadFieldValidationRulesProps> = ({ record = {} }) => {
  const { fieldValidationRules, fieldValidationRulesFileUploadStatus, fieldValidationRulesFilename } = record;

  if (!fieldValidationRules) {
    return <div style={{ width: '150px', color: 'red' }}>No Field validation rules uploaded</div>;
  }
  return (
    <DownloadJson
      jsonFile={fieldValidationRules}
      fileStatus={fieldValidationRulesFileUploadStatus}
      fileName={fieldValidationRulesFilename || 'sp_field_validation_rules.json'}
    />
  );
};

export default DownloadFieldValidationRules;
